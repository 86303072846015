import { useTranslation } from "react-i18next";
import { faArrowToBottom } from "@fortawesome/pro-light-svg-icons";
import { getMinimumRequiredPlan } from "utilities/featureFlags";
import { TeaserItemContent } from "components/miscellaneous/TeaserNavItem";
import { useAppSelector } from "redux/hooks";
import { companyLanguages } from "redux/auth/selectors";
import { useDownloadList } from "./useDownloadList";
import { TableListQuery } from "hooks/table-list/useTableListQuery";

interface useMainActionNodesProps {
  mini: boolean;
  toggleModalLoader: (arg: string | null) => void;
  toggleGeneratePdfListModal: (arg: boolean) => void;
  query: TableListQuery;
  lang: string;
  propertyType?: string;
}

const useMainActionNodes = ({
  mini,
  query,
  toggleModalLoader,
  toggleGeneratePdfListModal,
  lang,
  propertyType,
}: useMainActionNodesProps) => {
  const { t } = useTranslation();
  const languageOptions = useAppSelector(companyLanguages);
  const { downloadList } = useDownloadList();

  if (mini) {
    return undefined;
  }
  const subactions: {
    label: any;
    icon: any;
    onItemClick: () => void;
    disabled?: boolean;
  }[] = [
    {
      label: t("Download CSV list"),
      icon: faArrowToBottom,
      onItemClick: () => {
        // downloadCsv("csv");
        downloadList({
          query,
          type: "csv",
          toggleModalLoader,
          lang,
          propertyType,
        });
      },
    },
    {
      label: t("Download XLS list"),
      icon: faArrowToBottom,
      onItemClick: () => {
        // downloadCsv({
        downloadList({
          query,
          type: "xls",
          toggleModalLoader,
          lang,
          propertyType,
        });
      },
    },
  ];
  const minPropertyPDFGeneration = getMinimumRequiredPlan(
    "propertyPDFGeneration"
  );
  if (minPropertyPDFGeneration !== "NOACCESS") {
    subactions.push({
      icon: !minPropertyPDFGeneration ? faArrowToBottom : undefined,
      label: !minPropertyPDFGeneration ? (
        <>{t("Download PDF list")}</>
      ) : (
        <TeaserItemContent
          label={t("Download PDF list")}
          planToAccess={minPropertyPDFGeneration}
          icon={faArrowToBottom}
        />
      ),
      disabled: !!minPropertyPDFGeneration,
      onItemClick: () => {
        // deprecated
        if (!!languageOptions) {
          toggleGeneratePdfListModal(true);
        } else {
          // downloadCsv("pdf");
          downloadList({
            query,
            type: "pdf",
            toggleModalLoader,
            lang,
            propertyType,
          });
        }
      },
    });
  }
  return subactions;
};

export default useMainActionNodes;
