import { NavLink, NavLinkProps } from "react-router-dom";

import Subnav from "./Subnav";
import { Badge } from "../helpers-ux";
import { getMinimumRequiredPlan } from "utilities/featureFlags";
import { useTranslation } from "react-i18next";

export interface CustomRouteProps {
  navLabel?: string;
  dataBoarding?: string;
  path: NavLinkProps["to"];
  isActive?: NavLinkProps["isActive"];
  isTeaser?: boolean;
}

interface RouterSubnavProps {
  routes: CustomRouteProps[];
}

function RouterSubnav({ routes = [] }: RouterSubnavProps) {
  const { t } = useTranslation();
  const minEventManagement = getMinimumRequiredPlan("eventManagement");
  const minPropertyManagement = getMinimumRequiredPlan("propertyManagement");
  const items = routes
    .filter((item) => {
      return item.navLabel;
    })
    .map((item, i) => {
      return (
        <>
          {(item.isTeaser && (
            <div
              key={i}
              className="tw-pointer-events-none tw-flex tw-items-center tw-h-full tw-bg-cs-shade-100"
            >
              <span>
                <span style={{ opacity: "0.5" }}>{item.navLabel} </span>
                <Badge
                  marginLeft="tw-ml-2"
                  color="primary"
                  uppercase
                  innerShadow
                >
                  {minEventManagement !== "NOACCESS" && minEventManagement}
                  {minPropertyManagement === "NOACCESS" && t("PRO")}
                </Badge>
              </span>
            </div>
          )) || (
            <NavLink
              activeClassName="selected"
              key={i}
              to={item.path}
              data-boarding={item.dataBoarding}
              isActive={item.isActive}
            >
              {item.navLabel}
            </NavLink>
          )}
        </>
      );
    });

  return <Subnav items={items} />;
}

export default RouterSubnav;
