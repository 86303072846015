import { RootState } from "redux/store";
import { type UserFilterPresetValue, type UserFilterPresets } from "../actions";

/** Get the active filter preset for a given presetName */
export function selectActiveFilterPreset(presetName: string) {
  return (state: RootState): UserFilterPresetValue | undefined => {
    return state.auth.profile.data.casaoneUser?.filterPresets[presetName]?.find(
      (userFilterPreset) => userFilterPreset.active
    );
  };
}

/** Get all filter presets for the user */
export function selectAllFilterPresets(state: RootState): UserFilterPresets {
  const profile = state.auth.profile;
  if (profile.data.casaoneUser && profile.data.casaoneUser.filterPresets) {
    return profile.data.casaoneUser.filterPresets;
  }
  return {};
}

/** Get all filters for a given presetName for the user */
export function selectFilterPresetListByPresetName(presetName: string) {
  return (state: RootState): UserFilterPresetValue[] => {
    const profile = state.auth.profile;
    return profile.data.casaoneUser?.filterPresets?.[presetName] || [];
  };
}
