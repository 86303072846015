import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface ToolbarMenuProps {
  menuToggle?: boolean;
  profileIcon?: JSX.Element;
  profileInfo?: {
    firstName: string;
    lastName: string;
    role: string;
  };
  children?: ReactNode;
  isMobile?: boolean;
}

const ToolbarMenu = ({
  menuToggle = false,
  profileIcon,
  profileInfo,
  children,
  isMobile,
}: ToolbarMenuProps) => {
  const wrapperMobileClasses = `
    tw-text-white tw-transition-transform tw-antialiased
    tw-fixed tw-top-0 tw-right-0 tw-z-[-1] tw-w-[320px] tw-h-full tw-max-h-full tw-pt-20 tw-pr-0 tw-pb-8 tw-translate-x-full tw-bg-cs-shade-700
  `;
  const wrapperDesktopClasses = `tw-text-white tw-transition-transform tw-antialiased`;

  const listMobileClasses = `
    tw-relative tw-flex tw-flex-col tw-items-start tw-flex-nowrap tw-p-0 tw-m-0 tw-leading-[3rem] tw-list-none tw-h-[calc(100%-4rem)]
  `;
  const listDesktopClasses = `
    tw-relative tw-flex tw-items-start tw-flex-nowrap tw-p-0 tw-m-0 tw-leading-[3rem] tw-list-none tw-h-[calc(100% - 4rem)]
    tw-flex-row tw-items-center tw-h-auto tw-flex-wrap
  `;
  return (
    <div
      className={`
      ${isMobile ? wrapperMobileClasses : wrapperDesktopClasses}
      ${isMobile ? "md:tw-hidden" : "tw-hidden md:tw-block"}
      ${menuToggle ? "tw-translate-x-0" : ""}`}
    >
      <NavLink
        to="/admin"
        className="md:tw-hidden tw-flex tw-items-center tw-py-0 tw-px-4 tw-mb-8 tw-text-white"
      >
        <div className="tw-flex tw-justify-center tw-items-center tw-w-[42px] tw-h-[42px]">
          {profileIcon}
        </div>
        <div className="tw-ml-[1.3rem] tw-text-base">
          <div className="tw-font-bold">
            {profileInfo?.firstName} {profileInfo?.lastName}
          </div>
          {profileInfo?.role}
        </div>
      </NavLink>
      <ol
        className={`
        ${isMobile ? listMobileClasses : listDesktopClasses}
        ${isMobile ? "md:tw-hidden" : "md-down:tw-hidden"}`}
      >
        {children}
      </ol>
    </div>
  );
};

export default ToolbarMenu;
