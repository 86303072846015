import { Component } from "react";
import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import FormGroup from "../formElements/helpers/FormGroup";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AddressPicker extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      mapErrorMsg: null,
      map: null,
      markersLayer: null,
      currentMarker: null,
    };
  }

  componentDidMount() {
    const lang =
      this.props.profile && this.props.profile.data
        ? this.props.profile.data.lang
        : "";
    mapboxgl.accessToken =
      "pk.eyJ1IjoiY2FzYXNvZnQiLCJhIjoiY2lzbmZ2c3prMDAxMjJ4bWVyNGJlam5tNSJ9.w0h1a48d-lFAniCbSGvBLQ";

    let style = "";
    if (this.props.mapStyle === "gray") {
      switch (lang) {
        case "DE":
          style = "mapbox://styles/casasoft/cj6c37wxr4sm32rqlopz9d8xw";
          break;
        case "IT":
          // local names italian is not supported
          style = "mapbox://styles/casasoft/cj6c6vwm14wgv2rmr06mhfci0";
          break;
        case "EN":
          style = "mapbox://styles/casasoft/cj6c5pntj4uxe2ropj2ygrszf";
          break;
        case "FR":
          style = "mapbox://styles/casasoft/cj6c5qnwr4v0h2rmxuw7xbob5";
          break;
        default:
          // DE
          style = "mapbox://styles/casasoft/cj6c37wxr4sm32rqlopz9d8xw";
          break;
      }
    } else {
      style = "mapbox://styles/mapbox/streets-v9";
    }

    try {
      const map = new mapboxgl.Map({
        container: `addressPicker${this.props.id}`,
        minZoom: 3,
        maxZoom: 21,
        center: [46.926116, 8.079778],
        zoom: this.props.zoom,
        scrollZoom: false,
        style,
      });
      const nav = new mapboxgl.NavigationControl({
        options: {
          showCompass: false,
          showZoom: true,
        },
      });
      map.addControl(nav, "top-left");
      this.setState(
        {
          map,
        },
        () =>
          this._updateMarkerPos(this.props.lat, this.props.lng, this.props.zoom)
      );
    } catch (error) {
      if (error.message === "Failed to initialize WebGL.") {
        this.setState({
          mapErrorMsg:
            "Failed to load map - failed to initialize WebGL - Check your browser settings",
        });
      } else {
        this.setState({
          mapErrorMsg: "Failed to load map - unknown error",
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.map) {
      this._updateMarkerPos(nextProps.lat, nextProps.lng, false);
    }
  }

  _updateMarkerPos(lat, lng, zoom) {
    const newState = Object.assign({}, this.state);
    if (lat && lng) {
      newState.currentMarker = {
        lat,
        lng,
      };
      if (!newState.markersLayer) {
        newState.markersLayer = new mapboxgl.Marker({
          // icon: this._getStandardIcon('active'),
          draggable: true,
          color: "rgb(var(--tw-cs-accent-color-dark))",
        });
        newState.markersLayer.setLngLat([lng, lat]);
        newState.markersLayer.on("dragend", (e) => {
          const newMarker = e.target.getLngLat();
          const noOneWantsThis = true;
          if (this.props.pinUpdateAddress && !noOneWantsThis) {
            this._getNewPlaceReverse(newMarker);
          } else {
            // make sure to catch on update that you only sending lat/lng not the full address
            this._updateAddress({
              lat: newMarker.lat,
              lng: newMarker.lng,
            });
          }
        });
        newState.markersLayer.addTo(newState.map);
      } else {
        newState.markersLayer.setLngLat([lng, lat]);
      }

      if (newState.map) {
        if (zoom) {
          newState.map.easeTo({
            center: [lng, lat],
            zoom: this.props.zoom,
          });
        } else {
          newState.map.easeTo({
            center: [lng, lat],
          });
        }
        newState.map.scrollZoom.disable();
      }
    } else if (
      newState.currentMarker &&
      newState.currentMarker.lat === lat &&
      newState.map
    ) {
      if (newState.markersLayer) {
        newState.markersLayer.remove();
      }
      newState.map.scrollZoom.disable();
    } else if (
      newState.currentMarker === "undefined" &&
      newState.markersLayer
    ) {
      newState.markersLayer.remove();
      // no marker
    } else if (!lat && !lng) {
      // new marker - no marker - reset map
      if (newState.markersLayer && newState.currentMarker) {
        newState.markersLayer.remove();
        newState.map.easeTo({
          center: [46.926116, 8.079778],
          zoom: this.props.zoom,
        });
      }
    }
    this.setState(newState);
  }

  _getNewPlaceReverse(latLng) {
    if (this.props.gmap) {
      const geocoder = new this.props.gmap.Geocoder();
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          const newState = {};
          if (results[0]) {
            const place = results[0];
            for (let i = 0; i < place.address_components.length; i += 1) {
              let addressType = place.address_components[i].types[0];
              let val = "";
              switch (addressType) {
                case "country":
                  val = place.address_components[i].short_name;
                  break;
                case "route":
                  addressType = "street";
                  val = place.address_components[i].long_name;
                  break;
                default:
                  val = place.address_components[i].long_name;
              }
              newState[addressType] = val;
            }
            newState.lat = latLng.lat;
            newState.lng = latLng.lng;
            this._updateAddress(newState);
          }
        }
      });
    }
  }

  _updateAddress(newAddress) {
    if (this.props?.onChange) {
      this.props.onChange(newAddress);
    }
  }

  render() {
    const style = Object.assign({}, this.props.style, {
      width: "100%",
      height: "400px",
    });
    const formProps = {
      status: this.props.status,
      focused: this.props.focused,
      filled: this.props.filled,
      className: this.props.className,
      nobox: this.props.nobox,
      label: this.props.label,
      id: this.props.id,
      required: this.props.required,
      message: this.props.message,
      text: this.props.text,
    };
    return (
      <FormGroup {...formProps}>
        {(this.state.mapErrorMsg && (
          <div className="tw-flex tw-justify-center tw-items-center tw-h-[400px] tw-w-full tw-p-5 tw-border tw-border-solid tw-border-cs-danger-200 tw-rounded tw-bg-cs-danger-100 tw-mt-10">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="tw-mr-2 tw-text-cs-danger-300"
            />
            <p className="tw-text-cs-danger-300 tw-mb-0">
              {this.state.mapErrorMsg}
            </p>
          </div>
        )) || (
          <>
            <div style={style} id={`addressPicker${this.props.id}`} />
          </>
        )}
      </FormGroup>
    );
  }
}

AddressPicker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  gmap: PropTypes.object,
  style: PropTypes.object,
  onChange: PropTypes.func,
  profile: PropTypes.object,
  zoom: PropTypes.number,
  mapStyle: PropTypes.string,
  pinUpdateAddress: PropTypes.bool,
  status: PropTypes.string,
  focused: PropTypes.string,
  filled: PropTypes.string,
  className: PropTypes.string,
  nobox: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.array,
  text: PropTypes.array,
};

AddressPicker.defaultProps = {
  lat: null,
  lng: null,
  gmap: null,
  style: {},
  profile: {
    data: null,
  },
  zoom: 18,
  mapStyle: "gray",
  pinUpdateAddress: true,
  id: "#32lkj234jkad",
  status: null,
  focused: null,
  filled: null,
  className: null,
  nobox: null,
  label: null,
  required: null,
  message: null,
  text: null,
};

export default AddressPicker;
