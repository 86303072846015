/* eslint class-methods-use-this: "off" */

import { PureComponent } from "react";
import PropTypes from "prop-types";
import Messages from "@casasoft/styleguide/components/formElements/Messages";
import ErrorBoundary from "./ErrorBoundary";
import { Alert } from "@casasoft/styleguide/components/helpers-ux";

/*
  Needs the following defined

  **Extending Class:**

  in general the data should be flattened for hydration and un-flattened for persisting

  this.props.defaultData - all fields that are used in the Form and their defaults

  __getPayload - function to grab actual Data should be flattened and normalized - can default to defaultData

  __persistData - function that gets called with the providing data - here needs to be the logic to save the entity/entities split the data etc

 - function to clean Values (empty === null || number as string convert to number etc..)
                                      and flatten structure

  entityStore - **disabled atm since it won't work properly without more investigation** tore of the Entity - this is optional

  **Wrapper Component**

  save - boolean to trigger the submit from outside

*/

/**
 * @deprecated please follow the new guidelines: https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-forms-and-useform--page
 */
class FormBaseContainer extends PureComponent {
  defaultData = {};

  constructor(props, context) {
    super(props, context);

    this.state = {
      working: props.getPayload,
      messages: {},
      dirtyFields: [],
      data: this.props.defaultData,
      payload: this.props.defaultData,
    };
  }

  static getDerivedStateFromProps(incommingProps, state) {
    const resultState = {};
    if (incommingProps.id !== state.id) {
      resultState.working = true;
      resultState.payload = null;
      resultState.dirtyFields = [];
      resultState.id = incommingProps.id;
    }
    if (Object.keys(resultState).length === 0) {
      return null;
    }
    return resultState;
  }

  componentDidMount() {
    this.__triggerGetPayload();
  }

  __triggerGetPayload() {
    if (this.props.getPayload) {
      this.setState({ working: true });
      this.props
        .getPayload()
        .catch((err) => {
          this.setState({
            messages: this.__errToMessages(err),
            working: false,
          });
        })
        .then((payload) => {
          this.__receivePayload(payload);
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.save !== prevProps.save && this.props.save === true) {
      this.props.onReady?.(false);
      this.__handleSubmit();
    }
  }

  __flattenAndCleanPayload(payload) {
    if (payload) {
      const data = Object.assign({}, this.props.defaultData, payload);

      // items that are null should be rehydrated with their default values
      Object.keys(data).forEach((key) => {
        if (data[key] === null && this.props.defaultData[key] !== undefined) {
          data[key] = this.props.defaultData[key];
        }
      });

      return data;
    }
    return this.props.defaultData;
  }

  __hydrateForm(data) {
    this.setState({ data });
  }

  __receivePayload(payload) {
    const data = {};
    Object.entries(payload)
      .filter((elem) => this.props.defaultData[elem[0]] !== undefined)
      .forEach((item) => {
        data[item[0]] = item[1];
      });
    const cdata = Object.assign({}, this.state.data, data);
    this.setState({ payload: data, working: false, data: cdata });
    this.__hydrateForm(data);
  }

  __isDirty(field, value) {
    let shouldBeDirty = true;
    if (this.state.payload) {
      const payload = this.state.payload;
      if (payload[field] !== undefined && payload[field] === value) {
        shouldBeDirty = false;
      }
    }
    return shouldBeDirty;
  }

  __setDirtyness(field, value) {
    const shouldBeDirty = this.__isDirty(field, value);
    const dirtyFields = [...this.state.dirtyFields];
    if (shouldBeDirty && !dirtyFields.find((e) => e === field)) {
      dirtyFields.push(field);
      this.setState({ dirtyFields });
    } else if (!shouldBeDirty && dirtyFields.find((e) => e === field)) {
      dirtyFields.splice(dirtyFields.indexOf(field), 1);
      this.setState({ dirtyFields });
    }
    this.__emitDirtyness();
    return shouldBeDirty;
  }

  __emitDirtyness(newState = null) {
    const leState = newState || this.state;
    if (leState.dirtyFields.length > 0) {
      this.props.onReady?.(true);
    } else {
      this.props.onReady?.(false);
    }
  }

  __handleFieldChange(field, value) {
    if (this.props.defaultData[field] === undefined) {
      throw Error(
        `Warning defaultData value for ${field} not set. this can cause inconsistencies`
      );
    }
    const inputState = {
      data: Object.assign({}, this.state.data),
      dirtyFields: [...this.state.dirtyFields],
    };
    const newState = this.__updateDataValue(
      field,
      value,
      this.__isDirty(field, value),
      inputState
    );
    this.setState(newState);
    this.__emitDirtyness(newState);
  }

  __handleMultiFieldChange(fields) {
    let inputState = {
      data: Object.assign({}, this.state.data),
      dirtyFields: [...this.state.dirtyFields],
    };
    fields.forEach((field) => {
      inputState = this.__updateDataValue(
        field.name,
        field.value,
        this.__isDirty(field.name, field.value),
        inputState
      );
    });
    this.setState(inputState);
    this.__emitDirtyness(inputState);
  }

  __handleSetMessages(messages) {
    this.setState({ messages });
  }

  __updateDataValue(field, value, dirty = false, inputState) {
    const newData = Object.assign({}, inputState.data);
    const dirtyFields = [...inputState.dirtyFields];
    if (newData[field] !== value) {
      newData[field] = value;
      if (dirty && !dirtyFields.find((e) => e === field)) {
        dirtyFields.push(field);
      } else if (!dirty && dirtyFields.find((e) => e === field)) {
        dirtyFields.splice(dirtyFields.indexOf(field), 1);
      }
    }
    return { data: newData, dirtyFields };
  }

  __grabUpdatedFields() {
    const data = {};
    Object.keys(this.state.data)
      .filter((field) => {
        if (this.state.dirtyFields.includes(field)) {
          return true;
        }
        const found = this.state.dirtyFields.find((dirtyField) =>
          dirtyField.startsWith(`${field}.`)
        );
        if (found) {
          return true;
        }
        return false;
      })
      .forEach((key) => {
        data[key] = this.state.data[key];
      });
    return data;
  }

  __handleSubmit() {
    // if (this.state.dirtyFields.length > 0) {
    const data = this.__grabUpdatedFields();
    this.setState({ working: true });
    this.props
      .persistData(data, { ...this.state.data })
      .catch((err) => {
        this.setState({ working: false, messages: this.__errToMessages(err) });
      })
      .then(() => {
        this.setState({ working: false });
      });
  }

  // shared method to respond to server error messages
  __errToMessages(err) {
    const messages = {};
    if (err?.response?.data?.validation_messages) {
      Object.entries(err.response.data.validation_messages).forEach(
        (fieldMessages) => {
          if (this.props.defaultData[fieldMessages[0]] === undefined) {
            const renderedFieldMessages = Object.entries(fieldMessages[1]).map(
              (fieldMessage) => {
                return {
                  validationKey:
                    fieldMessages[0] !== "form" ? fieldMessage[0] : null,
                  text: fieldMessage[1],
                  type: "error",
                };
              }
            );
            messages.form = renderedFieldMessages;
          } else {
            const renderedFieldMessages = Object.entries(fieldMessages[1]).map(
              (fieldMessage) => {
                return {
                  validationKey:
                    fieldMessage[0] !== "form" ? fieldMessage[0] : null,
                  text: fieldMessage[1],
                  type: "error",
                };
              }
            );
            messages[fieldMessages[0]] = renderedFieldMessages;
          }
        }
      );
    } else if (err?.response?.data?.detail) {
      messages.form = [
        {
          validationKey:
            err.response.data.title !== "form" ? err.response.data.title : null,
          text: err.response.data.detail,
          type: "error",
        },
      ];
    } else if (typeof err.response.data === "string") {
      messages.form = [
        {
          validationKey: err !== "form" ? err : null,
          text: err,
          type: "error",
        },
      ];
    } else if (err.type && err.validationKey) {
      messages[err.validationKey] = [
        {
          validationKey: err.validationKey,
          text: err.text,
          type: err.type,
        },
      ];
    } else {
      throw Error(err);
    }
    return messages;
  }

  __renderFormMessages(messages) {
    let messageElements = null;
    if (messages && messages.form && messages.form.length) {
      messageElements = messages.form.map((elem) => {
        let messageType = "";
        switch (elem.type) {
          case "error":
            messageType = "danger";
            break;
          case "success":
            messageType = "success";
            break;
          case "info":
            messageType = "info";
            break;
          default:
            messageType = "danger";
            break;
        }
        return (
          <Alert key={`${elem.validationKey}${elem.text}`} color={messageType}>
            {elem.validationKey ? `${elem.validationKey}:` : ""} {elem.text}
          </Alert>
        );
      });
    }
    return messageElements;
  }

  render() {
    const childNode = this.props.editForm({
      messages: this.state.messages,
      data: this.state.data,
      working: this.state.working,
      onFieldChange: (field, value) => {
        this.__handleFieldChange(field, value);
      },
      onTriggerReload: () => {
        this.__triggerGetPayload();
        this.setState({ dirtyFields: [] });
      },
      onMultiFieldChange: (fields) => {
        this.__handleMultiFieldChange(fields);
      },
      setMessages: (messages) => {
        this.__handleSetMessages(messages);
      },
      onFormReady: (ready) => {
        this.props.onFormReady?.(ready);
      },
      setFormWorking: (status) => {
        this.setState({ working: status });
      },
    });

    return (
      <ErrorBoundary>
        <form
          onSubmit={() => {
            this.__handleSubmit();
          }}
          style={
            this.state.working ? { opacity: 0.5, pointerEvents: "none" } : {}
          }
        >
          {childNode}
          {this.state.messages && this.state.messages.form && (
            <Messages icon messages={this.state.messages.form} />
          )}
        </form>
      </ErrorBoundary>
    );
  }
}

FormBaseContainer.propTypes = {
  save: PropTypes.bool,
  onReady: PropTypes.func,
  onFormReady: PropTypes.func,
  getPayload: PropTypes.func,
  persistData: PropTypes.func.isRequired,
  editForm: PropTypes.func.isRequired,
  defaultData: PropTypes.object.isRequired,
};

FormBaseContainer.defaultProps = {
  save: false,
  getPayload: null,
};

export default FormBaseContainer;
