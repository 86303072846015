import { forwardRef, ReactNode, HTMLAttributes } from "react";
import { MainAlert } from "./MainAlert";

interface ContentProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "className"> {
  miniList?: JSX.Element;
  children: ReactNode;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ miniList, children, ...divProps }, ref) => {
    return (
      <div ref={ref} {...divProps} className="app__content">
        {!!miniList && (
          <div className="app__contentminilist__container">
            <div className="app__contentminilist__list">{miniList}</div>
            <div className="app__contentminilist__content">
              <MainAlert />
              <div className="app__contentminilist__content-container">
                {children}
              </div>
            </div>
          </div>
        )}
        {!miniList && (
          <div className="app__content__container">
            <MainAlert />
            {children}
          </div>
        )}
      </div>
    );
  }
);

export default Content;
