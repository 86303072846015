import { useState } from "react";
import { ReducedContactShape } from "../../LanguageBreakdownContainer";

export const useEditContactModal = () => {
  const [selectedContact, setSelectedContact] = useState<
    ReducedContactShape | undefined
  >(undefined);

  const handleEditContact = (contact: ReducedContactShape) => {
    setSelectedContact(contact);
  };

  const handleEditContactModalClose = () => {
    setSelectedContact(undefined);
  };

  return {
    selectedContact,
    handleEditContact,
    handleEditContactModalClose,
  };
};
