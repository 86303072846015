import i18n from "./i18n";

interface PriceArgs {
  price: number | string | null;
  time_segment?: string | null;
  property_segment?: string | null;
  currency?: string;
  trailingZeros?: boolean;
  alignRight?: boolean;
}

export const thousandSeparator = (number: number | string | undefined) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

export const getNormalicedPriceInteger = (input: PriceArgs) => {
  if (input.price) {
    return input.price;
  }
  return 0;
};

export type RenderPriceArgsAlignDefault = Omit<PriceArgs, "alignRight"> & {
  alignRight?: false;
};
type RenderPriceArgsAlignRight = Omit<PriceArgs, "alignRight"> & {
  alignRight: true;
};

export function renderPrice(
  input: RenderPriceArgsAlignDefault
): string | undefined;
export function renderPrice(
  input: RenderPriceArgsAlignRight
): JSX.Element | undefined;
export function renderPrice(
  input: RenderPriceArgsAlignDefault | RenderPriceArgsAlignRight
) {
  let showSqm: boolean = false;
  const currencyFormat: string = input.currency ? input.currency : "CHF";
  const trailingFormat: string = input.trailingZeros ? ".00" : ".–";
  let formattedPrice = thousandSeparator(input.price?.toString());
  if (input.price) {
    if (typeof input.price === "number" && Number.isInteger(input.price)) {
      formattedPrice += trailingFormat;
    }
    if (!input.time_segment) {
      // needs currencyFormat
      if (input.alignRight) {
        return (
          <div>
            {currencyFormat}
            <span
              style={{ display: "inline-block", width: 85, textAlign: "right" }}
            >
              {formattedPrice}
            </span>
          </div>
        );
      } else {
        return `${currencyFormat} ${formattedPrice}`;
      }
    } else if (input.time_segment) {
      let time = null;
      switch (input.time_segment) {
        case "w":
          time = i18n.t("standards:week");
          break;
        case "m":
          time = i18n.t("standards:month");
          break;
        case "y":
          time = i18n.t("standards:year");
          break;
        case "d":
          time = i18n.t("standards:day");
          break;
        default:
          time = null;
          break;
      }
      if (input.property_segment === "m") {
        showSqm = true;
      }
      if (showSqm) {
        return `${currencyFormat} ${formattedPrice} / m² ${
          time ? `/ ${time}` : ""
        }`;
      } else {
        return `${currencyFormat} ${formattedPrice} ${time ? `/ ${time}` : ""}`;
      }
    }
  }
}
