import moment from "moment";
import store, { CleanRootStateKeys } from "redux/store";
import { getPublisherItem, isPublisher } from "./publishers";
import {
  selectCasaoneUser,
  selectCompany,
  selectContractOptions,
  selectPublishers,
} from "redux/auth/selectors";

/**
 * Grab values from the state
 * @deprecated use `useAppSelector` with selectors from `xy/selectors.ts` files instead (https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-redux--page)
 */
const resourceGrabber = {
  grab(
    key: string,
    state: CleanRootStateKeys,
    value1?: any,
    value2?: any,
    value3?: any
  ): any {
    const thestate = store.getState()[state];
    switch (key) {
      case "profile":
        return this.getProfile(thestate);
      case "company":
        return this.getProfileCompany(thestate);
      case "plan":
        return this.getProfilePlan(thestate);
      case "lang":
        return this.getProfileLang(thestate);
      case "casaoneUser":
        return this.getProfileCasaoneUser(thestate);
      case "username":
        return this.getProfileUsername(thestate);
      case "contactId":
        return this.getProfileContactId(thestate);
      case "contractOptions":
        return this.getContractOptions(thestate);
      case "customFieldsSchema":
        return this.getCustomFieldsSchema(thestate);
      case "filterPresetList":
        return this.getUserFilterPresetList(thestate, value1);
      case "userFilterActivePreset":
        return this.getUserFilterActivePreset(thestate, value1);
      case "filterAllPresetList":
        return this.getUserFilterAllPresetList(thestate);
      case "filterPresetField":
        return this.getUserFilterPresetField(thestate, value1, value2, value3);
      case "filterPresetLabel":
        return this.getUserFilterPresetLabel(thestate, value1, value2);
      case "availablePublishers":
        return this.getAvailablePublishers(thestate, value1);
      case "unusedCredit":
        return this.getServiceCreditWithUnusedCredits(thestate, value1, value2);
      case "mainLang":
        return this.getMainLang(thestate);
      case "hasLang":
        return this.hasLang(thestate, value1);
      case "validCredits":
        return this.getValidServiceCreditsForProduct(thestate, value1);
      case "uniqueServiceRequestsForProductByRequestHash":
        return this.getUniqueServiceRequestsForProductByRequestHash(
          thestate,
          value1
        );
      case "uniqueServiceRequestsForProductByRequestHashAndReference":
        return this.getUniqueServiceRequestsForProductByRequestHashAndReference(
          thestate,
          value1,
          value2
        );
      case "uniqueServiceRequestsForProductByRequestHashAndFormatAndReference":
        return this.getUniqueServiceRequestsForProductByRequestHashAndFormatAndReference(
          thestate,
          value1,
          value2
        );
      default:
        return null;
    }
  },
  getProfile(state: any) {
    if (state?.profile?.data) {
      return state.profile;
    }
    return null;
  },
  getProfileCompany(state: any) {
    const company = selectCompany({ auth: state } as any);
    return company || null;
  },
  getProfilePlan(state: any) {
    const company = this.getProfileCompany(state);
    if (company?.contracts?.[0]?.options?.plan) {
      if (company.contracts[0].options.plan === "pro") {
        return "pro";
      }
      if (company.contracts[0].options.plan === "basic") {
        return "basic";
      }
    }
    return "starter";
  },
  getProfileLang(state: any) {
    const profile = this.getProfile(state);

    let lang = profile.data.lang ? profile.data.lang : "de";
    if (profile.data.casaoneUser?.contact?.lang) {
      lang = profile.data.casaoneUser.contact.lang;
    }

    return lang;
  },
  getProfileCasaoneUser(state: any) {
    const casaoneUser = selectCasaoneUser({ auth: state } as any);
    return casaoneUser || null;
  },
  getProfileUsername(state: any) {
    const user = this.getProfileCasaoneUser(state);
    if (user?.username) {
      return user.username;
    }
    return null;
  },
  getProfileContactId(state: any) {
    const casaoneUser = this.getProfileCasaoneUser(state);
    if (casaoneUser?.contact?.id) {
      return casaoneUser.contact.id;
    }
    return null;
  },
  getContractOptions(state: any) {
    const contractOptions = selectContractOptions({ auth: state } as any);
    return contractOptions || null;
  },
  getCustomFieldsSchema(state: any) {
    const company = this.getProfileCompany(state);
    if (company?.contracts?.[0]?.customFieldsSchema) {
      return company.contracts[0].customFieldsSchema;
    }
    return null;
  },
  getUserFilterPresetList(state: any, storeName: string) {
    const profile = this.getProfile(state);
    return profile.data.casaoneUser?.filterPresets?.[storeName] || [];
  },
  getUserFilterActivePreset(state: any, storeName: string) {
    const presets = this.getUserFilterPresetList(state, storeName);
    return presets.find((x: any) => x.active === true);
  },
  getUserFilterAllPresetList(state: any) {
    const profile = this.getProfile(state);
    if (profile.data.casaoneUser && profile.data.casaoneUser.filterPresets) {
      return profile.data.casaoneUser.filterPresets;
    }
    return {};
  },
  getUserFilterPresetField(
    state: any,
    storeName: string,
    key: any,
    elemkey: any
  ) {
    const presets = this.getUserFilterPresetList(state, storeName);
    if (presets.length) {
      const foundPresetIndex = presets.findIndex((x: any) => x.key === key);
      if (foundPresetIndex !== -1) {
        if (presets[foundPresetIndex]?.preset?.[elemkey]) {
          return presets[foundPresetIndex].preset[elemkey];
        }
      }
    }

    return undefined;
  },

  getUserFilterPresetLabel(state: any, storeName: string, key: any) {
    const presets = this.getUserFilterPresetList(state, storeName);
    if (presets) {
      const Rpresets = presets;
      const foundPresetIndex = Rpresets.findIndex((x: any) => x.key === key);
      if (foundPresetIndex !== -1) {
        if (Rpresets[foundPresetIndex] && Rpresets[foundPresetIndex].label) {
          return Rpresets[foundPresetIndex].label;
        }
      }
    }
    return "";
  },

  _grabPublisherItem(slug: string) {
    if (isPublisher(slug)) {
      const pubdata = getPublisherItem(slug);
      return { ...pubdata, slug };
    }
    return {
      slug,
      name: slug,
      logo: slug,
      disclaimer: slug,
      range: true,
      promotions: [],
    };
  },
  getMainLang(state: any) {
    const contractOptions = this.getContractOptions(state);
    if (contractOptions?.languages?.length) {
      return contractOptions.languages[0];
    }
    return "de";
  },
  hasLang(state: any, lang: string) {
    const contractOptions = this.getContractOptions(state);
    if (contractOptions?.languages?.length) {
      return contractOptions.languages.includes(lang);
    }
    return false;
  },
  getAvailablePublishers(state: any, projectPublishers = false) {
    const selector = selectPublishers(projectPublishers ? "project" : "single");
    const selectedPublishers = selector({ auth: state } as any);

    return selectedPublishers || [];
  },

  getServiceRequests(state: any) {
    if (state?.items) {
      return state.items;
    }
    return null;
  },

  getAllServiceRequestsForProduct(state: any, providerProductKey: any) {
    const serviceRequests = this.getServiceRequests(state);
    if (serviceRequests) {
      const productServices = serviceRequests.filter(
        (service: any) =>
          `${service.provider}-${service.product}` === providerProductKey
      );
      if (productServices && productServices.length) {
        return productServices;
      }
    }
    return null;
  },

  getUniqueServiceRequestsForProductByRequestHash(
    state: any,
    providerProductKey: any
  ) {
    const allServiceRequests = this.getAllServiceRequestsForProduct(
      state,
      providerProductKey
    );
    const flag: any = {};
    if (!allServiceRequests) {
      return null;
    }
    const uniqueServiceRequests = allServiceRequests.filter((service: any) => {
      if (
        flag[
          `${service.requestHash}-${
            service._embedded && service._embedded.creditPackage
              ? service._embedded.creditPackage
              : ""
          }`
        ]
      ) {
        return false;
      }
      flag[service.requestHash] = true;
      return true;
    });
    if (uniqueServiceRequests && uniqueServiceRequests.length) {
      return uniqueServiceRequests;
    }
    return null;
  },

  getUniqueServiceRequestsForProductByRequestHashAndFormat(
    state: any,
    providerProductKey: any
  ) {
    const allServiceRequests = this.getAllServiceRequestsForProduct(
      state,
      providerProductKey
    );
    const flag: any = {};
    if (!allServiceRequests) {
      return null;
    }
    const uniqueServiceRequests = allServiceRequests.filter((service: any) => {
      if (flag[`${service.requestHash}-${JSON.stringify(service.format)}`]) {
        return false;
      }
      flag[`${service.requestHash}-${JSON.stringify(service.format)}`] = true;
      return true;
    });
    if (uniqueServiceRequests && uniqueServiceRequests.length) {
      return uniqueServiceRequests;
    }
    return null;
  },

  getUniqueServiceRequestsForProductByRequestHashAndReference(
    state: any,
    providerProductKey: any,
    reference: any
  ) {
    return new Promise((resolve) => {
      const uniqueServiceRequests =
        this.getUniqueServiceRequestsForProductByRequestHash(
          state,
          providerProductKey
        );
      if (!uniqueServiceRequests) {
        return resolve(null);
      }
      const variants = uniqueServiceRequests.filter((service: any) => {
        return service.reference === String(reference);
      });
      return resolve({
        global: uniqueServiceRequests,
        local: variants && variants.length ? variants : null,
      });
    });
  },

  getUniqueServiceRequestsForProductByRequestHashAndFormatAndReference(
    state: any,
    providerProductKey: any,
    reference: any
  ) {
    return new Promise((resolve) => {
      const uniqueServiceRequests =
        this.getUniqueServiceRequestsForProductByRequestHashAndFormat(
          state,
          providerProductKey
        );
      if (!uniqueServiceRequests) {
        return resolve(null);
      }
      const variants = uniqueServiceRequests.filter((service: any) => {
        return service.reference === String(reference);
      });
      return resolve({
        global: uniqueServiceRequests,
        local: variants && variants.length ? variants : null,
      });
    });
  },

  getServiceCredits(state: any) {
    if (state?.items) {
      return state.items;
    }
    return null;
  },

  getValidServiceCreditsForProduct(state: any, providerProductKey: any) {
    const serviceCredits = this.getServiceCredits(state);
    if (serviceCredits) {
      const now = moment().unix();
      const validProviderProductCredits = serviceCredits.filter(
        (credit: any) => {
          if (credit.validUntil?.date) {
            return (
              `${credit.provider}-${credit.product}` === providerProductKey &&
              now <= moment(credit.validUntil.date).unix() &&
              now >= moment(credit.validFrom.date).unix() &&
              credit.verified
            );
          }
          return false;
        }
      );
      if (validProviderProductCredits && validProviderProductCredits.length) {
        return validProviderProductCredits;
      }
    }
    return null;
  },

  getServiceCreditWithUnusedCredits(
    state: any,
    providerProductKey: any,
    reference: any
  ) {
    const setReference = typeof reference !== "undefined" ? reference : null;
    const validCredits = this.grab(
      "validCredits",
      "serviceCredits",
      providerProductKey
    );
    const uniqueServiceRequestsForProductByRequestHash = this.grab(
      "uniqueServiceRequestsForProductByRequestHash",
      "serviceRequests",
      providerProductKey
    );
    if (validCredits && validCredits.length) {
      if (
        uniqueServiceRequestsForProductByRequestHash &&
        uniqueServiceRequestsForProductByRequestHash.length
      ) {
        const enrichedFilteredCredits = validCredits.filter((credit: any) => {
          const enrichedCredit = credit;
          const variants: any = {};
          let foreignUsedSingleRequest = false;
          uniqueServiceRequestsForProductByRequestHash.forEach(
            (productService: any) => {
              if (
                productService._embedded &&
                productService._embedded.creditPackage &&
                credit.id === productService._embedded.creditPackage.id &&
                (credit.amount > 1 ||
                  (credit.amount === 1 &&
                    ((setReference &&
                      setReference === productService.reference) ||
                      !setReference))) &&
                (!variants[productService.reference] ||
                  !variants[productService.reference][credit.id] ||
                  !variants[productService.reference][credit.id][
                    productService.requestHash
                  ])
              ) {
                if (
                  variants[productService.reference] &&
                  variants[productService.reference][credit.id]
                ) {
                  variants[productService.reference][credit.id].count += 1;
                  variants[productService.reference][credit.id][
                    productService.requestHash
                  ] = true;
                } else {
                  variants[productService.reference] = {
                    [credit.id]: {
                      count: 1,
                      [productService.requestHash]: true,
                    },
                  };
                }
              } else if (
                productService._embedded &&
                productService._embedded.creditPackage &&
                credit.id === productService._embedded.creditPackage.id &&
                setReference &&
                setReference !== productService.reference &&
                credit.amount === 1
              ) {
                // Single request already used by other entity
                foreignUsedSingleRequest = true;
              }
            }
          );
          let used = 0;
          Object.keys(variants).forEach((key) => {
            const creditVariants = variants[key];
            Object.keys(creditVariants).forEach((creditKey) => {
              const creditVariant = creditVariants[creditKey].count;
              // maximum usage interpolated to block credits for those variants
              const ceiling = Math.ceil(creditVariant / 4)
                ? Math.ceil(creditVariant / 4)
                : 1;
              used += ceiling;
            });
          });
          let variantsLeftOver: number | null = null;
          if (reference) {
            variantsLeftOver = variants?.[reference]?.[credit.id]?.count
              ? variants?.[reference]?.[credit.id]?.count % 4
              : null;
          }
          const available = credit.amount - used;
          enrichedCredit.available = available;
          enrichedCredit.used = used;
          enrichedCredit.usedVariants = variants;
          if (
            ((enrichedCredit.product === "valuation-service" &&
              available > -1 &&
              ((variantsLeftOver && variantsLeftOver > 0) ||
                (variantsLeftOver === null && available > 0))) ||
              available > 0) &&
            !foreignUsedSingleRequest
          ) {
            return enrichedCredit;
          }
          return null;
        });
        if (enrichedFilteredCredits && enrichedFilteredCredits.length) {
          return enrichedFilteredCredits;
        }
        return null;
      }
      return validCredits;
    }
    return null;
  },
};

export default resourceGrabber;
