import { API } from "utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface ResponsePayload {
  response: {
    status?: number;
    message?: string;
  };
  updateReceivedAt: number;
}
interface ErrorPayload {
  response?: Error;
  updateReceivedAt?: number;
}

export const updateJobGateway = createAsyncThunk<
  ResponsePayload,
  void,
  {
    rejectValue: ErrorPayload;
  }
>("@JOBGATEWAY/REQUEST", (_, { rejectWithValue }) =>
  API.updateJobGatewayAPI()
    .then((response) => {
      return {
        response,
        updateReceivedAt: Date.now(),
      };
    })
    .catch((err) => {
      return rejectWithValue({
        response: {
          name: err.name,
          message: err.message,
          stack: err.stack,
        },
        updateReceivedAt: Date.now(),
      });
    })
);
