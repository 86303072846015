import { ReactNode } from "react";
import type {
  PropertyDocumentationAttachment,
  PropertyFlyerAttachment,
} from "../helpers/propertyAttachment.model";
import { PropertyAttachmentDossierSidebar } from "./PropertyAttachmentDossierSidebar";

interface PropertyAttachmentFlyerSidebarProps {
  selectedAttachment?:
    | PropertyFlyerAttachment
    | PropertyDocumentationAttachment;
  onChange: (updatedAttachment: PropertyFlyerAttachment) => void;
  children?: ReactNode;
}

export const PropertyAttachmentFlyerSidebar = ({
  selectedAttachment,
  onChange,
}: PropertyAttachmentFlyerSidebarProps) => {
  const handleUpdate = (update: Partial<PropertyFlyerAttachment>): void => {
    if (selectedAttachment && update) {
      onChange({ ...selectedAttachment, ...update });
    }
  };

  return (
    <PropertyAttachmentDossierSidebar
      onChange={handleUpdate}
      selectedAttachment={selectedAttachment}
    />
  );
};
