import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import type { UserSignatureSelectValueType } from "../MessageSignature/helpers/useMessageSignatures";
import {
  clearCorruptedSignatureTags,
  getInjectedBody,
} from "./signatureManager";
import type { MessageCreateFormShape } from "../MessageCreateForm";
import { useCallback } from "react";
import type { MessageTemplateSelectValueType } from "../MessageTemplate/helpers/useMessageTemplates";
import type { PropertySelectValueType } from "../../property-select/helpers/usePropertySelect";

export const useDecoratedHandlers = ({
  getValues,
  setValue,
}: {
  getValues: UseFormGetValues<MessageCreateFormShape>;
  setValue: UseFormSetValue<MessageCreateFormShape>;
}) => {
  const updateBodyWithSignature = useCallback(
    (signature: UserSignatureSelectValueType) => {
      const newBody = getInjectedBody({
        signatureText: signature?.signature,
        body: getValues("body"),
      });
      setValue("body", newBody, { shouldDirty: true, shouldValidate: true });
    },
    [setValue, getValues]
  );

  const updateBodyWithTemplate = useCallback(
    (template: MessageTemplateSelectValueType) => {
      const newBody = template?.html || "<p></p>";

      setValue("body", newBody, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue]
  );

  const updateSubject = useCallback(
    (newSubject: string | undefined) => {
      setValue("subject", newSubject, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [setValue]
  );

  const updateBodyToRemoveCorruptedTags = useCallback(() => {
    const newBody = clearCorruptedSignatureTags({ body: getValues("body") });
    if (newBody) {
      setValue("body", newBody, { shouldDirty: true, shouldValidate: true });
    }
  }, [setValue, getValues]);

  const onSignatureChange = useCallback(
    (
      signature: UserSignatureSelectValueType,
      onChange: (signature: UserSignatureSelectValueType) => void
    ) => {
      updateBodyWithSignature(signature);
      onChange(signature);
    },
    [updateBodyWithSignature]
  );

  const onSignatureLoad = useCallback(
    (signature: UserSignatureSelectValueType) => {
      updateBodyWithSignature(signature);
      setValue("signature", signature);
    },
    [updateBodyWithSignature, setValue]
  );

  const onTemplateChange = useCallback(
    (
      template: MessageTemplateSelectValueType,
      onChange: (template: MessageTemplateSelectValueType) => void
    ) => {
      const newSubject = template?.subject;
      updateSubject(newSubject);
      updateBodyWithTemplate(template);
      updateBodyWithSignature(getValues("signature"));
      onChange(template);
    },
    [updateSubject, updateBodyWithTemplate, updateBodyWithSignature, getValues]
  );

  const onBodyBlur = useCallback(() => {
    updateBodyToRemoveCorruptedTags();
  }, [updateBodyToRemoveCorruptedTags]);

  const onActionChange = useCallback(
    (value: string) => {
      if (!getValues("subject")) {
        updateSubject(value);
      }
    },
    [updateSubject, getValues]
  );

  const onAddReminderToggle = useCallback(
    (isOpen) => {
      if (isOpen) {
        return getValues("subject");
      }
    },
    [getValues]
  );

  const onPropertyChange = useCallback(
    (
      property: PropertySelectValueType | undefined,
      onChange: (newProperty: PropertySelectValueType | undefined) => void
    ) => {
      setValue("phase", undefined, { shouldDirty: true, shouldValidate: true });
      setValue("attachments", [], { shouldDirty: true, shouldValidate: true });
      setValue("propertyAttachments", [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      onChange(property);
    },
    [setValue]
  );

  return {
    signature: {
      onChange: onSignatureChange,
      onLoad: onSignatureLoad,
    },
    template: {
      onChange: onTemplateChange,
    },
    body: {
      onBlur: onBodyBlur,
    },
    action: {
      onChange: onActionChange,
    },
    addReminder: {
      onToggle: onAddReminderToggle,
    },
    property: {
      onChange: onPropertyChange,
    },
  };
};
