import * as React from "react";
import FieldContainerInterface from "./helpers/FieldContainerInterface";
import FieldContainerElemInterface from "./helpers/FieldContainerElemInterface";
import FieldContainer from "./helpers/FieldContainer";
import FieldElem from "./helpers/FieldElem";
import extractDataAttributes, {
  DataAttributes,
} from "@casasoft/styleguide/utilities/extractDataAttributes";

/**
 * Normal input Field
 */
interface FieldProps extends FieldContainerInterface, DataAttributes {
  /** shown to the left of the field */
  prefix?: string | React.ReactNode;
  /** shown to the right of the field */
  suffix?: string | React.ReactNode;
  /** use cs-input-group-btn instead of cs-input-group-addon for input group with prefix */
  prefixDropdown?: boolean;
  /** html type="" override */
  type?: string;
  /** minimum value? */
  min?: number;
  /** maximum value? */
  max?: number;
  /** label */
  label?: string;
}

const Field = ({
  prefix = undefined,
  suffix = undefined,
  prefixDropdown = undefined,
  type = "text",
  min = undefined,
  max = undefined,
  label = "",
  ...remainingProps
}: FieldProps) => {
  const [dataAttrs, fieldContainerProps] =
    extractDataAttributes(remainingProps);

  return (
    <FieldContainer
      {...fieldContainerProps}
      label={label}
      renderElem={(passedDownProps: FieldContainerElemInterface) => {
        return (
          <FieldElem
            {...passedDownProps}
            {...dataAttrs}
            label={label}
            prefix={prefix}
            suffix={suffix}
            prefixDropdown={prefixDropdown}
            type={type}
            min={min}
            max={max}
          />
        );
      }}
    />
  );
};

export default Field;
