import { createResource } from "redux-rest-resource";
import Config from "config";
import queryBuilder from "../../utilities/queryBuilder";
import ContactMortgageState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: ContactMortgageState,
    actions?: any
  ) => ContactMortgageState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "contactMortgage",
  pluralName: "contactMortgages",
  url: `${Config.apiUrl}/${Config.customerKey}/contact-mortgage/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (state.items) {
          const newState = Object.assign({}, state);
          const items = state.items.map((item: any) => {
            if (item.id === action.context.id) {
              return action.context;
            }
            return item;
          });
          newState.items = items;
          newState.lastUpdated = Date.now();
          return newState;
        }
        return state;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.contact_mortgage) {
          return { res, body: res.body._embedded.contact_mortgage };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        const cnxtOpts = Object.assign(
          {},
          {
            pageSize: 20,
            filter: [],
            orderBy: [],
          },
          contextOpts
        );
        const opts = Object.assign(
          {},
          {
            pageSize: 50,
            orderBy: [
              {
                type: "field",
                field: "endDate",
                direction: "asc",
              },
            ],
            filter: [],
          },
          {
            pageSize: cnxtOpts.pageSize,
            orderBy: [...cnxtOpts.orderBy],
            filter: [...cnxtOpts.filter],
          }
        );

        const query = queryBuilder.build(opts.orderBy, opts.filter, {
          pagesize: opts.pageSize,
          page: contextOpts.page,
          context: "contact-mortgage-list",
        });
        return query;
      },
    },
  },
});
