import { Preset } from "components/miscellaneous/FilterForm";
import { UserFilterPresets } from "redux/auth";
import cloneDeep from "lodash/cloneDeep";
import { useRef } from "react";
import { useAppSelector } from "redux/hooks";

const prepareFilterPresets = (
  usersFilterPresetList: NonNullable<UserFilterPresets[string]>,
  filterFormOptions?: Omit<Preset, "key">
) => {
  if (filterFormOptions) {
    // hydrate default filter form options into new presets
    // in the store we only store the filter preset key and value, w/out label, etc, so we have to hydrate the new presetlist with all the default values
    const filterPresets = usersFilterPresetList
      .map((storedPreset) => {
        const newPreset = cloneDeep({
          label: storedPreset.label,
          key: storedPreset.key,
          preset: {
            ...filterFormOptions.preset,
          },
        });
        Object.entries(storedPreset.preset).forEach((theStoredPreset) => {
          if (newPreset.preset[theStoredPreset[0]]) {
            newPreset.preset[theStoredPreset[0]].value = theStoredPreset[1];
          }
        });
        return newPreset;
      })
      //sort filterlist alphabeticaly
      .sort((presetA, presetB) => {
        const labelA = presetA.label.toUpperCase();
        const labelB = presetB.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });

    filterPresets.push({
      ...filterFormOptions,
      key: "create-new-filter",
      // key: filterForm.filterPresetGeneratedKey,
      preset: {
        ...filterFormOptions.preset,
      },
    });

    return filterPresets;
  }
};

function usePreparedFilterPresets(
  storeName: string,
  filterFormOptions?: Omit<Preset, "key">
) {
  const { auth } = useAppSelector((state) => ({ auth: state.auth }));

  const emptyArrayRef = useRef<[]>([]); // safety -> so filterPresets will fall back to a ref array instead of a new variable for every render

  const filterPresets =
    auth.profile.data.casaoneUser?.filterPresets[storeName] ||
    emptyArrayRef.current;

  return (
    prepareFilterPresets(filterPresets, filterFormOptions) ||
    emptyArrayRef.current
  );
}

export default usePreparedFilterPresets;
