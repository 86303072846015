import queryBuilder from "utilities/queryBuilder";
import { GetUserContactListQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";
import { getCasaiamRolesButReporter } from "utilities/type-helpers/userRoles";

const query = createQuery<GetUserContactListQueryParams>(
  ({ query, includeCasasoftUsers = false }) => {
    // hide casasoft users from customers

    const casasotUsersFilter = includeCasasoftUsers
      ? []
      : [
          {
            where: "and",
            field: "username",
            type: "notlike",
            value: "%@casasoft.%",
          } as const,
        ];

    const builtQuery = queryBuilder.buildToUrl(
      query.orderBy,
      [
        ...query.filter,
        {
          where: "and",
          field: "status",
          type: "neq",
          value: "ignore",
        },
        ...casasotUsersFilter,

        // CONTACT JOIN
        {
          type: "innerjoin",
          field: "contact",
          alias: "contact",
        },
      ],

      {
        context: "user-list",
        pagesize: query.pageSize,
        page: query.page,
      }
    );

    // Filtering out ROLE_CASAONE_REPORTER before sending the request
    const roles = getCasaiamRolesButReporter().join(",");

    return {
      url: `v2/user?roles=${roles}&${builtQuery}`,
      method: "GET",
    };
  }
);

export default query;
