export interface SubnavProps {
  items: JSX.Element[];
}

const Subnav = ({ items }: SubnavProps) => {
  const navItems = items?.map((item, key) => {
    return (
      <li key={key} className="app__sub-nav__item">
        {item}
      </li>
    );
  });

  return (
    <div className="app__sub-nav">
      <ol className="app__sub-nav__list">{navItems}</ol>
    </div>
  );
};

export default Subnav;
