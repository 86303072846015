import Button from "@casasoft/styleguide/components/forms/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@casasoft/styleguide/components/modal";
import { useTranslation } from "react-i18next";
import {
  LanguageGroupData,
  ReducedContactShape,
} from "../LanguageBreakdownContainer";
import { LanguageContactGroupRow } from "./LanguageContactGroupRow";
import { useEditContactModal } from "./helpers/useEditContactModal";
import { EditContactWarningModal } from "../EditContactWarningModal/EditContactWarningModal";

export interface LanguageContactGroupContainerProps {
  languageGroup?: LanguageGroupData;
  onClose: () => void;
}

export const LanguageContactGroupContainer = ({
  languageGroup,
  onClose,
}: LanguageContactGroupContainerProps) => {
  const { t } = useTranslation();
  const { selectedContact, handleEditContact, handleEditContactModalClose } =
    useEditContactModal();

  const contacts: ReducedContactShape[] = languageGroup?.contacts || [];

  return (
    <>
      <Modal isOpen={!!languageGroup} onClose={onClose}>
        <ModalHeader>
          {t("Contacts")} {languageGroup?.label}
        </ModalHeader>
        <ModalBody className="tw-p-0">
          {contacts.map((contact, index) => (
            <LanguageContactGroupRow
              contact={contact}
              onContactEdit={() => handleEditContact(contact)}
              isLast={index === contacts.length - 1}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button isPrimary isOutline={false} onClick={onClose}>
            {t("Back")}
          </Button>
        </ModalFooter>
      </Modal>
      <EditContactWarningModal
        contact={selectedContact}
        languageLabel={languageGroup?.label}
        onClose={handleEditContactModalClose}
      />
    </>
  );
};
