import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { TableListProps } from "./TableList";
import { useTranslation } from "react-i18next";
import Select from "../formElements/Select";
import { Pagination, PaginationItem } from "../pagination";

interface TableListFooterProps extends Pick<TableListProps, "components"> {
  nrOfPages: number;
  totalItems: number;
  curPage: number;
  pageSize: number;
  paginationOptions?: number[];
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  mini: boolean;
  isSmallMode: boolean;
}

function TableListFooter({
  nrOfPages,
  totalItems,
  curPage,
  pageSize,
  paginationOptions = [5, 10, 20, 30, 40, 50, 100],
  onPageChange,
  onPageSizeChange,
  mini,
  isSmallMode,
}: TableListFooterProps) {
  const { t } = useTranslation();

  let pages: number[] = [];
  if (nrOfPages > 1) {
    const centroid = curPage;
    const maxMiddleItems = 4;
    if (nrOfPages >= 3) {
      pages = [centroid];
      let curLayer = 0;
      let maxMiddleItemsReached = false;
      while (!maxMiddleItemsReached) {
        curLayer += 1;
        const leftNext = centroid - curLayer;
        if (leftNext >= 1) {
          pages.unshift(leftNext);
        }
        const rightNext = centroid + curLayer;
        if (rightNext <= nrOfPages) {
          pages.push(rightNext);
        }
        if (pages.length > maxMiddleItems || curLayer > maxMiddleItems) {
          maxMiddleItemsReached = true;
        }
      }
    } else {
      pages = Array.from(Array(nrOfPages), (x1, x) => x + 1);
    }
  }

  const pageNodes = pages.map((page) => {
    if (page === 0) {
      return (
        <PaginationItem key="prev" disabled aria-label="previous">
          <span aria-hidden="true">...</span>
        </PaginationItem>
      );
    }
    return (
      <PaginationItem
        key={page}
        active={curPage === page}
        onClick={(e) => {
          e.preventDefault();
          onPageChange(page);
        }}
      >
        {page}
      </PaginationItem>
    );
  });

  if (pageNodes.length) {
    if (curPage > 1) {
      pageNodes.unshift(
        <PaginationItem
          key="prev"
          aria-label="previous"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(curPage - 1);
          }}
        >
          <span aria-hidden="true">
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        </PaginationItem>
      );
    } else {
      pageNodes.unshift(
        <PaginationItem key="prev" disabled aria-label="previous">
          <span aria-hidden="true">
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        </PaginationItem>
      );
    }
    if (curPage < nrOfPages) {
      pageNodes.push(
        <PaginationItem
          key="next"
          aria-label="next"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(curPage + 1);
          }}
        >
          <span aria-hidden="true">
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </PaginationItem>
      );
    } else {
      pageNodes.push(
        <PaginationItem key="next" disabled aria-label="next">
          <span aria-hidden="true">
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </PaginationItem>
      );
    }
  }

  const paginationShowingFrom = curPage * pageSize - (pageSize - 1);
  let paginationShowingTo = curPage * pageSize;
  if (paginationShowingTo > totalItems) {
    paginationShowingTo = totalItems;
  }
  const paginationInfos = `${paginationShowingFrom
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, "'")} – ${paginationShowingTo
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, "'")} ${t("from")} ${
    totalItems === 1000 // a temporary workaround for the event list, since we are limiting it to exactly 1000 from the backend to make it faster
      ? "1'000+"
      : totalItems.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
  }`;
  return (
    <div
      key="footer"
      className={`tw-flex ${
        !isSmallMode ? "tw-flex-nowrap" : "tw-flex-wrap"
      } tw-border-0 tw-border-solid tw-border-t-2 tw-border-t-tw-cs-accent-color tw-pt-4 ${
        !mini ? "tw-px-4 tw--mx-4 tw-mb-4" : "tw-px-3 tw--mx-3"
      }`}
    >
      {paginationOptions && !mini && (
        <div
          className={`${
            isSmallMode ? "tw-w-full tw-mb-3" : "tw-flex-1"
          }  tw-text-left`}
        >
          <Select
            className="tw-mb-0 tw-max-w-[490px]"
            placeholder={paginationInfos}
            onChange={(value: string) => {
              onPageSizeChange(parseInt(value, 10));
            }}
            compact={isSmallMode}
            value={pageSize.toString()}
            renderValue={() => paginationInfos}
            options={paginationOptions.map((opt) => {
              return {
                value: opt.toString(),
                label: `${opt} ${t("per page")}`,
              };
            })}
            menuTop={true}
          />
        </div>
      )}
      {pageNodes.length > 0 && (
        <div
          style={{ width: "1", textAlign: "center" }}
          className={`${isSmallMode ? "tw-w-full" : "tw-flex-1"}`}
        >
          <Pagination>{pageNodes}</Pagination>
        </div>
      )}
      {paginationOptions && !mini && (
        <div className={`${isSmallMode ? "tw-hidden" : "tw-flex-1"}`} />
      )}
    </div>
  );
}

export default TableListFooter;
