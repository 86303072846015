import { API } from "utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface ResponsePayload {
  response: API.UpdateGatewayAPIResponse;
  updateReceivedAt: number;
}
interface UpdateGatewayArgs {
  companyId?: string;
  force?: boolean;
}
interface ErrorPayload {
  response?: Error;
  updateReceivedAt?: number;
}

export const updateGateway = createAsyncThunk<
  ResponsePayload,
  UpdateGatewayArgs,
  {
    rejectValue: ErrorPayload;
  }
>("@GATEWAY/REQUEST", (options, { rejectWithValue }) =>
  API.updateGatewayAPI(options?.companyId, options?.force)
    .then((response) => {
      return {
        response,
        updateReceivedAt: Date.now(),
      };
    })
    .catch((err: Error) => {
      return rejectWithValue({
        response: {
          name: err.name,
          message: err.message,
          stack: err.stack,
        },
        updateReceivedAt: Date.now(),
      });
    })
);
