import { ReactNode } from "react";

interface FormModalColsItemProps {
  children: ReactNode;
}

function FormModalColsItem({ children }: FormModalColsItemProps) {
  return <div className="tw-min-w-0">{children}</div>;
}

export default FormModalColsItem;
