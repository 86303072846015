import { createResource } from "redux-rest-resource";
import { mediaResource, ResourceTypes } from "entities/media/store";
import { cloneDeep } from "lodash";

const mediaChooserResource = cloneDeep(mediaResource);
mediaChooserResource.name = "mediaChooser";
mediaChooserResource.pluralName = "mediaChoosers";

export const { types, actions, rootReducer }: ResourceTypes =
  createResource(mediaChooserResource);
