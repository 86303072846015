import { ReactNode } from "react";

interface CardBodyColsItemProps {
  title?: ReactNode;
  span?: "full";
  children: ReactNode;
  onClick?: () => void;
}

function CardBodyColsItem({
  title,
  children,
  span,
  onClick,
}: CardBodyColsItemProps) {
  return (
    <div
      className={`tw-min-w-0 ${span === "full" ? "tw-col-span-full" : ""} ${
        onClick ? "tw-cursor-pointer" : ""
      } tw-overflow-hidden tw-text-ellipsis`}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick();
            }
          : undefined
      }
    >
      {!!title && (
        <>
          <span className="tw-text-cs-shade-500 tw-font-thin">{title}</span>
          <br />
        </>
      )}
      {children}
    </div>
  );
}

export default CardBodyColsItem;
