import { createTransformResponse } from "api/createEndpoint";
import { GetPhaseRelationshipResponse } from "./types";

const transformResponse = createTransformResponse<GetPhaseRelationshipResponse>(
  (response: any) => {
    return {
      property:
        response?._embedded?.property_contact?.[0]?._embedded?.property?.id,
      contact:
        response?._embedded?.property_contact?.[0]?._embedded?.contact?.id,
      latestPhase:
        response?._embedded?.property_contact?.[0]?._embedded?.phase?.id,
      hasRelationship: response?._embedded?.property_contact?.[0]
        ? true
        : false,
    };
  }
);

export default transformResponse;
