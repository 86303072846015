type NamedTag = {
  name: string;
};

export const convertStringToNamedTags = (
  tagsString: string | null | undefined
): NamedTag[] | undefined => {
  if (tagsString) {
    const splitTags = tagsString.split(",").filter((tag) => tag);
    const uniqueTags = new Set(splitTags);

    return Array.from(uniqueTags).map((tag) => {
      return { name: tag.trim() };
    });
  }

  return [];
};
