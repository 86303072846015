import { axiosInstance } from "utilities/axios";

interface DownloadArgs {
  toggleModalLoader?: (arg: string | null) => void;
  url: string;
  fileName: string;
  loadingMessage: string;
}

export function useDownload() {
  const downloadFile = async ({
    toggleModalLoader,
    url,
    fileName,
    loadingMessage,
  }: DownloadArgs) => {
    if (toggleModalLoader) {
      toggleModalLoader(loadingMessage);
    }

    try {
      const response = await axiosInstance.get(url, {
        responseType: "blob",
      });

      // download file
      const fileURL = URL.createObjectURL(response.data);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = fileURL;
      a.download = fileName;
      a.click();
    } catch (error) {
      // TODO: once we have an axiosInstance wrapper for error handling, use proper error handling here
      // eslint-disable-next-line no-console
      console.error(error);
      alert("Could not download file");
    } finally {
      if (toggleModalLoader) {
        toggleModalLoader(null);
      }
    }
  };

  return { downloadFile };
}
