import { CasaoneBaseQueryArgs } from "api/types";
import { MutateContactSingleQueryParams } from "./types";

function query(body: MutateContactSingleQueryParams): CasaoneBaseQueryArgs {
  return {
    url: `contact/${body.id}`,
    method: "PATCH",
    data: body,
  };
}

export default query;
