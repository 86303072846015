import { useRules } from "hooks/useRules";

export const useFormFieldRules = () => {
  const { generateRules } = useRules();

  return {
    contact: {
      rules: generateRules(["required"]),
    },
    eventBaseType: {
      rules: generateRules(["required"]),
    },
    subject: {
      rules: generateRules(["required"]),
    },
    html: {
      rules: generateRules(["required"]),
    },
  };
};
