import { CountBubble } from "@casasoft/styleguide/components/helpers/Bubbles/CountBubble";
import { faPaperclip, faFileCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MessageMediaContainer } from "../../message-media/MessageMediaContainer";
import { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { PropertyAttachment } from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/helpers/propertyAttachment.model";

export type MessageAttachment = {
  rank: number;
  media: any;
  alt: string;
  title: string;
};

interface MessageAttachFilesControlProps {
  attachments: MessageAttachment[];
  selectedProperty?: PropertyListReducedShape;
  onSubmit: (attachments: MessageAttachment[]) => void;
  propertyAttachments: PropertyAttachment[];
  onSubmitPropertyAttachments: (attachments: PropertyAttachment[]) => void;
}

export const MessageAttachFilesControl = ({
  attachments,
  onSubmit,
  selectedProperty,
  propertyAttachments,
  onSubmitPropertyAttachments,
}: MessageAttachFilesControlProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen((isOpen) => !isOpen);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = (data: any) => {
    setIsModalOpen(false);
    onSubmit(data);
  };

  const attachmentsCount = attachments.length + propertyAttachments.length;

  return (
    <div className="tw-cursor-pointer tw-mr-3 tw-flex" onClick={handleClick}>
      <CountBubble
        color="danger"
        count={attachmentsCount}
        correctionRight={0}
        show={attachmentsCount > 0}
      >
        <FontAwesomeIcon
          size="lg"
          icon={attachments.length > 0 ? faFileCheck : faPaperclip}
          className="tw-mr-3 tw-text-tw-cs-accent-color"
        />
      </CountBubble>
      {t("Attach files/Flyer/Documentation")}
      <MessageMediaContainer
        attachments={attachments}
        isModalOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        selectedProperty={selectedProperty}
        propertyAttachments={propertyAttachments}
        onSubmitPropertyAttachments={onSubmitPropertyAttachments}
      />
    </div>
  );
};
