import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "full",
    label: "Fully developed",
  },
  {
    value: "part",
    label: "Partialy Developed",
  },
  {
    value: "undeveloped",
    label: "Undeveloped",
  },
];

export const getPropertyDevelopments = createGetList(untranslatedList);
export const getLabel = createGetLabel(untranslatedList);

export const usePropertyDevelopments = createUtilityHook(untranslatedList);
