import { GetPropertyListReducedResponse } from "api/entities/property/endpoints/list-reduced/types";
import PropertyExcerpt from "components/property/PropertyExcerpt";
import { useTranslation } from "react-i18next";
import { AddressHelper, MarketingMethods, Numvals } from "utilities";
import { renderPrice } from "utilities/price";
import { getPropertySizes } from "../helpers/propertySizesHelper";

interface UseItemsArgs {
  mini: boolean;
  propertyItems?: GetPropertyListReducedResponse["items"];
  listContext?: string;
}

function useItems({ mini, propertyItems, listContext }: UseItemsArgs) {
  const { t } = useTranslation();

  if (mini) {
    return propertyItems?.map((elem) => {
      const excerpt = (
        <PropertyExcerpt lines={3} entity={elem} showThumbnail small />
      );
      return {
        item_key: elem.id,
        id: elem.id,
        propertyType: elem.propertyType,
        marketingMethod: elem.marketingMethod,
        excerpt,
      };
    });
  } else {
    return propertyItems?.map((elem) => {
      const excerpt = (
        <PropertyExcerpt
          entity={elem}
          lines={4}
          showThumbnail
          showType={listContext === "properties-modal"}
        />
      );

      let addressRender = null;
      if (elem._embedded && elem._embedded.address) {
        addressRender = AddressHelper.renderAddress(elem._embedded.address);
      }

      let priceObject;
      let subTitle = "";
      if (elem.marketingMethod === "buy") {
        priceObject = {
          price: elem.price,
          time_segment: elem.priceTimeSegment,
          property_segment: elem.pricePropertySegment,
          currency: elem.priceCurrency,
        };
        subTitle = t("standards:Price");
      } else if (elem.marketingMethod === "rent") {
        if (elem.grossPrice && elem.grossPrice > 0) {
          priceObject = {
            price: elem.grossPrice,
            time_segment: elem.rentPriceTimeSegment,
            property_segment: elem.rentPricePropertySegment,
            currency: elem.priceCurrency,
          };
          subTitle = t("standards:Gross price");
        } else {
          priceObject = {
            price: elem.netPrice,
            time_segment: elem.rentPriceTimeSegment,
            property_segment: elem.rentPricePropertySegment,
            currency: elem.priceCurrency,
          };
          subTitle = t("standards:Net price");
        }
      } else {
        priceObject = {
          price: null,
          time_segment: null,
          property_segment: null,
        };
        subTitle = t("Marketing Method is missing");
      }
      let premiumR = null;
      if (elem.grossPremium) {
        premiumR = (
          <span>
            {Numvals.numvalLabel("grossPremium")}:{" "}
            {Numvals.numvalRender(elem, "grossPremium")}
            <br />
          </span>
        );
      }
      if (elem.netPremium) {
        premiumR = (
          <span>
            {Numvals.numvalLabel("netPremium")}:{" "}
            {Numvals.numvalRender(elem, "netPremium")}
            <br />
          </span>
        );
      }
      const priceRendered = (
        <p key="price">
          {premiumR}
          {elem.marketingMethod
            ? MarketingMethods.getLabel(elem.marketingMethod)
            : ""}
          <br />
          {renderPrice(priceObject)}
          <br />
          <span className="tw-text-cs-shade-500">{subTitle}</span>
        </p>
      );

      return {
        item_key: elem.id,
        id: elem.id,
        excerpt,
        address: addressRender,
        price: priceRendered,
        marketingMethod: elem.marketingMethod,
        sizes: getPropertySizes({ property: elem }),
      };
    });
  }
}

export default useItems;
