import { createResource } from "redux-rest-resource";
import ContactRelationShape from "./types";
import createResourceHelper from "utilities/createResourceHelper";
import createBaseActions from "entities/createBaseActions";
import Config from "config";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: ContactRelationShape,
    actions?: any
  ) => ContactRelationShape;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "mainContactRelation",
  pluralName: "mainContactRelations",
  url: `${Config.apiUrl}/${Config.customerKey}/contact-relation/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "contact_relation",
      forcedPageSize: 50,
      forcedOrderBy: [
        {
          type: "field",
          field: "created",
          direction: "desc",
        },
      ],
    }),
  },
});

const mainContactRelationResourceCreator = createResourceHelper({
  actions,
  name: "mainContactRelation",
  pluralName: "mainContactRelations",
});

export const mainContactRelationResource =
  mainContactRelationResourceCreator.resource;
export const useMainContactRelation =
  mainContactRelationResourceCreator.createHook();
