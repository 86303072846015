interface PropertyAttachmentSeparatorProps {
  label: string;
  show?: boolean;
}

export const PropertyAttachmentSeparator = ({
  label,
  show = true,
}: PropertyAttachmentSeparatorProps) => {
  const isVisible = show && !!label;

  return isVisible ? (
    <div className="tw-border-solid tw-border-0 tw-border-b-[1px] tw-border-cs-shade-100 tw-p-3 tw-font-semibold">
      {label}
    </div>
  ) : null;
};
