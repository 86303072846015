import { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { FormatOptionLabelMeta } from "reactSelectNew";
import { MessagingSelectLoading } from "../../../../miscellaneous/MessagingSelectLoading";
import { SelectPropertyOption } from "../SelectPropertyOption";
import { usePropertySelectDataFetch } from "./usePropertySelectDataFetch";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@casasoft/styleguide/components/tooltip";

type BaseValueType = { value: string; property?: PropertyListReducedShape };
export type PropertySelectValueType = BaseValueType;

interface UsePropertySelectProps {
  onChange?: (newValue: BaseValueType | undefined) => void;
}

export const usePropertySelect = ({ onChange }: UsePropertySelectProps) => {
  const { t } = useTranslation();
  const { fetchPropertiesBySearchTerm, isError } = usePropertySelectDataFetch();

  const formatOptionLabel = (
    data: BaseValueType | undefined,
    meta: FormatOptionLabelMeta<BaseValueType>
  ) => {
    if (!data || !data.property) {
      return <MessagingSelectLoading />;
    }
    const propertyTitle =
      data.property._embedded?.contents?.[0].name || t("No title");

    // context "menu" is dropdown option, context "input" is selected option in input
    return meta.context === "menu" ? (
      <SelectPropertyOption property={data.property} />
    ) : (
      <Tooltip
        content={
          <button type="button">
            <SelectPropertyOption property={data.property} />
          </button>
        }
        triggerAsChild
      >
        <div className="tw-relative">{propertyTitle}</div>
      </Tooltip>
    );
  };

  const loadOptions = async (searchTerm: string) => {
    const fullItems = await fetchPropertiesBySearchTerm({
      searchTerm,
    });

    return (fullItems || []).map((fullItem) => ({
      value: fullItem.id,
      property: fullItem,
    }));
  };

  const loadingMessage = () => <MessagingSelectLoading />;

  const noOptionsMessage = isError ? () => t("Error") : undefined;

  const onChangeHandler = (newValue: BaseValueType | null) => {
    if (onChange) {
      onChange(newValue === null ? undefined : newValue);
    }
  };

  return {
    formatOptionLabel,
    loadOptions,
    loadingMessage,
    noOptionsMessage,
    onChangeHandler,
  };
};
