import { ReactNode, useState } from "react";
import { Dropdown, DropdownProps } from "../dropdown";
import Field from "../formElements/Field";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faAngleDoubleRight } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { BaseButtonStyles } from "../forms/Button";
import ButtonGroup from "../forms/ButtonGroup";

export interface SearchField {
  key?: string;
  label?: string;
}

export interface TableListActionsProps {
  /**
   * Main action for all of the list.
   * Requires the "actions" component to be enabled.
   * */
  mainActionNode?: ReactNode;
  /**
   * Main actions for all of the list inside the dropdown.
   * Requires the "actions" component to be enabled.
   * */
  mainActionNodes?: DropdownProps["items"];
  /** searchFields array of key and label. The key "all" is reserved to search all fields (similar to no searchFields at all) */
  searchFields?: SearchField[];
  /** If set a filter form is rendered based on the prop */
  filterForm?: ReactNode;
  /** If set a filter form select is rendered based on the prop */
  filterFormSelect?: ReactNode;

  showSearch: boolean;
  showFilter: boolean;
  showActions: boolean;

  currentSearchField: SearchField;
  defaultSearchValue?: string;
  onSearchFieldChange: (searchField: SearchField) => void;
  onDelaySearchChange: (search: string, searchField?: string) => void;

  isSmallMode: boolean;
  mini: string | false;
}

function TableListActions({
  searchFields = [],
  filterForm,
  filterFormSelect,
  mainActionNode,
  mainActionNodes = [],

  currentSearchField,
  defaultSearchValue = "",
  onSearchFieldChange,
  onDelaySearchChange,

  showSearch,
  showFilter,
  showActions,

  isSmallMode,
  mini,
}: TableListActionsProps) {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState(defaultSearchValue);

  const actionsRowItemClasses = ` ${
    isSmallMode
      ? "tw-w-full tw-m-0"
      : "tw-flex-1 tw-max-w-[490px] tw-mr-3 last:tw-ml-auto first:last:tw-ml-0 tw-m-0 last:tw-mr-0"
  } 
    ${isSmallMode || mini ? "tw-mb-3" : "tw-mb-4"}`;

  const hasActions =
    showActions && !!(mini || mainActionNode || mainActionNodes.length);

  if (!showSearch && !showFilter && !hasActions) {
    return <></>;
  }

  return (
    <div className={`${!mini ? "tw-pb-4" : "tw-pb-3"}`}>
      <div
        key="actions"
        className={`tw-flex ${isSmallMode ? "tw-flex-wrap" : "tw-flex-nowrap"}`}
      >
        {!!(showFilter && filterFormSelect) && (
          <div className={`${actionsRowItemClasses}`}>{filterFormSelect}</div>
        )}
        {showSearch && (
          <div key="search" className={`${actionsRowItemClasses}`}>
            <Field
              className="tw-m-0"
              placeholder={t("Search")}
              onDelayDuration={500}
              onDelayChange={(value) => {
                onDelaySearchChange(value, currentSearchField.key);
              }}
              prefix={
                searchFields.length ? (
                  <Dropdown
                    toggleBtnProps={{ isLight: true, isOutline: true }}
                    label={currentSearchField.label}
                    items={searchFields.map((type) => ({
                      label: type.label,
                      onItemClick: () => {
                        onSearchFieldChange(type);
                        onDelaySearchChange(searchValue, type.key);
                      },
                    }))}
                  />
                ) : null
              }
              suffix={<FontAwesomeIcon icon={faSearch} />}
              prefixDropdown={true}
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value || "");
              }}
            />
          </div>
        )}
        {hasActions && (
          <div
            key="actions"
            className={`${actionsRowItemClasses} ${mini ? "tw-grow-0" : ""}`}
          >
            <ButtonGroup>
              {mini ? (
                <BaseButtonStyles isSecondary>
                  {(className) => (
                    <Link className={className} to={mini}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Link>
                  )}
                </BaseButtonStyles>
              ) : (
                mainActionNode
              )}
              {mainActionNodes.length ? (
                <Dropdown
                  items={mainActionNodes}
                  openOriginPreference="topright"
                  toggleBtnProps={{ isPrimary: true }}
                />
              ) : (
                <></>
              )}
            </ButtonGroup>
          </div>
        )}
      </div>
      {showFilter && filterForm}
    </div>
  );
}

export default TableListActions;
