export const colorVarNamesMap = [
  "--tw-cs-accent-color-light",
  "--tw-cs-accent-color",
  "--tw-cs-accent-color-dark",
  "--cs-shade-white",
  "--cs-shade-black",
  "--cs-shade-50",
  "--cs-shade-100",
  "--cs-shade-200",
  "--cs-shade-300",
  "--cs-shade-400",
  "--cs-shade-500",
  "--cs-shade-600",
  "--cs-shade-700",
  "--cs-shade-800",
  "--cs-shade-900",
  "--cs-shade-1000",
  "--cs-success",
  "--cs-success-dark",
  "--cs-warning",
  "--cs-danger",
  "--cs-danger-dark",
  "--cs-shadow-color-100",
  "--cs-shadow-color-200",
  "--cs-sticky-note-100",
  "--cs-sticky-note-200",
  "--cs-sticky-note-300",
] as const;

export type ColorVarNames = typeof colorVarNamesMap[number];
