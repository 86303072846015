const mailProviderUtility = {
  getProviderSettings() {
    return [
      {
        provider: "smtp.nine.ch",
        delayAmount: 6000,
        sendLimit: 200,
      },
      {
        provider: "smtp.office365.com",
        delayAmount: 2000,
        sendLimit: 200,
      },
      {
        provider: "smtp.gmail.com",
        delayAmount: 2000,
        sendLimit: 200,
      },
      {
        provider: "smtp.mandrillapp.com",
        delayAmount: 2000,
        sendLimit: 500,
      },
    ];
  },
  getThrottle(provider) {
    const throttle = this.getProviderSettings().find(
      (elem) => elem.provider === provider
    );
    if (throttle) {
      return throttle.delayAmount;
    }
    return 2000;
  },
  getSenderLimit(provider) {
    const throttle = this.getProviderSettings().find(
      (elem) => elem.provider === provider
    );
    if (throttle) {
      return throttle.sendLimit;
    }
    return 200;
  },
};

export default mailProviderUtility;
