import useForm, { RegisterFields } from "hooks/useForm";
import { AddReminderFormFooter } from "./AddReminderFormFooter";
import csMoment from "utilities/csMoment";
import { useTranslation } from "react-i18next";
import Field from "@casasoft/styleguide/components/formElements/Field";
import { ErrorMessage } from "@hookform/error-message";
import DateField from "@casasoft/styleguide/components/formElements/DateField";
import Tinymce from "@casasoft/styleguide/components/formElements/tinymce/Tinymce";
import { SubmitHandler } from "react-hook-form";
import UserContactSelectContainer from "components/admin/user/features/user-contact-select/UserContactSelectContainer";
import { useFormFieldRules } from "./helpers/useFormFieldRules";
import { isEmptyAddReminderForm } from "./helpers/isEmptyAddReminderForm";
import { tinymceManager } from "@casasoft/styleguide/components/formElements/tinymce/tinymceManager";

interface AddReminderProps {
  onSubmit: SubmitHandler<AddRemainderFormShape>;
  onClear: () => void;
  currentUserId: string | undefined;
  data: AddRemainderFormShape | undefined;
  messageSubject?: string;
}

export interface AddRemainderFormShape {
  subject?: string;
  date?: string;
  hour?: string;
  notes?: string;
  user?: string;
}

export const AddReminderForm = ({
  onSubmit,
  onClear,
  currentUserId,
  data,
  messageSubject,
}: AddReminderProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<AddRemainderFormShape>({
    defaultValues: isEmptyAddReminderForm(data)
      ? {
          subject: messageSubject || "",
          date: csMoment().add(1, "day").format("YYYY-MM-DD"),
          hour: "",
          notes: "",
          user: currentUserId || "",
        }
      : (data as AddRemainderFormShape),
  });
  const fieldRules = useFormFieldRules();

  return (
    <RegisterFields
      control={control}
      fields={fieldRules}
      render={(fieldsRenderer) => (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:tw-grid tw-gap-2 tw-grid-cols-[1fr_230px_230px]">
            {fieldsRenderer("subject", (formValue, onFormValueChange) => (
              <Field
                nobox
                label={t("Subject")}
                value={formValue || t("Reminder")}
                onChange={onFormValueChange}
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="subject" />,
                }}
              />
            ))}
            {fieldsRenderer("date", (formValue, onFormValueChange) => (
              <DateField
                nobox
                label={t("Date")}
                value={formValue || ""}
                onChange={(value) => {
                  onFormValueChange(value);
                }}
                rangeStart={csMoment(formValue)
                  .subtract(1, "day")
                  .format("YYYY-MM-DD HH:mm:ss")}
                locale={csMoment().locale()}
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="date" />,
                }}
              ></DateField>
            ))}
            {fieldsRenderer("hour", (formValue, onFormValueChange) => (
              <Field
                nobox
                label={t("Time")}
                value={formValue || undefined}
                onChange={(value: string) => {
                  onFormValueChange(value?.replace(".", ":"));
                }}
                placeholder="HH:MM"
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="hour" />,
                }}
              ></Field>
            ))}
            {fieldsRenderer("notes", (formValue, onFormValueChange) => (
              <Tinymce
                id="reminderNotes"
                label={t("Notes")}
                value={formValue || "&#8194;"}
                onChange={(value) => {
                  onFormValueChange(value || "");
                }}
                className="tw-col-span-3"
                rows={6}
                showToolbar={true}
                tinymceConfig={{
                  inline: false,
                  default_link_target: "_blank",
                  link_assume_external_targets: true,
                  target_list: false,
                  rel_list: [{ title: "nofollow", value: "nofollow" }],
                  quickbars_insert_toolbar: false,
                  toolbar: tinymceManager.getToolbar({ toolbar: "long" }),
                  toolbar_persist: true,
                  fixed_toolbar_container:
                    ".tinymceToolbarContainerAddReminder",
                }}
              />
            ))}
            {fieldsRenderer("user", (formValue, onFormValueChange) => (
              <UserContactSelectContainer
                label={t("User")}
                value={
                  formValue
                    ? {
                        value: formValue,
                      }
                    : undefined
                }
                onChange={(value) => {
                  onFormValueChange(value?.value);
                }}
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="user" />,
                }}
                isClearable={true}
              />
            ))}
          </div>
          <AddReminderFormFooter
            onClear={onClear}
            isDisabled={!isDirty}
            isUpdate={!isEmptyAddReminderForm(data)}
          />
        </form>
      )}
    ></RegisterFields>
  );
};
