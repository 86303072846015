import * as React from "react";
import {
  components as ReactSelectComponent,
  type MultiValueRemoveProps,
} from "react-select";

interface Props {
  props: MultiValueRemoveProps<any>;
}

const ReactSelectComponentMultiValueRemove: React.FC<Props> = ({
  props,
}: Props) => {
  return (
    ReactSelectComponent.MultiValueRemove && (
      <ReactSelectComponent.MultiValueRemove {...props}>
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
          <g
            id="carrot"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
          >
            <path stroke="#000000" d="M6,6 L14,14" id="Path-2"></path>
            <path
              d="M6,6 L14,14"
              id="Path-2"
              stroke="#000000"
              transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) "
            ></path>
          </g>
        </svg>
      </ReactSelectComponent.MultiValueRemove>
    )
  );
};

export default ReactSelectComponentMultiValueRemove;
