import {
  IaziPropertySettings,
  type IaziPropertySettingsProps,
} from "./IaziPropertySettings";
import { BASE_DATA_PROPERTY } from "utilities/propertyTypes";
import { hasAccessTo } from "utilities/featureFlags";

interface IaziPropertySettingsControlProps extends IaziPropertySettingsProps {
  propertyType: string;
}

export const IaziPropertySettingsControl = ({
  propertyType,
  ...rest
}: IaziPropertySettingsControlProps) => {
  const isBaseProperty = propertyType === BASE_DATA_PROPERTY;
  const hasAccess = hasAccessTo("iaziPropertyCreation");
  const addressIsDefined = rest.address?.lat && rest.address?.lon;

  const featureEnabled = hasAccess && isBaseProperty && addressIsDefined;

  if (!featureEnabled) {
    return null;
  }

  return <IaziPropertySettings {...rest} />;
};
