import { Card, CardHeader } from "@casasoft/styleguide/components/card";
import { useTranslation } from "react-i18next";
import { TableList } from "@casasoft/styleguide/components/table-list";
import { ReactNode } from "react";

export interface HelpcenterArticlesCardProps {
  articles: { item_key: string; title: ReactNode; content: ReactNode }[];
  activeTopic?: string;
  isLoading: boolean;
  onArticleSelect: (newSelectedArticle: string | undefined) => void;
}

const HelpcenterArticlesCard = ({
  articles,
  isLoading,
  onArticleSelect,
  activeTopic,
}: HelpcenterArticlesCardProps) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <div className="tw-pr-4">
          {`${t("Recent questions about")} ${activeTopic}`}
        </div>
      </CardHeader>
      <TableList
        itemsLoaded={!isLoading}
        loading={isLoading}
        pageSize={6}
        total={articles.length}
        onItemClick={(item) => {
          onArticleSelect(item.item_key);
        }}
        components={[]}
        cols={[
          {
            key: "title",
            label: t("Title"),
            maxWidth: "100%",
          },
        ]}
        items={articles}
      />
    </Card>
  );
};

export default HelpcenterArticlesCard;
