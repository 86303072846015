import Field from "../forms/Field";
import Select from "../formElements/Select";
import tag from "./MediaTagInterface";
import { useTranslation } from "react-i18next";
import { MediaShape } from "entities/mediaChooser/types";
import {
  useCreateMediaTagMutation,
  useDeleteMediaTagMutation,
  useGetDistinctMediaTagListQuery,
} from "api/entities/mediaTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/pro-light-svg-icons";
import { MediaTagSingleCreateShape } from "api/entities/mediaTag/endpoints/create-single/types";
import { MediaTagSingleDeleteShape } from "api/entities/mediaTag/endpoints/delete-single/types";

interface MediaChooserSelectedItemsProps {
  medias: MediaShape[] | undefined;
  mediasCommonTags: tag[];
  mediasSuggestedTags: tag[];
  onSelectedItemsChange: (items: MediaShape[]) => Promise<any>;
  onSelectedItemsSave: () => void;
  isClearable: boolean;
}

const MediaChooserSelectedItems = ({
  medias = undefined,
  mediasSuggestedTags = [],
  mediasCommonTags = [],
  onSelectedItemsChange = (items) => new Promise(() => undefined),
  onSelectedItemsSave = () => undefined,
  isClearable = false,
}: MediaChooserSelectedItemsProps) => {
  const { t } = useTranslation();
  const getMediaTagListResult = useGetDistinctMediaTagListQuery(undefined);
  const [createMediaTag] = useCreateMediaTagMutation();
  const [deleteMediaTag] = useDeleteMediaTagMutation();
  const __getMediaTagOptions = () => {
    let suggestedTagOptions: tag[] = [];
    const mediaTags: tag[] =
      getMediaTagListResult.data?.mediaTags &&
      getMediaTagListResult.data?.mediaTags?.length > 0
        ? getMediaTagListResult.data?.mediaTags
            .map((x) => {
              return {
                value: x.name,
                label: x.name,
                type: <FontAwesomeIcon icon={faTag} fixedWidth />,
              };
            })
            .filter(
              (mediaTag) =>
                mediasSuggestedTags.findIndex(
                  (suggestedTag) => mediaTag.value === suggestedTag.value
                ) === -1
            )
        : [];

    if (mediasSuggestedTags) {
      suggestedTagOptions = mediasSuggestedTags.map((x) => {
        return {
          value: x.value,
          label: x.value,
          type: x.type,
        };
      });
    }
    return [...suggestedTagOptions, ...mediaTags];
  };
  let selectedItemsNode = <></>;
  const MediaTagOptions = __getMediaTagOptions();
  const triggerLoadOptions = MediaTagOptions.map(
    (singleTag) => singleTag.value
  ).join("");
  if (medias && medias.length && medias[0] !== undefined) {
    selectedItemsNode = (
      <div>
        {medias.length < 2 && (
          <Field
            nobox
            id="name"
            label={t("Name")}
            value={medias[0].name || medias[0].originalFilename}
            placeholder={medias[0].originalFilename}
            onChange={(value) => {
              const newMedias = medias.map((item: MediaShape) => {
                return { ...item, name: value };
              });
              onSelectedItemsChange(newMedias);
            }}
            onDelayDuration={1000}
            onDelayChange={(value) => {
              let change = false;
              if (change) {
                onSelectedItemsSave();
              }
            }}
          />
        )}
        <Select
          nobox
          isCreatable
          isMulti
          isClearable={isClearable}
          // name="item-tags"
          key={triggerLoadOptions}
          id="tags"
          label={medias.length < 2 ? t("Tags") : t("Common tags")}
          value={mediasCommonTags.map((singleTag) => singleTag.value).join(",")}
          options={MediaTagOptions as any}
          renderValue={(option) => {
            return option.label;
          }}
          renderOption={(option) => {
            return (
              <div>
                {option.type && (
                  <span className="tw-text-cs-shade-500 tw-mr-2">
                    {option.type}
                  </span>
                )}
                {option.label}
              </div>
            );
          }}
          onSelectionChange={(selection: any, action: any) => {
            const tagsToAdd: tag[] = [];
            const tagsToRemove: tag[] = [];
            if (action.action === "remove-value") {
              tagsToRemove.push(action.removedValue);
            } else if (action.action === "select-option") {
              tagsToAdd.push(action.option);
            } else if (action.action === "clear") {
              mediasCommonTags.forEach((mediasCommonTag) =>
                tagsToRemove.push(mediasCommonTag)
              );
            } else if (action.action === "create-option") {
              if (selection && selection.length) {
                const newTag = selection.filter(
                  (selectionItem: tag) =>
                    mediasCommonTags.findIndex(
                      (mediaCommonTag: tag) =>
                        mediaCommonTag.value === selectionItem.value
                    ) === -1
                );
                if (newTag.length) {
                  tagsToAdd.push(newTag[0]);
                }
              }
            }

            // todo: switch to bulk create once BE fixed it -> https://casasoftag.atlassian.net/browse/CASAONE-7331
            const tagsToCreate: MediaTagSingleCreateShape[] = [];
            medias.forEach((media) => {
              tagsToAdd.forEach((addTag) => {
                tagsToCreate.push({
                  media: media.id,
                  name: addTag.value,
                });
              });
            });

            const tagsToDelete: MediaTagSingleDeleteShape[] = [];
            medias.forEach((media) => {
              const mediaTags = media._embedded?.tags;
              tagsToRemove.forEach((removeTag) => {
                const tagid = mediaTags?.find(
                  (tag) => tag.name === removeTag.value
                )?.id;
                if (tagid) {
                  tagsToDelete.push({
                    id: tagid,
                    media: media.id,
                    name: removeTag.value,
                  });
                }
              });
            });

            const createTagPromises = tagsToCreate.map((tagToCreate) =>
              createMediaTag(tagToCreate)
            );
            // get 204 but not working. BE issue
            const deleteTagPromises = tagsToDelete.map((tagToDelete) =>
              deleteMediaTag(tagToDelete)
            );
            Promise.all([...createTagPromises, ...deleteTagPromises]).then(
              () => {
                onSelectedItemsSave();
              }
            );
          }}
        />{" "}
      </div>
    );
  }
  return <div className="media-chooser__selection"> {selectedItemsNode} </div>;
};

export default MediaChooserSelectedItems;
