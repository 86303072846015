import { ReactNode } from "react";

export interface PointsShape {
  key: string;
  label: string;
  value: string | number | undefined | null;
  renderedValue?: ReactNode;
  customComparison?: (
    pointA?: PointsShape,
    pointB?: PointsShape
  ) => PointsShape;
}

function differ(points: PointsShape[], originPoints: PointsShape[]) {
  const allTheKeys = [
    ...points.map((point) => point.key),
    ...originPoints.map((originPoint) => originPoint.key),
  ];
  const allTheKeysUnique = Array.from(new Set(allTheKeys));

  // const allTheKeys = [...];
  const combined = allTheKeysUnique.map((key) => {
    const point = points.find((point) => key === point.key);
    const originPoint = originPoints.find(
      (originPoint) => key === originPoint.key
    );

    if (point?.customComparison) {
      return point.customComparison(point, originPoint);
    } else if (originPoint?.customComparison) {
      return originPoint.customComparison(point, originPoint);
    }

    // point was deleted (exists on origin)
    if (
      !point?.value &&
      point?.value !== 0 &&
      (originPoint?.value || originPoint?.value === 0)
    ) {
      return {
        ...originPoint,
        renderedValue: (
          <div>
            <del className="tw-text-cs-danger">
              {originPoint.renderedValue || originPoint.value}
            </del>
          </div>
        ),
      };
    }

    // point was added (does not exist on origin)
    if (
      !originPoint?.value &&
      originPoint?.value !== 0 &&
      (point?.value || point?.value === 0)
    ) {
      return {
        ...point,
        renderedValue: (
          <div>
            {point.renderedValue || point.value}{" "}
            <span className="tw-text-cs-success">(+)</span>
          </div>
        ),
      };
    }

    // point was modified (exists on both, but is not equel)
    if (
      (point?.value || point?.value === 0) &&
      (originPoint?.value || originPoint?.value === 0) &&
      point.value !== originPoint.value
    ) {
      return {
        ...point,
        renderedValue: (
          <div>
            {point.renderedValue || point.value}{" "}
            <del className="tw-text-cs-danger">
              {originPoint.renderedValue || originPoint.value}
            </del>
          </div>
        ),
      };
    }

    // point exists but none have a real value
    if (
      !point?.value &&
      point?.value !== 0 &&
      !originPoint?.value &&
      originPoint?.value !== 0
    ) {
      if (originPoint) {
        return originPoint;
      } else if (point) {
        return point;
      }
    }

    if (!point) {
      throw new Error("You broke the universe, an impossible error happened");
    }

    // the point was not modified and exists on both
    return point;
  });

  return combined;
}

export default differ;
