/* eslint class-methods-use-this: "off" */

import { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "@casasoft/styleguide/components/helpers-ux";

/*
  Needs the following defined

  **Extending Class:**

  in general the data should be flattened for hydration and un-flattened for persisting

  this.defaultData - all fields that are used in the Form and their defaults

  __getPayload - function to grab actual Data should be flattened and normalized - can default to defaultData

  __persistData - function that gets called with the providing data - here needs to be the logic to save the entity/entities split the data etc

 - function to clean Values (empty === null || number as string convert to number etc..)
                                      and flatten structure

  entityStore - **disabled atm since it won't work properly without more investigation** tore of the Entity - this is optional

  **Wrapper Component**

  save - boolean to trigger the submit from outside

*/

/**
 * @deprecated please follow the new guidelines: https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-forms-and-useform--page
 */
class FormBase extends Component {
  static getDerivedStateFromProps(incommingProps, state) {
    const resultState = {};
    if (incommingProps.save === true && state.saving === false) {
      resultState.saving = true;
    }
    if (incommingProps.id !== state.id) {
      resultState.working = true;
      resultState.payload = null;
      resultState.dirtyFields = [];
      resultState.id = incommingProps.id;
    }
    if (Object.keys(resultState).length === 0) {
      return null;
    }
    return resultState;
  }

  defaultData = {};

  componentDidMount() {
    if (this.__getPayload) {
      this.__getPayload();
    }
    if (this.__afterComponentDidMount) {
      this.__afterComponentDidMount();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.save !== prevProps.save && this.props.save === true) {
      this.props.onReady?.(false);
      this.__handleSubmit();
    }
  }

  __flattenAndCleanPayload(payload) {
    if (payload) {
      const data = Object.assign({}, this.defaultData, payload);

      // items that are null should be rehydrated with their default values
      Object.keys(data).forEach((key) => {
        if (data[key] === null && this.defaultData[key] !== undefined) {
          data[key] = this.defaultData[key];
        }
      });

      return data;
    }
    return this.defaultData;
  }

  __hydrateForm(data) {
    this.setState({ data });
  }

  __receivePayload(payload) {
    const data = {};
    Object.entries(payload)
      .filter((elem) => this.defaultData[elem[0]] !== undefined)
      .forEach((item) => {
        data[item[0]] = item[1];
      });
    this.setState({ payload: data, working: false });
    Object.entries(data).forEach((item) => {
      this.__handleFieldChange(item[0], item[1]);
    });

    this.__hydrateForm(data);
  }

  __setDirtyness(field, value) {
    let shouldBeDirty = true;
    if (this.state.payload) {
      const payload = this.state.payload;
      if (payload[field] !== undefined && payload[field] === value) {
        shouldBeDirty = false;
      }
    }
    const dirtyFields = this.state.dirtyFields;
    if (shouldBeDirty && !dirtyFields.find((e) => e === field)) {
      dirtyFields.push(field);
      this.setState({ dirtyFields });
    } else if (!shouldBeDirty && dirtyFields.find((e) => e === field)) {
      dirtyFields.splice(dirtyFields.indexOf(field), 1);
      this.setState({ dirtyFields });
    }
    this.__emitDirtyness();
    return shouldBeDirty;
  }

  __emitDirtyness() {
    if (this.state.dirtyFields.length > 0) {
      this.props.onReady?.(true);
    } else {
      this.props.onReady?.(false);
    }
  }

  __validate(field) {
    const currentState = JSON.parse(JSON.stringify(this.state));
    if (field) {
      // validation and messages for field and form
    } else {
      // reset messages on FieldChange
      currentState.messages = null;
    }
  }

  __handleFieldChange(field, value) {
    this.__validate(null);
    if (this.defaultData[field] === undefined) {
    }
    this.__setDirtyness(field, value);
    this.__updateDataValue(field, value);
  }

  __handleMultiFieldsChange(fieldValueList) {
    const data = Object.assign({}, this.state.data);
    fieldValueList.forEach(({ field, value }) => {
      this.__setDirtyness(field, value);
      if (data[field] !== value) {
        data[field] = value;
      }
    });
    this.setState({ data });
  }

  __updateDataValue(field, value) {
    const data = Object.assign({}, this.state.data);
    if (data[field] !== value) {
      data[field] = value;
      this.setState({ data });
      return true;
    }
    return false;
  }

  __grabUpdatedFields() {
    const data = {};
    Object.keys(this.state.data)
      .filter((field) => {
        if (this.state.dirtyFields.includes(field)) {
          return true;
        }
        const found = this.state.dirtyFields.find((dirtyField) =>
          dirtyField.startsWith(`${field}.`)
        );
        if (found) {
          return true;
        }
        return false;
      })
      .forEach((key) => {
        data[key] = this.state.data[key];
      });
    return data;
  }

  __handleSubmit() {
    if (this.state.dirtyFields.length > 0) {
      this.setState({ working: true });
      const data = this.__grabUpdatedFields();
      this.__persistData(data);
    } else {
      this.props.onReady?.(true);
    }
  }

  // shared method to respond to server error messages
  __errToMessages(err) {
    const messages = {};
    if (err.response?.data?.validation_messages) {
      Object.entries(err.response.data.validation_messages).forEach(
        (fieldMessages) => {
          if (this.defaultData[fieldMessages[0]] === undefined) {
            const renderedFieldMessages = Object.entries(fieldMessages[1]).map(
              (fieldMessage) => {
                return {
                  validationKey: fieldMessages[0],
                  text: `${fieldMessage[0]} ${fieldMessage[1]}`,
                  type: "error",
                };
              }
            );
            messages.form = renderedFieldMessages;
          } else {
            const renderedFieldMessages = Object.entries(fieldMessages[1]).map(
              (fieldMessage) => {
                return {
                  validationKey: fieldMessage[0],
                  text: fieldMessage[1],
                  type: "error",
                };
              }
            );
            messages[fieldMessages[0]] = renderedFieldMessages;
          }
        }
      );
    } else if (err.response?.data?.detail) {
      messages.form = [
        {
          validationKey: err.response.data.title,
          text: err.response.data.detail,
          type: "error",
        },
      ];
    } else {
      messages.form = [
        {
          validationKey: "unknown",
          text: "unknown",
          type: "error",
        },
      ];
    }
    return messages;
  }

  __renderFormMessages(messages) {
    let messageElements = null;
    if (messages && messages.form && messages.form.length) {
      messageElements = messages.form.map((elem) => {
        let messageType = "";
        switch (elem.type) {
          case "error":
            messageType = "danger";
            break;
          case "success":
            messageType = "success";
            break;
          case "info":
            messageType = "info";
            break;
          default:
            messageType = "danger";
            break;
        }
        return (
          <Alert key={`${elem.validationKey}${elem.text}`} color={messageType}>
            {elem.validationKey ? `${elem.validationKey}:` : ""} {elem.text}
          </Alert>
        );
      });
    }
    return messageElements;
  }

  render() {
    return (
      <form
        onSubmit={() => {
          this.__handleSubmit();
        }}
        style={
          this.state.working ? { opacity: 0.5, pointerEvents: "none" } : {}
        }
      >
        Please override the formBase render method
      </form>
    );
  }
}

FormBase.propTypes = {
  save: PropTypes.bool,
  onReady: PropTypes.func,
};

FormBase.defaultProps = {
  save: false,
};

export default FormBase;
