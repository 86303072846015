import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faEllipsisH } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import { BulkActionNodesProps } from "./types";
import { TableListBulkAction } from "./TableListBulkAction";
import CheckboxNew from "../forms/CheckboxNew";
import { Item } from "./TableListItem";

export interface TableListBulkSelectHeaderProps {
  bulkSelected: Item[];
  bulkActionNodes?: BulkActionNodesProps[];
  isSingle?: boolean;
  withSelectAll?: boolean;
  onBulkToggle?: () => void;
}

export function TableListBulkSelectHeader({
  bulkSelected,
  bulkActionNodes,
  onBulkToggle,
  isSingle,
  withSelectAll,
}: Readonly<TableListBulkSelectHeaderProps>) {
  const { t } = useTranslation();
  const isMultiAction = bulkActionNodes && bulkActionNodes.length > 2;
  const [showMobileBulk, setShowMobileBulk] = useState<boolean>(false);

  return (
    <div>
      <div
        className={`${
          bulkSelected.length || withSelectAll ? "tw-flex" : "tw-hidden"
        } tw-justify-${
          isSingle ? "end" : "between"
        } tw-items-center tw-h-11 tw-px-2 sm:tw-px-4 tw--mx-4 tw-bg-cs-shade-200`}
      >
        {!isSingle && (
          <div className="tw-flex tw-items-center">
            {!!withSelectAll && (
              <label className="tw-mr-3 tw-flex tw-items-center">
                <CheckboxNew
                  data-testid="TableListBulkSelectHeader-select-all"
                  checked={!!bulkSelected.length}
                  iconStyle={
                    !!bulkSelected.length ? "deselect-line" : "checkmark"
                  }
                  darkChecked
                  onToggle={() => {
                    onBulkToggle && onBulkToggle();
                  }}
                />
              </label>
            )}
            <div className="tw-ml-4">
              {!!bulkSelected.length && (
                <>
                  <strong>{bulkSelected.length}</strong>{" "}
                  <span className="tw-hidden sm:tw-inline">
                    {bulkSelected.length === 1
                      ? t("row selected.one", { keySeparator: "." })
                      : t("row selected.other", { keySeparator: "." })}
                  </span>
                </>
              )}
            </div>
          </div>
        )}

        {!!bulkActionNodes?.length && (
          <div className="tx-flex tw-items-center">
            {bulkActionNodes.map((bulkAction, i) => (
              <TableListBulkAction
                bulkAction={bulkAction}
                index={i}
                key={i}
                bulkSelected={bulkSelected}
                isMobileBulk={bulkActionNodes?.length > 1}
              />
            ))}
            {isMultiAction && (
              <button
                onClick={() => setShowMobileBulk((prevState) => !prevState)}
                className="sm:tw-hidden tw-ml-1 tw-overflow-hidden tw-max-h-5"
              >
                <FontAwesomeIcon icon={faEllipsisH} size="2x" />
              </button>
            )}
          </div>
        )}
      </div>

      {isMultiAction && showMobileBulk && (
        <div
          className={`${
            bulkSelected.length ? "tw-flex sm:tw-hidden" : "tw-hidden"
          } tw-mt-4 tw-justify-end tw-items-center tw-h-11 tw-px-2 sm:tw-px-4 tw--mx-4 tw-bg-tw-cs-accent-color tw-rounded after:tw-content-[""] after:tw-absolute after:tw-z-10 after:tw-bottom-[2.7rem] after:tw-right-0 after:tw-border-b-tw-cs-accent-color after:tw-border-t-transparent after:tw-border-x-transparent after:tw-border-solid after:tw-border-8`}
        >
          {bulkActionNodes.map((bulkAction, i) =>
            i >= 2 ? (
              <TableListBulkAction
                bulkAction={bulkAction}
                index={i}
                key={i}
                bulkSelected={bulkSelected}
                isMobileBulk
              />
            ) : null
          )}
        </div>
      )}
    </div>
  );
}
