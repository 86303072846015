import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
} from "react";

export interface BaseButtonProps {
  disabled?: boolean;
  isOutline?: boolean;
  isDropdown?: boolean;
  isToggle?: boolean;
  isPrimary?: boolean;
  isSecondary?: boolean;
  isDanger?: boolean;
  isWarning?: boolean;
  isSuccessful?: boolean;
  isDark?: boolean;
  isNote?: boolean;
  isBlock?: boolean;
  isSmall?: boolean;
  isLink?: boolean;
  isPill?: boolean;
  isLight?: boolean;
  /* isActive currently only handled for isLight btns */
  isActive?: boolean;
  isHeaderAction?: boolean;
  /** overwrites all other props!! -> pass custom classNames from outside */
  customStyle?: string;
  buttonValue?: string | ReactNode;
}

interface BaseButtonStylesProps extends BaseButtonProps {
  children: (className: string) => ReactNode;
}

export function BaseButtonStyles({
  disabled = false,
  isOutline = false,
  isDropdown = false,
  isToggle = false,
  isPrimary = false,
  isSecondary = false,
  isDanger = false,
  isWarning = false,
  isSuccessful = false,
  isDark = false,
  isNote = false,
  isBlock = false,
  isSmall = false,
  isLink = false,
  isPill = false,
  isLight = false,
  isActive = false,
  isHeaderAction = false,
  customStyle,
  children,
}: BaseButtonStylesProps) {
  const customStyleWHelperClass = customStyle
    ? `tw-cs-btn ${customStyle}`
    : customStyle;

  // https://github.com/tailwindlabs/tailwindcss/issues/7553#issuecomment-673753408
  // className={"

  function getBaseTheme() {
    if (isPrimary) {
      return "tw-cs-btn-primary";
    }
    if (isSecondary) {
      return "tw-cs-btn-secondary";
    }
    if (isLight) {
      return "tw-cs-btn-light";
    }
    if (isDark) {
      return "tw-cs-btn-dark";
    }
    if (isSuccessful) {
      return "tw-cs-btn-success";
    }
    if (isDanger) {
      return "tw-cs-btn-danger";
    }
    if (isWarning) {
      return "tw-cs-btn-warning";
    }
    if (isNote) {
      return "tw-cs-btn-note";
    }
    return "";
  }

  const baseTheme:
    | ""
    | "tw-cs-btn-primary"
    | "tw-cs-btn-secondary"
    | "tw-cs-btn-light"
    | "tw-cs-btn-dark"
    | "tw-cs-btn-success"
    | "tw-cs-btn-danger"
    | "tw-cs-btn-warning"
    | "tw-cs-btn-note" = getBaseTheme();

  return (
    <>
      {children(
        customStyleWHelperClass ||
          `tw-cs-btn
          tw-cs-btn-base
          ${baseTheme}
          ${disabled ? "tw-cs-btn-disabled" : ""}
          ${isActive ? "tw-cs-btn-active" : ""}
          ${isOutline ? "tw-cs-btn-outline" : ""}
          ${isSmall ? "tw-cs-btn-small" : ""}
          ${isBlock ? "tw-cs-btn-block" : ""}
          ${isLink ? "tw-cs-btn-link" : ""}
          ${isHeaderAction ? "tw-cs-btn-header-action" : ""}
          ${isPill ? "tw-cs-btn-pill" : ""}
          ${isDropdown ? "tw-cs-btn-dropdown" : ""}
          ${isToggle ? "tw-cs-btn-dropdown-toggle" : ""}
          `
      )}
    </>
  );
  // "}
}

export interface AnchorButtonProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "className">,
    BaseButtonProps {}

export const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  (
    {
      disabled,
      isOutline,
      isDropdown,
      isToggle,
      isPrimary,
      isSecondary,
      isDanger,
      isSuccessful,
      isDark,
      isWarning,
      isNote,
      isBlock,
      isSmall,
      isLink,
      isPill,
      isLight,
      isActive,
      isHeaderAction,
      customStyle,
      buttonValue = "",
      children,
      ...anchorProps
    },
    ref
  ) => {
    return (
      <BaseButtonStyles
        disabled={disabled}
        isOutline={isOutline}
        isDropdown={isDropdown}
        isToggle={isToggle}
        isPrimary={isPrimary}
        isSecondary={isSecondary}
        isDanger={isDanger}
        isSuccessful={isSuccessful}
        isDark={isDark}
        isWarning={isWarning}
        isNote={isNote}
        isBlock={isBlock}
        isSmall={isSmall}
        isLink={isLink}
        isPill={isPill}
        isLight={isLight}
        isActive={isActive}
        isHeaderAction={isHeaderAction}
        customStyle={customStyle}
      >
        {(className) => (
          <a {...anchorProps} ref={ref} className={className}>
            {children || buttonValue}
          </a>
        )}
      </BaseButtonStyles>
    );
  }
);

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type" | "className">,
    BaseButtonProps {
  type?: "submit" | "reset" | "button";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = "button",
      disabled,
      isOutline,
      isDropdown,
      isToggle,
      isPrimary,
      isSecondary,
      isDanger,
      isSuccessful,
      isDark,
      isWarning,
      isNote,
      isBlock,
      isSmall,
      isLink,
      isPill,
      isLight,
      isActive,
      isHeaderAction,
      customStyle,
      buttonValue = "",
      children,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <BaseButtonStyles
        disabled={disabled}
        isOutline={isOutline}
        isDropdown={isDropdown}
        isToggle={isToggle}
        isPrimary={isPrimary}
        isSecondary={isSecondary}
        isDanger={isDanger}
        isSuccessful={isSuccessful}
        isDark={isDark}
        isWarning={isWarning}
        isNote={isNote}
        isBlock={isBlock}
        isSmall={isSmall}
        isLink={isLink}
        isPill={isPill}
        isLight={isLight}
        isActive={isActive}
        isHeaderAction={isHeaderAction}
        customStyle={customStyle}
      >
        {(className) => (
          <button
            ref={ref}
            type={type}
            className={className}
            disabled={disabled}
            {...buttonProps}
          >
            {children || buttonValue}
          </button>
        )}
      </BaseButtonStyles>
    );
  }
);

export default Button;
