import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormBaseContainer from "components/miscellaneous/FormBaseContainer";
import ContactSearchprofilesEditForm from "./ContactSearchprofilesEditForm";
import resourceHelper from "utilities/resource";
import { actions as contactSearchprofileActions } from "entities/contactSearchprofile/store";

class ContactSearchprofilesCreateFormContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <FormBaseContainer
        defaultData={{
          priceMin: "",
          priceMax: "",
          roomMin: "",
          roomMax: "",
          utilities: "",
          categories: "",
          features: "",
          areaBwfNwfMin: "",
          areaBwfNwfMax: "",
          areaGsfMin: "",
          areaGsfMax: "",
          areaSiaNfMin: "",
          areaSiaNfMax: "",
          marketingMethod: "",
          polygon: "",
          notes: "",
          status: "active",
        }}
        onReady={(bool) => {
          this.props.onReady(bool);
        }}
        save={this.props.save}
        persistData={(data) => {
          const rData = Object.assign({}, data);
          if (this.props.contactAccessKey) {
            rData.contactAccessKey = this.props.contactAccessKey;
          }
          Object.entries(rData).forEach((item) => {
            const key = item[0];
            const value = item[1];
            if (value === "") {
              rData[key] = null;
            }
          });
          rData.contact = this.props.contactId;

          if (rData.priceMin) {
            rData.priceMin = parseFloat(rData.priceMin);
          }
          if (rData.priceMax) {
            rData.priceMax = parseFloat(rData.priceMax);
          }
          if (rData.roomMin) {
            rData.roomMin = parseFloat(rData.roomMin);
          }
          if (rData.roomMax) {
            rData.roomMax = parseFloat(rData.roomMax);
          }
          if (rData.areaBwfNwfMin) {
            rData.areaBwfNwfMin = parseFloat(rData.areaBwfNwfMin);
          }
          if (rData.areaBwfNwfMax) {
            rData.areaBwfNwfMax = parseFloat(rData.areaBwfNwfMax);
          }
          if (rData.areaGsfMin) {
            rData.areaGsfMin = parseFloat(rData.areaGsfMin);
          }
          if (rData.areaGsfMax) {
            rData.areaGsfMax = parseFloat(rData.areaGsfMax);
          }
          if (rData.areaSiaNfMin) {
            rData.areaSiaNfMin = parseFloat(rData.areaSiaNfMin);
          }
          if (rData.areaSiaNfMax) {
            rData.areaSiaNfMax = parseFloat(rData.areaSiaNfMax);
          }

          if (rData.categories) {
            rData.categories = rData.categories.split(",").map((slug, i) => {
              return {
                slug,
                rank: i,
              };
            });
          }

          if (rData.features) {
            rData.features = rData.features.split(",").map((slug, i) => {
              return {
                slug,
                rank: i,
              };
            });
          }

          if (rData.utilities) {
            rData.utilities = rData.utilities.split(",").map((slug, i) => {
              return {
                slug,
                rank: i,
              };
            });
          }

          return new Promise((resolve, reject) => {
            const urlParamsForRefetch = {};
            if (this.props.contactAccessKey) {
              urlParamsForRefetch.contactAccessKey =
                this.props.contactAccessKey;
            }
            resourceHelper
              .createItem(
                this.props.storeName,
                rData,
                true,
                urlParamsForRefetch
              )
              .then((response) => {
                if (response.code === 201) {
                  this.props.onReady(true);
                  this.props.onDone();
                  resourceHelper.reloadList("propertyMatches");
                  resolve(response);
                } else {
                  this.props.onFail();
                  reject({
                    validationKey: "resourceMissing",
                    text: "wrong response code",
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.props.onFail();
                reject(err);
              });
          });
        }}
        editForm={(props) => {
          if (props.data) {
            return (
              <ContactSearchprofilesEditForm
                autoFocus={this.props.autoFocus}
                {...props}
                contactId={this.props.contactId}
              />
            );
          }
          return <span>Loading ...</span>;
        }}
      />
    );
  }
}

ContactSearchprofilesCreateFormContainer.propTypes = {
  contactId: PropTypes.string.isRequired,
  contactSearchprofileStore: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  onReady: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  save: PropTypes.bool,
  autoFocus: PropTypes.string,
  storeName: PropTypes.string,
  contactAccessKey: PropTypes.string,
};

ContactSearchprofilesCreateFormContainer.defaultProps = {
  save: false,
  autoFocus: null,
  storeName: "contactSearchprofiles",
  contactAccessKey: null,
};

const comp = connect(
  (state) => {
    return {
      contactSearchprofileStore: state.contactSearchprofiles,
    };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators({ ...contactSearchprofileActions }, dispatch),
    };
  }
)(ContactSearchprofilesCreateFormContainer);

export default comp;
