import * as React from "react";
import {
  components as ReactSelectComponent,
  type ClearIndicatorProps,
} from "react-select";

interface Props {
  props: ClearIndicatorProps<any>;
}

const SelectCustomClearIndicator: React.FC<Props> = ({ props }: Props) => {
  return (
    ReactSelectComponent.ClearIndicator && (
      <ReactSelectComponent.ClearIndicator {...props}>
        <svg width="15px" height="15px" viewBox="0 0 20 20" version="1.1">
          <g
            id="carrot"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
          >
            <path d="M6,6 L14,14" id="Path-2"></path>
            <path
              d="M6,6 L14,14"
              id="Path-2"
              transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) "
            ></path>
          </g>
        </svg>
      </ReactSelectComponent.ClearIndicator>
    )
  );
};

export default SelectCustomClearIndicator;
