import { createResource } from "redux-rest-resource";
import Config from "config";
import createBaseActions from "entities/createBaseActions";
import ProjectContractState from "./types";
import createResourceHelper from "utilities/createResourceHelper";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: ProjectContractState,
    actions?: any
  ) => ProjectContractState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "projectContract",
  pluralName: "projectContracts",
  url: `${Config.apiUrl}/${Config.customerKey}/project-contract/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "contract",
      forcedApiContext: "project-contract-list",
    }),
  },
});

const projectContractResourceCreator = createResourceHelper({
  actions,
  name: "projectContract",
  pluralName: "projectContracts",
});

export const projectContractResource = projectContractResourceCreator.resource;
export const useProjectContracts = projectContractResourceCreator.createHook();
