import { SelectPhase } from "./SelectPhase";
import { usePhaseList } from "./helpers/usePhaseList";
import { useContactLastPhase } from "./helpers/useContactLastPhase";
import { SelectRelation } from "./SelectRelation";
import { RelationPhaseErrorReport } from "./RelationPhaseErrorReport";
import { useEffect } from "react";

export type RecipientFieldKeys = "relation" | "phase";

export type RelationChangeHandlers = {
  [key in RecipientFieldKeys]: (newValue: string | undefined) => void;
};
export type RelationValues = {
  [key in RecipientFieldKeys]: string | undefined;
};

export type RelationPhaseWatch = {
  propertyId?: string;
  recipientIds?: string[];
  eventType?: string;
  eventBaseType?: string;
};

interface SelectRelationContainerProps {
  values: RelationValues;
  changeHandlers: RelationChangeHandlers;
  propertyMarketingMethod: string | undefined;
  selectedFormValues: RelationPhaseWatch;
}

export const SelectRelationPhaseContainer = ({
  values,
  changeHandlers,
  propertyMarketingMethod,
  selectedFormValues,
}: SelectRelationContainerProps) => {
  const { latestPhaseId, error: latestPhaseError } = useContactLastPhase({
    propertyId: selectedFormValues.propertyId,
    recipientIds: selectedFormValues.recipientIds || [],
  });

  const {
    phaseOptions,
    phaseByRule,
    error: phaseListError,
    latestPhase,
    isValid,
  } = usePhaseList({
    propertyMarketingMethod,
    latestPhaseId,
    selectedEventType: selectedFormValues.eventType,
    selectedEventBaseType: selectedFormValues.eventBaseType,
  });

  useEffect(() => {
    if (phaseByRule) {
      changeHandlers.phase(phaseByRule);
    }
  }, [phaseByRule, changeHandlers]);

  if (!isValid) {
    return null;
  }

  const isRelationFieldDisabled = !selectedFormValues.propertyId;
  const isPhaseFieldHidden =
    values.relation !== "prospective-customer" ||
    !selectedFormValues.propertyId;

  if (phaseListError || latestPhaseError) {
    return (
      <RelationPhaseErrorReport
        phaseListError={phaseListError}
        latestPhaseError={latestPhaseError}
      />
    );
  }

  return (
    <div className="md:tw-flex tw-gap-4">
      <SelectRelation
        value={values.relation}
        onChange={changeHandlers.relation}
        isDisabled={isRelationFieldDisabled}
      />
      {values.relation === "prospective-customer" && (
        <SelectPhase
          value={values.phase}
          onChange={changeHandlers.phase}
          options={phaseOptions}
          isHidden={isPhaseFieldHidden}
          latestPhase={latestPhase}
        />
      )}
    </div>
  );
};
