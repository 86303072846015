import { isAxiosError } from "axios";
import { ApiErrorDefinition, isApi1ErrorShape } from "./apiErrorDefinitions";

function createApiErrorObject(error: unknown): { error: ApiErrorDefinition } {
  if (isAxiosError<unknown, unknown>(error)) {
    const errorResponse = error.response?.data;
    if (isApi1ErrorShape(errorResponse)) {
      return {
        error: {
          is_api_error_definition: true,
          status: errorResponse.status,
          code: errorResponse.title,
          message: errorResponse.detail,
          details: errorResponse.validation_messages,
        },
      };
    }
    return {
      error: {
        is_api_error_definition: true,
        status: error.status,
        code: error.code,
        message: error.message,
        unknown_details: errorResponse,
      },
    };
  }

  return {
    error: {
      is_api_error_definition: true,
      code: "unknown-error",
      message: "An unknown error occurred",
      unknown_details: error,
    },
  };
}

export default createApiErrorObject;
