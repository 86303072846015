import { createResource } from "redux-rest-resource";
import Config from "config";
import AddressState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: AddressState, actions?: any) => AddressState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "address",
  pluralName: "addresses",
  url: `${Config.apiUrl}/${Config.customerKey}/address/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
