import { $CombinedState } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { listenerMiddleware } from "./listenerMiddleware";
import { casageoApi, casaoneApi, casaoneApi2 } from "api";

function createStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        listenerMiddleware.middleware,
        thunk,
        casaoneApi.middleware,
        casaoneApi2.middleware,
        casageoApi.middleware
      ),
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: undefined,
  });
}

const store = createStore();

type OriginalRootState = ReturnType<typeof rootReducer>;
export type CleanRootStateKeys = Exclude<
  keyof OriginalRootState,
  // this is some kind of placeholder that causes enum issues
  typeof $CombinedState
>;
// type CleanRootStateValues = OriginalRootState[CleanRootStateKeys];
// export type RootState = { [key in CleanRootStateKeys]: CleanRootStateValues };
export type RootState = Omit<OriginalRootState, typeof $CombinedState>;
export type RootDispatch = typeof store.dispatch;
export default store;
