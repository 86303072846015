import createBoardingInstance from "@casasoft/styleguide/utilities/tours/createBoardingInstance";
import { history } from "config/history";
import { CsHistoryChangeEventType } from "index";
import completeAndBackToOverview from "tours/completeAndBackToOverview";
import { waitForElm } from "tours/utilities";
import resourceGrabber from "utilities/resourceGrabber";

export const PROPERTY_CREATE_TOUR = "prty-create-tour";

export const PROPERTY_TOUR_QUICKADD_MENU = "prty-tr-qck-a-mn";
export const PROPERTY_TOUR_CREATE_BUTTON = "prty-tr-crt-btn";
export const PROPERTY_TOUR_CREATE_FIELD_ADDRESS = "prty-tr-crt-adr-fld";
export const PROPERTY_TOUR_CREATE_FIELD_NAME = "prty-tr-crt-nm-fld";
export const PROPERTY_TOUR_CREATE_FIELD_M_METHOD = "prty-tr-crt-mm-fld";

export function subscribePropertyCreateOnboarding(t: (term: string) => string) {
  const [boarding, unsubscribe] = createBoardingInstance(
    PROPERTY_CREATE_TOUR,
    t,
    {
      allowClose: false,
      padding: 5,
      strictClickHandling: "block-all", // we are doing the clicking for them
    }
  );

  const historyChangeHandler = async (e: Event) => {
    if (!boarding.isActivated) {
      return;
    }

    const myEv = e as CsHistoryChangeEventType;

    // step 8 = enter property date step
    if (boarding.currentStep === 5) {
      if (myEv.detail.location.pathname.startsWith("/property/list/item/")) {
        await waitForElm(".app__contentminilist__list");
        boarding.next();
      }
    }
  };
  document.addEventListener("CsHistoryChangeEvent", historyChangeHandler);
  const unsubscribeHistory = () => {
    document.removeEventListener("CsHistoryChangeEvent", historyChangeHandler);
  };

  boarding.defineSteps([
    {
      element: `[data-boarding~="${PROPERTY_TOUR_QUICKADD_MENU}"]`,
      padding: -5,
      popover: {
        title: t("propertiesCreate.intro.title"),
        description: t("propertiesCreate.intro.desc"),
        prefferedSide: "right",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(`[data-boarding~="${PROPERTY_TOUR_QUICKADD_MENU}"]`);
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_CREATE_BUTTON}"]`,
      popover: {
        title: t("propertiesCreate.list.title"),
        description: t("propertiesCreate.list.desc"),
      },
      prepareElement: async (initiator) => {
        if (initiator === "next") {
          boarding.preventMove();
          history.push("/property/list");
          await waitForElm(`[data-boarding~="${PROPERTY_TOUR_CREATE_BUTTON}"]`);
          boarding.continue();
        }
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_CREATE_FIELD_ADDRESS}"]`,
      popover: {
        title: t("propertiesCreate.address.title"),
        description: t("propertiesCreate.address.desc"),
        prefferedSide: "left",
      },
      onPrevious: () => {
        // close modal
        const closeBtn = document.querySelector<HTMLElement>(
          `div[role="dialog"][data-state="open"] > div > button[type="button"]`
        );
        closeBtn?.click();
      },
      prepareElement: async (initiator) => {
        if (initiator !== "prev") {
          // open modal
          const createBtn = document.querySelector<HTMLElement>(
            `[data-boarding~="${PROPERTY_TOUR_CREATE_BUTTON}"]`
          );
          createBtn?.click();
        }

        boarding.preventMove();
        const addressFieldName = await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_CREATE_FIELD_ADDRESS}"]`
        );
        addressFieldName.blur();

        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_CREATE_FIELD_NAME}"]`,
      popover: {
        title: t("propertiesCreate.name.title"),
        description: t("propertiesCreate.name.desc"),
        prefferedSide: "left",
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_CREATE_FIELD_M_METHOD}"]`,
      popover: {
        title: t("propertiesCreate.marketingMethod.title"),
        description: t("propertiesCreate.marketingMethod.desc"),
        prefferedSide: "left",
      },
    },
    {
      element: 'div[role="dialog"][data-state="open"]',
      popover: {
        title: t("propertiesCreate.createForm.title"),
        description: t("propertiesCreate.createForm.desc"),
        prefferedSide: "left",
        alignment: "center",
        disableButtons: ["next"],
        className: "cs-popover-awaiting-input",
      },
      strictClickHandling: false,
      onNext: () => {
        if (!history.location.pathname.startsWith("/property/list/item/")) {
          // move next handeled by history change listener
          boarding.preventMove();
          setTimeout(() => {
            boarding.clearMovePrevented();
            history.push(
              `/property/list/item/${resourceGrabber.grab("username", "auth")}`
            );
          }, 0);
        }
      },
    },
    {
      element: `.app__contentminilist__list`,
      popover: {
        title: t("propertiesCreate.created.title"),
        description: t("propertiesCreate.created.desc"),
        prevBtnText: t("onboarding.btn.backToOverview"),
      },
      onPrevious: () => {
        completeAndBackToOverview(boarding);
      },
    },
  ]);

  return () => {
    unsubscribe();
    unsubscribeHistory();
  };
}
