import { RefObject, useEffect, useState } from "react";
import type {
  RecipientFieldKeys,
  RecipientValues,
} from "../SelectRecipientsContainer";

export const useRecipients = (
  values: RecipientValues<RecipientFieldKeys>,
  wrapperRef?: RefObject<HTMLDivElement>
) => {
  const [isCcEnabled, setIsCcEnabled] = useState(false);
  const [isBccEnabled, setIsBccEnabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCC = () => {
    const hasValue = !!values.contactCC;
    setIsCcEnabled((current) => (hasValue ? true : !current));
  };
  const handleBCC = () => {
    const hasValue = !!values.contactBCC;
    setIsBccEnabled((current) => (hasValue ? true : !current));
  };

  const handleFocus = () => {
    setIsExpanded(true);
  };
  const handleBlur = () => {
    setIsExpanded(false);
  };
  const handleWrapperClick = () => {
    // we programatically focus the wrapper when it is clicked, so that onFocus and onBlur events work as expected
    if (!wrapperRef?.current?.contains(document.activeElement)) {
      wrapperRef?.current?.focus();
    }
    setIsExpanded(true);
  };

  useEffect(() => {
    if (!isExpanded && isCcEnabled && !values.contactCC?.length) {
      setIsCcEnabled(false);
    }
    if (!isExpanded && isBccEnabled && !values.contactBCC?.length) {
      setIsBccEnabled(false);
    }
  }, [
    isExpanded,
    isCcEnabled,
    isBccEnabled,
    values.contactCC,
    values.contactBCC,
  ]);

  useEffect(() => {
    // If the focus is outside the wrapper, and wrapper is expanded, then collapse it
    const isInFocus =
      wrapperRef?.current?.contains(document.activeElement) ||
      wrapperRef?.current === document.activeElement;

    if (!isInFocus && isExpanded) {
      setIsExpanded(false);
    }
  }, [wrapperRef, isExpanded]);

  return {
    isCcEnabled,
    isBccEnabled,
    isExpanded,
    handleCC,
    handleBCC,
    handleFocus,
    handleBlur,
    handleWrapperClick,
  };
};
