import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseContactCreator, { ContactResourceTypes } from "./baseContactCreator";

const name = "dynamicContact" as const;
const pluralName = "dynamicContacts" as const;

export const { types, actions, rootReducer }: ContactResourceTypes =
  createResource(baseContactCreator({ name, pluralName }));

const dynamicContactResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const dynamicContactResource = dynamicContactResourceCreator.resource;
export const useDynamicContact = dynamicContactResourceCreator.createHook();
