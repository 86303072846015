import * as Sentry from "@sentry/browser";
import Config from "config";

const initSentry = () => {
  Sentry.init({
    dsn: "https://7e843e63a26c4d5ea48c0c783b6ad775@sentry.io/1253462",
    release: Config.release,
    enabled: process.env.NODE_ENV !== "development" && Config.env !== "local",
    environment: Config.env,
  });

  Sentry.setTag("company", Config.customerKey);
};

export default initSentry;
