import { MutateUserSingleResponse, MutateUserSingleQueryParams } from "./types";
import { createInvalidatesTags } from "api/createEndpoint";

const invalidatesTags = createInvalidatesTags<
  MutateUserSingleResponse,
  MutateUserSingleQueryParams
>((result, error, queryParams) => {
  return [{ type: "User", id: queryParams.username }];
});

export default invalidatesTags;
