import { ReactNode, useEffect, useState } from "react";
import {
  Toolbar,
  ToolbarMenuSubItem,
} from "@casasoft/styleguide/components/toolbar";
import { Main, Notificationcenter } from "@casasoft/styleguide/components/app";
import { Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faPlug } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { UserRoles } from "utilities";
import AppNav from "./miscellaneous/Nav";
import DashboardQuicktasksCard from "./dash/dashboard/DashboardQuicktasksCard";
import DashboardQuickalertsCard from "./dash/dashboard/DashboardQuickalertsCard";
import DashboardQuickremindersCard from "./dash/dashboard/DashboardQuickremindersCard";
import DashboardCompleteYourProfileCard from "./dash/dashboard/DashboardCompleteYourProfileCard";
import Config from "config";
import { getCurrentRole, hasAccessTo } from "utilities/featureFlags";
import EnvSettings from "./miscellaneous/EnvSettings";
import { Helpcenter } from "../@casasoft/styleguide/components/helpcenter";
import useFaqArticleList from "hooks/useFaqArticleList";
import { useLocation } from "react-router";
import { axiosInstance } from "utilities/axios";
import { useAppSelector } from "redux/hooks";
import { selectCasaoneUser, selectContactLang } from "redux/auth/selectors";
import { getCasaoneMarketplaceIntegrationLink } from "./toolbar/helpers/getCasaoneMarketplaceIntegrationLink";
import { useToolbarItems } from "./toolbar/hooks/useToolbarItems";

export type ToggleState = "open" | "close" | "peek";

interface AppProps {
  children: ReactNode;
}

function App({ children }: AppProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const lang = useAppSelector(selectContactLang);

  const {
    faqArticles,
    activeItem,
    similarTopics,
    isLoadingFaqs,
    onFaqTopicSelect,
    onFaqArticleSelect,
  } = useFaqArticleList();

  const [faqToggleState, setFaqToggleState] = useState<ToggleState>("close");
  const [notificationCenterToggleState, setNotificationCenterToggleState] =
    useState<ToggleState>("close");

  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    // Get number of non downloaded invoices
    if (hasAccessTo("invoiceManagement")) {
      axiosInstance
        .get(
          `${Config.api2Url}/company/${Config.customerKey}/invoice?hasInvoiceFile=true&exists[downloadedAt]=false`
        )
        .then((response) => setInvoicesCount(response.data.total_items || 0));
    }
  }, []);

  useEffect(() => {
    if (pathname.includes("/faq/list")) {
      setFaqToggleState("close");
    }
    // reset topic select on path change (used useConditionalEffect to not trigger useEffect on function onFaqTopicSelect call from elsewhere)
    onFaqTopicSelect("");
  }, [pathname, onFaqTopicSelect]);

  const [invoicesCount, setInvoicesCount] = useState(0);

  const casaoneUser = useAppSelector(selectCasaoneUser);

  const currentRole = getCurrentRole();

  // Does not make sense to go ahead without a user
  if (!casaoneUser || !casaoneUser.roles?.length) {
    throw new Error("Either CasaoneUser or his roles are not found");
  }

  const casaoneMarketplaceIntegrations: ToolbarMenuSubItem = {
    key: "casaone-marketplace-integrations",
    action: { externalLink: getCasaoneMarketplaceIntegrationLink(lang) },
    label: t("Integration marketplace"),
    icon: <FontAwesomeIcon fixedWidth icon={faPlug} />,
    suffixIcon: (
      <span style={{ top: "-0.1rem", position: "relative" }}>
        <FontAwesomeIcon
          fixedWidth
          opacity={0.4}
          icon={faExternalLink}
          size="xs"
        />
      </span>
    ),
  };

  const {
    toolbarItems,
    invoiceItem,
    menuIcon,
    securitySettingsItem,
    logoutItem,
    settingsItem,
  } = useToolbarItems({
    invoicesCount,
    faqToggleState,
    setFaqToggleState,
    notificationCenterToggleState,
    setNotificationCenterToggleState,
  });

  return (
    <div className="tw-h-full tw-min-h-0 tw-relative tw-flex tw-flex-col">
      <Toolbar
        brand={
          <Route
            render={({ history }) => (
              <div
                role="button"
                tabIndex={-1}
                onClick={() => {
                  history.push("/dash");
                }}
                className="tw-h-6 tw-relative"
              >
                <svg
                  width="239px"
                  height="47px"
                  viewBox="0 0 252 45.4"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-full tw-w-auto"
                >
                  <path
                    fill="#FFFFFF"
                    d="M34.9,37.1c0,0-4,4.5-12.8,4.5c-11.8,0-19.3-8.4-19.3-18.9c0-10.4,7.4-18.9,19.3-18.9c8.8,0,12.8,4.5,12.8,4.5 l-4.2,5.6c0,0-3.7-3.1-8.5-3.1c-6.9,0-11.8,5-11.8,11.9s4.9,11.9,11.8,11.9c5.5,0,8.5-3.1,8.5-3.1L34.9,37.1z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M55.1,4.5h1.3L75,40.8h-8.3l-2.4-4.9H47.1l-2.3,4.9h-8.3L55.1,4.5z M55.9,17.8h-0.5L50,29.6l-0.4,0.8h12.3 l-0.4-0.8L55.9,17.8z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M81,31.3c0,0,4.8,3.5,10.7,3.5c4.4,0,6.2-1.8,6.2-4c0-2.4-1.6-3.6-7.8-5.3c-8.2-2.3-12.5-5.2-12.5-11 c0-6.5,4.8-10.7,14.1-10.7c9.7,0,13.7,3.7,13.7,3.7l-3.9,6c0,0-3.9-3-9.8-3c-4,0-5.9,1.4-5.9,3.6s1.9,3.1,7.5,4.7 c8.9,2.4,12.9,5.5,12.9,11.3c0,6.3-4.4,11.4-14.3,11.4c-10,0-14.8-4.6-14.8-4.6L81,31.3z" // eslint-disable-line max-len
                  />
                  <path
                    fill="#FFFFFF"
                    d="M126.6,4.5h1.3l18.6,36.2h-8.3l-2.4-4.9h-17.3l-2.3,4.9H108L126.6,4.5z M127.4,17.8h-0.5l-5.5,11.8l-0.4,0.8 h12.3l-0.4-0.8L127.4,17.8z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M145.9,22.9c0-10.7,7.2-18.9,18.4-18.9s18.4,8.2,18.4,18.9c0,10.6-7.3,18.7-18.4,18.7S145.9,33.5,145.9,22.9z M180,22.9c0-8.9-5.8-16.4-15.7-16.4s-15.7,7.5-15.7,16.4c0,8.7,5.8,16.2,15.7,16.2S180,31.6,180,22.9z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M218.4,40.8h-0.9l-27.1-30.2v30.2h-2.6v-36h0.9l27.1,30.1V4.8h2.6V40.8z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M246.7,24.2h-20.2v14.1h23v2.5h-25.6v-36h25.6v2.5h-23v14.5h20.2V24.2z"
                  />
                </svg>
                {process.env.NODE_ENV === "development" ? (
                  <EnvSettings />
                ) : null}
              </div>
            )}
          />
        }
        toggleNav={(navToggle) => {
          setNavOpen(navToggle);
          setNotificationCenterToggleState("close");
        }}
        profileIcon={menuIcon}
        profileInfo={{
          // Contact is related to the user 1-1 but could be absent. Defaulting to empty string
          firstName: casaoneUser.contact?.firstName || "",
          lastName: casaoneUser.contact?.lastName || "",
          role: UserRoles.getRoleLabel(currentRole),
        }}
        closeMenu={
          notificationCenterToggleState === "open" || faqToggleState === "open"
        }
        desktopItems={[
          toolbarItems.faq,
          toolbarItems.calendar,
          toolbarItems.notifications,
          toolbarItems.settingsDropdown,
        ]}
        mobileItems={[
          settingsItem,
          securitySettingsItem,
          toolbarItems.calendar,
          toolbarItems.notifications,
          invoiceItem,
          toolbarItems.faq,
          casaoneMarketplaceIntegrations,
          logoutItem,
        ]}
      />
      <div role="presentation" className="tw-h-full tw-min-h-0 tw-relative">
        <Notificationcenter
          collapsed={notificationCenterToggleState !== "open"}
          onToggle={() => {
            setNotificationCenterToggleState(
              notificationCenterToggleState === "open" ? "close" : "open"
            );
          }}
          peek={notificationCenterToggleState === "peek"}
        >
          {notificationCenterToggleState === "open" && (
            <div className="tw-p-2 tw-h-full tw-overflow-y-scroll">
              {hasAccessTo("yourUserSettings") && (
                <>
                  <DashboardCompleteYourProfileCard size="small" />
                  <div className="tw-mb-4" />
                </>
              )}
              {hasAccessTo("eventManagement") && (
                <>
                  <DashboardQuickremindersCard compactView />
                  <div className="tw-mb-4" />
                  <DashboardQuicktasksCard compactView />
                  <div className="tw-mb-4" />
                </>
              )}
              {hasAccessTo("basicCasaoneFeatures") && (
                <DashboardQuickalertsCard />
              )}
            </div>
          )}
        </Notificationcenter>
        <Notificationcenter
          collapsed={faqToggleState !== "open"}
          onToggle={() => {
            setFaqToggleState(faqToggleState === "open" ? "close" : "open");
          }}
          peek={faqToggleState === "peek"}
        >
          {faqToggleState === "open" && (
            <Helpcenter
              isLoading={isLoadingFaqs}
              onTopicSelect={onFaqTopicSelect}
              onArticleSelect={onFaqArticleSelect}
              similiarTopics={similarTopics}
              faqArticles={faqArticles}
              activeArticle={activeItem}
              pathname={pathname}
              onClose={() => {
                setFaqToggleState("close");
              }}
            />
          )}
        </Notificationcenter>
        <Main
          onClick={() => {
            if (notificationCenterToggleState === "open") {
              setNotificationCenterToggleState("close");
            }
          }}
        >
          <AppNav
            collapsed={!navOpen}
            onToggle={() => {
              setNavOpen(navOpen ? false : true);
            }}
            navToggle={navOpen}
          />
          {children}
        </Main>
      </div>
    </div>
  );
}

export default App;
