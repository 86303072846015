import { MessageSignature } from "./MessageSignature";
import { mapUserSignatures } from "./helpers/mapUserSignatures";
import {
  useMessageSignatures,
  type UserSignatureSelectValueType,
} from "./helpers/useMessageSignatures";

interface MessageSignatureContainerProps {
  value: UserSignatureSelectValueType;
  onChange: (newValue: UserSignatureSelectValueType) => void;
  onLoad?: (initialValue: UserSignatureSelectValueType) => void;
}

export const MessageSignatureContainer = ({
  value,
  onChange,
  onLoad,
}: MessageSignatureContainerProps) => {
  const {
    selectedSignatureId,
    userSignatures = [],
    onSignatureChange,
    isDisabled,
  } = useMessageSignatures({
    signature: value,
    onChange,
    onLoad,
  });

  return (
    <MessageSignature
      options={mapUserSignatures(userSignatures)}
      value={selectedSignatureId}
      onChange={onSignatureChange}
      isDisabled={isDisabled}
    />
  );
};
