import { useState, useRef, useEffect } from "react";
import ReactSelect, { ActionMeta, MenuPlacement } from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import ReactSelectAsyncCreatable from "react-select/async-creatable";
import ReactSelectAsync from "react-select/async";
import SelectCustomOption from "./helpers/SelectCustomOption";
import SelectCustomDropDownIndicator from "./helpers/SelectCustomDropDownIndicator";
import SelectCustomClearIndicator from "./helpers/SelectCustomClearIndicator";
import SelectComponentMultiValueRemove from "./helpers/SelectComponentMultiValueRemove";
import SelectCustomSingleValue from "./helpers/SelectCustomSingleValue";
import SelectCustomMultipleValue from "./helpers/SelectCustomMultipleValue";
import useDebounce from "../../hooks/useDebounce";
import FormGroup, { FormGroupProps } from "./helpers/FormGroup";
import { useTranslation } from "react-i18next";

type ReactSelectProps = React.ComponentProps<typeof ReactSelectCreatable>;

export type CleanSelectOption = {
  value?: string | number;
  label: React.ReactNode | string;
  options?: CleanSelectOption[];
  [key: string]: unknown;
};

export type CleanSelectValue = CleanSelectOption;

interface SelectProps
  extends Pick<
    FormGroupProps,
    "nobox" | "label" | "className" | "id" | "required" | "message" | "text"
  > {
  compact?: boolean;
  disabled?: boolean;
  defaultInputValue?: string;
  isCreatable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isAsync?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  loadOptionsDebounce?: number;
  loadOptions?: (
    inputValue: string,
    callback: (options: CleanSelectOption[]) => void
  ) => Promise<CleanSelectOption[]>;
  labelFallback?: (val: string) => React.ReactNode;
  options?: CleanSelectOption[];
  onSelectionChange?: (
    value: CleanSelectValue | CleanSelectValue[] | undefined | null,
    action: ActionMeta<unknown>
  ) => void;
  renderOption?: (option: any) => string | React.ReactChild;
  renderValue?: (value: any) => string | React.ReactChild;
  defaultOptions?: boolean | CleanSelectOption[] | [];
  menuTop?: boolean;
  menuPlacement?: MenuPlacement;

  placeholder?: ReactSelectProps["placeholder"];
  value?: string;
  autoFocus?: boolean;

  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;

  onDelayDuration?: number;
  onDelayChange?: (term: string) => void;
  ariaLabel?: string;
}

const Select = ({
  disabled = false,
  compact = false,
  defaultInputValue,
  isCreatable = false,
  isClearable = false,
  isLoading = false,
  isAsync = false,
  options = [],
  isMulti = false,
  loadOptionsDebounce,
  loadOptions,
  labelFallback,
  onSelectionChange,
  isSearchable = true,
  renderOption = (props) => props.label,
  renderValue = (props) => props.label,
  defaultOptions,
  placeholder,
  menuTop = false,
  menuPlacement = "auto",

  value = "",

  onBlur,
  onFocus,

  // FormGroupProps
  nobox = false,
  label,
  className = "Select",
  id = "",
  required,
  message,
  text,

  onChange,
  onDelayDuration = 100,
  onDelayChange,
  ariaLabel,
}: SelectProps) => {
  const { t } = useTranslation();

  const [loadedOptions, setLoadedOptions] = useState<CleanSelectOption[]>([]);
  const loadOptionsDebounceRef = useRef<{
    timer: ReturnType<typeof setTimeout> | null;
  }>({ timer: null });

  const [filled, setFormGroupFilled] = useState(value ? true : false);
  const [focused, setFocused] = useState(false);
  const [curVal, setCurVal] = useState(value || "");

  useEffect(() => {
    // this will also trigger the useEffect below, so there is no need to set the formGroupFilled state from here
    setCurVal(value);
  }, [value]);
  useEffect(() => {
    setFormGroupFilled(curVal ? true : false);
  }, [curVal]);

  useDebounce(curVal, onDelayDuration, onDelayChange);

  const onChangeHandler = (
    newValue: CleanSelectValue | CleanSelectValue[] | undefined | null,
    action: ActionMeta<unknown>
  ) => {
    let stringValue = "";
    if (!newValue) {
      // don't do nothing
    } else if (isMulti && Array.isArray(newValue)) {
      stringValue = newValue.map((item) => item.value).join(",");
    } else if (newValue && !Array.isArray(newValue)) {
      if ("label" in newValue && typeof newValue.value === "string") {
        stringValue = newValue.value || "";
      }
    }
    setCurVal(stringValue);
    onChange?.(stringValue);
    onSelectionChange?.(newValue, action);
  };

  const __loadOptions = async (
    inputValue: string,
    callback: (options: CleanSelectOption[]) => void
  ) => {
    loadOptionsDebounceRef.current.timer &&
      clearTimeout(loadOptionsDebounceRef.current.timer);
    const loadOptionsDebouncePromise = await new Promise<CleanSelectOption[]>(
      (res) => {
        loadOptionsDebounceRef.current.timer = setTimeout(async () => {
          const result = await loadOptions?.(inputValue, callback);
          if (result && Array.isArray(result)) {
            const filteredResult = loadedOptions
              ? result.filter(
                  (option) =>
                    loadedOptions.findIndex(
                      (loadOption) => loadOption.value === option.value
                    ) === -1
                )
              : result;

            setLoadedOptions([...filteredResult, ...loadedOptions]);
            res(result);
          } else {
            if (loadedOptions) {
              res(loadedOptions);
            }
          }
          res([]);
        }, loadOptionsDebounce || 0);
      }
    );
    return loadOptionsDebouncePromise;
  };
  let valueObject: CleanSelectValue | CleanSelectValue[] | undefined =
    undefined;
  let existingOptions: CleanSelectOption[] = [];
  if (isAsync) {
    let filteredDefaultOptions: CleanSelectOption[] = [];
    if (defaultOptions && defaultOptions !== true && defaultOptions.length) {
      filteredDefaultOptions = defaultOptions;
      if (loadedOptions && loadedOptions.length) {
        filteredDefaultOptions = defaultOptions.filter(
          (option) =>
            loadedOptions.findIndex(
              (loadedOption) => option.value === loadedOption.value
            ) === -1
        );
      }
    }
    existingOptions = [...filteredDefaultOptions, ...loadedOptions];
  } else {
    existingOptions = options || [];
  }
  if (!isMulti) {
    if (curVal) {
      // iterate through values or groups
      existingOptions.forEach((option) => {
        if (option.value === curVal) {
          valueObject = option;
          return;
        }
        if (option.options) {
          const subItem = option.options.find(
            (subOption) => subOption.value === curVal
          );
          if (subItem) {
            valueObject = subItem;
            return;
          }
        }
      });

      if (!valueObject) {
        if (isCreatable) {
          valueObject = {
            value: curVal,
            label: curVal,
          };
        } else {
          valueObject = {
            value: curVal,
            label: labelFallback?.(curVal) || `${curVal} ...`,
          };
        }
      }
    } else if (isCreatable && curVal === "") {
      valueObject = undefined;
    } else if (isCreatable) {
      valueObject = {
        value: curVal,
        label: curVal,
      };
    } else {
      valueObject = undefined;
    }
  } else {
    let multiyValueObject: CleanSelectValue[] = [];

    if (curVal && typeof curVal === "string") {
      const valueArray = curVal
        .trim()
        .split(",")
        .reduce(
          (acc: string[], item: string) =>
            acc.includes(item.trim()) ? acc : [...acc, item.trim()],
          []
        );
      valueArray.forEach((val) => {
        if (!val) return;

        // array because of an open TS bug: https://github.com/microsoft/TypeScript/issues/11498
        const foundExistingOption: CleanSelectOption[] = [];

        // iterate through values or groups
        existingOptions.forEach((option) => {
          if (option.value === val) {
            foundExistingOption.push(option);
            return;
          }
          if (option.options) {
            const subItem = option.options.find(
              (subOption) => subOption.value === val
            );
            if (subItem) {
              foundExistingOption.push(subItem);
              return;
            }
          }
        });

        if (foundExistingOption.length) {
          multiyValueObject.push(...foundExistingOption);
        } else if (isCreatable) {
          multiyValueObject.push({ value: val, label: val });
        } else {
          multiyValueObject.push({
            value: val,
            label: labelFallback?.(curVal) || `${val} ...`,
          });
        }
      });
    }
    valueObject = multiyValueObject;
  }

  // the default props for different select types
  const defaultProps: ReactSelectProps = {
    "aria-label": ariaLabel,
    menuPlacement: menuPlacement,
    theme: (theme) => {
      return {
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#f5faff",
          // primary too light to make 3 shades
          primary75: "#f2f9fc",
          primary50: "#f2f9fc",
          primary25: "#f2f9fc",
        },
      };
    },
    styles: {
      control: (base, state) => {
        // split styles because types
        const hoverStyles: {
          borderColor: string;
          borderTopColor?: string;
          borderBottomColor?: string;
        } = {
          borderColor: "#000",
        };
        const styles = {
          ...base,
          minHeight: 39,
          lineHeight: "initial",
          boxShadow: "none",
          border: "1px solid #8993A3",
          "&:hover": hoverStyles,
        };

        if (menuTop) {
          styles.borderTopRightRadius = state.selectProps.menuIsOpen ? 0 : 4;
          styles.borderTopLeftRadius = state.selectProps.menuIsOpen ? 0 : 4;
        } else {
          styles.borderBottomRightRadius = state.selectProps.menuIsOpen ? 0 : 4;
          styles.borderBottomLeftRadius = state.selectProps.menuIsOpen ? 0 : 4;
        }
        if (compact) {
          styles.fontSize = 14;
        }
        if (disabled) {
          styles.opacity = 0.5;
          styles.pointerEvents = "none";
        }
        if (state.isFocused) {
          styles.border = "1px solid #000";
        }

        if (state.selectProps.menuIsOpen && state.isFocused) {
          if (menuTop) {
            styles.borderTop = "1px solid #eee";
            styles["&:hover"] = {
              ...styles["&:hover"],
              borderTopColor: "#eee",
            };
          } else {
            styles.borderBottom = "1px solid #eee";
            styles["&:hover"] = {
              ...styles["&:hover"],
              borderBottomColor: "#eee",
            };
          }
        }
        return styles;
      },
      multiValue: (base) => {
        const styles = {
          ...base,
          backgroundColor: "rgb(var(--cs-shade-100))",
          color: "rgb(var(--cs-shade-900))",
          border: "1px solid",
          borderColor: "rgb(var(--cs-shade-200))",
          borderRadius: "0.25rem",
          fontSize: 20,
        };
        if (compact) {
          styles.fontSize = 14;
        }
        return styles;
      },
      multiValueRemove: (base) => {
        return {
          ...base,
          // borderBottomLeftRadius: 0,
          // borderTopLeftRadius: 0,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgb(var(--cs-danger-100))",
            color: "rgb(var(--cs-danger-dark))",
            path: {
              stroke: "rgb(var(--cs-danger-dark))",
            },
          },
        };
      },
      menu: (base) => {
        const styles = {
          ...base,
          width: "calc(100% - 2px)",
          marginLeft: "1px",
          zIndex: 100000,
          color: "#000",
        };

        if (menuTop) {
          styles.marginBottom = 0;
          styles.borderBottomRightRadius = 0;
          styles.borderBottomLeftRadius = 0;
        } else {
          styles.marginTop = 0;
          styles.borderTopRightRadius = 0;
          styles.borderTopLeftRadius = 0;
        }

        if (compact) {
          styles.fontSize = 14;
        }
        return styles;
      },
      menuList: (base) => {
        const styles = {
          ...base,
        };

        if (menuTop) {
          styles.paddingBottom = 0;
          styles.borderBottomRightRadius = 0;
          styles.borderBottomLeftRadius = 0;
        } else {
          styles.paddingTop = 0;
          styles.borderTopRightRadius = 0;
          styles.borderTopLeftRadius = 0;
        }

        return styles;
      },
      dropdownIndicator: (base, state) => {
        return {
          ...base,
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen
            ? "rotate(180deg)"
            : undefined,
          padding: 11,
          polyline: {
            stroke: "#999999",
          },
          "&:hover": {
            polyline: {
              stroke: "#000000",
            },
          },
        };
      },
      clearIndicator: (base) => {
        return {
          ...base,
          path: {
            stroke: "#999999",
          },
          "&:hover": {
            path: {
              stroke: "#000000",
            },
          },
        };
      },
      indicatorSeparator: (base) => {
        const styles = {
          ...base,
          width: 0,
        };
        if (compact) {
          styles.display = "none";
        }
        return styles;
      },
      valueContainer: (base) => {
        if (nobox) {
          return {
            ...base,
            paddingLeft: 0,
          };
        }
        return {
          ...base,
        };
      },
      option: (base, state) => {
        return {
          ...base,
          color: state.isSelected || state.isFocused ? "#333" : "#666",
        };
      },
    },
    placeholder: placeholder === undefined ? t("Select") : placeholder,
    value: valueObject,
    defaultInputValue: defaultInputValue,
    className,
    classNamePrefix: "Select",
    options,
    isMulti,
    isSearchable,
    isClearable,
    isLoading,
    onChange: (newValue, action) => {
      onChangeHandler(newValue as CleanSelectValue, action);
    },
    onFocus: () => {
      onFocus?.(curVal);
      setFocused(true);
    },
    onBlur: () => {
      onBlur?.(curVal);
      setFocused(false);
    },
    components: {
      Option: (props) => (
        <SelectCustomOption props={props} renderOption={renderOption} />
      ),
      SingleValue: (props) =>
        !isMulti ? (
          <SelectCustomSingleValue props={props} renderValue={renderValue} />
        ) : null,
      MultiValueLabel: (props) =>
        isMulti ? (
          <SelectCustomMultipleValue props={props} renderValue={renderValue} />
        ) : null,
      DropdownIndicator: (props) => (
        <SelectCustomDropDownIndicator props={props} />
      ),
      ClearIndicator: (props) => <SelectCustomClearIndicator props={props} />,
      MultiValueRemove: (props) => (
        <SelectComponentMultiValueRemove props={props} />
      ),
    },
    noOptionsMessage: () => t("No results found"),
    formatCreateLabel: (newlabel: string) => `${t("Add")}: "${newlabel}"`,
  };
  const loadingMessage = () => t("...Loading");

  return (
    <FormGroup
      filled={filled}
      focused={focused}
      nobox={nobox}
      label={label}
      className={className}
      id={id}
      required={required}
      message={message}
      text={text}
    >
      {!isCreatable && !isAsync && <ReactSelect {...defaultProps} />}
      {isCreatable && !isAsync && <ReactSelectCreatable {...defaultProps} />}
      {!isCreatable && isAsync && (
        <ReactSelectAsync
          loadOptions={__loadOptions}
          defaultOptions={
            defaultOptions === true ? defaultOptions : existingOptions
          }
          loadingMessage={loadingMessage}
          aria-label={ariaLabel}
          {...defaultProps}
        />
      )}
      {isCreatable && isAsync && (
        <ReactSelectAsyncCreatable
          loadOptions={__loadOptions}
          defaultOptions={
            defaultOptions === true ? defaultOptions : existingOptions
          }
          loadingMessage={loadingMessage}
          aria-label={ariaLabel}
          {...defaultProps}
        />
      )}
    </FormGroup>
  );
};

export default Select;
