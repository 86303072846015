import { type CleanSelectOption } from "@casasoft/styleguide/components/formElements/Select";
import { useCallback, useMemo } from "react";
import { useIaziData } from "./useIaziData";
import type { IaziPropertySettingsProps } from "../IaziPropertySettings";
import { useIaziDataMapper } from "./useIaziDataMapper";

interface UseIaziPropertyOptionsProps
  extends Omit<IaziPropertySettingsProps, "onEnabledToggle"> {}

export const useIaziPropertyOptions = ({
  address,
  isEnabled,
  selectedProperty,
  onSelectProperty,
  onDataFetch,
}: UseIaziPropertyOptionsProps) => {
  const handleDataFetch = useCallback(
    (hasPropertyOptions: boolean) => {
      onSelectProperty(undefined);
      onDataFetch(hasPropertyOptions);
    },
    [onSelectProperty, onDataFetch]
  );

  const { iaziData, lastDataCall } = useIaziData({
    address,
    iaziEnabled: isEnabled,
    onFetch: handleDataFetch,
  });

  const { mapIaziDataToOptions } = useIaziDataMapper();

  const iaziPropertyOptions: CleanSelectOption[] = useMemo(
    () => mapIaziDataToOptions(iaziData),
    [iaziData, mapIaziDataToOptions]
  );

  const handleSelectIaziOption = (val: string) => {
    const selectedOption = iaziData.find((option) => option.uid === val);

    if (selectedOption) {
      onSelectProperty(selectedOption);
    }
  };

  const selectedPropertyId: string | undefined = useMemo(() => {
    const foundOption = iaziData.find(
      (option) => option.uid === selectedProperty?.uid
    );

    return foundOption?.uid;
  }, [iaziData, selectedProperty]);

  return {
    iaziPropertyOptions,
    handleSelectIaziOption,
    selectedPropertyId,
    lastDataCall,
  };
};
