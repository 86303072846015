import { Fragment, useEffect, useState } from "react";
import ToolbarMenu from "./ToolbarMenu";
import ToolbarBrand from "./ToolbarBrand";
import ToolbarNavToggle from "./ToolbarNavToggle";
import ToolbarMenuItem, { ToolbarMenuItemProps } from "./ToolbarMenuItem";

export interface ToolbarMenuItemShape
  extends Omit<ToolbarMenuItemProps, "isMobile" | "itemKey"> {
  key: string;
}

export interface ToolbarProps {
  brand: JSX.Element;
  toggleNav: (navToggle: boolean) => void;
  closeMenu: boolean;
  profileIcon?: JSX.Element;
  profileInfo?: {
    firstName: string;
    lastName: string;
    role: string;
  };
  mobileItems?: (ToolbarMenuItemShape | null | false)[];
  desktopItems?: (ToolbarMenuItemShape | null | false)[];
}

const Toolbar = ({
  brand,
  toggleNav,
  closeMenu = false,
  profileIcon,
  profileInfo,
  desktopItems = [],
  mobileItems = [],
}: ToolbarProps) => {
  const [navToggle, setNavToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);

  useEffect(() => {
    if (closeMenu) {
      setMenuToggle(false);
    }
  }, [menuToggle, closeMenu]);

  return (
    <div className="tw-relative tw-z-20 tw-flex tw-items-center tw-justify-between tw-w-full tw-text-base tw-bg-cs-shade-700">
      <div className="tw-flex tw-items-center">
        <ToolbarNavToggle
          isOpen={navToggle}
          onToggle={() => {
            toggleNav(!navToggle);
            setNavToggle(!navToggle);
            setMenuToggle(false);
          }}
        />

        <ToolbarBrand>{brand}</ToolbarBrand>
      </div>
      {/* MOBILE PROFILE ICON TOGGLE BUTTON */}
      <button
        className={`md:tw-hidden tw-relative tw-flex tw-justify-center tw-items-center tw-w-[42px] tw-h-[42px] tw-p-0 tw-mr-2 tw-text-white tw-bg-transparent tw-border-0 tw-transition-opacity

          before:tw-absolute before:tw-right-1 before:tw-h-[2px] before:tw-w-6 before:tw-bg-cs-shade-100 before:tw-transition-opacity before:tw-content-['']
          after:tw-absolute after:tw-right-1 after:tw-h-[2px] after:tw-w-6 after:tw-bg-cs-shade-100 after:tw-transition-opacity after:tw-content-['']

          before:tw-top[calc(50%-1px)] before:tw-rotate-45
          after:tw-bottom[calc(50%-1px)] after:tw--rotate-45
          ${
            menuToggle
              ? "before:tw-opacity-100 after:tw-opacity-100"
              : "before:tw-opacity-0 after:tw-opacity-0"
          }
        `}
        onClick={() => {
          setMenuToggle(!menuToggle);
          toggleNav(false);
          setNavToggle(false);
        }}
      >
        <span
          className={`tw-transition-opacity ${
            menuToggle ? "tw-opacity-0" : "tw-opacity-100"
          }`}
        >
          {profileIcon}
        </span>
      </button>
      <ToolbarMenu
        isMobile={false}
        menuToggle={menuToggle}
        profileIcon={profileIcon}
        profileInfo={profileInfo}
      >
        {desktopItems.map((desktopItem, i) => {
          if (!desktopItem) {
            return <Fragment key={i} />;
          }
          return (
            <ToolbarMenuItem
              action={desktopItem.action}
              icon={desktopItem.icon}
              suffixIcon={desktopItem.suffixIcon}
              label={desktopItem.label}
              key={desktopItem.key}
              itemKey={desktopItem.key}
              isMobile={false}
            />
          );
        })}
      </ToolbarMenu>
      <ToolbarMenu
        isMobile
        menuToggle={menuToggle}
        profileIcon={profileIcon}
        profileInfo={profileInfo}
      >
        {mobileItems.map((mobileItem, i) => {
          if (!mobileItem) {
            return <Fragment key={i} />;
          }
          return (
            <ToolbarMenuItem
              action={mobileItem.action}
              icon={mobileItem.icon}
              suffixIcon={mobileItem.suffixIcon}
              label={mobileItem.label}
              key={mobileItem.key}
              itemKey={mobileItem.key}
              isMobile
            />
          );
        })}
      </ToolbarMenu>
    </div>
  );
};

export default Toolbar;
