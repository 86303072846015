import axios from "axios";

import {
  interceptErrorToSentry,
  sentryWarningsResponseInterceptor,
  startTimeRequestInterceptor,
} from "./issueTrackerInterceptors";

const axiosPublicInstance = axios.create();

// track request time + size
axiosPublicInstance.interceptors.request.use(startTimeRequestInterceptor);
axiosPublicInstance.interceptors.response.use(
  sentryWarningsResponseInterceptor,
  interceptErrorToSentry
);

export default axiosPublicInstance;
