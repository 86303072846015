import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "sole-enterprise",
    label: "Sole Enterprise",
  },
  {
    value: "llc",
    label: "LLC",
  },
  {
    value: "ltd",
    label: "Ltd",
  },
  {
    value: "general-partnership",
    label: "General Partnership",
  },
  {
    value: "foundation",
    label: "Foundation",
  },
  {
    value: "branch",
    label: "Legal branch",
  },
  {
    value: "public-sector-institution",
    label: "Public sector institution",
  },
  {
    value: "cooperative",
    label: "Cooperative",
  },
  {
    value: "association",
    label: "Association",
  },
  {
    value: "limited-partnership",
    label: "Limited Partnership",
  },
];

export const getLegalForms = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLegalFormLabel = createGetLabel(untranslatedList);

export const useLegalForms = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
