import { CasaoneResultDescription } from "api/types";
import { GetEventListQueryParams, GetEventListResponse } from "./types";

const providesTags: CasaoneResultDescription<
  GetEventListResponse,
  GetEventListQueryParams
> = (result) => {
  if (result) {
    const eventTags = result._embedded.event.map((item) => ({
      type: "Event" as const,
      id: item.id,
    }));

    return [
      ...eventTags,
      {
        type: "Event",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["Event"];
};

export default providesTags;
