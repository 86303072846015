import { Spinner } from "../../helpers-ux";
import cx from "classnames";

interface MediaCHooserLoadingSpinnerProps {
  className?: string;
}

export const MediaChooserLoadingSpinner = ({
  className,
}: MediaCHooserLoadingSpinnerProps) => (
  <div
    className={cx("spinner-fixture", "tw-text-sm", className)}
    style={{
      width: "2em",
      height: "2em",
      transform: "scale(0.5)",
    }}
  >
    <Spinner />
  </div>
);
