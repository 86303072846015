import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "@casasoft/styleguide/components/card";
import Select from "@casasoft/styleguide/components/formElements/Select";
import { useTranslation } from "react-i18next";
import resourceGrabber from "utilities/resourceGrabber";

import PropertyRelationCard from "../../property/PropertyRelationCard";
import { getPhaseLabel } from "./PhaseExcerpt";
import PhaseOptionsExcerpt from "./PhaseOptionsExcerpt";
import { EventShape } from "entities/event/types";
import Config from "config";
import { PropertyContactRelationTypes } from "utilities";
import EventContactChooseCard from "components/event/EventContactChooseCard";
import {
  FormModalCols,
  FormModalColsItem,
} from "@casasoft/styleguide/components/form-modal";
import { axiosInstance } from "utilities/axios";
import ApiErrorReport from "@casasoft/styleguide/components/helpers-ux/ApiErrorReport";
import {
  useGetPhaseListQuery,
  useGetPhaseRelationshipQuery,
} from "api/entities/phase";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import usePhaseByEvent from "hooks/usePhaseByEvent";
import isMarketingMethod from "utilities/type-helpers/isMarketingMethod";

interface CreatePhaseRelationsGroupProps {
  hasRecipientList?: boolean;
  hideRelationShipCards?: boolean;
  disableRelationTypeSelect?: boolean;
  values: {
    event?: {
      baseType?: string;
      eventType?: string | null;
    };
    property?: string;
    propertyMarketingMethod?: string;
    contact?: string;
    relationType?: string;
    relationPhase?: string;
  };
  currentEventToEdit?: EventShape;
  onChange: (data: {
    contact?: string;
    property?: string;
    propertyMarketingMethod?: string;
    relationType?: string;
    relationPhase?: string;
  }) => void;
}

const CreatePhaseRelationsGroup = ({
  hasRecipientList = false,
  values: internalValues,
  hideRelationShipCards,
  disableRelationTypeSelect,
  onChange,
  currentEventToEdit,
}: CreatePhaseRelationsGroupProps) => {
  const { event, ...values } = internalValues;

  const { t } = useTranslation();

  const editFormId = currentEventToEdit?.id;
  const [isLatestEvent, setIsLatestEvent] = useState<boolean | "error">(
    !editFormId
  );

  useEffect(() => {
    if (editFormId) {
      if (values.contact && values.property && !hasRecipientList) {
        const checkIsLatestEvent = async () => {
          try {
            const resJson = await axiosInstance.get(
              `${Config.api2Url}/company/${Config.customerKey}/check-event-is-most-recent-having-phase-effect?eventId=${editFormId}&propertyId=${values.property}&contactId=${values.contact}`
            );
            if (resJson.status !== 200) {
              throw new Error("Failed to check if is latest event");
            }
            if (resJson.data.latestEvent) {
              setIsLatestEvent(true);
            } else {
              setIsLatestEvent(false);
            }
          } catch (error) {
            setIsLatestEvent("error");
          }
        };
        checkIsLatestEvent();
      }
      // its a create-form, always the latest event
    } else {
      setIsLatestEvent(true);
    }
  }, [values.contact, values.property, editFormId, hasRecipientList]);

  // check if a relationship already exists

  // TODO: why not use config customerKey? 🤔
  const profile = resourceGrabber.grab("profile", "auth");
  const isCrm =
    profile?.data?.company?.id === "casasoft" &&
    profile?.data?.apiCompanySection === "casasoft";

  const mMethod = isMarketingMethod(values.propertyMarketingMethod)
    ? values.propertyMarketingMethod
    : undefined;

  const getPhaseListResult = useGetPhaseListQuery(
    mMethod
      ? {
          phaseForMarketingMethod: mMethod,
        }
      : skipToken
  );

  const relationship =
    !hasRecipientList && values.contact && values.property
      ? {
          contact: values.contact,
          property: values.property,
        }
      : undefined;

  const getPhaseRelationshipResult = useGetPhaseRelationshipQuery(
    relationship || skipToken
  );

  const phaseByEvent = usePhaseByEvent({
    baseType: event?.baseType,
    eventType: event?.eventType,
    marketingMethod: mMethod,
  });

  if (getPhaseListResult.isError) {
    return (
      <div>
        <ApiErrorReport
          context="Phase Relation Group (phase list)"
          error={getPhaseListResult.error}
        />
      </div>
    );
  }

  if (getPhaseRelationshipResult.isError) {
    return (
      <div>
        <ApiErrorReport
          context="Phase Relation Group (latest phase)"
          error={getPhaseRelationshipResult.error}
        />
      </div>
    );
  }

  const latestEventPhase = getPhaseRelationshipResult.data?.latestPhase;

  return (
    <>
      {!hideRelationShipCards && (
        <FormModalCols>
          <FormModalColsItem>
            <EventContactChooseCard
              contactType="individual-and-legal-no-users-no-subsidiaries"
              contactId={values.contact}
              editingEventId={editFormId}
              headerTitle={t("Contact")}
              chooserHeaderTitle={t("Choose contact")}
              onChange={(id) => {
                onChange({ ...values, contact: id || undefined });
              }}
            />
          </FormModalColsItem>
          {!isCrm && (
            <FormModalColsItem>
              <PropertyRelationCard
                entityId={values.property}
                headerTitle={t("Property")}
                onChange={(id, rawProperty) => {
                  onChange({
                    ...values,
                    property: id || undefined,
                    propertyMarketingMethod:
                      rawProperty?.marketingMethod || undefined,
                  });
                }}
              />
            </FormModalColsItem>
          )}
        </FormModalCols>
      )}
      {(values.contact || hideRelationShipCards) &&
        values.property &&
        getPhaseListResult.isSuccess && (
          <>
            <div className="tw-mb-4" />
            <div>
              <Card>
                <CardHeader>
                  {getPhaseRelationshipResult.data?.hasRelationship
                    ? t("Edit contact relation")
                    : t("Create contact relation")}
                </CardHeader>
                <CardBody>
                  <div className="tw-grid lg:tw-grid-cols-2 tw-gap-4">
                    <div className="tw-min-w-0">
                      <Select
                        disabled={
                          disableRelationTypeSelect ||
                          (values.relationType === "prospective-customer" &&
                          latestEventPhase &&
                          editFormId
                            ? true
                            : false)
                        }
                        nobox
                        className="tw-mb-0"
                        label={t("Relation to property")}
                        placeholder={t("Choose a relation")}
                        value={values.relationType}
                        onChange={(value: string) => {
                          onChange({
                            ...values,
                            relationType: value,
                            relationPhase:
                              value !== "prospective-customer"
                                ? undefined
                                : values.relationPhase,
                          });
                        }}
                        options={PropertyContactRelationTypes.getOptions()}
                      />
                    </div>
                    {values.relationType === "prospective-customer" &&
                      latestEventPhase && (
                        <div className="tw-min-w-0">
                          <Select
                            disabled
                            nobox
                            className="tw-mb-0"
                            label={t("Current phase")}
                            placeholder={t("Choose a phase")}
                            value={
                              !isLatestEvent || !values.relationPhase
                                ? latestEventPhase || ""
                                : values.relationPhase || ""
                            } // empty string as a fallback because of a Select render bug
                            options={
                              getPhaseListResult.data._embedded.phase?.map(
                                (phase, i) => {
                                  return {
                                    value: phase.id,
                                    color: phase?.color,
                                    rank: i + 1,
                                    label: getPhaseLabel(phase) || phase.id,
                                  };
                                }
                              ) || []
                            }
                            renderValue={(val) => {
                              return (
                                <PhaseOptionsExcerpt
                                  value={val.value}
                                  label={val.label}
                                  color={val.color}
                                  rank={val.rank}
                                  previousPhase={getPhaseListResult.data._embedded.phase.find(
                                    (phase) => phase.id === latestEventPhase
                                  )}
                                />
                              );
                            }}
                            renderOption={(val) => {
                              return (
                                <PhaseOptionsExcerpt
                                  value={val.value}
                                  label={val.label}
                                  color={val.color}
                                  rank={val.rank}
                                  // previousPhase={
                                  //   !hideRelationShipCards
                                  //     ? propertyContactsStore.items[0]?._embedded
                                  //         ?.phase
                                  //     : undefined
                                  // }
                                  size="lg"
                                />
                              );
                            }}
                          />
                        </div>
                      )}
                    <div className="tw-min-w-0">
                      {values.relationType === "prospective-customer" && (
                        <Select
                          nobox
                          disabled={phaseByEvent.loading}
                          className="tw-mb-0"
                          isClearable={!editFormId}
                          isCreatable
                          label={t("Event phase")}
                          placeholder={t("Choose a phase")}
                          value={values.relationPhase || ""} // empty string as a fallback because of a Select render bug
                          options={
                            getPhaseListResult.data._embedded.phase.map(
                              (phase, i) => {
                                return {
                                  value: phase.id,
                                  color: phase?.color,
                                  rank: i + 1,
                                  label: getPhaseLabel(phase) || phase.id,
                                };
                              }
                            ) || []
                          }
                          onChange={(phase) => {
                            onChange({
                              ...values,
                              relationPhase: phase || undefined,
                            });
                          }}
                          renderValue={(val) => {
                            return (
                              <PhaseOptionsExcerpt
                                value={val.value}
                                label={val.label}
                                color={val.color}
                                rank={val.rank}
                              />
                            );
                          }}
                          renderOption={(val) => {
                            return (
                              <PhaseOptionsExcerpt
                                value={val.value}
                                label={val.label}
                                color={val.color}
                                rank={val.rank}
                                size="lg"
                              />
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </>
        )}
    </>
  );
};

export default CreatePhaseRelationsGroup;
