import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

export interface AlertProps {
  color: "success" | "info" | "danger" | "warning";
  small?: boolean;
  compact?: boolean;
  children: ReactNode;
}

function Alert({ color, children, small, compact = false }: AlertProps) {
  const styleClasses: string[] = [];
  let icon = faExclamationTriangle;
  switch (color) {
    case "warning":
      styleClasses.push(
        "tw-bg-cs-warning-100",
        "tw-border-cs-warning-200",
        "tw-text-cs-warning-300"
      );
      break;
    case "danger":
      styleClasses.push(
        "tw-bg-cs-danger-100",
        "tw-border-cs-danger-200",
        "tw-text-cs-danger-300"
      );
      break;
    case "success":
      styleClasses.push(
        "tw-bg-cs-success-100",
        "tw-border-cs-success-200",
        "tw-text-cs-success-300"
      );
      icon = faCheckCircle;
      break;
    case "info":
    default:
      styleClasses.push(
        "tw-bg-cs-info-100",
        "tw-border-cs-info-200",
        "tw-text-cs-info-300"
      );
      icon = faInfoCircle;
      break;
  }

  if (small) {
    styleClasses.push("tw-text-sm");
  }
  if (compact) {
    styleClasses.push("tw-py-2 tw-px-2");
  } else {
    styleClasses.push("tw-py-3 tw-px-5");
  }
  return (
    <div
      className={`tw-flex tw-border tw-rounded tw-border-solid tw-mb-4 last:tw-mb-0 ${styleClasses.join(
        " "
      )}`}
    >
      <div className="tw-shrink-0">
        <FontAwesomeIcon icon={icon} className="tw-mr-2" />
      </div>
      <div>{children}</div>
    </div>
  );
}

export default Alert;
