import { CasaoneBaseQueryArgs } from "api/types";
import queryBuilder from "utilities/queryBuilder";
import { GetContactListQueryParams } from "./types";

function query({ query }: GetContactListQueryParams): CasaoneBaseQueryArgs {
  const builtQuery = queryBuilder.buildToUrl(query.orderBy, query.filter, {
    pagesize: query.pageSize,
    page: query.page,
  });
  return {
    url: `contact?${builtQuery}`,
    method: "GET",
  };
}

export default query;
