import { CreateUserResponse, CreateUserQueryParams } from "./types";
import { createInvalidatesTags } from "api/createEndpoint";

const invalidatesTags = createInvalidatesTags<
  CreateUserResponse,
  CreateUserQueryParams
>((result, error, queryParams) => {
  if (result) {
    return [{ type: "User", id: queryParams.username }];
  }

  return ["User"];
});

export default invalidatesTags;
