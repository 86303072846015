import { ReactNode } from "react";

interface ToolbarBrandProps {
  children?: ReactNode;
}

const ToolbarBrand = ({ children }: ToolbarBrandProps) => {
  return <div className="tw-py-3 tw-px-4">{children}</div>;
};

export default ToolbarBrand;
