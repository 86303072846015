import { GetTemplatesListQueryParams, GetTemplatesListResponse } from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetTemplatesListResponse,
  GetTemplatesListQueryParams
>((result) => {
  if (result) {
    const templateTags = result._embedded.template.map((item) => ({
      type: "Template" as const,
      id: item.id,
    }));

    // merge subsidiary tags with static tags
    return [
      ...templateTags,
      {
        type: "Template",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["Template"];
});

export default providesTags;
