import { useState } from "react";
import NumberField from "@casasoft/styleguide/components/forms/NumberField";
import SwitchButton from "@casasoft/styleguide/components/formElements/SwitchButton";
import { Numvals } from "utilities";

interface PropertyNumberOfRoomsFieldProps {
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  message: any;
  id?: string;
  autoFocus?: boolean;
  required?: boolean;
}

const PropertyNumberOfRoomsField = ({
  value = "",
  onChange,
  onBlur,
  message = [],
  id = "numberOfRooms",
  autoFocus = false,
  required = false,
}: PropertyNumberOfRoomsFieldProps) => {
  const [forcedField, setForcedField] = useState<boolean>(false);

  return (
    <>
      {forcedField || parseFloat(value) > 5 || parseFloat(value) < 3 ? (
        <NumberField
          nobox
          id={id}
          label={Numvals.numvalLabel("numberOfRooms")}
          value={value}
          onChange={(changeValue) => {
            setForcedField(true);
            onChange?.(changeValue);
          }}
          onBlur={(blurValue: string) => {
            onBlur?.(blurValue);
          }}
          min={0}
          step={0.5}
          decimalPlaces={1}
          message={message}
          autoFocus={autoFocus}
          required={required}
        />
      ) : (
        <SwitchButton
          id={id}
          label={Numvals.numvalLabel("numberOfRooms")}
          value={value}
          onChange={(changeValue) => {
            onChange?.(changeValue || "");
          }}
          message={message}
          buttons={[
            {
              label: "-",
              value: "2.5",
            },
            {
              label: "3",
              value: "3",
            },
            {
              label: "3½",
              value: "3.5",
            },
            {
              label: "4",
              value: "4",
            },
            {
              label: "4½",
              value: "4.5",
            },

            {
              label: "5",
              value: "5",
            },
            {
              label: "+",
              value: "5.5",
            },
          ]}
          required={required}
        />
      )}
    </>
  );
};

export default PropertyNumberOfRoomsField;
