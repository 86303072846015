import handleFormModalError, {
  type FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import {
  useCreateEventMutation,
  useUpdateEventMutation,
} from "api/entities/event";
import { getAttendeeData } from "components/event/helpers/attendees";
import { EventShape } from "entities/event/types";
import { type SubmitHandler } from "react-hook-form";
import { selectCasaoneUserContactId } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import csMoment from "utilities/csMoment";
import {
  mapTaskFormToCreateEventPayload,
  mapTaskFormToUpdateEventPayload,
} from "../helpers/taskEditFormHelpers";

export interface TaskItemFormShape {
  private: boolean;
  subject: string | null;
  endDate: string | null;
  endTime: string | null;
  html: string | null;
  recipient: string | null;
  property: string | null;
}

interface UseTaskEditFormProps {
  task?: EventShape; // if used as edit form
  onDone: (body: EventShape) => void;
  onFail: FormModalOnFail;
}

export const useTaskEditForm = ({
  onDone,
  onFail,
  task,
}: UseTaskEditFormProps) => {
  const flow: "create" | "update" = !!task ? "update" : "create";
  const contactId = useAppSelector(selectCasaoneUserContactId);
  const { recipient } = getAttendeeData(task?._embedded?.attendees);

  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();

  const formDefaultValues = {
    private: task?.private || false,
    subject: task?.subject || null,
    endDate:
      flow === "update"
        ? task?.end?.date
          ? csMoment(task.end.date).format("YYYY-MM-DD")
          : null
        : csMoment().add(1, "day").format("YYYY-MM-DD"),
    endTime:
      flow === "update"
        ? task?.end?.date
          ? csMoment(task.end.date).format("HH:mm")
          : null
        : csMoment().format("HH:mm"),
    html: task?.html || null,
    recipient: recipient?._embedded?.contact?.id || contactId || null,
    property: task?._embedded?.property?.id || null,
  };

  const onSubmit: SubmitHandler<TaskItemFormShape> = async (data) => {
    try {
      if (flow === "create") {
        const body = mapTaskFormToCreateEventPayload({
          contactId,
          formData: data,
        });

        const createdEvent = await createEvent(body).unwrap();

        if (!createdEvent) {
          throw new Error("An error occurred");
        }

        onDone(createdEvent);
      } else if (task) {
        // update flow
        const body = mapTaskFormToUpdateEventPayload({ task, formData: data });
        await updateEvent(body).unwrap();
        onDone(task);
      }
    } catch (error) {
      handleFormModalError(error, onFail);
    }
  };

  return {
    onSubmit,
    formDefaultValues,
  };
};
