import {
  GetSubsidiaryListQueryParams,
  GetSubsidiaryListResponse,
} from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetSubsidiaryListResponse,
  GetSubsidiaryListQueryParams
>((result) => {
  if (result) {
    // generate tags for each subsidiary
    const subsidiaryTags = result._embedded.subsidiary.map((item) => ({
      type: "Subsidiary" as const,
      id: item.id,
    }));

    // merge subsidiary tags with static tags
    return [
      ...subsidiaryTags,
      {
        type: "Subsidiary",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["Subsidiary"];
});

export default providesTags;
