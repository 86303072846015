import { ReactNode } from "react";

interface ModalBodyProps {
  children: ReactNode;
  className?: string;
}

function ModalBody({ children, className }: ModalBodyProps) {
  const style = className ? className : "tw-p-4";

  return <div className={`tw-cs-modal-body ${style}`}>{children}</div>;
}

export default ModalBody;
