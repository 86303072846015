import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import resourceHelper from "utilities/resource";
import resourceGrabber from "utilities/resourceGrabber";
import { cloneDeep } from "lodash";

import {
  changeResourcePage,
  changeResourcePageSize,
  changeResourceSearch,
  changeResourceSort,
  resetResourceState,
} from "redux/app";

import { FilterItem } from "utilities/queryBuilder";
import { RootState } from "redux/store";
import { TableListContainerProps } from "./TableListContainerProps";
import { UserFilterPresets } from "redux/auth";
import FilterFormSelect from "./FilterFormSelect";
import FilterForm from "./FilterForm";

/**
 * A wrapper for a table list that handles the fetching of the data and the pagination.
 * @deprecated Replaced by hooks (useTableListQuery + useTableListResourceOptions) (and the FilterFormContainer component)
 */
const TableListContainer = ({
  actualStore,
  userFilterToQueryFilter = (inputFilter, filterName, filterValue) => {
    return inputFilter;
  },
  renderTableList,
  userSortToQuerySort = (opts) => {
    return [
      {
        type: "customorderby",
        field: opts.key,
        alias: "",
        direction: opts.dir,
      },
    ];
  },
  mini = false,
  filterFormOptions,
  forcedFilter,
  presetName,
  sortable = false,
  defaultOrder = [
    {
      type: "field",
      field: "created",
      direction: "desc",
    },
  ],
  searchOrder = [
    {
      type: "field",
      field: "created",
      direction: "desc",
    },
  ],
  apiContext,
  defaultPageSize = 10,
  storeName,
  fetchAction,
  storeValidateContext,
  renderFilterForm,
}: TableListContainerProps) => {
  const [filterFormOpen, setFilterFormOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  // which presetOptions?
  const customPresetNameOrStoreName = presetName || storeName;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const isLoading =
      (actualStore && "isFetching" in actualStore && actualStore?.isFetching) ||
      (actualStore && "isUpdating" in actualStore && actualStore?.isUpdating);
    setLoading(isLoading);
  }, [actualStore]);

  // get specific resourceOptions from redux store
  const currentResourceoptions = useSelector(
    (state: RootState) => state.app.resourceOptions[customPresetNameOrStoreName]
  );
  const oldValidateContext =
    "lastQuery" in actualStore
      ? actualStore.lastQuery?.listValidateContext
      : undefined;
  useEffect(() => {
    if (oldValidateContext !== storeValidateContext) {
      dispatch(resetResourceState(customPresetNameOrStoreName));
    }
  }, [
    dispatch,
    oldValidateContext,
    storeValidateContext,
    customPresetNameOrStoreName,
  ]);

  // wait for currentResourceoptions to be reset for new validateContext
  if (oldValidateContext !== storeValidateContext && currentResourceoptions) {
    // reset page / searchfield state
    return <></>;
  }

  // gets the currently active filter preset and its values if present.
  const usersActiveFilterPreset: NonNullable<
    UserFilterPresets[string]
  >[number] = resourceGrabber.grab(
    "userFilterActivePreset",
    "auth",
    customPresetNameOrStoreName
  );

  // build up the current query and fetch the list
  // get page infos
  const page = currentResourceoptions?.page || 1;
  const pageSize = currentResourceoptions?.pageSize || defaultPageSize;
  const totalItems = sortable
    ? undefined
    : (actualStore &&
        "listMetadata" in actualStore &&
        actualStore?.listMetadata?.totalItems) ||
      undefined;

  // get searchTerm
  const searchTerm = currentResourceoptions?.searchTerm;
  const searchField =
    currentResourceoptions && "searchField" in currentResourceoptions
      ? currentResourceoptions.searchField
      : undefined;

  // get sorting
  const sortKey = currentResourceoptions?.sortKey;
  const sortDir = currentResourceoptions?.sortDir;

  // stop when the filter form hasn't been fetched yet (it should have a value or be null)
  let newFilter: FilterItem[] = [];

  // build up custom filter if there is an active FilterPreset
  if (usersActiveFilterPreset) {
    // clonedeep so the userFilterToQueryFilter mutations won't modify the data stored in redux
    const activeFormCopy = cloneDeep(usersActiveFilterPreset);
    Object.entries(activeFormCopy.preset).forEach((aPresetAr) => {
      const value = aPresetAr[1];
      newFilter = userFilterToQueryFilter(newFilter, aPresetAr[0], value);
    });
  }

  // add current search term to the filter
  if (searchTerm) {
    const newSearchFilter: FilterItem = {
      type: "search",
      value: searchTerm,
    };
    if (searchField && searchField !== "all") {
      newSearchFilter.field = searchField;
    }
    newFilter.push(newSearchFilter);
  }

  // build sort direction
  const sortOrder =
    sortKey && sortDir // TODO: snc needs doubleckeck
      ? userSortToQuerySort({
          key: sortKey,
          dir: sortDir,
        })
      : searchTerm
      ? searchOrder
      : defaultOrder;

  // wether to actually fetch or not is handled by the resourceHelper/fetchAction which compares the queries
  if (fetchAction) {
    fetchAction({
      filter: [...newFilter, ...(forcedFilter || [])],
      orderBy: sortOrder,
      pageSize: pageSize,
      page: page,
      apiContext: apiContext,
    });
  } else {
    resourceHelper.fetchList(
      storeName,
      [...newFilter, ...(forcedFilter || [])],
      sortOrder,
      pageSize,
      false,
      apiContext,
      undefined,
      page
    );
  }

  return (
    <>
      {renderTableList({
        loading,
        sortable,
        defaultSearchValue: searchTerm,
        searchFieldFilter: searchField,
        total: totalItems,
        itemsLoaded:
          actualStore &&
          "listMetadata" in actualStore &&
          typeof actualStore.listMetadata?.totalItems === "number"
            ? true
            : false,
        pageSize: pageSize,
        page: page,
        mini: mini,
        onPageChange: (newPage) => {
          dispatch(changeResourcePage(customPresetNameOrStoreName, newPage));
        },
        onSearchChange: (search, field) => {
          dispatch(
            changeResourceSearch(customPresetNameOrStoreName, search, field)
          );
        },
        onPageSizeChange: (newPageSize) => {
          dispatch(changeResourcePage(customPresetNameOrStoreName, 1));
          dispatch(
            changeResourcePageSize(customPresetNameOrStoreName, newPageSize)
          );
        },
        onSortChange: (sortConf) => {
          dispatch(changeResourcePage(customPresetNameOrStoreName, 1));
          dispatch(
            changeResourceSort(
              customPresetNameOrStoreName,
              sortConf.key,
              sortConf.dir
            )
          );
        },
        filterFormSelect: !mini && renderFilterForm && (
          <FilterFormSelect
            presetName={presetName || storeName}
            filterFormOptions={filterFormOptions}
            onCreateFilter={() => {
              setFilterFormOpen(true);
            }}
          />
        ),
        filterForm: !mini && renderFilterForm && (
          <FilterForm
            presetName={presetName || storeName}
            open={filterFormOpen}
            openStateControl={(state) => {
              if (state === "close") {
                setFilterFormOpen(false);
              }
              if (state === "open") {
                setFilterFormOpen(true);
              }
            }}
            filterFormOptions={filterFormOptions}
            renderFilterForm={renderFilterForm}
          />
        ),
      })}
    </>
  );
};

export default TableListContainer;
