import * as React from "react";
import {
  components as ReactSelectComponent,
  type MultiValueGenericProps,
} from "react-select";

interface Props {
  renderValue?: (option: any) => string | React.ReactChild;
  props: MultiValueGenericProps<any>;
}

const SelectCustomMultipleValue: React.FC<Props> = ({
  renderValue = (optionProps: any) => optionProps.label,
  props,
}: Props) => {
  let renderData;
  if (props?.data?.options) {
    renderData = props.data.options.find((item: any) => {
      if (props.data.label === item.label) {
        return item;
      } else {
        return null;
      }
    });
  } else {
    renderData = props.data;
  }
  return (
    <ReactSelectComponent.MultiValueLabel {...props}>
      {renderValue(renderData)}
    </ReactSelectComponent.MultiValueLabel>
  );
};

export default SelectCustomMultipleValue;
