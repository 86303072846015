import { UserSignatureShape } from "api/entities/userSignature/endpoints/list/types";
import { type MessageSignatureOption } from "../MessageSignature";

export const mapUserSignatures = (
  userSignatures: UserSignatureShape[]
): MessageSignatureOption[] =>
  userSignatures.map((signature) => ({
    value: signature.id.toString(),
    label: signature.name,
    signature,
  }));
