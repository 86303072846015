import * as Sentry from "@sentry/browser";
import Config from "config";
import { receiveProfile } from "redux/auth";
import store from "redux/store";
import {
  CO_ACCESS_TOKEN,
  CO_ACCESS_TOKEN_EXPIRE,
  CO_REFRESH_TOKEN,
  CO_REFRESH_TOKEN_EXPIRE,
} from "./constants";

async function clearAuthTokens() {
  try {
    const res = await fetch(
      `${Config.casaconnectUrl}/realms/casasoft/protocol/openid-connect/logout`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          refresh_token: window.localStorage.getItem(CO_REFRESH_TOKEN) || "",
          client_id: "casaone-app",
        }),
      }
    );
    if (res.status !== 204) {
      console.error("The following response was not handled");
      console.error(res);
      throw new Error("Logout status code expected to be 204");
    }
  } catch (err) {
    const error = new Error(`Logoutfailed without a known reason`);
    Object.assign(
      error,
      err instanceof Error
        ? err
        : {
            error: err,
          }
    );
    Sentry.captureException(error);
  } finally {
    window.localStorage.removeItem(CO_ACCESS_TOKEN);
    window.localStorage.removeItem(CO_REFRESH_TOKEN);
    window.localStorage.removeItem(CO_ACCESS_TOKEN_EXPIRE);
    window.localStorage.removeItem(CO_REFRESH_TOKEN_EXPIRE);
    // clear profile, by dispatching an empty profile to redux
    store.dispatch(receiveProfile());
  }
}

export default clearAuthTokens;
