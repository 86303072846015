import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "openingHours",
    label: "Opening hours",
  },
  {
    value: "slogan",
    label: "Slogan", // Auflagen
  },
  {
    value: "description",
    label: "Description", // Ausstattung
  },
  {
    value: "dossierDisclaimer",
    label: "Disclaimer",
  },
  {
    value: "projectDisclaimer",
    label: "Project disclaimer",
  },
  {
    value: "virtualTourDisclaimer",
    label: "Virtual tour disclaimer",
  },
];

export const getCaCompanyContentTypes = createGetList(untranslatedList);
export const getLabel = createGetLabel(untranslatedList);

export const useCaCompanyContentTypes = createUtilityHook(untranslatedList);
