import {
  faImages,
  faHome,
  faAddressBook,
  faEnvelope,
  faBook,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import MediaChooserContainer from "../media/MediaChooserContainer";

import PropertyCreateForm, {
  IAZI_CREATED_FLAG_PREFIX,
} from "components/property/PropertyCreateForm";
import ContactCreateForm from "components/contact/ContactCreateForm";
import EventCreateForm from "components/event/EventCreateForm";

import resourceHelper from "utilities/resource";
import {
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
  BASE_DATA_PROJECT,
} from "utilities/propertyTypes";
import { useState } from "react";
import {
  Dropdown,
  DropdownProps,
} from "@casasoft/styleguide/components/dropdown";
import { history } from "config/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasAccessTo } from "utilities/featureFlags";
import { MessageModalCloseDialog } from "components/message/features/create-message/MessageModalCloseDialog/MessageModalCloseDialog";
import { MessageCreateFormContainer } from "components/message/features/create-message/MessageCreateFormContainer";

interface QuickaddMenuProps {
  headerActionMode?: boolean;
}

function QuickaddMenu({ headerActionMode = false }: QuickaddMenuProps) {
  const { t } = useTranslation();

  const [quickAddModalOpen, setQuickAddModalOpen] = useState<
    | typeof BASE_DATA_PROPERTY
    | typeof BASE_DATA_UNIT
    | typeof BASE_DATA_PROJECT
    | "contact"
    | "message"
    | "messageNew"
    | "event"
    | "media"
    | "company"
    | false
  >(false);

  const adderMenuItems: {
    formModal: React.ReactNode;
    dropDownItem: DropdownProps["items"][number];
  }[] = [];
  if (hasAccessTo("propertyManagement")) {
    adderMenuItems.push({
      dropDownItem: {
        label: t("Create property"),
        icon: faHome,
        onItemClick: () => {
          setQuickAddModalOpen(BASE_DATA_PROPERTY);
        },
      },
      formModal: (
        <FormModal
          key="createPropertyModal"
          isOpen={quickAddModalOpen === BASE_DATA_PROPERTY}
          header={t("Create property")}
          onDone={(body) => {
            setQuickAddModalOpen(false);
            if (body) {
              resourceHelper.fetchList("properties");
              history.push(
                body.importId?.startsWith(IAZI_CREATED_FLAG_PREFIX)
                  ? `/property/list/item/${body.id}?iaziCreated=1`
                  : `/property/list/item/${body.id}`
              );
            }
          }}
          form={(props) => {
            return (
              <PropertyCreateForm
                onDone={props.onDone}
                onFail={props.onFail}
                propertyType={BASE_DATA_PROPERTY}
              />
            );
          }}
          onCancel={() => {
            setQuickAddModalOpen(false);
          }}
        />
      ),
    });
  }
  if (hasAccessTo("propertyManagement")) {
    adderMenuItems.push(
      {
        dropDownItem: {
          label: t("Create Project"),
          icon: faHome,
          onItemClick: () => {
            setQuickAddModalOpen(BASE_DATA_PROJECT);
          },
        },
        formModal: (
          <FormModal
            key="createProjectModal"
            isOpen={quickAddModalOpen === BASE_DATA_PROJECT}
            header={t("Create Project")}
            onDone={(body) => {
              setQuickAddModalOpen(false);
              if (body) {
                resourceHelper.fetchList("projects");
                history.push(`/${BASE_DATA_PROJECT}/list/item/${body.id}`);
              }
            }}
            form={(props) => {
              return (
                <PropertyCreateForm
                  onDone={props.onDone}
                  onFail={props.onFail}
                  propertyType={BASE_DATA_PROJECT}
                />
              );
            }}
            onCancel={() => {
              setQuickAddModalOpen(false);
            }}
          />
        ),
      },
      {
        dropDownItem: {
          label: t("Create unit"),
          icon: faHome,
          onItemClick: () => {
            setQuickAddModalOpen(BASE_DATA_UNIT);
          },
        },
        formModal: (
          <FormModal
            key="createUnitModal"
            isOpen={quickAddModalOpen === BASE_DATA_UNIT}
            header={t("Create unit")}
            onDone={(body) => {
              setQuickAddModalOpen(false);
              if (body) {
                resourceHelper.fetchList("units");
                history.push(`/unit/list/item/${body.id}`);
              }
            }}
            form={(props) => {
              return (
                <PropertyCreateForm
                  onDone={props.onDone}
                  onFail={props.onFail}
                  propertyType={BASE_DATA_UNIT}
                />
              );
            }}
            onCancel={() => {
              setQuickAddModalOpen(false);
            }}
          />
        ),
      }
    );
  }
  if (hasAccessTo("contactManagement")) {
    // Contact Modal
    adderMenuItems.push({
      dropDownItem: {
        label: t("Create new contact"),
        icon: faAddressBook,
        onItemClick: () => {
          setQuickAddModalOpen("contact");
        },
      },
      formModal: (
        <FormModal
          key="createContactModal"
          isOpen={quickAddModalOpen === "contact"}
          header={t("Create new contact")}
          onDone={() => {
            setQuickAddModalOpen(false);
          }}
          form={(props) => (
            <ContactCreateForm onDone={props.onDone} onFail={props.onFail} />
          )}
          onCancel={() => {
            setQuickAddModalOpen(false);
          }}
        />
      ),
    });
  }
  if (hasAccessTo("messageManagement")) {
    // Message Modal
    adderMenuItems.push({
      dropDownItem: {
        label: t("Send message"),
        icon: faEnvelope,
        onItemClick: () => {
          setQuickAddModalOpen("message");
        },
      },
      formModal: (
        <FormModal
          key="createMessageModal"
          size="lg"
          isOpen={quickAddModalOpen === "message"}
          header={t("Send message")}
          onDone={(body) => {
            if (body?.id) {
              setQuickAddModalOpen(false);

              history.push(`/message/list/item/${body.id}`);
            }
          }}
          AccidentalCloseModal={MessageModalCloseDialog}
          form={(props) => {
            return (
              <MessageCreateFormContainer
                onDone={props.onDone}
                onFail={props.onFail}
                type="message-outbound"
              />
            );
          }}
          onCancel={() => {
            setQuickAddModalOpen(false);
          }}
        />
      ),
    });
  }
  if (hasAccessTo("eventManagement")) {
    // Event Modal
    adderMenuItems.push({
      dropDownItem: {
        label: t("Create Event"),
        icon: faBook,
        onItemClick: () => {
          setQuickAddModalOpen("event");
        },
      },
      formModal: (
        <FormModal
          key="createEventModal"
          isOpen={quickAddModalOpen === "event"}
          header={t("Create Event")}
          onDone={(body) => {
            setQuickAddModalOpen(false);
            if (body) {
              resourceHelper.reloadList("events");
              history.push(`/event/list/item/${body.id}`);
            }
          }}
          size="lg"
          form={(props) => {
            return (
              <EventCreateForm onDone={props.onDone} onFail={props.onFail} />
            );
          }}
          onCancel={() => {
            setQuickAddModalOpen(false);
          }}
        />
      ),
    });
  }
  if (hasAccessTo("mediaManagement")) {
    adderMenuItems.push({
      dropDownItem: {
        label: t("Upload file"),
        icon: faImages,
        onItemClick: () => {
          setQuickAddModalOpen("media");
        },
      },
      formModal: (
        <FormModal
          key="uploadFileModal"
          isOpen={quickAddModalOpen === "media"}
          header={t("Media library")}
          size="lg"
          form={(props) => {
            return <MediaChooserContainer activeTab="uploader" />;
          }}
          onCancel={() => {
            setQuickAddModalOpen(false);
          }}
        />
      ),
    });
  }

  if (!adderMenuItems.length) {
    return <></>;
  }

  return (
    <>
      <Dropdown
        toggleBtnProps={
          headerActionMode
            ? { isHeaderAction: true, isPrimary: true }
            : undefined
        }
        openOriginPreference="topright"
        icon={headerActionMode ? faPlusCircle : "toggler"}
        label={
          headerActionMode ? undefined : (
            <>
              <FontAwesomeIcon icon={faPlusCircle} /> {t("New")}
            </>
          )
        }
        items={adderMenuItems.map((item) => item.dropDownItem)}
      />
      {adderMenuItems.map((item) => item.formModal)}
    </>
  );
}

export default QuickaddMenu;
