import Button from "@casasoft/styleguide/components/forms/Button";
import { PROPERTY_TOUR_CREATE_BUTTON } from "tours/property-onboarding/propertyCreateOnboarding";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { Item } from "@casasoft/styleguide/components/table-list/TableListItem";
import { axiosInstance } from "utilities/axios";
import Config from "config";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
} from "utilities/propertyTypes";

interface useMainActionNodeProps {
  listContext?: string;
  apiContext?: string;
  toggleQuickAddModal: (toggle: boolean) => void;
  sortable: boolean;
  toggleSortable: (toggle: boolean) => void;
  sortOrder: Item[] | undefined;
  refetch: () => void;
  propertyType?: string;
}

const useMainActionNode = ({
  listContext,
  apiContext,
  toggleQuickAddModal,
  sortable,
  toggleSortable,
  sortOrder,
  refetch,
  propertyType,
}: useMainActionNodeProps) => {
  const { t } = useTranslation();

  const saveSortOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!sortOrder) {
      toggleSortable(false);
    } else {
      const ids = sortOrder.map((item: any) => item.id).join(",");
      axiosInstance
        .post(`${Config.apiUrl}/${Config.customerKey}/set-property-ranks`, {
          ids,
        })
        .then((response) => {
          if (response.status === 500 || response.status === 400) {
            throw Error(`${response.status} ${response.data.detail}`);
          }
          return response;
        })
        .catch((error) => {
          // error
        });
      toggleSortable(false);
    }
  };

  const getButtonLabel = (propertyType?: string) => {
    switch (propertyType) {
      case BASE_DATA_PROPERTY:
        return t("Create property");
      case BASE_DATA_UNIT:
        return t("Create unit");
      case BASE_DATA_PROJECT:
        return t("Create project");
      default:
        return t("Create property");
    }
  };

  if (listContext === "properties-modal") {
    return undefined;
  }

  if (apiContext === "unit-children") {
    if (sortable) {
      return (
        <Button
          onClick={(event) => saveSortOrder(event)}
          style={{ flexGrow: 1, width: 1 }}
          isDark
        >
          {t("Done sorting")}
        </Button>
      );
    }
    return (
      <Button
        onClick={(event) => {
          event.stopPropagation();
          toggleSortable(true);
        }}
        style={{ flexGrow: 1, width: 1 }}
        isPrimary
      >
        {t("Sort properties")}
      </Button>
    );
  }

  return (
    <Button
      onClick={(event) => {
        toggleQuickAddModal(true);
        event.stopPropagation();
      }}
      style={{ flexGrow: 1, width: 1 }}
      isPrimary
      data-boarding={PROPERTY_TOUR_CREATE_BUTTON}
    >
      <FontAwesomeIcon icon={faPlus} className="tw-mr-1" />
      {t(getButtonLabel(propertyType))}
    </Button>
  );
};

export default useMainActionNode;
