import differ, { PointsShape } from "@casasoft/styleguide/utilities/differ";
import { DataAttributes } from "@casasoft/styleguide/utilities/extractDataAttributes";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Button from "../forms/Button";
import Card from "./Card";
import CardBody from "./CardBody";
import CardBodyCols from "./CardBodyCols";
import CardHeader from "./CardHeader";
import CardPointsColsItems from "./CardPointsColsItems";

interface CardPointsProps extends DataAttributes {
  /** array of points to display */
  points: PointsShape[];
  /** if available, array of points of an "origin" entity, which will cause the points to render the difference using the "differ" util */
  pointsOrigin?: PointsShape[];
  /** will be passed as children to the CardHeader component */
  header: ReactNode;
  /** event that will open the main modal */
  onModalOpen: () => void;
  /** perform a final action on the points array before being used to render (for example, sort the points) */
  pointsFormat?: (finalPoints: PointsShape[]) => PointsShape[];
}

function CardPoints({
  points,
  pointsOrigin,
  header,
  onModalOpen,
  pointsFormat,
  ...dataAttrs
}: CardPointsProps) {
  const { t } = useTranslation();
  const pointsDiffed = pointsOrigin ? differ(points, pointsOrigin) : points;
  const pointsFiltered = pointsDiffed.filter(
    (point) => point.value || point.value === 0 // filter out empty values
  );
  const pointsFinal = pointsFormat?.([...pointsFiltered]) || pointsFiltered;

  return (
    <Card {...dataAttrs}>
      <CardHeader
        actions={[
          {
            icon: faEdit,
            onClick: () => {
              onModalOpen();
            },
          },
        ]}
      >
        {header}
      </CardHeader>
      <CardBody>
        {!pointsFinal.length && (
          <Button
            isPrimary
            isBlock
            isOutline
            onClick={() => {
              onModalOpen();
            }}
          >
            + {t("Add")}
          </Button>
        )}
        {!!pointsFinal.length && (
          <CardBodyCols
            onClick={() => {
              onModalOpen();
            }}
          >
            <CardPointsColsItems points={pointsFinal} />
          </CardBodyCols>
        )}
      </CardBody>
    </Card>
  );
}

export default CardPoints;
