import { CasaoneBaseQueryArgs } from "api/types";
import { MutateMediaTagQueryParams } from "./types";

function query(body: MutateMediaTagQueryParams): CasaoneBaseQueryArgs {
  return {
    url: `media-tag/${body.id}`,
    method: "DELETE",
    data: body,
  };
}

export default query;
