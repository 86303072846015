import { getContactDisplayName } from "../RecipientSelect/helpers/recipientSelectMiscHelpers";
import { type RecipientSelectValueType } from "../RecipientSelect/helpers/useRecipientSelect";

// these are based on estimation of how much pixels does on average one character take (and extra space that a single email takes)
// whenever layout, styling or font changes, these values should be updated
const CHARACTER_MULTIPLIER = 10;
const EMAIL_MULTIPLIER = 30;
const WRAPPER_PADDING = 80;
const NO_DISPLAY_NAME_PLACEHOLDER = "No email!";

export const getVisibleEmailsCount = (
  wrapperSize: number,
  allRecipients: RecipientSelectValueType[]
): number => {
  let visibleEmailsCount = allRecipients.length + 1;
  let projectedLength = wrapperSize + 1;

  while (projectedLength > wrapperSize) {
    visibleEmailsCount -= 1;
    const filteredRecipients = allRecipients.slice(0, visibleEmailsCount);
    const characterLength = filteredRecipients.reduce(
      (acc, recipient) =>
        acc +
        getContactDisplayName(recipient.contact, NO_DISPLAY_NAME_PLACEHOLDER)
          .length,
      0
    );

    projectedLength =
      characterLength * CHARACTER_MULTIPLIER +
      visibleEmailsCount * EMAIL_MULTIPLIER +
      WRAPPER_PADDING;
  }

  return visibleEmailsCount < 1 ? 1 : visibleEmailsCount;
};
