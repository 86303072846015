import { createResource } from "redux-rest-resource";
import Config from "config";
import queryBuilder from "../../utilities/queryBuilder";
import MediaState from "./types";
import { CasaiamRoles, KCRoles, Role } from "utilities/type-helpers/userRoles";
import { getCurrentRole } from "utilities/featureFlags";
import { selectProfileUsername } from "redux/auth/selectors";

export interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: MediaState, actions?: any) => MediaState;
}

// Roles allowed to fetch medias
const fetchAllowedRoles: Role[] = [
  CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
  CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
  CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
  KCRoles.ROLE_KC_CASASOFT_ADMIN,
  KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
];

export const mediaResource = {
  name: "media",
  pluralName: "medias",
  url: `${Config.apiUrl}/${Config.customerKey}/media/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (state.items) {
          const newState = Object.assign({}, state);
          const items = state.items.map((item: any) => {
            if (item.id === action.context.id) {
              return action.context;
            }
            return item;
          });
          newState.items = items;
          newState.lastUpdated = Date.now();
          return newState;
        }
        return state;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.media) {
          return { res, body: res.body._embedded.media };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        const state = getState();
        const role = getCurrentRole();
        const cnxtOpts = Object.assign(
          {},
          {
            pageSize: 20,
            filter: [],
            orderBy: [],
          },
          contextOpts
        );
        const opts = Object.assign(
          {},
          {
            pageSize: 50,
            orderBy: [
              {
                type: "field",
                field: "created",
                direction: "desc",
              },
            ],
            filter: [],
          },
          {
            pageSize: cnxtOpts.pageSize,
            orderBy: [...cnxtOpts.orderBy],
            filter: [...cnxtOpts.filter],
          }
        );
        if (!fetchAllowedRoles.includes(role)) {
          const username = selectProfileUsername(state);
          opts.filter.push({
            type: "eq",
            field: "creator",
            where: "and",
            value: username || "there-is-no-user",
          });
        }
        opts.filter.push({
          type: "notlike",
          field: "path",
          where: "and",
          value: "/prince/%",
        });
        opts.filter.push({
          type: "notlike",
          field: "path",
          where: "and",
          value: "/uploads-email/%",
        });
        opts.filter.push({
          type: "notlike",
          field: "path",
          where: "and",
          value: "/uploads-signature/%",
        });
        opts.filter.push({
          type: "orx",
          conditions: [
            {
              type: "neq",
              field: "status",
              value: "ignore",
            },
            {
              type: "isnull",
              field: "status",
            },
          ],
          where: "and",
        });
        const query = queryBuilder.build(opts.orderBy, opts.filter, {
          pagesize: opts.pageSize,
          page: contextOpts.page,
          context: "media-list",
        });
        return query;
      },
    },
  },
};

export const { types, actions, rootReducer }: ResourceTypes =
  createResource(mediaResource);
