import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { MessageBodyPreviewSelectArrow } from "./MessageBodyPreviewSelectArrow";
import { ContactForPreview } from "../../helpers/useMessageBodyPreview/messageBodyPreviewHelpers";

interface MessageBodyPreviewRecipientSelectProps {
  previewLoading: boolean;
  contacts: ContactForPreview[];
  handleContactChangeLeft: () => void;
  handleContactChangeRight: () => void;
  activeContact: ContactForPreview | undefined;
}

export const MessageBodyPreviewRecipientSelect = ({
  previewLoading,
  contacts,
  activeContact,
  handleContactChangeLeft,
  handleContactChangeRight,
}: MessageBodyPreviewRecipientSelectProps) => {
  if (contacts.length <= 1 || !activeContact) {
    return null;
  }

  return (
    <div className="tw-text-cs-shade-500 tw-flex tw-items-center tw-mr-1 tw-mt-[1px]">
      <MessageBodyPreviewSelectArrow
        icon={faAngleLeft}
        callback={handleContactChangeLeft}
        previewLoading={previewLoading}
      />
      <div>{activeContact.email}</div>
      <MessageBodyPreviewSelectArrow
        icon={faAngleRight}
        callback={handleContactChangeRight}
        previewLoading={previewLoading}
      />
    </div>
  );
};
