import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Unlimited",
    value: "unlimited",
  },
  {
    label: "Temporary",
    value: "temporary",
  },
  {
    label: "Freelance",
    value: "freelance",
  },
  {
    label: "Stage",
    value: "stage",
  },
  {
    label: "Supplementary",
    value: "supplementary",
  },
  {
    label: "Apprenticeship",
    value: "apprenticeship",
  },
];

export const getEmploymentTypes = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLabel = createGetLabel(untranslatedList);

export const useEmploymentTypes = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
