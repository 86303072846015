import { useEffect, useState } from "react";
import { Card, CardHeader } from "@casasoft/styleguide/components/card";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faCheckCircle,
  faCircle,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";
import { TableList } from "@casasoft/styleguide/components/table-list";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import csMoment from "utilities/csMoment";
import TasksEditForm from "components/plan/tasks/TasksEditForm";
import resourceGrabber from "utilities/resourceGrabber";
import { history } from "config/history";
import Button from "@casasoft/styleguide/components/forms/Button";
import { useQuickTask } from "entities/event/quickTaskStore";
import { getAttendeeData } from "../../event/helpers/attendees";
import { getAsignee } from "./helpers/dashboardQuicktasksCardHelpers";
import { useDashboardQuicktasksCardFilters } from "./hooks/useDashboardQuicktasksCardFilters";

interface DashboardQuicktasksCardProps {
  onChange?: () => void;
  compactView?: boolean;
}

function DashboardQuicktasksCard({
  onChange,
  compactView,
}: DashboardQuicktasksCardProps) {
  const { t } = useTranslation();
  const { store: quickTaskStore, fetchList: fetchQuickTaskList } =
    useQuickTask();

  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const casaoneUser = resourceGrabber.grab("casaoneUser", "auth");
  const { getFilter } = useDashboardQuicktasksCardFilters();

  useEffect(() => {
    const localCasaoneUser = resourceGrabber.grab("casaoneUser", "auth");
    let userContactId = "user-has-no-contact-defined";
    if (
      localCasaoneUser &&
      localCasaoneUser.contact &&
      localCasaoneUser.contact.id
    ) {
      userContactId = localCasaoneUser.contact.id;
    }
    fetchQuickTaskList({
      filter: getFilter(userContactId),
      orderBy: [
        {
          type: "customorderby",
          field: "end-null-last",
          direction: "asc",
        },
      ],
      pageSize: 10,
    });
  }, [fetchQuickTaskList, getFilter]);

  function loadMore() {
    let userContactId = "user-has-no-contact-defined";
    if (casaoneUser && casaoneUser.contact && casaoneUser.contact.id) {
      userContactId = casaoneUser.contact.id;
    }
    fetchQuickTaskList({
      filter: getFilter(userContactId),
      orderBy: [
        {
          type: "customorderby",
          field: "end-null-last",
          direction: "asc",
        },
      ],
      pageSize: (quickTaskStore.listMetadata?.pageSize || 0) + 10,
    });
  }

  // trigger the change when fetch is finished or store gets updated
  useEffect(() => {
    onChange?.();
  }, [onChange, quickTaskStore]);

  const items = quickTaskStore.items.map((elem) => {
    const startRender = elem.start
      ? csMoment(elem.start.date).format("L LT")
      : "?";
    const startRenderS = elem.start.date;
    const endRender = elem.end
      ? `${elem.end ? csMoment(elem.end.date).format("L LT") : "?"}`
      : "";
    const endRenderS = elem.end ? elem.end.date : null;
    const { recipient, sender } = getAttendeeData(elem?._embedded?.attendees);
    const senderName = sender?._embedded?.contact.displayName || "";
    const recipientName = recipient?._embedded?.contact?.displayName || "";
    const state = elem?._embedded?.events?.length ? (
      <FontAwesomeIcon icon={faCheckCircle} />
    ) : (
      <FontAwesomeIcon icon={faCircle} />
    );

    const isOld =
      elem.end && csMoment(elem.end.date).isBefore(csMoment()) ? true : false;

    let asignee: string | null = null;
    if (recipient && recipient.id !== sender?.id && casaoneUser?.contact?.id) {
      if (casaoneUser.contact.id !== sender?.id) {
        asignee = getAsignee(t("Delegator"), sender?._embedded?.contact);
      } else if (casaoneUser.contact.id !== recipient.id) {
        asignee = getAsignee(t("Delegatee"), recipient?._embedded?.contact);
      }
    } else {
      // its a task for ourselves
      asignee = getAsignee(t("Delegatee"), recipient?._embedded?.contact);
    }
    return {
      end_render: (
        <span className={isOld ? "tw-text-cs-danger" : ""}>{endRender}</span>
      ),
      end_render_s: endRenderS,
      id: elem.id,
      item_key: elem.id,
      senderName,
      recipientName,
      start_render: (
        <div
          className={isOld ? "tw-text-cs-danger" : ""}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {startRender}
        </div>
      ),
      start_render_s: startRenderS,
      state,
      asignee: (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {asignee}
        </div>
      ),
      subject: (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {elem.subject || "-"}
        </div>
      ),
      compact_content: (
        <div>
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {asignee}
          </div>
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {elem.subject || "-"}
          </div>
          <div
            className={isOld ? "tw-text-cs-danger" : ""}
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {startRender}
          </div>
        </div>
      ),
    };
  });

  return (
    <>
      <Card>
        <CardHeader
          actions={[
            {
              icon: faPlusCircle,
              onClick: () => {
                setCreateTaskModalOpen(true);
              },
            },
          ]}
        >
          {t("Tasks")}
        </CardHeader>
        <TableList
          cols={
            !compactView
              ? [
                  {
                    key: "state",
                    label: "Status",
                    maxWidth: 40,
                    placeholder: "circle",
                  },
                  {
                    key: "subject",
                    label: "Task",
                  },
                  {
                    key: "asignee",
                    label: "Delegatee",
                    maxWidth: 300,
                  },
                  {
                    key: "end_render",
                    label: "Deadline",
                    maxWidth: 150,
                  },
                ]
              : [
                  {
                    key: "state",
                    label: "Status",
                    maxWidth: 40,
                    placeholder: "circle",
                  },
                  {
                    key: "compact_content",
                    label: t("Info"),
                  },
                ]
          }
          itemsLoaded={
            quickTaskStore &&
            "listMetadata" in quickTaskStore &&
            typeof quickTaskStore.listMetadata?.totalItems === "number"
              ? true
              : false
          }
          pageSize={items.length}
          items={items}
          total={items.length}
          components={[]}
          itemPath={(item) => {
            return `/plan/tasks/item/${item.id}`;
          }}
          loading={quickTaskStore.isFetching}
        />
        <FormModal
          isOpen={createTaskModalOpen}
          header={t("Create task")}
          onDone={(body) => {
            history.push(`/plan/tasks/item/${body.id}`);
            setCreateTaskModalOpen(false);
          }}
          size="lg"
          form={(props) => {
            return (
              <TasksEditForm onDone={props.onDone} onFail={props.onFail} />
            );
          }}
          onCancel={() => {
            setCreateTaskModalOpen(false);
          }}
        />

        {quickTaskStore.listMetadata?.pageSize &&
          quickTaskStore.listMetadata.page <
            quickTaskStore.listMetadata.pageCount && (
            <Button
              onClick={() => {
                loadMore();
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} fixedWidth />
            </Button>
          )}
      </Card>
      <div className="tw-mb-4" />
    </>
  );
}

export default DashboardQuicktasksCard;
