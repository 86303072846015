import { createQuery } from "api/createEndpoint";
import { GetUserQueryParams } from "./types";

const query = createQuery<GetUserQueryParams>((username) => {
  return {
    url: `v2/user/${username}`,
    method: "GET",
  };
});

export default query;
