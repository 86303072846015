import { createTransformResponse } from "api/createEndpoint";
import type { IaziCommonDataProperty, IaziCommonDataResponse } from "./types";

export const getIaziUid = (property: IaziCommonDataProperty): string => {
  // EGID and EWID are provided by the federal building and apartment register
  // and identify every building and apartment in Switzerland in a unique way.
  return `${property.egid}-${property.ewid}`;
};

const transformResponse = createTransformResponse<IaziCommonDataResponse>(
  (response: any) => {
    const properties: IaziCommonDataProperty[] = response.properties.map(
      (property: IaziCommonDataProperty) => {
        return {
          ...property,
          uid: getIaziUid(property),
        };
      }
    );

    return { properties };
  }
);

export default transformResponse;
