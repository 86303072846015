import Group from "@visx/group/lib/Group";
import Pie from "@visx/shape/lib/shapes/Pie";
import { ReactNode } from "react";
import useThemeSettings from "@casasoft/styleguide/hooks/useThemeSettings";

const width = 500,
  height = 500,
  margin = {
    top: 30,
    left: 10,
    right: 10,
    bottom: 110,
  };

const Label = ({
  x,
  y,
  children,
}: {
  x: number;
  y: number;
  children: ReactNode;
}) => {
  return (
    <text
      fill="currentColor"
      textAnchor="middle"
      x={x}
      y={y}
      dy=".33em"
      fontSize={0.1}
    >
      {children}
    </text>
  );
};

type DashometerProps = {
  percent: number;
  label: string;
};

const Dashometer = ({ percent = 5, label }: DashometerProps) => {
  const { themeSettings } = useThemeSettings();

  const reasonItems = [
    { percent, opacity: 1, label },
    { percent: 100 - percent, opacity: 0, label },
  ];

  const radius = Math.min(width, height) / 2;

  return (
    <svg
      className="tw-h-auto tw-w-full"
      viewBox={`0 0 ${width} ${height / 2}`}
      width={width}
      height={(height - margin.top) / 2}
    >
      <rect
        x={0}
        y={0}
        rx={14}
        width={width}
        height={height}
        fill="transparent"
      />
      <Group top={height / 2} left={width / 2}>
        <Pie
          padAngle={0.02}
          data={reasonItems}
          pieValue={(d: { percent: number }) => d.percent}
          pieSortValues={() => 0}
          startAngle={-1.55}
          endAngle={1.55}
          outerRadius={radius}
          innerRadius={radius - 20}
          fill={themeSettings["--tw-cs-accent-color-dark"].hex}
          centroid={(
            centroid: [number, number],
            arc: { data: { percent: number } }
          ) => {
            const [x, y] = centroid;
            if (arc.data.percent > 9) {
              return (
                <Label x={x} y={y}>
                  {Math.round(arc.data.percent)}&nbsp;%
                </Label>
              );
            }
            return null;
          }}
        />
      </Group>
      <text
        data-testid="dashometer-counter"
        fill="currentColor"
        textAnchor="middle"
        x="50%"
        y="50%"
        dy=".5em"
        fontSize={45}
        fontWeight="bold"
      >
        {percent}%
      </text>
      <text
        data-testid="dashometer-label"
        fill="currentColor"
        textAnchor="middle"
        x="50%"
        y="50%"
        dy="2.8em"
        fontSize={25}
      >
        {label}
      </text>
    </svg>
  );
};

export default Dashometer;
