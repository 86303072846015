import { useTranslation } from "react-i18next";
import type {
  RecipientChangeHandlers,
  RecipientFieldKeys,
  RecipientValues,
} from "./SelectRecipientsContainer";
import { CollapsedSelect } from "./CollapsedSelect/CollapsedSelect";
import type { CollapsedSelectData } from "./CollapsedSelect/CollapsedSelect";
import { CopyButtons } from "./CopyButtons/CopyButtons";
import { RecipientSelectContainer } from "./RecipientSelect/RecipientSelectContainer";
import { ErrorMessage } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";
import { RefObject } from "react";
import { RecipientsNotification } from "./RecipientsNotification/RecipientsNotification";

export interface SelectRecipientsProps<TName extends string> {
  values: RecipientValues<TName>;
  changeHandlers: RecipientChangeHandlers<TName>;
  errors: FieldErrors<RecipientValues<TName>>;
  collapsedSelectData: CollapsedSelectData;
  isCcFieldEnabled: boolean;
  isBccFieldEnabled: boolean;
  isExpanded: boolean;
  onCCClick: () => void;
  onBCCClick: () => void;
  onFocus: () => void;
  onBlur: () => void;
  onWrapperClick: () => void;
  wrapperRef: RefObject<HTMLDivElement>;
}

export const SelectRecipients = ({
  values,
  changeHandlers,
  errors,
  collapsedSelectData,
  isCcFieldEnabled = false,
  isBccFieldEnabled = false,
  isExpanded = false,
  onCCClick,
  onBCCClick,
  onFocus,
  onBlur,
  onWrapperClick,
  wrapperRef,
}: SelectRecipientsProps<RecipientFieldKeys>) => {
  const { t } = useTranslation();
  const hasMoreThanOneDirectRecipient = (values.contact || []).length > 1;

  return (
    <div
      tabIndex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onWrapperClick}
      ref={wrapperRef}
    >
      <RecipientsNotification isVisible={hasMoreThanOneDirectRecipient} />
      <div className="tw-relative">
        <RecipientSelectContainer
          label={t("To:", { keySeparator: ">", nsSeparator: "|" })}
          onChange={changeHandlers.contact}
          value={values.contact}
          className={isExpanded ? "" : "tw-hidden"}
          selectAsyncOverride={{
            styles: {
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                paddingRight: 120,
              }),
            },
          }}
          message={{
            type: "error",
            text: <ErrorMessage errors={errors} name="contact" />,
          }}
        />
        <CollapsedSelect
          {...collapsedSelectData}
          className={isExpanded ? "tw-hidden" : ""}
          errors={errors}
        />
        <CopyButtons
          showCcButton={!isCcFieldEnabled}
          showBccButton={!isBccFieldEnabled}
          onCCClick={onCCClick}
          onBCCClick={onBCCClick}
        />
      </div>
      {isCcFieldEnabled && (
        <RecipientSelectContainer
          label={t("Cc:", { keySeparator: ">", nsSeparator: "|" })}
          onChange={changeHandlers.contactCC}
          value={values.contactCC}
          className={isExpanded ? "" : "tw-hidden"}
        />
      )}
      {isBccFieldEnabled && (
        <RecipientSelectContainer
          label={t("Bcc:", { keySeparator: ">", nsSeparator: "|" })}
          onChange={changeHandlers.contactBCC}
          value={values.contactBCC}
          className={isExpanded ? "" : "tw-hidden"}
        />
      )}
    </div>
  );
};
