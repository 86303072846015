import { Attendee, AttendeeTypes } from "entities/event/types";

interface GetAttendeesProps {
  sender?: string | null;
  recipient?: string | null;
  attendee?: string | null;
}

interface GetAttendeesPayloadProps {
  dirtyData: GetAttendeesProps;
  data: GetAttendeesProps;
}

export const getAttendeesUpdatePayload = ({
  dirtyData,
  data,
}: GetAttendeesPayloadProps) => {
  const { sender, recipient, attendee } = dirtyData;

  const isToDelete = sender === null || recipient === null || attendee === null;
  const isToUpdate = sender || recipient || attendee;

  if (!isToDelete && !isToUpdate) {
    return undefined;
  }

  const attendees = [];

  if (data.sender && dirtyData.sender !== null) {
    attendees.push({
      attendeeType: AttendeeTypes.SENDER,
      contact: dirtyData.sender || data.sender,
    });
  }
  if (data.recipient && dirtyData.recipient !== null) {
    attendees.push({
      attendeeType: AttendeeTypes.RECIPIENT,
      contact: dirtyData.recipient || data.recipient,
    });
  }
  if (data.attendee && dirtyData.attendee !== null) {
    attendees.push({
      attendeeType: AttendeeTypes.ATTENDEE,
      contact: dirtyData.attendee || data.attendee,
    });
  }

  return attendees;
};

export const getAttendeesCreatePayload = ({
  sender,
  recipient,
  attendee,
}: GetAttendeesProps) => {
  const attendees = [];
  if (sender) {
    attendees.push({
      attendeeType: AttendeeTypes.SENDER,
      contact: sender,
    });
  }
  if (recipient) {
    attendees.push({
      attendeeType: AttendeeTypes.RECIPIENT,
      contact: recipient,
    });
  }

  if (attendee) {
    attendees.push({
      attendeeType: AttendeeTypes.ATTENDEE,
      contact: attendee,
    });
  }
  return attendees;
};

export const getAttendeeData = (attendees?: Attendee[]) => {
  return {
    sender: attendees?.find(
      (attendee) => attendee.attendeeType === AttendeeTypes.SENDER
    ),
    recipient: attendees?.find(
      (attendee) => attendee.attendeeType === AttendeeTypes.RECIPIENT
    ),
    attendees:
      attendees?.filter(
        (attendee) => attendee.attendeeType === AttendeeTypes.ATTENDEE
      ) || [],
  };
};
