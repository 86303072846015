import SelectAsync from "@casasoft/styleguide/components/formElements/SelectAsync";
import { useTranslation } from "react-i18next";
import {
  PropertySelectValueType,
  usePropertySelect,
} from "./helpers/usePropertySelect";
import { usePropertyDataPreload } from "./helpers/usePropertyDataPreload";
import { SelectPropertyDialogButton } from "./SelectPropertyDialogButton";

interface SelectPropertyContainerProps {
  value?: PropertySelectValueType;
  onChange?: (newValue: PropertySelectValueType | undefined) => void;
  initialValue?: PropertySelectValueType;
}

export const SelectPropertyContainer = ({
  value,
  initialValue,
  onChange,
}: SelectPropertyContainerProps) => {
  const { t } = useTranslation();
  const {
    formatOptionLabel,
    loadOptions,
    loadingMessage,
    noOptionsMessage,
    onChangeHandler,
  } = usePropertySelect({ onChange });

  usePropertyDataPreload({ value: initialValue, onChange: onChangeHandler });

  return (
    <div className="md:tw-flex tw-gap-4 tw-items-center">
      <SelectAsync
        value={value}
        label={t("Property")}
        placeholder={t("Enter search term")}
        isSearchable
        isClearable
        noOptionsMessage={noOptionsMessage}
        loadOptionsDebounce={300}
        loadingMessage={loadingMessage}
        formatOptionLabel={formatOptionLabel}
        defaultOptions
        isMulti={false}
        onChange={onChangeHandler}
        loadOptions={loadOptions}
        maxMenuHeight={620}
        className="tw-basis-1/2 tw-shrink-0"
      />
      <SelectPropertyDialogButton
        value={value}
        onChange={onChange}
        disabled={!!value}
      />
    </div>
  );
};
