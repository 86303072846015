import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFileCode,
  faFileArchive,
  faFileCsv,
  faFileImage,
} from "@fortawesome/pro-light-svg-icons";

interface ThumbProps {
  mimeType?: string;
  src?: string | null;
  size?: {
    width: React.CSSProperties["width"];
    height: React.CSSProperties["height"];
  };
  title?: string;
}

const Thumb = ({ src, mimeType, size, title }: ThumbProps) => {
  let thumbIcon: React.ReactNode = null;

  switch (mimeType) {
    case "image/heic":
      thumbIcon = <FontAwesomeIcon icon={faFileImage} />;
      break;
    case "application/pdf":
      thumbIcon = <FontAwesomeIcon icon={faFilePdf} />;
      break;
    case "application/msword":
    case "application/vnd.ms-word":
    case "application/vnd.oasis.opendocument.text":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      thumbIcon = <FontAwesomeIcon icon={faFileWord} />;
      break;
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml":
    case "application/vnd.oasis.opendocument.spreadsheet":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      thumbIcon = <FontAwesomeIcon icon={faFileExcel} />;
      break;
    case "application/vnd.ms-powerpoint":
    case "application/vnd.oasis.opendocument.presentation":
    case "application/vnd.openxmlformats-officedocument.presentationml":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      thumbIcon = <FontAwesomeIcon icon={faFilePowerpoint} />;
      break;
    case "text/plain":
      thumbIcon = <FontAwesomeIcon icon={faFileAlt} />;
      break;
    case "application/json":
    case "text/html":
      thumbIcon = <FontAwesomeIcon icon={faFileCode} />;
      break;
    case "text/csv":
      thumbIcon = <FontAwesomeIcon icon={faFileCsv} />;
      break;
    case "application/gzip":
    case "application/zip":
    case "application/x-zip-compressed":
    case "application/octet-stream":
      thumbIcon = <FontAwesomeIcon icon={faFileArchive} />;
      break;
  }

  return (
    <div
      className={`media-thumbnail${
        thumbIcon ? " media-thumbnail--withicon" : ""
      }`}
      style={{
        float: "left",
        backgroundColor: "rgb(var( --cs-shade-100))",
        height: size?.height || "72px",
        width: size?.width || "72px",
        textAlign: "center",
      }}
    >
      <span
        className="media-thumbnail__icon"
        style={title ? { marginTop: "-15%" } : undefined}
      >
        {thumbIcon}
      </span>
      {title && <div className="media-thumbnail__title">{title}</div>}
      {src && (
        <div
          className="media-thumbnail__preview"
          style={{
            backgroundImage: `url(${src})`,
          }}
        ></div>
      )}
    </div>
  );
};

export default Thumb;
