import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    // Architekt
    value: "architect",
    label: "Architect",
  },
  {
    // Auftraggeber
    value: "contracting-authority",
    label: "Contracting authority",
  },
  {
    // Bauherr
    value: "building-contractor",
    label: "Building contractor",
  },
  {
    // Bewirtschafter
    value: "property-manager",
    label: "Property manager",
  },
  {
    // Eigentümer
    value: "owner",
    label: "Owner",
  },
  {
    // Handwerker
    value: "artisan",
    label: "Artisan",
  },
  {
    // Interessent
    value: "prospective-customer",
    label: "Prospective customer",
  },
  {
    // Käufer
    value: "buyer",
    label: "Buyer",
  },
  {
    // Mieter
    value: "tennant",
    label: "Tennant",
  },
  {
    // Vermarkter
    value: "marketer",
    label: "Marketer",
  },
  {
    value: "financial-consultant",
    label: "Financial consultant",
  },
  {
    // Notar
    value: "notary",
    label: "Notary",
  },
  {
    // Disqualifizierter lead
    value: "disqualified-lead",
    label: "Disqualified lead",
  },
  {
    value: "investor",
    label: "Investor",
  },
  {
    value: "former-owner",
    label: "Former Owner",
  },
];

const isNullItem = {
  value: "isnull",
  label: "without Relation to property",
};

export const getOptions = createGetList(
  (includeIsNull: boolean = false) => {
    if (includeIsNull) {
      return [...untranslatedList, isNullItem];
    }
    return untranslatedList;
  },
  {
    sort: (a, b) => {
      // make sure isNull item comes always first
      if (a.value === "isnull") {
        return -1;
      } else if (b.value === "isnull") {
        return 1;
      }
      return a.label.localeCompare(b.label);
    },
  }
);

export const getLabel = createGetLabel([...untranslatedList, isNullItem]);

// currently no proper sorting!
export const usePropertyContactRelationTypes = createUtilityHook(
  (includeIsNull: boolean = false) => {
    if (includeIsNull) {
      return [...untranslatedList, isNullItem];
    }
    return untranslatedList;
  },
  {
    sort: (a, b) => {
      // make sure isNull item comes alway last
      if (a.value === "isnull") {
        return 1;
      } else if (b.value === "isnull") {
        return -1;
      }
      return a.label.localeCompare(b.label);
    },
  }
);

export type PropertyContactRelation = typeof untranslatedList[number]["value"];
