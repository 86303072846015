import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "rent",
    label: "Rent",
  },
  {
    value: "buy",
    label: "Buy",
  },
];

export const getMethods = createGetList(untranslatedList);
export const getLabel = createGetLabel(untranslatedList);

export const useMarketingMethods = createUtilityHook(untranslatedList);
