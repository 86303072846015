import { axiosInstance } from "utilities/axios";
import {
  createGetItem,
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";
import Config from "config";
import { ServiceProviders } from "utilities";
import i18n from "utilities/i18n";
import { untranslatedServiceList } from "./services/constants";
import { serviceCustomTranslator } from "./services/servicesCustomTranslator";

function listConstructor(filter?: { group?: string; provider?: string }) {
  if (filter) {
    return untranslatedServiceList.filter((item) => {
      if (filter.group) {
        if (item.group !== filter.group) {
          return false;
        }
      }
      if (filter.provider) {
        if (item.provider !== filter.provider) {
          return false;
        }
      }
      return true;
    });
  }
  return untranslatedServiceList;
}

export const getServicesList = createGetList(listConstructor, {
  translator: serviceCustomTranslator,
  sort: "a-z",
});
export const getServicesLabel = createGetLabel(listConstructor, {
  translator: serviceCustomTranslator,
});
export const getServicesItem = createGetItem(listConstructor, {
  translator: serviceCustomTranslator,
});
export function getServicesItemOption(serviceKey: string, optionKey: string) {
  const serviceItemOptions = getServicesItem(serviceKey)?.options;
  return serviceItemOptions?.find((option) => option.value === optionKey);
}

export const useServices = createUtilityHook(listConstructor, {
  translator: serviceCustomTranslator,
  sort: "a-z",
});

export function sendServiceBookingMail(
  service: {
    provider: string;
    product: string;
    package: string;
    price: number;
    currency: string;
  },
  packageId: string,
  property?: { id: string; name: string }
) {
  const checkedProperty =
    typeof property !== "undefined" ? property : { id: null, name: null };
  axiosInstance.post(
    `${Config.apiUrl}/${Config.customerKey}/send-info-for-service-booking`,
    {
      provider:
        ServiceProviders.getServiceProvidersLabel(service.provider) || null,
      product: getServicesLabel(`${service.provider}-${service.product}`),
      package:
        getServicesItemOption(
          `${service.provider}-${service.product}`,
          service.package
        )?.label || null,
      packageId,
      price: service.price ? service.price : i18n.t("included"),
      currency: service.currency,
      email:
        ServiceProviders.getServiceProvidersItem(service.provider)?.email ||
        null,
      propertyId: checkedProperty.id,
      propertyName: checkedProperty.name,
    }
  );
}
