import { useEffect, useState } from "react";
import { type SelectPhaseOption } from "../SelectPhase";
import isMarketingMethod from "utilities/type-helpers/isMarketingMethod";
import { useGetPhaseListQuery } from "api/entities/phase";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { getPhaseLabel } from "components/miscellaneous/pipeline/PhaseExcerpt";
import getPhaseByEvent from "utilities/getPhaseByEvent";

interface UsePhaseListProps {
  propertyMarketingMethod: string | undefined;
  latestPhaseId: string | undefined;
  selectedEventType?: string;
  selectedEventBaseType?: string;
}

export const usePhaseList = ({
  propertyMarketingMethod,
  latestPhaseId,
  selectedEventType,
  selectedEventBaseType,
}: UsePhaseListProps) => {
  const [phaseOptions, setPhaseOptions] = useState<SelectPhaseOption[]>([]);
  const [phaseByRule, setPhaseByRule] = useState<string | undefined>();
  const marketingMethod = isMarketingMethod(propertyMarketingMethod)
    ? propertyMarketingMethod
    : undefined;

  const getPhaseListResult = useGetPhaseListQuery(
    marketingMethod
      ? {
          phaseForMarketingMethod: marketingMethod,
        }
      : skipToken
  );

  if (getPhaseListResult.isSuccess && !phaseOptions.length) {
    const newPhaseOptions =
      getPhaseListResult.data._embedded.phase.map((phase, i) => {
        return {
          value: phase.id,
          color: phase?.color || undefined,
          rank: i + 1,
          label: getPhaseLabel(phase) || phase.id,
        };
      }) || [];

    if (newPhaseOptions.length) {
      setPhaseOptions(newPhaseOptions);
    }
  }

  useEffect(() => {
    if (getPhaseListResult.isSuccess && getPhaseListResult.currentData) {
      const newPhaseByrule = getPhaseByEvent({
        eventType: selectedEventType,
        baseType: selectedEventBaseType,
        phaseItems: getPhaseListResult.currentData._embedded.phase,
      });

      setPhaseByRule(newPhaseByrule);
    }
  }, [getPhaseListResult, selectedEventBaseType, selectedEventType]);

  const latestPhase = getPhaseListResult.isSuccess
    ? getPhaseListResult.data._embedded.phase.find(
        (phase) => phase.id === latestPhaseId
      )
    : undefined;

  return {
    phaseOptions,
    phaseByRule,
    error: getPhaseListResult.isError ? getPhaseListResult.error : undefined,
    isValid: marketingMethod !== undefined,
    latestPhase,
  };
};
