import { RefObject, useMemo } from "react";
import type {
  RecipientChangeHandler,
  RecipientChangeHandlers,
  RecipientFieldKeys,
  RecipientValues,
} from "../SelectRecipientsContainer";
import type { CollapsedSelectData } from "../CollapsedSelect/CollapsedSelect";
import type { RecipientSelectValueType } from "../RecipientSelect/helpers/useRecipientSelect";
import { getVisibleEmailsCount } from "./collapsedSelectCalculator";

export const useCollapsedSelect = (
  values: RecipientValues<RecipientFieldKeys>,
  changeHandlers: RecipientChangeHandlers<RecipientFieldKeys>,
  wrapperRef?: RefObject<HTMLDivElement>
) => {
  const wrapperSize = wrapperRef?.current?.getBoundingClientRect().width ?? 0;
  const mergedValues: RecipientSelectValueType[] = (values.contact ?? [])
    ?.concat(values.contactCC ?? [])
    .concat(values.contactBCC ?? []);
  const visibleEmailsCount = getVisibleEmailsCount(wrapperSize, mergedValues);

  const totalRecipientsCount: number = useMemo(
    () => mergedValues.length,
    [mergedValues]
  );

  const collapsedValues = useMemo(
    () => mergedValues.slice(0, visibleEmailsCount),
    [mergedValues, visibleEmailsCount]
  );

  const updateRecipientGroup = (
    values: RecipientSelectValueType[] | undefined,
    onChange: RecipientChangeHandler,
    removedIds: RecipientSelectValueType[]
  ) => {
    if (values?.find((contactId) => removedIds?.includes(contactId))) {
      const newValues = values.filter(
        (contactId) => !removedIds?.includes(contactId)
      );
      onChange(newValues);
    }
  };

  const changeHandler: RecipientChangeHandler = (remainingIds) => {
    const removedIds = collapsedValues.filter(
      (contactId) => !remainingIds?.includes(contactId)
    ); // Should be just one removed id really

    updateRecipientGroup(values.contact, changeHandlers.contact, removedIds);
    updateRecipientGroup(
      values.contactCC,
      changeHandlers.contactCC,
      removedIds
    );
    updateRecipientGroup(
      values.contactBCC,
      changeHandlers.contactBCC,
      removedIds
    );
  };

  const collapsedSelectData: CollapsedSelectData = {
    value: collapsedValues,
    changeHandler,
    remainingCount: totalRecipientsCount - collapsedValues.length,
  };

  return {
    collapsedSelectData,
  };
};
