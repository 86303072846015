import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { fetchAuthTokens } from "utilities/auth";
import { history } from "config/history";

function CasaconnectAuthReturn() {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const stateParam = params.get("state") || "{}";
    const codeParam = params.get("code");
    const stateObj = JSON.parse(stateParam);

    async function fetchToken() {
      if (!codeParam) {
        throw new Error("Expected a code to be present");
      }
      await fetchAuthTokens({ code: codeParam });
      history.push(
        (stateObj.path as string).startsWith("/login") || stateObj.path === "/"
          ? "/dash"
          : stateObj.path
      );
    }
    fetchToken();
  }, [dispatch, search]);

  return (
    <div className="tw-h-full tw-w-full tw-relative spinner-fixture">
      <Spinner />
    </div>
  );
}

export default CasaconnectAuthReturn;
