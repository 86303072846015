import { ModalFooter } from "@casasoft/styleguide/components/modal";
import Field from "@casasoft/styleguide/components/formElements/Field";
import Select from "@casasoft/styleguide/components/formElements/Select";
import Tinymce from "@casasoft/styleguide/components/formElements/tinymce/Tinymce";
import DateField from "@casasoft/styleguide/components/formElements/DateField";
import { getLanguages } from "utilities/languages";
import { renderSalutation } from "utilities/salutation";
import Config from "config";
import csMoment from "utilities/csMoment";
import {
  CivilStatuses,
  ContactStatuses,
  Countries,
  Genders,
  ResidencePermits,
} from "utilities";
import { hasAccessTo } from "utilities/featureFlags";
import { useTranslation } from "react-i18next";
import { ContactShape } from "entities/contact/types";
import useForm, { RegisterFields } from "hooks/useForm";
import { PartialOrNull } from "utilities/type-helpers/object-oberators";
import { SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useCallback, useEffect, useState } from "react";
import Button from "@casasoft/styleguide/components/forms/Button";
import GenericRemoveConfirm from "@casasoft/styleguide/components/genericRemoveConfirm/GenericRemoveConfirm";
import {
  FormModalCols,
  FormModalColsItem,
  FormModalSectionHeader,
  FormModal,
} from "@casasoft/styleguide/components/form-modal";
import { axiosInstance } from "utilities/axios";
import handleFormModalError, {
  FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";

interface FormShape {
  status: string | null;
  visualReferenceId: string | null;
  entityType: string | null;
  displayName: string | null;
  gender: string | number | null;
  honorific: string | null;
  lastName: string | null;
  firstName: string | null;
  profession: string | null;
  function: string | null;
  dob: string | null;
  pob: string | null;
  civilStatus: string | null;
  poco: string | null;
  nationality: string | null;
  permit: string | null;
  lang: string | null;
  salutationTemplate: string | null;
  email: string | null;
  email2: string | null;
  phone: string | null;
  phoneDirect: string | null;
  mobile: string | null;
  fax: string | null;
  twitter: string | null;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  websiteUrl: string | null;
  tags: string | null;
  notes: string | null;
  addition: string | null;
}

interface ContactEditFormProps {
  contact: ContactShape;
  onDone: () => void;
  onFail: FormModalOnFail;
  onSubmit: (
    data: FormShape,
    dirtyData?: PartialOrNull<FormShape>
  ) => Promise<void>;
  isPublicEditForm?: boolean;
}

function ContactEditForm({
  contact,
  onDone,
  onFail,
  onSubmit: onSubmitProp,
  isPublicEditForm,
}: ContactEditFormProps) {
  const { t } = useTranslation();

  const [tagOptions, setTagOptions] = useState<string[]>([]);

  const [entityTypeChangeModalOpen, setEntityTypeChangeModalOpen] = useState<
    "legal" | "individual" | false
  >(false);

  const {
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitting },
    reset,
    control,
    getDirtyValues,
    watch,
  } = useForm<FormShape>({
    defaultValues: {
      status: contact.status || null,
      visualReferenceId: contact.visualReferenceId || null,
      entityType: contact.entityType || null,
      displayName: contact.displayName || null,
      gender: contact.gender || null,
      honorific: contact.honorific || null,
      lastName: contact.lastName || null,
      firstName: contact.firstName || null,
      profession: contact.profession || null,
      function: contact.function || null,
      dob: contact.dob?.date || null,
      pob: contact.pob || null,
      civilStatus: contact.civilStatus || null,
      poco: contact.poco || null,
      nationality: contact.nationality || null,
      permit: contact.permit || null,
      lang: contact.lang || null,
      salutationTemplate: contact.salutationTemplate || null,
      email: contact.email || null,
      email2: contact.email2 || null,
      phone: contact.phone || null,
      phoneDirect: contact.phoneDirect || null,
      mobile: contact.mobile || null,
      fax: contact.fax || null,
      twitter: contact.twitter || null,
      linkedin: contact.linkedin || null,
      facebook: contact.facebook || null,
      instagram: contact.instagram || null,
      websiteUrl: contact.websiteUrl || null,
      tags: contact._embedded?.tags?.map((tag) => tag.name).join(",") || null,
      notes: contact.notes || null,
      addition: contact.addition || null,
    },
  });

  const onSubmit: SubmitHandler<FormShape> = async (data) => {
    const dirtyData = getDirtyValues();

    try {
      await onSubmitProp(data, dirtyData);

      onDone();
      reset(data);
    } catch (error) {
      handleFormModalError(error, onFail);
    }
  };

  useEffect(() => {
    if (!isPublicEditForm) {
      axiosInstance
        .get(`${Config.apiUrl}/${Config.customerKey}/get-distinct-contact-tags`)
        .then((response) => {
          if (response.status === 500) {
            throw Error(`${response.status} ${response.data.detail}`);
          }
          return response.data;
        })

        .then((response) => {
          setTagOptions(
            (response?.contactTags as any[])
              ?.map((x) => x.name)
              .sort((a, b) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              }) || []
          );
        });
    }
  }, [isPublicEditForm]);

  const [firstNameWatch, lastNameWatch, displayNameWatch] = watch([
    "firstName",
    "lastName",
    "displayName",
  ]);

  const contactEntityTypeWatch = watch("entityType");
  const hideNodeForLegalContact = useCallback(
    (node: JSX.Element) => {
      if (contactEntityTypeWatch === "legal") {
        return <></>;
      }
      return node;
    },
    [contactEntityTypeWatch]
  );
  const hideNodeForPublicContacts = useCallback(
    (node: JSX.Element) => {
      if (isPublicEditForm) {
        return <></>;
      }
      return node;
    },
    [isPublicEditForm]
  );

  return (
    <RegisterFields
      fields={{
        status: {},
        visualReferenceId: {},
        entityType: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        displayName: {},
        gender: {},
        honorific: {},
        lastName: {},
        firstName: {},
        profession: {},
        function: {},
        dob: {},
        pob: {},
        civilStatus: {},
        poco: {},
        nationality: {},
        permit: {},
        lang: {},
        salutationTemplate: {},
        email: {},
        email2: {},
        phone: {},
        phoneDirect: {},
        mobile: {},
        fax: {},
        twitter: {},
        linkedin: {},
        facebook: {},
        instagram: {},
        websiteUrl: {},
        tags: {},
        notes: {},
        addition: {},
      }}
      control={control}
      render={(fieldsRenderer) => {
        const socialMediaCard = (
          <>
            <FormModalSectionHeader>{t("Social media")}</FormModalSectionHeader>
            {fieldsRenderer("twitter", (formValue, onFormValueChange) => (
              <Field
                nobox
                type="url"
                label={t("Twitter")}
                value={formValue || ""}
                onChange={(value) => {
                  onFormValueChange(value || null);
                }}
                placeholder="http://domain.ch"
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="twitter" />,
                }}
              />
            ))}
            {fieldsRenderer("linkedin", (formValue, onFormValueChange) => (
              <Field
                nobox
                type="url"
                label={t("linkedIn")}
                value={formValue || ""}
                onChange={(value) => {
                  onFormValueChange(value || null);
                }}
                placeholder="http://domain.ch"
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="linkedin" />,
                }}
              />
            ))}
            {fieldsRenderer("facebook", (formValue, onFormValueChange) => (
              <Field
                nobox
                type="url"
                label={t("Facebook")}
                value={formValue || ""}
                onChange={(value) => {
                  onFormValueChange(value || null);
                }}
                placeholder="http://domain.ch"
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="facebook" />,
                }}
              />
            ))}
            {fieldsRenderer("instagram", (formValue, onFormValueChange) => (
              <Field
                nobox
                type="url"
                label={t("Instagram")}
                value={formValue || ""}
                onChange={(value) => {
                  onFormValueChange(value || null);
                }}
                placeholder="http://domain.ch"
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="instagram" />,
                }}
              />
            ))}
            {fieldsRenderer("websiteUrl", (formValue, onFormValueChange) => (
              <Field
                nobox
                type="url"
                label={t("Website")}
                value={formValue || ""}
                onChange={(value) => {
                  onFormValueChange(value || null);
                }}
                placeholder="http://domain.ch"
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="websiteUrl" />,
                }}
              />
            ))}
          </>
        );
        return (
          <form
            className="spinner-fixture"
            onSubmit={handleSubmit(onSubmit)}
            style={
              isSubmitting ? { opacity: 0.5, pointerEvents: "none" } : undefined
            }
          >
            <FormModalCols>
              <FormModalColsItem>
                <FormModalSectionHeader>
                  {t("Contact details")}
                </FormModalSectionHeader>
                {fieldsRenderer("status", (formValue, onFormValueChange) =>
                  hideNodeForPublicContacts(
                    <Select
                      nobox
                      label={t("Status")}
                      placeholder={t("Choose status")}
                      value={formValue || undefined}
                      options={ContactStatuses.getContactStatus()}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="status" />,
                      }}
                    />
                  )
                )}
                {fieldsRenderer(
                  "visualReferenceId",
                  (formValue, onFormValueChange) =>
                    hideNodeForPublicContacts(
                      <Field
                        nobox
                        label={t("standards:Reference no.")}
                        value={formValue || ""}
                        onChange={(value) => {
                          onFormValueChange(value || null);
                        }}
                        message={{
                          type: "error",
                          text: (
                            <ErrorMessage
                              errors={errors}
                              name="visualReferenceId"
                            />
                          ),
                        }}
                      />
                    )
                )}
                {fieldsRenderer("entityType", (formValue, onFormValueChange) =>
                  hideNodeForPublicContacts(
                    // only pro can create companies, otherwise within modals (for instance) the starter user would not be able to see the company anywhere.
                    !isPublicEditForm && hasAccessTo("contactManagement") ? (
                      <Select
                        nobox
                        label={t("Entity")}
                        placeholder={t("Choose entity")}
                        value={formValue || undefined}
                        options={[
                          {
                            label: t("Individual entity"),
                            value: "individual",
                          },
                          {
                            label: t("Legal entity"),
                            value: "legal",
                          },
                        ]}
                        onChange={(value) => {
                          if (
                            (contact.entityType === "legal" &&
                              value === "individual") ||
                            (contact.entityType === "individual" &&
                              value === "legal")
                          ) {
                            setEntityTypeChangeModalOpen(value);
                          } else {
                            onFormValueChange(value || null);
                          }
                        }}
                        message={{
                          type: "error",
                          text: (
                            <ErrorMessage errors={errors} name="entityType" />
                          ),
                        }}
                      />
                    ) : (
                      <></>
                    )
                  )
                )}
                <FormModal
                  isOpen={!!entityTypeChangeModalOpen}
                  header={t("Switch entity type")}
                  onDone={() => {
                    setEntityTypeChangeModalOpen(false);
                  }}
                  onCancel={() => {
                    setEntityTypeChangeModalOpen(false);
                  }}
                  size="sm"
                  form={(props) => {
                    return (
                      <GenericRemoveConfirm
                        onDelete={() => {
                          if (!entityTypeChangeModalOpen) {
                            throw new Error("An error occured");
                          }
                          setValue("entityType", entityTypeChangeModalOpen, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }}
                        messageText={
                          entityTypeChangeModalOpen === "legal"
                            ? t("This will remove the linked company.")
                            : t("This will remove all linked employees.")
                        }
                        buttonText={t("Save")}
                        onDone={props.onDone}
                        onFail={props.onFail}
                      />
                    );
                  }}
                />

                {fieldsRenderer(
                  "displayName",
                  (formValue, onFormValueChange) => (
                    <Field
                      nobox
                      label={
                        contactEntityTypeWatch === "legal"
                          ? t("Legal name")
                          : t("Display name")
                      }
                      value={formValue || ""}
                      placeholder={`${firstNameWatch || ""} ${
                        lastNameWatch || ""
                      }`}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: (
                          <ErrorMessage errors={errors} name="displayName" />
                        ),
                      }}
                    />
                  )
                )}
                {fieldsRenderer("addition", (formValue, onFormValueChange) =>
                  contactEntityTypeWatch === "legal" ? (
                    <Field
                      nobox
                      label={t("Addition")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="addition" />,
                      }}
                    />
                  ) : (
                    <></>
                  )
                )}
                {fieldsRenderer("gender", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Select
                      nobox
                      label={t("Gender")}
                      placeholder={t("Select a Gender")}
                      value={formValue?.toString() || undefined}
                      options={Genders.getGenders()}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="gender" />,
                      }}
                    />
                  )
                )}
                {fieldsRenderer("honorific", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Field
                      nobox
                      label={t("Honorific")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="honorific" />,
                      }}
                    />
                  )
                )}
                {contactEntityTypeWatch !== "legal" &&
                  fieldsRenderer("lastName", (formValue, onFormValueChange) => (
                    <Field
                      nobox
                      label={t("Last name")}
                      value={formValue || ""}
                      onChange={(value) => {
                        if (!displayNameWatch) {
                          setValue(
                            "displayName",
                            `${firstNameWatch || ""} ${value}`,
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            }
                          );
                        } else if (
                          displayNameWatch ===
                          `${firstNameWatch || ""} ${lastNameWatch || ""}`
                        ) {
                          setValue(
                            "displayName",
                            `${firstNameWatch || ""} ${value}`,
                            {
                              shouldDirty: true,
                              shouldValidate: true,
                            }
                          );
                        }
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="lastName" />,
                      }}
                    />
                  ))}
                {contactEntityTypeWatch !== "legal" &&
                  fieldsRenderer(
                    "firstName",
                    (formValue, onFormValueChange) => (
                      <Field
                        nobox
                        label={t("First name")}
                        value={formValue || ""}
                        onChange={(value) => {
                          if (!displayNameWatch || "") {
                            setValue(
                              "displayName",
                              `${value} ${lastNameWatch || ""}`,
                              { shouldDirty: true, shouldValidate: true }
                            );
                          } else if (
                            displayNameWatch ===
                            `${firstNameWatch || ""} ${lastNameWatch || ""}`
                          ) {
                            setValue(
                              "displayName",
                              `${value} ${lastNameWatch || ""}`,
                              { shouldDirty: true, shouldValidate: true }
                            );
                          }
                          onFormValueChange(value || null);
                        }}
                        message={{
                          type: "error",
                          text: (
                            <ErrorMessage errors={errors} name="firstName" />
                          ),
                        }}
                      />
                    )
                  )}
                {fieldsRenderer("profession", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Field
                      nobox
                      label={t("Profession")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: (
                          <ErrorMessage errors={errors} name="profession" />
                        ),
                      }}
                    />
                  )
                )}
                {fieldsRenderer("function", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Field
                      nobox
                      label={t("Function")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="function" />,
                      }}
                    />
                  )
                )}
                {fieldsRenderer("dob", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <DateField
                      nobox
                      showTodayButton={false}
                      label={t("Date of birth")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      locale={csMoment().locale()}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="dob" />,
                      }}
                    />
                  )
                )}
                {fieldsRenderer("pob", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Field
                      nobox
                      label={t("Place of birth")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="pob" />,
                      }}
                    />
                  )
                )}
                {fieldsRenderer("civilStatus", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Select
                      nobox
                      label={t("Civil status")}
                      placeholder={t("Select a civil status")}
                      value={formValue || undefined}
                      options={CivilStatuses.getCivilStatus()}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: (
                          <ErrorMessage errors={errors} name="civilStatus" />
                        ),
                      }}
                    />
                  )
                )}
                {fieldsRenderer("poco", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Field
                      nobox
                      label={t("Place of civil origin")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="poco" />,
                      }}
                    />
                  )
                )}
                {fieldsRenderer("nationality", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Select
                      nobox
                      label={t("Nationality")}
                      placeholder={t("Select a nationality")}
                      value={formValue || undefined}
                      options={Countries.getCountries()}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: (
                          <ErrorMessage errors={errors} name="nationality" />
                        ),
                      }}
                    />
                  )
                )}
                {fieldsRenderer("permit", (formValue, onFormValueChange) =>
                  hideNodeForLegalContact(
                    <Select
                      nobox
                      label={t("Residence permit")}
                      placeholder={t("Select a permit")}
                      value={formValue || undefined}
                      options={ResidencePermits.getResidencePermits()}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="permit" />,
                      }}
                    />
                  )
                )}

                {contactEntityTypeWatch === "legal" && socialMediaCard}
              </FormModalColsItem>
              <FormModalColsItem>
                {contactEntityTypeWatch === "legal" && (
                  <>
                    <FormModalSectionHeader>
                      {t("Contact person")}
                    </FormModalSectionHeader>
                    {fieldsRenderer(
                      "lastName",
                      (formValue, onFormValueChange) => (
                        <Field
                          nobox
                          label={t("Last name")}
                          value={formValue || ""}
                          onChange={(value) => {
                            onFormValueChange(value || null);
                          }}
                          message={{
                            type: "error",
                            text: (
                              <ErrorMessage errors={errors} name="lastName" />
                            ),
                          }}
                        />
                      )
                    )}
                    {fieldsRenderer(
                      "firstName",
                      (formValue, onFormValueChange) => (
                        <Field
                          nobox
                          label={t("First name")}
                          value={formValue || ""}
                          onChange={(value) => {
                            onFormValueChange(value || null);
                          }}
                          message={{
                            type: "error",
                            text: (
                              <ErrorMessage errors={errors} name="firstName" />
                            ),
                          }}
                        />
                      )
                    )}
                  </>
                )}

                <FormModalSectionHeader>
                  {t("Communication")}
                </FormModalSectionHeader>
                {fieldsRenderer("lang", (formValue, onFormValueChange) => (
                  <Select
                    nobox
                    label={t("Primary language")}
                    placeholder={t("Select a Language")}
                    value={formValue || undefined}
                    options={getLanguages()}
                    onChange={(value) => {
                      onFormValueChange(value || null);
                    }}
                    message={{
                      type: "error",
                      text: <ErrorMessage errors={errors} name="lang" />,
                    }}
                  />
                ))}
                {fieldsRenderer(
                  "salutationTemplate",
                  (formValue, onFormValueChange) =>
                    hideNodeForPublicContacts(
                      <Field
                        nobox
                        label={t("Salutation")}
                        value={formValue || ""}
                        placeholder={renderSalutation({
                          ...contact,
                          salutationTemplate: formValue || "",
                        })}
                        onChange={(value) => {
                          onFormValueChange(value || null);
                        }}
                        text={
                          !formValue
                            ? [
                                renderSalutation({
                                  ...contact,
                                  salutationTemplate: formValue || "",
                                }),
                              ]
                            : undefined
                        }
                        message={{
                          type: "error",
                          text: (
                            <ErrorMessage
                              errors={errors}
                              name="salutationTemplate"
                            />
                          ),
                        }}
                      />
                    )
                )}

                {fieldsRenderer("email", (formValue, onFormValueChange) =>
                  hideNodeForPublicContacts(
                    <Field
                      nobox
                      label={t("E-Mail")}
                      type="email"
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="email" />,
                      }}
                    />
                  )
                )}

                {fieldsRenderer("email2", (formValue, onFormValueChange) => (
                  <Field
                    nobox
                    label={`${t("E-Mail")} 2`}
                    type="email"
                    value={formValue || ""}
                    onChange={(value) => {
                      onFormValueChange(value || null);
                    }}
                    message={{
                      type: "error",
                      text: <ErrorMessage errors={errors} name="email2" />,
                    }}
                  />
                ))}
                {fieldsRenderer("phone", (formValue, onFormValueChange) => (
                  <Field
                    nobox
                    type="phone"
                    label={t("Phone")}
                    value={formValue || ""}
                    onChange={(value) => {
                      onFormValueChange(value || null);
                    }}
                    message={{
                      type: "error",
                      text: <ErrorMessage errors={errors} name="phone" />,
                    }}
                  />
                ))}
                {fieldsRenderer(
                  "phoneDirect",
                  (formValue, onFormValueChange) => (
                    <Field
                      nobox
                      type="phone"
                      label={t("Phone direct")}
                      value={formValue || ""}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: (
                          <ErrorMessage errors={errors} name="phoneDirect" />
                        ),
                      }}
                    />
                  )
                )}
                {fieldsRenderer("mobile", (formValue, onFormValueChange) => (
                  <Field
                    nobox
                    type="phone"
                    label={t("Mobile")}
                    value={formValue || ""}
                    onChange={(value) => {
                      onFormValueChange(value || null);
                    }}
                    message={{
                      type: "error",
                      text: <ErrorMessage errors={errors} name="mobile" />,
                    }}
                  />
                ))}
                {fieldsRenderer("fax", (formValue, onFormValueChange) => (
                  <Field
                    nobox
                    type="phone"
                    label={t("Fax")}
                    value={formValue || ""}
                    onChange={(value) => {
                      onFormValueChange(value || null);
                    }}
                    message={{
                      type: "error",
                      text: <ErrorMessage errors={errors} name="fax" />,
                    }}
                  />
                ))}

                {contactEntityTypeWatch !== "legal" && socialMediaCard}

                {fieldsRenderer("tags", (formValue, onFormValueChange) =>
                  hideNodeForPublicContacts(
                    <Select
                      nobox
                      isMulti
                      isCreatable
                      label={t("Tags")}
                      placeholder={t("Tags")}
                      value={formValue || undefined}
                      options={tagOptions.map((x) => {
                        return { value: x, label: x };
                      })}
                      onChange={(value) => {
                        onFormValueChange(value || null);
                      }}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="tags" />,
                      }}
                    />
                  )
                )}
              </FormModalColsItem>
            </FormModalCols>
            {fieldsRenderer("notes", (formValue, onFormValueChange) =>
              hideNodeForPublicContacts(
                <Tinymce
                  label={t("Notes")}
                  value={formValue || ""}
                  placeholder=""
                  onChange={(value) => {
                    onFormValueChange(value || null);
                  }}
                  rows={3}
                  message={{
                    type: "error",
                    text: <ErrorMessage errors={errors} name="notes" />,
                  }}
                />
              )
            )}
            {isDirty && (
              <ModalFooter>
                <Button isPrimary type="submit" buttonValue={t("Save")} />
              </ModalFooter>
            )}
          </form>
        );
      }}
    />
  );
}

export default ContactEditForm;
