import {
  createGetItem,
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";
import { untranslatedProviderList } from "./serviceProviders/constants";

function listConstructor(
  context?: "service-creditable" | "service-requestable"
) {
  if (context) {
    return untranslatedProviderList.filter((item) => {
      if (context === "service-creditable" && item.creditableService) {
        return true;
      } else if (context === "service-requestable" && !item.creditableService) {
        return true;
      }
      return false;
    });
  }
  return untranslatedProviderList;
}

export const getServiceProvidersList = createGetList(listConstructor, {
  sort: "a-z",
});
export const getServiceProvidersLabel = createGetLabel(listConstructor);
export const getServiceProvidersItem = createGetItem(listConstructor);

export const useServiceProviders = createUtilityHook(listConstructor, {
  sort: "a-z", // optional - sort by translated labels
});
