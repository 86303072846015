import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CleanRootStateKeys } from "redux/store";
import { useDispatch } from "react-redux";
import { changeResourcePage } from "redux/app";
import resourceGrabber from "utilities/resourceGrabber";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import Field from "@casasoft/styleguide/components/formElements/Field";
import usePreparedActiveFilterPreset from "hooks/filter-form/usePreparedActiveFilterPreset";
import Button from "@casasoft/styleguide/components/forms/Button";
import { useUserFilterPresets } from "hooks/filter-form/useUserFilterPresets";

export interface PresetFields {
  [key: string]: {
    name: string;
    label: string;
    value: string | string[] | null;
    renderer?: (val: string[]) => string | null;
  };
}

export interface Preset {
  key: string;
  label: string;
  preset: PresetFields;
}

export interface RenderFilterFormActions {
  onFilterFieldChange: (
    field: string,
    value?: PresetFields[string]["value"]
  ) => void;
  getFilterFieldValue: (
    field: string
  ) => ReturnType<typeof resourceGrabber.grab>;
}

export interface FilterFormProps {
  renderFilterForm: (actions: RenderFilterFormActions) => ReactNode;
  presetName: CleanRootStateKeys | string;
  open: boolean;
  openStateControl: (arg: "open" | "close") => void;
  filterFormOptions?: Omit<Preset, "key">;
}

const FilterForm = ({
  renderFilterForm,
  presetName,
  open,
  openStateControl,
  filterFormOptions,
}: FilterFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    persistUserFilterPresets,
    removeUserFilterPreset,
    updateUserFilterPreset,
    activateUserFilterPreset,
  } = useUserFilterPresets();

  const activeFilterPreset = usePreparedActiveFilterPreset(
    presetName,
    filterFormOptions
  );

  const presets = Object.entries(activeFilterPreset?.preset || {}).filter(
    (preset) => preset[1].value
  );

  return (
    <>
      {!open && activeFilterPreset && (
        <div key="filterPreview" className="tw-mb-2 tw-flex">
          {presets.map((preset, i) => {
            const [, presetVal] = preset;
            const toRender =
              typeof presetVal.value === "string"
                ? [presetVal.value]
                : presetVal.value || [];

            const rendered = presetVal.renderer
              ? presetVal.renderer(toRender)
              : toRender.join(", ");

            return (
              <div className="tw-mr-3" key={i}>
                <Button
                  onClick={() => {
                    openStateControl("open");
                  }}
                  isLight
                  isOutline
                  isSmall
                >
                  {presetVal.label}: {rendered}
                </Button>
              </div>
            );
          })}
          <Button
            isLight
            isOutline
            isSmall
            onClick={() => {
              openStateControl("open");
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> {t("Edit")}
          </Button>
        </div>
      )}
      {open && activeFilterPreset && (
        <div
          key="filterForm"
          className="tw-shadow-filter-form tw-shadow-cs-shadow-color-200 tw-mt-2 tw--mx-4 tw-p-4"
        >
          <div>
            <Field
              nobox
              required
              id="label"
              label={t("Name")}
              value={
                activeFilterPreset &&
                resourceGrabber.grab(
                  "filterPresetLabel",
                  "auth",
                  presetName,
                  activeFilterPreset.key
                )
              }
              onBlur={(value: string) => {
                if (activeFilterPreset) {
                  dispatch(changeResourcePage(presetName, 1));
                  updateUserFilterPreset({
                    presetName,
                    presetKey: activeFilterPreset.key,
                    label: value,
                  });
                }
              }}
            />
            {renderFilterForm({
              onFilterFieldChange: (field, value) => {
                if (activeFilterPreset) {
                  dispatch(changeResourcePage(presetName, 1));
                  updateUserFilterPreset({
                    presetName,
                    presetKey: activeFilterPreset.key,
                    label: resourceGrabber.grab(
                      "filterPresetLabel",
                      "auth",
                      presetName,
                      activeFilterPreset.key
                    ),
                    elementKey: field,
                    elementValue: value || undefined,
                  });
                }
              },

              getFilterFieldValue: (field) => {
                if (activeFilterPreset) {
                  const grabbed = resourceGrabber.grab(
                    "filterPresetField",
                    "auth",
                    presetName,
                    activeFilterPreset.key,
                    field
                  );
                  return grabbed || [];
                }
              },
            })}
            <div className="tw-flex tw-flex-wrap tw-justify-between">
              <Button
                isDanger
                isOutline
                isSmall
                onClick={() => {
                  if (activeFilterPreset) {
                    dispatch(changeResourcePage(presetName, 1));
                    removeUserFilterPreset({
                      presetName,
                      presetKey: activeFilterPreset.key,
                    });
                    activateUserFilterPreset({
                      presetName,
                      presetKey: undefined,
                    });

                    openStateControl("close");
                  }
                }}
              >
                {t("Remove filter")}
              </Button>
              <Button
                isPrimary
                isOutline
                onClick={() => {
                  persistUserFilterPresets();
                  openStateControl("close");
                }}
              >
                {t("Save")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterForm;
