import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";
import { UserStatuses } from "utilities/type-helpers/userStatuses";

const userStatusesUntranslatedList = [
  {
    value: UserStatuses.active,
    label: "Active",
  },
  {
    value: UserStatuses.inactive,
    label: "Inactive",
  },
  {
    value: UserStatuses.ignore,
    label: "Ignore",
  },
];

export const getUserStatuses = createGetList(userStatusesUntranslatedList);
export const getUserStatusLabel = createGetLabel(userStatusesUntranslatedList);

export const useUserStatuses = createUtilityHook(userStatusesUntranslatedList);
