import { createTransformResponse } from "api/createEndpoint";
import { GetPropertyListReducedResponse } from "./types";

const transformResponse =
  createTransformResponse<GetPropertyListReducedResponse>((response: any) => {
    const newItems = response.items.map((item: any) => {
      const shapedItem = {
        ...item,
        _embedded: {
          contents: item?.contents.map((content: any) => {
            const sortedContentMedia = !!content?.contentMedia
              ? content?.contentMedia
                  .sort((a: any, b: any) => a.rank - b.rank)
                  .filter((item: any) => item.type === "image")
              : [];
            // we need just one media - the one with the lowest rank but it has to be an array
            const subItemArray = [
              {
                ...sortedContentMedia[0],
                _embedded: {
                  media: sortedContentMedia[0]?.media?.[0],
                },
              },
            ];
            delete subItemArray[0].media;
            const shapedContent = {
              ...content,
              _embedded: {
                contentMedias: subItemArray,
              },
            };
            delete shapedContent.contentMedia;
            return shapedContent;
          }),
          address: item?.propertyaddress?.[0],
        },
      };
      delete shapedItem.contents;
      delete shapedItem.propertyaddress;
      return shapedItem;
    });
    return {
      ...response,
      items: newItems,
    };
  });

export default transformResponse;
