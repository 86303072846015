import { casaoneApi } from "api";
import listQuery from "./endpoints/list/query";
import listProvidesTags from "./endpoints/list/providesTags";
import phaseRelationshipQuery from "./endpoints/phase-relationship/query";
import phaseRelationshipProvidesTags from "./endpoints/phase-relationship/providesTags";
import phaseRelationshipTransformResponse from "./endpoints/phase-relationship/transformResponse";

const userApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getPhaseList: builder.query({
      query: listQuery,
      providesTags: listProvidesTags,
    }),
    getPhaseRelationship: builder.query({
      query: phaseRelationshipQuery,
      providesTags: phaseRelationshipProvidesTags,
      transformResponse: phaseRelationshipTransformResponse,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPhaseListQuery,
  useGetPhaseRelationshipQuery,
  useLazyGetPhaseListQuery,
  useLazyGetPhaseRelationshipQuery,
} = userApi;
