import { createTransformResponse } from "api/createEndpoint";
import { GetListReducedResponse } from "./types";

const transformResponse = createTransformResponse<GetListReducedResponse>(
  (response: any) => {
    const newItems = response.items.map((item: any) => {
      const recipient = item?.recipient[0];
      const sender = item?.sender[0];

      const shapedItem = {
        ...item,
        threadUpdated: {
          date: item.threadUpdated,
          timezone_type: 3,
          timezone: "Europe/Zurich",
        },
        start: {
          date: item.start,
          timezone_type: 3,
          timezone: "Europe/Zurich",
        },
        _embedded: {
          events: item.events,
          creator: {
            username: item?.creator?.[0] ? item?.creator?.[0]?.username : null,
          },
          eventType: item?.eventType?.[0]
            ? {
                id: item.eventType[0].id,
                baseType: item.eventType[0].baseType,
              }
            : null,
          attendees: !!item?.attendees
            ? item?.attendees.map((attendee: any) => {
                return {
                  ...attendee,
                  _embedded: {
                    contact: attendee?.contact?.[0] || null,
                  },
                };
              })
            : [],
          property: item?.property?.[0]
            ? {
                ...item?.property?.[0],
                _embedded: {
                  contents: item?.property?.[0]?.contents.map(
                    (content: any) => {
                      const sortedContentMedia = !!content?.contentMedia
                        ? content?.contentMedia
                            .sort((a: any, b: any) => a.rank - b.rank)
                            .filter(
                              (item: any) =>
                                item.type === "image" ||
                                item.type === "offer-logo"
                            )
                        : [];
                      const offerLogo = sortedContentMedia.find(
                        (item: any) => item.type === "offer-logo"
                      );
                      const relevantImage = offerLogo || sortedContentMedia[0];
                      // we need just one media - the one with the lowest rank but it has to be an array
                      const subItemArray = [
                        {
                          ...relevantImage,
                          _embedded: {
                            media: relevantImage?.media?.[0],
                          },
                        },
                      ];
                      delete subItemArray[0].media;
                      const shapedContent = {
                        ...content,
                        _embedded: {
                          contentMedias: subItemArray,
                        },
                      };
                      delete shapedContent.contentMedia;
                      return shapedContent;
                    }
                  ),
                },
              }
            : null,
        },
      };
      delete shapedItem.attendees;
      delete shapedItem.sender;
      delete shapedItem.recipient;
      delete shapedItem._embedded.property?.contents;
      delete shapedItem.property;
      delete shapedItem.events;
      if (recipient) {
        const recipientContact = recipient.contact[0];
        shapedItem._embedded.recipient = {
          ...recipientContact,
          id: shapedItem.id,
          attendeeId: recipient.id,
          contactId: recipientContact.id,
        };
      }
      if (sender) {
        const senderContact = sender.contact[0];
        shapedItem._embedded.sender = {
          ...senderContact,
          id: shapedItem.id,
          attendeeId: sender.id,
          contactId: senderContact.id,
        };
      }
      return shapedItem;
    });
    return {
      ...response,
      items: newItems,
    };
  }
);

export default transformResponse;
