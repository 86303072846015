import { casaoneApi } from "api";
import listQuery from "./endpoints/list/query";
import listProvidesTags from "./endpoints/list/providesTags";

const userApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateList: builder.query({
      query: listQuery,
      providesTags: listProvidesTags,
    }),
  }),
  overrideExisting: false,
});

export const { useGetTemplateListQuery, useLazyGetTemplateListQuery } = userApi;
