import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import NumberField from "@casasoft/styleguide/components/forms/NumberField";
import SwitchButton from "@casasoft/styleguide/components/formElements/SwitchButton";
import { withTranslation } from "react-i18next";
import { Numvals } from "utilities";

class PropertyFloorField extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.defaultState;
  }

  defaultState = {
    working: false,
    forcedField: false,
  };

  render() {
    return (
      <Fragment>
        {this.state.forcedField ||
        parseInt(this.props.value, 10) > 3 ||
        parseInt(this.props.value, 10) < -1 ? (
          <NumberField
            nobox
            id={this.props.id}
            label={Numvals.numvalLabel("floor")}
            value={this.props.value}
            onChange={(value) => {
              this.setState({ forcedField: true });
              this.props.onChange?.(value);
            }}
            suffix="Etage"
            step={1}
            message={this.props.message}
          />
        ) : (
          <SwitchButton
            id={this.props.id}
            keyPass="numval.floor"
            label={Numvals.numvalLabel("floor")}
            value={this.props.value}
            onChange={(value) => {
              this.props.onChange?.(value);
            }}
            message={this.props.message}
            buttons={[
              {
                label: "-",
                value: "-4",
                id: `${this.props.id}-`,
              },
              {
                label: "-1",
                value: "-1",
                id: `${this.props.id}-1`,
              },
              {
                label: this.props.t("GF"),
                value: "0",
                id: `${this.props.id}EG`,
              },
              {
                label: "1",
                value: "1",
                id: `${this.props.id}1`,
              },
              {
                label: "2",
                value: "2",
                id: `${this.props.id}2`,
              },
              {
                label: "3",
                value: "3",
                id: `${this.props.id}3`,
              },
              {
                label: "+",
                value: "4",
                id: `${this.props.id}+`,
              },
            ]}
          />
        )}
      </Fragment>
    );
  }
}

PropertyFloorField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  id: PropTypes.string,
};

PropertyFloorField.defaultProps = {
  value: null,
  message: [],
  id: "floor",
};

export default withTranslation()(PropertyFloorField);
