import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseContactCreator, {
  ContactResourceTypes,
  filterOutUsersAndSubsidiaries,
} from "./baseContactCreator";

const name = "matchingsContact" as const;
const pluralName = "matchingsContacts" as const;

export const { types, actions, rootReducer }: ContactResourceTypes =
  createResource(
    baseContactCreator({
      name,
      pluralName,
      forcedFilter: filterOutUsersAndSubsidiaries,
    })
  );

const matchingsContactResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const matchingsContactResource =
  matchingsContactResourceCreator.resource;
export const useMatchingsContact = matchingsContactResourceCreator.createHook();
