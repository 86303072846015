import { AddReminderContainer } from "../../add-reminder/AddReminderContainer";
import { useTranslation } from "react-i18next";
import { type AddRemainderFormShape } from "../../add-reminder/AddReminderForm/AddReminderForm";
import { SubmitHandler } from "react-hook-form";
import { SymbolBubble } from "@casasoft/styleguide/components/helpers/Bubbles/SymbolBubble";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import { isEmptyAddReminderForm } from "../../add-reminder/AddReminderForm/helpers/isEmptyAddReminderForm";

interface MessageAddReminderControlProps {
  value: AddRemainderFormShape | undefined;
  onSubmit: SubmitHandler<AddRemainderFormShape>;
  onToggle?: (isOpen: boolean) => string | undefined;
}

export const MessageAddReminderControl = ({
  value,
  onSubmit,
  onToggle,
}: MessageAddReminderControlProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageSubject, setMessageSubject] = useState<string | undefined>();

  const handleClick = () => {
    setIsModalOpen((isOpen) => {
      const subject = onToggle && onToggle(!isOpen);

      if (subject) {
        setMessageSubject(subject);
      }

      return !isOpen;
    });
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = (data: AddRemainderFormShape) => {
    setIsModalOpen(false);
    onSubmit(data);
  };

  return (
    <div onClick={handleClick} className="tw-cursor-pointer">
      <div className="tw-flex">
        <SymbolBubble
          symbol="check"
          color="danger"
          correctionRight={0}
          show={!isEmptyAddReminderForm(value)}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faBell}
            className="tw-mr-3 tw-text-tw-cs-accent-color"
          />
        </SymbolBubble>
        {t("Add reminder")}
      </div>
      <AddReminderContainer
        isModalOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        defaultData={value}
        messageSubject={messageSubject}
      />
    </div>
  );
};
