import { CasaoneResultDescription } from "api/types";
import {
  GetMessagePreviewSingleResponse,
  GetMessagePreviewSingleBody,
} from "./types";

const providesTags: CasaoneResultDescription<
  GetMessagePreviewSingleResponse,
  GetMessagePreviewSingleBody
> = () => {
  // TODO: backend should send us some unique id for the requests or we should make one to cache the results
  // We need to cache id based on combination of HTML, recipient, sender, property and lang
  return ["MessagePreview"];
};

export default providesTags;
