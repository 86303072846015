import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseEventCreator, { EventResourceTypes } from "./baseEventCreator";

const name = "mainReminder" as const;
const pluralName = "mainReminders" as const;

export const { types, actions, rootReducer }: EventResourceTypes =
  createResource(
    baseEventCreator({
      name,
      pluralName,
      apiContext: "reminder-list",
      forcedFilter: [
        {
          type: "eq",
          field: "type",
          value: "reminder",
        },
        {
          type: "orx",
          conditions: [
            {
              type: "neq",
              field: "doneReason",
              value: "ignore",
              where: "and",
            },
            {
              type: "isnull",
              field: "doneReason",
            },
          ],
        },
      ],
      forcedOrderBy: [
        // {
        //   type: "field",
        //   field: "start",
        //   direction: "desc",
        // },
      ],
    })
  );

const mainReminderResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const mainReminderResource = mainReminderResourceCreator.resource;
export const useMainReminder = mainReminderResourceCreator.createHook();
