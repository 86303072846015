// Structure: "key: [i18nKey, group]"  (translation must be done outside and not in this file because it would always use English as language)
export const featuresList = {
  "has-elevator": ["standards:Has elevator", "none"],
  // "has-nice-view": ["standards:Nice view", "none"],
  "has-fireplace": ["standards:Chimney fireplace", "none"],
  "has-cabletv": ["standards:Cable TV", "none"],
  "is-child-friendly": ["standards:Child friendly", "none"],
  "has-parking": ["standards:Parking", "none"],
  "has-garage": ["standards:Garage", "none"],
  "has-balcony": ["standards:Balcony", "none"],
  // "has-isdn": [ i8n.t("standards:ISDN", "none"],
  "is-wheelchair-accessible": ["standards:Wheelchair accessible", "none"],
  "is-animal-friendly": ["standards:Animal friendly", "none"],
  // "has-ramp": [ i8n.t("standards:Ramp", "none"],
  // "has-lifting-platform": ["standards:Has lifting platform", "none"],
  // "has-railway-terminal": ["Train station", "none"],
  // "has-restrooms": [ i8n.t("standards:Restrooms", "none"],
  // "has-water-supply": ["standards:Water supply", "none"],
  // "has-sewage-supply": ["standards:Sewage supply", "none"],
  // "has-power-supply": ["standards:Power supply", "none"],
  "has-gas-supply": ["standards:Gas supply", "none"],
  "has-flat-sharing-community": ["standards:Flat sharing community", "none"],
  "is-corner-house": ["standards:Corner house", "none"],
  // "is-middle-house": ["standards:Middle house", "none"],
  "has-connected-building-land": ["standards:Connected building land", "none"],
  "has-gardenhouse": ["standards:Gardenhouse", "none"],
  "has-raised-ground-floor": ["standards:Raised ground floor", "none"],
  "is-new": ["standards:As New", "condition"],
  // "is-old": [ i8n.t("standards:Old", group: "none"],
  "has-building-law-restrictions": [
    "standards:has building law restrictions",
    "none",
  ],
  // "is-under-roof": ["standards:Is under roof", "none"],
  "has-swimmingpool": ["standards:Swimmingpool", "none"],
  "is-minergie-general": ["Minergie", "minergie"],
  // "is-minergie-certified": ["standards:Minergie certified", "none"],
  "is-minergie-p": ["Minergie-P", "minergie"],
  "is-minergie-eco": ["Minergie-ECO", "minergie"],
  "is-minergie-p-eco": ["Minergie-P-ECO", "minergie"],
  "is-minergie-a": ["Minergie-A", "minergie"],
  "is-minergie-a-eco": ["Minergie-A-ECO", "minergie"],
  "is-minergie-areal": ["Minergie-Areal", "minergie"],
  "is-mqs-bau": ["MQS Bau", "minergie"],
  "is-mqs-betrieb": ["MQS Betrieb", "minergie"],
  "is-sunny": ["standards:Sunny", "none"],
  "is-quiet": ["standards:Quiet", "none"],
  is_projection: ["Projection", "none"],
  // "has_demolition_property": [ standards:Contains demolition structure", "none"],
  "has-mountain-view": ["standards:Mountain view", "none"],
  "has-lake-view": ["standards:Lake view", "none"],
  "on-a-south-slope": ["standards:South-facing slope", "none"],
  "on-a-slope": ["standards:On a slope", "none"],
  "has-car-port": ["standards:Car port", "none"],
  // "has-double-car-port":[ "standards:Double car port", "none"],
  "has-double-garage": ["standards:Double garage", "none"],
  // "on-even-ground": ["standards:Even Ground", "none"],
  "has-rental-deposit-guarantee": [
    "standards:Rental deposit guarantee",
    "none",
  ],
  "is-non-smoking": ["standards:Non smoking", "none"],
  "is-demolition-property": ["standards:Demolition property", "condition"],
  "is-dilapidated": ["standards:Dilapidated", "condition"],
  "is-gutted": ["standards:Gutted", "condition"],
  "is-first-time-occupancy": ["standards:First time occupancy", "condition"],
  "is-well-tended": ["standards:Well tended", "condition"],
  "is-modernized": ["standards:Modernized", "condition"],
  "is-renovation-indigent": ["standards:Renovation indigent", "condition"],
  "is-shell-construction": ["standards:Shell construction", "condition"],
  "is-new-construction": ["standards:New construction", "condition"],
  "is-partially-renovation-indigent": [
    "standards:Partially renovation indigent",
    "condition",
  ],
  "is-partially-refurbished": ["standards:Partially refurbished", "condition"],
  "is-refurbished": ["standards:Refurbished", "condition"],
  "has-washing-machine": ["standards:Washing machine", "none"],
  "has-tumbler": ["standards:Tumbler", "none"],
  "has-kachelofen": ["standards:Kachelofen", "none"],
  "is-only-allowed-as-primary-residency": [
    "standards:Primary residency",
    "none",
  ],
  "is-allowed-as-secondary-residency": [
    "standards:Secondary residency",
    "none",
  ],
  "has-foreign-quota": ["standards:Foreign quota", "none"],
  "is-ground-floor": ["Ground floor", "none"],
  "has-remote-viewings": ["Remote viewing", "none"],
  // "has-fiber-optics": ["standards:Fiber optic connection", "none"],
  "has-multimedia-wiring": ["standards:Multimedia wiring", "none"],
  "has-charging-station": [
    "standards:Charging station for electric car",
    "none",
  ],
  "is-temporary-use": ["standards:Temporary use", "none"],
  "is-vat-opted": ["standards:VAT opted", "finance"],
  "is-not-vat-opted": ["standards:VAT not opted", "finance"],
  "is-share-deal": ["standards:Share deal", "finance"],
  "is-not-share-deal": ["standards:Asset deal", "finance"],
};
