import LoadingScreen from "./LoadingScreen";
import Root from "config/Root";
import useAuth from "hooks/useAuth";

function CasaauthContainer() {
  const [isAuthenticated] = useAuth();

  return !isAuthenticated ? <LoadingScreen /> : <Root />;
}

export default CasaauthContainer;
