import { CasaoneBaseQueryArgs } from "api/types";
import queryBuilder from "utilities/queryBuilder";
import { GetEventListQueryParams } from "./types";

function query({ query }: GetEventListQueryParams): CasaoneBaseQueryArgs {
  const builtQuery = queryBuilder.buildToUrl(
    query.orderBy,
    [
      ...query.filter,
      {
        type: "innerjoin",
        field: "attendees",
        alias: "attendee_recipient",
        condition: "attendee_recipient.attendeeType = 'recipient'",
        conditionType: "with",
      },
      {
        type: "leftjoin",
        field: "attendees",
        alias: "attendee_sender",
        condition: "attendee_sender.attendeeType = 'sender'",
        conditionType: "with",
      },
      {
        type: "leftjoin",
        field: "contact",
        alias: "recipient_contact",
        parentAlias: "attendee_recipient",
      },
      {
        type: "leftjoin",
        field: "contact",
        alias: "sender_contact",
        parentAlias: "attendee_sender",
      },
      {
        type: "notin",
        field: "type",
        values: ["reminder", "contact-merge"],
      },
      {
        type: "orx",
        conditions: [
          {
            type: "neq",
            field: "doneReason",
            value: "ignore",
            where: "and",
          },
          {
            type: "isnull",
            field: "doneReason",
          },
        ],
        where: "and",
      },
    ],
    {
      pagesize: query.pageSize,
      page: query.page,
      context: "event-list",
    }
  );

  return {
    url: `event?${builtQuery}`,
    method: "GET",
  };
}

export default query;
