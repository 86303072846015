import { forwardRef, HTMLAttributes, ReactNode } from "react";

interface BadgeProps
  extends Omit<HTMLAttributes<HTMLElement>, "className" | "children"> {
  children?: ReactNode;
  color: "danger" | "warning" | "primary" | "secondary" | "success";
  uppercase?: boolean;
  innerShadow?: boolean;
  marginLeft?: string;
  marginRight?: string;
  size?: "normal" | "medium";
}

const Badge = forwardRef<HTMLElement, BadgeProps>(function (
  {
    children,
    color,
    uppercase,
    innerShadow,
    marginLeft,
    marginRight,
    size = "normal",
    ...smallProps
  },
  ref
) {
  const colorClasses: string[] = [];
  switch (color) {
    case "danger":
      colorClasses.push("tw-bg-cs-danger", "tw-text-cs-shade-white");
      break;
    case "warning":
      colorClasses.push("tw-bg-cs-warning");
      break;
    case "primary":
      colorClasses.push("tw-bg-tw-cs-accent-color", "tw-text-cs-shade-white");
      break;
    case "secondary":
      colorClasses.push("tw-bg-cs-shade-100", "tw-text-cs-shade-900");
      break;
    case "success":
      colorClasses.push("tw-bg-cs-success", "tw-text-cs-shade-white");
      break;
  }
  let sizeClass = "tw-font-semibold tw-text-xs tw-py-0.5 tw-px-1";
  switch (size) {
    case "medium":
      sizeClass = "tw-font-semibold tw-text-sm tw-py-1 tw-px-2";
      break;
  }

  return (
    <small
      {...smallProps}
      ref={ref}
      className={`tw-inline-block tw-leading-none tw-whitespace-nowrap tw-rounded ${sizeClass}
        ${marginLeft ? marginLeft : ""}
        ${marginRight ? marginRight : ""}
        ${innerShadow ? "tw-shadow-badge-inner-shadow" : ""}
        ${uppercase ? "tw-uppercase" : ""}
        ${colorClasses.join(" ")}
      `}
    >
      {children}
    </small>
  );
});
export default Badge;
