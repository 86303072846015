// TODO: This file is beyond salvation. Please consider splitting it into multiple files and refactoring/rewriting.
/* eslint-disable deprecation/deprecation */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import {
  getSimilarTopics,
  TOPIC_Uncatecorized,
  TOPIC_User,
  TOPIC_Userprofile,
  TOPIC_Dashboard,
  TOPIC_Dokumentation,
  TOPIC_Companyprofile,
  TOPIC_Job,
  TOPIC_Contact,
  TOPIC_Unit,
  TOPIC_Logbook,
  TOPIC_Media,
  TOPIC_Message,
  TOPIC_Property,
  TOPIC_Project,
  TOPIC_Publication,
  TOPIC_Invoice,
  TOPIC_Reporting,
  TOPIC_Interface,
  TOPIC_Template,
} from "utilities/faqSimilarTopics";
import { useTranslation } from "react-i18next";
import { createGtagEvent } from "utilities/gtagHelper";
import { useFaq } from "entities/zendeskFaq/store";
import { useAppSelector } from "redux/hooks";
import { selectContactLang } from "redux/auth/selectors";

/** Hook to get relevant helpcenter article and similar topics */
const useFaqArticleList = () => {
  const fetchLang = useAppSelector(selectContactLang) === "fr" ? "fr" : "de";
  const { t } = useTranslation();
  const [selectedTopic, setSelectedTopic] = useState<string>();

  const {
    store: faqStore,
    fetchList: fetchFaqItems,
    getItem: getFaqItem,
  } = useFaq({
    fetchList: false,
  });

  const { pathname } = useLocation();
  const similarTopics = useMemo(
    () => getSimilarTopics(pathname, selectedTopic),
    [pathname, selectedTopic]
  );

  useEffect(() => {
    fetchFaqItems({
      urlParams: {
        section: similarTopics.activeTopic,
        lang: fetchLang,
        sort_by: "position",
        sort_order: "asc",
      },
    });
  }, [fetchFaqItems, fetchLang, similarTopics.activeTopic]);

  /** get label for helpcenter topics */
  const getLabel = (topicId: string) => {
    switch (topicId) {
      case TOPIC_Companyprofile:
        return t("Company Profile");
      case TOPIC_Userprofile:
        return t("My profile");
      case TOPIC_Template:
        return t("Template");
      case TOPIC_Job:
        return t("Jobs");
      case TOPIC_Unit:
        return t("Units");
      case TOPIC_Logbook:
        return t("Logbook");
      case TOPIC_Media:
        return t("Media");
      case TOPIC_Property:
        return t("Properties");
      case TOPIC_Project:
        return t("Projects");
      case TOPIC_Publication:
        return t("Publications");
      case TOPIC_Invoice:
        return t("Invoices");
      case TOPIC_Reporting:
        return t("Reporting");
      case TOPIC_Uncatecorized:
        return t("General");
      case TOPIC_Message:
        return t("Messages");
      case TOPIC_Contact:
        return t("Contacts");
      case TOPIC_Interface:
        return t("Interfaces");
      case TOPIC_User:
        return t("User");
      case TOPIC_Dokumentation:
        return t("Documentation");
      case TOPIC_Dashboard:
      default:
        return t("Dashboard");
    }
  };

  const faqArticles = faqStore.items
    .map((article) => {
      return {
        item_key: `${article.id}`,
        id: article.id,
        item_active: faqStore.item?.id === article.id,
        title: (
          <span
            dangerouslySetInnerHTML={{
              __html: article?.title || "",
            }}
          />
        ),
        content: (
          <span
            dangerouslySetInnerHTML={{
              __html: article?.body || "",
            }}
          />
        ),
        promoted: article.promoted,
      };
    })
    .filter((article) => article.promoted);

  /** Function to select a article */
  const onFaqArticleSelect = useCallback(
    (newArticle?: string) => {
      if (newArticle) {
        createGtagEvent(
          "Selected a helpcenter article",
          "Helpcenter",
          "Helpcenter",
          parseInt(newArticle)
        );
      }
      getFaqItem({ id: newArticle || 0, params: { lang: fetchLang } });
    },
    [getFaqItem, fetchLang]
  );
  /** Function to select a helpcenter topic */
  const onFaqTopicSelect = useCallback((newTopic?: string) => {
    setSelectedTopic(newTopic);
    if (newTopic) {
      createGtagEvent(
        "Selected a helpcenter topic",
        "Helpcenter",
        "Helpcenter",
        parseInt(newTopic)
      );
    }
  }, []);

  const getActiveItem = () => {
    if (faqStore?.item) {
      return {
        item_key: `${faqStore.item.id}`,
        id: faqStore.item.id,
        item_active: faqStore.item?.id === faqStore.item.id,
        title: (
          <span
            dangerouslySetInnerHTML={{
              __html: faqStore.item?.title || "",
            }}
          />
        ),
        content: (
          <span
            dangerouslySetInnerHTML={{
              __html: faqStore.item?.body || "",
            }}
          />
        ),
        url: faqStore.item.html_url,
        promoted: faqStore.item.promoted,
      };
    }
    return undefined;
  };

  return {
    faqArticles,
    activeItem: getActiveItem(),
    similarTopics: {
      ...similarTopics,
      activeTopic: {
        value: similarTopics.activeTopic,
        name: getLabel(similarTopics.activeTopic),
      },
      related: similarTopics.related.map((label) => ({
        value: label,
        name: getLabel(label),
      })),
    },
    onFaqTopicSelect: onFaqTopicSelect,
    onFaqArticleSelect: onFaqArticleSelect,
    isLoadingFaqs: faqStore.isFetching || faqStore.isFetchingItem,
  };
};

export default useFaqArticleList;
