import { Preset } from "components/miscellaneous/FilterForm";
import { UserFilterPresets } from "redux/auth";
import { useRef, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { useLazyGetUserContactListQuery } from "api/entities/user";

// get activeFilterpreset and fill it with the current active form
const PrepareActiveFilterPreset = (
  /** either the already grabbed  */
  usersActiveFilterPreset?: NonNullable<UserFilterPresets[string]>[number],
  filterFormOptions?: Omit<Preset, "key">
) => {
  const [userData, setUserData] = useState<{ username: string; id: string }[]>(
    []
  );
  const [getFullUserContactListQuery] = useLazyGetUserContactListQuery();

  useEffect(() => {
    if (usersActiveFilterPreset && usersActiveFilterPreset?.preset?.user) {
      // fetch user data for the user filter to display the usernames instead of id's
      const fetchData = async (users: string | string[]) => {
        const cleanUsers = Array.isArray(users) ? users : [users];
        const result = await getFullUserContactListQuery({
          includeCasasoftUsers: true,
          query: {
            filter: [
              {
                type: "in",
                field: "id",
                alias: "contact",
                values: cleanUsers,
              },
            ],
            orderBy: [],
            pageSize: -1,
            page: 1,
          },
        }).unwrap();
        const newUserData = result._embedded.user
          .map((user) =>
            user._embedded?.contact?.id
              ? {
                  username: user.username,
                  id: user._embedded?.contact?.id,
                }
              : undefined
          )
          .filter(Boolean) as { username: string; id: string }[];
        setUserData(newUserData);
      };

      const users = usersActiveFilterPreset?.preset?.user;
      // check if users already is an array of usernames
      if (!!users && !users[0].includes("@")) {
        fetchData(users).catch(console.error); //eslint-disable-line no-console
      }
    }
  }, [usersActiveFilterPreset, getFullUserContactListQuery]);

  // gets the currently active filter preset and its values from the store
  if (usersActiveFilterPreset) {
    const activeFilterPreset: Preset = {
      preset: {},
      label: usersActiveFilterPreset.label,
      key: usersActiveFilterPreset.key,
    };
    if (filterFormOptions?.preset) {
      Object.entries(filterFormOptions.preset).forEach((presetItemDuplets) => {
        let resultItem = presetItemDuplets[1];
        if (usersActiveFilterPreset) {
          const foundValue =
            usersActiveFilterPreset.preset[presetItemDuplets[0]];
          if (foundValue) {
            // if the presetItem is the user filter, replace the id's with the usernames
            // userData is just set if it's an array of id's and not already usernames
            if (
              resultItem.name === "user" &&
              Array.isArray(foundValue) &&
              !!userData
            ) {
              const userRender = foundValue.map((id) => {
                const foundUser = userData.find((user) => {
                  return user.id === id;
                });
                return foundUser?.username || id;
              });
              resultItem = {
                ...resultItem,
                value: userRender,
              };
            } else {
              resultItem = {
                ...resultItem,
                value: foundValue,
              };
            }
          }
        }
        activeFilterPreset.preset[presetItemDuplets[0]] = resultItem;
      });
    }
    return activeFilterPreset;
  }
};

function usePreparedActiveFilterPreset(
  storeName: string,
  filterFormOptions?: Omit<Preset, "key">
) {
  const { auth } = useAppSelector((state) => ({
    auth: state.auth,
  }));

  const emptyArrayRef = useRef<[]>([]); // safety -> so filterPresets will fall back to a ref array instead of a new variable for every render

  const filterPresets =
    auth.profile.data.casaoneUser?.filterPresets[storeName] ||
    emptyArrayRef.current;

  const activePreset = filterPresets.find((presets) => presets.active);
  return PrepareActiveFilterPreset(activePreset, filterFormOptions);
}

export default usePreparedActiveFilterPreset;
