import { useState } from "react";
import { Card, CardBody } from "@casasoft/styleguide/components/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faUserEdit } from "@fortawesome/pro-light-svg-icons";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import { useTranslation } from "react-i18next";

import Dashometer from "../../miscellaneous/charts/Dashometer";
import DashboardCompleteYourProfileEditForm from "./DashboardCompleteYourProfileEditForm";
import Button from "@casasoft/styleguide/components/forms/Button";
import { refreshProfile } from "redux/auth";
import { logOut } from "utilities/auth";
import { getFields } from "./helpers/dashboardCompleteYourProfileHelpers";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectCasaoneUserContact } from "redux/auth/selectors";

type DashboardCompleteYourProfileCardProps = {
  size?: "small" | "large";
  onChange?: () => void;
};

const DashboardCompleteYourProfileCard = ({
  size = "large",
  onChange,
}: DashboardCompleteYourProfileCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const contact = useAppSelector(selectCasaoneUserContact);

  if (!contact) {
    return (
      <>
        <Card hasPrimaryBg>
          <CardBody hasPrimaryBg>
            {t(
              "This user is not assigned to any contact. Some CASAONE features are therefore affected."
            )}
          </CardBody>
        </Card>
        <div className="tw-mb-4" />
      </>
    );
  }

  const { fields, numCompletedFields } = getFields({
    t,
    contact,
  });

  if (numCompletedFields === fields.length) {
    return null;
  }

  return (
    <>
      <Card hasPrimaryBg>
        <CardBody hasPrimaryBg>
          <div
            className={
              size === "large" ? "lg:tw-grid lg:tw-grid-cols-4 lg:tw-gap-4" : ""
            }
          >
            <div
              className={`tw-max-w-sm tw-mx-auto ${
                size === "large" ? "tw-min-w-0" : ""
              }`}
            >
              <Dashometer
                percent={(numCompletedFields / fields.length) * 100}
                label={t("Profile quality")}
              />
            </div>
            <div className={size === "large" ? "tw-min-w-0 tw-col-span-2" : ""}>
              <div className="tw-text-tw-cs-accent-color tw-mb-2">
                {t("Expert advice: Complete your profile", {
                  nsSeparator: "::",
                })}
              </div>
              <div className="tw-text-sm">
                <p>
                  {t(
                    "Only a couple more steps to 100%. With an up-to-date user profile you will optimize a more personalised CASAONE experience. It only takes a minute."
                  )}
                </p>
                <ul style={{ columnCount: 2 }} className="fa-ul">
                  {fields.map((field) => {
                    return (
                      <li
                        key={field.key}
                        className={
                          !field.completed ? "tw-text-cs-shade-500" : ""
                        }
                      >
                        <span className="fa-li">
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        {field.label}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={size === "large" ? "tw-min-w-0 tw-col-span-1" : ""}>
              <Button
                isPrimary
                isBlock
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <FontAwesomeIcon icon={faUserEdit} className="tw-mr-2" />
                {t("Complete your profile")}
              </Button>
            </div>
          </div>
        </CardBody>
        {showModal && (
          <FormModal
            isOpen={showModal}
            onDone={() => {
              setShowModal(false);
              dispatch(
                refreshProfile((success: boolean) => {
                  if (success) {
                    onChange?.();
                    setShowModal(false);
                  } else {
                    logOut();
                  }
                })
              );
            }}
            form={(props) => (
              <DashboardCompleteYourProfileEditForm
                onDone={props.onDone}
                onFail={props.onFail}
                contact={contact}
              />
            )}
            header={t("Complete your profile")}
            size="sm"
            onCancel={() => {
              setShowModal(false);
            }}
          />
        )}
      </Card>
      <div className="tw-mb-4" />
    </>
  );
};

export default DashboardCompleteYourProfileCard;
