import queryBuilder from "utilities/queryBuilder";
import { GetPhaseRelationshipQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<GetPhaseRelationshipQueryParams>(
  ({ contact, property }) => {
    const builtQuery = queryBuilder.buildToUrl(
      [
        {
          type: "field",
          field: "created",
          direction: "desc",
        },
      ],
      [
        {
          type: "eq",
          field: "contact",
          value: contact,
        },
        {
          type: "eq",
          field: "property",
          value: property,
        },
        {
          type: "eq",
          field: "relationType",
          value: "prospective-customer",
        },
      ],
      {
        pagesize: 1,
        page: 1,
      }
    );

    return {
      url: `property-contact?${builtQuery}`,
      method: "GET",
    };
  }
);

export default query;
