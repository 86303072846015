import { CasaoneBaseQueryArgs } from "api/types";
import queryBuilder from "utilities/queryBuilder";
import { GetUserSignatureListQueryParams } from "./types";

function query({
  query,
}: GetUserSignatureListQueryParams): CasaoneBaseQueryArgs {
  const builtQuery = queryBuilder.buildToUrl(query.orderBy, query.filter, {
    pagesize: query.pageSize,
    page: query.page,
  });
  return {
    url: `user-signature?${builtQuery}`,
    method: "GET",
  };
}

export default query;
