import * as Dialog from "@radix-ui/react-dialog";
import { ReactNode } from "react";

interface ModalHeaderProps {
  children: ReactNode;
}

function ModalHeader({ children }: ModalHeaderProps) {
  return (
    <div className="tw-p-4 tw-border-0 tw-border-b tw-border-solid tw-border-b-cs-shade-300">
      <Dialog.Title className="tw-text-base tw-mb-0 tw-pr-8">
        {children}
      </Dialog.Title>
    </div>
  );
}

export default ModalHeader;
