import { PureComponent } from "react";
import PropTypes from "prop-types";
import FieldInputGroup from "./FieldInputGroup";
import extractDataAttributes from "@casasoft/styleguide/utilities/extractDataAttributes";

class FieldElem extends PureComponent {
  render() {
    let type;
    const [dataAttrs] = extractDataAttributes(this.props);
    if (this.props.type === "currency") {
      type = "number";
    } else if (this.props.type === "integer") {
      type = "number";
    } else if (this.props.type === "password") {
      type = "password";
    } else if (this.props.type === "rooms") {
      type = "text";
    } else {
      type = this.props.type;
    }
    const fieldProps = {
      disabled: this.props.disabled,
      autoComplete: this.props.autoComplete,
      id: this.props.id,
      placeholder: this.props.placeholder,
      onChange: this.props.onChange,
      value: this.props.value,
      onKeyUp: this.props.onKeyUp,
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      name: this.props.name,
      required: this.props.required,
      lang: this.props.lang,
      autoFocus: this.props.autoFocus,
      pattern: this.props.pattern,
      min: this.props.min,
      max: this.props.max,
      step: this.props.step,
      type,
      readOnly: this.props.readOnly,
      "aria-label": this.props.ariaLabel,
    };
    const classNames = ["cs-form-control"];
    if (this.props.message && this.props.message.length) {
      classNames.push("cs-form-control--invalid");
    }
    return (
      <FieldInputGroup
        prefix={this.props.prefix}
        prefixDropdown={this.props.prefixDropdown}
        suffix={this.props.suffix}
      >
        <input
          {...fieldProps}
          {...dataAttrs}
          className={classNames.join(" ")}
        />
      </FieldInputGroup>
    );
  }
}

FieldElem.propTypes = {
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prefixDropdown: PropTypes.bool,
  pattern: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
  lang: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
};

FieldElem.defaultProps = {
  prefix: "",
  suffix: "",
  prefixDropdown: false,
  pattern: null,
  min: null,
  max: null,
  step: null,
  message: null,
  disabled: false,
  id: "",
  required: false,
  placeholder: "",
  value: "",
  name: "",
  lang: null,
  autoComplete: "none",
  autoFocus: false,
  type: "text",
  readOnly: false,
};

export default FieldElem;
