import { Modal } from "@casasoft/styleguide/components/modal";
import { LanguageBreakdownModalBody } from "./LanguageBreakdownModal/LanguageBreakdownModalBody";
import { LanguageBreakdownModalFooter } from "./LanguageBreakdownModal/LanguageBreakdownModalFooter";
import { LanguageBreakdownModalHeader } from "./LanguageBreakdownModal/LanguageBreakDownModalHeader";
import { ContactShape } from "api/entities/contact/types";
import { LanguageContactGroupContainer } from "./LanguageContactGroupModal/LanguageContactGroupContainer";
import { useLanguageGroupModal } from "./helpers/useLanguageGroupModal";
import { useLanguageCheckboxStatus } from "./helpers/useLanguageCheckboxStatus";

export interface ReducedContactShape
  extends Pick<
    ContactShape,
    | "entityType"
    | "contactType"
    | "displayName"
    | "firstName"
    | "lastName"
    | "id"
    | "email"
    | "lang"
  > {}

export interface LanguageGroupData {
  key: string;
  label: string;
  contacts: ReducedContactShape[];
}

export interface LanguageBreakdownContainerProps {
  isOpen?: boolean;
  onClose?: () => void;
  onDone?: (selectedGroups: LanguageGroupData[]) => void;
  languageGroups: LanguageGroupData[];
  isLoading?: boolean;
}

export const LanguageBreakdownContainer = ({
  isOpen = false,
  onClose,
  onDone,
  languageGroups,
  isLoading = false,
}: LanguageBreakdownContainerProps) => {
  const {
    selectedLanguageGroup,
    handleInfoClick,
    handleLanguageGroupModalClose,
  } = useLanguageGroupModal(languageGroups);
  const languageCheckboxStatus = useLanguageCheckboxStatus(languageGroups);

  const handleDone = () => {
    const selectedGroups = languageGroups.filter((group) =>
      Object.keys(languageCheckboxStatus.languageChecked)
        .filter((langCode) => languageCheckboxStatus.languageChecked[langCode])
        .includes(group.key)
    );

    onDone && onDone(selectedGroups);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <LanguageBreakdownModalHeader />
        <LanguageBreakdownModalBody
          languageGroups={languageGroups}
          languageCheckboxStatus={languageCheckboxStatus}
          onInfoClick={handleInfoClick}
          isLoading={isLoading}
        />
        <LanguageBreakdownModalFooter onDone={handleDone} />
      </Modal>
      <LanguageContactGroupContainer
        languageGroup={selectedLanguageGroup}
        onClose={handleLanguageGroupModalClose}
      />
    </>
  );
};
