import Config from "config";
import createBaseActions from "entities/createBaseActions";
import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import EmailSettingImapSmtpState from "./types";

const name = "mainEmailSettingImapSmtp" as const;
const pluralName = "mainEmailSettingsImapSmtp" as const;

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: EmailSettingImapSmtpState,
    actions?: any
  ) => EmailSettingImapSmtpState;
}

export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: name,
  pluralName: pluralName,
  url: `${Config.apiUrl}/${Config.customerKey}/email-setting-imap-smtp/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "email-setting-imap-smtp",
    }),
  },
});

const mainEmailSettingImapSmtpResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const mainEmailSettingImapSmtpResource =
  mainEmailSettingImapSmtpResourceCreator.resource;
export const useMainEmailSettingImapSmtp =
  mainEmailSettingImapSmtpResourceCreator.createHook();
