import {
  faRetweet,
  faMinusCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import {
  CardHeader,
  CardHeaderAction,
  CardBody,
  Card,
} from "@casasoft/styleguide/components/card";
import ContactExcerpt from "./features/contact-excerpt/ContactExcerpt";
import ContactChooseModal from "./ContactChooseModal";
import { hasAccessTo } from "utilities/featureFlags";
import { ContactShape } from "entities/contact/types";
import Button from "@casasoft/styleguide/components/forms/Button";
import { useTranslation } from "react-i18next";
import { ReactNode, useState } from "react";
import { AddressHelper } from "utilities";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import GenericRemoveConfirm from "@casasoft/styleguide/components/genericRemoveConfirm/GenericRemoveConfirm";

type ContactChooseModalProps = React.ComponentProps<typeof ContactChooseModal>;

interface ContactSelectCardProps
  extends Pick<
    ContactChooseModalProps,
    "forcedFilter" | "validateContact" | "storeValidateContext" | "contactType"
  > {
  onChange: (contactId: string | null) => void;
  headerTitle: string;
  chooserHeaderTitle: string;
  contact?: ContactShape;
  isLoading?: boolean;
  renderStyle?: "normal" | "address" | "phone";
  linkTo?: boolean;
  emptyStateNode?: ReactNode;
  noRemoveConfirm?: boolean;
  minHeight?: string;
  validationErrorMessages?: ReactNode[];
}

function ContactSelectCard({
  onChange,
  headerTitle,
  chooserHeaderTitle,
  contact,
  isLoading,
  renderStyle = "normal",
  linkTo,
  emptyStateNode,
  noRemoveConfirm,
  minHeight,
  validationErrorMessages,
  // choose modal props
  forcedFilter,
  validateContact,
  storeValidateContext,
  contactType,
}: ContactSelectCardProps) {
  const { t } = useTranslation();
  const [contactChooseModalOpen, setContactChooseModalOpen] = useState(false);
  const [contactRemoveModalOpen, setContactRemoveModalOpen] = useState(false);

  const contactPhones = [];
  if (contact) {
    if (contact.mobile) {
      contactPhones.push(
        <>
          M: {contact.mobile} <br />
        </>
      );
    }
    if (contact.phoneDirect) {
      contactPhones.push(
        <>
          D: {contact.phoneDirect} <br />
        </>
      );
    }
    if (contact.phone) {
      contactPhones.push(
        <>
          T: {contact.phone} <br />
        </>
      );
    }
    if (contact.fax && contactPhones.length < 3) {
      contactPhones.push(<>F: {contact.fax}</>);
    }
  }

  const headerActions: CardHeaderAction[] = [];

  if (contact && hasAccessTo("basicContactFunctionallity")) {
    headerActions.push(
      {
        icon: faMinusCircle,
        onClick: () => {
          if (!noRemoveConfirm) {
            setContactRemoveModalOpen(true);
          } else {
            onChange(null);
          }
        },
      },
      {
        icon: faRetweet,
        onClick: () => {
          setContactChooseModalOpen(true);
        },
      }
    );
  }
  if (validationErrorMessages) {
    headerActions.push({
      icon: faExclamationTriangle,
      iconStyle: "danger",
      tooltip: {
        textAlign: "left",
        placement: "left",
        node: (
          <>
            {validationErrorMessages.map((data, i) => {
              return <div key={i}>{data}</div>;
            })}
          </>
        ),
      },
    });
  }

  return (
    <>
      <Card loading={isLoading}>
        <CardHeader actions={headerActions}>{headerTitle}</CardHeader>
        <CardBody hasMinHeight={minHeight !== undefined}>
          {contact && (
            <div className="tw-text-sm">
              {renderStyle === "normal" ? (
                <ContactExcerpt linkTo={linkTo} contact={contact} lines={3} />
              ) : (
                <div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
                  <div className="tw-min-w-0">
                    <ContactExcerpt contact={contact} linkTo={linkTo} />
                    {renderStyle === "phone" && contact && contact.email}
                    <br />
                  </div>
                  <div className="tw-min-w-0">
                    {renderStyle === "phone"
                      ? contactPhones.length > 0 && contactPhones
                      : contact._embedded?.address &&
                        AddressHelper.renderAddress(contact._embedded.address)}
                  </div>
                </div>
              )}
            </div>
          )}
          {!contact && hasAccessTo("basicContactFunctionallity") && (
            <>
              {emptyStateNode && (
                <div className="tw-text-sm tw-mb-4">{emptyStateNode}</div>
              )}
              <Button
                type="button"
                onClick={() => {
                  setContactChooseModalOpen(true);
                }}
                isBlock
                isPrimary
                isOutline
                buttonValue={t("Select")}
              />
            </>
          )}
        </CardBody>
      </Card>
      {hasAccessTo("basicContactFunctionallity") && (
        <ContactChooseModal
          contactType={contactType}
          headerTitle={chooserHeaderTitle}
          storeValidateContext={storeValidateContext}
          isOpen={contactChooseModalOpen}
          validateContact={validateContact}
          onCancel={() => {
            setContactChooseModalOpen(false);
          }}
          onDone={(chosenItems) => {
            onChange(chosenItems[0] || null);
            setContactChooseModalOpen(false);
          }}
          forcedFilter={forcedFilter}
        />
      )}
      <FormModal
        header={t("Remove contact")}
        isOpen={contactRemoveModalOpen}
        size="sm"
        onDone={() => {
          setContactRemoveModalOpen(false);
        }}
        onCancel={() => {
          setContactRemoveModalOpen(false);
        }}
        form={(props) => (
          <GenericRemoveConfirm
            onDone={props.onDone}
            onFail={props.onFail}
            onDelete={() => {
              onChange(null);
            }}
          />
        )}
      />
    </>
  );
}

export default ContactSelectCard;
