import { selectAlert } from "redux/app/selectors";
import { useAppSelector } from "redux/hooks";
import { Alert } from "../helpers-ux";
import { useLocation } from "react-router";

export const MainAlert = () => {
  const alert = useAppSelector(selectAlert());
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const showAlertParam = params.get("showAlert");

  if (!alert || !showAlertParam || showAlertParam === "false") {
    return null;
  }

  return <Alert color={alert.color}>{alert.children}</Alert>;
};
