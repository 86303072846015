import { useUpdateUserMutation } from "api/entities/user";
import {
  updateUserFilterPresets,
  type UserFilterPresetValue,
} from "redux/auth";
import {
  selectAllFilterPresets,
  selectProfileUsername,
} from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import store from "redux/store";
import { getUpdatedUserFilterPresets } from "./getUpdatedUserFilterPresets";

export const useUserFilterPresets = () => {
  const username = useAppSelector(selectProfileUsername);
  const allFilterPresets = useAppSelector(selectAllFilterPresets);
  const [updateUser] = useUpdateUserMutation();

  const persistUserFilterPresets = async () => {
    if (username) {
      await updateUser({
        username,
        filterPresets: allFilterPresets,
      }).unwrap();
    }
  };

  const removeUserFilterPreset = async ({
    presetName,
    presetKey,
  }: {
    presetName: string;
    presetKey: string;
  }) => {
    if (!username) {
      return;
    }

    const newPresets = {
      ...allFilterPresets,
      [presetName]: allFilterPresets[presetName]?.filter(
        (e: any) => e.key !== presetKey
      ),
    };

    store.dispatch(
      updateUserFilterPresets(presetName, newPresets[presetName] || [])
    );

    await updateUser({
      username,
      filterPresets: newPresets,
    }).unwrap();
  };

  const updateUserFilterPreset = async (props: {
    presetName: string;
    presetKey: string;
    label: string;
    active?: boolean;
    elementKey?: string;
    elementValue?: string | string[];
  }) => {
    if (!username) {
      return;
    }

    const updatedPresets = getUpdatedUserFilterPresets({
      allPresets: allFilterPresets,
      ...props,
    });

    store.dispatch(updateUserFilterPresets(props.presetName, updatedPresets));
  };

  const activateUserFilterPreset = async ({
    presetName,
    presetKey,
  }: {
    presetName: string;
    presetKey: string | undefined;
  }) => {
    if (!username) {
      return;
    }

    // we access store directly because someone wrote this function to work synchronously to another state dispatches
    const state = store.getState();
    const currentPresetValues =
      state.auth.profile.data.casaoneUser?.filterPresets[presetName] || [];

    const newPresetsValues: UserFilterPresetValue[] = currentPresetValues.map(
      (item: any) => ({
        ...item,
        active: item.key === presetKey,
      })
    );
    const newPresets = { ...allFilterPresets, [presetName]: newPresetsValues };

    store.dispatch(updateUserFilterPresets(presetName, newPresetsValues));

    await updateUser({
      username,
      filterPresets: newPresets,
    }).unwrap();
  };

  return {
    persistUserFilterPresets,
    removeUserFilterPreset,
    updateUserFilterPreset,
    activateUserFilterPreset,
  };
};
