import ContactChooseCard from "components/contact/ContactChooseCard";
import { useDynamicContact } from "entities/contact/dynamicStore";

type ContactChooseCardProps = React.ComponentProps<typeof ContactChooseCard>;

interface EventContactChooseCardProps
  extends Pick<
    ContactChooseCardProps,
    "contactType" | "validationErrorMessages"
  > {
  contactId?: string;
  editingEventId?: string;
  headerTitle: string;
  chooserHeaderTitle: string;
  onChange: (newContactId: string | null) => void;
}

function EventContactChooseCard({
  contactId,
  editingEventId,
  headerTitle,
  chooserHeaderTitle,
  onChange,
  contactType,
  validationErrorMessages,
}: EventContactChooseCardProps) {
  const eventContactChooseStoreContext = `event-contact-choose-${
    editingEventId || "new-event"
  }`;

  const { store: dynamicContactsStore } = useDynamicContact({
    previousStateValidateContext: eventContactChooseStoreContext,
    getItem: contactId || undefined,
  });

  return (
    <ContactChooseCard
      noRemoveConfirm
      minHeight="6rem"
      isLoading={dynamicContactsStore.isFetchingItem}
      headerTitle={headerTitle}
      chooserHeaderTitle={chooserHeaderTitle}
      contact={
        contactId && dynamicContactsStore.item?.id === contactId
          ? dynamicContactsStore.item || undefined
          : undefined
      }
      storeValidateContext={eventContactChooseStoreContext}
      onChange={(newContactId) => {
        onChange(newContactId);
      }}
      contactType={contactType}
      validationErrorMessages={validationErrorMessages}
    />
  );
}

export default EventContactChooseCard;
