import { useTranslation } from "react-i18next";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import { TableListContainerProps } from "components/miscellaneous/TableListContainerProps";
import TableListContainer from "components/miscellaneous/TableListContainer";
import { useDynamicContact } from "entities/contact/dynamicStore";
import { TableList } from "@casasoft/styleguide/components/table-list";
import AddressEditForm from "../miscellaneous/address/AddressEditForm";
import ContactExcerpt from "./features/contact-excerpt/ContactExcerpt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/pro-light-svg-icons";
import useTableListBulkSelect, {
  UseTableListBulkSelectArgs,
} from "@casasoft/styleguide/hooks/useTableListBulkSelect";
import resourceGrabber from "utilities/resourceGrabber";
import { useEffect, useState } from "react";
import resourceHelper from "utilities/resource";
import { ContactShape } from "entities/contact/types";
import useConditionalEffect from "@casasoft/styleguide/hooks/useConditionalEffect";
import { AddressHelper } from "utilities";
import { FilterItem } from "utilities/queryBuilder";
import {
  filterOutUsersAndSubsidiaries,
  onlyIndividualsInludingUsers,
  onlyIndividualsWithoutUsers,
  onlyLegalWithoutSubsidiaries,
  onlyUsers,
} from "entities/contact/baseContactCreator";
import ContactEditForm from "./ContactEditForm";
import { DropdownProps } from "@casasoft/styleguide/components/dropdown";
import Button from "@casasoft/styleguide/components/forms/Button";
import ContactCreateForm from "./ContactCreateForm";
import { createGtagEvent } from "utilities/gtagHelper";
import handleFormModalError from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import { Item } from "@casasoft/styleguide/components/table-list/TableListItem";

interface ContactChooseModalProps {
  isOpen?: boolean;
  headerTitle?: string;
  onCancel?: () => void;
  onDone?: (id: string[]) => void;
  forcedFilter?: TableListContainerProps["forcedFilter"];
  multiSelect?: boolean;
  defaultSelected?: Item[];
  bulkActionNodes?: UseTableListBulkSelectArgs["bulkActionNodes"];
  isLoading?: boolean;
  validateContact?: (
    contact: ContactShape,
    selectedContactType?: string
  ) => string | boolean | void;
  storeValidateContext: string;
  contactType:
    | "individual-and-legal-no-users-no-subsidiaries"
    | "individual-only-no-users"
    | "legal-only-no-subsidiaries"
    | "individual-including-users"
    | "user-contacts-only";
}

function getContactTypeIdentifier(contact: ContactShape) {
  return `${contact.contactType}-${contact.entityType}`;
}

function ContactChooseModal({
  isOpen,
  headerTitle,
  onCancel,
  onDone,
  forcedFilter = [],
  multiSelect = false,
  defaultSelected,
  bulkActionNodes,
  isLoading,
  validateContact,
  storeValidateContext,
  contactType,
}: ContactChooseModalProps) {
  const { t } = useTranslation();
  const {
    store: dynamicContactsStore,
    fetchList: fetchDynamicContactsList,
    reloadList: reloadDynamicContactsList,
    updateItem: updateDynamicContactsItem,
  } = useDynamicContact({
    previousStateValidateContext: storeValidateContext,
  });

  const [addressFormOpen, setAddressFormOpen] = useState<
    false | { contactId: string } | { addressId: string }
  >(false); // false - closed, contactId - createform, addressId - editform(addressid)
  const [quickEditModalOpen, setQuickEditModalOpen] = useState<
    false | ContactShape
  >(false); // false - closed, string - active contactId
  const bulkSelectControl = useTableListBulkSelect({
    bulkActionNodes: bulkActionNodes,
    defaultSelected,
  });

  const [contactCreateFormOpen, setContactCreateFormOpen] = useState<boolean>();

  // update bulkSelected to defaultSelected when opened
  const [updateDefaultValues] = useConditionalEffect(() => {
    const firstDefaultSelectedItem = items.find(
      (contactItem) =>
        // this is expected as id is string || number
        // eslint-disable-next-line eqeqeq
        !!defaultSelected?.find((item) => item.id == contactItem.id)
    );
    if (firstDefaultSelectedItem) {
      setSelectedContactType(
        getContactTypeIdentifier(firstDefaultSelectedItem.contactRaw)
      );
    }
    bulkSelectControl.setItems(defaultSelected || []);
  });
  useEffect(() => {
    if (isOpen) {
      updateDefaultValues();
      createGtagEvent(
        "Open duplicate checker",
        "Duplicate checker",
        "Duplicate checker"
      );
    } else {
      setSelectedContactType(undefined);
    }
  }, [isOpen, updateDefaultValues]);

  const profile = resourceGrabber.grab("profile", "auth");
  const isCrm =
    profile?.data?.company?.id === "casasoft" &&
    profile?.data?.apiCompanySection === "casasoft";

  const cols = [
    {
      key: "excerpt",
      label: t("Contact"),
      baseWidth: "200px",
      minWidth: "150px",
      isSortable: true,
    },
    {
      key: "address",
      label: t("Address"),
      baseWidth: "160px",
      minWidth: "160px",
      placeholder: "line2x",
    },
    {
      key: "phones",
      label: t("Phone"),
      baseWidth: "100px",
      minWidth: "100px",
    },
    {
      key: "emails",
      label: "E-Mail",
      baseWidth: "100px",
      minWidth: "100px",
    },
  ];

  const [selectedContactType, setSelectedContactType] = useState<string>();

  const items = dynamicContactsStore.items.map((singleContact) => {
    let addressRender: string | JSX.Element | null = null;
    const addressId = singleContact._embedded?.address?.id;
    if (singleContact._embedded?.address) {
      addressRender = AddressHelper.renderAddress(
        singleContact._embedded.address
      );
    }

    const phones: React.ReactNode[] = [];
    if (singleContact.phone) {
      phones.push(<div key="phone">{singleContact.phone}</div>);
    }
    if (singleContact.mobile) {
      phones.push(<div key="mobile">{singleContact.mobile}</div>);
    }

    const emails: React.ReactNode[] = [];
    if (singleContact.email) {
      emails.push(<div key="email">{singleContact.email}</div>);
    }
    if (singleContact.websiteUrl) {
      emails.push(<div key="websiteUrl">{singleContact.websiteUrl}</div>);
    }
    const excerpt = <ContactExcerpt contact={singleContact} lines={3} />;

    const validateMsg = validateContact?.(singleContact, selectedContactType);

    return {
      item_key: singleContact.id,
      item_inactive: validateMsg || singleContact.status === "inactive",
      item_inactive_blocking: validateMsg ? "checkbox" : undefined, // block bulkselect through checkbox click
      id: singleContact.id,
      excerpt,
      addressId,
      address: addressRender,
      addressIsEmpty: AddressHelper.isEmpty(singleContact._embedded?.address),
      phones,
      emails,
      validateMsg,
      contactRaw: singleContact,
    };
  });

  const forcedFilterPresets: FilterItem[] = [];

  switch (contactType) {
    case "individual-and-legal-no-users-no-subsidiaries":
      forcedFilterPresets.push(...filterOutUsersAndSubsidiaries);
      break;
    case "individual-including-users":
      forcedFilterPresets.push(...onlyIndividualsInludingUsers);
      break;
    case "individual-only-no-users":
      forcedFilterPresets.push(...onlyIndividualsWithoutUsers);
      break;
    case "legal-only-no-subsidiaries":
      forcedFilterPresets.push(...onlyLegalWithoutSubsidiaries);
      break;
    case "user-contacts-only":
      forcedFilterPresets.push(...onlyUsers);
      break;
    default:
      throw new Error(`Invalid contactType provided: ${contactType}`);
  }

  // remove from bulkSelected if updated contact is invalid
  function unselectInvalidatedContact(contactToCheck: ContactShape) {
    if (
      bulkSelectControl.bulkSelected.includes(contactToCheck.id) && // was previously selected
      validateContact?.(contactToCheck, selectedContactType)
    ) {
      if (bulkSelectControl.bulkSelected.length === 1) {
        // only one was selected -> update rule
        setSelectedContactType(getContactTypeIdentifier(contactToCheck));
      } else {
        // unselect
        bulkSelectControl.toggleItem({
          ...contactToCheck,
          item_key: contactToCheck.id,
        });
      }
    }
  }

  // set contactType state when bulkSelect is set (first select) or removed (unselected all again)
  useEffect(() => {
    if (!bulkSelectControl.bulkSelected.length) {
      setSelectedContactType(undefined);
    } else if (bulkSelectControl.bulkSelected.length === 1) {
      const newlySelectedContact = dynamicContactsStore.items.find(
        (contactItem) => contactItem.id === bulkSelectControl.bulkSelected[0]
      );
      if (newlySelectedContact) {
        setSelectedContactType(getContactTypeIdentifier(newlySelectedContact));
      }
    }
  }, [bulkSelectControl.bulkSelected, dynamicContactsStore.items]);

  return (
    <>
      <FormModal
        header={headerTitle}
        isOpen={isOpen}
        size="lg"
        form={() => (
          <form className="spinner-fixture">
            <TableListContainer
              defaultOrder={[
                {
                  type: "field",
                  field: "created",
                  direction: "desc",
                },
              ]}
              searchOrder={[
                {
                  type: "customorderby",
                  field: "weight",
                  direction: "desc",
                  alias: "noAlias",
                },
              ]}
              forcedFilter={[
                ...forcedFilterPresets,
                ...forcedFilter,
                {
                  where: "and",
                  field: "referenceContact",
                  type: "isnull",
                },
              ]}
              userSortToQuerySort={(sortConf) => {
                let field = "";
                let alias = "";
                switch (sortConf.key) {
                  case "excerpt":
                    field = "name";
                    alias = "content";
                    break;
                  case "sizes":
                    field = "numberOfRooms";
                    break;
                  case "yearBuilt":
                    field = "yearBuilt";
                    break;
                  case "price":
                    field = "price";
                    break;
                  case "address":
                    field = "locality";
                    alias = "address";
                    break;
                  case "created":
                    return [
                      {
                        type: "field",
                        field: "created",
                        direction: sortConf.dir,
                      },
                    ];
                  default:
                    field = "name";
                    break;
                }
                return [
                  {
                    type: "customorderby",
                    field,
                    alias,
                    direction: sortConf.dir,
                  },
                ];
              }}
              fetchAction={fetchDynamicContactsList}
              actualStore={dynamicContactsStore}
              storeValidateContext={storeValidateContext}
              storeName="dynamicContacts"
              renderTableList={(tableListProps) => {
                return (
                  <TableList
                    {...tableListProps}
                    cardBody={false}
                    loading={isLoading || tableListProps.loading}
                    bulkSelectControl={
                      multiSelect ? bulkSelectControl : undefined
                    }
                    onBulkToggleAll={(hasHadSelectedItems) => {
                      if (!hasHadSelectedItems) {
                        const firstItemInListContact = items[0]?.contactRaw;
                        const firstItemInListType = firstItemInListContact
                          ? getContactTypeIdentifier(firstItemInListContact)
                          : undefined;
                        setSelectedContactType(firstItemInListType);

                        const allContactsWithFirstItemTypeIDs = items.filter(
                          (contactItem) =>
                            firstItemInListType ===
                            getContactTypeIdentifier(contactItem.contactRaw)
                        );

                        bulkSelectControl.setItems(
                          allContactsWithFirstItemTypeIDs
                        );
                        return false;
                      }
                    }}
                    mainActionNode={
                      contactType !== "user-contacts-only" ? (
                        <Button
                          onClick={(event) => {
                            setContactCreateFormOpen(true);
                            event.stopPropagation();
                            return true;
                          }}
                          style={{ flexGrow: 1, width: 1 }}
                          isPrimary
                        >
                          <FontAwesomeIcon icon={faPlus} className="tw-mr-1" />
                          {contactType === "legal-only-no-subsidiaries"
                            ? t("Create company")
                            : t("Create contact")}
                        </Button>
                      ) : undefined
                    }
                    items={items}
                    itemActionNodes={(item) => {
                      if (!isCrm) {
                        const itemActionNodes: DropdownProps["items"] = [];

                        // only show editform for non user-contacts and non subsidiaries
                        if (!item.contactRaw.contactType) {
                          itemActionNodes.push({
                            label: t("Edit contact"),
                            icon: faEdit,
                            onItemClick: () => {
                              setQuickEditModalOpen(item.contactRaw);
                            },
                          });
                        }
                        itemActionNodes.push({
                          label: item.addressIsEmpty
                            ? t("Create Address")
                            : t("Edit Address"),
                          icon: faEdit,
                          onItemClick: () => {
                            setAddressFormOpen(
                              item.addressId
                                ? { addressId: item.addressId }
                                : { contactId: item.id }
                            );
                          },
                        });
                        return itemActionNodes;
                      }
                    }}
                    cols={cols}
                    renderValue={(item, key) => {
                      return item[key];
                    }}
                    onItemClick={(item) => {
                      if (!item.validateMsg) {
                        if (multiSelect) {
                          bulkSelectControl.toggleItem(item);
                        } else {
                          onDone?.([item.id]);
                        }
                      }
                    }}
                  />
                );
              }}
            />
          </form>
        )}
        onCancel={onCancel}
      />
      <FormModal
        isOpen={!!addressFormOpen}
        onDone={() => {
          setAddressFormOpen(false);
        }}
        form={(props) => {
          if (!addressFormOpen) {
            return <></>; // impossible - just for correct type inference below
          }
          return (
            <AddressEditForm
              {...props}
              forEntity="contacts"
              loadDefaultValues={
                "addressId" in addressFormOpen
                  ? async () => {
                      const entity = await resourceHelper.getItem(
                        "address",
                        addressFormOpen.addressId
                      );
                      return entity;
                    }
                  : undefined
              }
              onSubmit={async (data) => {
                try {
                  if ("addressId" in addressFormOpen) {
                    await resourceHelper.updateItem("address", {
                      ...data,
                      id: addressFormOpen.addressId,
                    });
                  } else {
                    await resourceHelper.createItem("address", {
                      ...data,
                      contacts: [{ id: addressFormOpen.contactId }],
                    });
                  }
                  await reloadDynamicContactsList();
                  props.onDone();
                  return true;
                } catch (error) {
                  handleFormModalError(error, props.onFail);
                }
              }}
            />
          );
        }}
        header={t("Edit Address")}
        size="md"
        onCancel={() => {
          setAddressFormOpen(false);
        }}
      />
      <FormModal
        isOpen={!!quickEditModalOpen}
        onDone={() => {
          setQuickEditModalOpen(false);
          reloadDynamicContactsList();
        }}
        form={(props) => {
          if (!quickEditModalOpen) {
            return <></>; // impossible - just for proper type inference below
          }
          return (
            <ContactEditForm
              contact={quickEditModalOpen}
              onDone={props.onDone}
              onFail={props.onFail}
              onSubmit={async (data, dirtyData) => {
                const tagsMap = dirtyData?.tags
                  ?.split(",")
                  .map((tag) => ({ name: tag }));
                const updatedContact = await updateDynamicContactsItem(
                  quickEditModalOpen.id,
                  {
                    ...dirtyData,
                    tags: tagsMap || dirtyData?.tags,
                  },
                  "list"
                );

                if (updatedContact) {
                  unselectInvalidatedContact(updatedContact);
                }
              }}
            />
          );
        }}
        header={t("Edit contact")}
        size="lg"
        onCancel={() => {
          setQuickEditModalOpen(false);
        }}
      />
      <FormModal
        isOpen={contactCreateFormOpen}
        header={
          contactType === "legal-only-no-subsidiaries"
            ? t("Create company")
            : t("Create contact")
        }
        onDone={() => {
          setContactCreateFormOpen(false);
          reloadDynamicContactsList();
        }}
        size="md"
        form={(props) => (
          <ContactCreateForm
            onDone={props.onDone}
            onFail={props.onFail}
            preSelectedEntityType={
              contactType === "individual-including-users" ||
              contactType === "individual-only-no-users"
                ? "individual"
                : contactType === "legal-only-no-subsidiaries"
                ? "legal"
                : undefined
            }
          />
        )}
        onCancel={() => {
          setContactCreateFormOpen(false);
        }}
      />
    </>
  );
}

export default ContactChooseModal;
