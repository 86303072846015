import { Notification } from "@casasoft/styleguide/components/notification";
import { useTranslation } from "react-i18next";
import { HeightExpander } from "@casasoft/styleguide/components/expander/HeightExpander";

interface RecipientsNotificationProps {
  isVisible: boolean;
}

export const RecipientsNotification = ({
  isVisible,
}: RecipientsNotificationProps) => {
  const { t } = useTranslation();

  return (
    <HeightExpander isExpanded={isVisible}>
      <Notification
        text={t(
          "For data privacy reasons, all contacts listed in the “To:” field will receive an individual message with no indication about any others contact details",
          { keySeparator: ">", nsSeparator: "|" }
        )}
        colorType="info"
        cookieId="cs_message_recipients_info"
        className="tw-rounded tw-mb-2"
      />
    </HeightExpander>
  );
};
