import BaseField from "./BaseField";
import FormGroup from "../formElements/helpers/FormGroup";
import Input from "./Input";
import { Dropdown } from "@casasoft/styleguide/components/dropdown";

class PriceField extends BaseField {
  constructor(props, context) {
    super(props, context);
    const defaultOptions = this._getAllDefaultOptions();
    let cleanValue = "";
    let formattedValue = "";
    if (props.value) {
      cleanValue = this.__unformatInput(props.value);
      formattedValue = this.__formatInput(cleanValue);
    }
    this.state = {
      segments: {
        frequency: {
          expanded: false,
          setOption: defaultOptions.frequencyDefaultItem,
        },
        timeSegment: {
          expanded: false,
          setOption: defaultOptions.timeDefaultItem,
        },
        propertySegment: {
          expanded: false,
          setOption: defaultOptions.propertyDefaultItem,
        },
        combinedSegment: {
          expanded: false,
          setOption: defaultOptions.combinedDefaultItem,
        },
      },
      input: {
        value: formattedValue,
        cleanValue,
      },
      filled: props.nobox ? props.nobox : props.value !== "",
    };
  }

  _bind(...methods) {
    methods.forEach((method) => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    if (this.props.nobox) {
      this.setState({
        filled: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.state.input.value) {
      const cleanValue = this.__unformatInput(nextProps.value);
      const formattedValue = this.__formatInput(cleanValue);
      this.setState({
        input: {
          value: formattedValue,
          cleanValue,
        },
        filled: nextProps.nobox ? nextProps.nobox : nextProps.value !== "",
      });
    }

    if (
      JSON.stringify(nextProps.combinedSegments) !==
      JSON.stringify(this.props.combinedSegments)
    ) {
      const combinedDefaultItem = this._setDefaultOption(
        nextProps.combinedSegments
      );
      const frequencyDefaultItem = combinedDefaultItem.frequency;
      const timeDefaultItem = combinedDefaultItem.time;
      const propertyDefaultItem = combinedDefaultItem.property;
      this.setState({
        segments: {
          frequency: {
            expanded: false,
            setOption: frequencyDefaultItem,
          },
          timeSegment: {
            expanded: false,
            setOption: timeDefaultItem,
          },
          propertySegment: {
            expanded: false,
            setOption: propertyDefaultItem,
          },
          combinedSegment: {
            expanded: false,
            setOption: combinedDefaultItem,
          },
        },
      });
    }
  }

  _getAllDefaultOptions() {
    let frequencyDefaultItem = false;
    let timeDefaultItem = false;
    let propertyDefaultItem = false;
    let combinedDefaultItem = false;
    if (this.props.frequencies) {
      frequencyDefaultItem = this._setDefaultOption(this.props.frequencies);
    }
    if (this.props.timeSegments) {
      timeDefaultItem = this._setDefaultOption(this.props.timeSegments);
    }
    if (this.props.propertySegments) {
      propertyDefaultItem = this._setDefaultOption(this.props.propertySegments);
    }
    if (this.props.combinedSegments) {
      combinedDefaultItem = this._setDefaultOption(this.props.combinedSegments);
      frequencyDefaultItem = combinedDefaultItem.frequency;
      timeDefaultItem = combinedDefaultItem.time;
      propertyDefaultItem = combinedDefaultItem.property;
    }

    return {
      frequencyDefaultItem,
      timeDefaultItem,
      propertyDefaultItem,
      combinedDefaultItem,
    };
  }

  _setDefaultOption(options) {
    this.defaultItem = false;
    options.forEach((item) => {
      if (item.set) {
        this.defaultItem = item;
      }
    });

    if (!this.defaultItem && options) {
      this.defaultItem = options[0];
    }

    return this.defaultItem;
  }

  _handleDropdownItemClick(segment, item) {
    const newState = JSON.parse(JSON.stringify(this.state));
    if (segment !== "combinedSegment") {
      newState.segments[segment].setOption = item;
    } else {
      newState.segments[segment].setOption = item;
      newState.segments.timeSegment.setOption = item.time;
      newState.segments.propertySegment.setOption = item.property;
      newState.segments.frequency.setOption = item.frequency;
    }

    if (JSON.stringify(this.state) !== JSON.stringify(newState)) {
      this.props.onChange(newState);
    }
    this.setState(newState);
  }

  __formatInput(value) {
    let formattedValue = value;
    if (this.props.thousandSeparator) {
      formattedValue = this.__formatThousandSeparator(value);
    }
    return formattedValue;
  }

  __formatThousandSeparator(number) {
    return number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, this.props.thousandSeparator || "'");
  }

  __removeThousandSeparator(formattedValue) {
    if (formattedValue) {
      return formattedValue.toString().replace(/[']/g, "");
    }
    return formattedValue;
  }

  __unformatInput(formattedValue) {
    const cleanValue = this.__removeThousandSeparator(formattedValue);
    return cleanValue;
  }

  _handleInputChange(value) {
    const filteredValue = value.replace(/[^\d]/g, "");
    const cleanValue = this.__unformatInput(filteredValue);
    const formattedValue = this.__formatInput(cleanValue);
    const newState = Object.assign({}, this.state);
    newState.input.value = formattedValue;
    newState.input.cleanValue = cleanValue;
    newState.curVal = cleanValue;
    newState.filled = cleanValue !== "";
    if (JSON.stringify(this.state) !== JSON.stringify(newState)) {
      this.__handleChange(newState);
    }
    this.setState(newState);
  }

  __handleChange(state) {
    const priceObject = {
      input: {
        value: state.input.cleanValue,
      },
      segments: state.segments,
    };
    this.props.onChange(priceObject);
  }

  __handleBlur(state) {
    const priceObject = {
      input: {
        value: state.input.cleanValue,
      },
      segments: state.segments,
    };
    this.props.onBlur(priceObject);
  }

  __handleInputBlur(value) {
    if (this.props.onBlur) {
      const filteredValue = value.replace(/[^\d]/g, "");
      const cleanValue = this.__unformatInput(filteredValue);
      const formattedValue = this.__formatInput(cleanValue);
      const newState = Object.assign({}, this.state);
      newState.input.value = formattedValue;
      newState.input.cleanValue = cleanValue;
      newState.curVal = cleanValue;
      newState.filled = cleanValue !== "";
      this.__handleBlur(newState);
      if (JSON.stringify(this.state) !== JSON.stringify(newState)) {
        this.setState(newState);
      }
    }
  }

  render() {
    const sharedProps = this._getSharedProps();
    const formProps = {
      // type: 'currency',
      type: "text",
      pattern: this.props.pattern,
      min: this.props.min,
      max: this.props.max,
      step: this.props.step,
      value: this.state.input.value,
      onChange: (event) => {
        this._handleInputChange(event.target.value);
      },
      onBlur: (event) => {
        this.__handleInputBlur(event.target.value);
      },
    };

    // nobox special
    if (this.props.nobox) {
      sharedProps.filled = true;
    }

    const inputProps = Object.assign({}, sharedProps, formProps);

    const combinedSegments = this.props.combinedSegments?.map((item) => ({
      label: item.label,
      onItemClick: () => {
        this._handleDropdownItemClick("combinedSegment", item);
      },
    }));

    return (
      // to be done - implemententation with ButtonGroup
      <FormGroup {...sharedProps}>
        <div
          className={`cs-input-group ${
            this.state.segments.frequency.expanded ||
            this.state.segments.timeSegment.expanded ||
            this.state.segments.propertySegment.expanded
              ? "show"
              : ""
          }`}
        >
          {this.props.prefix ? (
            <span className="cs-input-group-addon">{this.props.prefix}</span>
          ) : (
            ""
          )}
          <Input {...inputProps} className="cs-form-control" />
          {this.props.suffix ? (
            <span className="cs-input-group-addon">{this.props.suffix}</span>
          ) : (
            ""
          )}
          {!!this.props.combinedSegments.length && (
            <div className="cs-input-group-btn">
              <Dropdown
                toggleBtnProps={{ isSecondary: true }}
                items={combinedSegments}
                open={this.state.segments.combinedSegment.expanded}
                onToggle={(newStateBool) => {
                  const newState = Object.assign({}, this.state);
                  Object.keys(newState.segments).forEach((key) => {
                    if (newState.segments[key].expanded) {
                      newState.segments[key].expanded = false;
                    } else if ("combinedSegment" === key) {
                      newState.segments[key].expanded = newStateBool;
                    }
                  });
                  this.setState(newState);
                }}
                label={
                  this.state.segments.combinedSegment.setOption
                    ? this.state.segments.combinedSegment.setOption.label
                    : undefined
                }
              />
            </div>
          )}
        </div>
      </FormGroup>
    );
  }
}

export default PriceField;
