import { MutateUserSingleQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<MutateUserSingleQueryParams>((body) => {
  return {
    url: `v2/user/${body.username}`,
    method: "PATCH",
    data: body,
  };
});

export default query;
