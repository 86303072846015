export const parseErrorMessage = (
  messages: string[] | string | object
): string[] => {
  if (typeof messages === "string") {
    return [messages];
  } else if (Array.isArray(messages)) {
    return messages;
  } else if (typeof messages === "object") {
    // return Object.values(messages);
    return Object.entries(messages).map(([field, innerMessages]) => {
      return `${field}: ${innerMessages}`;
    });
  }
  return ["Unknown error"];
};
