import { Item, TableListItemProps } from "./TableListItem";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { TableListBulkCheckbox } from "./TableListBulkCheckbox";
import { Dropdown } from "@casasoft/styleguide/components/dropdown";
import { DragHandle } from "./TableListItemHelpers";
import { twJoin } from "tailwind-merge";
import { TableListColumnWrapper } from "./TableListColumnWrapper";
import { TableListColumnsData } from "./TableListColumnsData";

export interface TableListItemColProps {
  key: string;
  align?: string;
  cardFlex?: string;
  width?: number | string;
  baseWidth?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  label?: string;
  isSortable?: boolean;
  placeholder?: string;
  flex?: string;
}

interface TableListItemColsProps
  extends Pick<
    TableListItemProps,
    | "item"
    | "cols"
    | "bulkSelectControl"
    | "itemActionNodes"
    | "renderValue"
    | "sortable"
    | "mini"
    | "itemIndex"
    | "cardView"
  > {
  itemHref?: string;
  onItemClick?: (item: Item) => void;
  itemClassNames?: string;
  itemPathName?: string;
}

function TableListItemCols({
  item,
  cols = [],
  bulkSelectControl,
  itemActionNodes,
  renderValue = (singleItem, key) => {
    return singleItem[key];
  },
  sortable,
  mini,
  itemIndex,
  cardView,
  itemHref,
  itemPathName,
  onItemClick,
  itemClassNames,
}: TableListItemColsProps) {
  const itemActionsList =
    itemActionNodes && !item.isFiller && !sortable
      ? itemActionNodes(item) || []
      : [];

  return (
    <div className={itemClassNames}>
      {!!bulkSelectControl && (
        <TableListBulkCheckbox
          item={item}
          checked={bulkSelectControl.bulkSelected.includes(item.id)}
          onChange={(itemChanged) => {
            bulkSelectControl.toggleItem(itemChanged);
          }}
          disabled={item.item_inactive_blocking === "checkbox"}
        />
      )}
      <TableListColumnWrapper
        onItemClick={onItemClick}
        itemClassNames={itemClassNames}
        itemHref={itemHref}
        itemPathName={itemPathName}
        item={item}
      >
        <>
          <TableListColumnsData
            item={item}
            cols={cols}
            cardView={cardView}
            renderValue={renderValue}
            sortable={sortable}
          />
          {sortable && (
            <DragHandle
              key={item.item_key}
              nr={itemIndex}
              cardView={cardView || false}
            />
          )}
        </>
      </TableListColumnWrapper>

      {!mini && !!itemActionsList.length && (
        <div
          key="actions"
          className={twJoin(
            "cs-tablelist-itemaction-btn-tw-helper tw-text-cs-shade-500 tw-flex tw-absolute tw-right-0 group-hover:tw-from-cs-shade-50 group-hover:tw-via-cs-shade-50",
            !cardView && "tw-bg-gradient-to-l",
            item.item_active
              ? "tw-from-cs-shade-50 tw-via-cs-shade-50"
              : "tw-from-cs-shade-white tw-via-cs-shade-white"
          )}
        >
          <Dropdown
            toggleBtnProps={{
              customStyle:
                "tw-text-inherit tw-p-3 tw-w-full tw-flex  tw-justify-center",
            }}
            items={itemActionsList}
            openOriginPreference="topright"
            icon={faCog}
          />
        </div>
      )}
    </div>
  );
}

export default TableListItemCols;
