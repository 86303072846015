import * as Sentry from "@sentry/browser";
import axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import interpretRequestURLMetaData from "./interpretRequestURLMetaData";

export function startTimeRequestInterceptor(
  req: InternalAxiosRequestConfig<any>
) {
  (req as any).requestStartDate = Date.now();
  return req;
}

export function sentryWarningsResponseInterceptor(
  res: AxiosResponse<any, any>
) {
  const MAX_TIME = 5000; // in ms
  const MAX_LENGTH = 5000000; // in bytes
  const requestStart: number = (res.config as any).requestStartDate;

  // necessary check, will result in an error otherwise -> Failed to read the 'responseText' property from 'XMLHttpRequest': The value is only accessible if the object's 'responseType' is '' or 'text' (was 'xxxxx').
  const responseText: string = ["", "text"].includes(res.request?.responseType)
    ? res.request.responseText || ""
    : "";

  const responseSize = new TextEncoder().encode(responseText).length;
  const responseTime = requestStart ? Date.now() - requestStart : -1;

  const url: string = res.request?.responseURL || "unknown-url";
  const urlToAPIInterpretation = interpretRequestURLMetaData(url);

  if (requestStart) {
    if (responseTime > MAX_TIME) {
      Sentry.captureMessage(
        `ApiNotice(slow request): ${urlToAPIInterpretation}`,
        {
          level: "warning",
          extra: {
            status: res.status,
            responseURL: res.request?.responseURL,
            time: responseTime,
            size: responseSize,
          },
        }
      );
    }
  }

  if (responseSize > MAX_LENGTH) {
    Sentry.captureMessage(
      `ApiNotice(large request): ${urlToAPIInterpretation}`,
      {
        level: "warning",
        extra: {
          status: res.status,
          responseURL: res.request.responseURL,
          time: responseTime,
          size: responseSize,
        },
      }
    );
  }
  return res;
}

export function interceptErrorToSentry(error: any) {
  console.error(error);
  if (axios.isAxiosError(error)) {
    const urlToAPIInterpretation = interpretRequestURLMetaData(
      error.config?.url || ""
    );

    Sentry.captureMessage(`ApiError: ${urlToAPIInterpretation}`, {
      level: "error",
      extra: {
        fullUrl: error.config?.url,
      },
    });
  } else {
    Sentry.captureException(error);
  }
  return Promise.reject(error);
}
