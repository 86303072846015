import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getThemeSettings, {
  getActiveTheme,
} from "../utilities/theming/getThemeSettings";

function useThemeSettings() {
  const { t } = useTranslation();

  useEffect(() => {
    function csThemeUpdatedListener() {
      setThemeSettings(getThemeSettings());
      setActiveTheme(getActiveTheme());
    }
    document.body.addEventListener("cs-theme-updated", csThemeUpdatedListener);

    return () => {
      document.body.removeEventListener(
        "cs-theme-updated",
        csThemeUpdatedListener
      );
    };
  }, []);

  const [themeSettings, setThemeSettings] = useState(getThemeSettings());
  const [activeTheme, setActiveTheme] = useState(getActiveTheme());

  let activeThemeLabel = "";
  switch (activeTheme) {
    // case "dark":
    //   activeThemeLabel = t("Dark theme");
    //   break;
    // case "auto":
    //   activeThemeLabel = t("Auto theme");
    //   break;
    case "black":
      activeThemeLabel = t("Black");
      break;
    case "blue":
      activeThemeLabel = t("Blue");
      break;
    case "custom":
      activeThemeLabel = t("Custom");
      break;
    case "default":
      activeThemeLabel = t("Standard");
      break;
    default:
      throw new Error("An error occured");
  }

  return { themeSettings, activeTheme, activeThemeLabel };
}

export default useThemeSettings;
