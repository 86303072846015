import { useAppSelector } from "redux/hooks";
import { selectCasaoneUser } from "redux/auth/selectors";
import { SubmitHandler } from "react-hook-form";
import {
  AddReminderForm,
  type AddRemainderFormShape,
} from "./AddReminderForm/AddReminderForm";
import { useTranslation } from "react-i18next";
import { FormModal } from "@casasoft/styleguide/components/form-modal";

interface AddReminderContainerProps {
  isModalOpen?: boolean;
  defaultData: AddRemainderFormShape | undefined;
  onClose?: () => void;
  onSubmit?: SubmitHandler<AddRemainderFormShape>;
  messageSubject?: string;
}

export const AddReminderContainer = ({
  isModalOpen,
  defaultData,
  onClose,
  onSubmit,
  messageSubject,
}: AddReminderContainerProps) => {
  const { t } = useTranslation();
  const currentUserId = useAppSelector(selectCasaoneUser)?.contact?.id;

  const handleSubmit = (result: AddRemainderFormShape) => {
    if (onSubmit) {
      onSubmit(result);
    }
  };

  const handleClear = () => {
    if (onSubmit) {
      // We cannot submit undefined as reminder form to unset it, so we consider empty object as undefined later on
      onSubmit({});
    }
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <FormModal
      header={t("Add reminder")}
      isOpen={isModalOpen}
      onCancel={handleClose}
      onDone={handleSubmit}
      form={() => (
        <AddReminderForm
          onSubmit={handleSubmit}
          onClear={handleClear}
          currentUserId={currentUserId}
          data={defaultData}
          messageSubject={messageSubject}
        />
      )}
      size="lg"
    />
  );
};
