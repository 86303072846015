import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "planned",
    label: "Planned",
  },
  {
    value: "under-construction",
    label: "Under construction",
  },
  {
    value: "ready",
    label: "Ready",
  },
  {
    value: "completed",
    label: "Completed",
  },
];

export const getPropertyConstructionStatus = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getPropertyConstructionLabel = createGetLabel(untranslatedList);

export const usePropertyConstructionStatuses = createUtilityHook(
  untranslatedList,
  {
    sort: "a-z",
  }
);
