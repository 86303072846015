import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Apprenticeship matura",
    value: "apprenticeship-matura",
  },
  {
    label: "Continuing education",
    value: "continuing-education",
  },
  {
    label: "University college",
    value: "university-college",
  },
];

export const getEducations = createGetList(untranslatedList);
export const getLabel = createGetLabel(untranslatedList);

export const useEducations = createUtilityHook(untranslatedList);
