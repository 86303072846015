import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageGroupData } from "../../LanguageBreakdownContainer";
import CheckboxElem from "@casasoft/styleguide/components/formElements/helpers/CheckboxElem";
import { Fragment } from "react";
import type { LanguageCheckboxStatus } from "../../helpers/languageCheckboxHelper";
import { MessagingSelectLoading } from "components/miscellaneous/MessagingSelectLoading";

export interface LanguageBreakdownContentBodyProps {
  languageGroups: LanguageGroupData[];
  languageCheckboxStatus: LanguageCheckboxStatus;
  className?: string;
  onInfoClick: (language: string) => void;
  isLoading?: boolean;
}

export const LanguageBreakdownContentBody = ({
  languageGroups,
  languageCheckboxStatus,
  onInfoClick,
  className,
  isLoading = false,
}: LanguageBreakdownContentBodyProps) => {
  const { languageChecked, handleLanguageCheck } = languageCheckboxStatus;
  return (
    <div className={className}>
      {isLoading ? (
        <MessagingSelectLoading />
      ) : (
        languageGroups.map((group) => (
          <Fragment key={group.key}>
            <div>
              <CheckboxElem
                onClick={() => handleLanguageCheck(group.key)}
                value={languageChecked[group.key] ? "checked" : "unchecked"}
                checked={true}
              />
            </div>
            <div className="tw-font-thin">{group.label}</div>
            <div className="tw-font-thin">{group.contacts.length}</div>
            <div className="tw-text-sm">
              <FontAwesomeIcon
                className="tw-text-tw-cs-accent-color"
                icon={faInfoCircle}
                size={"lg"}
                onClick={() => onInfoClick(group.key)}
                cursor={"pointer"}
              />
            </div>
          </Fragment>
        ))
      )}
    </div>
  );
};
