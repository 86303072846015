import { MutatePropertySingleQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<MutatePropertySingleQueryParams>((body) => {
  return {
    url: `property/${body.id}`,
    method: "PATCH",
    data: body,
  };
});

export default query;
