import {
  GetPropertyTagListQueryParams,
  GetPropertyTagListResponse,
} from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetPropertyTagListResponse,
  GetPropertyTagListQueryParams
>((result) => {
  if (result) {
    // generate tags for each propertyTag
    const tagTags = result.propertyTags.map((item) => ({
      type: "PropertyTag" as const,
      id: item.name,
    }));

    // merge tag tags with static tags
    return [
      ...tagTags,
      {
        type: "PropertyTag",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["PropertyTag"];
});

export default providesTags;
