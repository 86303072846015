import { useCallback } from "react";
import { useTranslation } from "react-i18next";

function useOnboardingTranslations() {
  const { t } = useTranslation();

  const onboardingT = useCallback(
    (term: string) => {
      const translated = t(term, { ns: "onboarding" });
      return translated.split("\n").join("<br/>");
    },
    [t]
  );

  return { t: onboardingT };
}

export default useOnboardingTranslations;
