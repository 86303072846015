import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { timeFormatRule } from "./form-rules/timeFormatRule";
import { requiredRule } from "./form-rules/requiredRule";

export type FormRule = Omit<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>;

type AvailableGeneralRules = "required" | "timeFormat";

export interface useRulesResponse {
  generateRules: (includedRules: AvailableGeneralRules[]) => FormRule;
}

export const useRules = (): useRulesResponse => {
  const { t } = useTranslation();

  const defaultRules: Record<AvailableGeneralRules, FormRule> = {
    required: requiredRule(t("Value is required and can't be empty") as string),
    timeFormat: timeFormatRule(
      t("Please input according to the requested format") as string
    ),
  };

  const generateRules = (includedRules: AvailableGeneralRules[]): FormRule => {
    return includedRules.reduce<FormRule>((result, rule) => {
      result = { ...result, ...defaultRules[rule] };
      return result;
    }, {});
  };

  return { generateRules };
};
