import Select, {
  CleanSelectOption,
} from "@casasoft/styleguide/components/formElements/Select";
import PhaseOptionsExcerpt from "components/miscellaneous/pipeline/PhaseOptionsExcerpt";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { PhaseListShape } from "api/entities/phase/endpoints/list/types";
import { useMemo } from "react";

export interface SelectPhaseOption extends CleanSelectOption {
  value: string;
  label: string;
  color?: string;
  rank: number;
}

interface SelectPhaseProps {
  value: string | undefined;
  onChange: (newValue: string | undefined) => void;
  options: SelectPhaseOption[];
  isHidden?: boolean;
  latestPhase: PhaseListShape | undefined;
}

export const SelectPhase = ({
  value,
  onChange,
  options,
  latestPhase,
  isHidden = true,
}: SelectPhaseProps) => {
  const { t } = useTranslation();

  const className = cx("tw-basis-1/2", { "tw-invisible": isHidden });
  const selectedPhase = useMemo(
    () => value || latestPhase?.id,
    [value, latestPhase]
  );

  return (
    <Select
      nobox
      label={t("Event phase")}
      placeholder={t("Choose a phase")}
      disabled={isHidden}
      value={selectedPhase}
      options={options}
      onChange={onChange}
      renderValue={(val) => {
        return selectedPhase ? (
          <PhaseOptionsExcerpt
            value={val.value}
            label={val.label}
            color={val.color}
            rank={val.rank}
            previousPhase={latestPhase}
          />
        ) : (
          <></>
        );
      }}
      renderOption={(val) => {
        return (
          <PhaseOptionsExcerpt
            value={val.value}
            label={val.label}
            color={val.color}
            rank={val.rank}
            size="lg"
          />
        );
      }}
      className={className}
    />
  );
};
