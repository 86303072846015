import { getAuthTokens } from "utilities/auth";
import axiosInstance from "./axiosInstance";

function updateAxiosInstanceToken() {
  const activeTokens = getAuthTokens();
  // for example for /public links the token might not be available
  if (activeTokens.accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${activeTokens.accessToken}`;
  }
}

// run once - set default token
updateAxiosInstanceToken();

export default updateAxiosInstanceToken;
