import FormGroup from "@casasoft/styleguide/components/formElements/helpers/FormGroup";
import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { useTranslation } from "react-i18next";

interface MessageBodyPreviewProps {
  previewBody: string;
  previewLoading: boolean;
}

export const MessageBodyPreview = ({
  previewBody,
  previewLoading,
}: MessageBodyPreviewProps) => {
  const { t } = useTranslation();

  return (
    <div className="tw-relative">
      <FormGroup required filled nobox label={t("Message")}>
        <div
          dangerouslySetInnerHTML={{ __html: previewBody }}
          className="mce-content-body"
          style={{
            position: "relative",
            opacity: previewLoading ? 0.5 : undefined,
            minHeight: previewLoading ? 150 : undefined,
            borderBottom: "1px solid rgb(var( --cs-shade-500))",
            paddingTop: "0.4em",
          }}
        />
      </FormGroup>
      {previewLoading && (
        <div
          className="spinner-fixture"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};
