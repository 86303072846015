import * as Dialog from "@radix-ui/react-dialog";
import { ReactNode } from "react";
import { Spinner } from "../helpers-ux";

interface ModalLoaderProps extends Dialog.DialogProps {
  children: ReactNode;
}

function ModalLoader({ children, ...dialogProps }: ModalLoaderProps) {
  return (
    <Dialog.Root {...dialogProps}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="tw-cs-modal-loader-overlay tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-grid tw-place-items-center tw-z-30 tw-overflow-y-auto tw-bg-black/50"
          onSubmit={(e) => {
            // required to prevent nested modals from firing the parents onSubmit events -> CASAONE-6122
            e.stopPropagation();
          }}
        >
          <Dialog.Content className="spinner-fixture">
            <Dialog.Description className="tw-text-cs-shade-white">
              {children}
            </Dialog.Description>
            <br />
            <br />
            <br />
            <br />
            <Spinner />
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default ModalLoader;
