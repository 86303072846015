import PriceField from "@casasoft/styleguide/components/forms/PriceField";
import { useTranslation } from "react-i18next";

interface PropertyPriceFieldProps {
  price?: string | number | null;
  currency?: string | null;
  pricePropertySegment?: string | null;
  onMultiFieldChange?: (data: { name: string; value: string }[]) => void;
  message?: any;
  id?: string;
  nobox?: boolean;
}

const PropertyPriceField = ({
  price,
  currency = "CHF",
  pricePropertySegment = "",
  onMultiFieldChange,
  message = [],
  id = "price",
  nobox = true,
}: PropertyPriceFieldProps) => {
  const { t } = useTranslation();

  const getCombinedSegments = (propertySegment: string | null) => {
    return [
      {
        label: ` ${t("standards:Price")} `,
        id: "price",
        time: {
          value: "infinite",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "year",
        set: propertySegment === "all",
      },
      {
        label: ` ${t("standards:Price per sqm")}`,
        id: "price_m2",
        time: {
          value: "infinite",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "m2",
        },
        value: "price_m2",
        set: propertySegment === "m",
      },
    ];
  };

  return (
    <PriceField
      nobox={nobox}
      id={id}
      prefix={currency}
      label={t("standards:Price")}
      combinedSegments={getCombinedSegments(pricePropertySegment)}
      name={id}
      value={price}
      placeholder={t("standards:On Request")}
      onChange={(value: any) => {
        const changeSet = [];
        if (price !== value.input.value.replace("'", "")) {
          changeSet.push({
            name: "price",
            value: value.input.value,
          });
        }
        if (
          value &&
          value.segments &&
          value.segments.combinedSegment &&
          value.segments.combinedSegment.setOption &&
          value.segments.combinedSegment.setOption.property &&
          value.segments.combinedSegment.setOption.property.value
        ) {
          changeSet.push({
            name: "pricePropertySegment",
            value:
              value.segments.combinedSegment.setOption.property.value.replace(
                /[0-9]/g,
                ""
              ),
          });
        }
        if (changeSet.length) onMultiFieldChange?.(changeSet);
      }}
      thousandSeparator="'"
      message={message}
    />
  );
};

export default PropertyPriceField;
