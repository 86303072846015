import { FormModal } from "@casasoft/styleguide/components/form-modal";
import { useTranslation } from "react-i18next";
import { MessageMediaFormFooter } from "./MessageMediaFormFooter";
import { type MessageAttachment } from "../create-message/MessageMiscControls/MessageAttachFilesControl";
import { useMessageMedia } from "./helpers/useMessageMedia";
import MediaChooserContainer from "components/media/MediaChooserContainer";
import { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { MediaChooserPropertyAttachmentsProps } from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/MediaChooserPropertyAttachments";
import { PropertyAttachment } from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/helpers/propertyAttachment.model";

interface MessageMediaContainerForm {
  attachments: MessageAttachment[];
  selectedProperty?: PropertyListReducedShape;
  isModalOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (result: any) => void;
  propertyAttachments: PropertyAttachment[];
  onSubmitPropertyAttachments: (attachments: PropertyAttachment[]) => void;
}

export const MessageMediaContainer = ({
  attachments,
  isModalOpen,
  onClose,
  onSubmit,
  selectedProperty,
  propertyAttachments,
  onSubmitPropertyAttachments,
}: MessageMediaContainerForm) => {
  const { handleMediaChange, handleMediaSubmit, handlePropertyMediaChange } =
    useMessageMedia({
      attachments,
      onSubmit,
      propertyAttachments,
      onSubmitPropertyAttachments,
    });
  const { t } = useTranslation();

  const propertyTabProps: MediaChooserPropertyAttachmentsProps = {
    selectedProperty,
    initiallySelected: propertyAttachments,
    onSelectionChange: handlePropertyMediaChange,
  };

  return (
    <FormModal
      isOpen={isModalOpen}
      header={t("Media library")}
      size="lg"
      form={() => (
        <>
          <MediaChooserContainer
            activeTab={
              !!propertyTabProps.selectedProperty ? "property" : "list"
            }
            onSelectionChange={handleMediaChange}
            initialSelectedItems={attachments.map(
              (attachment) => attachment.media
            )}
            propertyTabProps={propertyTabProps}
          />
          <MessageMediaFormFooter onAddButtonClick={handleMediaSubmit} />
        </>
      )}
      onDone={handleMediaSubmit}
      onCancel={onClose}
    />
  );
};
