import { BoardingExitReason } from "boarding.js";
import HighlightElement from "boarding.js/dist/core/highlight-element";

type BoardingResetEventDetails = {
  id: string;
  element: HighlightElement;
  exitReason: BoardingExitReason;
};
export type BoardingResetEvent = CustomEvent<BoardingResetEventDetails>;

/**
 * Dispatch the BoardingResetEvent custom event
 */
export function dispatchBoardingResetEvent(
  uniqueId: string,
  element: HighlightElement,
  exitReason: BoardingExitReason
) {
  const startEvent = new CustomEvent<BoardingResetEventDetails>(
    "BoardingResetEvent",
    {
      detail: { id: uniqueId, element, exitReason },
    }
  );
  document.dispatchEvent(startEvent);
}
