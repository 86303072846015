import { sendEvent } from "../sendEvent";

export const HELPCENTER_NAVBAR_OPEN = "helpcenter_navbar_open";
export const HELPCENTER_DROPDOWN_VISIT_HELPCENTER =
  "helpcenter_dropdown_visit_helpcenter";
export const HELPCENTER_DROPDOWN_SUBMIT_TICKET =
  "helpcenter_dropdown_submit_ticket";
export const HELPCENTER_DROPDOWN_VIRTUAL_ASSISTANT =
  "helpcenter_dropdown_virtual_assistant";
export const HELPCENTER_SLIDER_SUBMIT_TICKET =
  "helpcenter_slider_submit_ticket";

export const MessagingEventLabels = {
  [HELPCENTER_NAVBAR_OPEN]: "Open helpcenter navbar dropdown",
  [HELPCENTER_DROPDOWN_VISIT_HELPCENTER]: "Visit helpcenter sidebar",
  [HELPCENTER_DROPDOWN_SUBMIT_TICKET]:
    "Submit ticket from helpcenter navbar dropdown",
  [HELPCENTER_DROPDOWN_VIRTUAL_ASSISTANT]:
    "Ask virtual assistant from helpcenter navbar dropdown",
  [HELPCENTER_SLIDER_SUBMIT_TICKET]: "Submit ticket from helpcenter slider",
};

export const sendHelpcenterEvent = (
  eventName: keyof typeof MessagingEventLabels
) => {
  sendEvent({
    eventName,
    eventCategory: "Helpcenter",
    eventLabel: MessagingEventLabels[eventName],
  });
};
