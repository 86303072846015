import { Boarding, BoardingOptions } from "boarding.js";
import { dispatchBoardingResetEvent } from "./events/boardingReset";
import { dispatchBoardingStartEvent } from "./events/boardingStart";

/**
 * Initialize a boarding instance with our opinionated config
 * @param uniqueId unqiue tour id
 * @param t translator method
 * @param boardingOptions boarding config
 * @returns boarding instance
 */
function initBoardingInstance(
  uniqueId: string,
  t: (term: string) => string,
  boardingOptions?: Partial<BoardingOptions>
) {
  const boardingInstance = new Boarding({
    opacity:
      typeof boardingOptions?.opacity === "number"
        ? boardingOptions.opacity
        : 0.4,
    className: "cs-boarding-popover",
    closeBtnText: t("onboarding.btn.close"),
    doneBtnText: t("onboarding.btn.done"),
    nextBtnText: t("onboarding.btn.next"),
    prevBtnText: t("onboarding.btn.previous"),
    // strictClickHandling: "block-all",
    onStart: (element) => {
      boardingOptions?.onStart?.(element);

      dispatchBoardingStartEvent(uniqueId, element);
    },
    onReset: (element, exitReason) => {
      boardingOptions?.onReset?.(element, exitReason);

      dispatchBoardingResetEvent(uniqueId, element, exitReason);
    },
    onPopoverRender: (popoverElements) => {
      setTimeout(() => {
        const stepCounterWrapperElement = document.createElement("div");
        const stepCounterElement = document.createElement("div");
        stepCounterWrapperElement.appendChild(stepCounterElement);

        stepCounterWrapperElement.style.position = "relative";
        stepCounterWrapperElement.style.margin = "auto -0.75rem";

        if (boardingInstance.currentStep !== 0) {
          stepCounterElement.style.width = `${
            (100 / (boardingInstance.getSteps().length - 1)) *
            boardingInstance.currentStep
          }%`;
        } else {
          stepCounterElement.style.width = "0";
        }

        stepCounterElement.style.height = "1px";
        stepCounterWrapperElement.style.marginTop = "-1px";
        stepCounterElement.style.background = "rgb(var(--tw-cs-accent-color))";

        popoverElements.popoverWrapper.insertBefore(
          stepCounterWrapperElement,
          popoverElements.popoverFooter
        );
      }, 0);
    },
    ...boardingOptions,
  });

  return boardingInstance;
}

export default initBoardingInstance;
