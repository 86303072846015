import { TableListBulkSelectControl } from "../../hooks/useTableListBulkSelect";
import { DropdownProps } from "../dropdown";
import TableListItemCols, { TableListItemColProps } from "./TableListItemCols";
import {
  getItemClassName,
  SortableItem,
  TableListTooltipWrapper,
} from "./TableListItemHelpers";
import { twMerge } from "tailwind-merge";

export interface Item {
  item_key: string;
  item_active?: boolean;
  item_inactive?: boolean | string;
  item_inactive_blocking?: string;
  isFiller?: boolean;
  onClick?: (item: Item) => void;

  [key: string]: any;
}

/**
 * Table List item
 */
export interface TableListItemProps {
  /** Columns configuration. Flex, width, label, etc. */
  cols?: TableListItemColProps[];
  /** Renders a NavLink component based to URL return from itemPath */
  itemPath?: (item: Item) => string | undefined;
  /** Alternative to "itemPath". Renders <a> tag based to URL return from itemLink */
  itemLink?: (item: Item) => string;
  /** Alternative to "itemPath" and "itemLink". Custom click handler for Item */
  onItemClick?: (item: Item) => void;
  /**
   * Renders each Item's quick actions.
   * Requires the "item-actions" component to be enabled.
   * */
  itemActionNodes?: (item: Item) => DropdownProps["items"] | undefined;
  /** Renders each Item's value, but ONLY for when cardView is set to TRUE */
  renderValue?: (item: any, key: string) => any[];

  /** Alternate display mode. Cards instead of plane list. */
  cardView?: boolean;
  /** Whether a <SortableList /> wrapper should be rendered for the TableList to be drag-and-drop sortable. */
  sortable?: boolean;
  /** Control object returned by the TableListBulkSelect hook. If this prop is provided, TableList will render BulkSelect */
  bulkSelectControl?: TableListBulkSelectControl;
  /**
   * ..
   * ..
   * ..
   * ..
   */
  itemIndex: number | null;
  item: Item;
  mini: boolean;
}

const TableListItem = ({
  cols,
  onItemClick,
  itemPath = (singleItem) => {
    return "";
  },
  itemLink,
  itemActionNodes,
  renderValue,
  cardView = false,
  sortable = false,
  bulkSelectControl,
  itemIndex,
  item,
  mini,
}: TableListItemProps) => {
  const itemClassNames = getItemClassName(item, cardView, mini);

  const tableListItemColsProps = {
    item,
    cols,
    bulkSelectControl,
    itemActionNodes,
    renderValue,
    sortable,
    mini,
    itemIndex,
    cardView,
  };

  if (item.isFiller) {
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <TableListItemCols
          {...tableListItemColsProps}
          itemClassNames={itemClassNames}
        />
      </TableListTooltipWrapper>
    );
  }
  // case when the data items are interactive
  if (
    (item.onClick || onItemClick || itemLink?.(item) || itemPath?.(item)) &&
    !sortable
  ) {
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <TableListItemCols
          {...tableListItemColsProps}
          onItemClick={onItemClick || item.onClick}
          itemClassNames={itemClassNames}
          itemHref={itemLink?.(item)}
          itemPathName={itemPath(item)}
        />
      </TableListTooltipWrapper>
    );
  }
  if (sortable) {
    if (itemIndex === null) {
      throw new Error("An error occurred");
    }
    return (
      <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
        <SortableItem
          id={item.item_key}
          value={
            <TableListItemCols
              {...tableListItemColsProps}
              itemClassNames={itemClassNames}
            />
          }
          className={twMerge(
            itemClassNames,
            cardView ? `tw-cursor-grab` : `tw-cursor-ns-resize`
          )}
        />
      </TableListTooltipWrapper>
    );
  }
  return (
    <TableListTooltipWrapper invalidated={item.item_inactive || undefined}>
      <TableListItemCols
        {...tableListItemColsProps}
        itemClassNames={itemClassNames}
      />
    </TableListTooltipWrapper>
  );
};

export default TableListItem;
