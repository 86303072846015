import { useTranslation } from "react-i18next";
import useForm, { RegisterFields } from "hooks/useForm";
import { SubmitHandler } from "react-hook-form";
import Button from "@casasoft/styleguide/components/forms/Button";
import { Alert, Spinner } from "@casasoft/styleguide/components/helpers-ux";
import Switch from "@casasoft/styleguide/components/forms/Switch";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import handleFormModalError, {
  FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";

interface FormShape {
  confirm: boolean;
}

interface GenericRemoveConfirmProps {
  messageText?: string | JSX.Element;
  alertText?: string;
  buttonText?: string;
  isLoading?: boolean;

  /**
   * Context for error handling
   */
  context?: string;

  onDelete?: () => Promise<void> | void;
  onDone: () => void;
  onFail?: FormModalOnFail;
}

const GenericRemoveConfirm = ({
  messageText,
  alertText,
  buttonText,
  isLoading: isLoadingProp,
  context,
  onDelete,
  onDone,
  onFail,
}: GenericRemoveConfirmProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm<FormShape>({ defaultValues: { confirm: false } });

  const onSubmit: SubmitHandler<FormShape> = async () => {
    try {
      await onDelete?.();

      onDone();
    } catch (error) {
      handleFormModalError(error, onFail, context || "confirm modal");
    }
  };

  const isLoading = isLoadingProp || isSubmitting;

  return (
    <RegisterFields
      control={control}
      fields={{
        confirm: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
      }}
      render={(fieldsRenderer) => {
        return (
          <form
            className="spinner-fixture"
            onSubmit={handleSubmit(onSubmit)}
            style={isLoading ? { opacity: 0.5, pointerEvents: "none" } : {}}
          >
            {isLoading && <Spinner />}
            {(messageText && <p className="lead">{messageText}</p>) || (
              <p className="lead">
                {t("Are you sure you want to delete this item?")}
              </p>
            )}

            {alertText && <Alert color="danger">{alertText}</Alert>}

            {fieldsRenderer("confirm", (formValue, onFormValueChange) => (
              <Switch
                label={t("Yes")}
                checked={formValue}
                onToggle={(value: boolean) => {
                  onFormValueChange(value || false);
                }}
              />
            ))}

            {isDirty && (
              <ModalFooter>
                <Button
                  isPrimary
                  type="submit"
                  buttonValue={buttonText || t("Remove")}
                />
              </ModalFooter>
            )}
          </form>
        );
      }}
    />
  );
};

export default GenericRemoveConfirm;
