import { lazy, PureComponent, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NumberField from "@casasoft/styleguide/components/forms/NumberField";
import Select from "@casasoft/styleguide/components/formElements/Select";
import { withTranslation } from "react-i18next";
import asyncLoading from "react-async-loader";
import Textarea from "@casasoft/styleguide/components/forms/Textarea";
import Config from "config";
import ErrorBoundary from "../miscellaneous/ErrorBoundary";
import resourceGrabber from "utilities/resourceGrabber";
import {
  Categories,
  SearchprofileStatuses,
  Utilities,
  Features,
} from "utilities";
import {
  FormModalCols,
  FormModalColsItem,
} from "@casasoft/styleguide/components/form-modal";
// lazy import because it contains turf
const PolygonPicker = lazy(() =>
  import("@casasoft/styleguide/components/forms/PolygonPicker")
);

const Fallback = () => {
  return <span>Loading ...</span>;
};

class ContactSearchprofilesEditForm extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    const company = resourceGrabber.grab("company", "auth");
    const lang = resourceGrabber.grab("lang", "auth");
    // fallback of fallback Zurich
    const address = {
      lat: 47.377213,
      lng: 8.53208,
    };

    if (
      company &&
      company.address &&
      company.address.lat &&
      company.address.lng
    ) {
      address.lat = parseFloat(company.address.lat);
      address.lng = parseFloat(company.address.lng);
    }

    if (
      this.props.contactStore &&
      this.props.contactStore.item &&
      this.props.contactStore.item._embedded &&
      this.props.contactStore.item._embedded.address &&
      this.props.contactStore.item._embedded.address.lat &&
      this.props.contactStore.item._embedded.address.lng
    ) {
      address.lat = parseFloat(
        this.props.contactStore.item._embedded.address.lat
      );
      address.lng = parseFloat(
        this.props.contactStore.item._embedded.address.lng
      );
    }

    return (
      <div>
        <FormModalCols>
          <FormModalColsItem>
            <Select
              nobox
              className="tw-mb-0"
              id="marketingMethod"
              label={this.props.t("Marketing Method")}
              // placeholder={this.props.t('Entität auswählen')}
              value={this.props.data.marketingMethod}
              options={[
                {
                  label: this.props.t("Buy"),
                  value: "buy",
                },
                {
                  label: this.props.t("Rent"),
                  value: "rent",
                },
              ]}
              onChange={(value) => {
                this.props.onFieldChange("marketingMethod", value);
              }}
              message={this.props.messages.marketingMethod}
              autoFocus={this.props.autoFocus === "marketingMethod"}
              t={(string) => {
                switch (string) {
                  case "Select.noResultsText":
                    return this.props.t("No results found");
                  default:
                    return string;
                }
              }}
            />
          </FormModalColsItem>
          <FormModalColsItem>
            <Select
              nobox
              className="tw-mb-0"
              label={this.props.t("standards:Status")}
              placeholder={this.props.t("Choose a Status")}
              id="status"
              value={this.props.data.status}
              onChange={(value) => {
                this.props.onFieldChange("status", value);
              }}
              options={SearchprofileStatuses.getItems()}
              message={this.props.messages.status}
              autoFocus={this.props.autoFocus === "status"}
              t={(string) => {
                switch (string) {
                  case "Select.noResultsText":
                    return this.props.t("No results found");
                  default:
                    return string;
                }
              }}
            />
          </FormModalColsItem>
        </FormModalCols>
        <div className="tw-mb-4" />
        <FormModalCols>
          <FormModalColsItem>
            <Select
              nobox
              label={this.props.t("Utilities")}
              placeholder={this.props.t("Utilities")}
              id="utilities"
              isMulti
              onChange={(value) => {
                this.props.onFieldChange("utilities", value);
              }}
              value={this.props.data.utilities}
              options={Utilities.getUtilities()}
              message={this.props.messages.utilities}
              autoFocus={this.props.autoFocus === "utilities"}
              t={(string) => {
                switch (string) {
                  case "Select.noResultsText":
                    return this.props.t("No results found");
                  default:
                    return string;
                }
              }}
            />
            <Select
              nobox
              label={this.props.t("standards:Categories")}
              placeholder={this.props.t("standards:Categories")}
              id="categories"
              isMulti
              onChange={(value) => {
                this.props.onFieldChange("categories", value);
              }}
              value={this.props.data.categories}
              options={Categories.getCategories()
                .sort((a, b) => {
                  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                  return 0;
                })
                .map((elem) => {
                  return {
                    value: elem.value,
                    label: elem.label,
                  };
                })}
              message={this.props.messages.categories}
              autoFocus={this.props.autoFocus === "categories"}
              t={(string) => {
                switch (string) {
                  case "Select.noResultsText":
                    return this.props.t("No results found");
                  default:
                    return string;
                }
              }}
            />
            <Select
              nobox
              label={this.props.t("Property features")}
              placeholder={this.props.t("Property features")}
              id="features"
              isMulti
              onChange={(value) => {
                this.props.onFieldChange("features", value);
              }}
              value={this.props.data.features}
              options={Features.getFeatures("a-z").map((elem) => ({
                value: elem.key,
                label: elem.label,
              }))}
              message={this.props.messages.features}
              autoFocus={this.props.autoFocus === "features"}
              t={(string) => {
                switch (string) {
                  case "Select.noResultsText":
                    return this.props.t("No results found");
                  default:
                    return string;
                }
              }}
            />
            <Textarea
              nobox
              className="tw-mb-0"
              id="notes"
              label={this.props.t("Notes")}
              value={
                this.props.data.notes !== null ? this.props.data.notes : ""
              }
              placeholder=""
              onChange={(value) => {
                this.props.onFieldChange("notes", value);
              }}
              message={this.props.messages && this.props.messages.notes}
              rows={2}
              autoFocus={this.props.autoFocus === "notes"}
            />
          </FormModalColsItem>
          <FormModalColsItem>
            <FormModalCols>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="roomMin"
                  min={0}
                  max={
                    this.props.data.roomMax
                      ? parseFloat(this.props.data.roomMax, 10)
                      : 50
                  }
                  step={0.5}
                  decimalPlaces={1}
                  label={this.props.t("Room min")}
                  value={this.props.data.roomMin}
                  onChange={(value) => {
                    this.props.onFieldChange("roomMin", value);
                  }}
                  message={this.props.messages && this.props.messages.roomMin}
                  placeholder="Min"
                  autoFocus={this.props.autoFocus === "roomMin"}
                />
              </FormModalColsItem>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="roomMax"
                  min={
                    this.props.data.roomMin
                      ? parseFloat(this.props.data.roomMin, 10)
                      : 0.5
                  }
                  max={50}
                  step={0.5}
                  decimalPlaces={1}
                  label={this.props.t("Room max")}
                  value={this.props.data.roomMax}
                  onChange={(value) => {
                    this.props.onFieldChange("roomMax", value);
                  }}
                  message={this.props.messages && this.props.messages.roomMax}
                  placeholder="Max"
                  autoFocus={this.props.autoFocus === "roomMax"}
                />
              </FormModalColsItem>
            </FormModalCols>
            <div className="tw-mb-4" />
            <FormModalCols>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="priceMin"
                  min={1}
                  max={
                    this.props.data.priceMax
                      ? parseInt(this.props.data.priceMax, 10) - 1
                      : 100000000
                  }
                  label={this.props.t("Price min")}
                  value={this.props.data.priceMin}
                  onChange={(value) => {
                    this.props.onFieldChange("priceMin", value);
                  }}
                  message={this.props.messages && this.props.messages.priceMin}
                  placeholder="Min"
                  autoFocus={this.props.autoFocus === "priceMin"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="priceMax"
                  min={
                    this.props.data.priceMin
                      ? parseInt(this.props.data.priceMin, 10)
                      : 1
                  }
                  max={100000000}
                  label={this.props.t("Price max")}
                  value={this.props.data.priceMax}
                  onChange={(value) => {
                    this.props.onFieldChange("priceMax", value);
                  }}
                  message={this.props.messages && this.props.messages.priceMax}
                  placeholder="Max"
                  autoFocus={this.props.autoFocus === "priceMax"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
            </FormModalCols>
            <div className="tw-mb-4" />
            <FormModalCols>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="areaBwfNwfMin"
                  min={1}
                  max={
                    this.props.data.areaBwfNwfMax
                      ? parseInt(this.props.data.areaBwfNwfMax, 10) - 1
                      : 100000000
                  }
                  label={this.props.t("Living area min")}
                  value={this.props.data.areaBwfNwfMin}
                  onChange={(value) => {
                    this.props.onFieldChange("areaBwfNwfMin", value);
                  }}
                  message={
                    this.props.messages && this.props.messages.areaBwfNwfMin
                  }
                  placeholder="Min"
                  autoFocus={this.props.autoFocus === "areaBwfNwfMin"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="areaBwfNwfMax"
                  min={
                    this.props.data.areaBwfNwfMin
                      ? parseInt(this.props.data.areaBwfNwfMin, 10)
                      : 1
                  }
                  max={100000000}
                  label={this.props.t("Living area max")}
                  value={this.props.data.areaBwfNwfMax}
                  onChange={(value) => {
                    this.props.onFieldChange("areaBwfNwfMax", value);
                  }}
                  message={
                    this.props.messages && this.props.messages.areaBwfNwfMax
                  }
                  placeholder="Max"
                  autoFocus={this.props.autoFocus === "areaBwfNwfMax"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
            </FormModalCols>
            <div className="tw-mb-4" />
            <FormModalCols>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="areaGsfMin"
                  min={1}
                  max={
                    this.props.data.areaGsfMax
                      ? parseInt(this.props.data.areaGsfMax, 10) - 1
                      : 100000000
                  }
                  label={this.props.t("Property land min")}
                  value={this.props.data.areaGsfMin}
                  onChange={(value) => {
                    this.props.onFieldChange("areaGsfMin", value);
                  }}
                  message={
                    this.props.messages && this.props.messages.areaGsfMin
                  }
                  placeholder="Min"
                  autoFocus={this.props.autoFocus === "areaGsfMin"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="areaGsfMax"
                  min={
                    this.props.data.areaGsfMin
                      ? parseInt(this.props.data.areaGsfMin, 10)
                      : 1
                  }
                  max={100000000}
                  label={this.props.t("Property land max")}
                  value={this.props.data.areaGsfMax}
                  onChange={(value) => {
                    this.props.onFieldChange("areaGsfMax", value);
                  }}
                  message={
                    this.props.messages && this.props.messages.areaGsfMax
                  }
                  placeholder="Max"
                  autoFocus={this.props.autoFocus === "areaGsfMax"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
            </FormModalCols>
            <div className="tw-mb-4" />
            <FormModalCols>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="areaSiaNfMin"
                  min={1}
                  max={
                    this.props.data.areaSiaNfMax
                      ? parseInt(this.props.data.areaSiaNfMax, 10) - 1
                      : 100000000
                  }
                  label={this.props.t("Floorspace min")}
                  value={this.props.data.areaSiaNfMin}
                  onChange={(value) => {
                    this.props.onFieldChange("areaSiaNfMin", value);
                  }}
                  message={
                    this.props.messages && this.props.messages.areaSiaNfMin
                  }
                  placeholder="Min"
                  autoFocus={this.props.autoFocus === "areaSiaNfMin"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
              <FormModalColsItem>
                <NumberField
                  className="tw-mb-0"
                  nobox
                  id="areaSiaNfMax"
                  min={
                    this.props.data.areaSiaNfMin
                      ? parseInt(this.props.data.areaSiaNfMin, 10)
                      : 1
                  }
                  max={100000000}
                  label={this.props.t("Floorspace max")}
                  value={this.props.data.areaSiaNfMax}
                  onChange={(value) => {
                    this.props.onFieldChange("areaSiaNfMax", value);
                  }}
                  message={
                    this.props.messages && this.props.messages.areaSiaNfMax
                  }
                  placeholder="Max"
                  autoFocus={this.props.autoFocus === "areaSiaNfMax"}
                  decimalPlaces={2}
                />
              </FormModalColsItem>
            </FormModalCols>
          </FormModalColsItem>
        </FormModalCols>

        <ErrorBoundary>
          <Suspense fallback={<Fallback />}>
            <div className="tw-mb-4" />
            <PolygonPicker
              nobox
              label={this.props.t("Location")}
              lat={address.lat} // Contact Address or CompanyAddress
              lng={address.lng}
              gmap={this.props.gmap}
              onChange={(value) => {
                this.props.onFieldChange("polygon", value);
              }}
              zoom={13}
              primaryColor="blue" // you can choose your favourite Color :)
              format={"wkt"}
              value={this.props.data.polygon}
              casageoSelect
              casageoUrl={Config.casageoUrl}
              lang={lang}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    );
  }
}

ContactSearchprofilesEditForm.propTypes = {
  messages: PropTypes.object.isRequired,
  contactStore: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  // working: PropTypes.boolean.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.string,
  gmap: PropTypes.object,
  contactId: PropTypes.string,
};

ContactSearchprofilesEditForm.defaultProps = {
  autoFocus: null,
  gmap: {},
  contactId: null,
};

const connectedComp = connect((state) => {
  return {
    contactStore: state.contacts,
  };
}, {})(ContactSearchprofilesEditForm);

const comp = asyncLoading(() => {
  const googleMapsApiKey = Config.googleMapsApiKey;
  const gmap = {
    globalPath: "google.maps",
    url: `${Config.googleMapsApiUrl}/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
    jsonp: true,
  };
  return { gmap };
})(connectedComp);

export default withTranslation()(comp);
