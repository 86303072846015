import { ReactNode } from "react";

interface FormModalColsProps {
  children: ReactNode;
}

function FormModalCols({ children }: FormModalColsProps) {
  return <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">{children}</div>;
}

export default FormModalCols;
