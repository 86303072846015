import Config from "config";
import createBaseActions from "entities/createBaseActions";
import { FilterItem, OrderByItem } from "utilities/queryBuilder";
import ContactState from "./types";

export const filterOutUsersAndSubsidiaries: FilterItem[] = [
  {
    where: "and",
    field: "contactType",
    type: "isnull", // only normal casaone contacts - no subsidiaries, no users
  },
];
export const onlyIndividualsInludingUsers: FilterItem[] = [
  {
    where: "and",
    field: "entityType",
    type: "eq",
    value: "individual", // this also applies for users
  },
];
export const onlyIndividualsWithoutUsers: FilterItem[] = [
  {
    where: "and",
    field: "entityType",
    type: "eq",
    value: "individual",
  },
  {
    where: "and",
    field: "contactType",
    type: "isnull",
  },
];
export const onlyLegalWithoutSubsidiaries: FilterItem[] = [
  {
    where: "and",
    field: "entityType",
    type: "eq",
    value: "legal",
  },
  {
    where: "and",
    field: "contactType",
    type: "isnull",
  },
];
export const onlyUsers: FilterItem[] = [
  {
    where: "and",
    field: "contactType",
    type: "eq",
    value: "user",
  },
];

export interface ContactResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: ContactState, actions?: any) => ContactState;
}

interface BaseContactCreatorArgs {
  name: string;
  pluralName: string;
  forcedFilter?: FilterItem[];
  forcedOrderBy?: OrderByItem[];
}

function baseContactCreator({
  name,
  pluralName,
  forcedFilter = [],
  forcedOrderBy = [],
}: BaseContactCreatorArgs) {
  return {
    name: name,
    pluralName: pluralName,
    url: `${Config.apiUrl}/${Config.customerKey}/contact/:id`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    actions: {
      ...createBaseActions({
        embeddedName: "contact",
        forcedFilter: [
          {
            where: "and",
            field: "referenceContact",
            type: "isnull",
          },
          {
            where: "and",
            type: "distinct",
          },
          {
            type: "orx",
            conditions: [
              {
                type: "neq",
                field: "status",
                value: "ignore",
              },
              {
                type: "isnull",
                field: "status",
              },
            ],
            where: "and",
          },
          ...forcedFilter,
        ],
        forcedOrderBy: [...forcedOrderBy],
      }),
    },
  };
}

export default baseContactCreator;
