import { type FormRule } from "hooks/useRules";

const VALID_HOUR_FORMAT_REGEX = /^[0-9]{1,2}:[0-9]{2}$/;

export const timeFormatRule = (errorMessage?: string): FormRule => ({
  validate: (value: string) => {
    // allow empty value
    if (!value) {
      return;
    }

    if (!VALID_HOUR_FORMAT_REGEX.test(value)) {
      return errorMessage;
    }
  },
});
