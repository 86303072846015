import { CsHistoryChangeEventType } from "../index";
import { history } from "config/history";

const initHistoryListener = () => {
  history.listen((location) => {
    gtag("config", "G-4RFV85QQDF", {
      page_title: location.pathname,
      page_path: location.pathname,
      page_referrer: "anonymized",
      page_location: "anonymized",
      send_page_view: false,
    });

    const CsHistoryChangeEvent: CsHistoryChangeEventType = new CustomEvent(
      "CsHistoryChangeEvent",
      {
        detail: { location },
      }
    );

    document.dispatchEvent(CsHistoryChangeEvent);
  });
};

export default initHistoryListener;
