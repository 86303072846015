import { InputProps } from "reactSelectNew";
import { RecipientSelectValueType } from "../RecipientSelect/helpers/useRecipientSelect";

interface CollapsedSelectCustomInputProps
  extends InputProps<RecipientSelectValueType, true> {
  label: string;
}

const CollapsedSelectCustomInput = ({
  label,
}: CollapsedSelectCustomInputProps) => (
  <div>
    {label ? (
      <span className="tw-ml-2 tw-text-tw-cs-accent-color">{label}</span>
    ) : null}
  </div>
);

export const getCollapsedSelectInput =
  (label: string) => (props: InputProps<RecipientSelectValueType, true>) =>
    <CollapsedSelectCustomInput label={label} {...props} />;
