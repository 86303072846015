import { Notification } from "@casasoft/styleguide/components/notification";
import { useTranslation } from "react-i18next";

export const IaziDataError = () => {
  const { t } = useTranslation();

  return (
    <Notification
      colorType="danger"
      showIcon
      showCloseButton={false}
      text={t("Something went wrong")}
    />
  );
};
