import queryBuilder, { FilterItem } from "utilities/queryBuilder";
import { GetPropertyIdListQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<GetPropertyIdListQueryParams>(
  ({ propertyType, query }) => {
    const propertyTypeFilter: FilterItem = Array.isArray(propertyType)
      ? {
          type: "in",
          field: "propertyType",
          values: propertyType,
        }
      : {
          type: "eq",
          field: "propertyType",
          value: propertyType,
        };

    const builtQuery = queryBuilder.buildToUrl(
      query.orderBy,
      [
        ...query.filter,
        propertyTypeFilter,
        {
          type: "neq",
          field: "status",
          value: "ignore",
        },
      ],
      {
        pagesize: query.pageSize,
        page: query.page,
      },
      ["id"]
    );
    return {
      url: `property?${builtQuery}`,
      method: "GET",
    };
  }
);

export default query;
