import { useState, useEffect, ReactNode } from "react";
import * as React from "react";
import FormGroup from "./FormGroup";
import useDebounce from "../../../hooks/useDebounce";
import FieldContainerInterface from "./FieldContainerInterface";
import FieldContainerElemInterface from "./FieldContainerElemInterface";
import FieldElem from "./FieldElem";

export interface FieldContainerInterfaceWithRenderProp
  extends FieldContainerInterface {
  /** Element that needs to be renderd. this is required. */
  renderElem: (props: FieldContainerElemInterface) => JSX.Element;
}

const FieldContainer: React.FC<FieldContainerInterfaceWithRenderProp> = ({
  value = "",
  onChange,
  keyPass,
  onKeyUp,
  onKeyDown,
  onFocus,
  id = "",
  onDelayDuration = 100,
  onDelayChange,
  orig,
  onBlur,
  disabled,
  autoComplete,
  placeholder,
  name,
  required,
  setRef,
  message,
  text,
  lang,
  autoFocus,
  readOnly,
  nobox,
  label,
  status,
  className,
  formType,
  disableLabel,
  quickActions,
  renderElem,
  ariaLabel,
}: FieldContainerInterfaceWithRenderProp) => {
  const [curVal, setCurVal] = useState(value || "");
  const [filled, setFilled] = useState(value !== "");
  const [focused, setFocused] = useState(false);

  const [didMount, setDidMount] = useState(false);
  useEffect(() => setDidMount(true), []);
  useEffect(() => {
    if (didMount) {
      _handleChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const _handleChange = (newValue: string) => {
    setCurVal(newValue);
    setFilled(newValue !== "");
    onChange?.(newValue, keyPass ? keyPass : id, orig);
  };

  useDebounce(curVal, onDelayDuration, (delayedVal) => {
    onDelayChange?.(delayedVal, id, orig);
  });

  const _handleKeyUp = (event: React.KeyboardEvent) => {
    const element = event?.target as HTMLInputElement;
    onKeyUp?.(element.value, id);
  };

  const _handleKeyDown = (event: React.KeyboardEvent) => {
    const element = event?.target as HTMLInputElement;
    onKeyDown?.(element.value, id);
  };

  const _handleFocus = (focusValue: string) => {
    setFocused(true);
    onFocus?.(focusValue, keyPass ? keyPass : id, orig);
  };

  const _handleBlur = (blurValue: string) => {
    setFocused(false);
    onBlur?.(blurValue, keyPass ? keyPass : id, orig);
  };

  const fieldProps = {
    ariaLabel: ariaLabel,
    disabled,
    autoComplete,
    id,
    placeholder,
    onChange: (eventOrValue: any | string) => {
      if (eventOrValue && eventOrValue.target) {
        _handleChange(eventOrValue.target.value);
      } else {
        _handleChange(eventOrValue);
      }
    },
    value: curVal,
    onKeyUp: (event: React.KeyboardEvent) => {
      _handleKeyUp(event);
    },
    onKeyDown: (event: React.KeyboardEvent) => {
      _handleKeyDown(event);
    },
    onFocus: (eventOrValue: any | string) => {
      if (eventOrValue?.target) {
        _handleFocus(eventOrValue?.target.value);
      } else {
        _handleFocus(eventOrValue);
      }
    },
    onBlur: (eventOrValue: any | string) => {
      if (eventOrValue?.target) {
        _handleBlur(eventOrValue?.target.value);
      } else {
        _handleBlur(eventOrValue);
      }
    },
    name,
    required,
    ref: null,
    setRef,
    focused,
    filled,
    message,
    text,
    theme: "cs",
    lang,
    autoFocus,
    readOnly,
    nobox,
  };

  let child: ReactNode = null;
  try {
    child = renderElem(fieldProps);
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    child = <FieldElem disabled value="Feld-Ladefehler" nobox={nobox} />;
  }

  return (
    <FormGroup
      filled={filled}
      focused={focused}
      nobox={nobox}
      label={label}
      status={status}
      className={className}
      id={id}
      required={required}
      message={message}
      text={text}
      formType={formType}
      disableLabel={disableLabel}
      quickActions={quickActions}
    >
      {child}
    </FormGroup>
  );
};

export default FieldContainer;
