export const toEntityErrorMessages = (err: any) => {
  const messages: {
    [key: string]: {
      text: string;
      type: "error" | "success" | "info";
    }[];
  } = {};

  if (err.response?.data?.validation_messages) {
    Object.entries(err.response.data.validation_messages).forEach(
      (fieldMessages: any) => {
        messages[fieldMessages[0]] = Object.entries(fieldMessages[1]).map(
          (fieldMessage: any) => {
            return {
              text: fieldMessage[1],
              type: "error",
            };
          }
        );
      }
    );
  } else if (err.message) {
    messages.General = [
      {
        text: err.message,
        type: "error",
      },
    ];
  } else if (err.name) {
    messages.General = [
      {
        text: err.name,
        type: "error",
      },
    ];
  } else if (typeof err === "string") {
    messages.General = [
      {
        text: err,
        type: "error",
      },
    ];
  }
  // map to flat array
  return Object.entries(messages).flatMap(([slug, theErr]) => {
    return theErr.map((theErr2) => ({
      type: theErr2.type,
      text: theErr2.text && `${slug}: ${theErr2.text}`,
    }));
  });
};

export type EntityErrorMessage = ReturnType<
  typeof toEntityErrorMessages
>[number];
