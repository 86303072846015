import { CreateMediaTagSingleParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<CreateMediaTagSingleParams>((body) => {
  return {
    url: `media-tag`,
    method: "POST",
    data: body,
  };
});

export default query;
