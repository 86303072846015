import * as React from "react";
import {
  components as ReactSelectComponent,
  type OptionProps,
} from "react-select";

interface Props<OptionsProps> {
  renderOption?: (option: any) => string | React.ReactChild;
  props: OptionProps<OptionsProps, any>;
}

const SelectCustomOption: React.FC<Props<any>> = ({
  renderOption = (optionProps: any) => optionProps.label,
  props,
}: Props<any>) => {
  return (
    <ReactSelectComponent.Option {...props}>
      {renderOption(props.data)}
    </ReactSelectComponent.Option>
  );
};

export default SelectCustomOption;
