import { PureComponent } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";

class Messages extends PureComponent {
  __getIconFile(icon) {
    switch (icon) {
      case "error":
        return faExclamationTriangle;
      case "info":
        return faInfoCircle;
      default:
        return faInfoCircle;
    }
  }

  render() {
    return this.props.messages ? (
      <span>
        {this.props.messages.map((item, i, arr) => {
          const divider = i < arr.length - 1 && <br />;
          let messageType = "";
          switch (item.type) {
            case "error":
              messageType = "invalid";
              break;
            case "success":
              messageType = "valid";
              break;
            case "info":
              messageType = "info";
              break;
            default:
              messageType = "invalid";
              break;
          }
          if (!item.text) {
            return null;
          }
          return (
            <span
              className={`cs-feedback ${messageType}-feedback`}
              key={`${item.text.length}${Math.random()}`}
            >
              {item.type && this.props.icon && (
                <span>
                  <FontAwesomeIcon icon={this.__getIconFile(item.type)} />
                </span>
              )}
              <span
                dangerouslySetInnerHTML={{
                  __html: `${
                    item.validationKey
                      ? `<strong>${item.validationKey}:</strong>`
                      : ""
                  } ${item.text}`,
                }}
              />
              {divider}
            </span>
          );
        })}
      </span>
    ) : null;
  }
}

Messages.propTypes = {
  messages: PropTypes.array,
  icon: PropTypes.bool,
};

Messages.defaultProps = {
  messages: null,
  icon: false,
};

export default Messages;
