import { forwardRef, HTMLAttributes, KeyboardEvent } from "react";

interface DivAccessibleClickProps extends HTMLAttributes<HTMLDivElement> {}

/** simply renders a div, but when an onClick handler is set, treats the div as an accessible role=button by defaukt */
const DivAccessibleClick = forwardRef<HTMLDivElement, DivAccessibleClickProps>(
  function ({ children, ...props }, ref) {
    const defaultRole = props.onClick ? "button" : undefined;
    const defaultTabindex = props.onClick ? 0 : undefined;
    const defaultOnKeyDown = props.onClick
      ? (e: KeyboardEvent<HTMLDivElement>) => {
          if (e.key === "Enter" || e.key === " ") {
            e.currentTarget.click();
            e.preventDefault();
          }
        }
      : undefined;

    return (
      <div
        ref={ref}
        {...props}
        role={props.role || defaultRole}
        onKeyDown={props.onKeyDown || defaultOnKeyDown}
        tabIndex={
          typeof props.tabIndex === "number" ? props.tabIndex : defaultTabindex
        }
      >
        {children}
      </div>
    );
  }
);

export default DivAccessibleClick;
