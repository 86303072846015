import { createProvidesTags } from "api/createEndpoint";
import {
  GetPhaseRelationshipQueryParams,
  GetPhaseRelationshipResponse,
} from "./types";

const providesTags = createProvidesTags<
  GetPhaseRelationshipResponse,
  GetPhaseRelationshipQueryParams
>((result) => {
  if (result) {
    return [
      {
        type: "Phase",
        id: result.latestPhase,
      },
      {
        type: "Phase",
        id: "RELATIONSHIP",
      },
      {
        type: "Contact",
        id: result.contact,
      },
      {
        type: "Property",
        id: result.property,
      },
    ];
  }

  return ["Phase", "Property", "Contact"];
});

export default providesTags;
