import { casaoneApi } from "api";
import listQuery from "./endpoints/list/query";
import listProvidesTags from "./endpoints/list/providesTags";

const contactApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSignatureList: builder.query({
      query: listQuery,
      providesTags: listProvidesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserSignatureListQuery,
  useLazyGetUserSignatureListQuery,
} = contactApi;
