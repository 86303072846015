import { CasaoneBaseQueryArgs } from "api/types";
import { IaziCommonDataRequestBody } from "./types";

const DEFAULT_BODY: Partial<IaziCommonDataRequestBody> = {
  countryCode: "CH",
  cultureCode: "de-CH",
};

const query = (body: IaziCommonDataRequestBody): CasaoneBaseQueryArgs => ({
  url: `api/v1/iazi-ai-properties`,
  method: "POST",
  data: { ...DEFAULT_BODY, ...body },
});

export default query;
