import { useGetPropertyListReducedQuery } from "api/entities/property";
import useTableListQuery from "hooks/table-list/useTableListQuery";
import useTableListResourceOptions from "hooks/table-list/useTableListResourceOptions";
import PropertyList from "./PropertyList";
import ApiErrorReport from "@casasoft/styleguide/components/helpers-ux/ApiErrorReport";
import useTableListQueryResult from "hooks/table-list/useTableListQueryResult";
import { useEffect, useState } from "react";
import userSortToQuerySort from "./helpers/userSortToQuerySort";
import userFilterToQueryFilter from "./helpers/userFilterToQueryFilter";
import { useAppSelector } from "redux/hooks";
import { companyLanguages } from "redux/auth/selectors";
import { OrderByItem } from "utilities/queryBuilder";
import { DropdownProps } from "@casasoft/styleguide/components/dropdown";
import { TableListProps } from "@casasoft/styleguide/components/table-list";
import { FilterItemWithRememberMe } from "components/miscellaneous/TableListContainerProps";
import { Item } from "@casasoft/styleguide/components/table-list/TableListItem";
import { TableListBulkSelectControl } from "@casasoft/styleguide/hooks/useTableListBulkSelect";

interface PropertyListContainerProps {
  mini?: boolean;
  isModal?: boolean;
  presetName?: string;
  defaultOrder?: OrderByItem[];
  mainActionNodes?: DropdownProps["items"];
  itemActionNodes?: TableListProps["itemActionNodes"];
  forcedFilter?: FilterItemWithRememberMe[];
  apiContext?: string;
  listContext?: string;
  bulkSelectControl?: TableListBulkSelectControl;
  onItemClick?: (item: Item) => void;
  propertyType?: string;
  refetchTriggerHandler?: (state: boolean) => void;
  refetchTrigger?: boolean;
}

const PropertyListContainer = ({
  mini = false,
  propertyType,
  presetName,
  apiContext,
  listContext,
  mainActionNodes,
  itemActionNodes,
  forcedFilter,
  defaultOrder = [
    {
      type: "field",
      field: "created",
      direction: "desc",
    },
  ],
  isModal = false,
  bulkSelectControl,
  onItemClick,
  refetchTriggerHandler,
  refetchTrigger,
}: PropertyListContainerProps) => {
  const languageOptions = useAppSelector(companyLanguages);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<false | string>(
    false
  );
  const [removeModalOpen, setRemoveModalOpen] = useState<false | string>(false);
  const [quickAddModal, setQuickAddModal] = useState(false);
  const [generatingCSV, setGeneratingCSV] = useState<string | null>(null);
  const [generatePdfListModal, setGeneratePdfListModal] = useState(false);
  const [sortable, setSortable] = useState(false);
  const [sortOrder, setSortOrder] = useState<Item[] | undefined>();
  const [generateLang, setGenerateLang] = useState<string>(
    languageOptions?.[0]?.value || "de"
  );

  const generateLangHandler = (val: string) => setGenerateLang(val);
  const toggleQuickAddModal = (val: boolean) => setQuickAddModal(val);
  const toggleModalLoader = (val: string | null) => setGeneratingCSV(val);
  const toggleGeneratePdfListModal = (val: boolean) =>
    setGeneratePdfListModal(val);
  const sortOrderHandler = (val: Item[] | undefined) => setSortOrder(val);

  // TABLELIST ORCHESTRATION START
  const [resourceOptionsTLProps, resourceOptions] = useTableListResourceOptions(
    {
      resourceId: presetName || listContext || propertyType || "property",
    }
  );
  const sortOrderItem: OrderByItem[] = [
    {
      type: "customorderby",
      field: "rank",
      alias: "",
      direction: "asc",
    },
  ];
  const [query] = useTableListQuery({
    resourceOptions,
    forcedFilter: forcedFilter || [],
    userFilterToQueryFilter: userFilterToQueryFilter,
    userSortToQuerySort: userSortToQuerySort,
    defaultOrder: sortable ? sortOrderItem : defaultOrder,
  });

  const getPropertyListResult = useGetPropertyListReducedQuery({
    propertyType: propertyType,
    query,
  });

  const toggleSortable = (val: boolean) => {
    setSortable(val);
    if (!val) {
      sortOrderHandler(undefined);
      getPropertyListResult.refetch();
    }
  };

  useEffect(() => {
    if (refetchTriggerHandler && refetchTrigger) {
      refetchTriggerHandler(false);
      getPropertyListResult.refetch();
    }
  }, [refetchTrigger, refetchTriggerHandler, getPropertyListResult]);

  const listQueryResultTLProps = useTableListQueryResult(getPropertyListResult);
  if (getPropertyListResult.isError) {
    return (
      <ApiErrorReport
        context="Property List"
        error={getPropertyListResult.error}
      />
    );
  }

  // TABLELIST ORCHESTRATION END
  return (
    <PropertyList
      apiContext={apiContext}
      mainActionNodes={mainActionNodes}
      itemActionNodes={itemActionNodes}
      languageOptions={languageOptions}
      refetch={getPropertyListResult.refetch}
      resourceOptionsTLProps={resourceOptionsTLProps}
      propertyType={propertyType}
      toggleModalLoader={toggleModalLoader}
      toggleGeneratePdfListModal={toggleGeneratePdfListModal}
      generatingCSV={generatingCSV}
      generatePdfListModal={generatePdfListModal}
      listQueryResultTLProps={listQueryResultTLProps}
      propertyItems={getPropertyListResult.data?.items}
      mini={mini}
      query={query}
      generateLang={generateLang}
      generateLangHandler={generateLangHandler}
      quickAddModal={quickAddModal}
      toggleQuickAddModal={toggleQuickAddModal}
      duplicateModalOpen={duplicateModalOpen}
      onDuplicateModalOpenChange={(newState) => {
        setDuplicateModalOpen(newState);
      }}
      removeModalOpen={removeModalOpen}
      presetName={presetName}
      onRemoveModalOpenChange={(newState) => {
        setRemoveModalOpen(newState);
      }}
      sortable={sortable}
      toggleSortable={toggleSortable}
      sortOrder={sortOrder}
      sortOrderHandler={sortOrderHandler}
      isModal={isModal}
      listContext={listContext}
      bulkSelectControl={bulkSelectControl}
      onItemClick={onItemClick}
    />
  );
};

export default PropertyListContainer;
