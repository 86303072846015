import createBoardingInstance from "@casasoft/styleguide/utilities/tours/createBoardingInstance";
import { history } from "config/history";
import { CsHistoryChangeEventType } from "index";
import completeAndBackToOverview from "tours/completeAndBackToOverview";
import { waitForElm, waitForElmRemoval } from "tours/utilities";

export const PROPERTY_EDETAILS_TOUR = "prty-edtls-tour";

export const PROPERTY_TOUR_EDETAILS_APP_CONTENT = "prty-edtls-ap-cntnt";
export const PROPERTY_TOUR_EDETAILS_DETAILS_CARD = "prty-edtls-card-dtls";
export const PROPERTY_TOUR_EDETAILS_FINANCES_CARD = "prty-edtls-card-fncs";
export const PROPERTY_TOUR_EDETAILS_ACTION_DROPDOWN = "prty-edtls-dpd-actn";

export function subscribePropertyEditDetailsOnboarding(
  t: (term: string) => string
) {
  const [boarding, unsubscribe] = createBoardingInstance(
    PROPERTY_EDETAILS_TOUR,
    t,
    {
      allowClose: false,
      padding: 5,
      strictClickHandling: "block-all", // we are doing the clicking for them
    }
  );

  const historyChangeHandler = async (e: Event) => {
    if (!boarding.isActivated) {
      return;
    }

    const myEv = e as CsHistoryChangeEventType;

    // step 0 = enter property date step
    if (boarding.currentStep === 0) {
      if (myEv.detail.location.pathname.startsWith("/property/list/item/")) {
        await waitForElm(".app__contentminilist__list");
        boarding.next();
      }
    }
  };

  document.addEventListener("CsHistoryChangeEvent", historyChangeHandler);
  const unsubscribeHistory = () => {
    document.removeEventListener("CsHistoryChangeEvent", historyChangeHandler);
  };

  boarding.defineSteps([
    {
      element: `[data-boarding~="${PROPERTY_TOUR_EDETAILS_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto`,
      popover: {
        title: t("propertiesPrepare.intro.title"),
        description: t("propertiesPrepare.intro.desc"),
        prefferedSide: "top",
        showButtons: ["close", "previous"],
        className: "cs-popover-awaiting-input",
      },
      scrollIntoViewOptions: { behavior: "smooth", block: "start" },
      padding: 2,
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_EDETAILS_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto`
        );
        boarding.continue();
      },
      onNext: () => {
        if (!history.location.pathname.startsWith("/property/list/item/")) {
          // move next handeled by history change listener
          boarding.preventMove();
          setTimeout(() => {
            boarding.clearMovePrevented();
          }, 0);
        }
      },
      strictClickHandling: false,
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_EDETAILS_DETAILS_CARD}"]`,
      popover: {
        title: t("propertiesPrepare.details.title"),
        description: t("propertiesPrepare.details.desc"),
        prefferedSide: "left",
        showButtons: ["next", "close"],
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_EDETAILS_DETAILS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `div[role="dialog"][data-state="open"]`,
      popover: {
        title: t("propertiesPrepare.detailsForm.title"),
        description: t("propertiesPrepare.detailsForm.desc"),
        prefferedSide: "left",
        alignment: "center",
        showButtons: ["previous", "close"],
        className: "cs-popover-awaiting-input",
      },
      onPrevious: () => {
        // close modal
        const closeBtn = document.querySelector<HTMLElement>(
          `div[role="dialog"][data-state="open"] > div > button[type="button"]`
        );

        closeBtn?.click();
      },
      prepareElement: async () => {
        // open modal
        const createBtn = document.querySelector<HTMLElement>(
          `[data-boarding~="${PROPERTY_TOUR_EDETAILS_DETAILS_CARD}"] .tw-cs-btn-header-action`
        );
        createBtn?.click();

        boarding.preventMove();
        await waitForElm(`div[role="dialog"][data-state="open"]`);
        boarding.continue();

        // setTimout 0 is necessary, so it runes after all boarding.continue logic has finished
        await new Promise<void>((res) =>
          setTimeout(() => {
            res();
          }, 0)
        );
        const currentStepEl = boarding.getHighlightedElement().getElement();

        // modal is now open. subscribe to modal closing
        waitForElmRemoval('div[role="dialog"][data-state="open"]').then(() => {
          // when going back boarding.getHighlightedElement().getElement() will be the previous element -> therefore we don't want to go next again
          if (currentStepEl === boarding.getHighlightedElement().getElement()) {
            boarding.next();
          }
        });
      },
      scrollIntoViewOptions: "no-scroll",
      strictClickHandling: false,
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_EDETAILS_FINANCES_CARD}"]`,
      popover: {
        title: t("propertiesPrepare.finances.title"),
        description: t("propertiesPrepare.finances.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_EDETAILS_FINANCES_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `div[role="dialog"][data-state="open"]`,
      popover: {
        title: t("propertiesPrepare.financesForm.title"),
        description: t("propertiesPrepare.financesForm.dec"),
        prefferedSide: "left",
        alignment: "center",
        showButtons: ["previous", "close"],
        className: "cs-popover-awaiting-input",
      },
      onPrevious: () => {
        // close modal
        const closeBtn = document.querySelector<HTMLElement>(
          `div[role="dialog"][data-state="open"] > div > button[type="button"]`
        );

        closeBtn?.click();
      },
      prepareElement: async () => {
        // open modal
        const createBtn = document.querySelector<HTMLElement>(
          `[data-boarding~="${PROPERTY_TOUR_EDETAILS_FINANCES_CARD}"] .tw-cs-btn-header-action`
        );
        createBtn?.click();

        boarding.preventMove();
        await waitForElm(`div[role="dialog"][data-state="open"]`);
        boarding.continue();

        // setTimout 0 is necessary, so it runes after all boarding.continue logic has finished
        await new Promise<void>((res) =>
          setTimeout(() => {
            res();
          }, 0)
        );
        const currentStepEl = boarding.getHighlightedElement().getElement();

        // modal is now open. subscribe to modal closing
        waitForElmRemoval('div[role="dialog"][data-state="open"]').then(() => {
          // when going back boarding.getHighlightedElement().getElement() will be the previous element -> therefore we don't want to go next again
          if (currentStepEl === boarding.getHighlightedElement().getElement()) {
            boarding.next();
          }
        });
      },
      scrollIntoViewOptions: "no-scroll",
      strictClickHandling: false,
    },
    {
      element: `.app__contentminilist__content-container .tw-grid`,
      popover: {
        title: t("propertiesPrepare.finish.title"),
        description: t("propertiesPrepare.finish.desc"),
        prefferedSide: "left",
        alignment: "center",
        prevBtnText: t("onboarding.btn.backToOverview"),
      },
      onPrevious: () => {
        completeAndBackToOverview(boarding);
      },
      scrollIntoViewOptions: { behavior: "smooth", block: "start" },
      strictClickHandling: false,
    },
  ]);

  return () => {
    unsubscribe();
    unsubscribeHistory();
  };
}
