import { CasaoneResultDescription } from "api/types";
import {
  GetUserSignatureListQueryParams,
  GetUserSignatureListResponse,
} from "./types";

const providesTags: CasaoneResultDescription<
  GetUserSignatureListResponse,
  GetUserSignatureListQueryParams
> = (result) => {
  if (result) {
    const userSignatureTags = result._embedded.user_signature.map((item) => ({
      type: "UserSignature" as const,
      id: item.id,
    }));

    return [
      ...userSignatureTags,
      {
        type: "UserSignature",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["UserSignature"];
};

export default providesTags;
