import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "document",
    label: "Document",
  },
  {
    value: "plan",
    label: "Plan",
  },
  {
    value: "sales-brochure",
    label: "Sales brochure",
  },
  {
    value: "land-register-extract",
    label: "Land register extract",
  },
];

export const getDocumentTypes = createGetList(untranslatedList);
export const getDocumentTypeLabel = createGetLabel(untranslatedList);

export const useDocumentTypes = createUtilityHook(untranslatedList);
