import {
  TableList,
  TableListProps,
} from "@casasoft/styleguide/components/table-list";
import FilterFormContainer from "components/miscellaneous/FilterFormContainer";
import { TableListListQueryResultProps } from "hooks/table-list/useTableListQueryResult";
import { TableListResourceOptionsProps } from "hooks/table-list/useTableListResourceOptions";
import useColumns from "./hooks/useColumns";
import useItems from "./hooks/useItems";
import { useTranslation } from "react-i18next";
import { faCopy, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import PropertyRemoveForm from "components/property/PropertyRemoveForm";
import PropertyDuplicateForm from "components/property/PropertyDuplicateForm";
import { useUpdatePropertyMutation } from "api/entities/property";
import useFilterFormOptions from "./hooks/useFilterFormOptions";
import useRenderFilterForm from "./hooks/useRenderFilterForm";
import useMainActionNode from "./hooks/useMainActionNode";
import PropertyCreateForm, {
  IAZI_CREATED_FLAG_PREFIX,
} from "../../PropertyCreateForm";
import useMainActionNodes from "./hooks/useMainActionNodes";
import { TableListQuery } from "hooks/table-list/useTableListQuery";
import {
  ModalFooter,
  ModalLoader,
} from "@casasoft/styleguide/components/modal";
import Select from "@casasoft/styleguide/components/formElements/Select";
import Button from "@casasoft/styleguide/components/forms/Button";
import { useDownloadList } from "./hooks/useDownloadList";
import { GetPropertyListReducedResponse } from "api/entities/property/endpoints/list-reduced/types";
import { history } from "config/history";
import { DropdownProps } from "@casasoft/styleguide/components/dropdown";
import { Item } from "@casasoft/styleguide/components/table-list/TableListItem";
import { arrayMoveImmutable } from "array-move";
import { TableListBulkSelectControl } from "@casasoft/styleguide/hooks/useTableListBulkSelect";

interface PropertyListProps {
  resourceOptionsTLProps: TableListResourceOptionsProps;
  listQueryResultTLProps: TableListListQueryResultProps;
  propertyItems?: GetPropertyListReducedResponse["items"];
  mini: boolean;
  query: TableListQuery;
  duplicateModalOpen: false | string;
  removeModalOpen: false | string;
  onDuplicateModalOpenChange: (newState: string | false) => void;
  onRemoveModalOpenChange: (newState: string | false) => void;
  toggleQuickAddModal: (arg: boolean) => void;
  quickAddModal: boolean;
  toggleModalLoader: (arg: string | null) => void;
  toggleGeneratePdfListModal: (arg: boolean) => void;
  generatePdfListModal: boolean;
  generatingCSV: string | boolean | null;
  generateLangHandler: (arg: string) => void;
  generateLang: string;
  propertyType?: string;
  refetch: () => void;
  languageOptions?: { value: string; label: string }[];
  mainActionNodes?: DropdownProps["items"];
  itemActionNodes?: TableListProps["itemActionNodes"];
  apiContext?: string;
  sortOrder?: Item[] | undefined;
  sortOrderHandler: (arg: Item[] | undefined) => void;
  sortable: boolean;
  toggleSortable: (arg: boolean) => void;
  isModal: boolean;
  listContext?: string;
  bulkSelectControl?: TableListBulkSelectControl;
  onItemClick?: (item: Item) => void;
  presetName?: string;
}

const PropertyList = ({
  resourceOptionsTLProps,
  listQueryResultTLProps,
  propertyItems,
  mini,
  query,
  onDuplicateModalOpenChange,
  onRemoveModalOpenChange,
  duplicateModalOpen,
  removeModalOpen,
  toggleQuickAddModal,
  quickAddModal,
  toggleModalLoader,
  toggleGeneratePdfListModal,
  generatePdfListModal,
  generatingCSV,
  generateLangHandler,
  generateLang,
  propertyType,
  refetch,
  languageOptions,
  mainActionNodes,
  itemActionNodes,
  apiContext,
  sortOrder,
  sortOrderHandler,
  sortable,
  toggleSortable,
  isModal,
  listContext,
  bulkSelectControl,
  onItemClick,
  presetName,
}: PropertyListProps) => {
  const { t } = useTranslation();
  const { downloadList } = useDownloadList();
  const cols = useColumns({ mini, propertyType, sortable });

  const items = useItems({ mini, propertyItems, listContext }) || [];
  const renderItems = sortOrder || items;

  const mainActionNode = useMainActionNode({
    listContext,
    apiContext,
    toggleQuickAddModal,
    sortable,
    toggleSortable,
    sortOrder,
    refetch,
    propertyType,
  });
  const genericMainActionNodes = useMainActionNodes({
    mini: mini,
    toggleModalLoader: toggleModalLoader,
    toggleGeneratePdfListModal: toggleGeneratePdfListModal,
    query: query,
    lang: generateLang,
    propertyType,
  });

  const [updateProperty] = useUpdatePropertyMutation();

  const filterFormOptions = useFilterFormOptions({
    mini,
    propertyType: propertyType || "property",
  });
  const { renderFilterForm } = useRenderFilterForm({ filterFormOptions });

  return (
    <>
      <ModalLoader open={generatingCSV !== null}>
        {generatingCSV || ""}
      </ModalLoader>
      <FilterFormContainer
        presetName={presetName || propertyType || "property"}
        filterFormOptions={filterFormOptions}
        renderFilterForm={renderFilterForm}
        render={(filterFormTLProps) => (
          <TableList
            {...resourceOptionsTLProps}
            {...listQueryResultTLProps}
            {...filterFormTLProps}
            onNewSortOrder={({ oldIndex, newIndex }) => {
              const newSortedArray: Item[] = arrayMoveImmutable(
                [...renderItems],
                oldIndex,
                newIndex
              );
              sortOrderHandler(newSortedArray);
            }}
            bulkSelectControl={bulkSelectControl}
            onItemClick={onItemClick}
            sortable={sortable}
            cardView={sortable}
            cardBody={!isModal}
            components={
              mini
                ? ["search", "actions", "pagination"]
                : sortable
                ? ["actions"]
                : undefined
            }
            mini={mini ? `/${propertyType}/list` : false}
            cols={cols}
            items={renderItems}
            itemPath={
              onItemClick
                ? undefined
                : (item) =>
                    `/${item.propertyType || propertyType}/list/item/${item.id}`
            }
            itemActionNodes={
              itemActionNodes ||
              ((item: Item) => [
                {
                  label: t("Duplicate"),
                  icon: faCopy,
                  onItemClick: () => {
                    onDuplicateModalOpenChange(item.id);
                  },
                },
                "spacer",
                {
                  label: t("Remove"),
                  icon: faTrash,
                  onItemClick: () => {
                    onRemoveModalOpenChange(item.id);
                  },
                },
              ])
            }
            mainActionNode={mainActionNode}
            mainActionNodes={mainActionNodes || genericMainActionNodes}
            searchFields={
              !mini
                ? [
                    {
                      key: "all",
                      label: t("All"),
                    },
                    {
                      key: "visualReferenceId",
                      label: t("standards:Reference no."),
                    },
                    {
                      key: "address.street",
                      label: t("Street"),
                    },
                    {
                      key: "address.locality",
                      label: t("Locality"),
                    },
                  ]
                : undefined
            }
          />
        )}
      />
      <FormModal
        isOpen={generatePdfListModal}
        onCancel={() => {
          toggleGeneratePdfListModal(false);
        }}
        onDone={() => {
          toggleGeneratePdfListModal(false);
          downloadList({
            query,
            type: "pdf",
            toggleModalLoader,
            lang: generateLang,
            propertyType,
          });
        }}
        form={(props) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.onDone();
              }}
            >
              <Select
                nobox
                value={generateLang}
                options={languageOptions}
                onChange={(val) => {
                  generateLangHandler(val);
                }}
              />
              <ModalFooter>
                <Button isPrimary type="submit" buttonValue={t("Download")} />
              </ModalFooter>
            </form>
          );
        }}
        header={t("Download PDF list")}
        size="sm"
      />
      <FormModal
        isOpen={!!removeModalOpen}
        form={(props) => {
          return (
            <PropertyRemoveForm
              onDone={props.onDone}
              onFail={props.onFail}
              onReady={props.onReady}
              save={props.save}
              id={removeModalOpen}
              propertyType={propertyType}
            />
          );
        }}
        size="md"
        onCancel={() => {
          onRemoveModalOpenChange(false);
        }}
        onDone={async () => {
          if (!removeModalOpen) {
            throw new Error("No property ID provided");
          }
          // TODO: error handling with unwrap -> once refactored into own feature
          await updateProperty({ id: removeModalOpen, status: "ignore" });
          onRemoveModalOpenChange(false);
        }}
        header={t(`Remove ${propertyType}`)}
        saveButtonText={t("Remove")}
      />
      <FormModal
        isOpen={!!duplicateModalOpen}
        form={(props) => {
          if (!duplicateModalOpen) {
            throw new Error("No property ID provided");
          }
          return (
            <PropertyDuplicateForm
              onDone={() => {
                props.onDone();
              }}
              onFail={props.onFail}
              targetPropertyType={propertyType || "property"}
              entity={duplicateModalOpen}
            />
          );
        }}
        size="md"
        onCancel={() => {
          onDuplicateModalOpenChange(false);
        }}
        onDone={() => {
          refetch();
          onDuplicateModalOpenChange(false);
        }}
        header={t(`Duplicate ${propertyType}`)}
      />
      <FormModal
        isOpen={quickAddModal}
        header={t(`Create ${propertyType}`)}
        onDone={(body) => {
          history.push(
            body.importId?.startsWith(IAZI_CREATED_FLAG_PREFIX)
              ? `/${body.propertyType}/list/item/${body.id}?iaziCreated=1`
              : `/${body.propertyType}/list/item/${body.id}`
          );
          toggleQuickAddModal(false);
        }}
        size="md"
        form={(props) => {
          return (
            <PropertyCreateForm
              onDone={props.onDone}
              onFail={props.onFail}
              propertyType={propertyType || "property"}
            />
          );
        }}
        onCancel={() => {
          toggleQuickAddModal(false);
        }}
      />
    </>
  );
};

export default PropertyList;
