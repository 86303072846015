import { useEffect } from "react";
import { Card, CardHeader } from "@casasoft/styleguide/components/card";
import csMoment from "utilities/csMoment";
import { TableList } from "@casasoft/styleguide/components/table-list";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faList,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { EventShape } from "entities/event/types";
import { useQuickAlert } from "entities/event/quickAlertStore";
import { notificationResource } from "entities/event/notificationStore";
import { getAttendeeData } from "../../event/helpers/attendees";

interface DashboardQuickalertsCardProps {
  onChange?: () => void;
}

const DashboardQuickalertsCard = ({
  onChange,
}: DashboardQuickalertsCardProps) => {
  const { t } = useTranslation();

  const { store: quickAlertStore, updateItem: updateQuickAlertItem } =
    useQuickAlert({
      fetchList: { pageSize: 15 },
    });

  // trigger the change when fetch is finished
  useEffect(() => {
    onChange?.();
  }, [quickAlertStore.items, onChange]);

  return (
    <>
      <Card>
        <CardHeader
          actions={[
            {
              icon: faList,
            },
          ]}
        >
          {t("Hints / Alerts")}
        </CardHeader>
        <TableList
          loading={quickAlertStore.isFetching}
          itemsLoaded={
            quickAlertStore &&
            "listMetadata" in quickAlertStore &&
            typeof quickAlertStore.listMetadata?.totalItems === "number"
              ? true
              : false
          }
          cols={[
            {
              key: "state",
              label: "Status",
              placeholder: "circle",
              maxWidth: 40,
            },
            {
              key: "subject",
              label: "Alert",
            },
          ]}
          total={quickAlertStore.items.length}
          items={
            quickAlertStore.items?.map((elem: EventShape) => {
              const startRender = elem.start
                ? csMoment(elem.start.date).format("L LT")
                : "?";
              const startRenderS = elem.start.date;
              const { sender, recipient } = getAttendeeData(
                elem?._embedded?.attendees
              );
              let senderName = "";
              if (sender) {
                senderName = sender?._embedded?.contact?.displayName;
              }
              let recipientName = "";
              if (recipient) {
                recipientName = recipient?._embedded?.contact?.displayName;
              }

              let state = <FontAwesomeIcon icon={faExclamationTriangle} />;
              if (elem._embedded?.events?.length) {
                state = <FontAwesomeIcon icon={faCheckCircle} />;
              }

              return {
                id: elem.id,
                item_key: elem.id,
                senderName,
                recipientName,
                start_render: startRender,
                start_render_s: startRenderS,
                state,
                subject: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: elem.subject,
                    }}
                  ></span>
                ),
                propertyId: elem._embedded?.property?.id || null,
                propertyType: elem._embedded?.property?.propertyType,
              };
            }) || []
          }
          components={["item-actions"]}
          itemPath={(item) => {
            if (item.propertyId) {
              return `/${item.propertyType}/list/item/${item.propertyId}`;
            }
            return `/event/list/item/${item.id}`;
          }}
          itemActionNodes={(item) => [
            {
              label: t("Complete"),
              icon: faCheck,
              onItemClick: async () => {
                await updateQuickAlertItem(
                  item.id,
                  {
                    status: "done",
                  },
                  true
                );
                notificationResource.reloadList();
              },
            },
          ]}
        />
      </Card>
      <div className="tw-mb-4" />
    </>
  );
};

export default DashboardQuickalertsCard;
