import createBoardingInstance from "@casasoft/styleguide/utilities/tours/createBoardingInstance";
import { history } from "config/history";
import { CsHistoryChangeEventType } from "index";
import completeAndBackToOverview from "tours/completeAndBackToOverview";
import { waitForElm } from "tours/utilities";
// import resourceGrabber from "utilities/resourceGrabber";

export const PROPERTY_DETAILS_TOUR = "prty-dtls-tour";

export const PROPERTY_TOUR_DETAILS_APP_CONTENT = "prty-dtls-ap-cntnt";
export const PROPERTY_TOUR_DETAILS_PROPERTY_NAV_ITEM = "prty-dtls-prty-nv-itm";
export const PROPERTY_TOUR_DETAILS_NAME_CARD = "prty-dtls-crd-nm";
export const PROPERTY_TOUR_DETAILS_ADDRESS_CARD = "prty-dtls-crd-addrs";
export const PROPERTY_TOUR_DETAILS_DETAILS_CARD = "prty-dtls-crd-dtls";
export const PROPERTY_TOUR_DETAILS_INFRASTRUCTURE_CARD = "prty-dtls-crd-infr";
export const PROPERTY_TOUR_DETAILS_AREASV_CARD = "prty-dtls-crd-av";
export const PROPERTY_TOUR_DETAILS_FINANCES_CARD = "prty-dtls-crd-fncs";
export const PROPERTY_TOUR_DETAILS_IMAGES_CARD = "prty-dtls-crd-imgs";
export const PROPERTY_TOUR_DETAILS_ENERGY_CARD = "prty-dtls-crd-egy";
export const PROPERTY_TOUR_DETAILS_CONTRACT_CARD = "prty-dtls-crd-ctrct";
export const PROPERTY_TOUR_DETAILS_FEATURES_CARD = "prty-dtls-crd-ftrs";
export const PROPERTY_TOUR_DETAILS_DOCUMENTS_CARD = "prty-dtls-crd-docs";
export const PROPERTY_TOUR_DETAILS_DESCRIPTIONS_CARD = "prty-dtls-crd-descs";
export const PROPERTY_TOUR_DETAILS_OFFERS_CARD = "prty-dtls-crd-offrs";
export const PROPERTY_TOUR_DETAILS_LINKS_CARD = "prty-dtls-crd-href";
export const PROPERTY_TOUR_DETAILS_USERS_CARD = "prty-dtls-crd-usrs";
export const PROPERTY_TOUR_DETAILS_SUBSIDIARY_CARD = "prty-dtls-crd-subsdry";
export const PROPERTY_TOUR_DETAILS_CONTACTP_CARD = "prty-dtls-crd-ctcp";
export const PROPERTY_TOUR_DETAILS_INQUIRIESPERSON_CARD = "prty-dtls-crd-inqp";

export function subscribePropertyDetailsOnboarding(
  t: (term: string) => string
) {
  const [boarding, unsubscribe] = createBoardingInstance(
    PROPERTY_DETAILS_TOUR,
    t,
    {
      allowClose: false,
      padding: 5,
      strictClickHandling: "block-all", // we are doing the clicking for them
    }
  );

  const historyChangeHandler = async (e: Event) => {
    if (!boarding.isActivated) {
      return;
    }

    const myEv = e as CsHistoryChangeEventType;

    // step 8 = enter property date step
    if (boarding.currentStep === 1) {
      if (myEv.detail.location.pathname.startsWith("/property/list/item/")) {
        await waitForElm(".app__contentminilist__list");
        boarding.next();
      }
    }
  };

  document.addEventListener("CsHistoryChangeEvent", historyChangeHandler);
  const unsubscribeHistory = () => {
    document.removeEventListener("CsHistoryChangeEvent", historyChangeHandler);
  };

  boarding.defineSteps([
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_PROPERTY_NAV_ITEM}"]`,
      padding: -5,
      popover: {
        title: t("propertiesDetails.intro.title"),
        description: t("propertiesDetails.intro.desc"),
        prefferedSide: "right",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_PROPERTY_NAV_ITEM}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto`,
      popover: {
        title: t("propertiesDetails.list.title"),
        description: t("propertiesDetails.list.desc"),
        prefferedSide: "top",
        showButtons: ["close", "previous"],
        className: "cs-popover-awaiting-input",
      },
      scrollIntoViewOptions: { behavior: "smooth", block: "start" },
      padding: 2,
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto`
        );
        boarding.continue();
      },
      onNext: () => {
        if (!history.location.pathname.startsWith("/property/list/item/")) {
          // move next handeled by history change listener
          boarding.preventMove();
          setTimeout(() => {
            boarding.clearMovePrevented();
          }, 0);
        }
      },
      strictClickHandling: false,
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_NAME_CARD}"]`,
      popover: {
        title: t("propertiesDetails.contents.title"),
        description: t("propertiesDetails.contents.desc"),
        prefferedSide: "left",
        showButtons: ["next", "close"],
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_NAME_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_ADDRESS_CARD}"]`,
      popover: {
        title: t("propertiesDetails.address.title"),
        description: t("propertiesDetails.address.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_ADDRESS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_DETAILS_CARD}"]`,
      popover: {
        title: t("propertiesDetails.details.title"),
        description: t("propertiesDetails.details.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_DETAILS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_INFRASTRUCTURE_CARD}"]`,
      popover: {
        title: t("propertiesDetails.infra.title"),
        description: t("propertiesDetails.infra.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_INFRASTRUCTURE_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_AREASV_CARD}"]`,
      popover: {
        title: t("propertiesDetails.areasVols.title"),
        description: t("propertiesDetails.areasVols.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_AREASV_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_FINANCES_CARD}"]`,
      popover: {
        title: t("propertiesDetails.finances.title"),
        description: t("propertiesDetails.finances.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_FINANCES_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_IMAGES_CARD}"]`,
      popover: {
        title: t("propertiesDetails.gallery.title"),
        description: t("propertiesDetails.gallery.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_IMAGES_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_ENERGY_CARD}"]`,
      popover: {
        title: t("propertiesDetails.energy.title"),
        description: t("propertiesDetails.energy.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_ENERGY_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_CONTRACT_CARD}"]`,
      popover: {
        title: t("propertiesDetails.contracts.title"),
        description: t("propertiesDetails.contracts.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_CONTRACT_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_FEATURES_CARD}"]`,
      popover: {
        title: t("propertiesDetails.features.title"),
        description: t("propertiesDetails.features.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_FEATURES_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_DOCUMENTS_CARD}"]`,
      popover: {
        title: t("propertiesDetails.docs.title"),
        description: t("propertiesDetails.docs.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_DOCUMENTS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_DESCRIPTIONS_CARD}"]`,
      popover: {
        title: t("propertiesDetails.descriptions.title"),
        description: t("propertiesDetails.descriptions.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_DESCRIPTIONS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_OFFERS_CARD}"]`,
      popover: {
        title: t("propertiesDetails.iOffers.title"),
        description: t("propertiesDetails.iOffers.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_OFFERS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_LINKS_CARD}"]`,
      popover: {
        title: t("propertiesDetails.links.title"),
        description: t("propertiesDetails.links.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_LINKS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_USERS_CARD}"]`,
      popover: {
        title: t("propertiesDetails.user.title"),
        description: t("propertiesDetails.user.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_USERS_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_SUBSIDIARY_CARD}"]`,
      popover: {
        title: t("propertiesDetails.subsidiary.title"),
        description: t("propertiesDetails.subsidiary.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_SUBSIDIARY_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_CONTACTP_CARD}"]`,
      popover: {
        title: t("propertiesDetails.cPerson.title"),
        description: t("propertiesDetails.cPerson.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_CONTACTP_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${PROPERTY_TOUR_DETAILS_INQUIRIESPERSON_CARD}"]`,
      popover: {
        title: t("propertiesDetails.inquiriesPerson.title"),
        description: t("propertiesDetails.inquiriesPerson.desc"),
        prefferedSide: "left",
      },
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${PROPERTY_TOUR_DETAILS_INQUIRIESPERSON_CARD}"]`
        );
        boarding.continue();
      },
    },
    {
      element: `.app__contentminilist__content-container .tw-grid`,
      popover: {
        title: t("propertiesDetails.finish.title"),
        description: t("propertiesDetails.finish.desc"),
        prefferedSide: "left",
        alignment: "center",
        prevBtnText: t("onboarding.btn.backToOverview"),
      },
      onPrevious: () => {
        completeAndBackToOverview(boarding);
      },
      scrollIntoViewOptions: { behavior: "smooth", block: "start" },
      strictClickHandling: false,
    },
  ]);

  return () => {
    unsubscribe();
    unsubscribeHistory();
  };
}
