export enum UserStatuses {
  active = "active",
  inactive = "inactive",
  ignore = "ignore",
}

export type UserStatus = keyof typeof UserStatuses;

export function isUserStatus(status: string | null): status is UserStatus {
  return Object.keys(UserStatuses).includes(status as UserStatus);
}
