enum Services {
  geo = "geo",
}

enum CasaoneApi {
  api = "api",
  api2 = "api2",
}

export enum Env {
  local = "local",
  test = "test",
  test1 = "test1",
  test2 = "test2",
  staging = "staging",
  preprod = "preprod",
  production = "production",
}

export function buildConfig(forUrl: URL | Location) {
  type CustomerKey = string;

  // TODO: Refactor this so it works with localhost as well
  const getCompanyUrl = (slug: string): string => {
    const { host, protocol } = forUrl;
    const parts = host.split(".");
    parts[0] = slug;
    // verify we fix it in case of casaone
    const subdomain = parts[parts.length - 3];
    const env = getEnv();
    switch (env) {
      case Env.production:
        if (subdomain === "my") {
          parts[parts.length - 2] = "casaone";
        } else {
          parts[parts.length - 2] = "my.casaone";
        }
        break;
      case Env.staging:
      case Env.test:
      case Env.test1:
      case Env.test2:
        if (subdomain === Env.staging || subdomain === Env.test) {
          parts[parts.length - 2] = "casaone";
        } else {
          parts[parts.length - 2] = `${env}.casaone`;
        }
        break;
      default:
        break;
    }
    return `${protocol}//${parts.join(".")}`;
  };

  // get environment
  const getEnv = (): Env => {
    let env: Env;

    // check if localStore is set, which will overwrite
    const localStorageEnv = window.localStorage.getItem(
      "co_custom_env_settings"
    );
    if (localStorageEnv) {
      switch (localStorageEnv) {
        case "local":
          env = Env.local;
          break;
        case "test":
          env = Env.test;
          break;
        case "test1":
          env = Env.test1;
          break;
        case "test2":
          env = Env.test2;
          break;
        case "staging":
          env = Env.staging;
          break;
        case "preprod":
          env = Env.preprod;
          break;
        case "production":
          env = Env.production;
          break;
        default:
          throw new Error("Unsupported env selected");
      }
    } else {
      if (forUrl.host.match(/\.test\./)) {
        env = Env.test;
      } else if (forUrl.host.match(/\.test1\./)) {
        env = Env.test1;
      } else if (forUrl.host.match(/\.test2\./)) {
        env = Env.test2;
      } else if (forUrl.host.match(/\.staging\./)) {
        env = Env.staging;
      } else if (forUrl.host.match(/\.preprod\./)) {
        env = Env.preprod;
      } else if (forUrl.host.match(/(localhost|\.casaone\.local)/)) {
        env = Env.local;
      } else {
        env = Env.production;
      }
    }
    return env;
  };

  // get customer key
  const getCustomerKey = (): CustomerKey => {
    const hostChunks = forUrl.host.split(".");
    const mainHost = hostChunks[hostChunks.length - 2];
    let customerKey = hostChunks[0]; // customerKey default to lowest subdomain

    if (mainHost !== "casaone" && mainHost !== "casasoft") {
      if (forUrl.host.match(/(localhost|\.casaone\.local)/)) {
        customerKey = customerKey === forUrl.host ? "dev" : customerKey;
      } else {
        customerKey = "custom-domain";
      }
    }

    // "crm" is an alias to "casasoft"
    if (customerKey === "crm") {
      customerKey = "casasoft";
    }
    if (!customerKey) {
      throw new Error("No customerKey could be inferred");
    }
    return customerKey;
  };

  const serviceUrl = (serviceName: Services): string => {
    switch (getEnv()) {
      case Env.local:
      case Env.test:
        return `https://${serviceName}.${Env.test}.casasoft.com`;
      case Env.test1:
        return `https://${serviceName}.${Env.test1}.casasoft.com`;
      case Env.test2:
        return `https://${serviceName}.${Env.test2}.casasoft.com`;
      case Env.staging:
        return `https://${serviceName}.${Env.staging}.casasoft.com`;
      case Env.production:
      case Env.preprod:
        return `https://${serviceName}.casasoft.com`;
      default:
        throw new Error("Unknown Env for service url");
    }
  };

  const casaoneApi = (apiName: CasaoneApi): string => {
    const env = getEnv();
    switch (env) {
      case Env.local:
        // process.env.REACT_APP_ENV_CHECK just set in github actions to not allow local on prod/staging/test/preprod
        return process.env.REACT_APP_ENV_CHECK
          ? `https://casaone.ch`
          : `http://${apiName}.casaone.local:5792`;
      case Env.test:
        return `https://${apiName}.test.casaone.ch`;
      case Env.test1:
        return `https://${apiName}.test1.casaone.ch`;
      case Env.test2:
        return `https://${apiName}.test2.casaone.ch`;
      case Env.staging:
        return `https://${apiName}.staging.casaone.ch`;
      case Env.preprod:
        return `https://${apiName}.preprod.casaone.ch`;
      case Env.production:
        return `https://${apiName}.casaone.ch`;
      default:
        throw new Error("Unsupported env for API url");
    }
  };

  const casaconnectUrl = (): string => {
    const env = getEnv();

    switch (env) {
      case Env.local:
      case Env.test:
      case Env.test1:
      case Env.test2:
        return "https://connect.test.casasoft.com";
      case Env.staging:
        return "https://connect.staging.casasoft.com";
      case Env.preprod:
        return "https://connect.casasoft.com";
      case Env.production:
        return "https://connect.casasoft.com";
      default:
        throw new Error("Unsupported env for API url");
    }
  };

  const casaiamUrl = (): string => {
    const env = getEnv();

    switch (env) {
      case Env.local:
      case Env.test:
        return "https://casaiam.test.casasoft.com";
      case Env.test1:
        return "https://casaiam.test1.casasoft.com";
      case Env.test2:
        return "https://casaiam.test2.casasoft.com";
      case Env.staging:
        return "https://casaiam.staging.casasoft.com";
      case Env.preprod:
        return "https://casaiam.casasoft.com";
      case Env.production:
        return "https://casaiam.casasoft.com";
      default:
        throw new Error("Unsupported env for API url");
    }
  };

  return {
    release: process.env.REACT_APP_RELEASE_VERSION || "0.0.0",
    env: getEnv(),
    supportMail: "support@casasoft.ch",
    apiUrl: casaoneApi(CasaoneApi.api),
    api2Url: casaoneApi(CasaoneApi.api2),
    customerKey: getCustomerKey(),
    casageoUrl: serviceUrl(Services.geo),
    companyUrl: getCompanyUrl,
    faqUrl: "https://faq.casaone.ch",
    casaoneProjectUrl: "https://project.casaone.ch",
    casasoftWebsiteUrl: "https://casasoft.ch",
    googleMapsApiUrl: `https://maps.googleapis.com`,
    googleMapsApiKey: "AIzaSyDJj6FCeK5K5ltaVxac0oXOKNqosj1j7jI",
    mapboxUrl: "https://api.mapbox.com",
    casaconnectUrl: casaconnectUrl(),
    casaiamUrl: casaiamUrl(),
  };
}

export default buildConfig(window.location);
