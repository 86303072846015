import { createResource } from "redux-rest-resource";
import Config from "config";
import CaCompanyCoordState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: CaCompanyCoordState,
    actions?: any
  ) => CaCompanyCoordState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "caCompanyCoord",
  pluralName: "caCompanyCoords",
  url: `${Config.apiUrl}/${Config.customerKey}/ca-company/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (!state.items) {
          return state;
        }
        const newState = Object.assign({}, state);
        const items = state.items.map((item: any) => {
          if (item.id === action.context.id) {
            return action.context;
          }
          return item;
        });
        newState.items = items;
        newState.lastUpdated = Date.now();
        return newState;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.company_coords) {
          return { res, body: res.body._embedded.company_coords };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        const cnxtOpts = Object.assign(
          {},
          {
            pageSize: 20,
            filter: [],
            orderBy: [],
          },
          contextOpts
        );
        const opts = Object.assign(
          {},
          {
            pageSize: 50,
            orderBy: [
              {
                type: "customorderby",
                field: "name",
                alias: "",
                direction: "asc",
              },
            ],
            filter: [] as any[],
          },
          {
            pageSize: cnxtOpts.pageSize,
            orderBy: [...cnxtOpts.orderBy],
            filter: [...cnxtOpts.filter],
          }
        );

        if (!opts.filter.find((x) => x.field === "referenceContact")) {
          opts.filter.push({
            where: "and",
            field: "referenceContact",
            type: "isnull",
          });
        }

        opts.filter.push({
          where: "and",
          field: "contactType",
          type: "isnull",
        });

        // casaauth does not use the hydrator its manuell from here on
        const query: { [key: string]: any } = {};

        query.return_coords = "1";

        // searchfilter
        const searchFilter = opts.filter.find((item) => item.type === "search");
        if (searchFilter) {
          query.s = searchFilter.value;
        }

        // pagesize = page_size
        const pageSizeFilter = opts.pageSize;
        if (pageSizeFilter) {
          query.page_size = pageSizeFilter;
        }

        // active_products
        const activeProductsFilter = opts.filter.find(
          (item) => item.field === "active_products"
        );

        if (activeProductsFilter && activeProductsFilter.values) {
          query.active_products = activeProductsFilter.values.join(",");
        }

        // plans
        const plansFilter = opts.filter.find((item) => item.field === "plans");

        if (plansFilter && plansFilter.values) {
          query.plan = plansFilter.values.join(",");
        }

        // regions
        const regionsFilter = opts.filter.find(
          (item) => item.field === "regions"
        );
        if (regionsFilter && regionsFilter.values) {
          query.regions = regionsFilter.values.join(",");
        }

        // page
        if (cnxtOpts.page) {
          query.page = parseInt(cnxtOpts.page, 10);
        }

        return query;
      },
    },
  },
});
