import { defaultReducers } from "redux-rest-resource/lib/reducers/index";
import queryBuilder, {
  FilterItem,
  OrderByItem,
  Params,
} from "utilities/queryBuilder";

interface CreateBaseActionsArgs<TPrimaryKey extends string> {
  embeddedName: string;
  forcedFilter?: FilterItem[];
  forcedOrderBy?: OrderByItem[];
  forcedPageSize?: number;
  forcedApiContext?: string;
  customQuery?: (param1?: any, param2?: any) => Params;
  primaryKey?: TPrimaryKey;
}

function createBaseActions<TPrimaryKey extends string>({
  embeddedName,
  forcedFilter = [],
  forcedOrderBy = [],
  forcedPageSize,
  forcedApiContext,
  customQuery,
  primaryKey,
}: CreateBaseActionsArgs<TPrimaryKey>) {
  const resourcePrimaryKey = primaryKey || ("id" as const);
  return {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (state.items) {
          const oldItems: any[] = state.items;
          // maps/replaces patch result item data into list
          const items = oldItems.map((item) => {
            if (
              item[resourcePrimaryKey] === action.context[resourcePrimaryKey]
            ) {
              return action.context;
            }
            return item;
          });

          return {
            ...state,
            items: items,
            lastUpdated: Date.now(),
          };
        }
        return state;
      },
    },
    clearPreviousListState: {
      isPure: true,
      reduce: (state: any) => ({
        ...state,
        items: [],
      }),
    },
    clearPreviousItemState: {
      isPure: true,
      reduce: (state: any) => ({
        ...state,
        item: null,
      }),
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context
          ? { ...state.lastQuery, ...action.context }
          : action.context,
      }),
    },
    get: {
      transformResponse: (res: any) => {
        if (
          resourcePrimaryKey !== "id" &&
          res?.body?.[resourcePrimaryKey] !== undefined &&
          res?.body?.id === undefined
        ) {
          // always write "id" property from primary key, if it's not in the body.
          // -> because redux-resout-resoruce internally always checks for "id" to invalidate previous stores which causes weird behaviour if a different primary key is used (for example "username")
          res.body.id = res?.body?.[resourcePrimaryKey];
        }

        return res;
      },
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded[embeddedName]) {
          return { res, body: res.body._embedded[embeddedName] };
        }
        // needed after this changes: https://bitbucket.org/casasoftag/casaone/pull-requests/2744
        // when using fields filters response shape change
        if (res.body.items) {
          return { res, body: res.body.items };
        }
        return { res, body: [] };
      },
      reduce: (state: any, action: any) => {
        // do the default hydration from redux-rest-resource
        const newState = defaultReducers.fetch(state, action);
        // hydrate listMetaData
        const listMetadata =
          action.status === "resolved"
            ? {
                page: action.res.body.page,
                pageCount: action.res.body.page_count,
                pageSize: action.res.body.page_size,
                totalItems: action.res.body.total_items,
                _links: action.res.body._links,
              }
            : undefined;

        return {
          ...newState,
          listMetadata,
        };
      },
      query: customQuery
        ? customQuery
        : (getState: any, { contextOpts, context }: any) => {
            const opts = {
              ...contextOpts,
              filter: [...(contextOpts.filter || []), ...forcedFilter],
              orderBy: [...(contextOpts.orderBy || []), ...forcedOrderBy],
            };

            const query = queryBuilder.build(opts.orderBy, opts.filter, {
              pagesize: forcedPageSize || opts.pageSize,
              page: opts.page,
              context: forcedApiContext || opts.apiContext,
              ...context,
            });
            return query;
          },
    },
  };
}

export default createBaseActions;
