import type {
  PropertyFlyerAttachment,
  PropertyAttachment,
  PropertyDocumentationAttachment,
} from "../helpers/propertyAttachment.model";
import {
  isDocumentationAttachment,
  isFlyerAttachment,
} from "../helpers/attachmentGuard";
import { PropertyAttachmentFlyerSidebar } from "./PropertyAttachmentFlyerSidebar";
import { PropertyAttachmentDefaultSidebar } from "./PropertyAttachmentDefaultSidebar";
import { PropertyAttachmentDocumentationSidebar } from "./PropertyAttachmentDocumentationSidebar";

interface PropertyAttachmentSidebarSingleProps {
  selectedAttachment: PropertyAttachment;
  onChange: (updatedAttachment: PropertyAttachment) => void;
}

export const PropertyAttachmentSidebarSingle = (
  props: PropertyAttachmentSidebarSingleProps
) => {
  if (isDocumentationAttachment(props.selectedAttachment)) {
    return (
      <PropertyAttachmentDocumentationSidebar
        {...props}
        selectedAttachment={
          props.selectedAttachment as PropertyDocumentationAttachment
        }
      />
    );
  } else if (isFlyerAttachment(props.selectedAttachment)) {
    return (
      <PropertyAttachmentFlyerSidebar
        {...props}
        selectedAttachment={props.selectedAttachment as PropertyFlyerAttachment}
      />
    );
  }

  return (
    <PropertyAttachmentDefaultSidebar
      selectedAttachment={props.selectedAttachment}
    />
  );
};
