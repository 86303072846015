import { Component } from "react";
import PropTypes from "prop-types";
import { NavLink as Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faBinoculars,
  faLock,
  faStar,
  faPencil,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

import { faSign } from "@fortawesome/pro-light-svg-icons/faSign";

import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
  PUBLICATION_TYPES,
} from "utilities/propertyTypes";
import { Media, PropertyStatuses } from "utilities";
import { Badge } from "@casasoft/styleguide/components/helpers-ux";

class PropertyExcerpt extends Component {
  static __grabMainContentMedia(elem) {
    if (elem?._embedded?.contents?.[0]?._embedded?.contentMedias) {
      const foundMediaContentForLogo =
        elem._embedded.contents[0]._embedded.contentMedias.find(
          (contentMedia) => {
            if (
              contentMedia.type === "offer-logo" &&
              contentMedia._embedded &&
              contentMedia._embedded.media
            ) {
              return true;
            }
            return false;
          }
        );
      if (foundMediaContentForLogo) {
        return foundMediaContentForLogo;
      }
      const foundMediaContent =
        elem._embedded.contents[0]._embedded.contentMedias.find(
          (contentMedia) => {
            if (
              contentMedia.type === "image" &&
              contentMedia._embedded &&
              contentMedia._embedded.media
            ) {
              return true;
            }
            return false;
          }
        );
      if (foundMediaContent) {
        return foundMediaContent;
      }
    }
    return undefined;
  }

  static __grabTitle(entity) {
    if (
      entity &&
      entity._embedded &&
      entity._embedded.contents &&
      entity._embedded.contents[0]
    ) {
      return entity._embedded.contents[0].name;
    }
    return null;
  }

  __renderLines() {
    const lines = [];
    const entity = this.props.entity;

    // line 1: title
    if (this.props.lines >= 1) {
      const title = PropertyExcerpt.__grabTitle(entity);
      if (title) {
        lines.push({
          key: "name",
          render: title,
          style: {
            minWidth: "200px",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        });
      }
    }

    // line 2: ref
    if (this.props.lines >= 2) {
      if (entity.visualReferenceId) {
        lines.push({
          key: "visualReferenceId",
          render: entity.visualReferenceId,
          className: ["tw-font-semibold"],
        });
      }
    }

    // line 3: status
    if (this.props.lines >= 3) {
      if (entity.status) {
        let statusTxt = PropertyStatuses.getPropertyStatusLabel(
          entity.status,
          entity.marketingMethod
        );
        const propType = entity.propertyType;
        let propTypeT = null;
        if (this.props.showType) {
          if (propType === BASE_DATA_PROJECT) {
            propTypeT = this.props.t("Project");
          } else if (propType === BASE_DATA_UNIT) {
            propTypeT = this.props.t("Unit");
          } else if (propType === BASE_DATA_PROPERTY) {
            propTypeT = this.props.t("Property");
          } else if (PUBLICATION_TYPES.includes(propType)) {
            propTypeT = this.props.t("Publication");
          }
        }
        lines.push({
          key: "status",
          render: (
            <div>
              {propTypeT && <Badge color="secondary">{propTypeT}</Badge>}

              {statusTxt}
            </div>
          ),
          className:
            entity.status !== "active" ? ["tw-text-cs-shade-500"] : null,
        });
      }
    }

    // lin4: unit project property relation
    if (this.props.lines >= 4) {
      if (
        entity.propertyType === BASE_DATA_PROPERTY ||
        entity.propertyType === BASE_DATA_UNIT
      ) {
        const lelineitems = [];
        if (entity._embedded && entity._embedded.parent) {
          const subtitle = PropertyExcerpt.__grabTitle(entity._embedded.parent);
          if (subtitle) {
            lelineitems.push(<span key="subtitle">{subtitle}</span>);
          }
          if (
            entity._embedded.parent._embedded &&
            entity._embedded.parent._embedded.parent
          ) {
            const subsubtitle = PropertyExcerpt.__grabTitle(
              entity._embedded.parent._embedded.parent
            );
            if (subsubtitle) {
              lelineitems.push(<span key="subsubtitle">{subsubtitle}</span>);
            }
          }
        }
        if (lelineitems.length >= 1) {
          lines.push({
            key: "hierarchy",
            render: lelineitems.reduce((prev, curr) => [
              prev,
              <FontAwesomeIcon
                key="sep"
                icon={faChevronRight}
                className="tw-mr-1 tw-ml-1"
              />,
              curr,
            ]),
          });
        }
      }
    }

    const renderedLines = lines.map((line) => {
      let className = [];
      if (line.className) {
        className = line.className;
      }
      let style = null;
      if (line.style) {
        style = line.style;
      }
      return (
        <div key={line.key} className={className.join(" ")} style={style}>
          {line.render}
        </div>
      );
    });
    return renderedLines;
  }

  render() {
    if (!this.props.entity) {
      return null;
    }
    const renderedLines = this.__renderLines();
    let thumbnailImg = null;
    if (this.props.showThumbnail) {
      let thumbIcon = null;
      if (this.props.entity.status === "inactive") {
        thumbIcon = <FontAwesomeIcon icon={faEyeSlash} />;
      }
      if (this.props.entity.status === "private") {
        thumbIcon = <FontAwesomeIcon icon={faLock} />;
      }
      if (this.props.entity.status === "reference") {
        thumbIcon = <FontAwesomeIcon icon={faStar} />;
      }
      if (this.props.entity.status === "reserved") {
        thumbIcon = <FontAwesomeIcon icon={faSign} />;
      }
      if (this.props.entity.status === "taken") {
        thumbIcon = <FontAwesomeIcon icon={faSign} />;
      }
      if (this.props.entity.status === "draft") {
        thumbIcon = <FontAwesomeIcon icon={faPencil} />;
      }
      if (this.props.entity.status === "in-acquisition") {
        thumbIcon = <FontAwesomeIcon icon={faBinoculars} />;
      }
      const mainContentMedia = PropertyExcerpt.__grabMainContentMedia(
        this.props.entity
      );
      if (this.props.lines <= 2) {
        let thumbnailSrc = null;
        if (mainContentMedia) {
          thumbnailSrc = Media.mediaThumbnail(
            mainContentMedia._embedded.media,
            "sq"
          );
        }
        const thumbStyles = {
          marginRight: "0.5rem",
          backgroundColor: "rgb(var( --cs-shade-100))",
          height: (this.props.small ? 72 : 108) / 2,
          width: (this.props.small ? 72 : 108) / 2,
          textAlign: "center",
        };
        if (mainContentMedia && mainContentMedia.type === "offer-logo") {
          thumbStyles.backgroundImage = thumbnailSrc
            ? `url(${thumbnailSrc})`
            : null;
          thumbStyles.backgroundRepeat = "no-repeat";
          thumbStyles.backgroundPosition = "center center";
          thumbStyles.backgroundSize = "contain";
          thumbStyles.backgroundOrigin = "content-box";
          thumbStyles.padding = 7;
        } else {
          thumbStyles.backgroundImage = thumbnailSrc
            ? `url(${thumbnailSrc})`
            : null;
          thumbStyles.backgroundSize = "cover";
          thumbStyles.backgroundPosition = "center center";
        }

        thumbnailImg = (
          <div
            className={`property-thumbnail${
              thumbIcon ? " property-thumbnail--withicon" : ""
            }`}
            style={thumbStyles}
          >
            {thumbIcon}
          </div>
        );
      } else if (this.props.lines >= 3) {
        let thumbnailSrc = null;
        if (mainContentMedia) {
          thumbnailSrc = Media.mediaThumbnail(
            mainContentMedia._embedded.media,
            "sqmd"
          );
        }
        const thumbStyles = {
          marginRight: "0.5rem",
          backgroundColor: "rgb(var( --cs-shade-100))",
          height: (this.props.small ? 116 : 160) / 2,
          width: (this.props.small ? 116 : 160) / 2,
          textAlign: "center",
        };
        if (mainContentMedia && mainContentMedia.type === "offer-logo") {
          thumbStyles.backgroundImage = thumbnailSrc
            ? `url(${thumbnailSrc})`
            : null;
          thumbStyles.backgroundRepeat = "no-repeat";
          thumbStyles.backgroundSize = "contain";
          thumbStyles.backgroundPosition = "center center";
          thumbStyles.backgroundOrigin = "content-box";
          thumbStyles.padding = 12;
        } else {
          thumbStyles.backgroundImage = thumbnailSrc
            ? `url(${thumbnailSrc})`
            : null;
          thumbStyles.backgroundSize = "cover";
          thumbStyles.backgroundPosition = "center center";
        }
        thumbnailImg = (
          <div
            className={`property-thumbnail${
              thumbIcon ? " property-thumbnail--withicon" : ""
            }`}
            style={thumbStyles}
          >
            {thumbIcon}
          </div>
        );
      }
    }
    if (this.props.linkTo) {
      return (
        <Link
          style={{ display: "flex", position: "relative" }}
          className={this.props.small ? "small" : null}
          key="link"
          title="Navigate to item"
          to={`/${this.props.entity.propertyType}/list/item/${this.props.entity.id}`}
        >
          {thumbnailImg}
          <div style={{ flexGrow: 1, width: 200 }}>{renderedLines}</div>
        </Link>
      );
    }
    return (
      <div
        style={{ display: "flex", position: "relative" }}
        className={this.props.small ? "small" : null}
      >
        {thumbnailImg}
        <div style={{ flexGrow: 1, width: 200 }}>{renderedLines}</div>
      </div>
    );
  }
}

PropertyExcerpt.propTypes = {
  entity: PropTypes.object,
  lines: PropTypes.number,
  showThumbnail: PropTypes.bool,
  small: PropTypes.bool,
  linkTo: PropTypes.bool,
  showType: PropTypes.bool,
};

PropertyExcerpt.defaultProps = {
  entity: null,
  lines: 3,
  showThumbnail: false,
  small: false,
  linkTo: false,
  showType: false,
};

export default withTranslation()(PropertyExcerpt);
