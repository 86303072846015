import Tinymce from "@casasoft/styleguide/components/formElements/tinymce/Tinymce";
import { MessageHistoryControls } from "./MessageHistoryControls";
import cx from "classnames";

export type MessageHistoryChangeHandlers = {
  addMessageHistory: (isOn: boolean) => void;
  messageHistory: (value?: string) => void;
};

export type MessageHistoryValues = {
  addMessageHistory: boolean;
  messageHistory?: string;
};

interface MessageHistoryContainerProps {
  values: MessageHistoryValues;
  changeHandlers: MessageHistoryChangeHandlers;
}

export const MessageHistoryContainer = ({
  values,
  changeHandlers,
}: MessageHistoryContainerProps) => {
  const isExpanded = values.addMessageHistory;
  const onHideSwitchToggle = () => {
    changeHandlers.addMessageHistory(!isExpanded);
  };

  if (!values.messageHistory) {
    return null;
  }

  return (
    <div>
      <MessageHistoryControls
        isExpanded={values.addMessageHistory}
        onHideSwitchToggle={onHideSwitchToggle}
      />
      <div
        className={cx(
          "tw-scale-95",
          { "tw-max-h-[300px]": isExpanded },
          { "tw-max-h-0": !isExpanded },
          "tw-overflow-y-scroll"
        )}
        style={{ transition: "max-height 0.3s ease-in-out" }}
      >
        <Tinymce
          id={"message-history"}
          disabled
          value={values.messageHistory}
          placeholder=""
          rows={4}
          tinymceConfig={{
            menu: {},
            menubar: false,
            browser_spellcheck: false,
            statusbar: false,
            target_list: false,
            quickbars_insert_toolbar: false,
            toolbar: false,
          }}
        />
      </div>
    </div>
  );
};
