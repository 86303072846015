import { ReactNode } from "react";

interface PaginationProps {
  children: ReactNode;
}

function Pagination({ children }: PaginationProps) {
  return (
    <nav
      aria-label="pagination"
      className="tw-flex tw-justify-center tw-p-0 tw-m-0"
    >
      {children}
    </nav>
  );
}

export default Pagination;
