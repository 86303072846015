import { forwardRef, HTMLAttributes, ReactNode } from "react";

interface CardProps extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  children: ReactNode;
  /** Shows the card in a pulsating style and prevents click actions */
  loading?: boolean;
  /** Shows the card in a note style. Used for "internal notes" in most cases */
  isNote?: boolean;
  /** Gives the card a slightly tint bg color based on the accent color */
  hasPrimaryBg?: boolean;
}

const Card = forwardRef<HTMLDivElement, CardProps>(function (
  {
    children,
    loading = false,
    hasPrimaryBg = false,
    isNote = false,

    ...divProps
  },
  ref
) {
  return (
    <div
      {...divProps}
      ref={ref}
      className={`tw-cs-card-base 
        ${loading ? "tw-cs-card-loading" : ""}
        ${hasPrimaryBg ? "tw-cs-card-primary-bg" : ""}
        ${isNote ? "tw-cs-card-note" : ""}
      `}
    >
      {children}
    </div>
  );
});

export default Card;
