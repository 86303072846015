interface EmptyTextLineProps {
  /** width of the line, can be a number or a string with a unit */
  width: number | string;
  /** the color of the line -> multiple possible shades */
  shade?: "dark" | "medium" | "light";
}

function getShadeClass(shade: EmptyTextLineProps["shade"] = "medium") {
  switch (shade) {
    case "light":
      return "tw-bg-cs-shade-50";
    case "dark":
      return "tw-bg-cs-shade-300";
    case "medium":
    default:
      return "tw-bg-cs-shade-100";
  }
}

function EmptyTextLine({ width, shade }: EmptyTextLineProps) {
  return (
    <div
      className={`tw-h-[0.8em] tw-rounded-sm tw-mb-[0.6em] ${getShadeClass(
        shade
      )}`}
      style={{ width: width }}
    />
  );
}

export default EmptyTextLine;
