import "../assets/styles/main.scss";
import "@casasoft/styleguide/styles/index.css";

import {
  Content,
  RouterSubnav,
  View,
} from "@casasoft/styleguide/components/app";
import { lazy, Suspense, useEffect } from "react";
import { Route, useLocation } from "react-router";
import App from "components/App";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
  PUBLICATION_TYPES,
} from "utilities/propertyTypes";
import { history } from "config/history";
import useCasaoneTours from "tours/useCasaoneTours";
import resourceGrabber from "utilities/resourceGrabber";
import { PageNotificationBanners } from "components/miscellaneous/pageNotificationBanner/PageNotificationBanners";

const Crm = lazy(() => import("../components/crm/Index"));
const Dash = lazy(() => import("../components/dash/Index"));
const Media = lazy(() => import("../components/media/Index"));
const ContactRoot = lazy(() => import("../components/contact/ContactRoot"));
const Event = lazy(() => import("../components/event/Index"));
const Plan = lazy(() => import("../components/plan/Index"));
const Report = lazy(() => import("../components/reports/Index"));
const Message = lazy(() => import("../components/message/Index"));
const Admin = lazy(() => import("../components/admin/Index"));
const PropertyRoot = lazy(() => import("../components/property/PropertyRoot"));
const PublicationRoot = lazy(
  () => import("../components/publication/PublicationRoot")
);
const JobRoot = lazy(() => import("../components/job/JobRoot"));
const InvoicesRoot = lazy(() => import("../components/invoices/InvoicesRoot"));
const Fallback = () => {
  return (
    <View>
      <RouterSubnav
        routes={[
          {
            path: "/",
            navLabel: "Loading",
          },
        ]}
      />
      <Content>Loading ...</Content>
    </View>
  );
};
const Root = () => {
  const { pathname } = useLocation();
  const fetchLang = resourceGrabber.grab("lang", "auth") === "fr" ? "fr" : "de";
  useEffect(() => {
    // not a very clean way to handle fallback routes, but we are behind a major react-router version, so this will do until we upgrade
    if (pathname.includes("/faq/")) {
      window.location.href = `https://help.casasoft.ch/hc/${fetchLang}/signin?return_to=https%3A%2F%2Fhelp.casasoft.ch%2Fhc%2F${fetchLang}`;
    }
    if (["/", "/login"].includes(pathname)) {
      history.push("/dash");
    }
  }, [pathname, fetchLang]);

  // initialize onboarding tours
  useCasaoneTours();

  return (
    <>
      <PageNotificationBanners />
      <App>
        <Suspense fallback={<Fallback />}>
          <Route
            path="/admin-super"
            render={(routerProps) => {
              return <Crm {...routerProps} />;
            }}
          />
          <Route
            path="/media"
            render={(routerProps) => {
              return <Media {...routerProps} />;
            }}
          />

          <Route
            path={[
              `/${BASE_DATA_PROPERTY}`,
              `/${BASE_DATA_UNIT}`,
              `/${BASE_DATA_PROJECT}`,
            ]}
            render={() => <PropertyRoot />}
          />
          <Route
            path={[
              `/publication`,
              ...PUBLICATION_TYPES.map((aPubType) => `/${aPubType}/list`),
            ]}
            render={() => <PublicationRoot />}
          />
          <Route
            path={["/contact", "/contact/event"]}
            render={() => {
              return <ContactRoot />;
            }}
          />
          <Route
            path="/event"
            render={(routerProps) => {
              return <Event {...routerProps} />;
            }}
          />
          <Route
            path="/plan"
            render={(routerProps) => {
              return <Plan {...routerProps} />;
            }}
          />
          <Route
            path="/reports"
            render={(routerProps) => {
              return <Report {...routerProps} />;
            }}
          />
          <Route
            path="/message"
            render={(routerProps) => {
              return <Message {...routerProps} />;
            }}
          />
          <Route
            path="/job"
            render={(routerProps) => {
              return <JobRoot />;
            }}
          />
          <Route
            path="/admin"
            render={(routerProps) => {
              return <Admin {...routerProps} />;
            }}
          />
          <Route
            path="/dash"
            render={(routerProps) => {
              return <Dash {...routerProps} />;
            }}
          />
          <Route
            path="/invoices"
            render={() => {
              return <InvoicesRoot />;
            }}
          />
        </Suspense>
      </App>
    </>
  );
};

export default Root;
