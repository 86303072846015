import { CasaoneResultDescription } from "api/types";
import {
  GetContactListReducedQueryParams,
  GetContactListReducedResponse,
} from "./types";

const providesTags: CasaoneResultDescription<
  GetContactListReducedResponse,
  GetContactListReducedQueryParams
> = (result) => {
  if (result) {
    const contactTags = result.items.map((item) => ({
      type: "Contact" as const,
      id: item.id,
    }));

    return [
      ...contactTags,
      {
        type: "Contact",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["Contact"];
};

export default providesTags;
