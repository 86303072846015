import Switch from "@casasoft/styleguide/components/formElements/Switch";
import { useTranslation } from "react-i18next";

interface MessageSendContactLinkControlProps {
  value: boolean;
  onToggle?: (isOn: boolean) => void;
}

export const MessageSendContactLinkControl = ({
  onToggle,
  value,
}: MessageSendContactLinkControlProps) => {
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-items-center">
      {/* All the form elements and even helper form elements have external spacing defined (which is wrong) so they are very hard to style 
      that's why there are negative margins here (cannot use label on element because it has hardcoded font size). */}
      <Switch
        className="tw-w-auto tw-mr-[-8px] tw-mb-0"
        onToggle={onToggle}
        value={value ? "checked" : "unchecked"}
      />
      <span>{t("Send access link to contact")}</span>
    </div>
  );
};
