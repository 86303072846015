import Select from "@casasoft/styleguide/components/formElements/Select";
import { useTranslation } from "react-i18next";
import {
  MessageTemplateSelectValueType,
  useMessageTemplates,
} from "./helpers/useMessageTemplates";

interface MessageTemplateContainerProps {
  value: MessageTemplateSelectValueType;
  onChange: (value: MessageTemplateSelectValueType) => void;
  className?: string;
}

export const MessageTemplateContainer = ({
  value,
  onChange,
  className,
}: MessageTemplateContainerProps) => {
  const { t } = useTranslation();
  const { loadOptions, onTemplateChange, templateValue, error } =
    useMessageTemplates({
      value,
      onChange,
    });

  return (
    <Select
      nobox
      isClearable
      disabled={!!error}
      value={templateValue}
      label={t("Template")}
      placeholder={t("Choose Template")}
      defaultOptions
      loadOptionsDebounce={300}
      loadOptions={loadOptions}
      isSearchable
      isAsync
      onChange={onTemplateChange}
      className={className}
    />
  );
};
