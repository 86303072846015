import { lazy, Component, Suspense } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import csMoment from "utilities/csMoment";
import {
  Categories,
  MarketingMethods,
  SearchprofileStatuses,
  Utilities,
  Features,
} from "utilities";
import {
  CardBodyCols,
  CardBodyColsItem,
} from "@casasoft/styleguide/components/card";

// lazy import because it contains turf
const MultipolygonImg = lazy(() =>
  import("components/miscellaneous/MultipolygonImg")
);

const Fallback = () => {
  return <span>Loading ...</span>;
};

class ContactSearchprofilesExcerpt extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  static __renderNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  }

  __grabBaseData(entity) {
    const baseData = [];
    if (entity) {
      baseData.push({
        key: "marketingMethod",
        label: this.props.t("Marketing Method"),
        value: entity.marketingMethod
          ? MarketingMethods.getLabel(entity.marketingMethod)
          : null,
      });
      baseData.push({
        key: "utilities",
        label: this.props.t("Utilities"),
        value: Utilities.getLabelListFromEntity(entity),
      });
      baseData.push({
        key: "categories",
        label: this.props.t("standards:Categories"),
        value: Categories.getLabelListFromEntity(entity),
      });

      baseData.push({
        key: "features",
        label: this.props.t("Property features"),
        value: Features.getLabelListFromEntity(entity),
      });

      const statusValue = entity.status
        ? SearchprofileStatuses.getLabel(entity.status)
        : "";
      baseData.push({
        key: "status",
        label: this.props.t("standards:Status"),
        value: statusValue,
      });

      baseData.push({
        key: "price",
        label: this.props.t("Price"),
        value: `${
          entity.priceMin !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.priceMin)
            : ""
        } – ${
          entity.priceMax !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.priceMax)
            : ""
        }`,
      });
      baseData.push({
        key: "rooms",
        label: this.props.t("standards:Rooms"),
        value: `${entity.roomMin !== null ? entity.roomMin : ""} – ${
          entity.roomMax !== null ? entity.roomMax : ""
        }`,
      });
      baseData.push({
        key: "areaGsf",
        label: this.props.t("standards:Property land area"),
        value: `${
          entity.areaGsfMin !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.areaGsfMin)
            : ""
        } – ${
          entity.areaGsfMax !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.areaGsfMax)
            : ""
        }`,
      });
      baseData.push({
        key: "areaSiaNf",
        label: this.props.t("standards:Floorspace"),
        value: `${
          entity.areaSiaNfMin !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.areaSiaNfMin)
            : ""
        } – ${
          entity.areaSiaNfMax !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.areaSiaNfMax)
            : ""
        }`,
      });
      baseData.push({
        key: "areaBwfNwf",
        label: this.props.t("Living area"),
        value: `${
          entity.areaBwfNwfMin !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.areaBwfNwfMin)
            : ""
        } – ${
          entity.areaBwfNwfMax !== null
            ? ContactSearchprofilesExcerpt.__renderNumber(entity.areaBwfNwfMax)
            : ""
        }`,
      });
      if (!this.props.contactAccessKey) {
        baseData.push({
          key: "created",
          label: this.props.t("Created"),
          value: csMoment(entity.created.date).format("DD.MM.YYYY"),
        });
        if (entity.created.date !== entity.updated.date) {
          baseData.push({
            key: "updated",
            label: this.props.t("Updated"),
            value: csMoment(entity.updated.date).format("DD.MM.YYYY"),
          });
        }
      }
      baseData.push({
        key: "notes",
        label: this.props.t("Notes"),
        value: entity.notes,
      });
    }
    return baseData;
  }

  render() {
    const points = this.__grabBaseData(this.props.entity);
    let map = null;
    if (this.props.entity && this.props.entity.polygon) {
      map = (
        <Suspense fallback={<Fallback />}>
          <MultipolygonImg multipolygon={this.props.entity.polygon} />
        </Suspense>
      );
    }
    return (
      <div className="tw-grid md:tw-grid-cols-2 tw-gap-4">
        {map && <div className="tw-min-w-0">{map}</div>}
        <div className={`tw-min-w-0 ${!map ? "tw-col-span-full" : ""}`}>
          <CardBodyCols columns={map ? 2 : 3}>
            {points.map((point) => {
              return (
                <CardBodyColsItem
                  key={point.key}
                  title={point.label}
                  span={point.key === "notes" ? "full" : undefined}
                >
                  {point.value ? point.value : "-"}
                </CardBodyColsItem>
              );
            })}
          </CardBodyCols>
        </div>
      </div>
    );
  }
}

ContactSearchprofilesExcerpt.propTypes = {
  entity: PropTypes.object.isRequired,
  contactAccessKey: PropTypes.string,
};

ContactSearchprofilesExcerpt.defaultProps = {};

export default withTranslation()(ContactSearchprofilesExcerpt);
