import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "construction-loan",
    label: "Construction loan",
  },
  {
    value: "fixed-rate-mortgage",
    label: "Fixed-rate mortgage",
  },
  {
    value: "libor-mortgage",
    label: "Libor mortgage",
  },
  {
    value: "nna",
    label: "NNA",
  },
  {
    value: "variable-rate-mortgage",
    label: "Variable-rate mortgage",
  },
];

export const getMortgageProduct = createGetList(untranslatedList);
export const getMortgageProductLabel = createGetLabel(untranslatedList);

export const useMortgageProducts = createUtilityHook(untranslatedList);
