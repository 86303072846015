import SelectAsync, {
  SelectAsyncOverrideProps,
  SelectAsyncFormGroupProps,
} from "@casasoft/styleguide/components/formElements/SelectAsync";
import { useTranslation } from "react-i18next";
import { GroupBase } from "reactSelectNew";
import {
  useRecipientSelect,
  type RecipientSelectValueType,
} from "./helpers/useRecipientSelect";
import { ReducedContactShape } from "components/contact/features/contact-excerpt/ContactExcerpt";

type RecipientSelectContainerProps = {
  label: string;
  value?: RecipientSelectValueType[];
  onChange: (
    value: RecipientSelectValueType[],
    contact: ReducedContactShape[]
  ) => void;
  className?: string;
} & SelectAsyncFormGroupProps &
  SelectAsyncOverrideProps<
    RecipientSelectValueType,
    true,
    GroupBase<RecipientSelectValueType>
  >;

export const RecipientSelectContainer = ({
  label,
  className,
  onChange,
  selectAsyncOverride,
  ...selectAsyncProps
}: RecipientSelectContainerProps) => {
  const { t } = useTranslation();

  const { formatOptionLabel, handleChange, loadOptions, noOptionsMessage } =
    useRecipientSelect({
      value: selectAsyncProps.value,
      onChange,
    });

  return (
    <SelectAsync
      {...selectAsyncProps}
      {...selectAsyncOverride}
      placeholder={t("Enter search term")}
      isSearchable
      className={className}
      noOptionsMessage={noOptionsMessage}
      loadOptionsDebounce={300}
      label={label}
      isMulti={true}
      formatOptionLabel={formatOptionLabel}
      defaultOptions
      onChange={handleChange}
      loadOptions={loadOptions}
    />
  );
};
