import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const baseList = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
  {
    value: "reference",
    label: "Reference",
  },
  {
    value: "reserved",
    label: "Reserved",
  },
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "private",
    label: "Private",
  },
  {
    value: "in-acquisition",
    label: "In acquisition",
  },
];

function getTakenListItem(marketingMethod?: string) {
  return {
    value: "taken", // Verkauft / Vermietet
    label:
      marketingMethod === "rent"
        ? "Rented"
        : marketingMethod === "buy"
        ? "Sold"
        : "Taken",
  };
}

function listConstructor(marketingMethod?: string) {
  const taken = getTakenListItem(marketingMethod);
  return [...baseList, taken];
}

export const getPropertyStatus = createGetList(listConstructor, {
  sort: "a-z",
});
export const getPropertyStatusLabel = createGetLabel(listConstructor);

export const usePropertyStatuses = createUtilityHook(listConstructor, {
  sort: "a-z",
});
