import Select from "@casasoft/styleguide/components/formElements/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import useEventTypes from "hooks/useEventTypes";
import { type FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface SelectActionContainerProps {
  values: {
    eventType: string | undefined;
    eventBaseType: string | undefined;
  };
  changeHandlers: {
    eventType: (value: string | undefined) => void;
    eventBaseType: (value: string | undefined) => void;
  };
  onChangeSideEffect: (value: string) => void;
  errors: FieldErrors<{ eventBaseType: string }>;
}

export const SelectActionContainer = ({
  values,
  changeHandlers,
  onChangeSideEffect,
  errors,
}: SelectActionContainerProps) => {
  const { t } = useTranslation();
  const { getSingleItem, getSingleItemLabel, getGroupedEventTypesList } =
    useEventTypes();

  const handleChange = (value: string) => {
    const template = getSingleItem({ value });

    // TODO: Review if and why we need to use "useEventTypes" hook (lot of mysterious logic there)
    if (value) {
      onChangeSideEffect(getSingleItemLabel({ value }));
    }

    changeHandlers.eventBaseType(template?.baseType || undefined);
    changeHandlers.eventType(template?.eventType || undefined);
  };

  return (
    <Select
      nobox
      required
      isClearable
      label={t("Interaction")}
      placeholder={t("Choose Action")}
      value={values.eventType || values.eventBaseType || undefined}
      options={getGroupedEventTypesList({
        context: "message-outbound",
      })}
      onChange={handleChange}
      message={{
        type: "error",
        text: <ErrorMessage errors={errors} name="eventBaseType" />,
      }}
      renderOption={(option) => {
        return (
          <span>
            <FontAwesomeIcon
              className="tw-mr-2"
              fixedWidth
              icon={option.icon}
            />
            {option.label}
          </span>
        );
      }}
      renderValue={(option) => {
        return (
          <span>
            <FontAwesomeIcon
              className="tw-mr-2"
              fixedWidth
              icon={option.icon}
            />
            {option.label}
          </span>
        );
      }}
    />
  );
};
