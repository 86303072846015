import { Countries } from "utilities";
import Config from "config";
import rgbToHex from "@casasoft/styleguide/utilities/theming/rgbToHex";
import { AddressShape } from "api/entities";
import { CompanyShape } from "api/entities/csiamCompany/types";

// Mapbox URL parameters
const zoom = "17";
const mapHeight = "200";
const mapWidth = "300";

// Mapbox access token
const accessToken =
  "pk.eyJ1IjoiY2FzYXNvZnQiLCJhIjoiY2lzbmZ2c3prMDAxMjJ4bWVyNGJlam5tNSJ9.w0h1a48d-lFAniCbSGvBLQ";

// Mapbox URL
const basemap = `${Config.mapboxUrl}/styles/v1/mapbox/streets-v11/static/`;

// Check if the device is retina
const retina =
  window.devicePixelRatio && window.devicePixelRatio > 1 ? "@2x" : "";

//Getting the primnary color
const pinColorModernRGB = window
  .getComputedStyle(document.documentElement)
  .getPropertyValue("--tw-cs-accent-color")
  .trim()
  .split(" ");

/**
 * Checks if an address object is empty.
 * @param address - The address object to check.
 * @returns A boolean indicating whether the address is empty or not.
 */
export function isEmpty(
  address?: Pick<
    AddressShape,
    | "street"
    | "streetNumber"
    | "streetAddition"
    | "lat"
    | "lng"
    | "postalCode"
    | "region"
    | "locality"
    | "country"
  >
) {
  if (!address) {
    return true;
  }

  return (
    !address.street &&
    !address.streetNumber &&
    !address.streetAddition &&
    !address.lat &&
    !address.lng &&
    !address.postalCode &&
    !address.region &&
    !address.locality &&
    !address.country
  );
}

/**
 * Renders the address based on the provided parameters.
 *
 * @param address - The address object containing the address details.
 * @param plain - A boolean indicating whether to return the address as plain text or JSX.
 * @param divider - The string used to separate lines in the plain text address.
 * @param renderCountry - A boolean indicating whether to render the country in the address.
 * @param renderRegion - A boolean indicating whether to render the region in the address.
 * @returns The rendered address as either plain text or JSX.
 */
export function renderAddress(
  address?: CompanyShape["address"] | null,
  plain = false,
  divider = "\n",
  renderCountry = true,
  renderRegion = false
) {
  if (!address) {
    return null;
  }
  const lines = [];
  const street = address.street ? address.street : null;
  const streetNumber = address.streetNumber ? address.streetNumber : null;
  if (plain && street && !address?.hideExactAddress) {
    lines.push(streetNumber ? `${street} ${streetNumber}` : `${street}`);
  } else if (!plain && street && address?.hideExactAddress) {
    lines.push(
      <s className="tw-text-cs-shade-500">
        {streetNumber ? `${street} ${streetNumber}` : `${street}`}
      </s>
    );
  } else if (!plain && street) {
    lines.push(streetNumber ? `${street} ${streetNumber}` : `${street}`);
  }
  if (address.postOfficeBoxNumber) {
    lines.push(
      address.postOfficeBoxNumber ? address.postOfficeBoxNumber : null
    );
  }
  const postalCode = address.postalCode ? address.postalCode : null;
  const region = address.region ? address.region : null;

  let locality = address.locality ? address.locality : null;
  if (
    region &&
    renderRegion &&
    locality &&
    locality.search(new RegExp(region, "g")) === -1
  ) {
    locality = `${locality} - ${region}`;
  }
  if (locality) {
    lines.push(postalCode ? `${postalCode} ${locality}` : `${locality}`);
  }

  if (renderCountry) {
    lines.push(
      address.country ? Countries.getCountryLabel(address.country) : null
    );
  }
  if (plain) {
    return lines.join(divider);
  }
  return (
    <div>
      {lines.map((line, i) => {
        return <div key={`${i}${line}`}>{line}</div>;
      })}
    </div>
  );
}

/**
 * Builds the Mapbox URL for a given address.
 * @param address - The address object containing latitude and longitude.
 * @returns The Mapbox URL.
 */
export function buildMapboxUrl(address?: CompanyShape["address"]) {
  if (address?.lat && address.lng) {
    const pinColorModern = rgbToHex(
      parseInt(pinColorModernRGB[0] || "0"),
      parseInt(pinColorModernRGB[1] || "0"),
      parseInt(pinColorModernRGB[2] || "0")
    ).replace("#", "");

    const pin = `pin-l+${pinColorModern || "6c757d"}(${address.lng},${
      address.lat
    })`;

    return `${basemap}${pin}/${address.lng},${address.lat},${zoom}/${mapWidth}x${mapHeight}${retina}?access_token=${accessToken}`;
  }
  return "";
}
