import { Notification } from "@casasoft/styleguide/components/notification";
import { useTranslation } from "react-i18next";

export const IaziNoResultsWarning = () => {
  const { t } = useTranslation();

  return (
    <Notification
      colorType="warning"
      showIcon
      showCloseButton={false}
      text={t(
        "IAZI/CIFI AI could not found a match under this address. Please review the address details to try again or continue without AI assistance."
      )}
    />
  );
};
