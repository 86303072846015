import { createResource } from "redux-rest-resource";
import Config from "config";
import queryBuilder from "../../utilities/queryBuilder";
import PropertyContactState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: PropertyContactState,
    actions?: any
  ) => PropertyContactState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "dynamicPropertyContact",
  pluralName: "dynamicPropertyContacts",
  url: `${Config.apiUrl}/${Config.customerKey}/property-contact/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        const newState = Object.assign({}, state);
        const items = state.items.map((item: any) => {
          if (item.id === action.context.id) {
            return action.context;
          }
          return item;
        });
        newState.items = items;
        newState.lastUpdated = Date.now();
        return newState;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.property_contact) {
          return { res, body: res.body._embedded.property_contact };
        }
        // needed after this changes: https://bitbucket.org/casasoftag/casaone/pull-requests/2744
        // when using fields filters response shape change
        if (res.body.items) {
          return { res, body: res.body.items };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts, context }: any) => {
        const cnxtOpts = Object.assign(
          {},
          {
            pageSize: 20,
            filter: [],
            orderBy: [],
          },
          contextOpts
        );
        const opts = Object.assign(
          {},
          {
            pageSize: 50,
            orderBy: [
              {
                type: "field",
                field: "created",
                direction: "desc",
              },
            ],
            filter: [],
          },
          {
            pageSize: cnxtOpts.pageSize,
            orderBy: [...cnxtOpts.orderBy],
            filter: [...cnxtOpts.filter],
          }
        );

        opts.filter.push({
          where: "and",
          field: "property",
          type: "isnotnull",
        });

        opts.filter.push({
          type: "innerjoin",
          field: "contact",
          alias: "contact",
        });

        opts.filter.push({
          type: "orx",
          conditions: [
            {
              type: "neq",
              alias: "contact",
              field: "status",
              value: "ignore",
            },
            {
              alias: "contact",
              type: "isnull",
              field: "status",
            },
          ],
          where: "and",
        });

        const query = queryBuilder.build(opts.orderBy, opts.filter, {
          pagesize: opts.pageSize,
          page: contextOpts.page,
          context: "property-contact-list",
          ...context,
        });
        return query;
      },
    },
  },
});
