export interface ServiceItem {
  label: string;
  provider: string;
  product: string;
  value: string;
  info?: string[];
  group?: string;
  options: {
    label: string;
    duration: number;
    amount: number;
    price: number;
    currency: "CHF";
    value: string;
    locationReport: boolean;
    group: string;
    verified?: boolean;
    variants: number;
    info?: string[];
  }[];
}

export const untranslatedServiceList: ServiceItem[] = [
  {
    label: "IAZI Valuation Service",
    provider: "iazi",
    product: "valuation-service",
    value: "iazi-valuation-service",
    info: [
      "One valuation request includes 4 variants per property and counts as 1 credit",
    ],
    group: "forPurchase",
    options: [
      {
        label: "Free request",
        duration: 356,
        amount: 1,
        price: 0,
        currency: "CHF",
        value: "single-free-request",
        locationReport: false,
        group: "free",
        verified: true,
        variants: 4,
      },
      {
        label: "Single request",
        duration: 365,
        amount: 1,
        price: 300,
        currency: "CHF",
        value: "single-request",
        locationReport: false,
        group: "single",
        verified: true,
        variants: 4,
      },
      {
        label: "Bronze package",
        duration: 365,
        amount: 5,
        price: 1050,
        currency: "CHF",
        value: "bronce-package",
        locationReport: false,
        group: "package",
        variants: 4,
      },
      {
        label: "Silver package",
        info: ["10-reports"],
        duration: 365,
        amount: 10,
        price: 1800,
        currency: "CHF",
        value: "silver-package",
        locationReport: true,
        group: "package",
        variants: 4,
      },
      {
        label: "Gold package",
        info: ["30-reports"],
        duration: 365,
        amount: 30,
        price: 3600,
        currency: "CHF",
        value: "gold-package",
        locationReport: true, // specific to this location/property
        group: "package",
        variants: 4,
      },
      {
        label: "Platinum package",
        info: ["50-reports"],
        duration: 365,
        amount: 50,
        price: 5500,
        currency: "CHF",
        value: "platinum-package",
        locationReport: true, // specific to this location/property
        group: "package",
        variants: 4,
      },
      {
        label: "Diamond package",
        info: ["200-reports"],
        duration: 365,
        amount: 200,
        price: 7500,
        currency: "CHF",
        value: "diamond-package",
        locationReport: true, // specific to this location/property
        group: "package",
        variants: 4,
      },
    ],
  },
  {
    label: "IAZI Location Report Service",
    provider: "iazi",
    product: "location-report-service",
    value: "iazi-location-report-service", // combination of provider and product
    info: [
      "A request for a location costs 1 credit.",
      "The report can be downloaded in multiple languages.",
    ],
    group: "forPurchase",
    options: [
      {
        label: "Free request",
        duration: 365,
        amount: 1,
        price: 0,
        currency: "CHF",
        value: "single-free-request",
        locationReport: false,
        group: "free",
        verified: true,
        variants: 1,
      },
      {
        label: "Single request",
        info: ["Request counts for the location not the property"],
        duration: 365,
        amount: 1,
        price: 150,
        currency: "CHF",
        value: "single-request",
        locationReport: false,
        group: "single",
        verified: true,
        variants: 1,
      },
      {
        label: "Silver package",
        info: ["10-requests-4-variants"],
        duration: 365,
        amount: 10,
        price: 1800,
        currency: "CHF",
        value: "silver-package",
        locationReport: true,
        group: "package",
        variants: 1,
      },
      {
        label: "Gold package",
        info: ["30-requests-4-variants"],
        duration: 365,
        amount: 30,
        price: 3600,
        currency: "CHF",
        value: "gold-package",
        locationReport: true,
        group: "package",
        variants: 1,
      },
      {
        label: "Platinum package",
        info: ["50-requests-4-variants"],
        duration: 365,
        amount: 50,
        price: 5500,
        currency: "CHF",
        value: "platinum-package",
        locationReport: true,
        group: "package",
        variants: 1,
      },
      {
        label: "Diamond package",
        info: ["200-requests-4-variants"],
        duration: 365,
        amount: 200,
        price: 7500,
        currency: "CHF",
        value: "diamond-package",
        locationReport: true,
        group: "package",
        variants: 1,
      },
    ],
  },
  {
    label: "IAZI Macro Text Service",
    provider: "iazi",
    product: "location-macro-text-service",
    value: "iazi-location-macro-text-service", // combination of provider and product
    options: [],
  },
];
