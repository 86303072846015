import { UserSortToQuerySortFn } from "hooks/table-list/useTableListQuery";

const userSortToQuerySort: UserSortToQuerySortFn = (sortConf) => {
  switch (sortConf.key) {
    case "price":
      return [
        {
          type: "field",
          field: "marketingMethod",
          direction: sortConf.dir === "asc" ? "desc" : "asc",
        },
        {
          type: "field",
          field: "price",
          direction: sortConf.dir,
        },
        {
          type: "field",
          field: "grossPrice",
          direction: sortConf.dir,
        },
        {
          type: "field",
          field: "netPrice",
          direction: sortConf.dir,
        },
      ];
    default:
      throw new Error(`Invalid sort. Trying to sort by: ${sortConf.key}`);
  }
};

export default userSortToQuerySort;
