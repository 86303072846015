import * as React from "react";
import {
  components as ReactSelectComponent,
  type DropdownIndicatorProps,
} from "react-select";

interface Props {
  props: DropdownIndicatorProps<any, any>;
}

const SelectCustomDropDownIndicator: React.FC<Props> = ({ props }: Props) => {
  return (
    ReactSelectComponent.DropdownIndicator && (
      <ReactSelectComponent.DropdownIndicator {...props}>
        <svg width="15px" height="15px" viewBox="0 0 20 20" version="1.1">
          <g
            id="carrot"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
          >
            <polyline points="2.11313338 6.88686662 10.1131334 14.8868666 18.1131334 6.88686662"></polyline>
          </g>
        </svg>
      </ReactSelectComponent.DropdownIndicator>
    )
  );
};

export default SelectCustomDropDownIndicator;
