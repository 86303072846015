import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Cancelled",
    value: "neutralisiert",
  },
];

export const getInvoiceStatuses = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLabel = createGetLabel(untranslatedList);

export const useInvoiceStatuses = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
