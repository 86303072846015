import { forwardRef, ImgHTMLAttributes } from "react";

interface ImageThumbnailProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "className" | "alt"> {
  alt: string;
  containImage?: boolean;
}

const ImageThumbnail = forwardRef<HTMLImageElement, ImageThumbnailProps>(
  function (
    { alt, containImage = false, ...imgProps }: ImageThumbnailProps,
    ref
  ) {
    return (
      <img
        {...imgProps}
        alt={alt}
        className={`tw-w-full tw-border-solid tw-border tw-border-cs-shade-200 tw-rounded tw-p-1 ${
          containImage ? "tw-object-contain" : "tw-object-cover"
        } tw-max-h-80`}
        ref={ref}
      />
    );
  }
);

export default ImageThumbnail;
