import Tinymce from "@casasoft/styleguide/components/formElements/tinymce/Tinymce";
import { useTranslation } from "react-i18next";
import { addMessageTemplateTags } from "../../helpers/addMessageTemplateTags";
import { tinymceManager } from "@casasoft/styleguide/components/formElements/tinymce/tinymceManager";

interface MessageBodyEditProps {
  value: string | undefined;
  onChange: (newValue: string | undefined) => void;
  onBlur?: () => void;
  isToolbarDisabled?: boolean;
  error?: string;
}

export const MessageBodyEdit = ({
  value,
  onChange,
  onBlur,
  error,
}: MessageBodyEditProps) => {
  const { t } = useTranslation();

  return (
    <Tinymce
      required
      id={"message-body"}
      label={t("Message")}
      value={value}
      placeholder=""
      onChange={onChange}
      onBlur={onBlur}
      rows={6}
      tinymceConfig={{
        menu: {},
        menubar: false,
        browser_spellcheck: true,
        statusbar: false,
        inline: true,
        default_link_target: "_blank",
        link_assume_external_targets: true,
        target_list: false,
        rel_list: [{ title: "nofollow", value: "nofollow" }],
        quickbars_insert_toolbar: false,
        toolbar: tinymceManager.getToolbar({
          toolbar: "message",
        }),
        setup: (editor) => {
          addMessageTemplateTags({ editor, translator: t });
        },
      }}
      message={{
        type: "error",
        text: error,
      }}
    />
  );
};
