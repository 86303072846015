import { getLanguageShortLabel } from "utilities/languages";
import type { PropertyDossierAttachment } from "./helpers/propertyAttachment.model";

interface PropertyAttachmentItemLanguageProps {
  attachment: PropertyDossierAttachment;
}

export const PropertyAttachmentItemLanguage = ({
  attachment,
}: PropertyAttachmentItemLanguageProps) => {
  const label = `(${getLanguageShortLabel(attachment.language)})`;

  return attachment.language ? <div className="tw-ml-3">{label}</div> : null;
};
