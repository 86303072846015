import React, { useState, ReactNode, useCallback } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "../modal";

import { Spinner } from "../helpers-ux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import Button from "../forms/Button";
import { Tooltip } from "../tooltip";
import PortalEventBarrier from "../helpers/PortalEventBarrier";
import ApiErrorModal from "../helpers-ux/ApiErrorModal";
import {
  ApiErrorDefinition,
  isApiErrorDefinition,
} from "@casasoft/styleguide/utilities/api-error/apiErrorDefinitions";
import { FormModalOnFail } from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import { useTranslation } from "react-i18next";

interface ErrorMessage {
  text: string;
  type: "error" | "success" | "info";
}

export interface PreventCloseModalProps {
  isOpen: boolean;
  onAccept: () => void;
  onCancel?: () => void;
}

export interface FormProps {
  onReady: (bool: boolean) => void;
  save: boolean;
  onFail: FormModalOnFail;
  onDone: (result?: any) => void | Promise<void>;
  onLoadStart: () => void;
  onLoadEnd: () => void;
}

interface FormModalProps {
  /**
   * The main render method. You will recieve FormProps as an argument and then you should return the form.
   * Use the FormProps to propagate events up to the modal. Such as onFail, onDone, etc
   * */
  form: (props: FormProps) => ReactNode;
  /** Controlls the with of the Modal. Accepts three sizes */
  size?: "md" | "sm" | "lg";
  /** Controlls the open state. Currently only this pattern is supported, without an uncontrolled pattern */
  isOpen?: boolean;
  /** The event that gets fired when a user presses the "x" or outside the modal */
  onCancel?: () => void;
  /** The event that you fire yourself (through FormProps), usually when onSubmit completes without any errors */
  onDone?: (result?: any) => void;
  /** Deprecated! This would render a ModalFooter with a save button. You should do this inside the form render prop where you have more control over the ModalFooter by rendering it yourself */
  saveButtonText?: string;
  /** Deprecated! This would render inside the ModalFooter provided by the deprecated saveButtonText, as the scondary action. You should render your own ModalFooter inside the main render method */
  altButtonNode?: ReactNode;
  /** The title of the modal */
  header?: string;
  /** Disable closing modal when clicking outside of it and display provided modal instead */
  AccidentalCloseModal?: React.FC<PreventCloseModalProps>;
}

interface CaptureUnmountProps {
  children: ReactNode;
  onWillUnmount: (bodyRef: React.RefObject<HTMLDivElement>) => void;
}

class CaptureUnmount extends React.Component<CaptureUnmountProps> {
  private myRef: React.RefObject<HTMLDivElement>;

  constructor(props: CaptureUnmountProps) {
    super(props);
    this.myRef = React.createRef();
  }

  componentWillUnmount() {
    this.props.onWillUnmount(this.myRef);
  }

  render() {
    return <div ref={this.myRef}>{this.props.children}</div>;
  }
}

const FormModal = ({
  form,
  altButtonNode,
  size = "md",
  isOpen = false,
  onCancel,
  onDone,
  saveButtonText,
  header = "",
  AccidentalCloseModal,
}: FormModalProps) => {
  const { t } = useTranslation();

  const [formBodyHtmlClone, setFormBodyHtmlClone] = useState<string>();
  const [preventCloseModalOpen, setPreventCloseModalOpen] = useState(false);

  const [readyToSave, setReadyToSave] = useState(false);
  const [saveRequestInProgress, setSaveRequestInProgress] = useState(false);
  const [shaking, setShaking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<{
    /** old legacy error messages */
    legacyMessages?: ErrorMessage[];
    /** new api error definition */
    apiError?: ApiErrorDefinition;
    /** specific context for the api error */
    apiErrorContext?: string;
  }>();
  const [showApiError, setShowApiError] = useState(false);

  const shake = useCallback(() => {
    setShaking(true);
    setTimeout(() => {
      setShaking(false);
    }, 810);
  }, []);

  const formProps: FormProps = {
    onReady: (bool) => {
      setReadyToSave(bool);
    },
    save: saveRequestInProgress,
    onFail: (failReason, apiErrorContext) => {
      // result
      setSaveRequestInProgress(false);
      shake();

      if (isApiErrorDefinition(failReason)) {
        setFormError({
          apiError: failReason,
          apiErrorContext,
        });
      } else {
        if (failReason) {
          const errArray = Array.isArray(failReason)
            ? failReason
            : [failReason];

          setFormError({ legacyMessages: errArray });
        } else {
          setFormError(undefined);
        }
      }
    },
    onDone: (result) => {
      setFormError(undefined);
      setSaveRequestInProgress(false);
      onDone?.(result);
    },
    onLoadStart: () => {
      setLoading(true);
    },
    onLoadEnd: () => {
      setLoading(false);
    },
  };

  return (
    <PortalEventBarrier>
      <Modal
        isShaking={shaking}
        size={size}
        isOpen={isOpen}
        onClose={() => {
          if (!loading) {
            onCancel?.();
          }
        }}
        onOutsideClick={(e) => {
          if (AccidentalCloseModal) {
            e.preventDefault();
            setPreventCloseModalOpen(true);
          }
        }}
      >
        <ModalHeader>
          {!!formError?.legacyMessages?.length && (
            <Tooltip
              content={formError.legacyMessages.map((formError, i, arr) => {
                const divider = i < arr.length - 1 && <br />;
                if (!formError.text) {
                  return null;
                }
                return (
                  <span key={i}>
                    {typeof formError.text === "string" ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: formError.text }}
                      />
                    ) : (
                      formError.text
                    )}
                    {divider}
                  </span>
                );
              })}
            >
              <span
                style={{ paddingRight: "0.5em" }}
                className={`${
                  formError.legacyMessages.findIndex(
                    (err) => err.type === "error"
                  ) !== -1
                    ? "tw-text-cs-danger"
                    : formError.legacyMessages.findIndex(
                        (err) => err.type === "info"
                      ) !== -1
                    ? "tw-text-cs-info"
                    : formError.legacyMessages.findIndex(
                        (err) => err.type === "success"
                      ) !== -1
                    ? "tw-text-cs-success"
                    : "tw-text-cs-danger"
                }`}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </span>
            </Tooltip>
          )}
          {formError?.apiError && (
            <Tooltip
              content={t("Click icon to show error details")}
              triggerAsChild
            >
              <button
                className="tw-p-0 tw-pr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setShowApiError(true);
                }}
              >
                <span className="tw-text-cs-danger">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
              </button>
            </Tooltip>
          )}
          {header}
        </ModalHeader>
        <ModalBody>
          {!isOpen ? (
            <div
              className="spinner-fixture"
              dangerouslySetInnerHTML={
                formBodyHtmlClone ? { __html: formBodyHtmlClone } : undefined
              }
            />
          ) : (
            <CaptureUnmount
              onWillUnmount={(bodyRef) => {
                setFormBodyHtmlClone(bodyRef.current?.innerHTML);
              }}
            >
              {loading ? (
                <div
                  className="spinner-fixture"
                  style={{
                    pointerEvents: "none",
                    opacity: 0.5,
                  }}
                >
                  {form(formProps)}
                  <Spinner />
                </div>
              ) : (
                form(formProps)
              )}
            </CaptureUnmount>
          )}
        </ModalBody>
        {(saveButtonText || altButtonNode) && (
          <ModalFooter>
            {altButtonNode}
            {saveButtonText && readyToSave && (
              <Button
                disabled={saveRequestInProgress}
                isPrimary
                onClick={() => {
                  setSaveRequestInProgress(true);
                }}
              >
                {saveButtonText}
              </Button>
            )}
          </ModalFooter>
        )}
      </Modal>
      <ApiErrorModal
        context={formError?.apiErrorContext || "form modal"}
        error={formError?.apiError}
        open={showApiError}
        onOpenChange={(open) => {
          setShowApiError(open);
        }}
      />
      {!!AccidentalCloseModal && (
        <AccidentalCloseModal
          isOpen={preventCloseModalOpen}
          onCancel={() => {
            setPreventCloseModalOpen(false);
          }}
          onAccept={() => {
            onCancel?.();
            setPreventCloseModalOpen(false);
          }}
        />
      )}
    </PortalEventBarrier>
  );
};

export default FormModal;
