import { CasaoneResultDescription } from "api/types";
import {
  MutateContactListResponse,
  MutateContactListQueryParams,
} from "./types";

const invalidatesTags: CasaoneResultDescription<
  MutateContactListResponse,
  MutateContactListQueryParams
> = (result) => {
  if (result) {
    const contactTags = result._embedded.contact.map((item) => ({
      type: "Contact" as const,
      id: item.id,
    }));

    return [...contactTags, { type: "ContactTag" }];
  }

  return ["Contact"];
};

export default invalidatesTags;
