import { useTranslation } from "react-i18next";
import { type AlertItem } from "./models";
import { useAppSelector } from "redux/hooks";
import { selectContactLang } from "redux/auth/selectors";

// TODO: Remove this function after the maintenance work is done
const getAnnouncementLink = (lang: string): string => {
  switch (lang) {
    case "fr":
      return "https://casasoft.ch/fr/maintenance-performance";
    case "it":
      return "https://casasoft.ch/it/manutenzione-performance";
    default:
      return "https://casasoft.ch/wartungsarbeiten-performance";
  }
};

export const useMaintenanceWarning = () => {
  const { t } = useTranslation();
  const activeUntil = new Date("2024-06-17");
  const userLanguage = useAppSelector(selectContactLang);

  const maintenanceWarning: AlertItem | undefined =
    activeUntil > new Date()
      ? {
          colorType: "warning",
          cookieId: "cs_maintenance_2024-06-17",
          onClick: () => {
            window.open(getAnnouncementLink(userLanguage), "_blank");
          },
          text: ` ${t(
            "Announcement of maintenance work{{dp}} CASAONE will be partially unavailable this weekend (Saturday/Sunday). The reason for this is maintenance work on our databases. We kindly ask for your understanding. Show details{{dp}} {{link}}",
            { dp: ":", link: getAnnouncementLink(userLanguage) }
          )}`,
        }
      : undefined;

  return {
    maintenanceWarning,
  };
};
