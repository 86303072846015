import { createResource } from "redux-rest-resource";
import Config from "config";
import CgProviderPublisherSettingsState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: CgProviderPublisherSettingsState,
    actions?: any
  ) => CgProviderPublisherSettingsState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "cgProviderPublisherSettings",
  pluralName: "cgProviderPublisherSettings",
  url: `${Config.apiUrl}/${Config.customerKey}/cg-provider-publisher-settings/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (!state.items) {
          return state;
        }
        const newState = Object.assign({}, state);
        const items = state.items.map((item: any) => {
          if (item.id === action.context.id) {
            return action.context;
          }
          return item;
        });
        newState.items = items;
        newState.lastUpdated = Date.now();
        return newState;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (
          res.body._embedded &&
          res.body._embedded.provider_publisher_settings
        ) {
          return { res, body: res.body._embedded.provider_publisher_settings };
        }
        return { res, body: [] };
      },
      query: () => {
        // casaauth does not use the hydrator its manuell from here on
        const query: { [key: string]: any } = {};

        return query;
      },
    },
  },
});
