import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Employee",
    value: "employee",
  },
  {
    label: "Executive",
    value: "executive",
  },
  {
    label: "With responsibilities",
    value: "with-responsibilities",
  },
];

export const getJobPositions = createGetList(untranslatedList);
export const getLabel = createGetLabel(untranslatedList);

export const useJobPositions = createUtilityHook(untranslatedList);
