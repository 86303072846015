import { ReactNode } from "react";

interface CardBodyColsProps {
  children: ReactNode;
  onClick?: () => void;
  columns?: 3 | 2;
}

function CardBodyCols({ children, onClick, columns = 3 }: CardBodyColsProps) {
  return (
    <div
      className={`tw-grid tw-gap-3 tw-text-sm
        ${columns === 3 ? "lg:tw-grid-cols-3" : ""} 
        ${columns === 2 ? "lg:tw-grid-cols-2" : ""}  
        ${onClick ? "tw-cursor-pointer" : ""}
      `}
      role={onClick ? "button" : undefined}
      onClick={() => {
        onClick?.();
      }}
    >
      {children}
    </div>
  );
}

export default CardBodyCols;
