import { useState, useEffect, useRef } from "react";

/**
 * @param value - ⚠️ value must be a optimized variable or a basic type (string/number/etc). (useState value, useRef, etc)
 */
export default function useDebounce<T1>(
  value: T1,
  delay: number,
  onDebounceChange?: (val: T1) => void
) {
  // state and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState({
    value: value,
    invalidate: 0,
  });

  // ref to keep onDebounceChange in bounds
  const readyToFireEventHandle = useRef(false);
  const isFirstRenderRef = useRef(true);

  // reffify delay so useEffect doesn't have to be dependent on it
  const delayRef = useRef(delay);
  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);
  const debouncedValueRef = useRef(debouncedValue.value);
  useEffect(() => {
    debouncedValueRef.current = debouncedValue.value;
  }, [debouncedValue.value]);

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
    } else {
      const handler = setTimeout(() => {
        if (debouncedValueRef.current !== value) {
          // signify that the handler is ready to be called
          readyToFireEventHandle.current = true;

          setDebouncedValue((prev) => ({
            value,
            invalidate: prev.invalidate + 1,
          }));
        }
      }, delayRef.current);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  useEffect(() => {
    // make sure to not call the onDelayChange on every render, since it is a method passed as a dependency
    if (readyToFireEventHandle.current) {
      readyToFireEventHandle.current = false;
      onDebounceChange?.(debouncedValue.value);
    }
  }, [onDebounceChange, debouncedValue, value]);

  const typedArr: [typeof debouncedValue] = [debouncedValue];

  return typedArr;
}
