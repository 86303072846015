import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseEventCreator, { EventResourceTypes } from "./baseEventCreator";

const name = "dynamicEvent" as const;
const pluralName = "dynamicEvents" as const;

export const { types, actions, rootReducer }: EventResourceTypes =
  createResource(
    baseEventCreator({
      name,
      pluralName,
      apiContext: "event-list",
      forcedFilter: [
        {
          type: "notin",
          field: "type",
          values: ["reminder", "contact-merge"],
        },
        {
          type: "orx",
          conditions: [
            {
              type: "neq",
              field: "doneReason",
              value: "ignore",
              where: "and",
            },
            {
              type: "isnull",
              field: "doneReason",
            },
          ],
          where: "and",
        },
      ],
    })
  );

const dynamicEventResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const dynamicEventResource = dynamicEventResourceCreator.resource;
export const useDynamicEvent = dynamicEventResourceCreator.createHook();
