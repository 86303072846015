import { PointsShape } from "@casasoft/styleguide/utilities/differ";
import CardBodyColsItem from "./CardBodyColsItem";

interface CardPointsColsItemsProps {
  /** array of points to display */
  points: PointsShape[];
}

function CardPointsColsItems({ points }: CardPointsColsItemsProps) {
  return (
    <>
      {points.map((point) => {
        return (
          <CardBodyColsItem key={point.key} title={point.label}>
            {point.renderedValue || point.value || "-"}
          </CardBodyColsItem>
        );
      })}
    </>
  );
}

export default CardPointsColsItems;
