import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { clearAuthTokens } from "utilities/auth";
import { AnchorButton } from "@casasoft/styleguide/components/forms/Button";

const Logout = () => {
  const { t } = useTranslation();
  const hasMounted = useRef(false);
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      const asyncWrapper = async () => {
        try {
          await clearAuthTokens();
        } catch (error) {
          // throw new Error("Logout failed without a known reason");
        }
      };
      asyncWrapper();
    }
  }, []);
  return (
    <div className="outside__content__form">
      <div>
        <h1 className="display-4 tw-mb-2">{t("Logout")}</h1>
        <div className="tw-mb-12">{t("You have been logged out.")}</div>
        <div className="tw-w-full tw-text-center">
          <AnchorButton href="/login" isPrimary isBlock>
            {t("Go to Login Page")}
          </AnchorButton>
        </div>
      </div>
    </div>
  );
};

export default Logout;
