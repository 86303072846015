import Button from "@casasoft/styleguide/components/forms/Button";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface MessageMediaFormFooterProps {
  onAddButtonClick: () => void;
}

export const MessageMediaFormFooter = ({
  onAddButtonClick,
}: MessageMediaFormFooterProps) => {
  const { t } = useTranslation();

  return (
    <ModalFooter>
      <Button
        buttonValue={
          <span>
            <FontAwesomeIcon icon={faPlusCircle} className="tw-mr-2" />
            {t("Save")}
          </span>
        }
        isPrimary
        onClick={onAddButtonClick}
      />
    </ModalFooter>
  );
};
