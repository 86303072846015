import { type CleanSelectOption } from "@casasoft/styleguide/components/formElements/Select";
import { type IaziCommonDataProperty } from "api/entities/iazi/endpoints/get-common-data/types";
import { useTranslation } from "react-i18next";

export const useIaziDataMapper = () => {
  const { t } = useTranslation();

  const mapIaziDataToOptions = (
    iaziData: IaziCommonDataProperty[]
  ): CleanSelectOption[] => {
    return iaziData.map((property) => {
      const labelParts = [];

      if (typeof property.floor !== "undefined") {
        labelParts.push(`${t("Floor")} ${property.floor}`);
      }
      if (typeof property.numberOfRooms !== "undefined") {
        labelParts.push(`${t("Number of rooms")} ${property.numberOfRooms}`);
      }
      if (typeof property.areaBwf !== "undefined" && property.areaBwf > 0) {
        labelParts.push(`${property.areaBwf} m2`);
      }
      labelParts.push(`${t("Property")} ${property.ewid}`);

      const label = labelParts.join(", ");
      const value = property.uid;

      return { label, value };
    });
  };

  return { mapIaziDataToOptions };
};
