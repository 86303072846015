import { createResource } from "redux-rest-resource";
import InvoicesShape from "../caInvoices/types";
import createResourceHelper from "utilities/createResourceHelper";
import createBaseActions from "entities/createBaseActions";
import Config from "config";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: InvoicesShape, actions?: any) => InvoicesShape;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "caInvoiceCrm",
  pluralName: "caInvoicesCrm",
  url: `${Config.api2Url}/crm/invoice?hasInvoiceFile=true`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "invoice",
      customQuery: (getState: any, { contextOpts }: any) => {
        const cnxtOpts = Object.assign(
          {},
          {
            pageSize: 20,
            filter: [],
            orderBy: [],
          },
          contextOpts
        );
        const opts = Object.assign(
          {},
          {
            pageSize: 50,
            orderBy: [
              {
                type: "order_by",
                field: "id",
                direction: "desc",
              },
            ],
            filter: [] as any[],
          },
          {
            pageSize: cnxtOpts.pageSize,
            orderBy: [...cnxtOpts.orderBy],
            filter: [...cnxtOpts.filter],
          }
        );
        // casainvoice does not use the hydrator its manuell from here on
        const query: { [key: string]: any } = {};
        if (opts?.orderBy && opts.orderBy.length) {
          const order_by = opts.orderBy[0].field ? opts.orderBy[0].field : "id";
          const order_dir = opts.orderBy[0].direction
            ? opts.orderBy[0].direction
            : "desc";
          query[`order[${order_by}]`] = order_dir;
        }
        // searchfilter
        const searchFilter = opts.filter.find((item) => item.type === "search");
        if (searchFilter) {
          query.q = encodeURIComponent(searchFilter.value);
        }
        // pagesize = page_size
        const pageSizeFilter = opts.pageSize;
        if (pageSizeFilter) {
          query.page_size = pageSizeFilter;
        }
        // status
        const statusFilter = opts.filter.find(
          (item) => item.field === "status"
        );
        if (statusFilter && statusFilter.values) {
          query.status = statusFilter.values.join(",");
        }
        // page number
        if (cnxtOpts.page) {
          query.page = cnxtOpts.page;
        }
        // company
        if (cnxtOpts.filterString) {
          const urlParams = new URLSearchParams(cnxtOpts.filterString);
          query.companyId = urlParams.get("companyId");
        }
        return query;
      },
    }),
  },
});

const caInvoiceCrmResourceCreator = createResourceHelper({
  actions,
  name: "caInvoiceCrm",
  pluralName: "caInvoicesCrm",
});

export const caInvoiceCrmResource = caInvoiceCrmResourceCreator.resource;
export const useCaInvoicesCrm = caInvoiceCrmResourceCreator.createHook();
