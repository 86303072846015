import { createResource } from "redux-rest-resource";
import Config from "config";
import PropertyContentDescriptionState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: PropertyContentDescriptionState,
    actions?: any
  ) => PropertyContentDescriptionState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "propertyContentDescription",
  pluralName: "propertyContentDescriptions",
  url: `${Config.apiUrl}/${Config.customerKey}/property-content-description/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
