import { Spinner } from "@casasoft/styleguide/components/helpers-ux";

const LoadingScreen = () => {
  return (
    <div>
      <div className="tw-text-center tw-mt-12">
        <svg
          className="drawable-logo"
          width="239px"
          height="47px"
          viewBox="0 0 252 45.4"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="draw"
            fill="currentColor"
            d="M34.9,37.1c0,0-4,4.5-12.8,4.5c-11.8,0-19.3-8.4-19.3-18.9c0-10.4,7.4-18.9,19.3-18.9c8.8,0,12.8,4.5,12.8,4.5 l-4.2,5.6c0,0-3.7-3.1-8.5-3.1c-6.9,0-11.8,5-11.8,11.9s4.9,11.9,11.8,11.9c5.5,0,8.5-3.1,8.5-3.1L34.9,37.1z"
          />
          <path
            className="draw"
            fill="currentColor"
            d="M55.1,4.5h1.3L75,40.8h-8.3l-2.4-4.9H47.1l-2.3,4.9h-8.3L55.1,4.5z M55.9,17.8h-0.5L50,29.6l-0.4,0.8h12.3 l-0.4-0.8L55.9,17.8z"
          />
          <path
            className="draw"
            fill="currentColor"
            d="M81,31.3c0,0,4.8,3.5,10.7,3.5c4.4,0,6.2-1.8,6.2-4c0-2.4-1.6-3.6-7.8-5.3c-8.2-2.3-12.5-5.2-12.5-11 c0-6.5,4.8-10.7,14.1-10.7c9.7,0,13.7,3.7,13.7,3.7l-3.9,6c0,0-3.9-3-9.8-3c-4,0-5.9,1.4-5.9,3.6s1.9,3.1,7.5,4.7 c8.9,2.4,12.9,5.5,12.9,11.3c0,6.3-4.4,11.4-14.3,11.4c-10,0-14.8-4.6-14.8-4.6L81,31.3z" // eslint-disable-line max-len
          />
          <path
            className="draw"
            fill="currentColor"
            d="M126.6,4.5h1.3l18.6,36.2h-8.3l-2.4-4.9h-17.3l-2.3,4.9H108L126.6,4.5z M127.4,17.8h-0.5l-5.5,11.8l-0.4,0.8 h12.3l-0.4-0.8L127.4,17.8z"
          />
          <path
            className="draw"
            fill="currentColor"
            d="M145.9,22.9c0-10.7,7.2-18.9,18.4-18.9s18.4,8.2,18.4,18.9c0,10.6-7.3,18.7-18.4,18.7S145.9,33.5,145.9,22.9z M180,22.9c0-8.9-5.8-16.4-15.7-16.4s-15.7,7.5-15.7,16.4c0,8.7,5.8,16.2,15.7,16.2S180,31.6,180,22.9z"
          />
          <path
            className="draw"
            fill="currentColor"
            d="M218.4,40.8h-0.9l-27.1-30.2v30.2h-2.6v-36h0.9l27.1,30.1V4.8h2.6V40.8z"
          />
          <path
            className="draw"
            fill="currentColor"
            d="M246.7,24.2h-20.2v14.1h23v2.5h-25.6v-36h25.6v2.5h-23v14.5h20.2V24.2z"
          />
        </svg>
      </div>
      <Spinner />
    </div>
  );
};

export default LoadingScreen;
