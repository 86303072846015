import { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
} from "@casasoft/styleguide/components/card";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import resourceGrabber from "utilities/resourceGrabber";
import { withTranslation } from "react-i18next";
import AddressEditForm from "./AddressEditForm";
import CreateForm from "components/miscellaneous/address/AddressCreateForm";
import resourceHelper from "utilities/resource";
import Config from "config";
import { AddressHelper } from "utilities";
import Button from "@casasoft/styleguide/components/forms/Button";
import handleFormModalError from "@casasoft/styleguide/utilities/api-error/handleFormModalError";

class AddressCard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.defaultState;
    this.__toggleModal = this.__toggleModal.bind(this);
  }

  defaultState = {
    working: false,
    modalOpen: false,
    formIsNotReady: false,
    acceptDelete: false,
    submitAddressData: null,
    invalidPublisher: [],
  };

  __toggleModal() {
    this.setState({
      modalOpen: true,
    });
  }

  __headerTitle() {
    if (this.props.headerTitle) {
      return this.props.headerTitle;
    }
    return this.props.t("Address Details");
  }

  render() {
    const entity = this.props.entity;
    const mapboxUrl = AddressHelper.buildMapboxUrl(entity);
    const cardText = AddressHelper.renderAddress(entity);
    const contractOptions = resourceGrabber.grab("contractOptions", "auth");
    return (
      <>
        <div
          style={
            this.state.working ? { opacity: 0.5, pointerEvents: "none" } : {}
          }
        >
          <Card>
            <CardHeader
              actions={[
                {
                  icon: faEdit,
                  onClick: () => {
                    this.__toggleModal();
                  },
                },
              ]}
            >
              {this.__headerTitle()}
            </CardHeader>
            <CardBody onClick={this.__toggleModal} isSmall>
              {entity ? (
                <div>
                  {entity.lat && !entity.hideExactAddress && (
                    <div
                      style={{
                        float: "left",
                        marginRight: "1rem",
                        width: "12rem",
                        height: "6rem",
                        textAlign: "center",
                        backgroundColor: "gray",
                        color: "white",
                        backgroundImage: `url("${mapboxUrl}")`,
                        backgroundSize: "cover",
                        position: "relative",
                      }}
                    >
                      {contractOptions &&
                        contractOptions.optionalModules &&
                        contractOptions.optionalModules.regionModule &&
                        entity._embedded &&
                        entity._embedded.placeMunicipality &&
                        entity._embedded.placeMunicipality.casageoId && (
                          <span
                            style={{
                              position: "absolute",
                              bottom: "0",
                              left: "0",
                              background: "rgba(255, 255, 255, 0.7)",
                              height: "initial",
                              width: 30,
                              lineHeight: "initial",
                              padding: "5px 5px",
                              boxSizing: "border-box",
                            }}
                            className="tw-text-cs-shade-500"
                          >
                            <a
                              href={`/admin/places/list/item/${entity._embedded.placeMunicipality.id}`}
                              role="button"
                            >
                              <img
                                width="100%"
                                alt="Gemeinde Wappen"
                                src={`${Config.casageoUrl}/coa/${entity._embedded.placeMunicipality.casageoId}`}
                              />
                            </a>
                          </span>
                        )}
                    </div>
                  )}
                  {cardText}
                  {entity.elevation && (
                    <div>
                      {this.props.t("Altitude:", { nsSeparator: "::" })}{" "}
                      {Intl.NumberFormat("de-CH", { style: "decimal" }).format(
                        entity.elevation
                      )}{" "}
                      {this.props.t("m a.s.l.")}
                    </div>
                  )}
                </div>
              ) : (
                <Button isBlock isPrimary isOutline>
                  + {this.props.t("Create Address")}
                </Button>
              )}
            </CardBody>
          </Card>
          {entity ? (
            <FormModal
              isOpen={this.state.modalOpen}
              onDone={(body) => {
                this.setState({ modalOpen: false });
                this.props.onAfterUpdate(body);
              }}
              form={(props) => {
                return (
                  <AddressEditForm
                    onSubmit={async (data) => {
                      const submit = async () => {
                        try {
                          const response = await resourceHelper.updateItem(
                            this.props.storeName, // default: addresses, alternative: addressesWithAccesskey
                            {
                              ...data,
                              id: entity.id,
                              contactAccessKey:
                                this.props.contactAccessKey || undefined,
                            }
                          );
                          props.onDone(response.body);
                          return true;
                        } catch (error) {
                          handleFormModalError(error, props.onFail);
                        }
                      };
                      return await submit();
                    }}
                    pinUpdateAddress={this.props.pinUpdateAddress}
                    forEntity={this.props.for}
                    postOfficeBoxField={this.props.postOfficeBoxField}
                    address={{
                      locality: entity.locality,
                      postalCode: entity.postalCode,
                      street: entity.street,
                      streetNumber: entity.streetNumber,
                      lat: entity.lat && parseFloat(entity.lat),
                      lng: entity.lng && parseFloat(entity.lng),
                      hideExactAddress: entity.hideExactAddress
                        ? entity.hideExactAddress
                        : false,
                      postOfficeBoxNumber: entity.postOfficeBoxNumber,
                      country: entity.country,
                      region: entity.region,
                    }}
                  />
                );
              }}
              onCancel={() => {
                this.setState({ modalOpen: false });
              }}
              header={this.props.t("Edit Address")}
              size="md"
            />
          ) : (
            <FormModal
              isOpen={this.state.modalOpen}
              onDone={(body) => {
                this.setState({ modalOpen: false });
                this.props.onAfterCreate?.(body);
              }}
              form={(props) => {
                return (
                  <CreateForm
                    {...props}
                    pinUpdateAddress={this.props.pinUpdateAddress}
                    storeName={this.props.storeName}
                    contactAccessKey={this.props.contactAccessKey}
                    postOfficeBoxField={this.props.postOfficeBoxField}
                  />
                );
              }}
              onCancel={() => {
                this.setState({ modalOpen: false });
              }}
              header={this.props.t("Create Address")}
              size="md"
              saveButtonText={this.props.t("Save")}
            />
          )}
        </div>
      </>
    );
  }
}

AddressCard.propTypes = {
  entity: PropTypes.object,
  onAfterCreate: PropTypes.func,
  onAfterUpdate: PropTypes.func,
  storeName: PropTypes.string,
  contactAccessKey: PropTypes.string,
  // onAfterRemove: PropTypes.func,
  pinUpdateAddress: PropTypes.bool,
  for: PropTypes.string,
  postOfficeBoxField: PropTypes.bool,
};

AddressCard.defaultProps = {
  entity: {},
  onAfterUpdate: () => {
    this.setState({ working: false });
  },
  storeName: "addresses",
  contactAccessKey: null,
  // onAfterRemove: () => {},
  pinUpdateAddress: false,
  for: null,
  postOfficeBoxField: false,
};

export default withTranslation()(AddressCard);
