import { casaoneApi } from "api";
import getMessagePreviewSingleQuery from "./endpoints/single/query";
import getMessagePreviewSingleProvidesTags from "./endpoints/single/providesTags";

const messagePreviewApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getMessagePreviewSingle: builder.query({
      query: getMessagePreviewSingleQuery,
      providesTags: getMessagePreviewSingleProvidesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMessagePreviewSingleQuery,
  useLazyGetMessagePreviewSingleQuery,
} = messagePreviewApi;
