import { FilterFormProps } from "components/miscellaneous/FilterForm";
import useSubsidiaryFilterFormOptions from "components/miscellaneous/subsidiary/hooks/useSubsidiaryFilterFormOptions";
import { useTranslation } from "react-i18next";
import { Categories, PropertyStatuses, Utilities } from "utilities";
import { BASE_DATA_PROPERTY } from "utilities/propertyTypes";

interface UseFilterFormOptionsArgs {
  mini: boolean;
  propertyType: string;
}
function useFilterFormOptions({
  mini,
  propertyType,
}: UseFilterFormOptionsArgs) {
  const { t } = useTranslation();
  const subsidiaryOptions = useSubsidiaryFilterFormOptions();

  if (mini) {
    return;
  }

  const filterFormOptions: FilterFormProps["filterFormOptions"] = {
    label: t("Create filter"),
    preset: {
      tags: {
        name: "tags",
        label: t("Tags"),
        value: null,
      },
      marketingMethod: {
        name: "marketingMethod",
        label: t("Marketing Method"),
        renderer: (terms) => {
          switch (terms[0]) {
            case "rent":
              return t("Rent");
            case "buy":
              return t("Buy");
            default:
              return terms[0];
          }
        },
        value: null,
      },
      salesPersons: {
        name: "salesPersons",
        label: t("User"),
        value: null,
      },
      subsidiaries: {
        name: "subsidiaries",
        label: t("Provider"),
        value: null,
        renderer: (value) => {
          if (value) {
            if (!!subsidiaryOptions) {
              const returnNodes = subsidiaryOptions
                ?.filter((x) => value.includes(x.value))
                .map((item) => {
                  return item.sortValue;
                });
              return returnNodes.join(", ");
            }
          }
          return "";
        },
      },
      statuses: {
        name: "statuses",
        label: t("Status"),
        renderer: (terms) =>
          terms
            .map((term) => PropertyStatuses.getPropertyStatusLabel(term))
            .join(", "),
        value: null,
      },
      roomsFrom: {
        name: "roomsFrom",
        label: t("Rooms from"),
        value: null,
      },
      roomsTo: {
        name: "roomsTo",
        label: t("Rooms to"),
        value: null,
      },
    },
  };
  if (propertyType === BASE_DATA_PROPERTY) {
    filterFormOptions.preset.utilities = {
      name: "utilities",
      label: t("Utilities"),
      value: null,
      renderer: (value) => {
        if (value && value[0]) {
          return value[0]
            .split(",")
            .map((util) => Utilities.getUtilityLabel(util))
            .join(", ");
        }
        return "";
      },
    };
    filterFormOptions.preset.categories = {
      name: "categories",
      label: t("standards:Categories"),
      value: null,
      renderer: (value) => {
        if (value && value[0]) {
          return value[0]
            .split(",")
            .map((cat) => Categories.getLabel(cat))
            .join(", ");
        }
        return "";
      },
    };
  }

  return filterFormOptions;
}

export default useFilterFormOptions;
