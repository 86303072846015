import { PreventCloseModalProps } from "@casasoft/styleguide/components/form-modal/FormModal";
import Button from "@casasoft/styleguide/components/forms/Button";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "@casasoft/styleguide/components/modal";
import { useTranslation } from "react-i18next";

interface MessageModalCloseDialogProps extends PreventCloseModalProps {}

export const MessageModalCloseDialog = ({
  isOpen,
  onAccept,
  onCancel,
}: MessageModalCloseDialogProps) => {
  const { t } = useTranslation();
  const title = t("Close message") + "?";

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalHeader>
        <span className="tw-font-bold tw-text-cs-primary">{title}</span>
      </ModalHeader>
      <ModalBody>{t("Your entries will not be saved")}</ModalBody>
      <ModalFooter>
        <Button isSecondary onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button isPrimary onClick={onAccept}>
          {t("Close message")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
