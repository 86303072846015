import { useTranslation } from "react-i18next";
import { SendingMessage } from "./SendingMessage";
import {
  Card,
  CardBody,
  CardHeader,
} from "@casasoft/styleguide/components/card";
import { type ReducedContactShape } from "components/contact/features/contact-excerpt/ContactExcerpt";
import { type EmailContact } from "api/entities/event/endpoints/create-single/types";

export interface SentMessageMetaData {
  contactSimple?: EmailContact;
  contactFull?: ReducedContactShape;
  error?: string;
}

interface SendingMessageContainerProps {
  messageCount: number;
  sendCanceled: boolean;
  sentMessages: SentMessageMetaData[];
  className?: string;
}

export const SendingMessageContainer = ({
  className,
  ...rest
}: SendingMessageContainerProps) => {
  const { t } = useTranslation();
  const successfullMessages = rest.sentMessages.filter(
    (mesg) => !mesg.error
  ).length;
  const failedMessages = rest.sentMessages.filter(
    (mesg) => !!mesg.error
  ).length;

  return (
    <Card className={className}>
      <CardHeader>{t("Sending messages ...")}</CardHeader>
      <CardBody>
        <SendingMessage
          {...rest}
          failedMessages={failedMessages}
          successfullMessages={successfullMessages}
        />
      </CardBody>
    </Card>
  );
};
