import HighlightElement from "boarding.js/dist/core/highlight-element";

type BoardingStartEventDetails = {
  id: string;
  element: HighlightElement;
};
export type BoardingStartEvent = CustomEvent<BoardingStartEventDetails>;

/**
 * Dispatch the BoardingStartEvent custom event
 */
export function dispatchBoardingStartEvent(
  uniqueId: string,
  element: HighlightElement
) {
  const startEvent = new CustomEvent<BoardingStartEventDetails>(
    "BoardingStartEvent",
    {
      detail: { id: uniqueId, element },
    }
  );
  document.dispatchEvent(startEvent);
}
