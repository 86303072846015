import PriceField from "@casasoft/styleguide/components/forms/PriceField";
import { useTranslation } from "react-i18next";

interface PropertyGrossPriceFieldProps {
  grossPrice?: string | number | null;
  currency?: string | null;
  rentPricePropertySegment?: string | null;
  rentPriceTimeSegment?: string | null;
  onChange?: (name: string, value: string) => void;
  onMultiFieldChange: (field: { name: string; value: string }[]) => void;
  onBlur: (name: any, value: string) => void;
  message: any;
  id?: string;
  nobox?: boolean;
  required?: boolean;
}

const PropertyGrossPriceField = ({
  grossPrice,
  currency = "CHF",
  rentPricePropertySegment = "",
  rentPriceTimeSegment = "",
  onChange,
  onMultiFieldChange,
  onBlur,
  message = [],
  id = "grossPrice",
  nobox = true,
  required = false,
}: PropertyGrossPriceFieldProps) => {
  const { t } = useTranslation();

  const getCombinedSegments = (
    inputpropertySegment: string | null,
    timeSegment: string | null
  ) => {
    // dont trust the propertysegment if its not year (workaround for now!)
    const propertySegment = inputpropertySegment;

    const newCombinedSegments = [
      {
        label: ` ${t("standards:year")} `,
        id: "year",
        time: {
          value: "y",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "year",
        set: propertySegment === "all" && timeSegment === "y",
      },
      {
        label: ` ${t("standards:year")} ${t("standards:per")} ${t(
          "standards:m²"
        )} `,
        id: "year_m2",
        time: {
          value: "y",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "m2",
        },
        value: "year_m2",
        set: propertySegment === "m" && timeSegment === "y",
      },
      {
        label: ` ${t("standards:month")} `,
        id: "month",
        time: {
          value: "m",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "month",
        set:
          !propertySegment ||
          !timeSegment ||
          (propertySegment === "all" && timeSegment === "m"),
      },
      {
        label: ` ${t("standards:week")} `,
        id: "week",
        time: {
          value: "w",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "week",
        set: propertySegment === "all" && timeSegment === "w",
      },
      {
        label: ` ${t("standards:day")} `,
        id: "day",
        time: {
          value: "d",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "day",
        set: propertySegment === "all" && timeSegment === "d",
      },
    ];
    return newCombinedSegments;
  };

  const combinedSegments = getCombinedSegments(
    rentPricePropertySegment,
    rentPriceTimeSegment
  );
  const setOption = combinedSegments.filter((segment) => segment.set)[0];

  return (
    <PriceField
      nobox={nobox}
      id={id}
      prefix={currency}
      label={t("standards:Gross price")}
      combinedSegments={combinedSegments}
      name={id}
      value={grossPrice}
      placeholder={t("standards:On Request")}
      onChange={(value: any) => {
        const changeSet = [];
        if (grossPrice !== value.input.value.replace("'", "")) {
          onChange?.("grossPrice", value.input.value);
          changeSet.push({
            name: "grossPrice",
            value: value.input.value,
          });
        }
        if (value?.segments?.combinedSegment?.setOption) {
          if (
            value.segments.combinedSegment.setOption.property &&
            value.segments.combinedSegment.setOption.property.value &&
            setOption.property.value !==
              value.segments.combinedSegment.setOption.property.value
          ) {
            changeSet.push({
              name: "rentPricePropertySegment",
              value:
                value.segments.combinedSegment.setOption.property.value.replace(
                  /[0-9]/g,
                  ""
                ),
            });
          }
          if (
            value.segments.combinedSegment.setOption.time &&
            value.segments.combinedSegment.setOption.time.value &&
            setOption.time.value !==
              value.segments.combinedSegment.setOption.time.value
          ) {
            changeSet.push({
              name: "rentPriceTimeSegment",
              value: value.segments.combinedSegment.setOption.time.value,
            });
          }
          onMultiFieldChange(changeSet);
        }
      }}
      onBlur={(value: any) => {
        onBlur("grossPrice", value.input.value);
      }}
      thousandSeparator="'"
      message={message}
      required={required}
    />
  );
};

export default PropertyGrossPriceField;
