import Field from "@casasoft/styleguide/components/formElements/Field";
import Select from "@casasoft/styleguide/components/formElements/Select";
import Button from "@casasoft/styleguide/components/forms/Button";
import { ErrorMessage } from "@hookform/error-message";
import Config from "config";
import { useMainContact } from "entities/contact/mainStore";
import useForm, { RegisterFields } from "hooks/useForm";
import { history } from "config/history";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import { Genders } from "utilities";
import { hasAccessTo } from "utilities/featureFlags";
import { axiosInstance } from "utilities/axios";
import handleFormModalError, {
  FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";

interface ContactCreateFormProps {
  onDone: () => void;
  onFail: FormModalOnFail;
  preSelectedEntityType?: "individual" | "legal";
}

interface FormShape {
  entityType: string;
  gender: string | number | null;
  displayName: string | null;
  email: string | null;
  phone: string | null;
  mobile: string | null;
  tags: string | null;
}

function ContactCreateForm({
  onDone,
  onFail,
  preSelectedEntityType,
}: ContactCreateFormProps) {
  const { t } = useTranslation();

  const [tagOptions, setTagOptions] = useState<string[]>([]);

  const { createItem: createMainContactItem } = useMainContact();

  useEffect(() => {
    axiosInstance
      .get(`${Config.apiUrl}/${Config.customerKey}/get-distinct-contact-tags`)
      .then((response) => {
        if (response.status === 500) {
          throw Error(`${response.status} ${response.data.detail}`);
        }
        return response.data;
      })

      .then((response) => {
        setTagOptions(
          (response?.contactTags as any[])
            ?.map((x) => x.name)
            .sort((a, b) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            }) || []
        );
      });
  }, []);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    control,
  } = useForm<FormShape>({
    defaultValues: {
      entityType: preSelectedEntityType || "individual",
      gender: null,
      displayName: null,
      email: null,
      phone: null,
      mobile: null,
      tags: null,
    },
  });

  const onSubmit: SubmitHandler<FormShape> = async (data) => {
    try {
      const tagsMap = data?.tags?.split(",").map((tag) => ({ name: tag }));
      const createdContact = await createMainContactItem(
        {
          ...data,
          tags: tagsMap,
          address: {}, // create empty address is wanted, so there is a relation
        },
        true
      );
      if (!createdContact) {
        throw new Error("An error occured");
      }
      onDone();
      reset(data);
      history.push(`/contact/list/item/${createdContact.id}`);
    } catch (error) {
      handleFormModalError(error, onFail);
    }
  };

  const contactEntityTypeWatch = watch("entityType");
  const hideNodeForLegalContact = useCallback(
    (node: JSX.Element) => {
      if (contactEntityTypeWatch === "legal") {
        return <></>;
      }
      return node;
    },
    [contactEntityTypeWatch]
  );
  return (
    <RegisterFields
      fields={{
        entityType: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        gender: {},
        displayName: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        phone: {},
        mobile: {},
        email: {},
        tags: {},
      }}
      control={control}
      render={(fieldsRenderer) => (
        <form
          className="spinner-fixture"
          onSubmit={handleSubmit(onSubmit)}
          style={
            isSubmitting ? { opacity: 0.5, pointerEvents: "none" } : undefined
          }
        >
          {fieldsRenderer("entityType", (formValue, onFormValueChange) =>
            // only pro can create companies, otherwise within modals (for instance) the starter user would not be able to see the company anywhere.
            hasAccessTo("contactManagement") && !preSelectedEntityType ? (
              <Select
                nobox
                label={t("Entity")}
                placeholder={t("Choose entity")}
                value={formValue || undefined}
                options={[
                  {
                    label: t("Individual entity"),
                    value: "individual",
                  },
                  {
                    label: t("Legal entity"),
                    value: "legal",
                  },
                ]}
                onChange={(value) => {
                  if (!value) {
                    throw new Error("Entity type should always be set");
                  }
                  onFormValueChange(value);
                }}
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="entityType" />,
                }}
              />
            ) : (
              <></>
            )
          )}
          {fieldsRenderer("gender", (formValue, onFormValueChange) =>
            hideNodeForLegalContact(
              <Select
                nobox
                label={t("Gender")}
                placeholder={t("Select a Gender")}
                value={formValue?.toString() || undefined}
                options={Genders.getGenders()}
                onChange={(value) => {
                  onFormValueChange(value || null);
                }}
                message={{
                  type: "error",
                  text: <ErrorMessage errors={errors} name="gender" />,
                }}
              />
            )
          )}
          {fieldsRenderer("displayName", (formValue, onFormValueChange) => (
            <Field
              nobox
              required
              label={
                contactEntityTypeWatch === "legal"
                  ? t("Legal name")
                  : t("Display name")
              }
              value={formValue || ""}
              // placeholder={`${firstNameWatch || ""} ${lastNameWatch || ""}`}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="displayName" />,
              }}
            />
          ))}

          {fieldsRenderer("email", (formValue, onFormValueChange) => (
            <Field
              nobox
              label={t("E-Mail")}
              type="email"
              value={formValue || ""}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="email" />,
              }}
            />
          ))}
          {fieldsRenderer("phone", (formValue, onFormValueChange) => (
            <Field
              nobox
              type="phone"
              label={t("Phone")}
              value={formValue || ""}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="phone" />,
              }}
            />
          ))}
          {fieldsRenderer("mobile", (formValue, onFormValueChange) => (
            <Field
              nobox
              type="mobile"
              label={t("Mobile")}
              value={formValue || ""}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="mobile" />,
              }}
            />
          ))}

          {fieldsRenderer("tags", (formValue, onFormValueChange) => (
            <Select
              nobox
              isMulti
              isCreatable
              label={t("Tags")}
              placeholder={t("Tags")}
              value={formValue || undefined}
              options={tagOptions.map((x) => {
                return { value: x, label: x };
              })}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="tags" />,
              }}
            />
          ))}

          <ModalFooter>
            <Button isPrimary type="submit" buttonValue={t("Create")} />
          </ModalFooter>
        </form>
      )}
    />
  );
}

export default ContactCreateForm;
