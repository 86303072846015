import { CasaoneBaseQueryArgs } from "api/types";
import queryBuilder from "utilities/queryBuilder";
import { GetContactIdListQueryParams } from "./types";

function query({ query }: GetContactIdListQueryParams): CasaoneBaseQueryArgs {
  const builtQuery = queryBuilder.buildToUrl(
    query.orderBy,
    [
      {
        type: "neq",
        field: "status",
        value: "ignore",
      },
      {
        where: "and",
        field: "referenceContact",
        type: "isnull",
      },
      ...query.filter,
    ],
    {
      pagesize: query.pageSize,
      page: query.page,
    },
    ["id"]
  );

  return {
    url: `contact?${builtQuery}`,
    method: "GET",
  };
}

export default query;
