import Select from "@casasoft/styleguide/components/formElements/Select";
import { useTranslation } from "react-i18next";
import { UserSignatureShape } from "api/entities/userSignature/endpoints/list/types";

export type MessageSignatureOption = {
  label: string;
  value: string;
  signature: UserSignatureShape;
};

interface MessageSignatureProps {
  value: string | undefined;
  onChange: (newValue: string) => void;
  options: MessageSignatureOption[];
  isDisabled?: boolean;
}

export const MessageSignature = ({
  options,
  onChange,
  value,
  isDisabled = false,
}: MessageSignatureProps) => {
  const { t } = useTranslation();

  return (
    <Select
      nobox
      isClearable
      label={t("Add signature")}
      placeholder={t("Choose Signature")}
      value={value}
      options={options}
      onChange={onChange}
      disabled={isDisabled}
    />
  );
};
