import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "@casasoft/styleguide/components/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRetweet,
  faMinusCircle,
  faHome,
} from "@fortawesome/pro-light-svg-icons";

import { useTranslation } from "react-i18next";
import PropertyExcerpt from "./PropertyExcerpt";
import PropertyChooseModal from "./PropertyChooseModal";
import { useDynamicProperty } from "entities/dynamicProperty/store";
import { PropertyShape } from "entities/property/types";
import Button from "@casasoft/styleguide/components/forms/Button";

interface PropertyRelationCardProps {
  entity?: PropertyShape;
  entityId?: string;
  onChange?: (id: null | string, raw?: PropertyShape) => void;

  headerTitle?: string;
  contentMinHeight?: React.CSSProperties["minHeight"];
  linkTo?: boolean;
  interactions?: boolean;
}

const PropertyRelationCard = ({
  entity: entityProp,
  entityId,
  onChange,

  headerTitle = "Property",
  contentMinHeight = "6rem",
  linkTo = false,
  interactions = true,
}: PropertyRelationCardProps) => {
  const { t } = useTranslation();
  const { store: dynamicPropertiesStore, getItem: getDynamicProperty } =
    useDynamicProperty();

  const [chooseModalOpen, setChooseModalOpen] = useState(false);

  useEffect(() => {
    if (entityId) {
      getDynamicProperty({ id: entityId });
    }
  }, [entityId, getDynamicProperty]);
  const entity =
    !entityProp && entityId ? dynamicPropertiesStore.item : entityProp;

  return (
    <div
      style={
        dynamicPropertiesStore.isFetching
          ? { opacity: 0.5, pointerEvents: "none" }
          : {}
      }
    >
      <Card>
        <CardHeader
          actions={
            interactions && entity
              ? [
                  {
                    icon: faMinusCircle,
                    onClick: () => {
                      onChange?.(null);
                    },
                  },
                  {
                    icon: faRetweet,
                    onClick: () => {
                      setChooseModalOpen(true);
                    },
                  },
                ]
              : undefined
          }
        >
          <FontAwesomeIcon icon={faHome} size="1x" /> {headerTitle}
        </CardHeader>
        <CardBody hasMinHeight={contentMinHeight ? true : false} isSmall>
          {entity && (
            <PropertyExcerpt
              entity={entity}
              showThumbnail
              lines={3}
              linkTo={linkTo}
            />
          )}
          {interactions && !entity && !dynamicPropertiesStore.isFetching && (
            <Button
              isPrimary
              isOutline
              isBlock
              onClick={() => {
                setChooseModalOpen(true);
              }}
            >
              {t("Select")}
            </Button>
          )}
        </CardBody>
      </Card>

      <PropertyChooseModal
        isOpen={chooseModalOpen}
        onCancel={() => {
          setChooseModalOpen(false);
        }}
        onApply={(chosenItems, raw) => {
          onChange?.(chosenItems[0], raw?.[0]);
          setChooseModalOpen(false);
        }}
      />
    </div>
  );
};

export default PropertyRelationCard;
