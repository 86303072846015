import i18n from "./i18n";
import { AuthShape } from "redux/auth";

type Language = {
  value: string;
  locale: string;
  label: string;
  shortLabel: string;
};

const getLanguageItems = (extended = true) => {
  const SUPPORTED_LANGS: Language[] = [
    {
      value: "de",
      locale: "de-CH",
      label: i18n.t("German"),
      shortLabel: "DE",
    },
    {
      value: "fr",
      locale: "fr-CH",
      label: i18n.t("French"),
      shortLabel: "FR",
    },
    {
      value: "it",
      locale: "it-CH",
      label: i18n.t("Italian"),
      shortLabel: "IT",
    },
    {
      value: "en",
      locale: "en-US",
      label: i18n.t("English"),
      shortLabel: "EN",
    },
  ];

  const EXTENDED_LANGS = SUPPORTED_LANGS.concat({
    value: "es",
    locale: "es-ES",
    label: i18n.t("Spanish"),
    shortLabel: "ES",
  });
  const langs = extended ? EXTENDED_LANGS : SUPPORTED_LANGS;
  return langs.sort((a, b) => {
    return a.label.toString().localeCompare(b.label);
  });
};

export const getLanguages = (profile?: AuthShape["profile"]) => {
  if (profile?.data?.company?.contracts?.[0]?.options?.languages?.length) {
    const langs: string[] = profile.data.company.contracts[0].options.languages;
    return langs.map((alang) => ({
      value: alang,
      label: getLanguageLabel(alang),
      shortLabel: getLanguageShortLabel(alang),
    }));
  }
  return getLanguageItems();
};

export const getLanguageLabel = (value: string) => {
  const languages = getLanguageItems();
  return languages.find((lang) => lang.value === value)?.label || value;
};

export const getLanguageShortLabel = (value: string) => {
  const languages = getLanguageItems();
  return languages.find((lang) => lang.value === value)?.shortLabel || value;
};

export const getLanguageLabelByLocale = (locale: string) => {
  const languages = getLanguageItems();
  return languages.find((lang) => lang.locale === locale)?.shortLabel || locale;
};

export const getSystemLanguageList = (extendedSet = true): Language[] => {
  return getLanguageItems(extendedSet);
};
