import {
  GetUserContactListQueryParams,
  GetUserContactListResponse,
} from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetUserContactListResponse,
  GetUserContactListQueryParams
>((result) => {
  if (result) {
    // generate tags for each user
    const userTags = result._embedded.user.map((item) => ({
      type: "User" as const,
      id: item.username,
    }));

    const contactTags = result._embedded.user
      .map((item) =>
        item._embedded?.contact?.id
          ? {
              type: "Contact" as const,
              id: item._embedded?.contact?.id,
            }
          : undefined
      )
      .filter(Boolean) as { type: "Contact"; id: string }[];

    // merge user tags with static tags
    return [
      ...userTags,
      ...contactTags,
      {
        type: "User",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["User"];
});

export default providesTags;
