import { CSSProperties, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort as faSortLight } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "../tooltip";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { twMerge } from "tailwind-merge";
import { Item } from "./TableListItem";

export function DragHandle({
  nr = null,
  cardView,
}: {
  nr: number | null;
  cardView: boolean;
}) {
  return (
    <div
      key="sortable-handle"
      className={`tw-absolute ${
        !cardView
          ? "tw-right-4 tw-top-2/4 tw--translate-y-2/4"
          : "tw-bottom-2 tw-right-2"
      } tw-whitespace-nowrap tw-text-cs-shade-500`}
    >
      <span>{nr === null ? "" : nr + 1}</span>
      &nbsp;
      <FontAwesomeIcon fixedWidth icon={faSortLight} />
    </div>
  );
}

export function SortableItem({
  value,
  className = "",
  id,
}: {
  value: ReactNode;
  className: string;
  id: number | string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
  };
  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {value}
    </div>
  );
}

interface TableListTooltipWrapperProps {
  children: ReactNode;
  invalidated?: string | true;
}

export function TableListTooltipWrapper({
  children,
  invalidated,
}: TableListTooltipWrapperProps) {
  return (
    <Tooltip
      content={!!invalidated && invalidated !== true && invalidated}
      triggerAsChild
    >
      {children}
    </Tooltip>
  );
}

export const getItemClassName = (
  item: Item,
  cardView?: boolean,
  mini?: boolean
) => {
  return twMerge(
    cardView
      ? "tw-group tw-block tw-min-w-[300px] tw-max-w-[450px] tw-relative tw-m-1 tw-p-2 tw-bg-cs-shade-50 tw-rounded tw-flex-1 tw-border tw-border-solid tw-border-cs-shade-200"
      : "tw-group tw-flex tw-flex-nowrap tw-m-0 tw-p-0 tw-relative tw-border-0 tw-border-b last:tw-border-b-0 tw-border-solid tw-border-cs-shade-50 tw-no-underline tw-min-w-min hover:tw-bg-cs-shade-50 tw-grow",
    !cardView && !mini && "tw-px-4 tw--mx-4",
    !cardView && mini && "tw-px-3 tw--mx-3",
    item.item_active &&
      "tw-bg-cs-shade-50 tw-border-r-2 tw-border-r-tw-cs-accent-color",
    item.item_inactive && "tw-opacity-50"
  );
};
