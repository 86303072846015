import Config from "config";
import createBaseActions from "entities/createBaseActions";
import { FilterItem, OrderByItem } from "utilities/queryBuilder";
import EventState from "./types";

export interface EventResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: EventState, actions?: any) => EventState;
}

interface BaseEventCreatorArgs {
  name: string;
  pluralName: string;
  forcedFilter?: FilterItem[];
  forcedOrderBy?: OrderByItem[];
  apiContext: string;
  forcedPageSize?: number;
}

function baseEventCreator({
  name,
  pluralName,
  forcedFilter = [],
  forcedOrderBy = [],
  apiContext,
  forcedPageSize,
}: BaseEventCreatorArgs) {
  return {
    name: name,
    pluralName: pluralName,
    url: `${Config.apiUrl}/${Config.customerKey}/event/:id`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    actions: {
      ...createBaseActions({
        embeddedName: "event",
        forcedFilter: [...forcedFilter],
        forcedOrderBy: [...forcedOrderBy],
        forcedApiContext: apiContext,
        forcedPageSize: forcedPageSize,
      }),
    },
  };
}

export default baseEventCreator;
