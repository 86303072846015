import { ReactNode } from "react";

interface FilterFormColsProps {
  children: ReactNode;
}

function FilterFormCols({ children }: FilterFormColsProps) {
  return (
    <div className="tw-grid md:tw-grid-cols-6 tw-gap-4 tw-mb-4">{children}</div>
  );
}

export default FilterFormCols;
