// Actual property types - how they are stored in the DB/BACKEND
// base data
export const BASE_DATA_PROJECT = "project";
export const BASE_DATA_UNIT = "unit";
export const BASE_DATA_PROPERTY = "property";

export const BASE_DATA_TYPES = [
  BASE_DATA_PROJECT,
  BASE_DATA_UNIT,
  BASE_DATA_PROPERTY,
];
// project publications
export const PROJECT_PUBLICATION_PROJECT = "project-publication-project";
export const PROJECT_PUBLICATION_UNIT = "project-publication-unit";
export const PROJECT_PUBLICATION_PROPERTY = "project-publication-property";
// single publications
export const SINGLE_PUBLICATION_PROPERTY = "single-publication-property";
export const SINGLE_PUBLICATION_UNIT = "single-publication-unit";
export const SINGLE_PUBLICATION_PROJECT = "single-publication-project";

export const PUBLICATION_TYPES = [
  PROJECT_PUBLICATION_PROJECT,
  PROJECT_PUBLICATION_UNIT,
  PROJECT_PUBLICATION_PROPERTY,
  SINGLE_PUBLICATION_PROPERTY,
  SINGLE_PUBLICATION_UNIT,
  SINGLE_PUBLICATION_PROJECT,
];
export const PROJECT_PUBLICATION_TYPES = [
  PROJECT_PUBLICATION_PROPERTY,
  PROJECT_PUBLICATION_UNIT,
  PROJECT_PUBLICATION_PROJECT,
];
export const SINGLE_PUBLICATION_TYPES = [
  SINGLE_PUBLICATION_PROPERTY,
  SINGLE_PUBLICATION_UNIT,
  SINGLE_PUBLICATION_PROJECT,
];

// helper methods

/** method which maps singlePublicationTypes to projectPublicationTypes and vice-versa */
export const mapSingleAndProjectPublicationTypes = (
  publicationType: string
) => {
  switch (publicationType) {
    case SINGLE_PUBLICATION_PROPERTY:
      return PROJECT_PUBLICATION_PROPERTY;
    case SINGLE_PUBLICATION_UNIT:
      return PROJECT_PUBLICATION_UNIT;
    case SINGLE_PUBLICATION_PROJECT:
      return PROJECT_PUBLICATION_PROJECT;
    case PROJECT_PUBLICATION_PROPERTY:
      return SINGLE_PUBLICATION_PROPERTY;
    case PROJECT_PUBLICATION_UNIT:
      return SINGLE_PUBLICATION_UNIT;
    case PROJECT_PUBLICATION_PROJECT:
      return SINGLE_PUBLICATION_PROJECT;

    default:
      throw new Error("Unknown generic property type, or impossible to map.");
  }
};

/** method which checks whether a publication type is a single publication or project publication */
export const isSinglePublicationType = (publicationType: string) => {
  if (SINGLE_PUBLICATION_TYPES.includes(publicationType)) {
    return true;
  } else if (PROJECT_PUBLICATION_TYPES.includes(publicationType)) {
    return false;
  } else {
    throw new Error("Unknown publicationType passed.");
  }
};

/** method which converts a propertyType (i.e a single-publication-foo) to the corresponding base data type */
export const getBaseDataTypeFromPropertyType = (propertyType: string) => {
  switch (propertyType) {
    case SINGLE_PUBLICATION_PROPERTY:
    case PROJECT_PUBLICATION_PROPERTY:
    case BASE_DATA_PROPERTY:
      return BASE_DATA_PROPERTY;
    case SINGLE_PUBLICATION_UNIT:
    case PROJECT_PUBLICATION_UNIT:
    case BASE_DATA_UNIT:
      return BASE_DATA_UNIT;
    case SINGLE_PUBLICATION_PROJECT:
    case PROJECT_PUBLICATION_PROJECT:
    case BASE_DATA_PROJECT:
      return BASE_DATA_PROJECT;
    default:
      throw new Error("Unknown propertyType passed.");
  }
};
