import { PureComponent } from "react";
import PropTypes from "prop-types";

import { withTranslation } from "react-i18next";
import Switch from "@casasoft/styleguide/components/forms/Switch";
import resourceHelper from "../../utilities/resource";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
  PUBLICATION_TYPES,
} from "utilities/propertyTypes";
import { dynamicPropertyResource } from "entities/dynamicProperty/store";
import { Alert } from "@casasoft/styleguide/components/helpers-ux";

class PropertyRemoveForm extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      yes: false,
      messages: null,
      dirtyFields: [],
      data: this.defaultData,
      resource: null,
      id: null,
      children: [],
      publications: [],
      propertyContacts: [],
      events: [],
      propertyReports: [],
    };
    this.props.onReady?.(false);
  }

  componentDidMount() {
    // Avatar

    // children
    let childStoreName = null;
    switch (this.props.propertyType) {
      case BASE_DATA_PROJECT:
        childStoreName = "units";
        break;
      case BASE_DATA_UNIT:
        childStoreName = "properties";
        break;
      default:
        childStoreName = null;
        break;
    }
    if (childStoreName) {
      resourceHelper
        .fetchList(
          childStoreName,
          [
            {
              type: "eq",
              field: "parent",
              value: this.props.id,
              where: "and",
            },
          ],
          [
            {
              type: "field",
              field: "id",
              direction: "asc",
            },
          ],
          30,
          true
        )
        .then((result) => {
          if (
            result &&
            result.body &&
            result.body._embedded &&
            result.body._embedded.property
          ) {
            this.setState({ children: result.body._embedded.property });
          }
        });
    }

    // publications
    if (!PUBLICATION_TYPES.includes(this.props.propertyType)) {
      dynamicPropertyResource
        .fetchList({
          filter: [
            {
              type: "eq",
              field: "origin",
              value: this.props.id,
              where: "and",
            },
            {
              type: "in",
              field: "propertyType",
              where: "and",
              values: PUBLICATION_TYPES,
            },
          ],
          orderBy: [
            {
              type: "field",
              field: "id",
              direction: "asc",
            },
          ],
        })
        .then((publications) => {
          this.setState({ publications: publications });
        });
    }

    // propertyContacts
    resourceHelper
      .fetchList(
        "propertyContacts",
        [
          {
            type: "eq",
            field: "property",
            value: this.props.id,
            where: "and",
          },
        ],
        [
          {
            type: "field",
            field: "id",
            direction: "asc",
          },
        ],
        30,
        true
      )
      .then((result) => {
        if (
          result &&
          result.body &&
          result.body._embedded &&
          result.body._embedded.property_contact
        ) {
          this.setState({
            propertyContacts: result.body._embedded.property_contact,
          });
        }
      });
    // events (publication events get moved to origin)
    if (!PUBLICATION_TYPES.includes(this.props.propertyType)) {
      resourceHelper
        .fetchList(
          "events",
          [
            {
              type: "eq",
              field: "property",
              value: this.props.id,
              where: "and",
            },
          ],
          [
            {
              type: "field",
              field: "id",
              direction: "asc",
            },
          ],
          30,
          true
        )
        .then((result) => {
          if (
            result &&
            result.body &&
            result.body._embedded &&
            result.body._embedded.event
          ) {
            this.setState({ events: result.body._embedded.event });
          }
        });
    }

    // propertyReports
    if (!PUBLICATION_TYPES.includes(this.props.propertyType)) {
      resourceHelper
        .fetchList(
          "propertyReports",
          [
            {
              type: "eq",
              field: "property",
              value: this.props.id,
              where: "and",
            },
          ],
          [
            {
              type: "field",
              field: "id",
              direction: "asc",
            },
          ],
          30,
          true
        )
        .then((result) => {
          if (
            result &&
            result.body &&
            result.body._embedded &&
            result.body._embedded.property_report
          ) {
            this.setState({
              propertyReports: result.body._embedded.property_report,
            });
          }
        });
    }
  }

  __handleSubmit() {
    if (this.state.yes) {
      this.props.onDone?.();
    } else {
      this.props.onFail?.();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.save !== prevProps.save && this.props.save === true) {
      this.__handleSubmit();
    }
  }
  __grabRemoveText() {
    let removeText = "";
    switch (this.props.propertyType) {
      case BASE_DATA_PROPERTY:
        removeText = this.props.t(
          "Are you sure you want to delete this property?"
        );
        break;
      case BASE_DATA_UNIT:
        removeText = this.props.t("Are you sure you want to delete this unit?");
        break;
      case BASE_DATA_PROJECT:
        removeText = this.props.t(
          "Are you sure you want to delete this project?"
        );
        break;
      default:
        removeText = this.props.t(
          "Are you sure you want to delete this property?"
        );
        break;
    }
    if (PUBLICATION_TYPES.includes(this.props.propertyType)) {
      removeText = this.props.t(
        "Are you sure you want to delete this publication?"
      );
    }
    return removeText;
  }

  render() {
    return (
      <form
        onSubmit={() => {
          this.__handleSubmit();
        }}
        style={
          this.state.working ? { opacity: 0.5, pointerchildren: "none" } : {}
        }
      >
        <p className="lead">
          {this.props.message}
          {!this.props.message && this.__grabRemoveText()}
        </p>
        {this.state.children.length > 0 && (
          <Alert color="danger">
            {this.props.t(
              "There are at least {{count}} {{connectionNames}}. These will be lost. Please ensure that you wish to delete it.",
              {
                count: this.state.children.length,
                connectionNames: this.props.t("child relations"),
              }
            )}
          </Alert>
        )}
        {this.state.publications.length > 0 && (
          <Alert color="danger">
            {this.props.t(
              "There are at least {{count}} {{connectionNames}}. These will be lost. Please ensure that you wish to delete it.",
              {
                count: this.state.publications.length,
                connectionNames: this.props.t("publications"),
              }
            )}
          </Alert>
        )}
        {this.state.propertyContacts.length > 0 && (
          <Alert color="danger">
            {this.props.t(
              "There are at least {{count}} {{connectionNames}}. These will be lost. Please ensure that you wish to delete it.",
              {
                count: this.state.propertyContacts.length,
                connectionNames: this.props.t("contact relations"),
              }
            )}
          </Alert>
        )}
        {this.state.events.length > 0 && (
          <Alert color="danger">
            {this.props.t(
              "There are at least {{count}} {{connectionNames}}. These will be lost. Please ensure that you wish to delete it.",
              {
                count: this.state.events.length,
                connectionNames: this.props.t("event relations"),
              }
            )}
          </Alert>
        )}
        {this.state.propertyReports.length > 0 && (
          <Alert color="danger">
            {this.props.t(
              "There are at least {{count}} {{connectionNames}}. These will be lost. Please ensure that you wish to delete it.",
              {
                count: this.state.propertyReports.length,
                connectionNames: this.props.t("reports"),
              }
            )}
          </Alert>
        )}
        <Switch
          type="text"
          id="yes"
          label={this.props.t("Yes")}
          checked={this.state.yes}
          onToggle={(value) => {
            this.setState({ yes: value });
            if (value === true) {
              this.props.onReady?.(true);
            }
          }}
        />
      </form>
    );
  }
}

PropertyRemoveForm.propTypes = {
  save: PropTypes.bool,
  onFail: PropTypes.func,
  onReady: PropTypes.func,
  onDone: PropTypes.func,
  message: PropTypes.string,
  id: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
};

PropertyRemoveForm.defaultProps = {
  save: false,
  message: null,
};

export default withTranslation()(PropertyRemoveForm);
