import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Config from "config";

const PageImprint = () => {
  const { t } = useTranslation();
  const { lang } = useParams<{ lang: string }>();
  let content = (
    <div className="imp">
      <address itemType="https://schema.org/Organization">
        <strong itemProp="name">Casasoft AG</strong>
        <br />
        <span itemProp="address" itemType="https://schema.org/Address">
          <span itemProp="street-address">Flurstrasse 55</span>
          <br />
          CH-8048 <span itemProp="locality">Zürich</span>
        </span>
        <br />
        <br />T <span itemProp="tel">+41 71 686 94 94</span>
        <br />
        <br />
        <a href="mailto:info@casasoft.ch">info@casasoft.ch</a>
        <br />
        <a
          href={`${Config.casasoftWebsiteUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          title="casasoft.ch"
        >
          www.casasoft.ch
        </a>
      </address>
    </div>
  );
  if (lang === "fr") {
    content = (
      <div className="imp">
        <address itemType="https://schema.org/Organization">
          <strong itemProp="name">Casasoft AG</strong>
          <br />
          <span itemProp="address" itemType="https://schema.org/Address">
            <span itemProp="street-address">Flurstrasse 55</span>
            <br />
            CH-8048 <span itemProp="locality">Zürich</span>
          </span>
          <br />
          <br />T <span itemProp="tel">+41 71 686 94 94</span>
          <br />
          <br />
          <a href="mailto:info@casasoft.ch">info@casasoft.ch</a>
          <br />
          <a
            href={`${Config.casasoftWebsiteUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            title="casasoft.ch"
          >
            www.casasoft.ch
          </a>
        </address>
      </div>
    );
  }
  return (
    <div className="outside__content__pagecontent">
      <div>
        <h1 className="display-4 tw-mb-12">{t("Imprint")}</h1>
        {content}
        <p className="tw-mb-6 tw-mt-6 tw-text-sm">
          <NavLink to={`/login/${lang}`}>{t("Login")}</NavLink>
        </p>
      </div>
    </div>
  );
};
export default PageImprint;
