import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "floor",
    label: "Floor heating",
  },
  {
    value: "wall",
    label: "Wall heating",
  },
  {
    value: "radiators",
    label: "Radiators",
  },
  {
    value: "infrared",
    label: "Infrared",
  },
];

export const getHeatDistributions = createGetList(untranslatedList, {
  sort: "a-z",
});
export const label = createGetLabel(untranslatedList);

export const useHeatDistributions = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
