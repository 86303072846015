import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";
import { categoriesList } from "./categoriesList";

const untranslatedList = categoriesList.map(([value, label]) => ({
  value,
  label,
}));

export const getCategories = (...args: any) =>
  createGetList(untranslatedList)(...args);

export const getLabel = (category: string) =>
  createGetLabel(untranslatedList)(category);

/** gets a joined string list of untranslatedList */
export function getLabelListFromEntity(entity?: {
  _embedded?: {
    categories?: {
      id?: number;
      slug: string;
      rank: number;
    }[];
  };
}) {
  if (entity?._embedded?.categories?.length) {
    const items = entity._embedded.categories.map((elem) =>
      getLabel(elem.slug)
    );
    return items.join(", ");
  }
  return "";
}

export const useCategories = (...args: any) =>
  createUtilityHook(untranslatedList)(...args);
