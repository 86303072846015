import type {
  PropertyAttachment,
  PropertyDocumentationAttachment,
} from "./propertyAttachment.model";

export const isDossierAttachment = (
  attachment: PropertyAttachment
): boolean => {
  return (
    (attachment as PropertyDocumentationAttachment).addAddress !== undefined &&
    (attachment as PropertyDocumentationAttachment).addPrice !== undefined &&
    (attachment as PropertyDocumentationAttachment).format !== undefined &&
    (attachment as PropertyDocumentationAttachment).showHeaderFooter !==
      undefined
  );
};

export const isFlyerAttachment = (attachment: PropertyAttachment): boolean => {
  return (
    isDossierAttachment(attachment) && !isDocumentationAttachment(attachment)
  );
};

export const isDocumentationAttachment = (
  attachment: PropertyAttachment
): boolean => {
  return (
    isDossierAttachment(attachment) &&
    (attachment as PropertyDocumentationAttachment)
      .addAffordabilityCalculation !== undefined
  );
};
