import { ReactNode } from "react";

interface FormModalSectionHeaderProps {
  children: ReactNode;
}

function FormModalSectionHeader({ children }: FormModalSectionHeaderProps) {
  return (
    <h3 className="tw-text-tw-cs-accent-color tw-text-base tw-font-extralight tw-py-3 tw-border-solid tw-border-0 tw-border-b-2 tw-m-0 tw-mb-4">
      {children}
    </h3>
  );
}

export default FormModalSectionHeader;
