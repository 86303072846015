import Config from "config";

function interpretRequestURLMetaData(url: string) {
  let API = "unknown-api";
  let endpoint = "unknown-endpoint";
  let endpointType = "unknown-type";

  const { pathname, hostname } = new URL(url);

  // remove leading and trailing slash
  const cleanPathname = pathname.replace(/^\//, "").replace(/$\//, "");
  const path = cleanPathname.split("/");

  // API 1
  if (url.startsWith(`${Config.apiUrl}/`)) {
    API = "API1";
    if (path[0] === Config.customerKey && path[1]) {
      endpoint = path[1];
      if (path[2]) {
        endpointType = "single";
      } else {
        endpointType = "collection";
      }
    } else {
      endpoint = path.join("/");
      endpointType = "rpc";
    }
  }
  // API 2
  else if (url.startsWith(`${Config.api2Url}/`)) {
    API = "API2";
    if (path[0] === "company" && path[1] === Config.customerKey && path[2]) {
      endpoint = path[2];
      if (path[3]) {
        endpointType = "single";
      } else {
        endpointType = "collection";
      }
    } else {
      endpoint = path.join("/");
      endpointType = "rpc";
    }
  }
  // unknown API
  else {
    endpoint = `${hostname}/${path.join("/")}`;
  }

  return `[${API}] ${endpoint} (${endpointType})`;
}

export default interpretRequestURLMetaData;
