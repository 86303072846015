import SelectAsync, {
  SelectAsyncFormGroupProps,
} from "@casasoft/styleguide/components/formElements/SelectAsync";
import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { useLazyGetUserListQuery } from "api/entities/user";
import { useTranslation } from "react-i18next";
import UserExcerpt from "../../UserExcerpt";
import { MultiValue, OnChangeValue } from "reactSelectNew";
import getArrayedSelectValue from "./helpers/getArrayedSelectValue";
import useLoadOptionsHelper from "hooks/useLoadOptionsHelper";
import { GetUserListResponse } from "api/entities/user/endpoints/list/types";

type BaseValueType = { value: string };

type UserSelectContainerProps<IsMulti extends boolean = false> = {
  label: string;
  isMulti?: IsMulti;
  isClearable?: boolean;
  value?: IsMulti extends true ? MultiValue<BaseValueType> : BaseValueType;
  onChange: (value: OnChangeValue<BaseValueType, IsMulti>) => void;
  className?: string;

  getOptionLabelExtension?: (
    user: BaseValueType,
    allUserItems: GetUserListResponse["_embedded"]["user"]
  ) => false | JSX.Element | string;
  getLoadOptionsExtension?: (
    fullUserItems: GetUserListResponse["_embedded"]["user"]
  ) => { value: string }[];
} & SelectAsyncFormGroupProps;

function UserSelectContainer<IsMulti extends boolean = false>({
  label,
  className,
  onChange,
  getOptionLabelExtension,
  getLoadOptionsExtension,
  ...selectAsyncProps
}: UserSelectContainerProps<IsMulti>) {
  const { t } = useTranslation();

  const [getUserIdList] = useLazyGetUserListQuery();
  const [getFullUserListQuery, userGetResult] = useLazyGetUserListQuery();

  const fetchFullUserList = async (userIDs: string[]) => {
    try {
      const result = await getFullUserListQuery({
        query: {
          filter: [
            {
              type: "in",
              field: "username",
              values: userIDs,
            },
          ],
          orderBy: [],
          pageSize: -1,
          page: 1,
        },
      }).unwrap();
      return result._embedded.user;
    } catch (error) {
      return [];
    }
  };

  const [allUserItems, loadUserOptionsHelper] = useLoadOptionsHelper({
    notInField: "username",
    fetchIdListFn: async (query) => {
      try {
        const result = await getUserIdList({
          query,
        }).unwrap();

        return result._embedded.user.map((user) => user.username);
      } catch (error) {
        return [];
      }
    },
    fetchFullListFn: fetchFullUserList,
  });

  return (
    <SelectAsync
      {...selectAsyncProps}
      placeholder={t("Enter search term")}
      isSearchable
      className={className}
      noOptionsMessage={userGetResult.isError ? () => t("Error") : undefined}
      loadOptionsDebounce={300}
      label={label}
      getOptionLabel={(userValue): any => {
        const optionLabelExtension = getOptionLabelExtension?.(
          userValue,
          allUserItems
        );
        if (optionLabelExtension) {
          return optionLabelExtension;
        }

        const foundUser = allUserItems.find((user) => {
          return user.username === userValue.value;
        });

        if (!foundUser) {
          return (
            <div
              className="spinner-fixture tw-text-sm"
              style={{
                width: "2em",
                height: "2em",
                transform: "scale(0.5)",
              }}
            >
              <Spinner />
            </div>
          );
        }

        return <UserExcerpt isOneLiner lightName user={foundUser} />;
      }}
      defaultOptions
      onChange={(values) => {
        onChange(values);
      }}
      loadOptions={async (searchTerm) => {
        const arrayValues = getArrayedSelectValue(selectAsyncProps.value);

        const fullItems = await loadUserOptionsHelper({
          searchTerm,
          activeSelection: arrayValues,
          pageSize: 20,
        });

        const valueItems = fullItems.map((fullItem) => ({
          value: fullItem.username,
        }));

        const finalItems = getLoadOptionsExtension?.(fullItems) || valueItems;

        return finalItems;
      }}
    />
  );
}

export default UserSelectContainer;
