import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "CHF",
    label: "CHF",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "GBP",
    label: "GBP",
  },
];

export const getCurrencies = createGetList(untranslatedList);
export const getCurrencyLabel = createGetLabel(untranslatedList);

export const useCurrencies = createUtilityHook(untranslatedList);
