const countries = {
  getCountries() {
    return [
      {
        value: "CH",
        label: "Schweiz",
      },
      {
        value: "CH",
        label: "Switzerland",
      },
      {
        value: "DE",
        label: "Deutschland",
      },
      {
        value: "LI",
        label: "Lichtenstein",
      },
    ];
  },
  getCountryLabel(value) {
    const countryArr = this.getCountries();
    let label = "";
    for (let i = 0; i < countryArr.length; i += 1) {
      const country = countryArr[i];
      if (country.value === value) {
        label = country.label;
      }
    }
    return label;
  },

  getCountryValue(label) {
    const countryArr = this.getCountries();
    let value = "";
    for (let i = 0; i < countryArr.length; i += 1) {
      const country = countryArr[i];
      if (country.label === label) {
        value = country.value;
      }
    }
    return value;
  },
};

export default countries;
