import { CasaoneResultDescription } from "api/types";
import {
  MutateContactSingleResponse,
  MutateContactSingleQueryParams,
} from "./types";

const invalidatesTags: CasaoneResultDescription<
  MutateContactSingleResponse,
  MutateContactSingleQueryParams
> = (result) => {
  if (result) {
    return [{ type: "Contact", id: result.id }];
  }

  return ["Contact"];
};

export default invalidatesTags;
