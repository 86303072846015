import Select from "@casasoft/styleguide/components/formElements/Select";
import Switch from "@casasoft/styleguide/components/formElements/Switch";
import { useTranslation } from "react-i18next";
import type {
  IaziCommonDataProperty,
  IaziCommonDataRequestBody,
} from "api/entities/iazi/endpoints/get-common-data/types";
import { useIaziPropertyOptions } from "./hooks/useIaziPropertyOptions";
import { useMemo } from "react";
import { IaziNoResultsWarning } from "./IaziNoResultsWarning";
import { IaziDataError } from "./IaziDataError";
import { ErrorMessage } from "@hookform/error-message";
import { FieldError } from "react-hook-form";

export interface IaziPropertySettingsProps {
  isEnabled: boolean;
  selectedProperty: IaziCommonDataProperty | undefined;
  onEnabledToggle: (enabled: boolean) => void;
  onSelectProperty: (property: IaziCommonDataProperty | undefined) => void;
  onDataFetch: (hasPropertyOptions: boolean) => void;
  address?: Partial<IaziCommonDataRequestBody>;
  errors?: FieldError; //Partial<FieldErrorsImpl<any>>;
}

export const IaziPropertySettings = ({
  isEnabled,
  selectedProperty,
  onSelectProperty,
  onEnabledToggle,
  onDataFetch,
  address,
  errors,
}: IaziPropertySettingsProps) => {
  const { t } = useTranslation();

  const {
    iaziPropertyOptions,
    selectedPropertyId,
    handleSelectIaziOption,
    lastDataCall: { isFetching, error: showError, noResults },
  } = useIaziPropertyOptions({
    isEnabled,
    selectedProperty,
    onSelectProperty,
    onDataFetch,
    address,
  });

  const showPropertyOptions = useMemo(
    () => isEnabled && !noResults && !showError,
    [isEnabled, noResults, showError]
  );

  const showNoResultsWarning = noResults && !isFetching;
  const showDescription = !showNoResultsWarning && !showError;

  return (
    <div className="tw-mb-4">
      <div className="tw-border-0 tw-border-b tw-border-solid tw-border-b-tw-cs-accent-color">
        {t("IAZI/CIFI AI Service")}
      </div>
      <Switch
        checked={isEnabled}
        label={t("Create property with IAZI/CIFI AI Service")}
        onToggle={onEnabledToggle}
        className="tw-mt-2 tw-ml-[-6px] tw-mb-2"
      />
      {showDescription && (
        <p className="tw-text-sm tw-text-cs-shade-700">
          {t(
            "Create a property supported by AI from IAZI and select from matching results to progress."
          )}
        </p>
      )}
      {showPropertyOptions && (
        <Select
          nobox
          label={t("Choose property")}
          value={selectedPropertyId}
          options={iaziPropertyOptions}
          onChange={handleSelectIaziOption}
          className="tw-basis-1/2"
          disabled={!iaziPropertyOptions.length}
          key={`${address?.lat}${address?.lon}`}
          isLoading={isFetching}
          message={{
            type: "error",
            text: <ErrorMessage errors={errors} name="iaziPropertyData" />,
          }}
        />
      )}
      {showNoResultsWarning && <IaziNoResultsWarning />}
      {showError && <IaziDataError />}
    </div>
  );
};
