import Button from "@casasoft/styleguide/components/forms/Button";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import { useTranslation } from "react-i18next";

interface LanguageBreakdownModalFooterProps {
  onDone?: () => void;
}

export const LanguageBreakdownModalFooter = ({
  onDone,
}: LanguageBreakdownModalFooterProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onDone && onDone();
  };

  return (
    <ModalFooter>
      <Button isPrimary isOutline={false} onClick={handleClick}>
        {t("Next")}
      </Button>
    </ModalFooter>
  );
};
