import queryBuilder, { FilterItem } from "utilities/queryBuilder";
import { GetPropertyListReducedQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
} from "utilities/propertyTypes";

const query = createQuery<GetPropertyListReducedQueryParams>(
  ({ propertyType, query }) => {
    const queryFilter: FilterItem[] = [...query.filter];

    const getListReducedFilter = (
      propertyType: string[] = [
        BASE_DATA_PROPERTY,
        BASE_DATA_UNIT,
        BASE_DATA_PROJECT,
      ]
    ) => ({
      type: "in",
      field: "propertyType",
      values: propertyType,
    });

    if (propertyType) {
      queryFilter.push(
        getListReducedFilter(
          Array.isArray(propertyType) ? propertyType : [propertyType]
        ) as FilterItem
      );
    } else {
      queryFilter.push({
        type: "in",
        field: "propertyType",
        values: [BASE_DATA_PROPERTY, BASE_DATA_UNIT, BASE_DATA_PROJECT],
      });
    }
    const builtQuery = queryBuilder.buildToUrl(
      query.orderBy,
      [
        ...queryFilter,
        {
          type: "innerjoin",
          field: "contents",
          alias: "contents",
        },
        {
          type: "leftjoin",
          field: "contentMedias",
          alias: "contents_contentMedia",
          parentAlias: "contents",
        },
        {
          type: "leftjoin",
          field: "media",
          alias: "contents_contentMedia_media",
          parentAlias: "contents_contentMedia",
        },
        {
          type: "leftjoin",
          field: "address",
          alias: "propertyaddress",
        },
        {
          type: "neq",
          field: "status",
          value: "ignore",
        },
        {
          type: "orx",
          where: "and",
          conditions: [
            {
              type: "neq",
              field: "status",
              value: "ignore",
              alias: "contents",
            },
            {
              type: "isnull",
              field: "status",
              alias: "contents",
            },
          ],
        },
      ],
      {
        pagesize: query.pageSize,
        page: query.page,
      },
      [
        "id",
        "rank",
        "marketingMethod",
        "visualReferenceId",
        "status",
        "propertyType",
        "price",
        "rentPriceTimeSegment",
        "pricePropertySegment",
        "priceCurrency",
        "grossPrice",
        "rentPriceTimeSegment",
        "rentPricePropertySegment",
        "netPrice",
        "grossPremium",
        "netPremium",
        "numberOfRooms",
        "areaBwf",
        "areaBwfFrom",
        "areaBwfTo",
        "areaNwf",
        "areaSiaGsf",
        "areaSiaNf",
        "contents.name",
        "contents.status",
        "contents_contentMedia.type",
        "contents_contentMedia_media.originalFilename",
        "contents_contentMedia.rank",
        "contents_contentMedia_media.mimeType",
        "contents_contentMedia_media.orientation",
        "contents_contentMedia_media.path",
        "propertyaddress.street",
        "propertyaddress.streetNumber",
        "propertyaddress.postalCode",
        "propertyaddress.locality",
        "propertyaddress.region",
        "propertyaddress.country",
        "propertyaddress.hideExactAddress",
        "propertyaddress.postOfficeBoxNumber",
      ]
    );
    return {
      url: `property?${builtQuery}`,
      method: "GET",
    };
  }
);

export default query;
