import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseContactCreator, {
  ContactResourceTypes,
  filterOutUsersAndSubsidiaries,
} from "./baseContactCreator";

const name = "employeeContact" as const;
const pluralName = "employeeContacts" as const;

export const { types, actions, rootReducer }: ContactResourceTypes =
  createResource(
    baseContactCreator({
      name,
      pluralName,
      forcedFilter: [
        {
          where: "and",
          field: "employer",
          type: "isnotnull",
        },
        {
          where: "and",
          field: "entityType",
          type: "eq",
          value: "individual",
        },
        ...filterOutUsersAndSubsidiaries,
      ],
    })
  );

const employeeContactResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const employeeContactResource = employeeContactResourceCreator.resource;
export const useEmployeeContact = employeeContactResourceCreator.createHook();
