export interface ServiceProvider {
  value: string;
  label: string;
  creditableService: boolean;
  email: string;
}

export const untranslatedProviderList: ServiceProvider[] = [
  {
    value: "iazi",
    label: "IAZI Service",
    creditableService: true,
    email: "hedo@iazi.ch",
  },
  {
    value: "backbone",
    label: "Backbone",
    creditableService: false,
    email: "hello@bkbn.com",
  },
];
