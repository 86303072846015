import { TableListProps } from "@casasoft/styleguide/components/table-list";

export type TableListListQueryResultProps = Pick<
  TableListProps,
  "loading" | "itemsLoaded" | "total"
>;

/**
 * Converts a query result in the ListResponse shape to a TableListProps (loading states/total items)
 * @param queryResult The query result to convert
 * @returns The converted TableListProps (partial)
 * @see TableListProps
 */
function useTableListQueryResult<
  TQueryResult extends {
    data?: {
      total_items?: number;
    };
    isFetching: boolean;
    isLoading: boolean;
    isUninitialized: boolean;
  }
>(queryResult: TQueryResult): TableListListQueryResultProps {
  return {
    total: queryResult.data?.total_items,
    loading: queryResult.isFetching,
    itemsLoaded: !queryResult.isLoading && !queryResult.isUninitialized,
  };
}

export default useTableListQueryResult;
