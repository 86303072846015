import { FilterItem } from "utilities/queryBuilder";

export const useDashboardQuicktasksCardFilters = () => {
  return {
    getFilter: (userContactId: string) =>
      [
        {
          type: "innerjoin",
          field: "attendees",
          alias: "attendee_recipient_sender",
          condition:
            "attendee_recipient_sender.attendeeType IN('recipient', 'sender')",
          conditionType: "with",
        },
        {
          type: "eq",
          field: "contact",
          alias: "attendee_recipient_sender",
          value: userContactId,
        },
        {
          type: "in",
          field: "status",
          values: ["fresh"],
          where: "and",
        },
      ] as FilterItem[],
  };
};
