import Select from "@casasoft/styleguide/components/formElements/Select";
import { useTranslation } from "react-i18next";
import { PropertyContactRelationTypes } from "utilities";

interface SelectRelationProps {
  value: string | undefined;
  onChange: (newVal: string | undefined) => void;
  isDisabled?: boolean;
}

export const SelectRelation = ({
  value,
  onChange,
  isDisabled = false,
}: SelectRelationProps) => {
  const { t } = useTranslation();

  return (
    <Select
      nobox
      label={t("Create contact relation")}
      placeholder={t("Choose a relation")}
      value={value}
      options={PropertyContactRelationTypes.getOptions()}
      onChange={onChange}
      className="tw-basis-1/2"
      disabled={isDisabled}
    />
  );
};
