export type DataAttributes = { [key in `data-${string}`]?: any };

type FilterKeys<
  TSet extends object,
  TNeedle extends string,
  TSetKeys = keyof TSet
> = TSetKeys extends `${TNeedle}${any}` ? never : TSetKeys;

type FilterNotStartingWith<TSet extends object, TNeedle extends string> = Pick<
  TSet,
  FilterKeys<TSet, TNeedle>
>;
type FilterStartsWith<TSet extends object, TNeedle extends string> = Omit<
  TSet,
  FilterKeys<TSet, TNeedle>
>;

function extractDataAttributes<T1 extends object>(obj: T1) {
  const dataAttrs: any = {};
  const otherAttrs: any = {};
  Object.entries(obj).forEach(([key, val]) => {
    if (key.startsWith("data-")) {
      dataAttrs[key as `data-${string}`] = val;
    } else {
      otherAttrs[key] = val;
    }
  });

  const typedReturn: [
    FilterStartsWith<T1, "data-">,
    FilterNotStartingWith<T1, "data-">
  ] = [dataAttrs, otherAttrs];

  return typedReturn;
}

export default extractDataAttributes;
