import { UserFilterToQueryFilterFn } from "hooks/table-list/useTableListQuery";
import { safeJoin } from "utilities/array";

const MIN_ROOMS = 0;
const MAX_ROOMS = 99999;

const userFilterToQueryFilter: UserFilterToQueryFilterFn = (
  inputFilter,
  filterName,
  filterValue
) => {
  type FilterItems = typeof inputFilter;
  type FilterItem = FilterItems[number];
  const newFilter = [...inputFilter];
  if (filterName === "tags") {
    const idx = newFilter.findIndex((x) => x.field === "tags");
    const newFilterItem: FilterItem = {
      type: "leftjoin",
      field: "tags",
      alias: "tags",
    };
    if (filterValue && filterValue.length > 0) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }
    // where name
    const idx2 = newFilter.findIndex((x) => x.field === "name");
    const newFilterItem2: FilterItem = {
      type: "in",
      field: "name",
      alias: "tags",
      values: filterValue,
    };
    if (filterValue && filterValue.length > 0) {
      if (idx2 !== -1) {
        newFilter[idx2] = newFilterItem2;
      } else {
        newFilter.push(newFilterItem2);
      }
    } else if (idx2 !== -1) {
      newFilter.splice(idx2, 1);
    }
  }
  if (filterName === "marketingMethod") {
    const idx = newFilter.findIndex((x) => x.field === "marketingMethod");
    const newFilterItem: FilterItem = {
      type: "eq",
      field: "marketingMethod",
      value: safeJoin(filterValue),
    };
    if (filterValue && filterValue.length > 0) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }
  }
  if (filterName === "statuses") {
    const idx = newFilter.findIndex((x) => x.field === "status");
    const newFilterItem: FilterItem = {
      type: "in",
      field: "status",
      values: filterValue,
    };
    if (filterValue && filterValue.length > 0) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }
  }
  if (filterName === "salesPersons") {
    const idx = newFilter.findIndex((x) => x.field === "salesPerson");
    const newFilterItem: FilterItem = {
      type: "in",
      field: "salesPerson",
      values: filterValue,
    };
    if (filterValue && filterValue.length > 0) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }
  }
  if (filterName === "subsidiaries") {
    const idx2 = newFilter.findIndex((x) => x.rememberme === "subsidiaries");
    if (filterValue) {
      const showNulls = filterValue.find((x: string) => x === "isnull");
      const valuesWithoutIsNull = filterValue.filter(
        (x: string) => x !== "isnull"
      );

      const conditions: FilterItem[] = [];
      if (valuesWithoutIsNull.length >= 1) {
        conditions.push({
          type: "in",
          field: "subsidiary",
          values: valuesWithoutIsNull,
        });
      }
      if (showNulls) {
        conditions.push({
          type: "isnull",
          field: "subsidiary",
        });
      }

      const newFilterItem: FilterItem = {
        type: "orx",
        where: "and",
        conditions,
        rememberme: "subsidiaries",
      };
      if (filterValue && filterValue.length > 0) {
        if (idx2 !== -1) {
          newFilter[idx2] = newFilterItem;
        } else {
          newFilter.push(newFilterItem);
        }
      } else if (idx2 !== -1) {
        newFilter.splice(idx2, 1);
      }
    } else if (idx2 !== -1) {
      newFilter.splice(idx2, 1);
    }
  }
  if (filterName === "roomsFrom") {
    const idx = newFilter.findIndex((x) => x.field === "numberOfRooms");
    const newFilterItemTyped = idx !== -1 ? newFilter[idx] : undefined;
    if (newFilterItemTyped && newFilterItemTyped.type !== "between") {
      throw new Error("Expected between filter");
    }
    const newFilterItem: FilterItem = {
      type: "between",
      field: "numberOfRooms",
      from: safeJoin(filterValue) || MIN_ROOMS,
      to: newFilterItemTyped?.to || MAX_ROOMS,
    };
    if (filterValue) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }
  }
  if (filterName === "roomsTo") {
    const idx = newFilter.findIndex((x) => x.field === "numberOfRooms");
    const newFilterItemTyped = idx !== -1 ? newFilter[idx] : undefined;
    if (newFilterItemTyped && newFilterItemTyped.type !== "between") {
      throw new Error("Expected between filter");
    }
    let newFilterItem: FilterItem = {
      type: "between",
      field: "numberOfRooms",
      from: newFilterItemTyped?.from || MIN_ROOMS,
      to: safeJoin(filterValue) || MAX_ROOMS,
    };
    if (filterValue) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }
  }
  if (filterName === "utilities") {
    // left join
    const idx = newFilter.findIndex((x) => x.field === "utilities");
    const newFilterItem: FilterItem = {
      type: "leftjoin",
      field: "utilities",
      alias: "utilities",
    };
    if (filterValue && filterValue.length > 0) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }

    // where name
    const idx2 = newFilter.findIndex((x) => x.field === "slug");
    const newFilterItem2: FilterItem = {
      type: "in",
      field: "slug",
      alias: "utilities",
      values: filterValue?.length > 0 ? [...filterValue[0].split(",")] : [],
    };
    if (filterValue && filterValue.length > 0) {
      if (idx2 !== -1) {
        newFilter[idx2] = newFilterItem2;
      } else {
        newFilter.push(newFilterItem2);
      }
    } else if (idx2 !== -1) {
      newFilter.splice(idx2, 1);
    }
  }
  if (filterName === "categories") {
    // left join
    const idx = newFilter.findIndex((x) => x.field === "categories");
    const newFilterItem: FilterItem = {
      type: "leftjoin",
      field: "categories",
      alias: "categories",
    };
    if (filterValue && filterValue.length > 0) {
      if (idx !== -1) {
        newFilter[idx] = newFilterItem;
      } else {
        newFilter.push(newFilterItem);
      }
    } else if (idx !== -1) {
      newFilter.splice(idx, 1);
    }

    // where name
    const idx2 = newFilter.findIndex((x) => x.field === "slug");
    const newFilterItem2: FilterItem = {
      type: "in",
      field: "slug",
      alias: "categories",
      values: filterValue?.length > 0 ? [...filterValue[0].split(",")] : [],
    };
    if (filterValue && filterValue.length > 0) {
      if (idx2 !== -1) {
        newFilter[idx2] = newFilterItem2;
      } else {
        newFilter.push(newFilterItem2);
      }
    } else if (idx2 !== -1) {
      newFilter.splice(idx2, 1);
    }
  }
  return newFilter;
};

export default userFilterToQueryFilter;
