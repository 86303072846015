import { createQuery } from "api/createEndpoint";
import { GetMessagePreviewSingleBody } from "./types";

const query = createQuery<GetMessagePreviewSingleBody>((body) => {
  return {
    url: `generate-message-preview`,
    method: "POST",
    data: body,
  };
});

export default query;
