import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MessageBodyPreviewSelectArrowProps {
  icon: IconDefinition;
  callback: () => void;
  previewLoading: boolean;
}

export const MessageBodyPreviewSelectArrow = ({
  icon,
  callback,
  previewLoading,
}: MessageBodyPreviewSelectArrowProps) => {
  const handleClick = () => {
    if (previewLoading) {
      return;
    }
    callback();
  };

  return (
    <div
      style={{
        padding: "0.5em",
        cursor: "pointer",
        opacity: previewLoading ? 0.5 : undefined,
      }}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};
