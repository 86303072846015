import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseEventCreator, { EventResourceTypes } from "./baseEventCreator";

const name = "quickReminder" as const;
const pluralName = "quickReminders" as const;

export const { types, actions, rootReducer }: EventResourceTypes =
  createResource(
    baseEventCreator({
      name,
      pluralName,
      apiContext: "quickreminder-list",
      forcedFilter: [
        {
          type: "eq",
          field: "type",
          value: "reminder",
        },
      ],
      forcedOrderBy: [
        // {
        //   type: "field",
        //   field: "start",
        //   direction: "desc",
        // },
      ],
    })
  );

const quickReminderResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const quickReminderResource = quickReminderResourceCreator.resource;
export const useQuickReminder = quickReminderResourceCreator.createHook();
