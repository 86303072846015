import {
  ClearIndicatorProps,
  components as ReactSelectComponent,
  DropdownIndicatorProps,
  MenuProps,
  MultiValueRemoveProps,
} from "reactSelectNew";
import { GroupBase } from "reactSelectNew";

export const CS_REACT_SELECT_CONTAINER_CLASS = "cs-react-select-container-NEW";
export const CS_REACT_SELECT_CLASS_PREFIX = "cs-react-select-NEW";

export function ReactSelectMenu<
  OptionType = unknown,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({ ...props }: MenuProps<OptionType, IsMulti, GroupType>) {
  return (
    <ReactSelectComponent.Menu
      {...props}
      // className="cs-react-select"
    />
  );
}

export function ReactSelectDropDownIndicator<
  OptionType = unknown,
  IsMulti extends boolean = boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({ ...props }: DropdownIndicatorProps<OptionType, IsMulti, GroupType>) {
  return (
    <ReactSelectComponent.DropdownIndicator {...props} className="tw-group">
      <svg width="15px" height="15px" viewBox="0 0 20 20" version="1.1">
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <polyline
            className="tw-stroke-cs-shade-400 group-hover:tw-stroke-cs-shade-black"
            points="2.11313338 6.88686662 10.1131334 14.8868666 18.1131334 6.88686662"
          />
        </g>
      </svg>
    </ReactSelectComponent.DropdownIndicator>
  );
}

export function ReactSelectClearIndicator<
  OptionType = unknown,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({ ...props }: ClearIndicatorProps<OptionType, IsMulti, GroupType>) {
  return (
    <ReactSelectComponent.ClearIndicator
      {...props}
      className="tw-group tw-cursor-pointer"
    >
      <svg width="15px" height="15px" viewBox="0 0 20 20" version="1.1">
        <g
          id="carrot"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
          className="tw-stroke-cs-shade-400 group-hover:tw-stroke-cs-shade-black"
        >
          <path d="M6,6 L14,14" id="Path-2"></path>
          <path
            d="M6,6 L14,14"
            id="Path-2"
            transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) "
          ></path>
        </g>
      </svg>
    </ReactSelectComponent.ClearIndicator>
  );
}

export function ReactSelectMultiValueRemove<
  OptionType = unknown,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({ ...props }: MultiValueRemoveProps<OptionType, IsMulti, GroupType>) {
  return (
    <ReactSelectComponent.MultiValueRemove {...props}>
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g
          id="carrot"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
        >
          <path stroke="currentColor" d="M6,6 L14,14" id="Path-2"></path>
          <path
            d="M6,6 L14,14"
            id="Path-2"
            stroke="currentColor"
            transform="translate(10.000000, 10.000000) scale(-1, 1) translate(-10.000000, -10.000000) "
          ></path>
        </g>
      </svg>
    </ReactSelectComponent.MultiValueRemove>
  );
}
