import { ReactNode, SyntheticEvent } from "react";
import { createPortal } from "react-dom";

interface PortalEventBarrierProps {
  /** children should always be react portals */
  children: ReactNode;
}

// component needed because of: https://github.com/facebook/react/issues/11387#issuecomment-355395803
function PortalEventBarrier({ children }: PortalEventBarrierProps) {
  const stop = (e: SyntheticEvent<HTMLDivElement>) => {
    // we DONT call e.stopPropagation(), because this will cause issues with some libraries (TinyMCE is where we encountered the issues) that make use of the nativeEvents
    // this means we are basically only stopping the React Synthetic Event, but not the native one.
    e.isPropagationStopped = function JustReturnTrue() {
      return true;
    };
  };
  return createPortal(
    <div
      style={{ display: "none" }} // display none is okay, because it is a portal
      onClick={stop}
      onContextMenu={stop}
      onDoubleClick={stop}
      onDrag={stop}
      onDragEnd={stop}
      onDragEnter={stop}
      onDragExit={stop}
      onDragLeave={stop}
      onDragOver={stop}
      onDragStart={stop}
      onDrop={stop}
      onMouseDown={stop}
      onMouseEnter={stop}
      onMouseLeave={stop}
      onMouseMove={stop}
      onMouseOver={stop}
      onMouseOut={stop}
      onMouseUp={stop}
      onKeyDown={stop}
      onKeyPress={stop}
      onKeyUp={stop}
      onFocus={stop}
      onBlur={stop}
      onChange={stop}
      onInput={stop}
      onInvalid={stop}
      onSubmit={stop}
    >
      {children}
    </div>,
    document.body
  );
}

export default PortalEventBarrier;
