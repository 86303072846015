import Config from "config";

export interface MediaResource {
  mimeType?: string;
  path: string;
  orientation?: string | number | null;
  originalFilename?: string;
}

export function mediaThumbnail(resource: MediaResource, size = "sq") {
  let ext = "";
  if (resource.mimeType === "image/jpeg") {
    ext = ".jpg";
  } else if (resource.mimeType === "image/png") {
    ext = ".png";
  }
  const pathEncoded = encodeURIComponent(
    resource?.path?.replace(/\//g, "_slash_")
  );
  const or = resource.orientation;

  return `${Config.apiUrl}/${
    Config.customerKey
  }/thumb/${size}/${pathEncoded}${ext}${or !== null ? `/${or}` : ""}`;
}

export function mediaMimeTypeLabel(mime: string) {
  switch (mime) {
    case "application/msword":
    case "application/vnd.ms-word":
    case "application/vnd.oasis.opendocument.text":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "Word";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml":
    case "application/vnd.oasis.opendocument.spreadsheet":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "Excel";
    case "application/vnd.ms-powerpoint":
    case "application/vnd.oasis.opendocument.presentation":
    case "application/vnd.openxmlformats-officedocument.presentationml":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "PowerPoint";
    case "application/x-zip-compressed":
      return "Zip";
    case "application/octet-stream":
      return "Binary";
    case "application/gzip":
    case "application/zip":
    case "text/csv":
    case "image/heic":
    case "application/json":
    case "text/html":
    case "application/pdf":
    case "text/plain":
    default:
      return mime.includes("/") ? mime.split("/")[1] : mime;
  }
}

export function mediaOrig(resource: {
  path?: string;
  originalFilename?: string;
}) {
  if (resource.path) {
    let pathEncoded = encodeURIComponent(
      resource.path.replace(/\//g, "_slash_")
    );
    if (resource.originalFilename?.endsWith("xls")) {
      pathEncoded = `${pathEncoded}.xls`;
    }
    return `${Config.apiUrl}/${Config.customerKey}/orig/${pathEncoded}`;
  }
  return "#";
}

export function bytesToSize(bytes: number) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) {
    return "0 Byte";
  }
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
  return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
}
