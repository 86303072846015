import { SelectRecipients } from "./SelectRecipients";
import { useCollapsedSelect } from "./helpers/useCollapsedSelect";
import { useRecipients } from "./helpers/useRecipients";
import { type RecipientSelectValueType } from "./RecipientSelect/helpers/useRecipientSelect";
import { type FieldErrors } from "react-hook-form";
import { useRef } from "react";

export type RecipientChangeHandler = (
  newValue: RecipientSelectValueType[] | undefined
) => void;

export type RecipientChangeHandlers<K extends string> = {
  [P in K]: RecipientChangeHandler;
};
export type RecipientValues<K extends string> = {
  [P in K]: RecipientSelectValueType[] | undefined;
};

export type RecipientFieldKeys = "contact" | "contactCC" | "contactBCC";

export interface SelectRecipientsContainerProps<TName extends string> {
  values: RecipientValues<TName>;
  changeHandlers: RecipientChangeHandlers<TName>;
  errors: FieldErrors<RecipientValues<TName>>;
}

export const SelectRecipientsContainer = ({
  values,
  changeHandlers,
  errors,
}: SelectRecipientsContainerProps<RecipientFieldKeys>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    isCcEnabled,
    isBccEnabled,
    isExpanded,
    handleCC,
    handleBCC,
    handleFocus,
    handleBlur,
    handleWrapperClick,
  } = useRecipients(values, wrapperRef);
  const { collapsedSelectData } = useCollapsedSelect(
    values,
    changeHandlers,
    wrapperRef
  );

  return (
    <SelectRecipients
      changeHandlers={changeHandlers}
      values={values}
      errors={errors}
      collapsedSelectData={collapsedSelectData}
      isCcFieldEnabled={isCcEnabled}
      isBccFieldEnabled={isBccEnabled}
      isExpanded={isExpanded}
      onCCClick={handleCC}
      onBCCClick={handleBCC}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onWrapperClick={handleWrapperClick}
      wrapperRef={wrapperRef}
    />
  );
};
