import { MessageAttachment } from "../../create-message/MessageMiscControls/MessageAttachFilesControl";

interface MapMediaToMessageAttachmentProps {
  media: any;
  rank: number;
  defaultTitle: string;
}

export const mapMediaToMessageAttachment = ({
  media,
  rank,
  defaultTitle,
}: MapMediaToMessageAttachmentProps): MessageAttachment => {
  const title = media.originalFilename
    ? media.originalFilename.substr(media.originalFilename.lastIndexOf("/") + 1)
    : null;

  return {
    rank,
    alt: media.alt || `${defaultTitle} #${rank + 1}`,
    title: title || `${defaultTitle} #${rank + 1}`,
    media: { ...media },
  };
};
