import { casaoneApi } from "api";
import singleQuery from "./endpoints/single/query";
import singleProvidesTags from "./endpoints/single/providesTags";
import listQuery from "./endpoints/list/query";
import listReducedQuery from "./endpoints/list-reduced/query";
import listProvidesTags from "./endpoints/list/providesTags";
import listReducedProvidesTags from "./endpoints/list-reduced/providesTags";
import updateSingleQuery from "./endpoints/update-single/query";
import updateSingleInvalidatesTags from "./endpoints/update-single/invalidatesTags";
import createSingleQuery from "./endpoints/create-single/query";
import createSingleInvalidatesTags from "./endpoints/create-single/invalidatesTags";
import transformEventResponse from "./endpoints/list-reduced/transformResponse";

const eventApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getEvent: builder.query({
      query: singleQuery,
      providesTags: singleProvidesTags,
    }),
    getEventList: builder.query({
      query: listQuery,
      providesTags: listProvidesTags,
    }),
    getEventListReduced: builder.query({
      query: listReducedQuery,
      providesTags: listReducedProvidesTags,
      transformResponse: transformEventResponse,
    }),
    updateEvent: builder.mutation({
      query: updateSingleQuery,
      invalidatesTags: updateSingleInvalidatesTags,
    }),
    createEvent: builder.mutation({
      query: createSingleQuery,
      invalidatesTags: createSingleInvalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEventQuery,
  useUpdateEventMutation,
  useCreateEventMutation,
  useGetEventListQuery,
  useGetEventListReducedQuery,
} = eventApi;
