import type { LanguageGroupData } from "../LanguageBreakdownContainer";

export type LanguagesChecked = Record<string, boolean>;

export type LanguageCheckboxStatus = {
  languageChecked: LanguagesChecked;
  wholeSelectionChecked: boolean;
  handleLanguageCheck: (lang: string) => void;
  handleWholeSelectionCheck: () => void;
};

export const setAllKeys = (languages: LanguagesChecked, areChecked: boolean) =>
  Object.keys(languages).reduce(
    (newLanguages, current) => ({ ...newLanguages, [current]: areChecked }),
    {}
  );

export const getInitialLanguageSelection = (
  languageGroups: LanguageGroupData[]
) =>
  languageGroups.reduce<LanguagesChecked>(
    (result, current) => ({ ...result, [current.key]: true }),
    {}
  );
