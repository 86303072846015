import { InputHTMLAttributes } from "react";
import * as React from "react";

interface SwitchElemProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "onBlur"> {
  label?: string | JSX.Element;
  onChange?: (value: string) => void;
  onToggle?: (value: boolean) => void;
  autoComplete?: string;
  autoFocus?: boolean;
  ariaLabel?: string;
}

const SwitchElem: React.FC<SwitchElemProps> = ({
  id,
  value,
  label,
  onChange,
  autoComplete = "off",
  autoFocus = false,
  onToggle,
  ariaLabel,
  ...inputProps
}: SwitchElemProps) => {
  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target) {
      if (event.target.checked) {
        onChange?.("checked");
        onToggle?.(true);
      } else {
        onChange?.("unchecked");
        onToggle?.(false);
      }
    }
  };

  const _handleToggle = () => {
    if (!inputProps.disabled) {
      if (value === "checked") {
        onChange?.("unchecked");
        onToggle?.(false);
      } else {
        onChange?.("checked");
        onToggle?.(true);
      }
    }
  };

  return (
    <div
      className="cs-input-group"
      style={
        inputProps.disabled
          ? {
              opacity: 0.5,
            }
          : undefined
      }
    >
      <div className="cs-form-switch__switch tw-shrink-0">
        <input
          {...inputProps}
          aria-label={ariaLabel}
          className="cs-form-switch__input"
          onChange={(event) => {
            _handleChange(event);
          }}
          checked={value === "checked" ? true : false}
          autoComplete={autoComplete}
          type="checkbox"
          value=""
          id={id}
        />
        <div
          className="cs-form-switch__slider"
          role="button"
          tabIndex={-1}
          onClick={() => _handleToggle()}
        >
          <div className="cs-form-switch__on-inicator">1</div>
          <div className="cs-form-switch__off-inicator">0</div>
          <div className="cs-form-switch__handle" />
        </div>
      </div>
      {typeof label === "string" ? (
        <label
          htmlFor={id}
          className="cs-form-switch__label"
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      ) : (
        <label htmlFor={id} className="cs-form-switch__label">
          {label}
        </label>
      )}
    </div>
  );
};

export default SwitchElem;
