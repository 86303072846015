import { PureComponent } from "react";
import PropTypes from "prop-types";

class Input extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      curVal: this.props.value ? this.props.value : "",
      curChecked: this.props.checked ? this.props.checked : false,
    };
    this.__handleChange = this.__handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      const that = this;
      setTimeout(() => {
        that.input.focus();
      }, 200);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.value !== undefined &&
      nextProps.value !== this.state.curVal
    ) {
      this.setState({ curVal: nextProps.value });
    }
  }

  __handleBlur(e) {
    const newState = Object.assign({}, this.state);
    newState.curVal = e.target.value;
    newState.currEvent = Object.assign({}, e);
    if (e.persist) {
      // react event
      e.persist();
    }
    if (this.props.type === "url") {
      if (newState.curVal && !/^(f|ht)tps?:\/\//i.test(newState.curVal)) {
        const newValue = `http://${newState.curVal}`;
        newState.currEvent.target.value = newValue;
        this.__handleChange(newState.currEvent);
      }
    }
    this.props.onBlur?.(newState.currEvent);
  }

  __handleChange(e) {
    if (e.persist) {
      // react event
      e.persist();
    }
    const newState = Object.assign({}, this.state);
    newState.curVal = e.target.value;
    newState.curChecked = this.state.curChecked === false;
    newState.curEvent = Object.assign({}, e);
    if (this.props.type === "rooms") {
      const re = /^[0-9]{0,2}(\.|,)?$/;
      let filteredValue = "";
      if (re.test(newState.curVal)) {
        filteredValue = newState.curVal.replace(/,/, ".");
      } else {
        filteredValue = newState.curVal;
      }
      newState.curVal = filteredValue;
    }
    newState.curEvent.target.value = newState.curVal;
    this.props.onChange?.(newState.curEvent);
    this.setState(newState);
  }

  render() {
    let type;
    if (this.props.type === "currency") {
      type = "number";
    } else if (this.props.type === "integer") {
      type = "number";
    } else if (this.props.type === "password") {
      type = "password";
    } else if (this.props.type === "rooms") {
      type = "text";
    } else {
      type = this.props.type;
    }

    const inputProps = {
      disabled: this.props.disabled,
      autoComplete: this.props.autoComplete,
      id: this.props.id,
      placeholder: this.props.placeholder,
      value: this.state.curVal,
      name: this.props.name,
      required: this.props.required,
      onChange: (event) => {
        this.__handleChange(event);
      },
      onFocus: (event) => {
        this.props.onFocus?.(event);
      },
      onBlur: (event) => {
        this.__handleBlur(event);
      },
      onKeyUp: (event) => {
        this.props.onKeyUp?.(event);
      },
      onKeyDown: (event) => {
        this.props.onKeyDown?.(event);
      },
      type,
      pattern: this.props.pattern,
      min: this.props.min,
      max: this.props.max,
      step: this.props.step,
      noValidate: this.props.noValidate,
      lang: this.props.lang,
      autoFocus: this.props.autoFocus,
    };

    if (this.props.type === "typeahead") {
      inputProps.ref = this.props?.setRef;
    }
    const classes = [this.props.className];
    if (
      (this.props.message &&
        Array.isArray(this.props.message) &&
        this.props.message.length) ||
      (this.props.message && !Array.isArray(this.props.message))
    ) {
      classes.push("is-invalid");
    }
    return (
      <input
        ref={(i) => {
          this.input = i;
        }}
        className={classes.join(" ")}
        type={type}
        {...inputProps}
      />
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  filled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  setRef: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  pattern: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  noValidate: PropTypes.string,
  onBlur: PropTypes.func,
  lang: PropTypes.string,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  disabled: false,
  focused: false,
  filled: false,
  checked: false,
  className: "",
  label: "",
  id: "",
  required: false,
  message: null,
  placeholder: "",
  value: "",
  name: "",
  pattern: null,
  min: null,
  max: null,
  step: null,
  noValidate: null,
  lang: null,
  autoFocus: false,
  autoComplete: "none",
};

export default Input;
