import { useEffect, useRef, useState } from "react";
import FieldInputGroup from "../formElements/helpers/FieldInputGroup";
import FormGroup, { FormGroupProps } from "../formElements/helpers/FormGroup";
import useDebounce from "../../hooks/useDebounce";

interface NumberFieldProps
  extends Pick<
    FormGroupProps,
    "nobox" | "label" | "className" | "id" | "required" | "message" | "text"
  > {
  prefix?: string;
  suffix?: string;
  min?: number;
  max?: number;
  thousandSeparator?: string;
  decimalPlaces?: number;
  step?: number;

  placeholder?: string;
  value?: string;
  disabled?: boolean;
  autoComplete?: string;
  id?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onDelayChange?: (term: string) => void;
  onDelayDuration?: number;
  autoFocus?: boolean;
  quickActions?: any;
}

const NumberField = ({
  min,
  max,
  thousandSeparator = "'",
  decimalPlaces,
  step = 1,

  // FieldInputGroupProps
  prefix = "",
  suffix = "",

  // FormGroupProps
  nobox = false,
  label,
  className = "Select",
  id = "",
  required,
  message,
  text,
  quickActions,

  placeholder = "",
  value = "",
  disabled = false,
  onChange,
  onBlur,
  onFocus,
  onDelayChange,
  onDelayDuration = 100,
  autoFocus,
}: NumberFieldProps) => {
  const negativRegex = "^-";

  const [filled, setFormGroupFilled] = useState(value ? true : false);
  const [focused, setFocused] = useState(false);
  const [curVal, setCurVal] = useState(value || "");

  useEffect(() => {
    // this will also trigger the useEffect below, so there is no need to set the formGroupFilled state from here
    setCurVal(value || "");
  }, [value]);
  useEffect(() => {
    setFormGroupFilled(curVal ? true : false);
  }, [curVal]);

  useDebounce(curVal, onDelayDuration, onDelayChange);

  const __correctMaxMin = (newValue: string) => {
    if (max !== undefined && parseFloat(newValue) > max) {
      return max.toString();
    }
    if (min !== undefined && parseFloat(newValue) < min) {
      return min.toString();
    }
    return newValue;
  };

  const _handleOnChange = (newValue: string) => {
    setCurVal(newValue);
    onChange?.(newValue);
  };

  const __formatInput = (number: string) => {
    const negativ = number.toString().match(new RegExp(negativRegex));

    if (parseFloat(number) % 1 === 0) {
      const formattedNumber = number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
      const isStillNegativ = formattedNumber
        .toString()
        .match(new RegExp(negativRegex));
      return `${negativ && !isStillNegativ ? "-" : ""}${formattedNumber}`;
    }
    const formatedArr = number.toString().split(".");
    formatedArr[0] = formatedArr[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      thousandSeparator
    );
    if (formatedArr[1] === "") {
      const isStillNegativ = formatedArr[0].match(new RegExp(negativRegex));
      return `${negativ && !isStillNegativ ? "-" : ""}${formatedArr[0]}`;
    }
    const stillNegativ = formatedArr[0].match(new RegExp(negativRegex));
    if (formatedArr[1]) {
      return `${negativ && !stillNegativ ? "-" : ""}${formatedArr[0]}.${
        formatedArr[1]
      }`;
    }
    return number;
  };

  const __unformatInput = (formattedValue: string) => {
    if (formattedValue !== "") {
      let cleanValue = formattedValue;
      let negativ: RegExpMatchArray | null = null;
      if (cleanValue) {
        negativ = cleanValue.toString().match(new RegExp(negativRegex));
      }
      cleanValue = __correctDecimalPlaces(cleanValue);
      const stillNegativ = cleanValue
        .toString()
        .match(new RegExp(negativRegex));
      return `${negativ && !stillNegativ ? "-" : ""}${cleanValue}`;
    }
    return formattedValue;
  };

  const __correctDecimalPlaces = (newValue: string) => {
    let cleanValue = newValue;
    if (!decimalPlaces) {
      const regex = "[^0-9]";
      cleanValue = cleanValue.toString().replace(new RegExp(regex, "g"), "");
    } else {
      // replace "," with "." (unless comma is the thousandSeparator)
      if (!thousandSeparator.includes(",")) {
        cleanValue = cleanValue.replace(",", ".");
      }
      // simplify to numbers and "."
      const regex = "[^0-9.]";
      cleanValue = cleanValue.toString().replace(new RegExp(regex, "g"), "");
      const regex2 = `\\d*\\.{1}\\d${
        decimalPlaces !== null ? `{${decimalPlaces}}` : "*"
      }`;
      const cleanValueArr = cleanValue.match(new RegExp(regex2, ""));
      if (cleanValueArr && cleanValueArr.length) {
        cleanValue = cleanValueArr[0];
      }
    }
    return cleanValue;
  };

  const classNames = ["cs-form-control"];
  if (message) {
    classNames.push("cs-form-control--invalid");
  }

  // remember cursor position
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPositionRef = useRef<{
    start: number | null;
    end: number | null;
  }>({
    start: null,
    end: null,
  });
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart = cursorPositionRef.current.start;
      inputRef.current.selectionEnd = cursorPositionRef.current.end;
    }
  }, [curVal]);

  return (
    <FormGroup
      filled={filled}
      focused={focused}
      nobox={nobox}
      label={label}
      className={className}
      id={id}
      required={required}
      message={message}
      text={text}
      quickActions={quickActions}
    >
      <FieldInputGroup prefix={prefix} suffix={suffix}>
        <input
          ref={inputRef}
          disabled={disabled}
          id={id}
          placeholder={
            typeof placeholder === "string" ? placeholder : undefined
          }
          onChange={(eventOrValue) => {
            const formatToNowDiff =
              __formatInput(__unformatInput(eventOrValue.target.value)).length -
              eventOrValue.target.value.length; // check if the format method will have a different length to account for that difference for the next render -> prevents cursor jump

            cursorPositionRef.current.start =
              eventOrValue.target.selectionStart !== null
                ? eventOrValue.target.selectionStart + formatToNowDiff
                : null;
            cursorPositionRef.current.end =
              eventOrValue.target.selectionEnd !== null
                ? eventOrValue.target.selectionEnd + formatToNowDiff
                : null;

            const unformatedInput = __unformatInput(eventOrValue.target.value);

            _handleOnChange(unformatedInput);
          }}
          value={__formatInput(curVal)}
          onKeyUp={(event) => {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
              const finalStep = event.key === "ArrowUp" ? step : step * -1;
              let int = curVal !== "" ? parseFloat(curVal) : 0;
              int = (int * 1000 + finalStep * 1000) / 1000;
              const stringVal = __correctMaxMin(int.toString());
              if (stringVal !== curVal) {
                _handleOnChange(stringVal);
              }
            }
          }}
          onFocus={() => {
            onFocus?.(curVal);
            setFocused(true);
          }}
          onBlur={() => {
            const parsed = parseFloat(curVal).toString();
            const numberified = parsed === "NaN" ? "" : parsed;

            const corrected = __correctMaxMin(numberified);
            // if the value got corrected, also call an onChange
            if (corrected !== curVal) {
              _handleOnChange(corrected);
            }
            onBlur?.(corrected);
            setFocused(false);
          }}
          required={required}
          autoFocus={autoFocus}
          type={"text"}
          className={classNames.join(" ")}
        />
      </FieldInputGroup>
    </FormGroup>
  );
};

export default NumberField;
