import { useCreateEventMutation } from "api/entities/event";
import type { CreateEventSingleBody } from "api/entities/event/endpoints/create-single/types";
import { type FormShape } from "components/event/EventCreateForm";
import { getAttendeesCreatePayload } from "components/event/helpers/attendees";
import { mapSenderRecipient } from "components/event/helpers/mapSenderRecipient";
import { selectCasaoneUser } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import { type PartialOrNull } from "utilities/type-helpers/object-oberators";

export const useCreateEventHelper = () => {
  const [createEventTrigger] = useCreateEventMutation();
  const currentUserId = useAppSelector(selectCasaoneUser)?.contact?.id;

  const createReminderEvent = async ({
    reminderData,
    parentEventId,
    sender,
  }: {
    reminderData: PartialOrNull<CreateEventSingleBody>;
    parentEventId: string;
    sender: string | null | undefined;
  }) => {
    const {
      reminderStartDate,
      reminderStartTime,
      reminderSubject,
      reminderHtml,
      reminderUser,
    } = reminderData;

    await createEventTrigger({
      type: "reminder",
      attendees: getAttendeesCreatePayload({
        sender: sender || currentUserId,
        recipient: reminderUser,
      }),
      start: reminderStartDate
        ? `${reminderStartDate} ${reminderStartTime || "00:00"}`
        : undefined,
      subject: reminderSubject,
      html: reminderHtml,
      eventTrigger: parentEventId,
    });
  };

  // TODO: I just exported the code from monstrosity EventCreateForm.tsx to this file
  // Once properly covered with tests it should be refactored a bit, there are some nonsensical parts
  const createEvent = async (
    formData: FormShape,
    reminderData: PartialOrNull<CreateEventSingleBody>,
    requestData: CreateEventSingleBody
  ) => {
    const { sender, recipient } = mapSenderRecipient(
      formData,
      formData.eventBaseType
    );

    requestData.attendees = getAttendeesCreatePayload({
      sender: sender || currentUserId,
      recipient,
    });

    const response = await createEventTrigger({
      ...requestData,
      requestedRelationType: formData.relationType,
    }).unwrap();

    // create reminder
    if (reminderData.addReminder && response.id) {
      await createReminderEvent({
        reminderData,
        parentEventId: response.id,
        sender,
      });
    }

    return response;
  };

  return { createEvent };
};
