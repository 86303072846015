import { type FormShape as PropertyCreateFormShape } from "components/property/PropertyCreateForm";
import { type RegisterFieldProps } from "hooks/useForm";
import { type UseFormGetValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface UseValidationFieldRulesProps {
  getValues: UseFormGetValues<PropertyCreateFormShape>;
}

export const useValidationFieldRules = ({
  getValues,
}: UseValidationFieldRulesProps) => {
  const { t } = useTranslation();

  const fieldRules: RegisterFieldProps<PropertyCreateFormShape>["fields"] = {
    marketingMethod: {
      rules: {
        required: {
          value: true,
          message: t("Value is required and can't be empty"),
        },
      },
    },
    name: {
      rules: {
        required: {
          value: true,
          message: t("Value is required and can't be empty"),
        },
      },
    },
    locality: {},
    postalCode: {},
    lat: {},
    lng: {},
    street: {},
    streetNumber: {},
    country: {
      rules: {
        required: {
          value: true,
          message: t("Value is required and can't be empty"),
        },
      },
    },
    region: {},
    iaziPropertyData: {
      rules: {
        validate: (value) => {
          const isEnabled = getValues("iaziEnabled");
          const hasOptions = getValues("iaziHasPropertyOptions");

          if (isEnabled && !value && hasOptions) {
            return t<string>("Value is required and can't be empty");
          }
          return true;
        },
      },
    },
  };

  return { fieldRules };
};
