import { ReactNode } from "react";

interface ViewProps {
  children: ReactNode;
}

function View({ children }: ViewProps) {
  return <main className="app__view app__view--with-subnav">{children}</main>;
}

export default View;
