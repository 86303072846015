import { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { renderAddress } from "utilities/addressHelper";
import PropertyExcerpt from "components/property/PropertyExcerpt";

interface SelectPropertyOptionProps {
  property: PropertyListReducedShape | undefined;
}

export const SelectPropertyOption = ({
  property,
}: SelectPropertyOptionProps) => {
  if (!property) {
    return null;
  }

  return (
    <div className="tw-flex tw-py-2 tw-px-4 tw-text-sm">
      <PropertyExcerpt showThumbnail entity={property} lines={3} />
      <div className="tw-w-32 tw-shrink">&nbsp;</div>
      <div className="tw-shrink-0">
        {renderAddress(property._embedded.address)}
      </div>
    </div>
  );
};
