import type { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { PublicationListReducedShape } from "api/entities/publication/endpoints/list-reduced/types";
import type { PropertyShape } from "entities/property/types";
import { numvalLabel, numvalRender } from "utilities/numvals";

type PropertySizesProps = {
  property:
    | PropertyShape
    | PropertyListReducedShape
    | PublicationListReducedShape;
  includeAreaSiaNf?: boolean;
};

export const getPropertySizes = ({
  property,
  includeAreaSiaNf = true,
}: PropertySizesProps): JSX.Element[] => {
  const sizes = [];
  if (property.numberOfRooms) {
    sizes.push({
      key: "numberOfRooms",
      label: numvalLabel("numberOfRooms"),
      value: property.numberOfRooms
        ? numvalRender(property, "numberOfRooms")
        : "",
    });
  }
  if (property.areaBwf) {
    sizes.push({
      key: "areaBwf",
      label: numvalLabel("areaBwf"),
      value: property.areaBwf ? numvalRender(property, "areaBwf") : "",
    });
  } else if (property.areaNwf) {
    sizes.push({
      key: "areaNwf",
      label: numvalLabel("areaNwf"),
      value: property.areaNwf ? numvalRender(property, "areaNwf") : "",
    });
  }
  if (property.areaSiaGsf) {
    sizes.push({
      key: "areaSiaGsf",
      label: numvalLabel("areaSiaGsf"),
      value: property.areaSiaGsf ? numvalRender(property, "areaSiaGsf") : "",
    });
  }
  // added since CASAONE-2676. A follow-up idea would be to rank all possible props, so there is always something displayed, but only the highest priority 5 or so. Look at that ticket for more informations if you end up here because you are looking into such ranks :P
  if (includeAreaSiaNf && property.areaSiaNf) {
    sizes.push({
      key: "areaSiaNf",
      label: numvalLabel("areaSiaNf"),
      value: property.areaSiaNf ? numvalRender(property, "areaSiaNf") : "",
    });
  }

  return sizes.map((size) => (
    <div key={size.key}>
      <span className="tw-text-cs-shade-500">{size.label}:</span> {size.value}
    </div>
  ));
};
