import { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
} from "@casasoft/styleguide/components/card";
import { connect } from "react-redux";
import { faTrash, faEdit } from "@fortawesome/pro-light-svg-icons";

import { FormModal } from "@casasoft/styleguide/components/form-modal";
import { TableList } from "@casasoft/styleguide/components/table-list";
import { withTranslation } from "react-i18next";
import ContactSearchprofilesExcerpt from "./ContactSearchprofilesExcerpt";
import ContactSearchprofilesCreateFormContainer from "./ContactSearchprofilesCreateFormContainer";
import ContactSearchprofilesEditFormContainer from "./ContactSearchprofilesEditFormContainer";
import resourceHelper from "utilities/resource";
import Button from "@casasoft/styleguide/components/forms/Button";

class ContactSearchprofilesCard extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      working: false,
      modalOpen: false,
      entity: null,
      editId: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      (!state.entity && props.entity) ||
      state.entity.id !== props.entity.id
    ) {
      let urlParams = {};
      if (props.contactAccessKey) {
        urlParams = {
          contactAccessKey: props.contactAccessKey,
        };
      }
      resourceHelper.fetchList(
        props.storeName,
        [
          {
            where: "and",
            field: "contact",
            type: "eq",
            value: props.entity.id,
          },
        ],
        null,
        100,
        false,
        null,
        urlParams
      );
      return { entity: props.entity };
    }
    return null;
  }

  __fetchItems() {
    let urlParams = {};
    if (this.props.contactAccessKey) {
      urlParams = {
        contactAccessKey: this.props.contactAccessKey,
      };
    }
    resourceHelper.fetchList(
      this.props.storeName,
      [
        {
          where: "and",
          field: "contact",
          type: "eq",
          value: this.props.entity.id,
        },
      ],
      null,
      100,
      false,
      null,
      urlParams
    );
  }

  addItem() {
    this.setState({
      modalOpen: true,
    });
  }

  render() {
    const entity = this.props.entity;
    let items = [];
    if (
      this.props.contactSearchprofileStore.items &&
      this.props.contactSearchprofileStore.items.length
    ) {
      items = this.props.contactSearchprofileStore.items;
    }
    return (
      <div
        style={
          this.state.working ? { opacity: 0.5, pointerEvents: "none" } : {}
        }
      >
        <Card>
          <CardHeader>
            {this.props.headerTitle
              ? this.props.headerTitle
              : this.props.t("Search profiles")}
          </CardHeader>
          <TableList
            actualStore={this.props.contactSearchprofileStore}
            components={["item-actions"]}
            onItemClick={(item) => {
              this.setState({ editId: item.item_key });
            }}
            pageSize={items.length}
            total={items.length}
            itemActionNodes={(item) => [
              {
                label: this.props.t("Edit"),
                icon: faEdit,
                onItemClick: () => {
                  this.setState({ editId: item.item_key });
                },
              },
              "spacer",
              {
                label: this.props.t("Remove"),
                icon: faTrash,
                onItemClick: () => {
                  const data = { id: item.item_key };
                  const urlParamsForReload = {};
                  if (this.props.contactAccessKey) {
                    data.contactAccessKey = this.props.contactAccessKey;
                    urlParamsForReload.contactAccessKey =
                      this.props.contactAccessKey;
                  }
                  resourceHelper.deleteItem(
                    this.props.storeName,
                    data,
                    true,
                    urlParamsForReload
                  );
                },
              },
            ]}
            cols={[
              {
                key: "excerpt",
                label: "",
                placeholder: "line2x",
              },
            ]}
            items={items.map((item) => {
              return {
                item_key: item.id,
                excerpt: (
                  <ContactSearchprofilesExcerpt
                    entity={item}
                    contactAccessKey={this.props.contactAccessKey}
                  />
                ),
              };
            })}
            t={(string) => {
              switch (string) {
                case "tableList.editFilterBtn":
                  return this.props.t("Edit");
                case "tableList.searchPlaceholder":
                  return this.props.t("Search");
                case "tableList.paginationFromWord":
                  return this.props.t("from");
                case "tableList.paginationPerPage":
                  return this.props.t("per page");
                case "tableList.filterPlaceholder":
                  return this.props.t("Filter");
                case "tableList.noResultsFound":
                  return this.props.t("No results found");
                default:
                  return string;
              }
            }}
          />
          <CardBody>
            <Button
              isPrimary
              isOutline
              isBlock
              onClick={() => {
                this.setState({ modalOpen: true });
              }}
            >
              + {this.props.t("Create search profile")}
            </Button>
            <FormModal
              isOpen={this.state.modalOpen}
              onDone={() => {
                // body
                this.setState({ modalOpen: false });
                this.__fetchItems();
              }}
              form={(props) => {
                return (
                  <ContactSearchprofilesCreateFormContainer
                    {...props}
                    contactId={entity.id}
                    storeName={this.props.storeName}
                    contactAccessKey={this.props.contactAccessKey}
                    autoFocus="priceMin"
                  />
                );
              }}
              header={this.props.t("Create search profile")}
              size="lg"
              onCancel={() => {
                this.setState({ modalOpen: false });
              }}
              saveButtonText={this.props.t("Save")}
            />
            {this.state.editId !== null && (
              <FormModal
                isOpen={this.state.editId !== null}
                onDone={() => {
                  // body
                  this.setState({ editId: null });
                  this.__fetchItems();
                }}
                form={(props) => {
                  return (
                    <ContactSearchprofilesEditFormContainer
                      {...props}
                      id={this.state.editId}
                      storeName={this.props.storeName}
                      contactAccessKey={this.props.contactAccessKey}
                      autoFocus="priceMin"
                    />
                  );
                }}
                header={this.props.t("Edit search profile")}
                size="lg"
                onCancel={() => {
                  this.setState({ editId: null });
                }}
                saveButtonText={this.props.t("Save")}
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

ContactSearchprofilesCard.propTypes = {
  entity: PropTypes.object.isRequired,
  contactSearchprofileStore: PropTypes.object.isRequired,
  storeName: PropTypes.string,
  contactAccessKey: PropTypes.string,
  headerTitle: PropTypes.string,
};

ContactSearchprofilesCard.defaultProps = {
  storeName: "contactSearchprofiles",
  contactAccessKey: null,
  headerTitle: null,
};

const translated = withTranslation()(ContactSearchprofilesCard);

export default connect((state, props) => {
  let store = null;
  if (props.storeName) {
    store = state[props.storeName];
  } else {
    store = state.contactSearchprofiles;
  }
  return {
    contactSearchprofileStore: store,
  };
})(translated);
