import { ButtonHTMLAttributes } from "react";

interface PaginationItemProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className" | "type"> {
  active?: boolean;
}

function PaginationItem({ children, active, ...props }: PaginationItemProps) {
  return (
    <button
      {...props}
      type="button"
      className={`tw-px-3 tw-py-2 tw-leading-[1] focus:tw-outline-solid focus:tw-outline-cs-shade-300 focus:tw-outline-2 disabled:tw-text-cs-shade-600 ${
        active
          ? "tw-text-white tw-bg-tw-cs-accent-color tw-cursor-default"
          : "tw-text-tw-cs-accent-color hover:tw-bg-cs-shade-50 hover:tw-text-tw-cs-accent-color-dark tw-cursor-pointer"
      }`}
    >
      {children}
    </button>
  );
}

export default PaginationItem;
