import SelectAsync, {
  SelectAsyncFormGroupProps,
} from "@casasoft/styleguide/components/formElements/SelectAsync";
import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { useLazyGetUserContactListQuery } from "api/entities/user";
import { useTranslation } from "react-i18next";
import UserExcerpt from "../../UserExcerpt";
import { MultiValue, OnChangeValue } from "reactSelectNew";
import getArrayedSelectValue from "./helpers/getArrayedSelectValue";
import useLoadOptionsHelper from "hooks/useLoadOptionsHelper";
import { GetUserContactListResponse } from "api/entities/user/endpoints/user-contact-list/types";

type BaseValueType = { value: string };

type UserContactSelectContainerProps<IsMulti extends boolean = false> = {
  label: string;
  isMulti?: IsMulti;
  isClearable?: boolean;
  value?: IsMulti extends true ? MultiValue<BaseValueType> : BaseValueType;
  onChange: (value: OnChangeValue<BaseValueType, IsMulti>) => void;
  className?: string;

  showCasasoftUsers?: boolean;

  getOptionLabelExtension?: (
    user: BaseValueType,
    allUserItems: GetUserContactListResponse["_embedded"]["user"]
  ) => false | JSX.Element | string;
  getLoadOptionsExtension?: (
    fullUserItems: GetUserContactListResponse["_embedded"]["user"]
  ) => { value: string }[];
} & SelectAsyncFormGroupProps;

function UserContactSelectContainer<IsMulti extends boolean = false>({
  label,
  className,
  onChange,
  getOptionLabelExtension,
  getLoadOptionsExtension,
  showCasasoftUsers,
  ...selectAsyncProps
}: UserContactSelectContainerProps<IsMulti>) {
  const { t } = useTranslation();

  const [getUserContactIdList] = useLazyGetUserContactListQuery();
  const [getFullUserContactListQuery, userGetResult] =
    useLazyGetUserContactListQuery();

  const fetchFullUserContactList = async (contactIDs: string[]) => {
    try {
      const result = await getFullUserContactListQuery({
        includeCasasoftUsers: showCasasoftUsers,
        query: {
          filter: [
            {
              type: "in",
              field: "id",
              alias: "contact",
              values: contactIDs,
            },
          ],
          orderBy: [],
          pageSize: -1,
          page: 1,
        },
      }).unwrap();
      return result._embedded.user;
    } catch (error) {
      return [];
    }
  };

  const [allUserItems, loadUserOptionsHelper] = useLoadOptionsHelper({
    notInField: "id",
    notInAlias: "contact",
    fetchIdListFn: async (query) => {
      try {
        const result = await getUserContactIdList({
          includeCasasoftUsers: showCasasoftUsers,
          query,
        }).unwrap();

        return result._embedded.user
          .map((user) => user._embedded?.contact?.id || undefined)
          .filter(Boolean) as string[];
      } catch (error) {
        return [];
      }
    },
    fetchFullListFn: fetchFullUserContactList,
  });

  return (
    <SelectAsync
      {...selectAsyncProps}
      placeholder={t("Enter search term")}
      isSearchable
      className={className}
      noOptionsMessage={userGetResult.isError ? () => t("Error") : undefined}
      loadOptionsDebounce={300}
      label={label}
      getOptionLabel={(userContactValue): any => {
        const optionLabelExtension = getOptionLabelExtension?.(
          userContactValue,
          allUserItems
        );
        if (optionLabelExtension) {
          return optionLabelExtension;
        }

        const foundUser = allUserItems.find((user) => {
          return user._embedded?.contact?.id === userContactValue.value;
        });

        if (allUserItems.length === 0) {
          return (
            <div
              className="spinner-fixture tw-text-sm"
              style={{
                width: "2em",
                height: "2em",
                transform: "scale(0.5)",
              }}
            >
              <Spinner />
            </div>
          );
        }
        if (!foundUser) {
          return;
        }
        return <UserExcerpt isOneLiner lightName user={foundUser} />;
      }}
      defaultOptions
      onChange={onChange}
      loadOptions={async (searchTerm) => {
        const arrayValues = getArrayedSelectValue(selectAsyncProps.value);

        const fullItems = await loadUserOptionsHelper({
          searchTerm,
          activeSelection: arrayValues,
          pageSize: 20,
        });

        const valueItems = fullItems
          .map((fullItem) =>
            fullItem._embedded?.contact?.id
              ? {
                  value: fullItem._embedded?.contact?.id,
                }
              : undefined
          )
          .filter(Boolean) as { value: string }[];

        const finalItems = getLoadOptionsExtension?.(fullItems) || valueItems;

        return finalItems;
      }}
    />
  );
}

export default UserContactSelectContainer;
