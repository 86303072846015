import { GetPropertySingleQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<GetPropertySingleQueryParams>((id) => {
  return {
    url: `property/${id}`,
    method: "GET",
  };
});

export default query;
