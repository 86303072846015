import { useState } from "react";
import ReactSelectAsync, { AsyncProps } from "reactSelectNew/async";
import { GroupBase } from "reactSelectNew";
import FormGroup from "./helpers/FormGroup";
import {
  ReactSelectMenu,
  ReactSelectClearIndicator,
  ReactSelectDropDownIndicator,
  CS_REACT_SELECT_CONTAINER_CLASS,
  CS_REACT_SELECT_CLASS_PREFIX,
  ReactSelectMultiValueRemove,
} from "./helpers/ReactSelectComponents";
import { useReactSelectDebouncableLoadOptions } from "@casasoft/styleguide/hooks/useReactSelectDebouncableLoadOptions";
import { useTranslation } from "react-i18next";

type FormGroupProps = React.ComponentProps<typeof FormGroup>;

export type SelectAsyncFormGroupProps = Pick<
  FormGroupProps,
  "nobox" | "label" | "className" | "id" | "required" | "message" | "text"
>;

export interface SelectAsyncOverrideProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> {
  selectAsyncOverride?: Pick<
    AsyncProps<Option, IsMulti, Group>,
    "components" | "styles"
  >;
}

export interface CustomSelectAsyncProps extends SelectAsyncFormGroupProps {
  loadOptionsDebounce?: number;
}

function SelectAsync<
  OptionType = unknown,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({
  // formgroup props
  nobox = true,
  label,
  className,
  id,
  required,
  message,
  text,
  // custom props
  loadOptionsDebounce,
  // orig props
  ...props
}: AsyncProps<OptionType, IsMulti, GroupType> & CustomSelectAsyncProps) {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);

  const debouncableLoadOptions = useReactSelectDebouncableLoadOptions(
    props.loadOptions,
    loadOptionsDebounce
  );

  // make compatible with both isMulti and normal
  const arrayedValue = [];
  if (props.value) {
    if (Array.isArray(props.value)) {
      arrayedValue.push(...props.value);
    } else {
      arrayedValue.push(props.value);
    }
  }

  const hasValue = !!arrayedValue.length;

  return (
    <FormGroup
      filled={hasValue ? true : false}
      focused={focused}
      nobox={nobox}
      label={label}
      className={className}
      id={id}
      required={required}
      message={message}
      text={text}
    >
      <ReactSelectAsync
        {...props}
        placeholder={
          props.placeholder === undefined ? t("Select") : props.placeholder
        }
        // menuIsOpen={true}
        loadOptions={debouncableLoadOptions}
        onFocus={(...args) => {
          setFocused(true);
          props.onFocus?.(...args);
        }}
        onBlur={(...args) => {
          setFocused(false);
          props.onBlur?.(...args);
        }}
        loadingMessage={(...args) => {
          const customMessage = props.loadingMessage?.(...args);
          return customMessage || t("...Loading");
        }}
        noOptionsMessage={(...args) => {
          const customMessage = props.noOptionsMessage?.(...args);
          return customMessage || t("No results found");
        }}
        components={{
          ClearIndicator: ReactSelectClearIndicator,
          MultiValueRemove: ReactSelectMultiValueRemove,
          DropdownIndicator: ReactSelectDropDownIndicator,
          Menu: ReactSelectMenu,
          ...props.components,
        }}
        className={CS_REACT_SELECT_CONTAINER_CLASS}
        classNamePrefix={CS_REACT_SELECT_CLASS_PREFIX}
      />
    </FormGroup>
  );
}

export default SelectAsync;
