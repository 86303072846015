import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormBaseContainer from "components/miscellaneous/FormBaseContainer";
import ContactSearchprofilesEditForm from "./ContactSearchprofilesEditForm";
import resourceHelper from "../../utilities/resource";
import { actions as contactSearchprofileActions } from "../../entities/contactSearchprofile/store";

class ContactSearchprofilesEditFormContainer extends PureComponent {
  static __cleanPayload(body) {
    const data = {
      priceMin: body.priceMin ? body.priceMin.toString() : "",
      priceMax: body.priceMax ? body.priceMax.toString() : "",
      roomMin: body.roomMin ? body.roomMin.toString() : "",
      roomMax: body.roomMax ? body.roomMax.toString() : "",
      areaBwfNwfMin: body.areaBwfNwfMin ? body.areaBwfNwfMin.toString() : "",
      areaBwfNwfMax: body.areaBwfNwfMax ? body.areaBwfNwfMax.toString() : "",
      areaGsfMin: body.areaGsfMin ? body.areaGsfMin.toString() : "",
      areaGsfMax: body.areaGsfMax ? body.areaGsfMax.toString() : "",
      areaSiaNfMin: body.areaSiaNfMin ? body.areaSiaNfMin.toString() : "",
      areaSiaNfMax: body.areaSiaNfMax ? body.areaSiaNfMax.toString() : "",
      categories:
        body._embedded && body._embedded.categories
          ? body._embedded.categories.map((x) => x.slug).join(",")
          : null,
      features:
        body._embedded && body._embedded.features
          ? body._embedded.features.map((x) => x.slug).join(",")
          : null,
      utilities:
        body._embedded && body._embedded.utilities
          ? body._embedded.utilities.map((x) => x.slug).join(",")
          : null,
      polygon: body.polygon ? body.polygon.toString() : "",
      marketingMethod: body.marketingMethod
        ? body.marketingMethod.toString()
        : "",
      notes: body.notes ? body.notes.toString() : "",
      status: body.status ? body.status.toString() : "",
    };

    return data;
  }

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <FormBaseContainer
        defaultData={{
          utilities: "",
          categories: "",
          features: "",
          priceMin: "",
          priceMax: "",
          roomMin: "",
          roomMax: "",
          areaBwfNwfMin: "",
          areaBwfNwfMax: "",
          areaGsfMin: "",
          areaGsfMax: "",
          areaSiaNfMin: "",
          areaSiaNfMax: "",
          polygon: "",
          marketingMethod: "",
          notes: "",
          status: "active",
        }}
        onReady={(bool) => {
          this.props.onReady(bool);
        }}
        save={this.props.save}
        getPayload={() => {
          return new Promise((resolve, reject) => {
            if (
              this.props.contactSearchprofileStore &&
              this.props.contactSearchprofileStore.item &&
              this.props.contactSearchprofileStore.item.id === this.props.id
            ) {
              resolve(
                ContactSearchprofilesEditFormContainer.__cleanPayload(
                  this.props.contactSearchprofileStore.item
                )
              );
            }

            let idOrParams = this.props.id;
            if (this.props.contactAccessKey) {
              idOrParams = {
                id: this.props.id,
                contactAccessKey: this.props.contactAccessKey,
              };
            }
            resourceHelper
              .getItem(this.props.storeName, idOrParams)
              .then((body) => {
                if (body) {
                  resolve(
                    ContactSearchprofilesEditFormContainer.__cleanPayload(body)
                  );
                } else {
                  reject({
                    validationKey: "resourceMissing",
                    text: "could not load resource",
                    type: "error",
                  });
                }
              });
          });
        }}
        persistData={(data) => {
          const rData = Object.assign({}, data);
          if (this.props.contactAccessKey) {
            rData.contactAccessKey = this.props.contactAccessKey;
          }
          Object.entries(rData).forEach((item) => {
            const key = item[0];
            const value = item[1];
            if (value === "") {
              rData[key] = null;
            }
          });
          rData.id = this.props.id;

          if (rData.priceMin) {
            rData.priceMin = parseFloat(rData.priceMin);
          }
          if (rData.priceMax) {
            rData.priceMax = parseFloat(rData.priceMax);
          }
          if (rData.roomMin) {
            rData.roomMin = parseFloat(rData.roomMin);
          }
          if (rData.roomMax) {
            rData.roomMax = parseFloat(rData.roomMax);
          }
          if (rData.areaBwfNwfMin) {
            rData.areaBwfNwfMin = parseFloat(rData.areaBwfNwfMin);
          }
          if (rData.areaBwfNwfMax) {
            rData.areaBwfNwfMax = parseFloat(rData.areaBwfNwfMax);
          }
          if (rData.areaGsfMin) {
            rData.areaGsfMin = parseFloat(rData.areaGsfMin);
          }
          if (rData.areaGsfMax) {
            rData.areaGsfMax = parseFloat(rData.areaGsfMax);
          }
          if (rData.areaSiaNfMin) {
            rData.areaSiaNfMin = parseFloat(rData.areaSiaNfMin);
          }
          if (rData.areaSiaNfMax) {
            rData.areaSiaNfMax = parseFloat(rData.areaSiaNfMax);
          }

          if (rData.categories) {
            rData.categories = rData.categories.split(",").map((slug, i) => {
              return {
                slug,
                rank: i,
              };
            });
          }

          if (rData.features) {
            rData.features = rData.features.split(",").map((slug, i) => {
              return {
                slug,
                rank: i,
              };
            });
          }

          if (rData.utilities) {
            rData.utilities = rData.utilities.split(",").map((slug, i) => {
              return {
                slug,
                rank: i,
              };
            });
          }
          return new Promise((resolve, reject) => {
            resourceHelper
              .updateItem(this.props.storeName, rData, true)
              .then((response) => {
                if (response.code === 200) {
                  this.props.onReady(true);
                  this.props.onDone();
                  resourceHelper.reloadList("propertyMatches");
                  resolve(response);
                } else {
                  this.props.onFail();
                  reject({
                    validationKey: "resourceMissing",
                    text: "wrong response code",
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.props.onFail();
                reject(err);
              });
          });
        }}
        editForm={(props) => {
          if (props.data) {
            return (
              <ContactSearchprofilesEditForm
                autoFocus={this.props.autoFocus}
                {...props}
              />
            );
          }
          return <span>Loading ...</span>;
        }}
      />
    );
  }
}

ContactSearchprofilesEditFormContainer.propTypes = {
  id: PropTypes.number.isRequired,
  contactSearchprofileStore: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  onReady: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  save: PropTypes.bool,
  autoFocus: PropTypes.string,
  storeName: PropTypes.string,
  contactAccessKey: PropTypes.string,
};

ContactSearchprofilesEditFormContainer.defaultProps = {
  save: false,
  autoFocus: null,
  storeName: "contactSearchprofiles",
  contactAccessKey: null,
};

const comp = connect(
  (state) => {
    return {
      contactSearchprofileStore: state.contactSearchprofiles,
    };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators({ ...contactSearchprofileActions }, dispatch),
    };
  }
)(ContactSearchprofilesEditFormContainer);

export default comp;
