import * as React from "react";

interface MainProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "className"> {}

const Main = ({ children, ...props }: MainProps) => {
  return (
    <div {...props} className="app__main">
      {children}
    </div>
  );
};

export default Main;
