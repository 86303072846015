interface SendGtagEventOptions {
  eventName: string;
  eventCategory: string;
  eventLabel?: string;
  eventParams?:
    | Gtag.ControlParams
    | Gtag.EventParams
    | Gtag.ConfigParams
    | Gtag.CustomParams;
}

export const sendEvent = ({
  eventName,
  eventCategory,
  eventLabel,
  eventParams,
}: SendGtagEventOptions): void => {
  if (!eventName) {
    return;
  }

  if (typeof window !== "undefined") {
    gtag("event", eventName, {
      event_category: eventCategory,
      event_label: eventLabel ?? eventName,
      ...eventParams,
    });
  }
};
