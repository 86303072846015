import { LanguageGroupData } from "components/contact/features/language-breakdown/LanguageBreakdownContainer";
import { useState } from "react";
import { RecipientSelectValueType } from "../../recipients-select/RecipientSelect/helpers/useRecipientSelect";

const DEFAULT_RECIPIENTS_LIMIT = 200;

export const useLanguageBreakdown = (
  recipientsLimit: number = DEFAULT_RECIPIENTS_LIMIT
) => {
  const [selectedRecipients, setSelectedRecipients] = useState<
    RecipientSelectValueType[] | undefined
  >(undefined);
  const [isLanguageBreakdownModalOpen, setIsLanguageBreakdownModalOpen] =
    useState(false);

  const handleLanguageBreakdownModalClose = () => {
    setIsLanguageBreakdownModalOpen(false);
  };

  const handleLanguageBreakdownOnDone = (
    selectedGroups: LanguageGroupData[]
  ): void => {
    setSelectedRecipients(
      selectedGroups
        .flatMap((group) =>
          group.contacts.map((contact) => ({ value: contact.id, contact }))
        )
        .slice(0, recipientsLimit)
    );
    setIsLanguageBreakdownModalOpen(false);
  };

  return {
    handleLanguageBreakdownModalClose,
    handleLanguageBreakdownOnDone,
    isLanguageBreakdownModalOpen,
    selectedRecipients,
    setIsLanguageBreakdownModalOpen,
  };
};
