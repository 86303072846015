import { useEffect, useMemo, useState } from "react";
import type { LanguageGroupData } from "../LanguageBreakdownContainer";
import {
  getInitialLanguageSelection,
  setAllKeys,
  type LanguageCheckboxStatus,
  type LanguagesChecked,
} from "./languageCheckboxHelper";

export const useLanguageCheckboxStatus = (
  languageGroups: LanguageGroupData[]
): LanguageCheckboxStatus => {
  const [languageChecked, setlanguageChecked] = useState<LanguagesChecked>({});

  useEffect(() => {
    const languageGroupsAreLoaded = !!languageGroups.length;
    const languageCheckboxesAreNotPopulated =
      !Object.entries(languageChecked).length;

    if (languageGroupsAreLoaded && languageCheckboxesAreNotPopulated) {
      setlanguageChecked(getInitialLanguageSelection(languageGroups));
    }
  }, [languageGroups, languageChecked]);

  const wholeSelectionChecked = useMemo<boolean>(() => {
    return Object.values(languageChecked).every(
      (langCheck) => langCheck === true
    );
  }, [languageChecked]);

  const handleLanguageCheck = (lang: string) => {
    setlanguageChecked((current) => ({ ...current, [lang]: !current[lang] }));
  };

  const handleWholeSelectionCheck = () => {
    if (wholeSelectionChecked) {
      setlanguageChecked(setAllKeys(languageChecked, false));
    } else {
      setlanguageChecked(setAllKeys(languageChecked, true));
    }
  };

  return {
    languageChecked,
    wholeSelectionChecked,
    handleLanguageCheck,
    handleWholeSelectionCheck,
  };
};
