import Switch from "@casasoft/styleguide/components/formElements/Switch";
import { useTranslation } from "react-i18next";
import { MessageBodyPreviewRecipientSelect } from "./MessageBodyPreviewRecipientSelect";
import cx from "classnames";
import { type ContactForPreview } from "../../helpers/useMessageBodyPreview/messageBodyPreviewHelpers";

interface MessageBodyPreviewControlsProps {
  handlePreviewToggle: (isOn: boolean) => void;
  handleContactChangeLeft: () => void;
  handleContactChangeRight: () => void;
  inPreviewMode: boolean;
  contacts: ContactForPreview[];
  activeContact: ContactForPreview | undefined;
  previewLoading: boolean;
  className?: string;
}

export const MessageBodyPreviewControls = ({
  inPreviewMode,
  handlePreviewToggle,
  handleContactChangeLeft,
  handleContactChangeRight,
  previewLoading,
  contacts,
  activeContact,
  className,
}: MessageBodyPreviewControlsProps) => {
  const { t } = useTranslation();

  return (
    <div className={cx("tw-flex", "tw-items-center", className)}>
      <div className="tw-flex tw-items-center tw-mr-8 tw-ml-[-8px]">
        <Switch
          checked={inPreviewMode}
          onToggle={handlePreviewToggle}
          className="tw-w-auto tw-mr-[-12px] tw-mb-0"
        />
        <span>{t("Display preview")}</span>
      </div>
      {inPreviewMode && (
        <MessageBodyPreviewRecipientSelect
          contacts={contacts}
          activeContact={activeContact}
          previewLoading={previewLoading}
          handleContactChangeLeft={handleContactChangeLeft}
          handleContactChangeRight={handleContactChangeRight}
        />
      )}
    </div>
  );
};
