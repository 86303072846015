import { ReactNode } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle as faUserCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { Media, UserRoles } from "utilities";
import { UserShape } from "api/entities/user/types";

interface UserIconProps {
  avatar?:
    | {
        mimeType?: string;
        path: string;
        orientation?: string | number | null;
      }
    | {
        _links: {
          self: {
            href: string; // this shape can occur when the avatar is coming from a deeply linked entity, which is why we need to handle it
          };
        };
      }
    | null;
  isLarge?: boolean;
}

const UserIcon = ({ avatar, isLarge = false }: UserIconProps) => {
  if (!avatar || "_links" in avatar) {
    return (
      <FontAwesomeIcon
        icon={isLarge ? faUserCircleSolid : faUserCircle}
        className={`tw-mr-1 ${isLarge ? "tw-text-cs-shade-300" : ""}`}
        size={isLarge ? "3x" : undefined}
        color="fillColor"
      />
    );
  }
  return (
    <img
      style={{
        height: isLarge ? "3em" : "1em",
        width: isLarge ? "3em" : "1em",
        display: "inline-block",
        verticalAlign: "-0.125em",
      }}
      alt="avatar"
      className="tw-rounded-full tw-mr-1"
      src={Media.mediaThumbnail(avatar, "sq")}
    />
  );
};

type UserExcerptProps = {
  user: UserShape;
  bigAvatar?: boolean;
  /* forces to only render first line (name/email) */
  isOneLiner?: boolean;
  lightName?: boolean;
  showEmail?: boolean;
  showPhoneNumbers?: boolean;
  showRole?: boolean;
};

const UserExcerpt = ({
  user,
  bigAvatar = false,
  isOneLiner = false,
  lightName = false,
  showEmail = false,
  showPhoneNumbers = false,
  showRole = true,
}: UserExcerptProps) => {
  const { t } = useTranslation();

  if (bigAvatar && isOneLiner) {
    throw new Error("Can't be both, oneLiner + bigAvatar");
  }

  const linesArr: {
    key: string;
    render: ReactNode;
    className?: string[];
  }[] = [];

  let displayName = user._embedded?.contact?.displayName;
  let fullName =
    (user._embedded?.contact?.firstName || user._embedded?.contact?.lastName) &&
    `${user._embedded?.contact?.firstName || ""} ${
      user._embedded?.contact?.lastName || ""
    }`.trim();

  // line 1: name / username
  linesArr.push({
    key: "name",
    render: (
      <span>
        {!bigAvatar && (
          <UserIcon avatar={user._embedded?.contact?._embedded?.avatar} />
        )}
        {displayName || fullName || user.username}
      </span>
    ),
    className: lightName ? [] : ["tw-font-semibold"],
  });

  if (!isOneLiner) {
    // line 2: email/username (default: hidden)
    if (showEmail) {
      // if no name was found -> email/username is already displayed as name
      if (fullName || displayName) {
        linesArr.push({
          key: "email",
          render: user.username,
        });
      }
    }

    // line 3: role (default: visible)
    if (showRole) {
      linesArr.push({
        key: "role",
        render:
          user.status === "inactive" ? (
            <span className="tw-text-cs-danger">{t("Deactivated")}</span>
          ) : user.roles?.length ? (
            UserRoles.getRoleLabel(user.roles[0])
          ) : (
            <span />
          ),
      });
    }
  }

  const bigAvatarNode = bigAvatar ? (
    <div style={{ marginRight: "0.5rem", alignSelf: "center" }}>
      <UserIcon avatar={user._embedded?.contact?._embedded?.avatar} isLarge />
    </div>
  ) : null;

  return (
    <div title={user.username} style={{ display: "flex" }}>
      {bigAvatarNode}
      <div
        style={{
          display: "flex",
          width: showPhoneNumbers ? "100%" : undefined, // only need width for 2 column layout
        }}
      >
        <div style={{ flex: "1 1 0%" }}>
          {linesArr.map((line) => (
            <div key={line.key} className={line.className?.join(" ")}>
              {line.render}
            </div>
          ))}
        </div>
        {showPhoneNumbers && (
          <div style={{ flex: "1 1 0%" }}>
            <div>T: {user._embedded?.contact?.phone || "–"}</div>
            <div>M: {user._embedded?.contact?.mobile || "–"}</div>
            <div>D: {user._embedded?.contact?.phoneDirect || "–"}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export { UserExcerpt as default, UserIcon };
