import Field from "@casasoft/styleguide/components/formElements/Field";
import Switch from "@casasoft/styleguide/components/formElements/Switch";
import DateField from "@casasoft/styleguide/components/formElements/DateField";
import Tinymce from "@casasoft/styleguide/components/formElements/tinymce/Tinymce";

import csMoment from "utilities/csMoment";
import PropertyRelationCard from "components/property/PropertyRelationCard";
import EventContactChooseCard from "components/event/EventContactChooseCard";
import { useTranslation } from "react-i18next";
import { RegisterFields } from "hooks/useForm";
import { FormModalOnFail } from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import { ErrorMessage } from "@hookform/error-message";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import Button from "@casasoft/styleguide/components/forms/Button";
import { EventShape } from "entities/event/types";
import {
  FormModalCols,
  FormModalColsItem,
} from "@casasoft/styleguide/components/form-modal";

import {
  TaskItemFormShape,
  useTaskEditForm,
} from "./features/task-edit/hooks/useTaskEditForm";
import { quickTaskResource } from "entities/event/quickTaskStore";
import { mainTaskResource } from "entities/event/mainTaskStore";
import { useForm } from "react-hook-form";

interface TasksEditFormProps {
  task?: EventShape; // if used as edit form
  onDone: (body: EventShape) => void;
  onFail: FormModalOnFail;
}

const legacyStoresRefresh = () => {
  // TODO: Remove following lines once taskStores (quickTaskStore and mainTaskStore) are migrated to RTK
  quickTaskResource.reloadList();
  mainTaskResource.reloadList();
};

function TasksEditForm({ onDone, onFail, task }: TasksEditFormProps) {
  const { t } = useTranslation();

  const isCreateForm = !task;
  const { onSubmit, formDefaultValues } = useTaskEditForm({
    onDone: (body: EventShape) => {
      legacyStoresRefresh();
      onDone(body);
    },
    onFail,
    task,
  });
  const { handleSubmit, formState, reset, control, trigger } =
    useForm<TaskItemFormShape>({
      defaultValues: formDefaultValues,
    });
  const { errors, isSubmitting, isDirty } = formState;

  return (
    <RegisterFields
      fields={{
        private: {},
        subject: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        endDate: {},
        endTime: {
          rules: {
            validate: (value) => {
              // allow empty value
              if (!value) {
                return;
              }

              const isValidFormat = /^[0-9]{2}:[0-9]{2}$/.test(value);
              if (!isValidFormat) {
                // looks this weird because of type interferances: https://github.com/react-hook-form/react-hook-form/issues/580#issuecomment-671330600
                const errMsg = t(
                  "Please input according to the requested format"
                );
                return typeof errMsg === "string" ? errMsg : "error";
              }
            },
          },
        },
        html: {},
        recipient: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        property: {},
      }}
      control={control}
      render={(fieldsRenderer) => (
        <form
          className="spinner-fixture"
          onSubmit={handleSubmit((data) => {
            reset();
            onSubmit(data);
          })}
          style={
            isSubmitting ? { opacity: 0.5, pointerEvents: "none" } : undefined
          }
        >
          <FormModalCols>
            <FormModalColsItem>
              {fieldsRenderer("private", (formValue, onFormValueChange) => (
                <Switch
                  label={t("Private")}
                  checked={formValue}
                  onToggle={(value) => {
                    onFormValueChange(value);
                  }}
                  message={{
                    type: "error",
                    text: <ErrorMessage errors={errors} name="private" />,
                  }}
                />
              ))}

              {fieldsRenderer("subject", (formValue, onFormValueChange) => (
                <Field
                  nobox
                  label={t("Task")}
                  value={formValue || undefined}
                  onChange={(value) => {
                    onFormValueChange(value || null);
                  }}
                  message={{
                    type: "error",
                    text: <ErrorMessage errors={errors} name="subject" />,
                  }}
                />
              ))}
            </FormModalColsItem>
            <FormModalColsItem>
              <FormModalCols>
                <FormModalColsItem>
                  {fieldsRenderer("endDate", (formValue, onFormValueChange) => (
                    <DateField
                      nobox
                      label={t("Deadline")}
                      value={formValue || undefined}
                      onChange={(value: string) => {
                        onFormValueChange(value);
                      }}
                      locale={csMoment().locale()}
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="endDate" />,
                      }}
                    />
                  ))}
                </FormModalColsItem>
                <FormModalColsItem>
                  {fieldsRenderer("endTime", (formValue, onFormValueChange) => (
                    <Field
                      nobox
                      label={t("Time")}
                      onChange={(value: string) => {
                        onFormValueChange(value?.replace(".", ":"));
                      }}
                      // validate on blur - we are not using the onDelayChange because it has a bug of firing the first time before any input actually happened
                      onBlur={() => {
                        trigger("endTime");
                      }}
                      value={formValue || undefined}
                      placeholder="HH:MM"
                      message={{
                        type: "error",
                        text: <ErrorMessage errors={errors} name="endTime" />,
                      }}
                    />
                  ))}
                </FormModalColsItem>
              </FormModalCols>
            </FormModalColsItem>
          </FormModalCols>
          {fieldsRenderer("html", (formValue, onFormValueChange) => (
            <Tinymce
              label={t("Notes")}
              value={formValue || undefined}
              placeholder=""
              onChange={(html) => {
                onFormValueChange(html || null);
              }}
              rows={6}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="html" />,
              }}
            />
          ))}
          <FormModalCols>
            <FormModalColsItem>
              {fieldsRenderer("recipient", (formValue, onFormValueChange) => (
                <EventContactChooseCard
                  contactType="user-contacts-only"
                  contactId={formValue || undefined}
                  headerTitle={t("Delegatee")}
                  chooserHeaderTitle={t("Choose user")}
                  onChange={(id) => {
                    onFormValueChange(id);
                  }}
                  validationErrorMessages={
                    errors.recipient
                      ? [<ErrorMessage errors={errors} name="recipient" />]
                      : undefined
                  }
                />
              ))}
            </FormModalColsItem>
            <FormModalColsItem>
              {fieldsRenderer("property", (formValue, onFormValueChange) => (
                <PropertyRelationCard
                  entityId={formValue || undefined}
                  headerTitle={t("Property")}
                  onChange={(id) => {
                    onFormValueChange(id || null);
                  }}
                />
              ))}
            </FormModalColsItem>
          </FormModalCols>
          {(isDirty || isCreateForm) && (
            <ModalFooter>
              <Button
                isPrimary
                type="submit"
                buttonValue={isCreateForm ? t("Create") : t("Save")}
              />
            </ModalFooter>
          )}
        </form>
      )}
    />
  );
}

export default TasksEditForm;
