import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
} from "@fortawesome/pro-light-svg-icons";

import { Thumb } from "../media";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Alert } from "../helpers-ux";
import {
  MediaChooserPropertyAttachments,
  MediaChooserPropertyAttachmentsProps,
} from "./MediaChooserPropertyAttachments/MediaChooserPropertyAttachments";
import { MediaChooserFilterSearch } from "./MediaChooserFilterSearch";
import { MediaShape } from "entities/mediaChooser/types";
import { CountBubble } from "../helpers/Bubbles/CountBubble";
import { PropertyAttachment } from "./MediaChooserPropertyAttachments/helpers/propertyAttachment.model";

interface MediaChooserProps {
  select: "multiple" | "single" | "none" | string;
  onSelectionChange: (medias: any) => void;
  postUrl?: string;
  postAuthorization?: string;
  fetchMedias?: (query: any) => Promise<void>;
  mediaUploaded: (newMedia: any) => void;
  media?: any;
  mediaStore: any;
  activeTab?: string;
  thumbnailUrl: (item: any) => string | null;
  selectedItemsNode: ReactNode;
  activeItem?: any;
  activeItemTags?: [];
  onSetSelectedItems: (items: any) => void;
  initialSelectedItems?: MediaShape[];
  accept?: Accept;
  onError?: (error: any) => void;
  mediaTypeFilter?: string;
  propertyTabProps?: MediaChooserPropertyAttachmentsProps | undefined;
}

interface FileStateItem {
  file: File;
  key: number;
  loaded: number;
  total: number;
  uploading: boolean;
  err?: string | JSX.Element[];
}

interface MediaChooserState {
  activeTab: string;
  selectedItems: MediaShape[];
  selectedPropertyAttachments: PropertyAttachment[];
  activeItem: any;
  files: FileStateItem[] | null;
  search: string;
  searchTags: string;
  loadingMediaList: boolean;
  activeItemTags: [];
  tagBucket: any;
  dropItemCounter: number;
  curQuery: {};
  page: number;
  mediaList: any;
  mediaListWrapper: any;
  validationErrors: string;
}

const MediaChooser = ({
  select = "multiple",
  onSelectionChange,
  postUrl = "http://localhost",
  postAuthorization,
  fetchMedias = () => {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000, "not-implemented");
    });
  },
  mediaUploaded,
  mediaStore,
  activeTab = "list",
  thumbnailUrl,
  selectedItemsNode,
  activeItem,
  onSetSelectedItems,
  initialSelectedItems,
  onError,
  accept,
  mediaTypeFilter,
  propertyTabProps,
}: MediaChooserProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<MediaChooserState>({
    activeTab: activeTab,
    selectedItems: initialSelectedItems || [],
    selectedPropertyAttachments: propertyTabProps?.initiallySelected || [],
    activeItem: null,
    files: null,
    search: "",
    searchTags: "",
    loadingMediaList: false,
    activeItemTags: [],
    tagBucket: null,
    dropItemCounter: 0,
    curQuery: {
      order_by: "created",
      order_dir: "desc",
    },
    page: 1,
    mediaList: {},
    mediaListWrapper: {},
    validationErrors: "",
  });

  const grabStore = useCallback(() => {
    let store: any = {
      items: [],
      listMetadata: {
        page: 1,
        pageCount: 1,
        pageSize: 100,
      },
    };
    if (mediaStore) {
      store = mediaStore;
    }

    return store;
  }, [mediaStore]);

  const loadMediaListPage = useCallback(
    (nextPage: number) => {
      const newQuery: any = Object.assign({}, state.curQuery, {
        page: nextPage,
      });
      setState((prevState) => ({ ...prevState, loadingMediaList: true }));

      fetchMedias(newQuery).then(() => {
        setState((prevState) => ({
          ...prevState,
          loadingMediaList: false,
          curQuery: newQuery,
        }));
      });
    },
    [fetchMedias, state.curQuery]
  );

  const mediaListWrapper = useRef<HTMLDivElement>(null);
  const mediaList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMediaListScroll = (event: any) => {
      const windowHeight =
        "innerHeight" in event.target
          ? event.target.innerHeight
          : event.target.offsetHeight;
      const docHeight = mediaList.current?.offsetHeight || 0;
      const curBottom = windowHeight + event.target.scrollTop;

      if (curBottom >= docHeight) {
        if (!state.loadingMediaList) {
          const nextPage =
            (mediaStore.items && state.page ? state.page : 1) + 1;

          if (
            mediaStore.items &&
            grabStore()?.listMetadata?.pageSize <
              grabStore()?.listMetadata?.totalItems
          ) {
            loadMediaListPage(nextPage);

            setState((prevState) => ({
              ...prevState,
              page: nextPage,
            }));
          }
        }
      }
    };
    mediaListWrapper.current?.addEventListener("scroll", handleMediaListScroll);

    const mediaListWrapperCurrent = mediaListWrapper.current;

    return () => {
      mediaListWrapperCurrent?.removeEventListener(
        "scroll",
        handleMediaListScroll
      );
    };
  }, [
    grabStore,
    loadMediaListPage,
    mediaStore.items,
    state.loadingMediaList,
    state.page,
  ]);

  const handleNavClick = (tab: string) => {
    setState((prevState) => ({ ...prevState, activeTab: tab }));
  };

  const handleItemClick = useCallback(
    (elem: any) => {
      // load tags
      if (elem._embedded && elem._embedded.tagRelations) {
        const activeItemTags = elem._embedded.tagRelations.map(
          (tagRelation: any) => {
            if (tagRelation._embedded && tagRelation._embedded.tag) {
              const tag = tagRelation._embedded.tag;
              return { value: tag.id, label: tag.name };
            }
            return null;
          }
        );
        setState((prevState) => ({ ...prevState, activeItemTags }));
      } else {
        setState((prevState) => ({ ...prevState, activeItemTags: [] }));
      }

      const items: any = state.selectedItems;
      const foundIndex = items.findIndex((item: any) => elem.id === item?.id);
      if (foundIndex > -1) {
        items.splice(foundIndex, 1);
        if (onSetSelectedItems) {
          onSetSelectedItems(null);
        }
      } else {
        if (select === "single") {
          items.splice(0, items.length);
        }
        items.push(elem);
      }
      setState((prevState) => ({ ...prevState, selectedItems: items }));
      onSelectionChange(items);
    },
    [onSelectionChange, onSetSelectedItems, select, state.selectedItems]
  );

  const handleClearClick = () => {
    setState((prevState) => ({ ...prevState, selectedItems: [] }));
    onSelectionChange([]);
    if (onSetSelectedItems) {
      onSetSelectedItems([]);
    }
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length) {
      // TODO: this means there was an error, show error message
      onError?.(fileRejections);
      return;
    }
    let dropItemCounter = state.dropItemCounter;
    const fileWrappers = acceptedFiles.map((file) => {
      dropItemCounter += 1;

      return {
        file,
        key: dropItemCounter,
        loaded: 0,
        total: 1,
        uploading: false,
      };
    });
    let newFiles = fileWrappers;
    if (state.files) {
      newFiles = state.files.concat(fileWrappers);
    }
    setState((prevState) => ({
      ...prevState,
      files: newFiles,
      dropItemCounter,
    }));
  };

  const onreadystatechange = useCallback(
    (file: FileStateItem, xhrObj: any) => {
      if (xhrObj.readyState === 4) {
        // if complete
        if (xhrObj.status === 200) {
          // check if "OK" (200)
          // success
        } else if (xhrObj.status === 422) {
          const newFiles = state.files?.map((elem) => {
            if (elem === file) {
              const newelem = Object.assign({}, elem);
              const responseObj = JSON.parse(xhrObj.responseText);
              if (
                responseObj.validation_messages &&
                responseObj.validation_messages.file
              ) {
                const errLines = Object.entries(
                  responseObj.validation_messages.file
                ).map((entry: any) => {
                  return (
                    <div key={entry[0]}>
                      {entry[0]}: {entry[1]}
                    </div>
                  );
                });
                newelem.err = errLines;
              }
              return newelem;
            }
            return elem;
          });
          setState((prevState) => ({ ...prevState, files: newFiles || null }));
        } else {
          const newFiles = state.files?.map((elem) => {
            if (elem === file) {
              const newelem = Object.assign({}, elem);
              newelem.err = `ERR ${xhrObj.status}`;
              return newelem;
            }
            return elem;
          });
          setState((prevState) => ({ ...prevState, files: newFiles || null }));
        }
      }
    },
    [state.files]
  );

  const uploadprogress = useCallback(
    (file: FileStateItem, loaded: number, total: number) => {
      const newFiles = state.files?.map((elem) => {
        const newElem = elem;
        if (elem === file) {
          newElem.loaded = loaded;
          newElem.total = total;
        }
        return newElem;
      });
      setState((prevState) => ({ ...prevState, files: newFiles || null }));
    },
    [state.files]
  );

  const transferComplete = useCallback(
    (file: FileStateItem, xhr: any) => {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          setState((prevState) => ({ ...prevState, files: [] }));
          const newMedia = JSON.parse(xhr.responseText);
          mediaUploaded(newMedia);
          handleItemClick(newMedia);
        }
      }
    },
    [handleItemClick, mediaUploaded]
  );

  const uploadMedias = useCallback(() => {
    state.files?.forEach((file) => {
      if (!file.uploading) {
        const newFiles = state.files?.map((elem) => {
          const newelem = elem;
          if (elem === file) {
            newelem.uploading = true;
          }
          return newelem;
        });
        setState((prevState) => ({ ...prevState, files: newFiles || null }));

        const data = new FormData();
        data.append("file", file.file);
        const xhrObj = new XMLHttpRequest();
        xhrObj.upload.addEventListener(
          "progress",
          (evt) => uploadprogress(file, evt.loaded, evt.total),
          false
        );
        xhrObj.onreadystatechange = () => onreadystatechange(file, xhrObj);
        xhrObj.open("POST", `${postUrl}/upload`, true);
        xhrObj.onload = () => transferComplete(file, xhrObj);
        xhrObj.setRequestHeader("Accept", "application/json");
        if (postAuthorization) {
          xhrObj.setRequestHeader("Authorization", postAuthorization);
        }
        xhrObj.send(data);
      }
    });
  }, [
    onreadystatechange,
    postAuthorization,
    postUrl,
    state.files,
    transferComplete,
    uploadprogress,
  ]);

  useEffect(() => {
    if (state.files) {
      uploadMedias();
      setState((prevState) => ({ ...prevState, validationErrors: "" }));
    }
  }, [state.files, uploadMedias]);

  const handleSearchChange = (value: string) => {
    setState((prevState) => ({ ...prevState, search: value }));
  };

  const handleSearchPersistance = (value: string) => {
    const newQuery = Object.assign({}, state.curQuery, {
      s: value,
      page: 1,
    });
    setState((prevState) => ({ ...prevState, loadingMediaList: true }));
    fetchMedias(newQuery).then(() => {
      setState((prevState) => ({
        ...prevState,
        loadingMediaList: false,
        curQuery: newQuery,
      }));
    });
  };

  const onTagsChange = (val: string) => {
    const newQuery = Object.assign({}, state.curQuery, {
      tags: val,
      page: 1,
    });
    setState((prevState) => ({ ...prevState, loadingMediaList: true }));
    setState((prevState) => ({ ...prevState, searchTags: val }));

    fetchMedias(newQuery).then(() => {
      setState((prevState) => ({
        ...prevState,
        loadingMediaList: false,
        curQuery: newQuery,
      }));
    });
  };

  let listUploadNodes: ReactNode[] = [];
  if (state.files) {
    listUploadNodes = state.files.map((elem) => {
      return (
        <div className="media-chooser__listItem" key={`u${elem.key}`}>
          <div className="media-chooser__listItemBox">
            <div
              className="media-chooser__listItem__preview"
              style={{
                backgroundImage: `url('${(elem.file as any).preview}')`,
              }}
            />
            {elem.err && (
              <div className="media-chooser__uploadErr">{elem.err}</div>
            )}
            {!elem.err && (
              <div className="media-chooser__uploadProgress">
                <div
                  className="media-chooser__uploadProgressBar"
                  style={{
                    width: `${Math.round((elem.loaded / elem.total) * 100)}%`,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    });
  }

  const listSNodes = grabStore().items?.map((elem: any, key: any) => {
    let selected = false;
    const classNames = ["media-chooser__listItemBox"];
    if (
      state.selectedItems.find(
        (selectedItem: any) => selectedItem?.id === elem.id
      )
    ) {
      selected = true;
      classNames.push("media-chooser__listItemBox--selected");
    }
    if (activeItem && activeItem.id === elem.id) {
      classNames.push("media-chooser__listItemBox--active");
    }

    return (
      <div
        role="button"
        tabIndex={-1}
        key={key}
        onClick={() => handleItemClick(elem)}
        className="media-chooser__listItem"
        title={elem.originalFilename}
      >
        <div className={classNames.join(" ")}>
          {selected && (
            <div className="media-chooser__listItemBox__selectedicon">
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          )}
          {selected && (
            <div className="media-chooser__listItemBox__removeicon">
              <FontAwesomeIcon icon={faMinusCircle} />
            </div>
          )}
          {!selected && (
            <div className="media-chooser__listItemBox__addicon">
              <FontAwesomeIcon icon={faPlusCircle} />
            </div>
          )}
          {elem && (
            <div className="media-chooser__listItem__content">
              <Thumb
                src={thumbnailUrl(elem)}
                mimeType={elem.mimeType}
                size={{ width: "100%", height: "100%" }}
                title={elem.originalFilename}
              />
            </div>
          )}
        </div>
      </div>
    );
  });

  const loadingListNodes = [];
  if (state.loadingMediaList) {
    const newRequestSize = 40;
    while (loadingListNodes.length < newRequestSize) {
      loadingListNodes.push(
        <div
          role="button"
          tabIndex={-1}
          key={state.page + loadingListNodes.length + 1}
          className="media-chooser__listItem media-chooser__listItem--loading"
        >
          <div className="media-chooser__listItemBox">
            <div className="media-chooser__listItem__content">
              <div className="media-chooser__listItem__icon"></div>
              <div className="media-chooser__listItem__title"></div>
            </div>
            <div className="media-chooser__listItem__preview" />
          </div>
        </div>
      );
    }
  }

  const listNodes = listUploadNodes.concat(listSNodes);

  return (
    <div>
      <div className="media-chooser__wrapper">
        <div className="media-chooser__navRow">
          <div className="media-chooser__navMenu">
            <button
              onClick={() => handleNavClick("uploader")}
              className={`media-chooser__navMenuItem ${
                state.activeTab === "uploader"
                  ? "media-chooser__activeNavMenuItem"
                  : ""
              }`}
            >
              {t("mediaChooser.uploadFile")}
            </button>
            <button
              onClick={() => handleNavClick("list")}
              className={`media-chooser__navMenuItem ${
                state.activeTab === "list"
                  ? "media-chooser__activeNavMenuItem"
                  : ""
              }`}
            >
              <CountBubble
                color="danger"
                count={state.selectedItems.length}
                show={select === "multiple" && state.selectedItems.length > 0}
                correctionTop={-8}
                correctionRight={-18}
              >
                {t("mediaChooser.mediaLibrary")}
              </CountBubble>
            </button>
            {!!propertyTabProps?.selectedProperty && (
              <button
                onClick={() => handleNavClick("property")}
                className={`media-chooser__navMenuItem ${
                  state.activeTab === "property"
                    ? "media-chooser__activeNavMenuItem"
                    : ""
                }`}
              >
                <CountBubble
                  color="danger"
                  count={state.selectedPropertyAttachments.length}
                  show={
                    select === "multiple" &&
                    state.selectedPropertyAttachments.length > 0
                  }
                  correctionTop={-8}
                  correctionRight={-18}
                >
                  {t("mediaChooser.propertyAttachments")}
                </CountBubble>
              </button>
            )}
          </div>
        </div>
        <div
          className={`media-chooser__contentRow media-chooser__contentListRow ${
            state.activeTab === "list"
              ? "media-chooser__activeContentListRow"
              : ""
          }`}
        >
          <div className="media-chooser__listContentWrapper">
            <MediaChooserFilterSearch
              searchString={state.search}
              onSearchChange={handleSearchChange}
              onDelaySearchChange={handleSearchPersistance}
              searchTags={state.searchTags}
              onTagsChange={onTagsChange}
            />
            <div ref={mediaListWrapper} className="media-chooser__listWrapper">
              <div ref={mediaList} className="media-chooser__list">
                {listNodes}
                {loadingListNodes}
              </div>
            </div>
            {state.selectedItems.length > 0 || activeItem ? (
              <div className="media-chooser__actionRow">
                {state.selectedItems.length > 0 ? (
                  <div className="media-chooser__chosenActions">
                    <div className="media-chooser__chosenText">
                      {state.selectedItems.length} {t("mediaChooser.chosen")}
                    </div>
                    <button
                      onClick={() => handleClearClick()}
                      className="media-chooser__chosenClearBtn"
                    >
                      {t("mediaChooser.clear")}
                    </button>
                  </div>
                ) : null}
                {state.selectedItems.length > 0 ? (
                  <ul className="media-chooser__chosenList">
                    {state.selectedItems.map((elem, key) => (
                      <li className="media-chooser__chosenLi" key={key}>
                        <div className="media-chooser__chosenImg">
                          {elem && thumbnailUrl(elem) && (
                            <div
                              className="media-chooser__chosenImg__img"
                              style={{
                                backgroundImage: `url('${thumbnailUrl(elem)}')`,
                              }}
                            />
                          )}
                          <div className="media-chooser__chosenImg__key">
                            {key + 1}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            ) : null}
          </div>
          {selectedItemsNode ? selectedItemsNode : null}
        </div>
        <div
          className={`media-chooser__contentRow media-chooser__contentUploadRow ${
            state.activeTab === "uploader"
              ? "media-chooser__activeContentUploadRow"
              : ""
          }`}
        >
          <Dropzone
            noDragEventsBubbling
            accept={accept}
            onDrop={(acceptedFiles, fileRejections) => {
              onDrop(acceptedFiles, fileRejections);

              if (fileRejections.length === 0) {
                setState((prevState) => ({ ...prevState, activeTab: "list" }));
              } else {
                const validationErrorCodes: string[] = [
                  "image-too-large",
                  "invalid-file-type",
                  "too-many-files",
                ];

                let validationErrors: string;
                fileRejections[0].errors.forEach((error) => {
                  let translatedMessage = "";
                  switch (error.code) {
                    case "too-many-files":
                      translatedMessage = t("Too many files");
                      break;
                    case "image-too-large":
                      translatedMessage = t(
                        "Image {{filename}} is larger than 10MB",
                        {
                          filename: fileRejections[0].file.name,
                        }
                      );
                      break;
                    case "invalid-file-type":
                      translatedMessage = t(
                        "The file type of {{filename}} is not supported.",
                        {
                          filename: fileRejections[0].file.name,
                        }
                      );
                      break;
                  }
                  validationErrors = validationErrorCodes.includes(error.code)
                    ? translatedMessage
                    : "";
                });

                setState((prevState) => ({
                  ...prevState,
                  validationErrors: validationErrors,
                }));
              }
            }}
            maxFiles={10}
            validator={(file) => {
              const maxImageSize = 10485760; // 10MB in bytes
              const acceptedMimeTypesImages = [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/svg",
                "image/svg%2Bxml",
                "image/svg+xml",
                "image/tiff",
              ];
              const acceptedMimeTypesDocuments = [
                "application/pdf",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml",
                "application/vnd.oasis.opendocument.spreadsheet",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/msword",
                "application/vnd.ms-word",
                "application/vnd.oasis.opendocument.text",
                "application/vnd.openxmlformats-officedocument.wordprocessingml",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/tiff",
              ];
              if (
                acceptedMimeTypesImages.includes(file.type) &&
                file.size > maxImageSize
              ) {
                return {
                  code: "image-too-large",
                  message: t(`Image {{filename}} is larger than 10MB`, {
                    filename: file.name,
                  }),
                };
              }

              if (
                !acceptedMimeTypesImages.includes(file.type) &&
                !acceptedMimeTypesDocuments.includes(file.type)
              ) {
                return {
                  code: "invalid-file-type",
                  message: t(
                    `The file type of {{filename}} is not supported.`,
                    {
                      filename: file.name,
                    }
                  ),
                };
              }
              return null;
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps({ className: "media-chooser__dropZone" })}
                >
                  <input {...getInputProps()} />
                  <div>
                    <div className="media-chooser__dropZoneText">
                      <div className="tw-mb-2">
                        {t("mediaChooser.dropOrClick")}
                      </div>
                      <div>{t("Max number of files")}: 10</div>
                      <div>{t("Max image file size")}: 10MB</div>
                      {mediaTypeFilter === "document" && (
                        <div className="tw-absolute tw-bottom-[2%] tw-left-[2%] tw-right-[2%]">
                          <Alert small color="info">
                            <div className="tw-text-left">
                              {t(
                                "Files containing high DPI (“Dots per Inch”) resolution or vector graphic information (files made for high printing quality) might cause issues within CASAONE."
                              )}
                            </div>
                            <div className="tw-text-left">
                              {t(
                                "Try using a media file version without such content in case of an error."
                              )}
                            </div>
                          </Alert>
                        </div>
                      )}
                    </div>

                    <div className="tw-text-cs-danger">
                      {state.validationErrors}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <div
          className={`media-chooser__contentRow media-chooser__contentListRow ${
            state.activeTab === "property"
              ? "media-chooser__activeContentListRow"
              : ""
          }`}
        >
          {propertyTabProps && (
            <MediaChooserPropertyAttachments
              {...propertyTabProps}
              onSelectionChange={(newSelection: PropertyAttachment[]) => {
                const onlyUnique = (
                  value: PropertyAttachment,
                  index: number,
                  array: PropertyAttachment[]
                ) => {
                  return array.indexOf(value) === index;
                };

                const uniqueSelection = newSelection.filter(onlyUnique);

                setState((prevState) => ({
                  ...prevState,
                  selectedPropertyAttachments: uniqueSelection,
                }));
                propertyTabProps.onSelectionChange &&
                  propertyTabProps.onSelectionChange(uniqueSelection);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaChooser;
