import {
  Card,
  CardHeader,
  CardBody,
  CardPointsColsItems,
  CardBodyCols,
} from "@casasoft/styleguide/components/card";

import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { FormModal } from "@casasoft/styleguide/components/form-modal";

import { useTranslation } from "react-i18next";
import csMoment from "utilities/csMoment";
import { getLanguageLabel } from "utilities/languages";

import ContactEditForm from "./ContactEditForm";
import { renderSalutation } from "utilities/salutation";
import {
  CivilStatuses,
  ContactStatuses,
  Countries,
  Genders,
  ResidencePermits,
} from "utilities";
import { PointsShape } from "@casasoft/styleguide/utilities/differ";
import { ContactShape } from "entities/contact/types";
import { useState } from "react";
import { useMainContact } from "entities/contact/mainStore";

type ContactEditFormProps = React.ComponentProps<typeof ContactEditForm>;

interface ContactDetailsCardProps {
  contact: ContactShape;
  publicContactSettings?: {
    onSubmit: ContactEditFormProps["onSubmit"];
  };
}

function ContactDetailsCard({
  contact,
  publicContactSettings,
}: ContactDetailsCardProps) {
  const isPublicCard = !!publicContactSettings;
  const { t } = useTranslation();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const { updateItem: updateMainContactsItem } = useMainContact();

  function grabDatapoints() {
    const dataPoints: PointsShape[] = [];
    if (!isPublicCard) {
      dataPoints.push({
        key: "status",
        label: t("Status"),
        value: contact.status
          ? ContactStatuses.getContactStatusLabel(contact.status)
          : "",
      });
      dataPoints.push({
        key: "visualReferenceId",
        label: t("standards:Reference no."),
        value: contact.visualReferenceId ? contact.visualReferenceId : "",
      });
      if (contact.entityType !== "individual") {
        dataPoints.push({
          key: "entityType",
          label: t("Entität"),
          value: `${t("Legal entity")}`,
        });
      } else {
        dataPoints.push({
          key: "entityType",
          label: t("Entität"),
          value: `${t("Individual entity")}`,
        });
      }
    }
    if (contact.entityType === "individual") {
      dataPoints.push(
        {
          key: "displayName",
          label: t("Display name"),
          value: contact.displayName ? contact.displayName : "",
        },
        {
          key: "gender",
          label: t("Gender"),
          value: contact.gender
            ? Genders.getGenderLabel(`${contact.gender}`)
            : "",
        }
      );
    } else {
      dataPoints.push({
        key: "displayName",
        label: t("Legal name"),
        value: contact.displayName ? contact.displayName : "",
      });
    }
    if (contact.entityType !== "individual") {
      dataPoints.push(
        {
          key: "uid",
          label: t("UID-Nr."),
          value: contact.uid ? contact.uid : "",
        },
        {
          key: "honorific",
          label: t("Honorific"),
          value: contact.honorific ? contact.honorific : "",
        },
        {
          key: "lastName",
          label: t("Last name"),
          value: contact.lastName ? contact.lastName : "",
        }
      );
      dataPoints.push({
        key: "firstName",
        label: t("First name"),
        value: contact.firstName ? contact.firstName : "",
      });
    } else {
      dataPoints.push({
        key: "contactPerson",
        label: t("Contact person"),
        value:
          contact.firstName || contact.lastName
            ? `${contact.firstName || ""} ${contact.lastName || ""}`.trim()
            : "",
      });
    }
    if (contact.entityType === "individual") {
      dataPoints.push(
        {
          key: "profession",
          label: t("Profession"),
          value: contact.profession ? contact.profession : "",
        },
        {
          key: "function",
          label: t("Function"),
          value: contact.function ? contact.function : "",
          renderedValue: contact.function ? (
            <div dangerouslySetInnerHTML={{ __html: contact.function }} />
          ) : (
            ""
          ),
        },
        {
          key: "dob",
          label: t("Date of birth"),
          value: contact.dob
            ? csMoment(contact.dob.date).format("DD.MM.YYYY")
            : "",
        },
        {
          key: "pob",
          label: t("Place of birth"),
          value: contact.pob ? contact.pob : "",
        },
        {
          key: "civilStatus",
          label: t("Civil status"),
          value: contact.civilStatus
            ? CivilStatuses.getCivilStatusLabel(`${contact.civilStatus}`)
            : "",
        },
        {
          key: "poco",
          label: t("Place of civil origin"),
          value: contact.poco ? contact.poco : "",
        },
        {
          key: "nationality",
          label: t("Nationality"),
          value: contact.nationality
            ? Countries.getCountryLabel(contact.nationality)
            : "",
        },
        {
          key: "permit",
          label: t("Residence permit"),
          value: contact.permit
            ? ResidencePermits.getResidencePermitsLabel(contact.permit)
            : "",
        }
      );
    }
    dataPoints.push({
      key: "lang",
      label: t("Primary language"),
      value: contact.lang ? getLanguageLabel(contact.lang) : "",
    });
    if (contact.entityType === "individual") {
      const renderedSalutation = renderSalutation(contact);
      dataPoints.push({
        key: "salutationTemplate",
        label: t("Salutation"),
        value: renderedSalutation || "",
      });
    }
    if (!isPublicCard) {
      dataPoints.push({
        key: "email",
        label: t("E-Mail"),
        value: contact.email ? contact.email : "",
        renderedValue: contact.email ? (
          <a href={`mailto:${contact.email}`} title={contact.email}>
            {contact.email}
          </a>
        ) : (
          ""
        ),
      });
    }
    dataPoints.push({
      key: "email2",
      label: `${t("E-Mail")} 2`,
      value: contact.email2 ? contact.email2 : "",
      renderedValue: contact.email2 ? (
        <a href={`mailto:${contact.email2}`} title={contact.email2}>
          {contact.email2}
        </a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "phone",
      label: t("Phone"),
      value: contact.phone,
      renderedValue: contact.phone ? (
        <a href={`tel:${contact.phone}`}>{contact.phone}</a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "phoneDirect",
      label: t("Phone direct"),
      value: contact.phoneDirect,
      renderedValue: contact.phoneDirect ? (
        <a href={`tel:${contact.phoneDirect}`}>{contact.phoneDirect}</a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "mobile",
      label: t("Mobile"),
      value: contact.mobile,
      renderedValue: contact.mobile ? (
        <a href={`tel:${contact.mobile}`}>{contact.mobile}</a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "fax",
      label: t("Fax"),
      value: contact.fax ? contact.fax : "",
      renderedValue: contact.fax ? (
        <a href={`tel:${contact.fax}`}>{contact.fax}</a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "twitter",
      label: t("Twitter"),
      value: contact.twitter,
      renderedValue: contact.twitter ? (
        <a href={contact.twitter} target="_blank" rel="noopener noreferrer">
          {contact.twitter}
        </a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "linkedin",
      label: t("linkedIn"),
      value: contact.linkedin,
      renderedValue: contact.linkedin ? (
        <a href={contact.linkedin} target="_blank" rel="noopener noreferrer">
          {contact.linkedin}
        </a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "facebook",
      label: t("Facebook"),
      value: contact.facebook,
      renderedValue: contact.facebook ? (
        <a href={contact.facebook} target="_blank" rel="noopener noreferrer">
          {contact.facebook}
        </a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "instagram",
      label: t("Instagram"),
      value: contact.instagram,
      renderedValue: contact.instagram ? (
        <a href={contact.instagram} target="_blank" rel="noopener noreferrer">
          {contact.instagram}
        </a>
      ) : (
        ""
      ),
    });
    dataPoints.push({
      key: "websiteUrl",
      label: t("Website"),
      value: contact.websiteUrl,
      renderedValue: contact.websiteUrl ? (
        <a href={contact.websiteUrl} target="_blank" rel="noopener noreferrer">
          {contact.websiteUrl}
        </a>
      ) : (
        ""
      ),
    });

    if (!isPublicCard) {
      let tagsRendered = "";
      if (contact._embedded?.tags) {
        tagsRendered = contact._embedded.tags.map((x) => x.name).join(", ");
      }
      dataPoints.push({
        key: "tags",
        label: t("Tags"),
        value: tagsRendered,
      });

      if (contact.contactType === null) {
        dataPoints.push({
          key: "notes",
          label: t("Notes"),
          value: contact.notes,
          renderedValue: contact.notes ? (
            <div dangerouslySetInnerHTML={{ __html: contact.notes }} />
          ) : (
            ""
          ),
        });
      }
    }

    return dataPoints;
  }

  const points = grabDatapoints().filter(
    (point) => point.value || point.value === 0 // filter out empty values
  );

  return (
    <>
      <Card>
        <CardHeader
          actions={
            contact?.status !== "ignore"
              ? [
                  {
                    icon: faEdit,
                    onClick: () => {
                      setEditModalOpen(true);
                    },
                  },
                ]
              : undefined
          }
        >
          {t("Contact Details")}
        </CardHeader>
        <CardBody>
          {contact.status === "ignore" ? (
            <div>{t("This Contact got deleted")}</div>
          ) : (
            <CardBodyCols
              onClick={() => {
                setEditModalOpen(true);
              }}
            >
              <CardPointsColsItems points={points} />
            </CardBodyCols>
          )}
        </CardBody>
      </Card>
      <FormModal
        isOpen={editModalOpen}
        onDone={() => {
          setEditModalOpen(false);
        }}
        form={(props) => (
          <ContactEditForm
            onDone={props.onDone}
            onFail={props.onFail}
            isPublicEditForm={isPublicCard}
            onSubmit={async (data, dirtyData) => {
              if (isPublicCard) {
                await publicContactSettings?.onSubmit(data, dirtyData);
              } else {
                const tagsMap = dirtyData?.tags
                  ?.split(",")
                  .map((tag) => ({ name: tag }));
                await updateMainContactsItem(
                  contact.id,
                  {
                    ...dirtyData,
                    tags: tagsMap || dirtyData?.tags,
                  },
                  "list"
                );
              }
            }}
            contact={contact}
          />
        )}
        header={t("Edit contact")}
        size="lg"
        onCancel={() => {
          setEditModalOpen(false);
        }}
      />
    </>
  );
}

export default ContactDetailsCard;
