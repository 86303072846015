import Field from "@casasoft/styleguide/components/formElements/Field";
import { ErrorMessage } from "@hookform/error-message";
import { type FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface MessageSubjectProps {
  value: string | undefined;
  onChange: (value: string) => void;
  errors: FieldErrors<{ subject: string }>;
  className?: string;
}

export const MessageSubject = ({
  value = "",
  onChange,
  errors,
  className,
}: MessageSubjectProps) => {
  const { t } = useTranslation();

  return (
    <Field
      nobox
      label={t("Subject")}
      required
      value={value}
      onChange={onChange}
      message={{
        type: "error",
        text: <ErrorMessage errors={errors} name="subject" />,
      }}
      className={className}
    />
  );
};
