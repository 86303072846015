import { Component } from "react";
import PropTypes from "prop-types";
import PriceField from "@casasoft/styleguide/components/forms/PriceField";
import { withTranslation } from "react-i18next";

class PropertyExtraCostsTotalField extends Component {
  __getCombinedSegments(inputpropertySegment, timeSegment) {
    // dont trust the propertysegment if its not year (workaround for now!)
    const propertySegment = inputpropertySegment;
    return [
      {
        label: ` ${this.props.t("standards:year")} `,
        id: "year",
        time: {
          value: "y",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "year",
        set: propertySegment === "all" && timeSegment === "y",
      },
      {
        label: ` ${this.props.t("standards:year")} ${this.props.t(
          "standards:per"
        )} ${this.props.t("standards:m²")} `,
        id: "year_m2",
        time: {
          value: "y",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "m2",
        },
        value: "year_m2",
        set: propertySegment === "m" && timeSegment === "y",
      },
      {
        label: ` ${this.props.t("standards:month")} `,
        id: "month",
        time: {
          value: "m",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "month",
        set:
          !propertySegment ||
          !timeSegment ||
          (propertySegment === "all" && timeSegment === "m"),
      },
      {
        label: ` ${this.props.t("standards:week")} `,
        id: "week",
        time: {
          value: "w",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "week",
        set: propertySegment === "all" && timeSegment === "w",
      },
      {
        label: ` ${this.props.t("standards:day")} `,
        id: "day",
        time: {
          value: "d",
        },
        frequency: {
          value: 1,
        },
        property: {
          value: "all",
        },
        value: "day",
        set: propertySegment === "all" && timeSegment === "d",
      },
    ];
  }

  render() {
    return (
      <PriceField
        nobox={this.props.nobox}
        id={this.props.id}
        prefix={this.props.currency}
        label={this.props.t("Extracosts")}
        combinedSegments={this.__getCombinedSegments(
          this.props.rentPricePropertySegment,
          this.props.rentPriceTimeSegment
        )}
        name={this.props.id}
        value={this.props.extraCostsTotal}
        placeholder={this.props.t("standards:On Request")}
        onChange={(value) => {
          const changeSet = [];
          if (
            this.props.extraCostsTotal !== value.input.value.replace("'", "")
          ) {
            this.props.onChange?.("extraCostsTotal", value.input.value);
            changeSet.push({
              name: "extraCostsTotal",
              value: value.input.value,
            });
          }
          if (
            value &&
            value.segments &&
            value.segments.combinedSegment &&
            value.segments.combinedSegment.setOption
          ) {
            if (
              value.segments.combinedSegment.setOption.property &&
              value.segments.combinedSegment.setOption.property.value
            ) {
              changeSet.push({
                name: "rentPricePropertySegment",
                value:
                  value.segments.combinedSegment.setOption.property.value.replace(
                    /[0-9]/g,
                    ""
                  ),
              });
            }
            if (
              value.segments.combinedSegment.setOption.time &&
              value.segments.combinedSegment.setOption.time.value
            ) {
              changeSet.push({
                name: "rentPriceTimeSegment",
                value: value.segments.combinedSegment.setOption.time.value,
              });
            }
          }
          this.props.onMultiFieldChange?.(changeSet);
        }}
        onBlur={(value) => {
          this.props.onBlur?.("extraCostsTotal", value.input.value);
        }}
        thousandSeparator="'"
        message={this.props.message}
        required={this.props.required}
      />
    );
  }
}

PropertyExtraCostsTotalField.propTypes = {
  extraCostsTotal: PropTypes.string,
  currency: PropTypes.string,
  rentPricePropertySegment: PropTypes.string,
  rentPriceTimeSegment: PropTypes.string,
  onChange: PropTypes.func,
  onMultiFieldChange: PropTypes.func,
  onBlur: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  id: PropTypes.string,
  nobox: PropTypes.bool,
  required: PropTypes.bool,
};

PropertyExtraCostsTotalField.defaultProps = {
  extraCostsTotal: null,
  currency: "CHF",
  rentPricePropertySegment: "",
  rentPriceTimeSegment: "",
  message: [],
  id: "extraCostsTotal",
  nobox: true,
  required: false,
};

export default withTranslation()(PropertyExtraCostsTotalField);
