import { GetUserListQueryParams, GetUserListResponse } from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetUserListResponse,
  GetUserListQueryParams
>((result) => {
  if (result) {
    // generate tags for each user
    const userTags = result._embedded.user.map((item) => ({
      type: "User" as const,
      id: item.username,
    }));

    // merge user tags with static tags
    return [
      ...userTags,
      {
        type: "User",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["User"];
});

export default providesTags;
