import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "volumeSiaGv",
    label: "SIA 416",
  },
  {
    value: "volumeSia116Gv",
    label: "SIA 116",
  },
  {
    value: "volumeGva",
    label: "GVA",
  },
  {
    value: "volumeUnknownGv",
    label: "Unknown definition",
  },
];

export const getVolumeTypes = createGetList(untranslatedList);
export const getVolumeTypeLabel = createGetLabel(untranslatedList);

export const useVolumeTypes = createUtilityHook(untranslatedList);
