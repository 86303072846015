import { FetchProfileCasaoneUserShape } from "utilities/api";

type GetFieldsArgs = {
  t: (key: string) => string;
  contact: FetchProfileCasaoneUserShape["contact"];
};

export const getFields = ({ t, contact }: GetFieldsArgs) => {
  const fields = [
    {
      key: "email",
      label: t("Email"),
      completed: true,
    },
    {
      key: "lang",
      label: t("Language"),
      completed: contact.lang,
    },
    {
      key: "name",
      label: t("First and Lastname"),
      completed: contact.firstName && contact.lastName,
    },
    {
      key: "gender",
      label: t("Salutation"),
      completed: contact.gender,
    },
    {
      key: "dob",
      label: t("Date of birth"),
      completed: contact.dob,
    },
  ];

  const numCompletedFields = fields.filter((f) => f.completed).length;

  return { fields, numCompletedFields };
};
