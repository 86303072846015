import Config from "config";
import { TableListQuery } from "hooks/table-list/useTableListQuery";
import { useDownload } from "hooks/useDownload";
import { useTranslation } from "react-i18next";
import csMoment from "utilities/csMoment";
import queryBuilder, { FilterItem } from "utilities/queryBuilder";

export interface DownloadListArgs {
  type: "csv" | "xls" | "pdf";
  toggleModalLoader: (arg: string | null) => void;
  query: TableListQuery;
  lang: string;
  propertyType?: string;
}

export function useDownloadList() {
  const { t } = useTranslation();
  const { downloadFile } = useDownload();
  const downloadList = async ({
    type,
    query,
    toggleModalLoader,
    lang,
    propertyType,
  }: DownloadListArgs) => {
    const baseUrl = `${Config.apiUrl}/${Config.customerKey}/property?`;

    const queryfilter: FilterItem[] = [
      {
        type: "neq",
        field: "status",
        value: "ignore",
      },
      ...query.filter,
    ];

    if (propertyType) {
      queryfilter.push({
        type: "eq",
        field: "propertyType",
        value: propertyType,
      });
    }

    const builtQuery = queryBuilder.buildToUrl(query.orderBy, queryfilter, {
      context: "property-list",
      pagesize: 100,
      page: 1,
      output: type,
      lang: lang,
    });

    const name = `${t(
      propertyType ? propertyType : "Property"
    )}_${csMoment().format("DD_MM_YYYY_hh_mm_ss")}.${type}`;

    await downloadFile({
      toggleModalLoader,
      fileName: name,
      url: `${baseUrl}${builtQuery}`,
      loadingMessage: t("...Loading"),
    });
  };

  return { downloadList };
}
