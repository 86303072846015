import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseEventCreator, { EventResourceTypes } from "./baseEventCreator";

const name = "quickTask" as const;
const pluralName = "quickTasks" as const;

export const { types, actions, rootReducer }: EventResourceTypes =
  createResource(
    baseEventCreator({
      name,
      pluralName,
      apiContext: "quicktask-list",
      forcedFilter: [
        {
          type: "eq",
          field: "type",
          value: "task",
        },
        // {
        //   type: "orx",
        //   conditions: [
        //     {
        //       type: "neq",
        //       field: "doneReason",
        //       value: "ignore",
        //     },
        //     {
        //       type: "isnull",
        //       field: "doneReason",
        //     },
        //   ],
        //   where: "and",
        // },
      ],
      forcedOrderBy: [
        // {
        //   type: "field",
        //   field: "start",
        //   direction: "desc",
        // },
      ],
    })
  );

const quickTaskResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const quickTaskResource = quickTaskResourceCreator.resource;
export const useQuickTask = quickTaskResourceCreator.createHook();
