import { forwardRef } from "react";
import * as Checkbox from "@radix-ui/react-checkbox";

interface CheckboxNewProps
  extends Omit<
    React.ComponentProps<typeof Checkbox.Root>,
    "className" | "checked" | "onCheckedChange"
  > {
  checked: boolean;
  onToggle?: (newValue: boolean) => void;
  iconStyle?: "checkmark" | "deselect-line";
  rounded?: boolean;
  transparentBg?: boolean;
  darkChecked?: boolean;
  className?: string;
}

/** Render a beautiful checkbox :) */
const CheckboxNew = forwardRef<HTMLButtonElement, CheckboxNewProps>(function (
  {
    children,
    checked,
    onToggle,
    iconStyle = "checkmark",
    rounded = true,
    transparentBg = false,
    darkChecked = false,
    className = "",
    ...props
  },
  ref
) {
  let iconStyleClasses =
    "after:tw-top-1/2 after:tw-left-1/2 after:-tw-translate-x-[60%] after:-tw-translate-y-[60%] after:tw-border-2 after:tw-w-1 after:tw-h-2 after:tw-border-t-0 after:tw-border-l-0 after:tw-rotate-45";
  if (iconStyle === "deselect-line") {
    iconStyleClasses =
      "after:tw-top-1/2 after:tw-left-1/2 after:-tw-translate-x-1/2 after:-tw-translate-y-1/2 after:tw-w-2 after:tw-h-0 after:tw-border-0 after:tw-border-b-2";
  }

  return (
    <Checkbox.Root
      {...props}
      ref={ref}
      className={`tw-relative tw-w-4 tw-h-4 ${
        rounded ? "tw-rounded-full" : "tw-rounded"
      } tw-appearance-none tw-border tw-transition-colors tw-border-cs-shade-300 tw-border-solid ${
        !transparentBg ? "tw-bg-cs-shade-100" : ""
      } tw-cursor-pointer ${className}`}
      checked={checked}
      onCheckedChange={(checked) => {
        if (!props.disabled) {
          onToggle?.(!checked);
        }
      }}
    >
      <Checkbox.Indicator
        className={`tw-absolute tw-inset-0 ${
          rounded ? "tw-rounded-full" : "tw-rounded"
        }  ${
          darkChecked
            ? "tw-bg-tw-cs-accent-color-dark"
            : "tw-bg-tw-cs-accent-color"
        }
        ${iconStyleClasses} after:tw-border-transparent after:tw-border-cs-shade-50 after:tw-pointer-events-none after:tw-transition-colors after:tw-content-[''] after:tw-border-solid after:tw-block after:tw-relative`}
      />
    </Checkbox.Root>
  );
});

export default CheckboxNew;
