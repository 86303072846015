import Config from "config";
import createBaseActions from "entities/createBaseActions";
import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import CompanyEmailSettingUserState from "./types";

const name = "mainCompanyEmailSettingUser" as const;
const pluralName = "mainCompanyEmailSettingUsers" as const;

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: CompanyEmailSettingUserState,
    actions?: any
  ) => CompanyEmailSettingUserState;
}

export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: name,
  pluralName: pluralName,
  url: `${Config.apiUrl}/${Config.customerKey}/user-email-setting/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "user_email_setting",
      forcedFilter: [
        {
          type: "eq",
          field: "companyEmailSetting",
          alias: "emailSettingJoin",
          value: "1",
        },
        {
          type: "innerjoin",
          field: "emailSetting",
          alias: "emailSettingJoin",
        },
        // {
        //   type: "isnotnull",
        //   field: "id",
        //   alias: "emailSettingJoin",
        // },
      ],
    }),
  },
});

const mainCompanyEmailSettingUserResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const mainCompanyEmailSettingUserResource =
  mainCompanyEmailSettingUserResourceCreator.resource;
export const useMainCompanyEmailSettingUser =
  mainCompanyEmailSettingUserResourceCreator.createHook();
