import { Alert } from "@casasoft/styleguide/components/helpers-ux";
import * as Progress from "@radix-ui/react-progress";
import ContactExcerpt from "components/contact/features/contact-excerpt/ContactExcerpt";
import { useTranslation } from "react-i18next";
import { type SentMessageMetaData } from "./SendingMessageContainer";

interface SendingMessageProps {
  messageCount: number;
  successfullMessages: number;
  failedMessages: number;
  sendCanceled: boolean;
  sentMessages: SentMessageMetaData[];
}

const ratioToPercentage = (n: number, of: number) => {
  return (100 / of) * n;
};

export const SendingMessage = ({
  messageCount,
  successfullMessages,
  failedMessages,
  sendCanceled,
  sentMessages,
}: SendingMessageProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <>
        <div className="tw-text-center">
          {t("Sending messages ...")} ({successfullMessages + failedMessages}{" "}
          {t("from")} {messageCount})
        </div>
        <Progress.Root className="tw-overflow-hidden tw-bg-cs-shade-300/50 tw-rounded-full tw-flex tw-h-3">
          <Progress.Indicator
            className="tw-transition-[width] tw-bg-cs-success"
            style={{
              width: `${ratioToPercentage(successfullMessages, messageCount)}%`,
            }}
          />
          <Progress.Indicator
            className="tw-transition-[width] tw-bg-cs-danger"
            style={{
              width: `${ratioToPercentage(
                sendCanceled ? failedMessages - 1 : failedMessages, // -1 because the message that displays the error in the list is also put into the state
                messageCount
              )}%`,
            }}
          />
          <Progress.Indicator
            className="tw-transition-[width] tw-bg-cs-warning"
            style={{
              width: `${ratioToPercentage(
                sendCanceled
                  ? messageCount - (successfullMessages + failedMessages) + 1
                  : 0, // +1 because the message that displays the error in the list is also put into the state
                messageCount
              )}%`,
            }}
          />
        </Progress.Root>
        {successfullMessages + failedMessages > 0 && ( // check if any message was sent
          <div className="tw-mt-2 tw-mb-2">
            {messageCount > 1 &&
              successfullMessages + failedMessages === messageCount && (
                <Alert color="info">{t("Done")}</Alert>
              )}
            {sentMessages.map((sentMessage, i) => {
              if (sentMessage.error) {
                return (
                  <Alert key={i} color="danger">
                    ERR{" "}
                    {sentMessage.contactFull ? (
                      <ContactExcerpt
                        contact={sentMessage.contactFull}
                        lines={1}
                        linkTo
                      />
                    ) : (
                      <div>{sentMessage.contactSimple?.email}</div>
                    )}
                    <div>{sentMessage.error}</div>
                  </Alert>
                );
              }
              return (
                <Alert key={i} color="success">
                  {sentMessage.contactSimple?.email}
                </Alert>
              );
            })}
          </div>
        )}
      </>
    </div>
  );
};
