import { useState } from "react";
import { type MessageAttachment } from "../../create-message/MessageMiscControls/MessageAttachFilesControl";
import { useTranslation } from "react-i18next";
import { mapMediaToMessageAttachment } from "./mediaMapper";
import { PropertyAttachment } from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/helpers/propertyAttachment.model";

interface UseMessageMediaProps {
  attachments: MessageAttachment[];
  onChange?: (media: MessageAttachment[]) => void;
  onSubmit?: (media: MessageAttachment[]) => void;
  propertyAttachments: PropertyAttachment[];
  onSubmitPropertyAttachments: (media: PropertyAttachment[]) => void;
}

export const useMessageMedia = ({
  attachments,
  onChange,
  onSubmit,
  propertyAttachments,
  onSubmitPropertyAttachments,
}: UseMessageMediaProps) => {
  const { t } = useTranslation();

  const [selectedAttachments, setSelectedAttachments] =
    useState<MessageAttachment[]>(attachments);
  const [selectedPropertyAttachments, setSelectedPropertyAttachments] =
    useState<PropertyAttachment[]>(propertyAttachments);

  const handleMediaChange = (medias: any[]) => {
    const newSelection = medias.map((media, i) =>
      mapMediaToMessageAttachment({
        media,
        rank: selectedAttachments.length + 2 + i,
        defaultTitle: t("Attachment"),
      })
    );

    if (onChange) {
      onChange(newSelection);
    }

    setSelectedAttachments([...newSelection]);
  };

  const handleMediaSubmit = () => {
    if (onSubmit) {
      onSubmit(selectedAttachments);
    }

    if (onSubmitPropertyAttachments) {
      onSubmitPropertyAttachments(selectedPropertyAttachments);
    }
  };

  const handlePropertyMediaChange = (medias: PropertyAttachment[]) => {
    setSelectedPropertyAttachments([...medias]);
  };

  return {
    handleMediaChange,
    handleMediaSubmit,
    handlePropertyMediaChange,
  };
};
