const baseFields = [
  "id",
  "type",
  "subject",
  "threadUpdated",
  "status",
  "private",
  "doneReason",
  "events.type",
  "events.doneReason",
  "attendees.private",
  "attendees_contact.id",
  "attendees_contact.displayName",
  "attendees_contact.firstName",
  "attendees_contact.lastName",
  "attendees_contact.email",
  "attendees_contact.phone",
  "attendees_contact.visualReferenceId",
  "attendees_contact.entityType",
  "attendees_contact.contactType",
  "recipient_contact.id",
  "recipient_contact.displayName",
  "recipient_contact.firstName",
  "recipient_contact.lastName",
  "recipient_contact.email",
  "recipient_contact.phone",
  "recipient_contact.visualReferenceId",
  "recipient_contact.entityType",
  "recipient_contact.contactType",
  "recipient_contact_employer.displayName",
  "recipient_contact_employer.firstName",
  "recipient_contact_employer.lastName",
  "recipient_contact_employer.status",
  "recipient_contact_avatar.path",
  "recipient_contact_avatar.originalFilename",
  "recipient_contact_avatar.alt",
  "recipient_contact_avatar.size",
  "recipient_contact_avatar.width",
  "recipient_contact_avatar.height",
  "recipient_contact_avatar.crop1x1",
  "recipient_contact_avatar.mimeType",
  "recipient_contact_avatar.viewGroup",
  "sender_contact.id",
  "sender_contact.displayName",
  "sender_contact.firstName",
  "sender_contact.lastName",
  "sender_contact.email",
  "sender_contact.phone",
  "sender_contact.visualReferenceId",
  "sender_contact.entityType",
  "sender_contact.contactType",
  "sender_contact_employer.displayName",
  "sender_contact_employer.firstName",
  "sender_contact_employer.lastName",
  "sender_contact_employer.status",
  "sender_contact_avatar.path",
  "sender_contact_avatar.originalFilename",
  "sender_contact_avatar.alt",
  "sender_contact_avatar.size",
  "sender_contact_avatar.width",
  "sender_contact_avatar.height",
  "sender_contact_avatar.crop1x1",
  "sender_contact_avatar.mimeType",
  "sender_contact_avatar.viewGroup",
  "property.id",
  "property.visualReferenceId",
  "property.status",
  "property.propertyType",
  "property_contents.name",
  "property_contents.status",
  "property_contents_contentMedia.type",
  "property_contents_contentMedia_media.originalFilename",
  "property_contents_contentMedia.rank",
  "property_contents_contentMedia_media.mimeType",
  "property_contents_contentMedia_media.orientation",
  "property_contents_contentMedia_media.path",
  "eventType.id",
  "eventType.baseType",
];

export const eventFields = [...baseFields, "start", "creator.username"];

export const messageFields = [...baseFields, "source", "txt", "html"];
