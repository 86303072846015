import { Boarding } from "boarding.js";
import { history } from "config/history";

/** hijack previous btn functioncallity to link to my profile instead */
async function completeAndBackToOverview(boarding: Boarding) {
  // hijack previous btn
  boarding.preventMove();
  setTimeout(() => {
    boarding.clearMovePrevented();
    // trigger "finish" button
    boarding.next();
    // go back to profile
    history.push("/admin/profile");
  }, 0);
}

export default completeAndBackToOverview;
