import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "job-requirements",
    label: "Our requirements",
  },
  {
    value: "profile",
    label: "Your profile",
  },
  {
    value: "job-offer",
    label: "What we offer",
  },
];

export const getJobDescriptionTypes = createGetList(untranslatedList);
export const getJobDescriptionTypesLabel = createGetLabel(untranslatedList);

export const useJobDescriptionTypes = createUtilityHook(untranslatedList);
