import * as Dialog from "@radix-ui/react-dialog";
import ApiError from "./ApiErrorReport";
import { ApiErrorDefinition } from "@casasoft/styleguide/utilities/api-error/apiErrorDefinitions";

interface ApiErrorModalProps extends Dialog.DialogProps {
  context: string;
  error?: ApiErrorDefinition;
  refetchFn?: () => void;
}

function ApiErrorModal({
  context,
  error,
  refetchFn,
  ...dialogProps
}: ApiErrorModalProps) {
  return (
    <Dialog.Root {...dialogProps}>
      <Dialog.Portal>
        <Dialog.Overlay
          className="tw-cs-modal-loader-overlay tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-grid tw-place-items-center tw-z-30 tw-overflow-y-auto tw-bg-black/50 tw-cursor-pointer"
          onSubmit={(e) => {
            // required to prevent nested modals from firing the parents onSubmit events -> CASAONE-6122
            e.stopPropagation();
          }}
        >
          <Dialog.Content className="tw-w-full">
            <ApiError context={context} refetchFn={refetchFn} error={error} />
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default ApiErrorModal;
