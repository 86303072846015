import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "electric",
    label: "Electric heating",
  },
  {
    value: "geothermal-probe",
    label: "Geothermal-probe heating",
  },
  {
    value: "district",
    label: "District heating",
  },
  {
    value: "gas",
    label: "Gas heating",
  },
  {
    value: "wood",
    label: "Wood heating",
  },
  {
    value: "air-water-heatpump",
    label: "Air-water-heatpump heating",
  },
  {
    value: "oil",
    label: "Oil heating",
  },
  {
    value: "pellet",
    label: "Pellet heating",
  },
  {
    value: "heatpump",
    label: "Heatpump heating",
  },
  {
    value: "solar-thermal",
    label: "Solar Thermal",
  },
  {
    value: "photovoltaics",
    label: "Photovoltaics",
  },
  {
    value: "coal",
    label: "standards:Coal",
  },
  {
    value: "bhkwr",
    label: "standards:Block-type thermal power station",
  },
  {
    value: "heatpump-brine-and-water",
    label: "standards:Brine-water heat pump",
  },
];

export const getHeatGenerations = createGetList(untranslatedList, {
  sort: "a-z",
});
export const label = createGetLabel(untranslatedList);

export const useHeatGenerations = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
