import type { TableListBulkSelectHeaderProps } from "../components/table-list/TableListBulkSelectHeader";
import { useCallback, useState } from "react";
import { Item } from "../components/table-list/TableListItem";
import intersection from "lodash/intersection";

export interface UseTableListBulkSelectArgs {
  defaultSelected?: Item[];
  bulkActionNodes?: TableListBulkSelectHeaderProps["bulkActionNodes"];
  isSingle?: TableListBulkSelectHeaderProps["isSingle"];
}

function useTableListBulkSelect({
  defaultSelected = [],
  bulkActionNodes,
  isSingle,
}: UseTableListBulkSelectArgs = {}) {
  const [bulkSelected, setBulkSelected] = useState<Item["id"][]>(
    defaultSelected.map((item) => item.id)
  );
  const [bulkSelectedFullItem, setBulkSelectedFullItem] =
    useState<Item[]>(defaultSelected);

  const toggleItem = useCallback(
    (item: Item) => {
      setBulkSelectedFullItem((prevSelected) => {
        let newBulkSelected = [...prevSelected];
        // eslint-disable-next-line eqeqeq
        if (newBulkSelected.some((selected) => selected.id == item.id)) {
          newBulkSelected = newBulkSelected.filter(
            // eslint-disable-next-line eqeqeq
            (selected) => selected.id != item.id
          );
        } else if (isSingle) {
          newBulkSelected = [item];
        } else {
          newBulkSelected.push(item);
        }
        return [...newBulkSelected];
      });
      const itemID = item.id;
      setBulkSelected((prevSelected) => {
        let newBulkSelected = [...prevSelected];
        if (newBulkSelected.includes(itemID)) {
          newBulkSelected.splice(prevSelected.indexOf(itemID), 1);
        } else if (isSingle) {
          newBulkSelected = [itemID];
        } else {
          newBulkSelected.push(itemID);
        }
        return [...newBulkSelected];
      });
    },
    [isSingle]
  );

  const toggleAll = useCallback((items: Item[]) => {
    const ids = items.map((item) => item.id);
    setBulkSelected((prevSelected) => {
      if (prevSelected.length > 0) {
        return [];
      }

      return [...ids];
    });

    setBulkSelectedFullItem((prevSelected) => {
      if (prevSelected.length > 0) {
        return [];
      }

      return [...items];
    });
  }, []);

  const toggleItems = useCallback((items: Item[]) => {
    setBulkSelectedFullItem((prevSelected) => {
      const intersectionItems = prevSelected.filter(
        // item id is string || number
        // eslint-disable-next-line eqeqeq
        (item) => items.some((i) => i.id == item.id)
      );
      if (intersectionItems.length > 0) {
        return prevSelected.filter(
          // item id is string || number
          // eslint-disable-next-line eqeqeq
          (p) => !intersectionItems.some((i) => i.id == p.id)
        );
      }
      return [...prevSelected, ...items];
    });
    const itemIDs = items.map((item) => item.id);
    setBulkSelected((prevSelected) => {
      const intersectionItems = intersection(prevSelected, itemIDs);
      if (intersectionItems.length > 0) {
        return prevSelected.filter((item) => !intersectionItems.includes(item));
      }
      return [...prevSelected, ...itemIDs];
    });
  }, []);

  const setItems = useCallback((items: Item[]) => {
    setBulkSelectedFullItem([...items]);
    setBulkSelected([...items.map((item) => item.id)]);
  }, []);

  return {
    toggleAll,
    setItems,
    bulkSelected,
    bulkActionNodes,
    isSingle,
    toggleItems,
    bulkSelectedFullItem,
    toggleItem,
  };
}

export type TableListBulkSelectControl = ReturnType<
  typeof useTableListBulkSelect
>;

export default useTableListBulkSelect;
