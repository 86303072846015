import Config from "config";
import createBaseActions from "entities/createBaseActions";
import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import EmailSettingNylasState from "./types";

const name = "mainEmailSettingNylas" as const;
const pluralName = "mainEmailSettingsNylas" as const;

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: EmailSettingNylasState,
    actions?: any
  ) => EmailSettingNylasState;
}

export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: name,
  pluralName: pluralName,
  url: `${Config.apiUrl}/${Config.customerKey}/email-setting-nylas/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "email-setting-nylas",
    }),
  },
});

const mainEmailSettingNylasResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const mainEmailSettingNylasResource =
  mainEmailSettingNylasResourceCreator.resource;
export const useMainEmailSettingNylas =
  mainEmailSettingNylasResourceCreator.createHook();
