import {
  faCarGarage,
  faCar,
  faWarehouseAlt,
  faWarehouse,
  faGavel,
} from "@fortawesome/pro-light-svg-icons";
import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "parking-exterior-space",
    label: "standards:External parking space",
    icon: faCar,
  },
  {
    value: "parking-carport",
    label: "standards:Carport",
    icon: faCarGarage,
  },
  {
    value: "parking-garage",
    label: "standards:Single garage",
    icon: faCarGarage,
  },
  {
    value: "parking-garage-connected",
    label: "standards:Connected garage",
    icon: faWarehouse,
  },
  {
    value: "parking-garage-box",
    label: "standards:Garage box",
    icon: faWarehouse,
  },
  {
    value: "parking-garage-underground",
    label: "standards:Underground parking garage",
    icon: faWarehouse,
  },
  {
    value: "parking-house",
    label: "standards:Parking structure",
    icon: faWarehouse,
  },
  {
    value: "parking-duplex",
    label: "standards:Duplex garage",
    icon: faWarehouse,
  },
  {
    value: "parking-double-garage",
    label: "standards:Double garage",
    icon: faWarehouse,
  },
  {
    value: "room-workroom",
    label: "standards:Workroom",
    icon: faGavel,
  },
  {
    value: "room-storage-basement",
    label: "standards:Storage basement",
    icon: faWarehouseAlt,
  },
];

export const getIntegratedOfferTypes = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLabel = createGetLabel(untranslatedList);
export function getIcon(value: string) {
  return (
    untranslatedList.find((item) => item.value === value)?.icon || faWarehouse
  );
}

export const useIntegratedOfferTypes = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
