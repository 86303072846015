import { RESOURCE_USES_PUBLIC_AXIOS__CSPWAK } from "entities/contactSearchprofileWithAccesskey/store";
import { RESOURCE_USES_PUBLIC_AXIOS__CWAK } from "entities/contactWithAccesskey/store";
import { defaultGlobals } from "redux-rest-resource";
import { axiosInstance, axiosPublicInstance } from "utilities/axios";

defaultGlobals.fetch = async (requestInfo, requestInit) => {
  let isPublicResource = false;

  // check whether we should use public axios instance (no auth tokens)
  for (const isPublicResourceChecker of [
    RESOURCE_USES_PUBLIC_AXIOS__CWAK,
    RESOURCE_USES_PUBLIC_AXIOS__CSPWAK,
  ]) {
    if (isPublicResourceChecker(requestInfo)) {
      isPublicResource = true;
      break;
    }
  }

  const axiosInstanceToUse = isPublicResource
    ? axiosPublicInstance
    : axiosInstance;

  if (typeof requestInfo !== "string") {
    throw new Error("Expected redux-rest-resource to use a string as URL");
  }
  const headers = requestInit?.headers;
  if (Array.isArray(headers) || headers instanceof Headers) {
    throw new Error("Expected redux-rest-resource to use a string as URL");
  }

  const axiosResponse = await axiosInstanceToUse.request({
    url: requestInfo,
    method: requestInit?.method,
    headers: headers,
    data: requestInit?.body,
  });

  const fakeFetchResponse = new Response(
    axiosResponse.data ? JSON.stringify(axiosResponse.data) : null,
    {
      headers: axiosResponse.headers
        ? {
            ...axiosResponse.headers,
          }
        : undefined,
      status: axiosResponse.status,
      statusText: axiosResponse.statusText,
    }
  );

  return fakeFetchResponse;
};
