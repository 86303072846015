import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseContactCreator, {
  ContactResourceTypes,
  filterOutUsersAndSubsidiaries,
} from "./baseContactCreator";

const name = "birthdaysContact" as const;
const pluralName = "birthdaysContacts" as const;

export const { types, actions, rootReducer }: ContactResourceTypes =
  createResource(
    baseContactCreator({
      name,
      pluralName,
      forcedOrderBy: [
        {
          type: "customorderby",
          field: "dob",
          direction: "asc",
        },
      ],
      forcedFilter: filterOutUsersAndSubsidiaries,
    })
  );

const birthdaysContactResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const birthdaysContactResource =
  birthdaysContactResourceCreator.resource;
export const useBirthdaysContact = birthdaysContactResourceCreator.createHook();
