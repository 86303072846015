import { useCallback, useEffect, useState } from "react";
import type { PropertyAttachment } from "./propertyAttachment.model";

interface UsePropertyAttachmentsFilterProps {
  propertyAttachments: PropertyAttachment[];
}

export const usePropertyAttachmentsFilter = ({
  propertyAttachments,
}: UsePropertyAttachmentsFilterProps) => {
  const [searchString, setSearchString] = useState("");
  const [filteredPropertyAttachments, setfilteredPropertyAttachments] =
    useState<PropertyAttachment[]>([]);

  useEffect(() => {
    // we have to set filtered results like this as we are waiting for propertyAttachments to load
    setfilteredPropertyAttachments(propertyAttachments);
  }, [propertyAttachments]);

  const handleSearch = useCallback(
    (newSearchString: string) => {
      setSearchString(newSearchString);
      setfilteredPropertyAttachments(
        propertyAttachments.filter(
          (attachment) =>
            attachment.title
              .toLowerCase()
              .includes(newSearchString.toLowerCase()) ||
            attachment.mediaResource.originalFilename
              ?.toLowerCase()
              .includes(newSearchString.toLowerCase())
        )
      );
    },
    [propertyAttachments]
  );

  return {
    filteredPropertyAttachments,
    searchString,
    handleSearch,
  };
};
