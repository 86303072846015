import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import ToolbarMenuItemDropdown from "./ToolbarMenuItemDropdown";
import { Tooltip } from "@casasoft/styleguide/components/tooltip";

export type ToolbarMenuItemAction =
  | { navLink: string; className?: string }
  | { externalLink: string; className?: string; onItemClick?: () => void }
  | {
      onItemClick: () => void;
      onItemEnter?: () => void;
      onItemLeave?: () => void;
      className?: string;
    };

export type ToolbarMenuSubItem = Omit<
  ToolbarMenuItemProps,
  "action" | "itemKey"
> & {
  key: string;
  action: ToolbarMenuItemAction;
};

export interface ToolbarMenuItemProps {
  label: string;
  itemKey: string;
  icon: ReactNode;
  suffixIcon?: ReactNode;
  isMobile?: boolean;
  action:
    | ToolbarMenuItemAction
    | {
        subItems: (null | false | ToolbarMenuSubItem)[];
        className?: string;
        onItemClick?: () => void;
      };
}

const ToolbarMenuItem = ({
  icon,
  itemKey,
  suffixIcon,
  label,
  action,
  isMobile = false,
}: ToolbarMenuItemProps) => {
  const mobileClasses = `
    tw-relative tw-flex tw-items-center
    tw-w-full tw-h-auto tw-text-white
    after:tw-absolute after:tw-bottom-0 after:tw-left-4 after:tw-w-[calc(100%-2rem)] after:tw-h-[1px] after:tw-content-[''] after:tw-bg-cs-shade-100 after:tw-opacity-60
    first-of-type:before:tw-absolute first-of-type:before:tw-h-[1px] first-of-type:before:tw-w-[calc(100%-2rem)] first-of-type:before:tw-left-4 first-of-type:before:tw-top-0 first-of-type:before:tw-content-[''] first-of-type:before:tw-bg-cs-shade-100 first-of-type:before:tw-opacity-60
    last-of-type:tw-mt-auto last-of-type:tw-border-0 last-of-type:after:tw-hidden
  `;
  const desktopClasses = `tw-relative tw-flex tw-items-center tw-h-12 hover:tw-bg-cs-shade-800 hover:tw-shadow-none 
                          hover:tw-outline-none hover:tw-no-underline
                          focus-within:tw-bg-cs-shade-800 focus-within:tw-shadow-none focus-within:tw-outline-none focus-within:tw-no-underline`;

  const iconLabel = !isMobile ? (
    <span className="tw-text-lg">
      {icon}
      {suffixIcon}
    </span>
  ) : (
    <span className="tw-flex tw-justify-between tw-w-full tw-relative">
      <span>
        <span className="tw-mr-6 tw-text-lg">{icon}</span>
        {label}
      </span>
      {suffixIcon && <span className="tw-ml-4">{suffixIcon}</span>}
    </span>
  );

  function renderItemAction() {
    if ("navLink" in action) {
      return (
        <NavLink
          className={`
            tw-flex tw-items-center tw-h-12 tw-text-inherit tw-px-4 tw-whitespace-nowrap 
            ${
              isMobile
                ? `tw-w-full tw-h-8 tw-py-5 tw-px-4 tw-text-base`
                : `tw-text-lg`
            }
          `}
          to={action.navLink}
        >
          {iconLabel}
        </NavLink>
      );
    }
    if ("externalLink" in action) {
      return (
        <a
          className={`
            tw-flex tw-items-center tw-h-12 tw-text-inherit tw-px-4 tw-whitespace-nowrap 
            ${
              isMobile
                ? `tw-w-full tw-h-8 tw-py-5 tw-px-4 tw-text-base`
                : `tw-text-lg`
            }
          `}
          href={action.externalLink}
          target="_blank"
          rel="noreferrer"
        >
          {iconLabel}
        </a>
      );
    }
    if ("subItems" in action) {
      return (
        <ToolbarMenuItemDropdown
          items={action.subItems}
          onClick={() => action.onItemClick && action.onItemClick?.()}
        >
          {iconLabel}
        </ToolbarMenuItemDropdown>
      );
    }
    return (
      <button
        type="button"
        className={`
                tw-flex tw-items-center tw-h-12 tw-text-inherit tw-px-4 tw-whitespace-nowrap
                ${
                  isMobile
                    ? `tw-w-full tw-h-8 tw-py-5 tw-px-4 tw-text-base`
                    : `tw-text-lg`
                }
              `}
        onClick={() => {
          action.onItemClick();
        }}
        onMouseEnter={() => {
          action.onItemEnter?.();
        }}
        onMouseLeave={() => {
          action.onItemLeave?.();
        }}
      >
        {iconLabel}
      </button>
    );
  }

  return (
    <li
      className={`
        ${action.className || ""}
        ${isMobile ? mobileClasses : desktopClasses}
      `}
      data-toolbar-item-key={itemKey}
    >
      <Tooltip placement="bottom" content={!isMobile ? label : undefined}>
        {renderItemAction()}
      </Tooltip>
    </li>
  );
};

export default ToolbarMenuItem;
