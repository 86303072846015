import Switch from "../../../formElements/Switch";
import type { PropertyDocumentationAttachment } from "../helpers/propertyAttachment.model";
import { useTranslation } from "react-i18next";
import {
  PropertyAttachmentDossierOnChange,
  PropertyAttachmentDossierSidebar,
} from "./PropertyAttachmentDossierSidebar";

interface PropertyAttachmentDocumentationSidebarProps {
  selectedAttachment?: PropertyDocumentationAttachment;
  onChange: (updatedAttachment: PropertyDocumentationAttachment) => void;
}

export const PropertyAttachmentDocumentationSidebar = ({
  selectedAttachment,
  onChange,
}: PropertyAttachmentDocumentationSidebarProps) => {
  const { t } = useTranslation();

  const handleUpdate = (
    update: Partial<PropertyDocumentationAttachment>
  ): void => {
    if (selectedAttachment && update) {
      onChange({ ...selectedAttachment, ...update });
    }
  };

  return (
    <PropertyAttachmentDossierSidebar
      onChange={handleUpdate as PropertyAttachmentDossierOnChange}
      selectedAttachment={selectedAttachment}
    >
      <Switch
        label={t("Affordability calculation")}
        disabled={!selectedAttachment?.addPrice}
        checked={
          !selectedAttachment?.addPrice
            ? false
            : selectedAttachment?.addAffordabilityCalculation
        }
        onToggle={(value: boolean) => {
          if (selectedAttachment?.addPrice) {
            handleUpdate({ addAffordabilityCalculation: value });
          }
        }}
      />
    </PropertyAttachmentDossierSidebar>
  );
};
