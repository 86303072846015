import { createResource } from "redux-rest-resource";
import Config from "config";
import CgProviderState from "./types";
import queryBuilder from "utilities/queryBuilder";
import prepareFetchQueryOptions from "utilities/prepareFetchQueryOptions";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: CgProviderState, actions?: any) => CgProviderState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "cgProvider",
  pluralName: "cgProviders",
  url: `${Config.api2Url}/crm/providers/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (state.items) {
          const newState = Object.assign({}, state);
          const items = state.items.map((item: any) => {
            if (item.id === action.context.id) {
              return action.context;
            }
            return item;
          });
          newState.items = items;
          newState.lastUpdated = Date.now();
          return newState;
        }
        return state;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.provider) {
          return { res, body: res.body._embedded.provider };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        if (!contextOpts) {
          return {};
        }
        const opts = prepareFetchQueryOptions("cgprovider-list", contextOpts);

        const params: any = {
          context: opts.apiContext,
          pagesize: opts.pageSize,
        };

        // eq fields are simple params
        const eqFields = opts.filter.filter((x) => x.type === "eq");
        eqFields.forEach((eqField) => {
          params[eqField.field] = eqField.value;
        });

        const query = queryBuilder.build([], [], params);
        return query;
      },
    },
  },
});
