import { casaoneApi } from "api";
import singleQuery from "./endpoints/single/query";
import singleProvidesTags from "./endpoints/single/providesTags";
import listQuery from "./endpoints/list/query";
import listReducedQuery from "./endpoints/list-reduced/query";
import idListQuery from "./endpoints/list-ids/query";
import listProvidesTags from "./endpoints/list/providesTags";
import listReducedProvidesTags from "./endpoints/list-reduced/providesTags";
import idListProvidesTags from "./endpoints/list-ids/providesTags";
import updateSingleQuery from "./endpoints/update-single/query";
import updateSingleInvalidatesTags from "./endpoints/update-single/invalidatesTags";
import updateListQuery from "./endpoints/update-list/query";
import updateListInvalidatesTags from "./endpoints/update-list/invalidatesTags";
import transformResponse from "./endpoints/list-reduced/transformResponse";

const contactApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getContact: builder.query({
      query: singleQuery,
      providesTags: singleProvidesTags,
    }),
    getContactList: builder.query({
      query: listQuery,
      providesTags: listProvidesTags,
    }),
    getContactListReduced: builder.query({
      query: listReducedQuery,
      providesTags: listReducedProvidesTags,
      transformResponse: transformResponse,
    }),
    getContactIdList: builder.query({
      query: idListQuery,
      providesTags: idListProvidesTags,
    }),
    updateContact: builder.mutation({
      query: updateSingleQuery,
      invalidatesTags: updateSingleInvalidatesTags,
    }),
    updateContactList: builder.mutation({
      query: updateListQuery,
      invalidatesTags: updateListInvalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContactQuery,
  useLazyGetContactQuery,
  useGetContactListQuery,
  useLazyGetContactListQuery,
  useGetContactListReducedQuery,
  useLazyGetContactListReducedQuery,
  useGetContactIdListQuery,
  useLazyGetContactIdListQuery,
  useUpdateContactMutation,
  useUpdateContactListMutation,
} = contactApi;
