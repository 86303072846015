import intersection from "lodash/intersection";
import { faSort, faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableListItemColProps } from "./TableListItemCols";
import { TableListBulkSelectControl } from "../../hooks/useTableListBulkSelect";
import CheckboxNew from "../forms/CheckboxNew";
import { Item } from "./TableListItem";
import { TableListBulkSelectHeader } from "./TableListBulkSelectHeader";
import { twMerge } from "tailwind-merge";

export interface TableListHeaderProps {
  cardView: boolean;
  sortable: boolean;
  cols: TableListItemColProps[];
  onColumnClick: (colKey: string) => void;
  sortActiveKey: string | null;
  sortDirection: "asc" | "desc" | null;
  bulkSelectControl?: TableListBulkSelectControl;
  defaultSortKey?: string;
  defaultSortDir?: "asc" | "desc" | null;
  availableItems: Item[];
  onBulkToggleAll?: (hasHadSelectedItems: boolean) => false | void;
  showBulkHeader: boolean;
}

function TableListHeader({
  cardView,
  sortable,
  cols,
  onColumnClick,
  sortActiveKey,
  sortDirection,
  bulkSelectControl,
  defaultSortKey,
  defaultSortDir,
  availableItems,
  onBulkToggleAll,
  showBulkHeader,
}: TableListHeaderProps) {
  const bulkSelected = bulkSelectControl?.bulkSelected || [];
  const selectedOnCurrentPage = intersection(
    bulkSelected,
    availableItems.map((item) => item.id)
  );
  const isSelectedOnCurrentPage = !!selectedOnCurrentPage.length;

  return (
    <div className={"tw-flex tw-flex-col tw-min-w-min tw-relative "}>
      {showBulkHeader && (
        <TableListBulkSelectHeader
          isSingle={bulkSelectControl?.isSingle}
          bulkSelected={bulkSelectControl?.bulkSelectedFullItem || []}
          bulkActionNodes={bulkSelectControl?.bulkActionNodes}
          onBulkToggle={() => {
            const onToggleAllReturn = onBulkToggleAll?.(
              !!bulkSelectControl?.bulkSelected.length
            );

            if (onToggleAllReturn !== false) {
              bulkSelectControl?.toggleAll(availableItems);
            }
          }}
        />
      )}
      <div
        className={twMerge(
          "tw-flex tw-flex-nowrap tw-m-0 tw-p-0 tw-pr-4 tw--mx-4 tw-min-w-min tw-relative tw-no-underline tw-text-tw-cs-accent-color tw-border-0 tw-border-b-2 tw-border-solid tw-whitespace-nowrap",
          bulkSelectControl && bulkSelectControl.isSingle
            ? "tw-pl-16"
            : "tw-pl-4"
        )}
      >
        {bulkSelectControl && !bulkSelectControl.isSingle && (
          <label className="tw-items-center tw-w-12 tw-flex tw-justify-around tw-cursor-pointer">
            <CheckboxNew
              data-testid="TableListBulkSelectHeader-select-all"
              checked={isSelectedOnCurrentPage}
              iconStyle={
                bulkSelected.length <= availableItems.length &&
                isSelectedOnCurrentPage
                  ? "deselect-line"
                  : "checkmark"
              }
              onToggle={() => {
                bulkSelectControl.toggleItems(availableItems);
              }}
            />
          </label>
        )}
        {cols.map((col) => {
          const additionalCellClassNames: string[] = [];
          if (!cardView && col.align && col.align === "right") {
            additionalCellClassNames.push("tw-text-right");
          }
          return (
            <div
              key={col.key}
              style={
                !cardView
                  ? {
                      width: col.baseWidth || "150px",
                      minWidth:
                        col.minWidth || (col.maxWidth ? undefined : "150px"),
                      maxWidth: col.maxWidth,
                    }
                  : undefined
              }
              className={`tw-group tw-leading-6 tw-font-thin tw-text-cs-shade-800 tw-relative focus:tw-outline-none tw-border-tw-cs-accent-color tw-grow tw-pt-2 tw-pb-1 tw-pr-6 last:tw-pr-0 ${
                col.isSortable ? "tw-cursor-pointer" : ""
              } ${additionalCellClassNames.join(" ")}`}
              role="columnheader"
              aria-sort="ascending"
              tabIndex={0}
              onClick={
                !sortable && col.isSortable
                  ? () => {
                      onColumnClick(col.key);
                    }
                  : undefined
              }
            >
              {col.label}
              {col.isSortable && (
                <span className="tw-absolute tw--left-4 tw-top-0 tw-py-2 tw-pointer-events-none">
                  {sortActiveKey === col.key && sortDirection && (
                    <>
                      {((sortDirection !== defaultSortDir &&
                        sortActiveKey === defaultSortKey) ||
                        sortActiveKey !== defaultSortKey) && (
                        <span className="tw-absolute tw-left-0 tw-top-0 tw-py-2 ">
                          <FontAwesomeIcon
                            fixedWidth
                            icon={
                              sortDirection === "asc" ? faSortUp : faSortDown
                            }
                          />
                        </span>
                      )}
                    </>
                  )}
                  <span
                    className={`group-hover:tw-block tw-opacity-50 ${
                      sortActiveKey === col.key ? "tw-block" : "tw-hidden"
                    }`}
                  >
                    <FontAwesomeIcon fixedWidth icon={faSort} />
                  </span>
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TableListHeader;
