import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactNode } from "react";
import Button from "../forms/Button";
import { Tooltip } from "../tooltip";

export interface CardHeaderAction {
  icon: IconDefinition;
  iconStyle?: "danger" | "success";
  iconSpin?: boolean;
  extraLabel?: string;
  // alt: string;
  onClick?: () => void | Promise<void>;
  tooltip?: {
    placement?: "top" | "right" | "bottom" | "left";
    textAlign?: "center" | "left" | "right";
    node: ReactNode;
  };
}

interface CardHeaderProps {
  children: ReactNode;
  isNote?: boolean;
  /** An optional list of actions with click handlers, tooltip, different styles, icon, etc. */
  actions?: (CardHeaderAction | { customNode: ReactNode })[];
}

const CardHeader = ({ children, isNote = false, actions }: CardHeaderProps) => {
  return (
    <div
      className={`tw-cs-card-header-base 
        ${isNote ? "tw-cs-card-header-note" : ""}
      `}
    >
      {children}
      {!!actions?.length && (
        <div className="tw-absolute tw-z-[1] tw-top-3 tw-right-4">
          {actions.map((action, i) => {
            if ("customNode" in action) {
              return <Fragment key={i}>{action.customNode}</Fragment>;
            }
            return (
              <Tooltip
                key={i}
                content={action.tooltip?.node}
                textAlign={action.tooltip?.textAlign}
                placement={action.tooltip?.placement}
                triggerAsChild
              >
                <Button
                  isHeaderAction
                  onClick={action.onClick}
                  isPrimary={!isNote && !action.iconStyle}
                  isNote={isNote && !action.iconStyle}
                  isDanger={action.iconStyle === "danger"}
                  isSuccessful={action.iconStyle === "success"}
                  data-testid="card-header-button"
                >
                  <FontAwesomeIcon icon={action.icon} spin={action.iconSpin} />
                  {action.extraLabel && (
                    <span className="tw-ml-1">{action.extraLabel}</span>
                  )}
                </Button>
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CardHeader;
