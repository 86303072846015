import { CasaoneResultDescription } from "api/types";
import { GetContactSingleResponse, GetContactSingleQueryParams } from "./types";

const providesTags: CasaoneResultDescription<
  GetContactSingleResponse,
  GetContactSingleQueryParams
> = (result) => {
  if (result) {
    return [{ type: "Contact", id: result.id }];
  }

  return ["Contact"];
};

export default providesTags;
