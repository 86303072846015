import { Card, CardHeader } from "@casasoft/styleguide/components/card";
import { useTranslation } from "react-i18next";
import { TableList } from "@casasoft/styleguide/components/table-list";
import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { useAppSelector } from "../../../../redux/hooks";
import { selectContactLang } from "../../../../redux/auth/selectors";

export interface FaqSimilarTopicsCardProps {
  similiarTopics?: {
    activeTopic: { value: string; name?: string };
    related: { value: string; name?: ReactNode }[];
  };
  onTopicSelect: (newSelectedTopic?: string) => void;
  isLoading: boolean;
}

const FaqSimilarTopicsCard = ({
  similiarTopics,
  isLoading,
  onTopicSelect,
}: FaqSimilarTopicsCardProps) => {
  const { t } = useTranslation();
  const userLanguage = useAppSelector(selectContactLang);
  const fetchLang = userLanguage === "fr" ? "fr" : "de";

  const topicItems = similiarTopics?.related.map((label) => {
    return {
      item_key: label.value.toLowerCase(),
      label: label.name || label.value,
    };
  });

  return (
    <Card>
      <CardHeader>{t("Similar topics")}</CardHeader>
      <TableList
        itemsLoaded={!isLoading}
        loading={isLoading}
        pageSize={topicItems?.length}
        total={topicItems?.length}
        components={[]}
        onItemClick={(item) => {
          onTopicSelect(item.item_key);
        }}
        cols={[
          {
            key: "label",
            label: t("Label"),
            maxWidth: "100%",
          },
        ]}
        items={topicItems}
      />
      <a
        href={`https://help.casasoft.ch/hc/${fetchLang}/signin?return_to=https%3A%2F%2Fhelp.casasoft.ch%2Fhc%2F${fetchLang}`}
        target={"_blank"}
        className="tw-rounded-b tw-text-white tw-bg-tw-cs-accent-color hover:tw-bg-tw-cs-accent-color-dark tw-p-4 tw-flex tw-items-center tw-justify-between tw-border-t-2 tw-border-solid tw-border-0 tw-border-cs-shade-50"
        rel="noreferrer"
      >
        <div className="tw-whitespace-nowrap tw-text-ellipsis">
          {t("View all articles in Helpcenter")}
        </div>
        <FontAwesomeIcon icon={faExternalLink} />
      </a>
    </Card>
  );
};

export default FaqSimilarTopicsCard;
