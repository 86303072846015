import { Fragment, ReactNode } from "react";
import { PointsShape } from "./differ";

const customCommaSeperatedStringValueComparator: PointsShape["customComparison"] =
  (pointA, pointB) => {
    const pointAValues =
      typeof pointA?.value === "string" && pointA.value.trim()
        ? pointA.value.split(", ")
        : [];
    const pointBValues =
      typeof pointB?.value === "string" && pointB.value.trim()
        ? pointB.value.split(", ")
        : [];

    const addedValues: string[] = [];
    const existingValues: string[] = [];
    const deletedValues: string[] = [];

    const allTheValuesUnique = Array.from(
      new Set([...pointAValues, ...pointBValues])
    );

    allTheValuesUnique.forEach((value) => {
      if (pointAValues.includes(value)) {
        if (pointBValues.includes(value)) {
          existingValues.push(value);
        } else {
          addedValues.push(value);
        }
      } else {
        deletedValues.push(value);
      }
    });

    // render different value types into array so we can join them with a comma later
    const renderedValues: ReactNode[] = [];
    if (existingValues.length) {
      renderedValues.push(existingValues.join(", "));
    }
    if (addedValues.length) {
      renderedValues.push(
        ...addedValues.map((addedValue) => (
          <span key={addedValue}>
            {addedValue} <span className="tw-text-cs-success">(+)</span>
          </span>
        ))
      );
    }
    if (deletedValues.length) {
      renderedValues.push(
        <del className="tw-text-cs-danger">{deletedValues.join(", ")}</del>
      );
    }

    return {
      key: pointA?.key || pointB?.key || "should-never-happen",
      value: pointA?.value || pointB?.value || "should-never-happen",
      label: pointA?.label || pointB?.label || "should-never-happen",
      renderedValue: (
        <div>
          {renderedValues.map((renderedValue, i) => (
            <Fragment key={i}>
              {renderedValue}
              <span className="last:tw-hidden">, </span>
            </Fragment>
          ))}
        </div>
      ),
    };
  };

export default customCommaSeperatedStringValueComparator;
