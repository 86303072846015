import cx from "classnames";

export const BUBBLE_COLORS_LIST = ["danger", "success", "info"] as const;
export type BubbleColor = typeof BUBBLE_COLORS_LIST[number];

export interface BubbleProps {
  content: React.ReactNode;
  color?: BubbleColor;
  correctionTop?: number;
  correctionRight?: number;
  children?: React.ReactNode;
  show?: boolean;
}

export const Bubble = ({
  content,
  color = "info",
  correctionTop = -12,
  correctionRight = -15,
  show = true,
  children,
}: BubbleProps) => {
  if (show) {
    return (
      <div className={cx("tw-relative", "tw-w-fit")}>
        {children}
        <div
          className={cx(
            "tw-absolute",
            "tw-rounded-full",
            { "tw-bg-cs-danger": color === "danger" },
            { "tw-bg-cs-info": color === "info" },
            { "tw-bg-cs-success": color === "success" },
            "tw-text-white",
            "tw-text-sm",
            "tw-font-semibold",
            "tw-w-[19px]",
            "tw-h-[19px]",
            "tw-flex",
            "tw-justify-center",
            "tw-items-center"
          )}
          style={{
            top: correctionTop,
            right: correctionRight,
          }}
        >
          {content}
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
