import { MultiValue } from "reactSelectNew";
import { ContactSelectValueType } from "../features/contact-select/ContactSelectContainer";
import { ContactShape } from "api/entities/contact/types";

export const getContactById = (
  id: ContactSelectValueType,
  contactList: ContactShape[]
): ContactShape | undefined =>
  contactList.find((contact) => {
    return contact.id === id.value;
  });

export const getMultipleContactsByIds = (
  ids: MultiValue<ContactSelectValueType>,
  contactList: ContactShape[]
): ContactShape[] =>
  ids.reduce<ContactShape[]>((resultList, id) => {
    const foundContact = contactList.find((contact) => contact.id === id.value);
    if (foundContact) {
      resultList.push(foundContact);
    }
    return resultList;
  }, [] as ContactShape[]);
