import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@casasoft/styleguide/components/modal";
import { useTranslation } from "react-i18next";
import Button from "@casasoft/styleguide/components/forms/Button";
import { Alert } from "@casasoft/styleguide/components/helpers-ux";
import { ReducedContactShape } from "../LanguageBreakdownContainer";
import { history } from "config/history";

export interface EditContactWarningModalProps {
  contact?: ReducedContactShape;
  languageLabel?: string;
  onClose: () => void;
}

export const EditContactWarningModal = ({
  contact,
  languageLabel,
  onClose,
}: EditContactWarningModalProps) => {
  const { t } = useTranslation();

  const handleEditContactConfirmClick = () => {
    history.push(`/contact/list/item/${contact?.id}`);
  };

  return (
    <Modal isOpen={!!contact} onClose={onClose}>
      <ModalHeader>
        {t("Contacts")} {languageLabel}
      </ModalHeader>
      <ModalBody>
        <Alert color="warning" small>
          {t("When you edit a contact you will lose your contact selection.")}
        </Alert>
        <div className="tw-font-thin tw-mb-2">
          {t("Do you want to edit the contact?")}
        </div>
        <Button isPrimary isPill={true} onClick={handleEditContactConfirmClick}>
          {t("Yes")}
        </Button>
      </ModalBody>
      <ModalFooter>
        <Button isPrimary isOutline={false} onClick={onClose}>
          {t("Back")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
