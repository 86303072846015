import config from "config";
import { axiosInstance } from "utilities/axios";

export const getDossierMedia = async (
  propertyId: string,
  options: any,
  lang: string
) => {
  const response = await axiosInstance.post(
    `${config.apiUrl}/${config.customerKey}/make-property-dossier-media/${propertyId}/${lang}`,
    options
  );
  if (response.status === 500) {
    return null;
  }
  return response.data;
};
