import { Component } from "react";
import OhSnap from "assets/oh_snap_ruin.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import Config from "config";
import Button from "@casasoft/styleguide/components/forms/Button";

interface ChunkBoundaryProps extends WithTranslation {}
interface ChunkBoundaryState {
  hasError: boolean;
  theError: string;
}
class ChunkBoundary extends Component<ChunkBoundaryProps, ChunkBoundaryState> {
  constructor(props: ChunkBoundaryProps) {
    super(props);
    this.state = { hasError: false, theError: "" };
  }
  static getDerivedStateFromError(error: Error) {
    if (error.name === "ChunkLoadError") {
      // display fallback ui
      return { hasError: true, theError: error };
    }
    return { hasError: false, theError: "" };
  }

  componentDidCatch(error: Error) {
    if (error.name !== "ChunkLoadError") {
      // pass error further up
      throw error;
    }
  }

  render() {
    if (this.state.hasError) {
      const supportInfo = this.props
        .t("Please reload and if the problem persists contact our %ssupport%s.")
        .split("%s");

      return (
        <div
          style={{
            display: "flex",
            height: "100vh",
            padding: "1em",
            overflow: "auto",
          }}
        >
          <div style={{ margin: "auto", textAlign: "center", maxWidth: 415 }}>
            <img
              src={OhSnap}
              alt="Oh Snap Illustration"
              className="tw-mb-6"
              style={{ width: "100%", maxWidth: 300 }}
            />
            <h1>{this.props.t("Oh Snap!")}</h1>
            <p>
              {this.props.t("An error occurred loading some resources.")}
              <br />
              {this.props.t("Make sure you are connected to the internet.")}
            </p>
            {supportInfo.length === 3 && (
              <p style={{ fontSize: "0.8em" }}>
                {supportInfo[0]}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${Config.casasoftWebsiteUrl}/support/`}
                  style={{
                    color: "rgb(var(--tw-cs-accent-color))",
                    textDecoration: "underline",
                  }}
                >
                  {supportInfo[1]}
                </a>
                {supportInfo[2]}
              </p>
            )}
            <Button
              isPrimary
              onClick={() => {
                window.location.reload();
              }}
            >
              {this.props.t("Reload page")}
            </Button>
            <div className="tw-text-sm tw-mt-4">
              <i style={{ opacity: 0.3 }}>v{Config.release}</i>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ChunkBoundary);
