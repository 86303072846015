import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Media } from "utilities";

class MediaExcerpt extends Component {
  static bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) {
      return "0 Byte";
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  __renderNameLines(startLineCount = 0) {
    const lines = [];
    const entity = this.props.entity;
    let lineCount = startLineCount;

    // customtitle
    if (this.props.title && lineCount <= this.props.lines) {
      lineCount += 1;
      lines.push({
        key: "customtitle",
        style: { maxWidth: 190 },
        render: <div>{this.props.title}</div>,
        className: ["tw-font-semibold"],
      });
    }

    if (lineCount <= this.props.lines) {
      lineCount += 1;
      lines.push({
        key: "origfilename",
        style: { maxWidth: 190 },
        className: ["tw-truncate"],
        render: entity.originalFilename,
      });
    }

    const renderedLines = lines.map((line) => {
      let className = [];
      if (line.className) {
        className = line.className;
      }
      return (
        <div key={line.key} className={className.join(" ")}>
          {line.render}
        </div>
      );
    });
    return renderedLines;
  }

  __renderFileLines(startLineCount = 0) {
    let lineCount = startLineCount;
    const lines = [];
    const entity = this.props.entity;

    if (entity.mimeType && lineCount <= this.props.lines) {
      lineCount += 1;
      lines.push({
        key: "mime",
        style: { maxWidth: 190 },
        className: ["tw-truncate"],
        render: (
          <div>
            {entity.mimeType &&
              Media.mediaMimeTypeLabel(entity.mimeType).toUpperCase()}
            &nbsp; ({MediaExcerpt.bytesToSize(entity.size)})
          </div>
        ),
      });
    }

    if ((entity.width || entity.height) && lineCount <= this.props.lines) {
      lineCount += 1;
      lines.push({
        key: "widthheight",
        style: { maxWidth: 190 },
        className: ["tw-truncate"],
        render: (
          <div>
            {entity.width}x{entity.height}
          </div>
        ),
      });
    }

    const renderedLines = lines.map((line) => {
      let className = [];
      if (line.className) {
        className = line.className;
      }
      return (
        <div key={line.key} className={className.join(" ")}>
          {line.render}
        </div>
      );
    });
    return renderedLines;
  }

  render() {
    return (
      <Fragment>
        {this.props.renderName && this.__renderNameLines()}
        {this.props.renderInfos &&
          this.__renderFileLines(this.__renderNameLines().length)}
      </Fragment>
    );
  }
}

MediaExcerpt.propTypes = {
  entity: PropTypes.object.isRequired,
  lines: PropTypes.number,
  title: PropTypes.string,
  renderName: PropTypes.bool,
  renderInfos: PropTypes.bool,
  // t: PropTypes.func,
};

MediaExcerpt.defaultProps = {
  // t: () => {},
  title: null,
  lines: 3,
  renderName: false,
  renderInfos: false,
};

export default MediaExcerpt;
