import { casaoneApi } from "api";
import distinctListQuery from "./endpoints/distinct-media-tag-list/query";
import distinctListProvidesTags from "./endpoints/distinct-media-tag-list/providesTags";
import createSingleQuery from "./endpoints/create-single/query";
import createSingleInvalidatesTags from "./endpoints/create-single/invalidatesTags";
import deleteQuery from "./endpoints/delete-single/query";
import deleteInvalidatesTags from "./endpoints/delete-single/invalidatesTags";

const userApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getDistinctMediaTagList: builder.query({
      query: distinctListQuery,
      providesTags: distinctListProvidesTags,
    }),
    createMediaTag: builder.mutation({
      query: createSingleQuery,
      invalidatesTags: createSingleInvalidatesTags,
    }),
    deleteMediaTag: builder.mutation({
      query: deleteQuery,
      invalidatesTags: deleteInvalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDistinctMediaTagListQuery,
  useLazyGetDistinctMediaTagListQuery,
  useCreateMediaTagMutation,
  useDeleteMediaTagMutation,
} = userApi;
