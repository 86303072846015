import queryBuilder from "utilities/queryBuilder";
import { GetPhaseListQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<GetPhaseListQueryParams>(
  ({ phaseForMarketingMethod }) => {
    const mmMethodPipline =
      phaseForMarketingMethod === "rent"
        ? "prospective-customer-property-rent"
        : "prospective-customer-property-buy";

    const builtQuery = queryBuilder.buildToUrl(
      [
        {
          type: "field",
          field: "rank",
          direction: "asc",
        },
      ],
      [
        {
          type: "eq",
          field: "pipeline",
          value: mmMethodPipline,
        },
        {
          type: "eq",
          field: "status",
          value: "active",
        },
      ],
      {
        pagesize: -1,
        page: 1,
      }
    );

    return {
      url: `phase?${builtQuery}`,
      method: "GET",
    };
  }
);

export default query;
