import { CasaoneBaseQueryArgs } from "api/types";
import { UpdateEventSingleBody } from "./types";

function query(body: UpdateEventSingleBody): CasaoneBaseQueryArgs {
  return {
    url: `event/${body.id}`,
    method: "PATCH",
    data: body,
  };
}

export default query;
