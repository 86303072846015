import { GetMediaTagListQueryParams, GetMediaTagListResponse } from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetMediaTagListResponse,
  GetMediaTagListQueryParams
>((result) => {
  if (result) {
    // generate tags for each mediaTag
    const tagTags = result.mediaTags.map((item) => ({
      type: "MediaTag" as const,
      id: item.name,
    }));

    // merge tag tags with static tags
    return [
      ...tagTags,
      {
        type: "MediaTag",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["MediaTag"];
});

export default providesTags;
