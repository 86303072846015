import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];

export const getJobStatuses = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLabel = createGetLabel(untranslatedList);

export const useJobStatuses = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
