import ApiErrorReport from "@casasoft/styleguide/components/helpers-ux/ApiErrorReport";
import type { SerializedError } from "@reduxjs/toolkit";

const PHASE_LIST_ERROR_CONTEXT = "Phase Relation Group (phase list)";
const LATEST_PHASE_ERROR_CONTEXT = "Phase Relation Group (latest phase)";

interface RelationPhaseErrorReportProps {
  phaseListError: SerializedError | undefined;
  latestPhaseError: SerializedError | undefined;
}

export const RelationPhaseErrorReport = ({
  phaseListError,
  latestPhaseError,
}: RelationPhaseErrorReportProps) => (
  <div>
    <ApiErrorReport
      context={
        phaseListError ? PHASE_LIST_ERROR_CONTEXT : LATEST_PHASE_ERROR_CONTEXT
      }
      error={phaseListError ? phaseListError : latestPhaseError}
    />
  </div>
);
