import { createReducer } from "@reduxjs/toolkit";
import {
  resetResourceState,
  changeResourceSearch,
  changeResourcePageSize,
  changeResourcePage,
  changeResourceSort,
  showAlert,
  hideAlert,
} from "./actions";
import { AlertProps } from "@casasoft/styleguide/components/helpers-ux/Alert";
import { ReactNode } from "react";

export interface AppStateResourceOptions {
  pageSize?: number | null;
  pageNumber?: number | null;
  page?: number | null;
  searchTerm?: string;
  searchField?: string;
  sortKey?: string | null;
  sortDir?: "asc" | "desc" | null;
}

interface AppStateShape {
  loading: boolean;
  alert:
    | {
        color: AlertProps["color"];
        children: ReactNode;
      }
    | undefined;
  appNavOpen: boolean;
  appNcOpen: boolean;
  appNcPeeked: boolean;
  resourceOptions: {
    [key in string]?: AppStateResourceOptions;
  };
}

const initialState: AppStateShape = {
  loading: false,
  alert: undefined,
  appNavOpen: false,
  appNcOpen: false,
  appNcPeeked: false,
  resourceOptions: {},
};

const appReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetResourceState, (state, action) => {
      delete state.resourceOptions[action.payload.resource];
    })
    .addCase(changeResourceSearch, (state, action) => {
      if (state.resourceOptions[action.payload.resource]) {
        return {
          ...state,
          resourceOptions: {
            ...state.resourceOptions,
            [action.payload.resource]: {
              ...state.resourceOptions[action.payload.resource],
              searchTerm: action.payload.term,
              searchField: action.payload.field,
            },
          },
        };
      }
      // create it if it doesn't exist yet
      return {
        ...state,
        resourceOptions: {
          ...state.resourceOptions,
          [action.payload.resource]: {
            pageSize: null,
            searchTerm: action.payload.term,
            searchField: action.payload.field,
            sortKey: null,
            sortDir: null,
            page: null,
          },
        },
      };
    })
    .addCase(changeResourcePageSize, (state, action) => {
      if (state.resourceOptions[action.payload.resource]) {
        return {
          ...state,
          resourceOptions: {
            ...state.resourceOptions,
            [action.payload.resource]: {
              ...state.resourceOptions[action.payload.resource],
              pageSize: action.payload.pageSize,
            },
          },
        };
      }
      // create it if it doesn't exist yet
      return {
        ...state,
        resourceOptions: {
          ...state.resourceOptions,
          [action.payload.resource]: {
            pageSize: action.payload.pageSize,
            searchTerm: "",
            sortKey: null,
            sortDir: null,
            page: null,
          },
        },
      };
    })
    .addCase(changeResourcePage, (state, action) => {
      if (state.resourceOptions[action.payload.resource]) {
        return {
          ...state,
          resourceOptions: {
            ...state.resourceOptions,
            [action.payload.resource]: {
              ...state.resourceOptions[action.payload.resource],
              page: action.payload.page,
            },
          },
        };
      }
      // create it if it doesn't exist yet
      return {
        ...state,
        resourceOptions: {
          ...state.resourceOptions,
          [action.payload.resource]: {
            pageSize: null,
            searchTerm: "",
            sortKey: null,
            sortDir: null,
            page: action.payload.page,
          },
        },
      };
    })
    .addCase(changeResourceSort, (state, action) => {
      if (state.resourceOptions[action.payload.resource]) {
        return {
          ...state,
          resourceOptions: {
            ...state.resourceOptions,
            [action.payload.resource]: {
              ...state.resourceOptions[action.payload.resource],
              sortKey: action.payload.key,
              sortDir: action.payload.dir,
            },
          },
        };
      }
      // create it if it doesn't exist yet
      return {
        ...state,
        resourceOptions: {
          ...state.resourceOptions,
          [action.payload.resource]: {
            pageSize: null,
            searchTerm: "",
            sortKey: action.payload.key,
            sortDir: action.payload.dir,
            page: null,
          },
        },
      };
    })
    .addCase(showAlert, (state, action) => ({
      ...state,
      alert: action.payload,
    }))
    .addCase(hideAlert, (state, action) => ({
      ...state,
      alert: undefined,
    }));
});

export { appReducer };
