import { createResource } from "redux-rest-resource";
import Config from "config";
import queryBuilder from "utilities/queryBuilder";
import CgPublisherState from "./types";
import prepareFetchQueryOptions from "utilities/prepareFetchQueryOptions";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: CgPublisherState, actions?: any) => CgPublisherState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "cgPublisher",
  pluralName: "cgPublishers",
  url: `${Config.api2Url}/crm/publishers/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (state.items) {
          const newState = Object.assign({}, state);
          const items = state.items.map((item: any) => {
            if (item.id === action.context.id) {
              return action.context;
            }
            return item;
          });
          newState.items = items;
          newState.lastUpdated = Date.now();
          return newState;
        }
        return state;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.publisher) {
          return { res, body: res.body._embedded.publisher };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        if (!contextOpts) {
          return {};
        }
        const opts = prepareFetchQueryOptions("cgpublisher-list", contextOpts);

        const order_by = opts?.orderBy[0]?.field;
        const order_dir = opts?.orderBy[0]?.direction;

        const params: any = {
          context: opts.apiContext,
          page_size: opts.pageSize,
          order_by,
          order_dir,
          page: contextOpts.page,
        };

        // eq fields are simple params
        const eqFields = opts.filter.filter((x) => x.type === "eq");
        eqFields.forEach((eqField) => {
          params[eqField.field] = eqField.value;
        });

        const query = queryBuilder.build([], [], params);
        return query;
      },
    },
  },
});
