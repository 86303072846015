import { FormShape } from "../EventCreateForm";
import { PartialOrNull } from "utilities/type-helpers/object-oberators";

export const mapSenderRecipient = (
  eventData: PartialOrNull<FormShape>,
  eventBaseType?: string | null
) => {
  return {
    sender:
      eventBaseType === "message-inbound" ||
      eventBaseType === "message-inbound-property-inquiry"
        ? eventData.contact
        : eventData.user,
    recipient:
      eventBaseType === "message-inbound" ||
      eventBaseType === "message-inbound-property-inquiry"
        ? eventData.user
        : eventData.contact,
  };
};
