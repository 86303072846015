import extractDataAttributes from "@casasoft/styleguide/utilities/extractDataAttributes";
import { useState } from "react";
import Button, { BaseButtonProps } from "../forms/Button";
import ButtonGroup from "../forms/ButtonGroup";
import FormGroup, { FormGroupProps } from "./helpers/FormGroup";

type SingleSwitchButtonBase<TV extends any> = {
  label: string;
  value: TV;
  buttenStyleOverrides?: BaseButtonProps;
};

type SwitchButtonProps<
  TV extends any,
  TSBB extends SingleSwitchButtonBase<TV>
> = Pick<
  FormGroupProps,
  "id" | "filled" | "message" | "label" | "required" | "text"
> & {
  buttons?: TSBB[];
  value?: TV;
  onChange?: (newVal?: TV) => void;

  growButtons?: boolean;
};

function SwitchButton<TV extends any, TSBB extends SingleSwitchButtonBase<TV>>({
  buttons = [],
  value,
  onChange,
  growButtons = true,
  ...remainingProps
}: SwitchButtonProps<TV, TSBB>) {
  const [focused, setFocused] = useState(false);
  const [dataAttrs, formGroupProps] = extractDataAttributes(remainingProps);
  return (
    <FormGroup {...formGroupProps} nobox filled focused={focused}>
      <ButtonGroup role="radiogroup" {...dataAttrs}>
        {buttons.map((button) => (
          <Button
            isSecondary={
              typeof button.buttenStyleOverrides === "undefined"
                ? true
                : undefined
            }
            isActive={button.value === value}
            {...button.buttenStyleOverrides}
            onClick={() => {
              onChange?.(button.value === value ? undefined : button.value);
            }}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            style={{ flex: growButtons ? "1 1 0%" : undefined }}
            role="radio"
          >
            {button.label}
          </Button>
        ))}
      </ButtonGroup>
    </FormGroup>
  );
}

export default SwitchButton;
