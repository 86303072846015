import ContactExcerpt, {
  ReducedContactShape,
} from "components/contact/features/contact-excerpt/ContactExcerpt";
import { useTranslation } from "react-i18next";
import { FormatOptionLabelMeta, MultiValue } from "reactSelectNew";
import { useRecipientSelectDataFetch } from "./useRecipientSelectDataFetch";
import { getMultipleContactsByIds } from "components/contact/helpers/matchContactsToIds";
import {
  getContactDisplayName,
  looksLikeAnEmail,
} from "./recipientSelectMiscHelpers";
import {
  NEW_EMAIL_PREFIX,
  RecipientSelectNewEmailOption,
} from "../RecipientSelectNewEmailOption";
import { MessagingSelectLoading } from "components/miscellaneous/MessagingSelectLoading";
import { Tooltip } from "@casasoft/styleguide/components/tooltip";

export type RecipientSelectValueType = {
  value: string;
  contact?: ReducedContactShape;
};

interface UseRecipientSelectProps {
  value: RecipientSelectValueType[] | undefined;
  onChange: (
    value: RecipientSelectValueType[],
    contact: ReducedContactShape[]
  ) => void;
}

export const useRecipientSelect = ({
  value,
  onChange,
}: UseRecipientSelectProps) => {
  const { t } = useTranslation();

  const { allContactItems, loadContactOptionsHelper, contactGetResult } =
    useRecipientSelectDataFetch();

  const formatOptionLabelInMenu = (option: RecipientSelectValueType) => {
    if (option.value?.includes(NEW_EMAIL_PREFIX)) {
      return <RecipientSelectNewEmailOption optionValue={option.value} />;
    }

    return option.contact ? (
      <ContactExcerpt lines={4} contact={option.contact} />
    ) : (
      t("Name is missing")
    );
  };

  const formatOptionLabelInInput = (option: RecipientSelectValueType) => {
    if (option.value?.includes(NEW_EMAIL_PREFIX)) {
      return <span>{option.value.replace(NEW_EMAIL_PREFIX, "")}</span>;
    }

    if (!option.contact) {
      option.contact = allContactItems.find(
        (contact) => contact.id === option.value
      );
    }

    if (!option?.contact) {
      return <MessagingSelectLoading />;
    }

    return (
      <span>
        <Tooltip content={option.contact.email} triggerAsChild>
          <button type="button">
            {getContactDisplayName(option.contact, t("No Email!"))}
          </button>
        </Tooltip>
      </span>
    );
  };

  const formatOptionLabel = (
    option: RecipientSelectValueType,
    meta: FormatOptionLabelMeta<RecipientSelectValueType>
  ) => {
    // context "menu" is dropdown option, context "input" is selected option in input
    return meta.context === "menu"
      ? formatOptionLabelInMenu(option)
      : formatOptionLabelInInput(option);
  };

  const loadOptions = async (searchTerm: string) => {
    const fullItems = await loadContactOptionsHelper({
      searchTerm,
      activeSelection: value?.map((v) => v.value) || [],
      pageSize: 20,
    });

    const valueItems = fullItems.map((fullItem) => ({
      value: fullItem.id,
      contact: fullItem,
    }));

    if (valueItems.length === 0 && looksLikeAnEmail(searchTerm)) {
      return [{ value: `${NEW_EMAIL_PREFIX}${searchTerm}` }];
    }

    return valueItems;
  };

  const handleChange = (value: MultiValue<RecipientSelectValueType>) => {
    onChange([...value], getMultipleContactsByIds(value, allContactItems));
  };

  const noOptionsMessage = contactGetResult?.isError
    ? () => t("Error")
    : undefined;

  return {
    formatOptionLabel,
    handleChange,
    loadOptions,
    noOptionsMessage,
  };
};
