import { Boarding } from "boarding.js";

type BoardingStartTriggerEventDetails = {
  id: string;
};
type BoardingStartTriggerEvent = CustomEvent<BoardingStartTriggerEventDetails>;

/**
 * Trigger a tour start trigger event
 * @param uniqueId Id of the tour that should be started
 */
export function dispatchBoardingTriggerStartEvent(uniqueId: string) {
  const startEvent = new CustomEvent<BoardingStartTriggerEventDetails>(
    "BoardingStartTriggerEvent",
    {
      detail: { id: uniqueId },
    }
  );
  document.dispatchEvent(startEvent);
}

/**
 * Subscribe to the tour start trigger event to then actually start the tour
 * @param uniqueId id of the tour we want to start -> will get compared to the start trigger event details
 * @param boardingInstance the actual tour instance
 * @returns an unsubscriber method to remove the event listener again
 */
export function createTourTriggerSubscription(
  uniqueId: string,
  boardingInstance: Boarding
) {
  const handler = (event: Event) => {
    const tourTriggerEvent = event as BoardingStartTriggerEvent;

    if (tourTriggerEvent.detail.id === uniqueId) {
      boardingInstance.start();
    }
  };

  document.addEventListener("BoardingStartTriggerEvent", handler);

  const unsubscribe = () => {
    document.removeEventListener("BoardingStartTriggerEvent", handler);
  };

  return unsubscribe;
}
