import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Knowledgeble proficiency",
    value: "knowledge",
  },
  {
    label: "Professional experience",
    value: "professional-experience",
  },
  {
    label: "Business fluent",
    value: "business-fluent",
  },
  {
    label: "Native language",
    value: "native-language",
  },
];

export const getLanguageProficiencies = createGetList(untranslatedList);
export const getLabel = createGetLabel(untranslatedList);

export const useLanguageProficiencies = createUtilityHook(untranslatedList);
