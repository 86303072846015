import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  changeResourcePage,
  changeResourcePageSize,
  changeResourceSearch,
  changeResourceSort,
} from "redux/app";
import { TableListSortConf } from "@casasoft/styleguide/components/table-list/TableList";
import { UserFilterPresetValue } from "redux/auth";
import { selectActiveFilterPreset } from "redux/auth/selectors";
import { selectResourceOptions } from "redux/app/selectors";

interface UseTableListResourceOptionsArgs {
  /** The Id of the resource to get the options for. In context of filter presets, this would be (and will be used as) the `presetName` */
  resourceId: string;
  /**
   * The default page size for the list that should be fetched. Will also be passed to the TableList component.
   * @default 10
   */
  defaultPageSize?: number;
}

/** The resource options that will be passed to the TableList component as props. */
export interface TableListResourceOptionsProps {
  /** The current page number */
  page: number;
  /** The page size amount */
  pageSize: number;
  /** The default search term rendered in the TableList if available */
  defaultSearchValue?: string;
  /** The selected field for the search if available */
  searchFieldFilter?: string;
  /** The handle for when the page changes */
  onPageChange: (newPage: number) => void;
  /** The handle for when the search changes */
  onSearchChange: (search: string, field?: string) => void;
  /** The handle for when the page size changes */
  onPageSizeChange: (newPageSize: number) => void;
  /** The handle for when the sort and order changes */
  onSortChange: (sortConf: TableListSortConf) => void;
}

/** The active resource options that will be used later for `useTableListQuery` */
export interface TableListResourceOptions {
  sortKey?: string | null;
  sortDir?: "asc" | "desc" | null;
  activeFilterPreset?: UserFilterPresetValue;
  page: number;
  pageSize: number;
  context?: string;
  searchTerm?: string;
  searchField?: string;
}

function useTableListResourceOptions({
  resourceId,
  defaultPageSize = 10,
}: UseTableListResourceOptionsArgs) {
  const dispatch = useAppDispatch();

  const currentResourceOptions = useAppSelector(
    selectResourceOptions(resourceId)
  );

  // get pagination
  const page = currentResourceOptions?.page || 1;
  const pageSize = currentResourceOptions?.pageSize || defaultPageSize;

  // get searchTerm
  const searchTerm = currentResourceOptions?.searchTerm;
  const searchField = currentResourceOptions
    ? currentResourceOptions.searchField
    : undefined;

  const onPageChange = useCallback(
    (newPage: number) => {
      dispatch(changeResourcePage(resourceId, newPage));
    },
    [dispatch, resourceId]
  );

  const onSearchChange = useCallback(
    (search: string, field?: string) => {
      dispatch(changeResourceSearch(resourceId, search, field));
      onPageChange(1);
    },
    [dispatch, resourceId, onPageChange]
  );
  const onPageSizeChange = useCallback(
    (newPageSize: number) => {
      dispatch(changeResourcePage(resourceId, 1));
      dispatch(changeResourcePageSize(resourceId, newPageSize));
    },
    [dispatch, resourceId]
  );

  const onSortChange = useCallback(
    (sortConf: TableListSortConf) => {
      dispatch(changeResourcePage(resourceId, 1));
      dispatch(changeResourceSort(resourceId, sortConf.key, sortConf.dir));
    },
    [dispatch, resourceId]
  );

  const tableListProps: TableListResourceOptionsProps = {
    page,
    pageSize,
    defaultSearchValue: searchTerm,
    searchFieldFilter: searchField,
    onPageChange,
    onSearchChange,
    onPageSizeChange,
    onSortChange,
  };

  // get sorting
  const sortKey = currentResourceOptions?.sortKey;
  const sortDir = currentResourceOptions?.sortDir;

  // get users active filter preset
  const usersActiveFilterPreset: UserFilterPresetValue | undefined =
    useAppSelector(selectActiveFilterPreset(resourceId));

  const resourceOptions: TableListResourceOptions = {
    sortKey,
    sortDir,
    page,
    pageSize,
    searchTerm,
    searchField,
    activeFilterPreset: usersActiveFilterPreset,
  };

  return [tableListProps, resourceOptions] as const;
}

export default useTableListResourceOptions;
