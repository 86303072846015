import { PropertyAttachmentItem } from "./PropertyAttachmentItem";
import type { PropertyAttachment } from "./helpers/propertyAttachment.model";

interface PropertyAttachmentListProps {
  list: PropertyAttachment[];
  selectedAttachments: PropertyAttachment[];
  lastSelectedAttachment?: PropertyAttachment;
  onAttachmentToggle: (
    attachment: PropertyAttachment,
    isChecked: boolean
  ) => void;
  onAattachmentHover?: (attachment: PropertyAttachment) => void;
}

export const PropertyAttachmentList = ({
  list,
  selectedAttachments,
  lastSelectedAttachment,
  onAttachmentToggle,
  onAattachmentHover,
}: PropertyAttachmentListProps) => {
  return (
    <>
      {list.map((attachment) => (
        <PropertyAttachmentItem
          key={attachment.id}
          attachment={attachment}
          isChecked={selectedAttachments.some(
            (selected) => selected.id === attachment.id
          )}
          onAttachmentToggle={onAttachmentToggle}
          onAttachmentHover={onAattachmentHover}
          hasActiveSidebar={attachment.id === lastSelectedAttachment?.id}
        />
      ))}
    </>
  );
};
