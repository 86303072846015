import { createResource } from "redux-rest-resource";
import Config from "config";
import PublicationState from "./types";
import createResourceHelper, {
  CreateHookArgs,
} from "utilities/createResourceHelper";
import createBaseActions from "entities/createBaseActions";
import {
  PROJECT_PUBLICATION_TYPES,
  SINGLE_PUBLICATION_TYPES,
} from "utilities/propertyTypes";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: PublicationState, actions?: any) => PublicationState;
}

const baseResourceData = {
  url: `${Config.apiUrl}/${Config.customerKey}/property/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

// single publications
const {
  actions: singlePublicationActions,
  rootReducer: singlePublicationReducer,
}: ResourceTypes = createResource({
  ...baseResourceData,
  name: "singlePublication",
  pluralName: "singlePublications",
  actions: {
    ...createBaseActions({
      embeddedName: "property",
      forcedFilter: [
        {
          type: "in",
          field: "propertyType",
          where: "and",
          values: SINGLE_PUBLICATION_TYPES,
        },
        {
          type: "neq",
          field: "status",
          value: "ignore",
          where: "and",
        },
      ],
    }),
  },
});

const singlePublicationResourceCreator = createResourceHelper({
  actions: singlePublicationActions,
  name: "singlePublication",
  pluralName: "singlePublications",
});

const singlePublicationResource = singlePublicationResourceCreator.resource;
const useSinglePublications = singlePublicationResourceCreator.createHook();

// project publications
const {
  actions: projectPublicationActions,
  rootReducer: projectPublicationReducer,
}: ResourceTypes = createResource({
  ...baseResourceData,
  name: "projectPublication",
  pluralName: "projectPublications",
  actions: {
    ...createBaseActions({
      embeddedName: "property",
      forcedFilter: [
        {
          type: "in",
          field: "propertyType",
          where: "and",
          values: PROJECT_PUBLICATION_TYPES,
        },
        {
          type: "neq",
          field: "status",
          value: "ignore",
          where: "and",
        },
      ],
    }),
  },
});

const projectPublicationResourceCreator = createResourceHelper({
  actions: projectPublicationActions,
  name: "projectPublication",
  pluralName: "projectPublications",
});

const projectPublicationResource = projectPublicationResourceCreator.resource;
const useProjectPublications = projectPublicationResourceCreator.createHook();

// exports
export const publicationResource = (type: "single" | "project") => {
  switch (type) {
    case "single":
      return singlePublicationResource;
    case "project":
      return projectPublicationResource;
  }
};
export const usePublications = (
  type: "single" | "project",
  args?: CreateHookArgs
) => {
  const single = useSinglePublications(type === "single" ? args : undefined);
  const project = useProjectPublications(type === "project" ? args : undefined);
  switch (type) {
    case "single":
      return single;
    case "project":
      return project;
  }
};

export const rootReducers = {
  singlePublicationReducer,
  projectPublicationReducer,
};
