import Config, { Env } from "../config";

export const HOTJAR_ID_PROD = 3855550;
export const HOTJAR_ID_NOT_PROD = 3855557;

export function addHotjar() {
  (window as any).hj =
    (window as any).hj ||
    function () {
      ((window as any).hj.q = (window as any).hj.q || []).push(arguments);
    };

  (window as any)._hjSettings = {
    hjid: Config.env === Env.production ? HOTJAR_ID_PROD : HOTJAR_ID_NOT_PROD,
    hjsv: 6,
  };

  const script: any = document.getElementById("hotjar");
  if (script) {
    script.src =
      "https://static.hotjar.com/c/hotjar-" +
      (window as any)._hjSettings.hjid +
      ".js?sv=" +
      (window as any)._hjSettings.hjsv;
  }
}
