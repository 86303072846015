import { ContactSelectValueType } from "components/contact/features/contact-select/ContactSelectContainer";
import { PropertySelectValueType } from "../../property-select/helpers/usePropertySelect";

export const mapRecipientIdToContacts = (
  recipientId: string | undefined
): ContactSelectValueType[] => (recipientId ? [{ value: recipientId }] : []);

export const mapPropertyIdToPropertyValue = (
  propertyId: string | undefined
): PropertySelectValueType | undefined =>
  propertyId
    ? {
        value: propertyId,
      }
    : undefined;
