import Button from "@casasoft/styleguide/components/forms/Button";
import PropertyChooseModal from "components/property/PropertyChooseModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PropertySelectValueType } from "./helpers/usePropertySelect";

interface SelectPropertyDialogButtonProps {
  value?: PropertySelectValueType;
  onChange?: (newValue: PropertySelectValueType | undefined) => void;
  disabled?: boolean;
}

export const SelectPropertyDialogButton = ({
  value,
  onChange,
  disabled = false,
}: SelectPropertyDialogButtonProps) => {
  const { t } = useTranslation();
  const [chooseModalOpen, setChooseModalOpen] = useState(false);

  return (
    <div className="tw-basis-1/2">
      <Button
        isPrimary
        isOutline
        isBlock
        onClick={() => {
          setChooseModalOpen(true);
        }}
        disabled={disabled}
      >
        {t("Select")}
      </Button>
      <PropertyChooseModal
        isOpen={chooseModalOpen}
        onCancel={() => {
          setChooseModalOpen(false);
        }}
        onApply={(chosenItems, raw) => {
          onChange?.({
            value: chosenItems[0],
            property: raw?.[0].excerpt.props.entity,
          });
          setChooseModalOpen(false);
        }}
      />
    </div>
  );
};
