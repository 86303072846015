import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseContactCreator, {
  ContactResourceTypes,
  filterOutUsersAndSubsidiaries,
} from "./baseContactCreator";

const name = "mainContact" as const;
const pluralName = "mainContacts" as const;

export const { types, actions, rootReducer }: ContactResourceTypes =
  createResource(
    baseContactCreator({
      name,
      pluralName,
      forcedFilter: filterOutUsersAndSubsidiaries,
    })
  );

const mainContactResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const mainContactResource = mainContactResourceCreator.resource;
export const useMainContact = mainContactResourceCreator.createHook();
