import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { ReactNode, useEffect, useState } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import Button, { ButtonProps } from "../forms/Button";

const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownMenuContent = DropdownMenuPrimitive.Content;
const DropdownMenuItem = DropdownMenuPrimitive.Item;
const DropdownMenuSeparator = DropdownMenuPrimitive.DropdownMenuSeparator;

export interface DropdownProps {
  openOriginPreference?: "topleft" | "topright";
  icon?: IconDefinition | "toggler";
  label?: ReactNode;
  toggleBtnProps?: Partial<ButtonProps>;
  items: (
    | {
        icon?: IconDefinition;
        label: ReactNode;
        disabled?: boolean;
        primaryText?: boolean;
        onItemClick?: () => void | Promise<void>;
      }
    | {
        icon?: IconDefinition;
        label: ReactNode;
        externalLink: string;
      }
    | {
        icon?: IconDefinition;
        label: ReactNode;
        navLink: string;
      }
    | "spacer"
  )[];
}

function Dropdown({
  openOriginPreference = "topleft",
  icon = "toggler",
  label,
  toggleBtnProps = {},
  items,
}: DropdownProps) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  // fix that it closes automatically on window.scroll
  useEffect(() => {
    function closeHandler(e: Event) {
      // scroll on "document" allowed -> happens on mousehover (internal radix ui logic) for dropdowns that are to big for the screen, to show all the items by programatically scrolling
      if (e.target !== document) {
        setIsOpen(false);
      }
    }
    window.addEventListener("scroll", closeHandler, true);
    return () => {
      window.removeEventListener("scroll", closeHandler, true);
    };
  }, []);

  return (
    <DropdownMenu
      modal={false}
      // we are using a controlled state, to implement a fix that it closes automatically on window.scroll
      open={isOpen}
      onOpenChange={(newState) => {
        setIsOpen(newState);
      }}
    >
      <DropdownMenuTrigger
        asChild
        onClick={(e) => {
          // stopPropagation needed to stop propagating to parent (like TableList onItemClick)
          e.stopPropagation();
          // similar story
          e.preventDefault();
        }}
      >
        <Button {...toggleBtnProps} isToggle={icon === "toggler"}>
          {label}
          {icon !== "toggler" && <FontAwesomeIcon icon={icon} />}
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="cs-dropdown-menu show"
        align={
          openOriginPreference === "topleft"
            ? // || openOriginPreference === "bottomleft"
              "start"
            : "end"
        }
        loop
      >
        {items.map((item, i) => {
          function renderItem() {
            if (item === "spacer") {
              throw new Error("Something unexpected happened");
            }

            return (
              <Button
                key={i}
                isDropdown
                disabled={"disabled" in item && item.disabled}
              >
                {item.icon && (
                  <FontAwesomeIcon
                    icon={item.icon}
                    fixedWidth
                    className="tw-mr-1"
                    style={
                      "disabled" in item && item.disabled
                        ? { opacity: "0.5" }
                        : {}
                    }
                  />
                )}
                {item.label}
              </Button>
            );
          }
          if (item === "spacer") {
            return (
              <DropdownMenuSeparator key={i} className="cs-dropdown-divider" />
            );
          }
          return (
            <DropdownMenuItem
              key={i}
              onClick={(e) => {
                // stopPropagation needed to stop propagating to parent (like TableList onItemClick)
                e.stopPropagation();
                // preventDefault NOT needed!
              }}
              onSelect={async (e) => {
                if ("externalLink" in item) {
                  window.open(item.externalLink, "_blank");
                } else if ("navLink" in item) {
                  history.push(item.navLink);
                } else {
                  if (!item.disabled) {
                    await item.onItemClick?.();
                  }
                }
              }}
            >
              {renderItem()}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default Dropdown;
