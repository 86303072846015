import resourceGrabber from "utilities/resourceGrabber";
import { readableColor, darken } from "polished";
import { GetPhaseListResponse } from "api/entities/phase/endpoints/list/types";

interface PhaseExcerptProps {
  phase?: GetPhaseListResponse["_embedded"]["phase"][number];
}

export const getPhaseLabel = (
  phase?: GetPhaseListResponse["_embedded"]["phase"][number]
) => {
  const userLang = resourceGrabber.grab("lang", "auth");
  const mainLang = resourceGrabber.grab("mainLang", "auth");
  const contents = phase?._embedded?.contents;
  return (
    contents?.find((content) => content.lang === userLang)?.name ||
    contents?.find((content) => content.lang === mainLang)?.name ||
    contents?.find((content) => content.lang === "de")?.name
  );
};

const PhaseExcerpt = ({ phase }: PhaseExcerptProps) => {
  return phase ? (
    <div
      style={{
        borderRadius: "5px",
        display: "inline-block",
        fontSize: "0.6rem",
        padding: "1px 4px",
        backgroundColor: `${phase?.color || "#e3e3e3"}`,
        marginTop: "5px",
      }}
    >
      <strong
        style={{
          color: readableColor(darken(0.2, phase?.color || "#e3e3e3")),
        }}
      >
        {getPhaseLabel(phase)}
      </strong>
    </div>
  ) : null;
};

export default PhaseExcerpt;
