import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Config from "config";
import { hasAccessTo } from "utilities/featureFlags";
import { history } from "config/history";
import { axiosInstance } from "utilities/axios";
import { AlertItem } from "./models";

export const useOverdueInvoiceWarning = () => {
  const { t } = useTranslation();
  const [hasWarning, setHasWarning] = useState(false);

  useEffect(() => {
    if (hasAccessTo("invoiceManagement")) {
      axiosInstance
        .get(
          `${Config.api2Url}/company/${Config.customerKey}/invoice?hasInvoiceFile=true&overdue=true`
        )
        .then((response) => {
          if (response.data.total_items > 0) {
            // set this one again to true to show the warning
            setHasWarning(false);
          }
        });
    }
  }, []);

  const overdueInvoiceWarning: AlertItem | undefined = hasWarning
    ? {
        colorType: "danger",
        cookieId: "cs_invoice_overdue",
        text: t(
          "You have probably overlooked the fact that an invoice is due for payment. Please transfer the amount within the next 10 days. Show invoice."
        ),
        onClick: () => {
          history.push("/invoices");
        },
      }
    : undefined;

  return {
    overdueInvoiceWarning,
  };
};
