import { createResource } from "redux-rest-resource";
import Config from "config";
import createBaseActions from "entities/createBaseActions";
import BaseState from "entities/baseState";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: BaseState, actions?: any) => BaseState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "caAddress",
  pluralName: "caAddresses",
  url: `${Config.api2Url}/crm/address/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "address",
    }),
  },
});
