import { Spinner } from "@casasoft/styleguide/components/helpers-ux";

export const MessagingSelectLoading = () => {
  return (
    <div
      className="spinner-fixture tw-text-sm"
      style={{
        width: "2em",
        height: "2em",
        transform: "scale(0.5)",
      }}
    >
      <Spinner />
    </div>
  );
};
