import { Media } from "utilities";
import CheckboxNew from "../../forms/CheckboxNew";
import { Tooltip } from "@casasoft/styleguide/components/tooltip";
import { Thumb } from "../../media";
import MediaExcerpt from "components/media/MediaExcerpt";
import type {
  PropertyAttachment,
  PropertyDossierAttachment,
} from "./helpers/propertyAttachment.model";
import { twMerge } from "tailwind-merge";
import {
  isDocumentationAttachment,
  isFlyerAttachment,
} from "./helpers/attachmentGuard";
import { PropertyAttachmentItemLanguage } from "./PropertyAttachmentItemLanguage";

interface PropertyAttachmentItemProps {
  attachment: PropertyAttachment;
  isChecked: boolean;
  hasActiveSidebar?: boolean;
  onAttachmentToggle: (
    attachment: PropertyAttachment,
    isChecked: boolean
  ) => void;
  onAttachmentHover?: (attachment: PropertyAttachment) => void;
}

export const PropertyAttachmentItem = ({
  attachment,
  isChecked,
  hasActiveSidebar,
  onAttachmentToggle,
  onAttachmentHover,
}: PropertyAttachmentItemProps) => {
  const isDossierAttachment =
    isDocumentationAttachment(attachment) || isFlyerAttachment(attachment);

  const handleClick = () => {
    onAttachmentToggle(attachment, isChecked);
  };

  const handleMouseEnter = () => {
    onAttachmentHover && onAttachmentHover(attachment);
  };

  return (
    <div
      className={twMerge(
        "tw-border-solid tw-border-0 tw-border-b-[1px] tw-border-cs-shade-100 tw-cursor-pointer tw-select-none tw-bg-cs-shade",
        hasActiveSidebar && "tw-bg-cs-shade-50"
      )}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
    >
      <Tooltip content={attachment.tooltip} triggerAsChild>
        <div className="tw-flex tw-items-center tw-p-3">
          <CheckboxNew
            name={attachment.id}
            checked={isChecked}
            onToggle={handleClick}
            className="tw-mr-3 tw-shrink-0"
          />

          <Thumb
            src={Media.mediaThumbnail(attachment.mediaResource, "sqmd")}
            mimeType={attachment.mediaResource?.mimeType}
          />
          <div className="tw-ml-3 tw-min-w-[140px] tw-max-w-[500px]">
            <MediaExcerpt
              renderName
              renderInfos={!isDossierAttachment}
              lines={4}
              entity={attachment.mediaResource}
              title={attachment.title}
            />
          </div>
          {isDossierAttachment && (
            <PropertyAttachmentItemLanguage
              attachment={attachment as PropertyDossierAttachment}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
