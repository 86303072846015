import { Component } from "react";
import PropTypes from "prop-types";
import FormGroup from "../formElements/helpers/FormGroup";

class BaseField extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      curVal: "",
      filled: false,
    };
  }

  componentWillMount() {
    if (this.props.value) {
      this.setState({
        curVal: this.props.value,
        filled: this.props.value !== "",
      });
    } else {
      this.setState({ filled: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.curVal) {
      this._handleChange(nextProps.value);
    }
  }

  _handleChange(value) {
    // console.log('Basefield change');
    // receives the props and sets the state then less render updates
    this.setState({
      curVal: value,
      filled: value !== "",
    });
    if (this.props?.onChange) {
      this.props.onChange(
        value,
        this.props.keyPass ? this.props.keyPass : this.props.id,
        this.props.orig
      );
    }
  }

  _handleKeyUp(event) {
    // only Keyup if needed otherwise always double trigger
    if (this.props.onDelayChange) {
      this.props.onKeyUp?.(event.target.value, this.props.id);
      this._handleDelayChange();
    }
  }

  _handleDelayChange() {
    const component = this;
    if (this.state.onDelayTimer) {
      clearTimeout(this.state.onDelayTimer);
    }
    const newtimer = setTimeout(() => {
      if (component.props.onDelayChange) {
        component.props.onDelayChange(
          component.state.curVal,
          component.props.id,
          component.props.orig
        );
      }
    }, component.props.onDelayDuration);

    this.setState({ onDelayTimer: newtimer });
  }

  _handleFocus(value) {
    this.setState({ focused: true });
    if (this.props?.onFocus) {
      this.props.onFocus(
        value,
        this.props.keyPass ? this.props.keyPass : this.props.id,
        this.props.orig
      );
    }
  }

  _handleBlur(value) {
    this.setState({ focused: false });
    if (this.props?.onBlur) {
      this.props.onBlur(
        value,
        this.props.keyPass ? this.props.keyPass : this.props.id,
        this.props.orig
      );
    }
  }

  _getSharedProps() {
    return {
      disabled: this.props.disabled,
      autoComplete: this.props.autoComplete,
      id: this.props.id,
      placeholder: this.props.placeholder,
      quickActions: this.props.quickActions,
      onChange: (eventOrValue) => {
        if (eventOrValue && eventOrValue.target) {
          this._handleChange(eventOrValue.target.value);
        } else {
          this._handleChange(eventOrValue);
        }
      },
      value: this.state.curVal,
      onKeyUp: (event) => {
        this._handleKeyUp(event);
      },
      onFocus: (event) => {
        this._handleFocus(event.target.value);
      },
      onBlur: (event) => {
        this._handleBlur(event.target.value);
      },
      name: this.props.name,
      required: this.props.required,
      nobox: this.props.nobox,
      ref: null,
      setRef: this.props.setRef,
      focused: this.state.focused,
      filled: this.state.filled,
      label: this.props.label,
      message: this.props.message,
      text: this.props.text,
      theme: "cs",
      lang: this.props.lang,
      autoFocus: this.props.autoFocus,
      maxLength: this.props.maxLength,
    };
  }

  render() {
    const sharedProps = this._getSharedProps();
    return <FormGroup {...sharedProps}>please define render</FormGroup>;
  }
}

BaseField.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  filled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  nobox: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  text: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  lang: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  setRef: PropTypes.func,
  keyPass: PropTypes.string,
  orig: PropTypes.string,
  onChange: PropTypes.func,
  onDelayChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
  onDelayDuration: PropTypes.number,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.string,
  t: PropTypes.func,
};

BaseField.defaultProps = {
  type: "text",
  disabled: false,
  focused: false,
  filled: false,
  className: "",
  label: "",
  id: "",
  required: false,
  nobox: false,
  message: null,
  text: null,
  placeholder: "",
  value: "",
  name: "",
  keyPass: "",
  orig: "",
  lang: null,
  onDelayChange: null,
  autoComplete: "none",
  onDelayDuration: 100,
  autoFocus: false,
  maxLength: null,
  t: (key) => {
    return key;
  },
};

export default BaseField;
