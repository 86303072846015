import Config from "config";
import createBaseActions from "entities/createBaseActions";
import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import UserEmailSettingState from "./types";

const name = "mainUserEmailSetting" as const;
const pluralName = "mainUserEmailSettings" as const;

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: UserEmailSettingState,
    actions?: any
  ) => UserEmailSettingState;
}

export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: name,
  pluralName: pluralName,
  url: `${Config.apiUrl}/${Config.customerKey}/user-email-setting/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "user_email_setting",
    }),
  },
});

const mainUserEmailSettingResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const mainUserEmailSettingResource =
  mainUserEmailSettingResourceCreator.resource;
export const useMainUserEmailSetting =
  mainUserEmailSettingResourceCreator.createHook();
