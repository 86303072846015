import { AddRemainderFormShape } from "../AddReminderForm";

const allPropertiesAreEmpty = (obj: any) => {
  return Object.values(obj).every((v) => !v);
};

export const isEmptyAddReminderForm = (
  form: AddRemainderFormShape | undefined
): boolean => {
  return !form || Object.keys(form).length === 0 || allPropertiesAreEmpty(form);
};
