import { API } from "utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IconName, IconPrefix } from "@fortawesome/pro-light-svg-icons";
import iconMap from "utilities/iconMap";

export interface EventTypesShape {
  eventTypesList: (Omit<API.EventTypesListResponseShape, "icon"> & {
    value: string;
    icon: [IconPrefix, IconName];
  })[];
  lastFetched: number;
}

interface ResponsePayload {
  response: EventTypesShape["eventTypesList"];
  updateReceivedAt: number;
}

interface ErrorPayload {
  response?: Error;
  updateReceivedAt: number;
}

export const getEventTypeList = createAsyncThunk<
  ResponsePayload,
  void,
  {
    rejectValue: ErrorPayload;
  }
>("@EVENT_TYPES/REQUEST", (_, { rejectWithValue }) =>
  API.fetchEventTypesAPI()
    .then((response) => {
      const items: EventTypesShape["eventTypesList"] =
        response.eventTypes?.map((item) => {
          return {
            ...item,
            value: item.eventType || item.baseType,
            // check icon map if the icon exists in our library, otherwise use fallback icon
            // regular and solid is currently never used in the FE. we could add it here as well in the future
            icon: iconMap[item.icon]
              ? ["fal", item.icon.split("/")[1] as IconName]
              : ["fal", "question-circle"],
          };
        }) || [];

      return {
        response: items,
        updateReceivedAt: Date.now(),
      };
    })
    .catch((err: Error) => {
      return rejectWithValue({
        response: {
          name: err.name,
          message: err.message,
          stack: err.stack,
        },
        updateReceivedAt: Date.now(),
      });
    })
);
