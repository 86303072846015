import { useState, useEffect } from "react";
import AddressTypeAhead from "@casasoft/styleguide/components/forms/AddressTypeAhead";
import AddressPicker from "@casasoft/styleguide/components/forms/AddressPickerMapBox";
import Switch from "@casasoft/styleguide/components/forms/Switch";
import { useTranslation } from "react-i18next";
import Config from "config";
import Button from "@casasoft/styleguide/components/forms/Button";
import useForm, { RegisterFields } from "hooks/useForm";
import { SubmitHandler } from "react-hook-form";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import asyncLoading from "react-async-loader";
import { SINGLE_PUBLICATION_TYPES } from "utilities/propertyTypes";
import { Countries } from "utilities";
import { axiosInstance } from "utilities/axios";
import { PartialOrNull } from "utilities/type-helpers/object-oberators";
import { CompanyShape } from "api/entities/csiamCompany/types";

interface FormShape {
  locality: string | null;
  postalCode: number | null;
  street: string | null;
  streetNumber: string | null;
  lat: number | null;
  lng: number | null;
  hideExactAddress: boolean;
  postOfficeBoxNumber: string | null;
  country: string | null;
  region: string | null;
}

interface AddressEditFormProps {
  address?: CompanyShape["address"];
  forEntity: string;
  pinUpdateAddress?: boolean;
  postOfficeBoxField?: boolean;

  gmap?: any;

  // loadDefaultValues is an alternative to providing the address prop
  loadDefaultValues?: () => Promise<Partial<AddressEditFormProps["address"]>>;
  // returns true if it was successful
  onSubmit: (
    dataToPatch: PartialOrNull<FormShape> & { elevation?: string }
  ) => Promise<true | undefined>;
}

const AddressEditForm = ({
  address,
  forEntity,
  pinUpdateAddress,
  postOfficeBoxField,
  gmap,
  loadDefaultValues,
  onSubmit: onSubmitProp,
}: AddressEditFormProps) => {
  // TODO: remove this unnecessary ugly hack after AddressTypeAhead is refactored. It's only purpose is to make sure the Map renders AFTER the TypeAhaed to prevent a double marker bug
  const [mapIsReady, setMapIsReady] = useState(false);
  useEffect(() => {
    setMapIsReady(true);
  }, []);
  const [isLoadingDefaults, setIsLoadingDefaults] = useState<
    boolean | "loaded"
  >(!loadDefaultValues ? "loaded" : false);

  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },

    control,
    reset,
    getDirtyValues,
    trigger,
  } = useForm<FormShape>({
    defaultValues: {
      locality: address?.locality || null,
      postalCode: address?.postalCode || null,
      street: address?.street || null,
      streetNumber: address?.streetNumber || "",
      lat: (address?.lat && parseFloat(address?.lat)) || null,
      lng: (address?.lng && parseFloat(address?.lng)) || null,
      hideExactAddress: address?.hideExactAddress
        ? address?.hideExactAddress
        : false,
      postOfficeBoxNumber: address?.postOfficeBoxNumber || null,
      country: address?.country || null,
      region: address?.region || null,
    },
  });

  // load default values async
  useEffect(() => {
    if (loadDefaultValues && !isLoadingDefaults) {
      setIsLoadingDefaults(true);
      const loadDefaults = async () => {
        const defaultData = await loadDefaultValues();
        const readyData = {
          locality: defaultData?.locality ? defaultData.locality : null,
          postalCode: defaultData?.postalCode ? defaultData.postalCode : null,
          street: defaultData?.street ? defaultData.street : null,
          streetNumber: defaultData?.streetNumber
            ? defaultData.streetNumber
            : null,
          hideExactAddress: defaultData?.hideExactAddress
            ? defaultData.hideExactAddress
            : false,
          postOfficeBoxNumber: defaultData?.postOfficeBoxNumber
            ? defaultData.postOfficeBoxNumber
            : null,
          country: defaultData?.country ? defaultData.country : null,
          region: defaultData?.region ? defaultData.region : null,
          lng: defaultData?.lng ? parseFloat(defaultData.lng) : null,
          lat: defaultData?.lat ? parseFloat(defaultData.lat) : null,
        };
        reset(readyData);
        setIsLoadingDefaults("loaded");
      };
      loadDefaults();
    }
  }, [reset, loadDefaultValues, isLoadingDefaults]);

  const onSubmit: SubmitHandler<FormShape> = async (data) => {
    // get elevation from whereever
    let elevation: string | undefined = undefined;
    const dirtyData = getDirtyValues();
    if (dirtyData.lat && dirtyData.lng) {
      const response = await axiosInstance.get(
        `${Config.apiUrl}/${Config.customerKey}/get-elevation?locations=${dirtyData.lat},${dirtyData.lng}`
      );
      if (response?.data?.results?.[0]?.elevation) {
        elevation = response.data.results[0].elevation;
      }
    }

    const dataToPatch = {
      ...dirtyData,
      elevation,
    };
    const didSubmit = await onSubmitProp(dataToPatch);
    didSubmit && reset(data);
  };

  return (
    <RegisterFields
      control={control}
      fields={{
        locality: {},
        postalCode: {},
        lat: {},
        lng: {},
        hideExactAddress: {},
        street: {},
        streetNumber: {},
        postOfficeBoxNumber: {},
        country: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        region: {},
      }}
      render={(fieldsRenderer, chainFields) => {
        return (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={
              isSubmitting || isLoadingDefaults === true
                ? { opacity: 0.5, pointerEvents: "none" }
                : {}
            }
          >
            {chainFields(
              [
                "country",
                "locality",
                "postalCode",
                "lat",
                "lng",
                // "hideExactAddress",
                "street",
                "streetNumber",
                "postOfficeBoxNumber",
                "country",
                "region",
              ],
              (formValues, formValueChangeHandlers) => {
                return (
                  <>
                    <AddressTypeAhead
                      nobox
                      postOfficeBoxField={postOfficeBoxField}
                      id="locality"
                      label={t("Address")}
                      placeholder={t("Street, locality")}
                      subvalues={{
                        lat: formValues.lat || undefined,
                        lng: formValues.lng || undefined,
                        street: formValues.street || undefined,
                        street_number: formValues.streetNumber || undefined,
                        post_office_box_number:
                          formValues.postOfficeBoxNumber || undefined,
                        locality: formValues.locality || undefined,
                        postal_code: formValues.postalCode || undefined,
                        country: formValues.country || undefined,
                        region: formValues.region || undefined,
                      }}
                      gmap={gmap}
                      onSubvaluesChange={(newAddress: any) => {
                        if (isLoadingDefaults === "loaded") {
                          formValueChangeHandlers.locality(
                            newAddress.locality || null
                          );
                          formValueChangeHandlers.postalCode(
                            newAddress.postal_code || null
                          );
                          formValueChangeHandlers.street(
                            newAddress.street || null
                          );
                          formValueChangeHandlers.streetNumber(
                            newAddress.street_number || null
                          );
                          formValueChangeHandlers.postOfficeBoxNumber(
                            newAddress.post_office_box_number || null
                          );
                          formValueChangeHandlers.country(
                            newAddress.country || null
                          );
                          formValueChangeHandlers.region(
                            newAddress.region || null
                          );
                          formValueChangeHandlers.lat(newAddress.lat || null);
                          formValueChangeHandlers.lng(newAddress.lng || null);
                          trigger("country");
                        }
                      }}
                      countryDropdownOptions={Countries.getCountries()}
                      autocomplete="false"
                      translate={(string: string) => {
                        switch (string) {
                          case "Enter address manual":
                            return t("addressTypeAhead.enterAddressManual");
                          case "Street":
                            return t("addressTypeAhead.street");
                          case "Street Number":
                            return t("addressTypeAhead.streetNumber");
                          case "Postal Code":
                            return t("addressTypeAhead.postalCode");
                          case "Locality":
                            return t("addressTypeAhead.locality");
                          case "Post Office Box Number":
                            return t("addressTypeAhead.postOfficeBoxNumber");
                          case "Country":
                            return t("addressTypeAhead.country");
                          case "Automatic address lookup":
                            return t(
                              "addressTypeAhead.Automatic address lookup"
                            );
                          case "Active":
                            return t("Active");
                          default:
                            return string;
                        }
                      }}
                    />
                    {mapIsReady && formValues.lat && (
                      <AddressPicker
                        lat={formValues.lat}
                        lng={formValues.lng}
                        gmap={gmap}
                        zoom={16}
                        mapStyle="street"
                        onChange={(newAddress: any) => {
                          formValueChangeHandlers.lat(newAddress.lat);
                          formValueChangeHandlers.lng(newAddress.lng);
                          if (pinUpdateAddress) {
                            formValueChangeHandlers.locality(
                              newAddress.locality
                            );
                            formValueChangeHandlers.postalCode(
                              newAddress.postal_code
                            );
                            formValueChangeHandlers.street(newAddress.street);
                            formValueChangeHandlers.streetNumber(
                              newAddress.street_number
                            );
                            formValueChangeHandlers.postOfficeBoxNumber(
                              newAddress.post_office_box_number
                            );
                            formValueChangeHandlers.country(newAddress.country);
                            formValueChangeHandlers.region(newAddress.region);
                            trigger();
                          }
                        }}
                        countryDropdownOptions={Countries.getCountries()}
                        autocomplete="false"
                        pinUpdateAddress={pinUpdateAddress}
                      />
                    )}
                  </>
                );
              }
            )}
            {SINGLE_PUBLICATION_TYPES.includes(forEntity) &&
              fieldsRenderer(
                "hideExactAddress",
                (formValue, onFormValueChange) => (
                  <Switch
                    checked={formValue}
                    label={t("hide exact address")}
                    onToggle={(value: boolean) => {
                      onFormValueChange(value || false);
                    }}
                  />
                )
              )}
            {isDirty && (
              <ModalFooter>
                <Button
                  disabled={!isValid}
                  isPrimary
                  type="submit"
                  buttonValue={t("Save")}
                />
              </ModalFooter>
            )}
          </form>
        );
      }}
    />
  );
};

const compAsyncLoaded = asyncLoading(() => {
  const googleMapsApiKey = Config.googleMapsApiKey;
  const gmap = {
    globalPath: "google.maps",
    url: `${Config.googleMapsApiUrl}/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
    jsonp: true,
  };
  return { gmap };
})(AddressEditForm);
export default compAsyncLoaded as typeof AddressEditForm;
