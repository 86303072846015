import getAuthTokens from "./getAuthTokens";

function shouldRefetchAuthToken() {
  const activeTokens = getAuthTokens();
  if (activeTokens.accessTokenExpire <= Date.now()) {
    return true;
  }
  return false;
}

export default shouldRefetchAuthToken;
