import PropTypes from "prop-types";
import BaseField from "./BaseField";
import FormGroup from "../formElements/helpers/FormGroup";
import TextareaAutoresize from "react-textarea-autosize";

class Textarea extends BaseField {
  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        this.textarea.focus();
      }, 200);
    }
  }

  render() {
    const sharedProps = this._getSharedProps();

    if (this.props.rows) {
      sharedProps.rows = this.props.rows;
    }

    if (this.props.autoresize) {
      sharedProps.autoresize = this.props.autoresize;
    }

    const textareaProps = {
      value: sharedProps.value,
      disabled: sharedProps.disabled,
      id: sharedProps.id,
      placeholder: sharedProps.placeholder,
      onKeyUp: sharedProps.onKeyUp,
      onFocus: sharedProps.onFocus,
      onBlur: sharedProps.onBlur,
      name: sharedProps.name,
      required: sharedProps.required,
      rows: this.props.rows,
      minRows: this.props.minRows,
      maxRows: this.props.maxRows,
      autoresize: this.props.autoresize,
      onChange: (event) => {
        this._handleChange(event.target.value);
      },
      maxLength: sharedProps.maxLength,
    };

    return (
      <FormGroup {...sharedProps}>
        {(this.props.autoresize && (
          <TextareaAutoresize
            minRows={this.props.minRows}
            maxRows={this.props.maxRows}
            ref={(i) => {
              this.textarea = i;
            }}
            className="cs-form-control"
            {...textareaProps}
          />
        )) || (
          <textarea
            ref={(i) => {
              this.textarea = i;
            }}
            className="cs-form-control"
            {...textareaProps}
          />
        )}
      </FormGroup>
    );
  }
}

Textarea.propTypes = {
  rows: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  autoresize: PropTypes.boolean,
};
Textarea.defaultProps = {
  rows: 2,
  minRows: 1,
  maxRows: 99,
  autoresize: false,
};

export default Textarea;
