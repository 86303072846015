import Switch from "@casasoft/styleguide/components/formElements/Switch";
import { useTranslation } from "react-i18next";

interface MessageHistoryControlsProps {
  isExpanded: boolean;
  onHideSwitchToggle: () => void;
}

export const MessageHistoryControls = ({
  isExpanded,
  onHideSwitchToggle,
}: MessageHistoryControlsProps) => {
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-items-center tw-mr-8 tw-ml-[-8px]">
      <Switch
        checked={isExpanded}
        onToggle={() => {
          onHideSwitchToggle();
        }}
        className="tw-w-auto tw-mr-[-12px] tw-mb-0"
      />
      <span>{t("Include last message")}</span>
    </div>
  );
};
