import { faEdit, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReducedContactShape } from "../LanguageBreakdownContainer";
import { useTranslation } from "react-i18next";

export interface LanguageContactGroupRowProps {
  contact: ReducedContactShape;
  onContactEdit: () => void;
  isLast?: boolean;
}

export const LanguageContactGroupRow = ({
  contact,
  onContactEdit,
  isLast = false,
}: LanguageContactGroupRowProps) => {
  const { t } = useTranslation();
  const borderClass =
    "tw-border-solid tw-border-0 tw-border-b tw-border-b-cs-shade-100";
  const rowClass = `tw-flex tw-items-center tw-p-4 ${
    isLast ? "" : borderClass
  }`;

  const displayName =
    contact.displayName || contact.email || t("Name is missing");

  return (
    <div className={rowClass}>
      <FontAwesomeIcon icon={faUser} className="tw-mr-1" />
      <div className="tw-grow tw-font-semibold tw-text-sm">{displayName}</div>
      <FontAwesomeIcon
        icon={faEdit}
        className=" tw-text-tw-cs-accent-color"
        cursor="pointer"
        onClick={onContactEdit}
      />
    </div>
  );
};
