import { useRef } from "react";
import { GroupBase } from "reactSelectNew";
import { AsyncAdditionalProps } from "reactSelectNew/dist/declarations/src/useAsync";

export function useReactSelectDebouncableLoadOptions<
  OptionType = unknown,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(
  originalLoadOptions?: AsyncAdditionalProps<
    OptionType,
    GroupType
  >["loadOptions"],
  loadOptionsDebounce?: number
) {
  const loadOptionsDebounceRef = useRef<{
    timer: ReturnType<typeof setTimeout> | null;
  }>({ timer: null });

  const debouncableLoadOptions: typeof originalLoadOptions = async (
    ...args
  ) => {
    loadOptionsDebounceRef.current.timer &&
      clearTimeout(loadOptionsDebounceRef.current.timer);
    const loadOptionsDebouncePromise = await new Promise<
      readonly (OptionType | GroupType)[]
    >((res) => {
      loadOptionsDebounceRef.current.timer = setTimeout(async () => {
        const result = await originalLoadOptions?.(...args);
        if (!result) {
          throw new Error("An error occured");
        }
        res(result);
      }, loadOptionsDebounce || 0);
    });
    return loadOptionsDebouncePromise;
  };

  return loadOptionsDebounce && originalLoadOptions
    ? debouncableLoadOptions
    : originalLoadOptions;
}
