import * as Sentry from "@sentry/browser";
import { createReducer, SerializedError } from "@reduxjs/toolkit";
import { API } from "utilities";
import { updateGateway } from "./actions";

interface GatewayShape {
  updateStatus: string | null;
  updatePayload: SerializedError | API.UpdateGatewayAPIResponse | null;
  updateReceivedAt: number | null;
}

const initialState: GatewayShape = {
  updateStatus: null,
  updatePayload: null,
  updateReceivedAt: null,
};

const gatewayReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateGateway.pending, (state) => {
      return {
        ...state,
        updateStatus: "fetching",
        updatePayload: null,
        updateReceivedAt: null,
      };
    })
    .addCase(updateGateway.rejected, (state, action) => {
      return {
        ...state,
        updateStatus: "error",
        updatePayload: action.payload?.response || action.error,
        updateReceivedAt: action.payload?.updateReceivedAt || null,
      };
    })
    .addCase(updateGateway.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response?.gateway?.httpcode === 200) {
        return {
          ...state,
          updateStatus: "success",
          updatePayload: response,
          updateReceivedAt: action.payload.updateReceivedAt,
        };
      }

      if (response.status === 500 || response?.gateway?.httpcode === 500) {
        return {
          ...state,
          updateStatus: "fail",
          updatePayload: response,
          updateReceivedAt: null,
        };
      }

      if (response.message === "Unable to acquire lock.") {
        return {
          ...state,
          updateStatus: "locked",
          updatePayload: response,
          updateReceivedAt: null,
        };
      }

      // Handle weird gateway responses where we get a 200 from the server, but something unhandled in the response.
      // When this happens the sync button is always rotating and seems like it's forever fetching data.
      // In these cases we send the whole response to Sentry (ugly but does the job).
      Sentry.captureException(response);
      return {
        ...state,
        updateStatus: "error",
        updatePayload: response,
        updateReceivedAt: null,
      };
    });
});

export { gatewayReducer };
