import { ReactNode, useState } from "react";
import FilterForm, {
  FilterFormProps,
} from "components/miscellaneous/FilterForm";
import FilterFormSelect from "components/miscellaneous/FilterFormSelect";

interface FilterFormContainerProps {
  presetName: string;
  filterFormOptions?: FilterFormProps["filterFormOptions"];
  renderFilterForm?: FilterFormProps["renderFilterForm"];
  render: (tableListProps: {
    filterFormSelect?: ReactNode;
    filterForm?: ReactNode;
  }) => ReactNode;
}

function FilterFormContainer({
  presetName,
  filterFormOptions,
  renderFilterForm,
  render,
}: FilterFormContainerProps) {
  const [filterFormOpen, setFilterFormOpen] = useState(false);

  return (
    <>
      {render({
        filterFormSelect: renderFilterForm && (
          <FilterFormSelect
            presetName={presetName}
            filterFormOptions={filterFormOptions}
            onCreateFilter={() => {
              setFilterFormOpen(true);
            }}
          />
        ),
        filterForm: renderFilterForm && (
          <FilterForm
            presetName={presetName}
            open={filterFormOpen}
            openStateControl={(state) => {
              if (state === "close") {
                setFilterFormOpen(false);
              }
              if (state === "open") {
                setFilterFormOpen(true);
              }
            }}
            filterFormOptions={filterFormOptions}
            renderFilterForm={renderFilterForm}
          />
        ),
      })}
    </>
  );
}

export default FilterFormContainer;
