import queryBuilder, { FilterItem, OrderByItem } from "utilities/queryBuilder";
import { createQuery } from "api/createEndpoint";
import { GetTemplatesListQueryParams } from "./types";

const getAllQuery = () => {
  const orderBy: OrderByItem[] = [
    {
      type: "field",
      field: "name",
      direction: "asc",
    },
  ];

  return { orderBy, filter: [], pageSize: 100 };
};

const getSearchQuery = (searchString: string) => {
  const filter: FilterItem[] = [
    {
      type: "search",
      value: searchString,
    },
  ];

  return { orderBy: [], filter, pageSize: 20 };
};

const query = createQuery<GetTemplatesListQueryParams>(
  ({ searchString, query }) => {
    const { orderBy, filter, pageSize } = {
      ...query,
      ...(searchString ? getSearchQuery(searchString) : getAllQuery()),
    };

    const builtQuery = queryBuilder.buildToUrl(orderBy, filter, {
      pagesize: pageSize,
      page: 1,
    });

    return {
      url: `template?${builtQuery}`,
      method: "GET",
    };
  }
);

export default query;
