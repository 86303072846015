import moment from "moment";
import "moment/locale/de-ch";
import "moment/locale/fr-ch";
import "moment/locale/it-ch";
import "moment/locale/en-gb";
import resourceGrabber from "./resourceGrabber";

const csMoment = (date) => {
  const lang = resourceGrabber.grab("lang", "auth");
  let locale = "de-ch";
  if (lang) {
    switch (lang) {
      case "fr":
        locale = "fr-ch";
        break;
      case "it":
        locale = "it-ch";
        break;
      case "en":
        locale = "en-gb";
        break;
      case "de":
        locale = "de-ch";
        break;
      default:
        locale = "de-ch";
        break;
    }
  }
  const dateObject = moment(date);
  dateObject.locale(locale);
  return dateObject;
};

export default csMoment;
