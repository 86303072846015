import i18n from "./i18n";
import { renderPrice } from "./price";

function getNumvals(): Record<
  string,
  {
    label: string;
    type: string;
    si: string;
  }
> {
  return {
    floor: {
      label: i18n.t("standards:Floor"),
      type: "int_signed",
      si: "floor",
    },
    number_of_floors: {
      label: i18n.t("standards:Floors"),
      type: "int_positive",
      si: "",
    },
    number_of_apartments: {
      label: i18n.t("standards:Number of residential units"),
      type: "int_positive",
      si: "",
    },
    number_of_buildings: {
      label: i18n.t("standards:Number of buildings"),
      type: "int_positive",
      si: "",
    },
    number_of_commercial_units: {
      label: i18n.t("standards:Number of commercial units"),
      type: "int_positive",
      si: "",
    },
    number_of_bathrooms: {
      label: i18n.t("standards:Bathrooms"),
      type: "float_half",
      si: "",
    },
    number_of_guest_toilets: {
      label: i18n.t("standards:Guest toilets"),
      type: "int_positive",
      si: "",
    },
    distance_kindergarten: {
      label: i18n.t("standards:Distance to kindergarten"),
      type: "int_positive",
      si: "m",
    },
    distance_primary_school: {
      label: i18n.t("standards:Distance to primary school"),
      type: "int_positive",
      si: "m",
    },
    distance_high_school: {
      label: i18n.t("standards:Distance to high school"),
      type: "int_positive",
      si: "m",
    },
    distance_college_university: {
      label: i18n.t("standards:Distance to college"),
      type: "int_positive",
      si: "m",
    },
    distance_bus_stop: {
      label: i18n.t("standards:Distance to bus stop"),
      type: "int_positive",
      si: "m",
    },
    distance_train_station: {
      label: i18n.t("standards:Distance to train station"),
      type: "int_positive",
      si: "m",
    },
    distance_post: {
      label: i18n.t("standards:Distance to post"),
      type: "int_positive",
      si: "m",
    },
    distance_bank: {
      label: i18n.t("standards:Distance to bank"),
      type: "int_positive",
      si: "m",
    },
    distance_cable_railway_station: {
      label: i18n.t("standards:Distance to railway station"),
      type: "int_positive",
      si: "m",
    },
    distance_boat_dock: {
      label: i18n.t("standards:Distance to boat dock"),
      type: "int_positive",
      si: "m",
    },
    distance_public_transport: {
      label: i18n.t("standards:Distance to public transport"),
      type: "int_positive",
      si: "m",
    },
    distance_shop: {
      label: i18n.t("standards:Distance to shop"),
      type: "int_positive",
      si: "m",
    },
    distance_motorway: {
      label: i18n.t("standards:Distance to freeway"),
      type: "int_positive",
      si: "m",
    },
    distance_airport: {
      label: i18n.t("standards:Distance to airport"),
      type: "int_positive",
      si: "m",
    },
    number_of_rooms: {
      label: i18n.t("standards:Rooms"),
      type: "float_half",
      si: "",
    },
    number_of_rooms_from: {
      label: i18n.t("standards:Number of rooms from"),
      type: "int_positive",
      si: "",
    },
    number_of_rooms_to: {
      label: i18n.t("standards:Number of rooms to"),
      type: "int_positive",
      si: "",
    },
    area_sia_gf: {
      label: i18n.t("standards:Total floor area"),
      type: "int_positive",
      si: "m2",
    },
    area_sia_nf: {
      label: i18n.t("standards:Floorspace"),
      type: "int_positive",
      si: "m2",
    },
    area_sia_nf_from: {
      label: i18n.t("standards:Floorspace from"),
      type: "int_positive",
      si: "m2",
    },
    area_sia_nf_to: {
      label: i18n.t("standards:Floorspace to"),
      type: "int_positive",
      si: "m2",
    },
    area_cellar: {
      label: i18n.t("Cellar space"),
      type: "int_positive",
      si: "m2",
    },
    area_balcony: {
      label: i18n.t("Balcony space"),
      type: "int_positive",
      si: "m2",
    },
    area_terrace: {
      label: i18n.t("Terrace space"),
      type: "int_positive",
      si: "m2",
    },
    area_loggia: {
      label: i18n.t("Loggia space"),
      type: "int_positive",
      si: "m2",
    },
    area_garden: {
      label: i18n.t("Garden space"),
      type: "int_positive",
      si: "m2",
    },
    volume_sia_gv: {
      label: i18n.t("standards:SIA-GV"),
      type: "int_positive",
      si: "m3",
    },
    area_sia_gsf: {
      label: i18n.t("standards:SIA-GSF"),
      type: "int_positive",
      si: "m2",
    },
    area_sia_ggf: {
      label: i18n.t("standards:SIA-GGF"),
      type: "int_positive",
      si: "m2",
    },
    volume_gva: {
      label: i18n.t("standards:Cubature GVA"),
      type: "int_positive",
      si: "m3",
    },
    volume_sia116_gv: {
      label: i18n.t("standards:Cubature SIA"),
      type: "int_positive",
      si: "m3",
    },
    utilization_number: {
      label: i18n.t("standards:Utilization number"),
      type: "float_positive",
      si: "",
    },
    area_bwf: {
      label: i18n.t("standards:Total living area"),
      type: "int_positive",
      si: "m2",
    },
    area_bwf_from: {
      label: i18n.t("standards:Living area from"),
      type: "int_positive",
      si: "m2",
    },
    area_bwf_to: {
      label: i18n.t("standards:Living area to"),
      type: "int_positive",
      si: "m2",
    },
    area_nwf: {
      label: i18n.t("standards:Net living area"),
      type: "int_positive",
      si: "m2",
    },
    year_built: {
      label: i18n.t("standards:Year built"),
      type: "int_positive",
      si: "",
    },
    yearBuilt: {
      label: i18n.t("standards:Year built"),
      type: "int_positive",
      si: "",
    },
    year_last_renovated: {
      label: i18n.t("standards:Year of last renovation"),
      type: "int_positive",
      si: "",
    },
    yearLastRenovated: {
      label: i18n.t("standards:Year of last renovation"),
      type: "int_positive",
      si: "",
    },
    year_renovated: {
      label: i18n.t("standards:Year of last renovation"),
      type: "int_positive",
      si: "",
    },
    ceiling_height: {
      label: i18n.t("standards:Ceiling height"),
      type: "int_positive",
      si: "m",
    },
    hall_height: {
      label: i18n.t("standards:Hall height"),
      type: "int_positive",
      si: "m",
    },
    property_land_price: {
      label: i18n.t("standards:Land price"),
      type: "int_positive",
      si: "currency",
    },
    gross_premium: {
      label: i18n.t("standards:Gross premium"),
      type: "float_positive",
      si: "%",
    },
    gross_premium_target: {
      label: i18n.t("standards:Gross premium (target)"),
      type: "float_positive",
      si: "%",
    },
    net_premium: {
      label: i18n.t("Net premium"),
      type: "float_positive",
      si: "%",
    },
    occupancy_percentage: {
      label: i18n.t("standards:Current occupancy"),
      type: "float_positive",
      si: "%",
    },
    rental_deposit: {
      label: i18n.t("standards:Rental deposit"),
      type: "int_positive",
      si: "currency",
    },
    constructed_factor: {
      label: i18n.t("Constructed factor"),
      type: "float_positive",
      si: "",
    },
    imputed_rent_value: {
      label: i18n.t("Imputed rent value"),
      type: "int_positive",
      si: "currency",
    },
    extra_costs_total: {
      label: i18n.t("Extra costs"),
      type: "int_positive",
      si: "currency",
    },
    building_insurance_value: {
      label: i18n.t("Building insurance value"),
      type: "int_positive",
      si: "currency",
    },
    official_tax_value: {
      label: i18n.t("Official tax value"),
      type: "int_positive",
      si: "currency",
    },
    renewal_fund_input: {
      label: i18n.t("Renewal fund input"),
      type: "int_positive",
      si: "currency",
    },
    renewal_fund_value: {
      label: i18n.t("Renewal fund value"),
      type: "int_positive",
      si: "currency",
    },
    geak_exterior: {
      label: i18n.t("GEAK exterior"),
      type: "",
      si: "geak",
    },
    geak_total: {
      label: i18n.t("GEAK total"),
      type: "",
      si: "geak",
    },
    buy_price_range_from: {
      label: i18n.t("Price from"),
      type: "int_positive",
      si: "currency",
    },
    buy_price_range_to: {
      label: i18n.t("Price to"),
      type: "int_positive",
      si: "currency",
    },
    rent_price_range_from: {
      label: i18n.t("Price from"),
      type: "int_positive",
      si: "currency",
    },
    rent_price_range_to: {
      label: i18n.t("Price to"),
      type: "int_positive",
      si: "currency",
    },
    price_range_from: {
      label: i18n.t("Price from"),
      type: "int_positive",
      si: "currency",
    },
    price_range_to: {
      label: i18n.t("Price to"),
      type: "int_positive",
      si: "currency",
    },
  };
}
function numvalFromTemplate(keyorig: string) {
  const key = keyorig
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, "");

  const numvals = getNumvals();
  if (numvals[key] !== undefined) {
    return numvals[key];
  }
  return {
    label: `Unknown:${key}`,
    type: "float_positive",
    si: "",
  };
}

function numvalNF(key: string, value: string) {
  // type
  const float = parseFloat(`${value}`);
  if (key.startsWith("year")) {
    return value;
  }
  if (float) {
    return new Intl.NumberFormat("de-CH", { style: "decimal" }).format(float);
  }
  return value;
}
export function numvalRender<T1 extends string>(
  valueOrResource: { [key in T1]: string | number | null } | number | null,
  key: T1,
  currency?: string
) {
  const value =
    valueOrResource && typeof valueOrResource === "object"
      ? typeof valueOrResource[key] === "number"
        ? valueOrResource[key]
        : valueOrResource[key] || null
      : valueOrResource;
  const template = numvalFromTemplate(key);
  if (value === null) {
    return "";
  }

  if (
    value === 0 &&
    ["int_positive", "float_positive"].includes(template.type)
  ) {
    return "";
  }

  switch (template.si) {
    case "m":
      return `${numvalNF(key, `${value}`)} m`;
    case "m2":
      return `${numvalNF(key, `${value}`)} m²`;
    case "m3":
      return `${numvalNF(key, `${value}`)} m³`;
    case "kg":
      return `${numvalNF(key, `${value}`)} kg`;
    case "%":
      return `${numvalNF(key, `${value}`)} %`;
    case "geak":
      switch (value) {
        case 1:
          return "A";
        case 2:
          return "B";
        case 3:
          return "C";
        case 4:
          return "D";
        case 5:
          return "E";
        case 6:
          return "F";
        case 7:
          return "G";
        default:
          return null;
      }
    case "currency":
      return renderPrice({
        price: value,
        time_segment: null,
        property_segment: null,
        currency: currency,
      });
    case "floor":
      switch (value.toString()) {
        case "-1":
          return "-1.";
        case "0":
          return i18n.t("GF");
        case "1":
          return "1.";
        case null:
          return null;
        default:
          return `${value.toString()}.`;
      }
    default:
      return `${numvalNF(key, `${value}`)}`;
  }
}
export function numvalLabel(key: string) {
  return numvalFromTemplate(key).label;
}
