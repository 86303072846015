import { ReactNode } from "react";
import Select, { type CleanSelectOption } from "../../../formElements/Select";
import Switch from "../../../formElements/Switch";
import type {
  PropertyDossierAttachment,
  PropertyDossierFormat,
} from "../helpers/propertyAttachment.model";
import { useTranslation } from "react-i18next";
import { getSystemLanguageList } from "utilities/languages";

export type PropertyAttachmentDossierOnChange = (
  updatedAttachment: PropertyDossierAttachment
) => void;

interface PropertyAttachmentDossierSidebarProps {
  selectedAttachment?: PropertyDossierAttachment;
  onChange: PropertyAttachmentDossierOnChange;
  children?: ReactNode;
}

export const PropertyAttachmentDossierSidebar = ({
  selectedAttachment,
  onChange,
  children,
}: PropertyAttachmentDossierSidebarProps) => {
  const { t } = useTranslation();

  const handleUpdate = (update: Partial<PropertyDossierAttachment>): void => {
    if (selectedAttachment && update) {
      onChange({ ...selectedAttachment, ...update });
    }
  };

  const languageOptions: CleanSelectOption[] = getSystemLanguageList(false).map(
    ({ label, value }) => ({ label, value })
  );

  return (
    <>
      <Select
        nobox
        label={t("Language")}
        ariaLabel={t("Language")}
        placeholder={t("Choose language")}
        value={selectedAttachment?.language}
        isClearable={false}
        onChange={(value: string) => {
          handleUpdate({ language: value as PropertyDossierFormat });
        }}
        options={languageOptions}
      />
      <Select
        nobox
        label={t("Format")}
        ariaLabel={t("Format")}
        placeholder={t("Choose a Format")}
        value={selectedAttachment?.format}
        isClearable={false}
        onChange={(value: string) => {
          handleUpdate({ format: value as PropertyDossierFormat });
        }}
        options={[
          {
            value: "portrait",
            label: `${t("A4 portrait")}`,
          },
          {
            value: "landscape",
            label: `${t("A4 landscape")}`,
          },
        ]}
      />
      <Switch
        label={t("Show price")}
        ariaLabel={t("Show price")}
        checked={selectedAttachment?.addPrice}
        onToggle={(value: boolean) => {
          handleUpdate({ addPrice: value });
        }}
      />
      {children}
      <Switch
        label={t("Show address")}
        ariaLabel={t("Show address")}
        checked={selectedAttachment?.addAddress}
        onToggle={(value: boolean) => {
          handleUpdate({ addAddress: value });
        }}
      />
      <Switch
        label={t("Show header/footer")}
        ariaLabel={t("Show header/footer")}
        checked={selectedAttachment?.showHeaderFooter}
        onToggle={(value: boolean) => {
          handleUpdate({ showHeaderFooter: value });
        }}
      />
    </>
  );
};
