import { createResource } from "redux-rest-resource";
import Config from "config";
import queryBuilder from "../../utilities/queryBuilder";
import ContactState from "entities/contact/types";
import prepareFetchQueryOptions from "utilities/prepareFetchQueryOptions";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: ContactState, actions?: any) => ContactState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "contactWithAccesskey",
  pluralName: "contactsWithAccesskey",
  url: `${Config.apiUrl}/${Config.customerKey}/contact-with-accesskey/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: true,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any) => {
        return state;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.contact) {
          return { res, body: res.body._embedded.contact };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        const opts = prepareFetchQueryOptions("contact-list", contextOpts);

        if (!opts.filter.find((x) => x.field === "referenceContact")) {
          opts.filter.push({
            where: "and",
            field: "referenceContact",
            type: "isnull",
          });
        }

        opts.filter.push({
          where: "and",
          field: "contactType",
          type: "isnull",
        });

        opts.filter.push({
          where: "and",
          type: "distinct",
        });

        opts.filter.push({
          type: "orx",
          conditions: [
            {
              type: "neq",
              field: "status",
              value: "ignore",
            },
            {
              type: "isnull",
              field: "status",
            },
          ],
          where: "and",
        });
        const query = queryBuilder.build(opts.orderBy, opts.filter, {
          pagesize: opts.pageSize,
          page: contextOpts.page,
          context: opts.apiContext,
        });
        return query;
      },
    },
  },
});

export const RESOURCE_USES_PUBLIC_AXIOS__CWAK = (url: URL | RequestInfo) =>
  url
    .toString()
    .startsWith(
      `${Config.apiUrl}/${Config.customerKey}/contact-with-accesskey`
    );
