import {
  GetPropertyListReducedQueryParams,
  GetPropertyListReducedResponse,
} from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetPropertyListReducedResponse,
  GetPropertyListReducedQueryParams
>((result) => {
  if (result) {
    // generate tags for each property
    const propertyTags = result.items.map((propertyItem) => {
      // add a tag for each property
      return {
        type: "Property" as const,
        id: propertyItem.id,
      };
    });

    // merge all tags together and add a partial list tag
    return [
      ...propertyTags,
      {
        type: "Property",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["Property"];
});

export default providesTags;
