import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

type BaseListItem = { value: string; label: string };
type CreatorOptions<TListType> = {
  sort?: "a-z" | ((a: TListType, b: TListType) => number);
  translator?: (
    currItem: TListType,
    originalTranslator: (arg: string) => string
  ) => string | TListType;
};

type ListConstructor<TListType, TListConstructorArgs> =
  | TListType[]
  | ((listConstructorArgs?: TListConstructorArgs) => TListType[]);

export type CustomUtilityTranslator<TListType extends BaseListItem> =
  CreatorOptions<TListType>["translator"];

/** runs all labels through a provided translator method */
function translateList<TListType extends BaseListItem>(
  untranslatedList: TListType[],
  translator: (
    arg: TListType,
    originalTranslator: (arg: string) => string
  ) => string | TListType,
  originalTranslator: (arg: string) => string
) {
  return untranslatedList.map((item) => {
    const translatedItemOrLabel = translator(item, originalTranslator);
    const translatedItem =
      typeof translatedItemOrLabel === "string"
        ? { ...item, label: translatedItemOrLabel }
        : { ...translatedItemOrLabel };
    return translatedItem;
  });
}

/** creates utility to return list */
export function createGetList<
  TListType extends BaseListItem,
  TListConstructorArgs
>(
  listConstructor: ListConstructor<TListType, TListConstructorArgs>,
  creatorOptions: CreatorOptions<TListType> = {}
) {
  // deconstruct creatorOptions
  const {
    sort = false,
    translator = (item, originalTranslator) => originalTranslator(item.label),
  } = creatorOptions;

  // final list helper method
  return (listConstructorArgs?: TListConstructorArgs) => {
    // construct list
    const constructedList = Array.isArray(listConstructor)
      ? listConstructor
      : listConstructor(listConstructorArgs);

    // run through translator
    const translatedList = translateList(constructedList, translator, (arg) =>
      i18n.t(arg)
    );

    // sorting
    if (sort) {
      return translatedList.sort(
        sort === "a-z"
          ? (a, b) => {
              return a.label.toString().localeCompare(b.label);
            }
          : sort
      );
    }
    return translatedList;
  };
}

/** creates utility to return label based on key/value */
export function createGetLabel<
  TListType extends BaseListItem,
  TListConstructorArgs
>(
  listConstructor: ListConstructor<TListType, TListConstructorArgs>,
  creatorOptions: Pick<CreatorOptions<TListType>, "translator"> = {}
) {
  // deconstruct creatorOptions
  const {
    translator = (item, originalTranslator) => originalTranslator(item.label),
  } = creatorOptions;

  // final label helper method
  return (value: string, listConstructorArgs?: TListConstructorArgs) => {
    // construct list
    const constructedList = Array.isArray(listConstructor)
      ? listConstructor
      : listConstructor(listConstructorArgs);

    // run through translator
    const translatedList = translateList(constructedList, translator, (arg) =>
      i18n.t(arg)
    );

    const foundLabel = translatedList.find(
      (type) => type.value === value
    )?.label;

    return foundLabel || value;
  };
}

/** creates utility to return item obj based on key/value */
export function createGetItem<
  TListType extends BaseListItem,
  TListConstructorArgs
>(
  listConstructor: ListConstructor<TListType, TListConstructorArgs>,
  creatorOptions: Pick<CreatorOptions<TListType>, "translator"> = {}
) {
  // deconstruct creatorOptions
  const {
    translator = (item, originalTranslator) => originalTranslator(item.label),
  } = creatorOptions;

  // final item helper method
  return (value: string, listConstructorArgs?: TListConstructorArgs) => {
    // construct list
    const constructedList = Array.isArray(listConstructor)
      ? listConstructor
      : listConstructor(listConstructorArgs);

    // run through translator
    const translatedList = translateList(constructedList, translator, (arg) =>
      i18n.t(arg)
    );
    const foundItem = translatedList.find((type) => type.value === value);

    return foundItem || undefined;
  };
}

/** a hook that does the same as the static utility methods. The upside is, that this hooks subscribes to i18n, and therefore automatically rerenders when the language would change */
export function createUtilityHook<
  TListType extends BaseListItem,
  TListConstructorArgs
>(
  listConstructor: ListConstructor<TListType, TListConstructorArgs>,
  creatorOptions: CreatorOptions<TListType> = {}
) {
  // deconstruct creatorOptions
  const {
    sort = false,
    translator = (item, originalTranslator) => originalTranslator(item.label),
  } = creatorOptions;

  // final helper hook
  return (listConstructorArgs?: TListConstructorArgs) => {
    const { t } = useTranslation();
    // currently only "inital" arguments are supported -> because we put it in a state, so useEffect down below can safely depend
    const [initalConstArgs] = useState(listConstructorArgs);

    const translatedList = useMemo(() => {
      // construct list
      const constructedList = Array.isArray(listConstructor)
        ? listConstructor
        : listConstructor(initalConstArgs);
      // run through translator
      const translatedList = translateList(constructedList, translator, (arg) =>
        t(arg)
      );

      // sorting
      if (sort) {
        return translatedList.sort(
          sort === "a-z"
            ? (a, b) => {
                return a.label.toString().localeCompare(b.label);
              }
            : sort
        );
      }
      return translatedList;
    }, [t, initalConstArgs]);

    const getLabel = useCallback(
      (value: string) => {
        const foundLabel = translatedList.find(
          (item) => item.value === value
        )?.label;
        return foundLabel || value;
      },
      [translatedList]
    );
    const getItem = useCallback(
      (value: string) => {
        const foundItem = translatedList.find((item) => item.value === value);
        return foundItem || undefined;
      },
      [translatedList]
    );

    const typedReturn: [
      typeof translatedList,
      typeof getLabel,
      typeof getItem
    ] = [translatedList, getLabel, getItem];

    return typedReturn;
  };
}
