import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Dissolved partnership",
    value: "0",
  },
  {
    label: "Divorced",
    value: "1",
  },
  {
    label: "In a registered partnership",
    value: "2",
  },
  {
    label: "Single",
    value: "3",
  },
  {
    label: "Unmarried",
    value: "4",
  },
  {
    label: "Married",
    value: "5",
  },
  {
    label: "Widowed",
    value: "6",
  },
];

export const getCivilStatus = createGetList(untranslatedList);
export const getCivilStatusLabel = createGetLabel(untranslatedList);

export const useCivilStatuses = createUtilityHook(untranslatedList);
