import Field from "@casasoft/styleguide/components/formElements/Field";
import type { PropertyAttachment } from "../helpers/propertyAttachment.model";
import { useTranslation } from "react-i18next";

interface PropertyAttachmentDefaultSidebarProps {
  selectedAttachment?: PropertyAttachment;
}

export const PropertyAttachmentDefaultSidebar = ({
  selectedAttachment,
}: PropertyAttachmentDefaultSidebarProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Field
        nobox
        id="name"
        label={t("Name")}
        value={selectedAttachment?.title}
        placeholder={selectedAttachment?.title}
        disabled={true}
      />
    </>
  );
};
