import axios from "axios";
import authTokensRequestInterceptor from "./authTokensInterceptor";
import {
  interceptErrorToSentry,
  sentryWarningsResponseInterceptor,
  startTimeRequestInterceptor,
} from "./issueTrackerInterceptors";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(authTokensRequestInterceptor);
// track request time + size
axiosInstance.interceptors.request.use(startTimeRequestInterceptor);
axiosInstance.interceptors.response.use(
  sentryWarningsResponseInterceptor,
  interceptErrorToSentry
);

export default axiosInstance;
