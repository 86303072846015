import { useAppSelector } from "redux/hooks";
import { selectProfileUsername } from "redux/auth/selectors";
import { useCallback } from "react";
import { useGetUserQuery, useUpdateUserMutation } from "api/entities/user";

const useCompleteTour = () => {
  const username = useAppSelector(selectProfileUsername);
  const { currentData: userData } = useGetUserQuery(username || "");
  const [updateUser] = useUpdateUserMutation();
  const completeTour = useCallback(
    async (id: string) => {
      if (username) {
        const previousTours =
          userData?.completedTours && Array.isArray(userData.completedTours)
            ? userData.completedTours
            : [];

        await updateUser({
          username,
          completedTours: [...previousTours, id].filter(function (
            item,
            pos,
            self
          ) {
            return self.indexOf(item) === pos;
          }),
        });
      }
    },
    [updateUser, userData, username]
  );
  return { completeTour };
};

export default useCompleteTour;
