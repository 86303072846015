import { CasaoneBaseQueryArgs } from "api/types";
import queryBuilder from "utilities/queryBuilder";
import { GetContactListReducedQueryParams } from "./types";

function query({
  query,
}: GetContactListReducedQueryParams): CasaoneBaseQueryArgs {
  const builtQuery = queryBuilder.buildToUrl(
    query.orderBy,
    [
      ...query.filter,
      {
        type: "leftjoin",
        field: "address",
        alias: "contactaddress",
      },
      {
        type: "leftjoin",
        field: "avatar",
        alias: "contactavatar",
      },
      {
        type: "neq",
        field: "status",
        value: "ignore",
      },
    ],
    {
      pagesize: query.pageSize,
      page: query.page,
    },
    [
      "id",
      "visualReferenceId",
      "status",
      "contactType",
      "entityType",
      "firstName",
      "lastName",
      "displayName",
      "addition",
      "email",
      "email2",
      "phone",
      "phoneDirect",
      "mobile",
      "websiteUrl",
      "created",
      "contactaddress.street",
      "contactaddress.streetNumber",
      "contactaddress.postalCode",
      "contactaddress.locality",
      "contactaddress.region",
      "contactaddress.country",
      "contactaddress.hideExactAddress",
      "contactaddress.postOfficeBoxNumber",
      "contactavatar.path",
      "contactavatar.originalFilename",
      "contactavatar.alt",
      "contactavatar.size",
      "contactavatar.width",
      "contactavatar.height",
      "contactavatar.crop1x1",
      "contactavatar.mimeType",
      "contactavatar.viewGroup",
    ]
  );
  return {
    url: `contact?${builtQuery}`,
    method: "GET",
  };
}

export default query;
