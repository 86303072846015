function prepareFetchQueryOptions(apiContext: string, contextOpts: any) {
  const cnxtOpts = Object.assign(
    {
      pageSize: 20,
      filter: [],
      orderBy: [],
      apiContext: apiContext,
    },
    contextOpts
  );
  const opts = Object.assign(
    {
      pageSize: 50,
      orderBy: [
        {
          type: "field",
          field: "created",
          direction: "desc",
        },
      ],
      filter: [] as any[],
    },
    {
      pageSize: cnxtOpts.pageSize,
      orderBy: [...cnxtOpts.orderBy],
      filter: [...cnxtOpts.filter],
      apiContext: cnxtOpts.apiContext,
    }
  );

  return opts;
}

export default prepareFetchQueryOptions;
