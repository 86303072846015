import {
  MutatePropertySingleResponse,
  MutatePropertySingleQueryParams,
} from "./types";
import { createInvalidatesTags } from "api/createEndpoint";

const invalidatesTags = createInvalidatesTags<
  MutatePropertySingleResponse,
  MutatePropertySingleQueryParams
>((result, err, queryParams) => {
  return [
    { type: "Property", id: queryParams.id },
    {
      type: "Property",
      id: "PARTIAL-LIST",
    },
    {
      type: "PropertyTag",
      id: "PARTIAL-LIST",
    },
  ];
});

export default invalidatesTags;
