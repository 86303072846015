import useForm, { RegisterFields } from "hooks/useForm";
import { SelectRecipientsContainer } from "../recipients-select/SelectRecipientsContainer";
import { SelectPropertyContainer } from "../property-select/SelectPropertyContainer";
import { PropertySelectValueType } from "../property-select/helpers/usePropertySelect";
import { MessageSignatureContainer } from "./MessageSignature/MessageSignatureContainer";
import { UserSignatureSelectValueType } from "./MessageSignature/helpers/useMessageSignatures";
import { MessageBodyContainer } from "./MessageBody/MessageBodyContainer";
import { useDecoratedHandlers } from "./helpers/useDecoratedHandlers";
import { SelectRelationPhaseContainer } from "../relation-select/SelectRelationPhaseContainer";
import { useRelationPhaseWatch } from "./helpers/useRelationPhaseWatch";
import { MessageMiscControls } from "./MessageMiscControls/MessageMiscControls";
import { EventShape } from "entities/event/types";
import { type AddRemainderFormShape } from "../add-reminder/AddReminderForm/AddReminderForm";
import { SelectActionContainer } from "../action-select/SelectActionContainer";
import { MessageSubject } from "./MessageSubject/MessageSubject";
import { type MessageAttachment } from "./MessageMiscControls/MessageAttachFilesControl";
import { MessageTemplateContainer } from "./MessageTemplate/MessageTemplateContainer";
import { MessageTemplateSelectValueType } from "./MessageTemplate/helpers/useMessageTemplates";
import { PropertyAttachment } from "@casasoft/styleguide/components/legacy/MediaChooserPropertyAttachments/helpers/propertyAttachment.model";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import Button from "@casasoft/styleguide/components/forms/Button";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useEffect } from "react";
import { type RecipientSelectValueType } from "../recipients-select/RecipientSelect/helpers/useRecipientSelect";
import { PropertyContactRelation } from "utilities/keyValueHelpers/propertyContactRelationTypes";
import { useMessageBodyPreview } from "./helpers/useMessageBodyPreview/useMessageBodyPreview";
import { useFormFieldRules } from "./helpers/useFormFieldRules";
import { MessageHistoryContainer } from "./MessageHistory/MessageHistoryContainer";

export interface MessageCreateFormShape {
  contact: RecipientSelectValueType[] | undefined;
  contactCC: RecipientSelectValueType[] | undefined;
  contactBCC: RecipientSelectValueType[] | undefined;
  subject: string | undefined;
  lang: string | undefined;
  property: PropertySelectValueType | undefined;
  eventBaseType: string | undefined;
  eventType: string | undefined;
  body: string | undefined;
  messageHistory: string | undefined;
  addMessageHistory: boolean;
  sendContactLink: boolean;
  signature: UserSignatureSelectValueType;
  relation: PropertyContactRelation | undefined;
  phase: string | undefined;
  addReminderForm: AddRemainderFormShape | undefined;
  attachments: MessageAttachment[];
  propertyAttachments: PropertyAttachment[];
  template: MessageTemplateSelectValueType;
}

export interface MessageCreateFormProps {
  defaultData: Partial<MessageCreateFormShape>;
  initialData?: Partial<MessageCreateFormShape>;
  isRelationFieldDisabled?: boolean;
  thread?: EventShape;
  onSubmit: (data: MessageCreateFormShape) => void;
  isVisible: boolean;
  onRecipientsChange?: (recipients: string[]) => void;
  updatedRecipientList?: RecipientSelectValueType[];
}

export const MessageCreateForm = ({
  defaultData,
  initialData,
  isRelationFieldDisabled = false,
  onSubmit,
  isVisible,
  updatedRecipientList,
}: MessageCreateFormProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isDirty },
    watch,
  } = useForm<MessageCreateFormShape>({
    defaultValues: {
      contact: defaultData.contact || undefined,
      contactCC: defaultData.contactCC || undefined,
      contactBCC: defaultData.contactBCC || undefined,
      subject: defaultData.subject,
      lang: defaultData.lang,
      property: defaultData.property || undefined,
      eventBaseType: defaultData.eventBaseType,
      eventType: defaultData.eventType,
      body: defaultData.body,
      messageHistory: defaultData.messageHistory,
      addMessageHistory: defaultData.addMessageHistory || true,
      sendContactLink: defaultData.sendContactLink || false,
      signature: defaultData.signature,
      relation: defaultData.relation,
      phase: defaultData.phase,
      addReminderForm: defaultData.addReminderForm,
      attachments: defaultData.attachments || [],
      propertyAttachments: defaultData.propertyAttachments || [],
      template: defaultData.template,
    },
  });

  const {
    body: bodyHandlers,
    signature: signatureHandlers,
    template: templateHandlers,
    action: actionHandlers,
    addReminder: addReminderHandlers,
    property: propertyHandlers,
  } = useDecoratedHandlers({ getValues, setValue });

  const fieldRules = useFormFieldRules();

  const relationPhaseWatch = useRelationPhaseWatch({
    watch,
  });
  const messageBodyPreview = useMessageBodyPreview({ getValues });

  useEffect(() => {
    if (!!updatedRecipientList) {
      setValue("contact", updatedRecipientList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRecipientList]);

  return (
    <div className={cx({ "tw-hidden": !isVisible })}>
      <RegisterFields
        control={control}
        fields={fieldRules}
        render={(fieldsRenderer, chainFields) => (
          <form onSubmit={handleSubmit(onSubmit)}>
            {chainFields(
              ["contact", "contactCC", "contactBCC"],
              (formValues, changeHandlers) => (
                <SelectRecipientsContainer
                  changeHandlers={changeHandlers}
                  values={formValues}
                  errors={errors}
                />
              )
            )}
            {fieldsRenderer("property", (formValue, changeHandler) => (
              <SelectPropertyContainer
                value={formValue}
                initialValue={initialData?.property}
                onChange={(newValue) =>
                  propertyHandlers.onChange(newValue, changeHandler)
                }
              />
            ))}
            {chainFields(
              ["eventBaseType", "eventType"],
              (formValues, formValueChangeHandlers) => (
                <SelectActionContainer
                  values={formValues}
                  changeHandlers={formValueChangeHandlers}
                  onChangeSideEffect={actionHandlers.onChange}
                  errors={errors}
                />
              )
            )}
            {chainFields(
              ["subject", "template"],
              (formValues, formValueChangeHandlers) => (
                <div className="md:tw-flex tw-gap-4">
                  <MessageSubject
                    value={formValues.subject}
                    onChange={formValueChangeHandlers.subject}
                    errors={errors}
                    className="tw-basis-1/2"
                  />
                  <MessageTemplateContainer
                    value={formValues.template}
                    onChange={(template: MessageTemplateSelectValueType) => {
                      templateHandlers.onChange(
                        template,
                        formValueChangeHandlers.template
                      );
                    }}
                    className="tw-basis-1/2"
                  />
                </div>
              )
            )}
            {fieldsRenderer("body", (formValue, changeHandler) => (
              <MessageBodyContainer
                value={formValue}
                onChange={changeHandler}
                onBlur={bodyHandlers.onBlur}
                error={errors.body?.message}
                messageBodyPreview={messageBodyPreview}
              />
            ))}
            {chainFields(
              ["messageHistory", "addMessageHistory"],
              (formValues, changeHandlers) => (
                <MessageHistoryContainer
                  values={formValues}
                  changeHandlers={changeHandlers}
                />
              )
            )}
            {chainFields(
              [
                "sendContactLink",
                "addReminderForm",
                "attachments",
                "propertyAttachments",
              ],
              (formValues, changeHandlers) => (
                <MessageMiscControls
                  values={formValues}
                  changeHandlers={changeHandlers}
                  onAddReminderToggle={addReminderHandlers.onToggle}
                  selectedProperty={getValues("property")?.property}
                />
              )
            )}
            {fieldsRenderer("signature", (formValue, changeHandler) => (
              <MessageSignatureContainer
                value={formValue}
                onChange={(signature: UserSignatureSelectValueType) => {
                  signatureHandlers.onChange(signature, changeHandler);
                }}
                onLoad={signatureHandlers.onLoad}
              />
            ))}
            {!isRelationFieldDisabled &&
              chainFields(
                ["relation", "phase"],
                (formValues, changeHandlers) => (
                  <SelectRelationPhaseContainer
                    changeHandlers={changeHandlers}
                    values={formValues}
                    propertyMarketingMethod={
                      getValues("property")?.property?.marketingMethod ||
                      undefined
                    }
                    selectedFormValues={relationPhaseWatch}
                  />
                )
              )}

            <ModalFooter>
              <Button
                isPrimary
                type="submit"
                buttonValue={t("Send")}
                disabled={!isDirty}
              />
            </ModalFooter>
          </form>
        )}
      ></RegisterFields>
    </div>
  );
};
