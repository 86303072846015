import { useRules } from "hooks/useRules";

export const useFormFieldRules = () => {
  const { generateRules } = useRules();

  return {
    date: {},
    hour: {
      rules: generateRules(["timeFormat"]),
    },
    subject: {},
    notes: {},
    user: {},
  };
};
