import { GetPropertyListQueryParams, GetPropertyListResponse } from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetPropertyListResponse,
  GetPropertyListQueryParams
>((result) => {
  if (result) {
    // prepare an array of tags for each property content
    const propertyContentTags: { type: "PropertyContent"; id?: number }[] = [];
    // generate tags for each property
    const propertyTags = result._embedded.property.map((propertyItem) => {
      // add tags for each property content
      if (propertyItem._embedded.contents) {
        propertyContentTags.push(
          ...propertyItem._embedded.contents.map((pContent) => ({
            type: "PropertyContent" as const,
            id: pContent.id,
          }))
        );
      }

      // add a tag for each property
      return {
        type: "Property" as const,
        id: propertyItem.id,
      };
    });

    // merge all tags together and add a partial list tag
    return [
      ...propertyContentTags,
      ...propertyTags,
      {
        type: "Property",
        id: "PARTIAL-LIST",
      },
    ];
  }
  return ["Property"];
});

export default providesTags;
