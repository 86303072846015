import { sendEvent } from "../sendEvent";
import { CustomEventGroup } from "../types";

type MessagePreviewCommonEventParams = {
  bodyLength?: number;
  propertyId?: string;
  lang?: string;
};

export type MessagePreviewEventParams = {
  message_preview: MessagePreviewCommonEventParams;
};

const defaults: CustomEventGroup<MessagePreviewEventParams> = {
  message_preview: {
    eventLabel: "Message Preview Displayed",
    eventParams: {
      bodyLength: 0,
      propertyId: "none",
      lang: "none",
    },
  },
};

export const sendMessagePreviewEvent = (
  eventName: keyof MessagePreviewEventParams,
  eventParams: MessagePreviewEventParams[typeof eventName]
) => {
  sendEvent({
    ...defaults[eventName],
    ...{
      eventName,
      eventCategory: "messaging",
      eventParams: { ...defaults[eventName].eventParams, ...eventParams },
    },
  });
};
