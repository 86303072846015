import { Editor } from "tinymce";
import { Numvals } from "utilities";

export const addMessageTemplateTags = ({
  editor,
  translator,
}: {
  editor: Editor;
  translator: (term: string) => string;
}) => {
  editor.ui.registry.addMenuButton("mustacheTags", {
    type: "menubutton",
    text: translator("Template tags"),
    icon: "template",
    fetch: (callback) => {
      callback([
        {
          type: "nestedmenuitem",
          text: translator("Property"),
          getSubmenuItems: () => {
            return [
              {
                type: "menuitem",
                text: translator("Name"),
                onAction: () =>
                  editor.insertContent("<kbd>{{property.name}}</kbd>&nbsp;"),
              },
              {
                type: "menuitem",
                text: translator("addressTypeAhead.postalCode"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{property.postalCode}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("Locality"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{property.locality}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: Numvals.numvalLabel("numberOfRooms"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{property.numberOfRooms}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("addressTypeAhead.street"),
                onAction: () =>
                  editor.insertContent("<kbd>{{property.street}}</kbd>&nbsp;"),
              },
              {
                type: "menuitem",
                text: translator("addressTypeAhead.streetNumber"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{property.streetNumber}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("standards:Property land area"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{property.areaSiaGsf}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("standards:Links"),
                onAction: () =>
                  editor.insertContent("<kbd>{{property.links}}</kbd>&nbsp;"),
              },
            ];
          },
        },
        {
          type: "nestedmenuitem",
          text: translator("Recipient"),
          getSubmenuItems: () => {
            return [
              {
                type: "menuitem",
                text: translator("Salutation"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{recipient.salutation}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("First name"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{recipient.firstName}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("Last name"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{recipient.lastName}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("Full name"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{recipient.fullName}}</kbd>&nbsp;"
                  ),
              },
            ];
          },
        },
        {
          type: "nestedmenuitem",
          text: translator("Sender"),
          getSubmenuItems: () => {
            return [
              {
                type: "menuitem",
                text: translator("Salutation"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{sender.salutation}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("First name"),
                onAction: () =>
                  editor.insertContent("<kbd>{{sender.firstName}}</kbd>&nbsp;"),
              },
              {
                type: "menuitem",
                text: translator("Last name"),
                onAction: () =>
                  editor.insertContent("<kbd>{{sender.lastName}}</kbd>&nbsp;"),
              },
              {
                type: "menuitem",
                text: translator("Full name"),
                onAction: () =>
                  editor.insertContent("<kbd>{{sender.fullName}}</kbd>&nbsp;"),
              },
              {
                type: "menuitem",
                text: translator("Phone"),
                onAction: () =>
                  editor.insertContent("<kbd>{{sender.phone}}</kbd>&nbsp;"),
              },
              {
                type: "menuitem",
                text: translator("Phone direct"),
                onAction: () =>
                  editor.insertContent(
                    "<kbd>{{sender.phoneDirect}}</kbd>&nbsp;"
                  ),
              },
              {
                type: "menuitem",
                text: translator("Mobile"),
                onAction: () =>
                  editor.insertContent("<kbd>{{sender.mobile}}</kbd>&nbsp;"),
              },
              {
                type: "menuitem",
                text: translator("E-Mail"),
                onAction: () =>
                  editor.insertContent("<kbd>{{sender.email}}</kbd>&nbsp;"),
              },
            ];
          },
        },
        {
          type: "nestedmenuitem",
          text: translator("Attachments"),
          getSubmenuItems: () => {
            return [
              {
                type: "menuitem",
                text: translator("Attachment links"),
                onAction: () =>
                  editor.insertContent("<kbd>{{attachmentLinks}}</kbd>&nbsp;"),
              },
            ];
          },
        },
      ]);
    },
  });
};
