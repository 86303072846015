import { ReactNode, useEffect, useState } from "react";
import EnvSettings from "./EnvSettings";

interface EnvSettingsContainerProps {
  children: ReactNode;
}

const EnvSettingsContainer =
  process.env.NODE_ENV === "development"
    ? ({ children }: EnvSettingsContainerProps) => {
        const [timeoutcomplete, settimeoutcomplete] = useState(false);
        const [showEnvSetting, setShowEnvSettings] = useState(false);
        useEffect(() => {
          const cachedTimeout = setTimeout(() => {
            settimeoutcomplete(true);
            document.removeEventListener("keydown", envSettingsKeyHandler);
          }, 800);

          const envSettingsKeyHandler = (e: KeyboardEvent) => {
            if (e.key === " ") {
              clearTimeout(cachedTimeout);
              setShowEnvSettings(true);
            }
          };

          document.addEventListener("keydown", envSettingsKeyHandler);
          return () => {
            document.removeEventListener("keydown", envSettingsKeyHandler);
          };
        }, []);
        if (timeoutcomplete) {
          return <>{children}</>;
        }
        if (showEnvSetting) {
          return (
            <div style={{ top: "50%", margin: "auto" }}>
              <EnvSettings />
            </div>
          );
        }
        return (
          <div style={{ top: "50%", margin: "auto" }}>
            Press space to choose a different environment
          </div>
        );
      }
    : ({ children }: EnvSettingsContainerProps) => <>{children}</>;

export default EnvSettingsContainer;
