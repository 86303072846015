import * as React from "react";
import {
  components as ReactSelectComponent,
  type SingleValueProps,
} from "react-select";

interface Props {
  renderValue?: (option: any) => string | React.ReactChild;
  props: SingleValueProps<any>;
}

const SelectCustomSingleValue: React.FC<Props> = ({
  renderValue = (optionProps: any) => optionProps.label,
  props,
}: Props) => {
  let renderData;
  if (props?.data?.options?.length > 0) {
    renderData = props.data.options.find((item: any) => {
      if (props.data.label === item.label) {
        return item;
      } else {
        return null;
      }
    });
  } else {
    renderData = props.data;
  }
  return (
    <ReactSelectComponent.SingleValue {...props}>
      {renderValue(renderData)}
    </ReactSelectComponent.SingleValue>
  );
};

export default SelectCustomSingleValue;
