import { useGetUserSignatureListQuery } from "api/entities/userSignature";
import { UserSignatureShape } from "api/entities/userSignature/endpoints/list/types";
import { useEffect, useState } from "react";
import { selectProfileUsername } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";

type BaseValueType = UserSignatureShape | undefined;
export type UserSignatureSelectValueType = BaseValueType;

interface UseMessageSignaturesProps {
  signature: BaseValueType;
  onChange: (newValue: UserSignatureSelectValueType) => void;
  onLoad?: (initialValue: BaseValueType) => void;
}

export const useMessageSignatures = ({
  onChange,
  onLoad,
  signature,
}: UseMessageSignaturesProps) => {
  const username = useAppSelector(selectProfileUsername);
  const [userSignatures, setUserSignatures] = useState<
    UserSignatureShape[] | undefined
  >();
  const [selectedSignatureId, setSelectedSignatureId] = useState(
    signature?.id.toString()
  );

  const fetchResult = useGetUserSignatureListQuery({
    query: {
      page: 1,
      pageSize: -1,
      orderBy: [],
      filter: [
        {
          where: "and",
          field: "user",
          type: "eq",
          value: username,
        },
      ],
    },
  });

  if (fetchResult.isSuccess && !userSignatures) {
    setUserSignatures(fetchResult.data._embedded.user_signature);
  }

  const onSignatureChange = (newValue: string) => {
    if (newValue !== selectedSignatureId) {
      const foundSignature = userSignatures?.find(
        (signature) => signature.id.toString() === newValue
      );

      setSelectedSignatureId(newValue);
      onChange(foundSignature);
    }
  };

  useEffect(() => {
    if (userSignatures && userSignatures.length >= 1) {
      const firstSignature = userSignatures[0];

      setSelectedSignatureId(
        (current) => current ?? firstSignature.id.toString()
      );

      if (onLoad) {
        onLoad(firstSignature);
      }
    }
  }, [userSignatures, onLoad]);

  return {
    selectedSignatureId,
    userSignatures: userSignatures || [],
    onSignatureChange,
    isDisabled: !fetchResult.isSuccess,
  };
};
