import PropTypes from "prop-types";
import AddressTypeAhead from "@casasoft/styleguide/components/forms/AddressTypeAhead";
import AddressPicker from "@casasoft/styleguide/components/forms/AddressPickerMapBox";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import asyncLoading from "react-async-loader";
import { withTranslation } from "react-i18next";
import { actions as addressActions } from "entities/address/store";
import Config from "config";
import FormBase from "../FormBase";
import { Countries } from "utilities";

class AddressCreateForm extends FormBase {
  constructor(props, context) {
    super(props, context);

    this.state = {
      working: false,
      messages: null,
      dirtyFields: [],
      data: this.defaultData,
      resource: null,
    };
  }

  defaultData = {
    lat: null,
    lng: null,
    street: "",
    streetNumber: "",
    region: "",
    postOfficeBoxNumber: "",
    locality: "",
    postalCode: "",
    country: "",
  };

  __saveAddress(data) {
    const promise = new Promise((resolve, reject) => {
      this.props.actions
        ?.createAddress?.(data, {
          query: {
            pleaseSendResponse: true,
          },
          assignResponse: true,
        })
        .then((response) => {
          if (response.code === 201) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(() => {
          this.props.onFail?.();
          reject();
        });
    });
    return promise;
  }

  __persistData(data) {
    this.setState({ working: true });
    this.__saveAddress(data)
      .then((response) => {
        this.props.onReady?.(true);
        this.props.onDone?.(response.body);
      })
      .catch(() => {
        this.props.onFail?.();
        this.setState({ working: false });
      });
  }

  render() {
    return (
      <form
        onSubmit={() => {
          this.__handleSubmit();
        }}
        style={
          this.state.working ? { opacity: 0.5, pointerEvents: "none" } : {}
        }
      >
        <AddressTypeAhead
          nobox
          postOfficeBoxField={this.props.postOfficeBoxField}
          id="locality"
          label={this.props.t("Address")}
          placeholder={this.props.t("Street, locality")}
          subvalues={{
            lat: this.state.data.lat,
            lng: this.state.data.lng,
            street: this.state.data.street,
            street_number: this.state.data.streetNumber,
            region: this.state.data.region,
            post_office_box_number: this.state.data.postOfficeBoxNumber,
            locality: this.state.data.locality,
            postal_code: this.state.data.postalCode,
            country: this.state.data.country,
          }}
          gmap={this.props.gmap}
          onSubvaluesChange={(address) => {
            this.__handleMultiFieldsChange([
              { field: "lat", value: address.lat },
              { field: "lng", value: address.lng },
              { field: "street", value: address.street },
              { field: "streetNumber", value: address.street_number },
              { field: "region", value: address.region },
              {
                field: "postOfficeBoxNumber",
                value: address.post_office_box_number,
              },
              { field: "locality", value: address.locality },
              { field: "postalCode", value: address.postal_code },
              { field: "country", value: address.country },
            ]);
          }}
          countryDropdownOptions={Countries.getCountries()}
          autocomplete="false"
          translate={(string) => {
            switch (string) {
              case "Enter address manual":
                return this.props.t("addressTypeAhead.enterAddressManual");
              case "Street":
                return this.props.t("addressTypeAhead.street");
              case "Street Number":
                return this.props.t("addressTypeAhead.streetNumber");
              case "Postal Code":
                return this.props.t("addressTypeAhead.postalCode");
              case "Locality":
                return this.props.t("addressTypeAhead.locality");
              case "Post Office Box Number":
                return this.props.t("addressTypeAhead.postOfficeBoxNumber");
              case "Country":
                return this.props.t("addressTypeAhead.country");
              case "Automatic address lookup":
                return this.props.t(
                  "addressTypeAhead.Automatic address lookup"
                );
              case "Active":
                return this.props.t("Active");
              default:
                return string;
            }
          }}
        />
        {this.state.data.lat && (
          <AddressPicker
            lat={this.state.data.lat}
            lng={this.state.data.lng}
            gmap={this.props.gmap}
            zoom={16}
            mapStyle="street"
            onChange={(address) => {
              this.__handleFieldChange("lat", address.lat);
              this.__handleFieldChange("lng", address.lng);
              if (this.props.pinUpdateAddress) {
                this.__handleFieldChange("street", address.street);
                this.__handleFieldChange("streetNumber", address.street_number);
                this.__handleFieldChange("region", address.region);
                this.__handleFieldChange(
                  "postOfficeBoxNumber",
                  address.post_office_box_number
                );
                this.__handleFieldChange("locality", address.locality);
                this.__handleFieldChange("postalCode", address.postal_code);
                this.__handleFieldChange("country", address.country);
              }
            }}
            countryDropdownOptions={Countries.getCountries()}
            autocomplete="false"
            pinUpdateAddress={this.props.pinUpdateAddress}
          />
        )}
      </form>
    );
  }
}

AddressCreateForm.propTypes = {
  actions: PropTypes.object,
  addressStore: PropTypes.object,
  onFail: PropTypes.func,
  onReady: PropTypes.func,
  save: PropTypes.bool,
  deniedCreate: PropTypes.func,
  approvedCreate: PropTypes.func,
  setCreateable: PropTypes.func,
  createDone: PropTypes.func,
  gmap: PropTypes.object,
  pinUpdateAddress: PropTypes.bool,
  postOfficeBoxField: PropTypes.bool,
};

AddressCreateForm.defaultProps = {
  addressStore: {},
  save: false,
  gmap: {},
  pinUpdateAddress: false,
  postOfficeBoxField: false,
};

const compConnected = connect(
  (state) => {
    return {
      addressStore: state.addresses,
    };
  },
  (dispatch) => {
    return {
      actions: bindActionCreators({ ...addressActions }, dispatch),
    };
  }
)(AddressCreateForm);

const compAsyncLoaded = asyncLoading(() => {
  const googleMapsApiKey = Config.googleMapsApiKey;
  const gmap = {
    globalPath: "google.maps",
    url: `${Config.googleMapsApiUrl}/maps/api/js?key=${googleMapsApiKey}&libraries=places`,
    jsonp: true,
  };
  return { gmap };
})(compConnected);

export default withTranslation()(compAsyncLoaded);
