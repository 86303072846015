import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetPhaseListQuery } from "api/entities/phase";
import getPhaseByEvent from "utilities/getPhaseByEvent";

interface UsePhaseByEventArgs {
  baseType?: string;
  eventType?: string | null;
  marketingMethod?: "buy" | "rent";
}

function usePhaseByEvent({
  baseType,
  eventType,
  marketingMethod,
}: UsePhaseByEventArgs) {
  const getPhaseListResult = useGetPhaseListQuery(
    marketingMethod ? { phaseForMarketingMethod: marketingMethod } : skipToken
  );

  return {
    loading: getPhaseListResult.isLoading || getPhaseListResult.isFetching,
    phaseByEvent:
      getPhaseListResult.currentData &&
      getPhaseByEvent({
        baseType,
        eventType,
        phaseItems: getPhaseListResult.currentData._embedded.phase,
      }),
  };
}

export default usePhaseByEvent;
