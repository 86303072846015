import { createTransformResponse } from "api/createEndpoint";
import { GetContactListReducedResponse } from "./types";

const transformResponse =
  createTransformResponse<GetContactListReducedResponse>((response: any) => {
    const newItems = response.items.map((item: any) => {
      const shapedItem = {
        ...item,
        created: {
          date: item.created,
          timezone_type: 3,
          timezone: "Europe/Zurich",
        },
        _embedded: {
          address: item?.contactaddress?.[0],
          avatar: item?.contactavatar?.[0],
        },
      };
      delete shapedItem.contactaddress;
      delete shapedItem.contactavatar;
      return shapedItem;
    });
    return {
      ...response,
      items: newItems,
    };
  });

export default transformResponse;
