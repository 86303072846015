import { useMemo, useState } from "react";
import { ContactForPreview } from "../../../helpers/useMessageBodyPreview/messageBodyPreviewHelpers";

export const useMessageBodyPreviewControls = ({
  contacts,
  fetchPreview,
}: {
  contacts: ContactForPreview[];
  fetchPreview: (recipient: ContactForPreview | undefined) => Promise<void>;
}) => {
  const [inPreviewMode, setInPreviewMode] = useState(false);
  const [activeContactIndex, setActiveContactIndex] = useState(0);

  const activeContact = useMemo(() => {
    return contacts[
      activeContactIndex >= contacts.length ? 0 : activeContactIndex
    ];
  }, [activeContactIndex, contacts]);

  const handleContactChangeLeft = () => {
    setActiveContactIndex((currentIndex) => {
      let newIndex = currentIndex - 1;

      if (newIndex < 0) {
        newIndex = contacts.length - 1;
      }

      fetchPreview(contacts[newIndex]);
      return newIndex;
    });
  };

  const handleContactChangeRight = () => {
    setActiveContactIndex((currentIndex) => {
      let newIndex = currentIndex + 1;

      if (newIndex >= contacts.length) {
        newIndex = 0;
      }

      fetchPreview(contacts[newIndex]);
      return newIndex;
    });
  };

  const handlePreviewToggle = async (isOn: boolean) => {
    setInPreviewMode(isOn);
    if (isOn) {
      fetchPreview(activeContact);
    }
  };

  return {
    activeContact,
    handleContactChangeLeft,
    handleContactChangeRight,
    handlePreviewToggle,
    inPreviewMode,
  };
};
