import { createProvidesTags } from "api/createEndpoint";
import { GetUserQueryParams } from "./types";
import { UserShape } from "../../types";

const providesTags = createProvidesTags<UserShape, GetUserQueryParams>(
  (result) => {
    if (result) {
      return [{ type: "User", id: result.username }];
    }

    return ["User"];
  }
);

export default providesTags;
