import Config from "config";
import createBaseActions from "entities/createBaseActions";
import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import CompanyEmailSettingState from "./types";

const name = "mainCompanyEmailSetting" as const;
const pluralName = "mainCompanyEmailSettings" as const;

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: CompanyEmailSettingState,
    actions?: any
  ) => CompanyEmailSettingState;
}

export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: name,
  pluralName: pluralName,
  url: `${Config.apiUrl}/${Config.customerKey}/email-setting/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "email_setting",
      forcedFilter: [
        {
          type: "eq",
          field: "companyEmailSetting",
          value: "1",
        },
      ],
    }),
  },
});

const mainCompanyEmailSettingResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const mainCompanyEmailSettingResource =
  mainCompanyEmailSettingResourceCreator.resource;
export const useMainCompanyEmailSetting =
  mainCompanyEmailSettingResourceCreator.createHook();
