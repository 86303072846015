import { useLazyGetTemplateListQuery } from "api/entities/template";
import { mapMessageTemplates } from "./mapMessageTemplates";
import { CleanSelectOption } from "@casasoft/styleguide/components/formElements/Select";
import { TemplateShape } from "entities/template/types";
import { useState } from "react";

type BaseValueType = TemplateShape | undefined;
export type MessageTemplateSelectValueType = BaseValueType;

interface UseMessageTemplatesProps {
  value: BaseValueType;
  onChange: (newValue: MessageTemplateSelectValueType) => void;
}

export const useMessageTemplates = ({
  value,
  onChange,
}: UseMessageTemplatesProps) => {
  const [trigger] = useLazyGetTemplateListQuery();
  const [templates, setTemplates] = useState<TemplateShape[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateShape | undefined
  >(value);

  const loadOptions = async (
    searchString: string
  ): Promise<CleanSelectOption[]> => {
    try {
      const data = await trigger({ searchString }).unwrap();
      const templates = data._embedded.template;

      setTemplates(templates);
      return mapMessageTemplates(templates);
    } catch (e) {
      setError("Error loading templates");
    }

    return mapMessageTemplates([]);
  };

  const onTemplateChange = (newValue: string) => {
    if (newValue !== selectedTemplate?.id?.toString()) {
      const foundTemplate = templates.find(
        (template) => template.id?.toString() === newValue
      );

      if (foundTemplate) {
        setSelectedTemplate(foundTemplate);
        onChange(foundTemplate);
      }
    }
  };

  return {
    loadOptions,
    onTemplateChange,
    templateValue: value?.id?.toString(),
    error,
  };
};
