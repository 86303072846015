import { useEffect, useState, ReactNode } from "react";
import { useParams } from "react-router-dom";
import { Main } from "@casasoft/styleguide/components/app";
import Config from "config";
import { i18n } from "utilities";

interface OutsideWrapperProps {
  children: ReactNode;
}

const OutsideWrapper = ({ children }: OutsideWrapperProps) => {
  const [curImage, setCurImage] = useState<
    { type: "path"; path: string } | { type: "url"; url: string }
  >();

  useEffect(() => {
    const fetchBgImage = async () => {
      fetch(`${Config.apiUrl}/${Config.customerKey}/get-bg-image`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 500) {
            throw Error(`${response.status} ${response.detail}`);
          }
          return response;
        })
        .then((response) => {
          setCurImage(response);
        });
    };
    fetchBgImage();
  }, []);

  const { lang: langParam } = useParams<{ lang: string }>();

  const lang = langParam || "de";

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  let imagesrc = "";
  if (curImage?.type === "path") {
    imagesrc = `${Config.apiUrl}${curImage.path}`;
  }
  if (curImage?.type === "url") {
    imagesrc = curImage.url;
  }

  return (
    <div className="outside app--notoolbar">
      <Main>
        <div className="outside__content">{children}</div>
        {imagesrc && (
          <div
            className="outside__infospace"
            style={{ backgroundImage: `url(${imagesrc})` }}
          />
        )}
        {!imagesrc && (
          <div
            className="outside__infospace"
            style={{ backgroundImage: `url('/images/login-bg-summer.jpg')` }}
          />
        )}
      </Main>
    </div>
  );
};

export default OutsideWrapper;
