import { CasaoneBaseQueryArgs } from "api/types";
import queryBuilder, { FilterItem } from "utilities/queryBuilder";
import { GetListReducedQueryParams } from "./types";
import { eventFields, messageFields } from "./fieldsets";

function query({
  eventType,
  query,
}: GetListReducedQueryParams): CasaoneBaseQueryArgs {
  const additionalEventTypeFilter: FilterItem[] = [];

  if (eventType === "event") {
    additionalEventTypeFilter.push(
      {
        type: "leftjoin",
        field: "creator",
        alias: "creator",
      },
      {
        type: "notin",
        field: "type",
        values: ["reminder", "contact-merge"],
      }
    );
  }
  if (eventType === "message") {
    additionalEventTypeFilter.push({
      type: "in",
      field: "type",
      values: [
        "message-outbound",
        "message-inbound",
        "message-forward",
        "message-inbound-property-inquiry",
        "message-outbound-property-matching",
        "message-outbound-property-documentation",
      ],
      where: "and",
    });
  }
  const queryFields = eventType === "message" ? messageFields : eventFields;
  const queryContext =
    eventType === "message"
      ? query?.context || "message-list-reduced"
      : query?.context || "event-list-reduced";
  const builtQuery = queryBuilder.buildToUrl(
    query.orderBy,
    [
      ...query.filter,
      {
        type: "leftjoin",
        field: "events",
        alias: "events",
      },
      {
        type: "leftjoin",
        field: "eventType",
        alias: "eventType",
      },
      {
        type: "leftjoin",
        field: "attendees",
        alias: "attendees",
        condition: `attendees.attendeeType = 'attendee'`,
        conditionType: "with",
      },
      {
        type: "leftjoin",
        field: "contact",
        alias: "attendees_contact",
        parentAlias: "attendees",
      },
      {
        type: "leftjoin",
        field: "attendees",
        alias: "recipient",
        condition: `recipient.attendeeType = 'recipient'`,
        conditionType: "with",
      },
      {
        type: "leftjoin",
        field: "contact",
        alias: "recipient_contact",
        parentAlias: "recipient",
      },
      {
        type: "leftjoin",
        field: "employer",
        alias: "recipient_contact_employer",
        parentAlias: "recipient_contact",
      },
      {
        type: "leftjoin",
        field: "avatar",
        alias: "recipient_contact_avatar",
        parentAlias: "recipient_contact",
      },
      {
        type: "leftjoin",
        field: "attendees",
        alias: "sender",
        condition: `sender.attendeeType = 'sender'`,
        conditionType: "with",
      },
      {
        type: "leftjoin",
        field: "contact",
        alias: "sender_contact",
        parentAlias: "sender",
      },
      {
        type: "leftjoin",
        field: "employer",
        alias: "sender_contact_employer",
        parentAlias: "sender_contact",
      },
      {
        type: "leftjoin",
        field: "avatar",
        alias: "sender_contact_avatar",
        parentAlias: "sender_contact",
      },
      {
        type: "leftjoin",
        field: "contents",
        alias: "property_contents",
        parentAlias: "property",
        condition: `property_contents.status != 'ignore'`,
        conditionType: "with",
      },
      {
        type: "leftjoin",
        field: "contentMedias",
        alias: "property_contents_contentMedia",
        parentAlias: "property_contents",
      },
      {
        type: "leftjoin",
        field: "media",
        alias: "property_contents_contentMedia_media",
        parentAlias: "property_contents_contentMedia",
      },

      {
        type: "neq",
        field: "doneReason",
        value: "ignore",
      },
      ...additionalEventTypeFilter,
    ],
    {
      pagesize: query.pageSize,
      page: query.page,
      context: queryContext,
    },
    queryFields
  );

  return {
    url: `event?${builtQuery}`,
    method: "GET",
  };
}

export default query;
