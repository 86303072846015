import { RootState } from "redux/store";

/** Selects the resource options for a given resource id. Resource id in relation to filter presets would be known as the `presetName` */
export function selectResourceOptions(resourceId: string) {
  return (state: RootState) => {
    return state.app.resourceOptions[resourceId];
  };
}

export function selectAlert() {
  return (state: RootState) => {
    return state.app.alert;
  };
}
