import { UpdateEventSingleBody } from "api/entities/event/endpoints/update-single/types";
import { type TaskItemFormShape } from "../hooks/useTaskEditForm";
import {
  getAttendeeData,
  getAttendeesCreatePayload,
  getAttendeesUpdatePayload,
} from "components/event/helpers/attendees";
import type { EventShape } from "entities/event/types";
import { CreateEventSingleBody } from "api/entities/event/endpoints/create-single/types";
import csMoment from "utilities/csMoment";

const formatTaskDateTime = ({
  date,
  time,
}: {
  date: string | null;
  time: string | null;
}): string | null => (date ? `${date} ${time || "00:00"}` : null);

export const mapTaskFormToCreateEventPayload = ({
  contactId,
  formData,
}: {
  contactId: string | undefined;
  formData: TaskItemFormShape;
}): CreateEventSingleBody => {
  return {
    type: "task",
    start: csMoment().format("YYYY-MM-DD HH:mm"),
    end: formatTaskDateTime({
      date: formData.endDate,
      time: formData.endTime,
    }),
    private: formData.private,
    subject: formData.subject,
    html: formData.html,
    attendees: getAttendeesCreatePayload({
      recipient: formData.recipient,
      sender: contactId,
    }),
    property: formData.property,
  };
};

export const mapTaskFormToUpdateEventPayload = ({
  task,
  formData,
}: {
  task: EventShape;
  formData: TaskItemFormShape;
}): UpdateEventSingleBody => {
  const sender = getAttendeeData(task._embedded?.attendees).sender;
  const senderContactId = sender?._embedded.contact.id;

  return {
    id: task.id,
    end: formatTaskDateTime({
      date: formData.endDate,
      time: formData.endTime,
    }),
    private: formData.private,
    subject: formData.subject,
    html: formData.html,
    attendees: getAttendeesUpdatePayload({
      dirtyData: { recipient: formData.recipient },
      data: {
        recipient: formData.recipient,
        sender: senderContactId,
      },
    }),
    property: formData.property,
  };
};
