import type { PropertyAttachment } from "../helpers/propertyAttachment.model";
import { PropertyAttachmentSidebarSingle } from "./PropertyAttachmentSidebarSingle";

interface PropertyAttachmentSidebarProps {
  lastSelectedAttachment: PropertyAttachment | undefined;
  onChange: (updatedAttachment: PropertyAttachment) => void;
}

export const PropertyAttachmentSidebar = ({
  lastSelectedAttachment,
  onChange,
}: PropertyAttachmentSidebarProps) => {
  const content = !lastSelectedAttachment ? null : (
    <PropertyAttachmentSidebarSingle
      selectedAttachment={lastSelectedAttachment}
      onChange={onChange}
    />
  );

  return (
    <div className="tw-w-[300px] tw-h-full tw-bg-cs-shade-50 tw-p-2">
      {content}
    </div>
  );
};
