import { MultiValue } from "reactSelectNew";

type BaseValueType = { value: string };

function getArrayedSelectValue<IsMulti extends boolean>(
  theValue?: IsMulti extends true ? MultiValue<BaseValueType> : BaseValueType
): string[] {
  const arrayValues: string[] = isMultiValue(theValue)
    ? theValue.map((val) => val.value)
    : theValue
    ? [theValue.value]
    : [];

  return arrayValues;
}

function isMultiValue(value: any): value is MultiValue<BaseValueType> {
  return Array.isArray(value);
}

export default getArrayedSelectValue;
