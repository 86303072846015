import { sendEvent } from "../sendEvent";
import { CustomEventGroup } from "../types";

type TinyMceCommonEventParams = {
  command: string;
  ui?: string;
  value?: string;
  lang?: string;
};

export type TinyMceEventParams = {
  command_executed: TinyMceCommonEventParams;
};

// We don't want to send these events to GA, because they are very frequent and not that useful for us
export const BLACK_LIST = ["mceFocus", "mceBlur"];

const defaults: CustomEventGroup<TinyMceEventParams> = {
  command_executed: {
    eventLabel: "TinyMCE Command Executed",
    eventParams: { lang: "de" },
  },
};

export const sendTinymceEvent = (
  eventName: keyof TinyMceEventParams,
  eventParams: TinyMceEventParams[typeof eventName]
) => {
  if (BLACK_LIST.includes(eventParams.command)) {
    return;
  }

  sendEvent({
    ...defaults[eventName],
    ...{
      eventName,
      eventCategory: "misc",
      eventParams: { ...defaults[eventName].eventParams, ...eventParams },
    },
  });
};
