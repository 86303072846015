import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import FormGroup, { FormGroupProps } from "../helpers/FormGroup";
import useDebounce from "../../../hooks/useDebounce";
import { tinymceManager } from "./tinymceManager";
import { selectContactLang } from "redux/auth/selectors";
import { useSelector } from "react-redux";
import { sendTinymceEvent } from "utilities/analytics/misc/sendTinymceEvent";

type TinymceEditorProps = React.ComponentProps<typeof Editor>;
// extend all the formgroup props which this component supports / passed down
export interface TinymceProps
  extends Pick<
    FormGroupProps,
    | "label"
    | "status"
    | "className"
    | "id"
    | "required"
    | "message"
    | "text"
    | "formType"
    | "disableLabel"
    | "quickActions"
  > {
  value: TinymceEditorProps["value"];
  tinymceConfig?: TinymceEditorProps["init"];

  // event props
  onDelayDuration?: number;
  onChange?: (html?: string, txt?: string) => void;
  onDelayChange?: (val?: string) => void;
  onFocus?: TinymceEditorProps["onFocus"];
  onBlur?: TinymceEditorProps["onBlur"];
  // onKeyUp: TinymceEditorProps["onKeyUp"];

  // custom props
  tinymce?: boolean;
  showToolbar?: boolean;

  // textarea props
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  required?: boolean;
  rows?: number;
}

const Tinymce = ({
  tinymce = true,
  tinymceConfig = {},
  rows = 5,

  // autoFocus,

  disabled,
  value,
  placeholder,
  name,

  onFocus,
  onBlur,
  onChange,

  // FormGroupProps
  label,
  status,
  className,
  id,
  required,
  message,
  text,
  formType,
  disableLabel,
  quickActions,

  onDelayChange,
  onDelayDuration = 100,

  showToolbar = true,
}: TinymceProps) => {
  const userLanguage = useSelector(selectContactLang);
  const defaultTinymceConfig: TinymceEditorProps["init"] = {
    menu: {},
    menubar: false,
    contextmenu:
      "link linkchecker image imagetools table spellchecker configurepermanentpen pastetext",
    quickbars_selection_toolbar:
      "bold italic | bullist numlist quicklink charmap",
    quickbars_insert_toolbar: false,
    autoresize_bottom_margin: 30,
    autoresize_max_height: 200,
    resize: true,
    plugins: [
      "autolink",
      "link",
      "lists",
      "charmap",
      "quickbars",
      "paste",
      "inlite",
      "textcolor",
      "emoticons",
    ],
    toolbar: tinymceManager.getToolbar({ toolbar: "long" }),
    statusbar: false,
    language: userLanguage,
    // default language url public path
    language_url: `/translations/tinymce/${userLanguage}.js`,
    default_link_target: "_blank",
    link_assume_external_targets: true,
    link_list: undefined,
    target_list: false,
    rel_list: [{ title: "nofollow", value: "nofollow" }],
    browser_spellcheck: true,
    paste_as_text: true, // paste as text by default
    fixed_toolbar_container: ".tinymceToolbarContainer",
    content_style: `.mce-content-body { font-size: 14pt;}`,
  };

  const mergedConfig = {
    ...defaultTinymceConfig,
    ...tinymceConfig,
  };

  useDebounce(value, onDelayDuration, onDelayChange);

  const [focused, setFocused] = useState(false);
  const toolbarContainerClass = mergedConfig.fixed_toolbar_container?.replace(
    ".",
    ""
  );

  return (
    <FormGroup
      filled={value ? true : false}
      focused={focused}
      nobox
      label={label}
      status={status}
      className={className}
      id={id}
      required={required}
      message={message}
      text={text}
      formType={formType}
      disableLabel={disableLabel}
      quickActions={quickActions}
    >
      <div
        className={toolbarContainerClass}
        style={{ display: showToolbar ? "" : "none" }}
      ></div>

      {tinymce ? (
        <Editor
          id={id}
          value={value}
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          init={mergedConfig}
          inline
          onEditorChange={(content, editor) => {
            onChange?.(content, editor.getContent({ format: "text" }));
          }}
          onFocus={(e, editor) => {
            setFocused(true);
            onFocus?.(e, editor);
          }}
          onBlur={(e, editor) => {
            setFocused(false);
            onBlur?.(e, editor);
          }}
          onInit={(evt, editor) => {
            editor.on("ExecCommand", (e) => {
              sendTinymceEvent("command_executed", {
                command: e.command,
                lang: userLanguage,
                value: e.value,
                ui: e.ui as unknown as string | undefined,
              });
            });
          }}
          disabled={disabled}
        />
      ) : (
        <textarea
          className="cs-form-control"
          value={value}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          name={name}
          required={required}
          rows={rows}
          onChange={(e) => {
            onChange?.(e.target.value);
          }}
        />
      )}
    </FormGroup>
  );
};

export default Tinymce;
