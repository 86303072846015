import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { DataAttributes } from "@casasoft/styleguide/utilities/extractDataAttributes";

export interface NavProps {
  /** Default value for collapse state */
  collapsed?: boolean;
  navToggle?: boolean;
  onToggle?: () => void;
  /** Array of item names to generate list */
  items: ({ node: ReactNode } & DataAttributes)[];
}

const Nav = ({
  collapsed = true,
  navToggle = false,
  onToggle,
  items = [],
}: NavProps) => {
  const { t } = useTranslation();
  const navItems = items.map(({ node: itemNode, ...dataAttributes }, key) => {
    return (
      <li {...dataAttributes} key={key} className="app__nav-item">
        {itemNode}
      </li>
    );
  });

  return (
    <div
      className={`app__nav ${collapsed ? "app__nav--collapsed" : ""} ${
        navToggle ? "open" : ""
      }`}
    >
      <div
        className="app__nav-toggler"
        onClick={() => {
          onToggle?.();
        }}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon
          fixedWidth
          transform={{ rotate: collapsed ? 180 : 0 }}
          icon={faAngleDoubleLeft}
        />{" "}
        {t("Collapse")}
      </div>
      <ol className="app__nav-list">{navItems}</ol>
    </div>
  );
};
export default Nav;
