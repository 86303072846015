interface ToolbarNavToggleProps {
  isOpen: boolean;
  onToggle: () => void;
}

const ToolbarNavToggle = ({ isOpen, onToggle }: ToolbarNavToggleProps) => {
  return (
    <div
      className={`md:tw-hidden tw-relative tw-w-6 tw-h-4 tw-mx-2 tw-cursor-pointer`}
      onClick={() => {
        onToggle();
      }}
    >
      <span
        className={`tw-absolute tw-left-0 tw-w-full tw-h-[2px] tw-bg-cs-shade-100 tw-transition-transform-opacity tw-will-change-transform tw-top-0 ${
          isOpen ? "tw-top-[calc(50%-1px)] tw-rotate-45" : ""
        }`}
      />
      <span
        className={`tw-absolute tw-left-0 tw-w-full tw-h-[2px] tw-bg-cs-shade-100 tw-transition-transform-opacity tw-will-change-transform tw-top-[calc(50%-1px)] ${
          isOpen ? "tw-opacity-0" : ""
        }`}
      />
      <span
        className={`tw-absolute tw-left-0 tw-w-full tw-h-[2px] tw-bg-cs-shade-100 tw-transition-transform-opacity tw-will-change-transform tw-bottom-0 ${
          isOpen ? "tw-bottom-[calc(50%-1px)] tw--rotate-45" : ""
        }`}
      />
    </div>
  );
};

export default ToolbarNavToggle;
