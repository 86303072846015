import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "standards:Fixed pricing",
    value: "fixed",
  },
  {
    label: "standards:Bidding process",
    value: "bidding",
  },
  {
    label: "standards:Auction",
    value: "auction",
  },
  {
    label: "standards:VAT opted",
    value: "isVatOpted",
  },
  {
    label: "standards:VAT not opted",
    value: "isNotVatOpted",
  },
  {
    label: "standards:Share deal",
    value: "isShareDeal",
  },
  {
    label: "standards:Asset deal",
    value: "isNotShareDeal",
  },
  {
    label: "standards:Sales method",
    value: "salesMethod",
  },
];

export const getFinanceItems = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLabel = createGetLabel(untranslatedList);

export const useFinances = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
