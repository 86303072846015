import {
  LanguageGroupData,
  type ReducedContactShape,
} from "components/contact/features/language-breakdown/LanguageBreakdownContainer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import mailProviderUtility from "utilities/mailProviderUtility";
import resourceGrabber from "utilities/resourceGrabber";

export const RECIPIENT_LANGUAGES = {
  en: "English",
  de: "German",
  fr: "French",
  it: "Italian",
  es: "Spanish",
  xx: "Unknown",
};

export type RecipientListFnProps = {
  lang?: string;
  recipientsLimit: number;
};

export type RecipientListFn = (
  data: RecipientListFnProps
) => Promise<ReducedContactShape[]> | undefined;

export const getRecipientsByLang = (
  list: ReducedContactShape[],
  lang: string
): ReducedContactShape[] =>
  lang === "xx"
    ? list.filter((contact) => !contact.lang)
    : list.filter((contact) => contact.lang === lang);

export const useRecipientList = (recipientList?: RecipientListFn) => {
  const { t } = useTranslation();
  const [languageRecipients, setLanguageRecipients] = useState<
    LanguageGroupData[] | undefined
  >(undefined);
  const [loading, setLoading] = useState(!!recipientList);
  // eslint-disable-next-line deprecation/deprecation
  const host = resourceGrabber.grab("casaoneUser", "auth")?.smtpHost;
  const recipientsLimit = host ? mailProviderUtility.getSenderLimit(host) : 200;

  const loadRecipients = async (): Promise<void> => {
    if (!recipientList) {
      return undefined;
    }
    setLoading(true);

    const allRecipients = await recipientList({
      lang: "all", // todo - this is to keep compatibility with the old code, where we use the function to fetch each language separately
      recipientsLimit:
        recipientsLimit * Object.keys(RECIPIENT_LANGUAGES).length,
    });

    const data =
      allRecipients &&
      Object.entries(RECIPIENT_LANGUAGES).map(([langCode, label]) => ({
        key: langCode,
        label: t(label),
        contacts: getRecipientsByLang(allRecipients, langCode),
      }));

    setLoading(false);
    setLanguageRecipients(data?.filter((group) => group.contacts.length > 0));
  };

  return {
    loadRecipients,
    languageRecipients,
    recipientsLimit,
    showLanguageBreakdown: !!recipientList,
    isLoadingLanguageRecipients: loading,
  };
};
