import { CasaoneResultDescription } from "api/types";
import { UpdateEventSingleResponse, UpdateEventSingleBody } from "./types";

const invalidatesTags: CasaoneResultDescription<
  UpdateEventSingleResponse,
  UpdateEventSingleBody
> = (result) => {
  if (result) {
    return [{ type: "Event", id: result.id }, { type: "PropertyContact" }];
  }

  return ["Event", "PropertyContact"];
};

export default invalidatesTags;
