import { BoardingOptions } from "boarding.js";
import { createTourTriggerSubscription } from "./events/boardingTriggerStart";
import initBoardingInstance from "./initBoardingInstance";

/**
 * Create a boarding instance and a subscription to the trigger event
 * @param uniqueId id of the boarding instance (used for the events)
 * @param t translator method for popover terms
 * @param boardingOptions the options that should be passed to the boarding instance
 * @returns [boardingInstance, unsubscriber]
 */
function createBoardingInstance(
  uniqueId: string,
  t: (term: string) => string,
  boardingOptions?: Partial<BoardingOptions>
) {
  const boardingInstance = initBoardingInstance(uniqueId, t, boardingOptions);

  const boardingInstanceTriggerunsibscriber = createTourTriggerSubscription(
    uniqueId,
    boardingInstance
  );

  return [boardingInstance, boardingInstanceTriggerunsibscriber] as const;
}

export default createBoardingInstance;
