import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faQuestionCircle,
  faSearch,
  faPhoneVolume,
  faPhone,
  faUserCheck,
  faStickyNote,
  faStamp,
  faBuilding,
  faClipboardList,
  faClipboardCheck,
  faUpload,
  faDownload,
  faExclamationTriangle,
  faEnvelope,
  faShare,
  faUsdCircle,
  faBell,
  faBook,
  faCommentAltDollar,
  faAd,
  faPlane,
  faSign,
  faCalendarCheck,
  faExchange,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";

const iconMap: { [key: string]: IconDefinition } = {
  "light/question-circle": faQuestionCircle,
  "light/ban": faBan,
  "light/search": faSearch,
  "light/phone-volume": faPhoneVolume,
  "light/phone": faPhone,
  "light/user-check": faUserCheck,
  "light/sign": faSign,
  "light/sticky-note": faStickyNote,
  "light/stamp": faStamp,
  "light/building": faBuilding,
  "light/calendar-check": faCalendarCheck,
  "light/clipboard-list": faClipboardList,
  "light/clipboard-check": faClipboardCheck,
  "light/upload": faUpload,
  "light/download": faDownload,
  "light/exclamation-triangle": faExclamationTriangle,
  "light/envelope": faEnvelope,
  "light/share": faShare,
  "light/usd-circle": faUsdCircle,
  "light/bell": faBell,
  "light/book": faBook,
  "light/comment-alt-dollar": faCommentAltDollar,
  "light/ad": faAd,
  "light/plane": faPlane,
  "light/exchange": faExchange,
};
Object.entries(iconMap).forEach((icon) => {
  library.add(icon[1]);
});

export default iconMap;
