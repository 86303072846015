import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  ["commercial", "standards:Commercial"],
  ["gastronomy", "standards:Gastronomy"],
  ["vacation", "standards:Vacation"],
  ["agricultural", "standards:Agricultural"],
  ["industrial", "standards:Industrial"],
  ["residential", "standards:Residential"],
  ["storage", "standards:Storage"],
  ["parking", "standards:Parking"],
  ["building", "standards:Construction"],
  ["investment", "Investment"],
];

const untranslatedListMapped = untranslatedList.map(([value, label]) => ({
  value,
  label,
}));

export const getUtilities = createGetList(untranslatedListMapped, {
  sort: "a-z",
});
export const getUtilityLabel = createGetLabel(untranslatedListMapped);

/** gets a joined string list of utilities */
export function getLabelListFromEntity(entity?: {
  _embedded?: {
    utilities?: {
      slug: string;
    }[];
  };
}) {
  if (entity?._embedded?.utilities?.length) {
    const items = entity._embedded.utilities.map((elem) =>
      getUtilityLabel(elem.slug)
    );
    return items.join(", ");
  }
  return "";
}

export const useUtilities = createUtilityHook(untranslatedListMapped, {
  sort: "a-z",
});
