import { ColorVarNames } from "./colorVarNameMap";

export type ThemePresetSlugs = "black" | "blue";

type RgbColor = [number, number, number];

export type ColorsMap = Partial<Record<ColorVarNames, RgbColor>>;

export const themePresetsMap: {
  [key in ThemePresetSlugs]: ColorsMap;
} = {
  blue: {
    /* primary colors */
    "--tw-cs-accent-color-light": [226, 247, 252] /* #e2f7fc */,
    "--tw-cs-accent-color": [0, 71, 140] /* #00478c */,
    "--tw-cs-accent-color-dark": [0, 38, 77] /* #00264d */,
  },
  black: {
    /* primary colors */
    "--tw-cs-accent-color-light": [221, 221, 221] /* #dddddd */,
    "--tw-cs-accent-color": [85, 85, 85] /* #555555 */,
    "--tw-cs-accent-color-dark": [0, 0, 0] /* #000000 */,
  },
};
