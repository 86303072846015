import { useEffect, useState } from "react";
import useForm, { RegisterFields } from "hooks/useForm";
import { useTranslation } from "react-i18next";
import { SubmitHandler } from "react-hook-form";
import { PropertyShape } from "entities/property/types";
import NumberField from "@casasoft/styleguide/components/forms/NumberField";
import Field from "@casasoft/styleguide/components/forms/Field";
import PropertyFloorField from "../property/PropertyFloorField";
import PropertyNumberOfRoomsField from "../property/PropertyNumberOfRoomsField";
import PropertyPriceField from "../property/PropertyPriceField";
import PropertyNetPriceField from "../property/PropertyNetPriceField";
import PropertyGrossPriceField from "../property/PropertyGrossPriceField";
import PropertyExtraCostsTotalField from "../property/PropertyExtraCostsTotalField";
import { Spinner } from "@casasoft/styleguide/components/helpers-ux";
import { ErrorMessage } from "@hookform/error-message";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import Button from "@casasoft/styleguide/components/forms/Button";
import handleFormModalError, {
  FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import { useDynamicProperty } from "entities/dynamicProperty/store";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_TYPES,
  BASE_DATA_UNIT,
  isSinglePublicationType,
  PUBLICATION_TYPES,
} from "utilities/propertyTypes";
import Messages from "@casasoft/styleguide/components/formElements/Messages";
import Config from "config";
import resourceHelper from "utilities/resource";
import { usePublications } from "entities/publication/store";
import { Numvals } from "utilities";
import { axiosInstance } from "utilities/axios";

interface FormShape {
  name: string | null;
  floor: number | null;
  numberOfRooms: number | null;
  grossPrice: number | null;
  netPrice: number | null;
  price: number | null;
  pricePropertySegment: "m" | "km" | "all" | null;
  rentPricePropertySegment: "m" | "km" | "all" | null;
  rentPriceTimeSegment: "y" | "m" | "d" | "w" | null;
  extraCostsTotal: number | null;
  rentalDeposit: number | null;
}

interface PropertyDuplicateFormProps {
  entity: PropertyShape | string;
  targetPropertyType: string;
  onDone?: (data?: { id: string; propertyType: string }) => void;
  onFail: FormModalOnFail;
}

function validateSegments(
  type: "price-property-segment",
  value?: string
): "m" | "km" | "all" | null;
function validateSegments(
  type: "rent-price-property-segment",
  value?: string
): "m" | "km" | "all" | null;
function validateSegments(
  type: "rent-price-time-segment",
  value?: string
): "y" | "m" | "d" | "w" | null;
function validateSegments(
  type:
    | "price-property-segment"
    | "rent-price-property-segment"
    | "rent-price-time-segment",
  value?: string
) {
  switch (type) {
    case "price-property-segment":
    case "rent-price-property-segment":
      if (value && ["m", "all", "km", "all"].includes(value)) {
        return value;
      }
      return null;
    case "rent-price-time-segment":
      if (value && ["y", "m", "d", "w"].includes(value)) {
        return value;
      }
      return null;
  }
}

const propertyToDefaultValues = (
  property?: PropertyShape,
  nameSuffix?: string
): FormShape => {
  return {
    name: !nameSuffix
      ? property?._embedded?.contents?.[0]?.name || null
      : `${property?._embedded?.contents?.[0]?.name || ""} ${nameSuffix}`,
    floor: property?.floor || null,
    numberOfRooms: property?.numberOfRooms || null,
    grossPrice: property?.grossPrice || null,
    netPrice: property?.netPrice || null,
    price: property?.price || null,
    pricePropertySegment: validateSegments(
      "price-property-segment",
      property?.pricePropertySegment
    ),
    rentPricePropertySegment: validateSegments(
      "rent-price-property-segment",
      property?.rentPricePropertySegment
    ),
    rentPriceTimeSegment:
      validateSegments(
        "rent-price-time-segment",
        property?.rentPriceTimeSegment
      ) || null,
    extraCostsTotal: property?.extraCostsTotal || null,
    rentalDeposit: property?.rentalDeposit || null,
  };
};

const PropertyDuplicateForm = ({
  entity: entityProp,
  targetPropertyType,
  onDone,
  onFail,
}: PropertyDuplicateFormProps) => {
  const { t } = useTranslation();

  const { store: dynamicPropertiesStore, getItem: getDynamicProperty } =
    useDynamicProperty();

  const temporaryPropertyTypePublication = PUBLICATION_TYPES.includes(
    targetPropertyType
  )
    ? targetPropertyType
    : undefined;
  const { reloadList: reloadPublicationsList } = usePublications(
    temporaryPropertyTypePublication &&
      isSinglePublicationType(temporaryPropertyTypePublication)
      ? "single"
      : "project"
  );

  type MessageKeys =
    | "netPrice"
    | "grossPrice"
    | "extraCostsTotal"
    | "rentalDeposit"
    | "form";
  const [infoMesages, setInfoMessages] = useState<{
    [key in MessageKeys]: { text: string; type: "info" }[];
  }>({
    form: [],
    netPrice: [],
    grossPrice: [],
    extraCostsTotal: [],
    rentalDeposit: [],
  });

  const entityId = typeof entityProp === "string" ? entityProp : undefined;
  const entity =
    typeof entityProp === "string" ? dynamicPropertiesStore.item : entityProp;

  const sourcePropertyType = entity?.propertyType || "";
  // if it's used as a create form (base-data -> publication)
  const isCreateForm =
    !PUBLICATION_TYPES.includes(sourcePropertyType) &&
    PUBLICATION_TYPES.includes(targetPropertyType);
  const {
    setValue,
    getValues,
    getDirtyValues,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<FormShape>({
    defaultValues: propertyToDefaultValues(
      entity || undefined,
      isCreateForm ? undefined : t("copy")
    ),
  });

  if (isDirty) {
    // a bug with useForm requires to deconstruct isDirty in order for the component to subscribe to rerenders. this condition just removes the is-unused warning
  }

  useEffect(() => {
    if (entityId) {
      getDynamicProperty({
        id: entityId,
        force: true,
      }).then((newProperty) => {
        if (newProperty) {
          const newSourcePropertyType = newProperty.propertyType;
          // if it's used as a create form (base-data -> publication)
          const isCreateFormCheck =
            !PUBLICATION_TYPES.includes(newSourcePropertyType) &&
            PUBLICATION_TYPES.includes(targetPropertyType);
          reset(
            propertyToDefaultValues(
              newProperty,
              isCreateFormCheck ? undefined : t("copy")
            )
          );
        }
      });
    }
  }, [entityId, getDynamicProperty, reset, targetPropertyType, t]);

  const onSubmit: SubmitHandler<FormShape> = async (data) => {
    const {
      name, // extract
      ...dirtyData
    } = getDirtyValues();
    const mainLangContentsLang = entity?._embedded?.contents?.[0].lang;

    const transformedData = {
      ...dirtyData,
      contents: mainLangContentsLang
        ? [{ lang: mainLangContentsLang, name: data.name || null, rank: 0 }]
        : undefined,
    };

    try {
      const resJson = await axiosInstance.post(
        `${Config.api2Url}/company/${Config.customerKey}/duplicate-property`,
        {
          targetPropertyType: targetPropertyType,
          property: entity?.id,
          data: transformedData,
        }
      );

      if (resJson.data.duplicatedId) {
        if (BASE_DATA_TYPES.includes(targetPropertyType)) {
          resourceHelper.reloadList(targetPropertyType); // reload unit/property/project list
        }
        if (PUBLICATION_TYPES.includes(targetPropertyType)) {
          reloadPublicationsList();
        }
      } else {
        throw new Error("Duplication/creation failed for an unknown reason");
      }

      onDone?.({
        id: resJson.data.duplicatedId,
        propertyType: targetPropertyType,
      });
      reset(data);
    } catch (error) {
      handleFormModalError(error, onFail);
    }
  };

  if (!entity) {
    return <></>;
  }

  function grabNameTitle() {
    let newNameTitle = t("Property name");
    if ([...PUBLICATION_TYPES].includes(targetPropertyType)) {
      newNameTitle = t("Title for publication");
    } else if (targetPropertyType === BASE_DATA_PROJECT) {
      newNameTitle = t("Project name");
    } else if (targetPropertyType === BASE_DATA_UNIT) {
      newNameTitle = t("Unit name");
    }
    return newNameTitle;
  }

  const nameTitle = grabNameTitle();

  const validateRent = (
    field: "netPrice" | "grossPrice" | "extraCostsTotal" | "rentalDeposit",
    value: number | null
  ) => {
    const fieldsToChange: {
      name: "netPrice" | "grossPrice" | "extraCostsTotal" | "rentalDeposit";
      value: number;
    }[] = [];

    const messages: typeof infoMesages = {
      form: [],
      netPrice: [],
      grossPrice: [],
      extraCostsTotal: [],
      rentalDeposit: [],
    };
    // getValues matched to take give value in consideration
    const sourceNetPrice = field === "netPrice" ? value : getValues("netPrice");
    const sourceGrossPrice =
      field === "grossPrice" ? value : getValues("grossPrice");
    const sourceExtraCostsTotal =
      field === "extraCostsTotal" ? value : getValues("extraCostsTotal");
    const sourceRentalDeposit =
      field === "rentalDeposit" ? value : getValues("rentalDeposit");
    const info: (
      | "netPrice"
      | "grossPrice"
      | "extraCostsTotal"
      | "rentalDeposit"
    )[] = [];
    if (
      sourceNetPrice === null &&
      sourceGrossPrice !== null &&
      sourceExtraCostsTotal !== null &&
      field !== "netPrice"
    ) {
      const netPrice = sourceGrossPrice - sourceExtraCostsTotal;
      fieldsToChange.push({ name: "netPrice", value: netPrice });

      messages.netPrice.push({
        text: t(
          "We calculated the net rent according to the gross rent and extracosts."
        ),
        type: "info",
      });
      messages.form = [
        {
          text: t(
            "We did some corrections for you, please check if that's correct."
          ),
          type: "info",
        },
      ];
      info.push("netPrice");
    }
    if (
      sourceNetPrice !== null &&
      sourceGrossPrice === null &&
      sourceExtraCostsTotal !== null &&
      field !== "grossPrice"
    ) {
      const grossPrice = sourceNetPrice + sourceExtraCostsTotal;
      fieldsToChange.push({
        name: "grossPrice",
        value: grossPrice,
      });

      messages.grossPrice.push({
        text: t(
          "We calculated the gross rent according to the net rent and extracosts."
        ),
        type: "info",
      });
      messages.form = [
        {
          text: t(
            "We did some corrections for you, please check if that's correct."
          ),
          type: "info",
        },
      ];
      info.push("grossPrice");
    }
    if (
      sourceNetPrice !== null &&
      sourceGrossPrice !== null &&
      sourceGrossPrice - sourceNetPrice !== sourceExtraCostsTotal
    ) {
      const extraCostsTotal = sourceGrossPrice - sourceNetPrice;
      fieldsToChange.push({
        name: "extraCostsTotal",
        value: extraCostsTotal,
      });

      messages.extraCostsTotal.push({
        text: t(
          "We calculated the extra costs according to the net rent and gross rent."
        ),
        type: "info",
      });
      messages.form = [
        {
          text: t(
            "We did some corrections for you, please check if that's correct."
          ),
          type: "info",
        },
      ];
      info.push("extraCostsTotal");
    }
    if (
      info.includes("grossPrice") ||
      (sourceGrossPrice !== null &&
        (!sourceRentalDeposit ||
          sourceRentalDeposit < 3 * sourceGrossPrice ||
          sourceRentalDeposit < 3 * sourceGrossPrice) &&
        field === "grossPrice")
    ) {
      let grossPrice: number;
      if (sourceGrossPrice !== null) {
        grossPrice = sourceGrossPrice;
      } else {
        grossPrice = 0;
      }
      const rentalDeposit = 3 * grossPrice;
      fieldsToChange.push({
        name: "rentalDeposit",
        value: rentalDeposit,
      });

      messages.rentalDeposit.push({
        text: t(
          "We calculated the minimal rental deposit as the triple of the gross rent."
        ),
        type: "info",
      });
      messages.form = [
        {
          text: t(
            "We did some corrections for you, please check if that's correct."
          ),
          type: "info",
        },
      ];
      info.push("rentalDeposit");
    }
    if (info.length) {
      setInfoMessages(messages);
    }
    if (fieldsToChange.length > 0) {
      fieldsToChange.forEach((changeField) => {
        setValue(changeField.name, changeField.value, {
          shouldDirty: true,
          shouldValidate: true,
        });
      });
    }
  };

  const getDescription = () => {
    if (PUBLICATION_TYPES.includes(targetPropertyType)) {
      return t(
        "This action will create a publication based on the provided property."
      );
    }
    if (targetPropertyType === BASE_DATA_PROJECT) {
      return t(
        "This action will create a new project based on the chosen projects data."
      );
    }
    if (targetPropertyType === BASE_DATA_UNIT) {
      return t(
        "This action will create a new unit based on the chosen units data."
      );
    }
    return t(
      "This action will create a new property based on the chosen properties data."
    );
  };

  return (
    <RegisterFields
      control={control}
      fields={{
        name: {
          rules: {
            required: {
              value: true,
              message: t("Value is required and can't be empty"),
            },
          },
        },
        floor: {},
        numberOfRooms: {},
        grossPrice: {},
        netPrice: {},
        price: {},
        pricePropertySegment: {},
        rentPricePropertySegment: {},
        rentPriceTimeSegment: {},
        extraCostsTotal: {},
        rentalDeposit: {},
      }}
      render={(fieldsRenderer, chainFields) => (
        <form
          className="spinner-fixture"
          style={isSubmitting ? { opacity: 0.5, pointerEvents: "none" } : {}}
          onSubmit={handleSubmit(onSubmit)}
        >
          {isSubmitting && <Spinner />}
          {getDescription()}
          {fieldsRenderer("name", (formValue, onFormValueChange) =>
            !isCreateForm ? (
              <Field
                nobox
                label={nameTitle}
                value={formValue || undefined}
                onChange={(value: string) => {
                  onFormValueChange(value || null);
                }}
                message={{
                  type: "error",
                  text: <ErrorMessage name="name" errors={errors} />,
                }}
                required
              />
            ) : (
              // make sure name is registered
              <>
                {errors.name && (
                  <span className="tw-text-cs-danger">
                    <br /> Name: <ErrorMessage name="name" errors={errors} />
                  </span>
                )}
              </>
            )
          )}
          {entity.marketingMethod === "rent" &&
            chainFields(
              ["rentPricePropertySegment", "rentPriceTimeSegment"],
              (formValues, formValueChangeHandlers) => (
                <>
                  {fieldsRenderer(
                    "grossPrice",
                    (formValue, onFormValueChange) => (
                      <PropertyGrossPriceField
                        grossPrice={formValue}
                        rentPricePropertySegment={
                          formValues.rentPricePropertySegment || undefined
                        }
                        rentPriceTimeSegment={
                          formValues.rentPriceTimeSegment || undefined
                        }
                        currency={entity.priceCurrency}
                        onChange={(field: string, value: string) => {
                          onFormValueChange(!value ? null : parseFloat(value));
                        }}
                        onMultiFieldChange={(
                          fields: { name: string; value: string }[]
                        ) => {
                          fields.forEach((field) => {
                            if (field.name === "rentPricePropertySegment") {
                              const segment = validateSegments(
                                "rent-price-property-segment",
                                field.value
                              );
                              formValueChangeHandlers.rentPricePropertySegment(
                                segment
                              );
                            }
                            if (field.name === "rentPriceTimeSegment") {
                              const segment = validateSegments(
                                "rent-price-time-segment",
                                field.value
                              );
                              formValueChangeHandlers.rentPriceTimeSegment(
                                segment
                              );
                            }
                          });
                        }}
                        message={
                          infoMesages.grossPrice.length
                            ? infoMesages.grossPrice
                            : {
                                type: "error",
                                text: (
                                  <ErrorMessage
                                    name="grossPrice"
                                    errors={errors}
                                  />
                                ),
                              }
                        }
                        onBlur={(field: "grossPrice", value: string) => {
                          validateRent(
                            field,
                            !value ? null : parseFloat(value)
                          );
                        }}
                      />
                    )
                  )}
                  {fieldsRenderer(
                    "netPrice",
                    (formValue, onFormValueChange) => (
                      <PropertyNetPriceField
                        netPrice={formValue}
                        rentPricePropertySegment={
                          formValues.rentPricePropertySegment || undefined
                        }
                        rentPriceTimeSegment={
                          formValues.rentPriceTimeSegment || undefined
                        }
                        currency={entity.priceCurrency}
                        onChange={(field: string, value: string) => {
                          onFormValueChange(!value ? null : parseFloat(value));
                        }}
                        onMultiFieldChange={(
                          fields: { name: string; value: string }[]
                        ) => {
                          fields.forEach((field) => {
                            if (field.name === "rentPricePropertySegment") {
                              const segment = validateSegments(
                                "rent-price-property-segment",
                                field.value
                              );
                              formValueChangeHandlers.rentPricePropertySegment(
                                segment
                              );
                            }
                            if (field.name === "rentPriceTimeSegment") {
                              const segment = validateSegments(
                                "rent-price-time-segment",
                                field.value
                              );
                              formValueChangeHandlers.rentPriceTimeSegment(
                                segment
                              );
                            }
                          });
                        }}
                        message={
                          infoMesages.netPrice.length
                            ? infoMesages.netPrice
                            : {
                                type: "error",
                                text: (
                                  <ErrorMessage
                                    name="netPrice"
                                    errors={errors}
                                  />
                                ),
                              }
                        }
                        onBlur={(field: "netPrice", value: string) => {
                          validateRent(
                            field,
                            !value ? null : parseFloat(value)
                          );
                        }}
                      />
                    )
                  )}
                  {fieldsRenderer(
                    "extraCostsTotal",
                    (formValue, onFormValueChange) => (
                      <PropertyExtraCostsTotalField
                        extraCostsTotal={formValue}
                        rentPricePropertySegment={
                          formValues.rentPricePropertySegment || undefined
                        }
                        rentPriceTimeSegment={
                          formValues.rentPriceTimeSegment || undefined
                        }
                        currency={entity.priceCurrency}
                        onChange={(field: string, value: string) => {
                          onFormValueChange(!value ? null : parseFloat(value));
                        }}
                        message={
                          infoMesages.extraCostsTotal.length
                            ? infoMesages.extraCostsTotal
                            : {
                                type: "error",
                                text: (
                                  <ErrorMessage
                                    name="extraCostsTotal"
                                    errors={errors}
                                  />
                                ),
                              }
                        }
                        onMultiFieldChange={(
                          fields: { name: string; value: string }[]
                        ) => {
                          fields.forEach((field) => {
                            if (field.name === "rentPricePropertySegment") {
                              const segment = validateSegments(
                                "rent-price-property-segment",
                                field.value
                              );
                              formValueChangeHandlers.rentPricePropertySegment(
                                segment
                              );
                            }
                            if (field.name === "rentPriceTimeSegment") {
                              const segment = validateSegments(
                                "rent-price-time-segment",
                                field.value
                              );
                              formValueChangeHandlers.rentPriceTimeSegment(
                                segment
                              );
                            }
                          });
                        }}
                        onBlur={(field: "extraCostsTotal", value: string) => {
                          validateRent(
                            field,
                            !value ? null : parseFloat(value)
                          );
                        }}
                      />
                    )
                  )}
                </>
              )
            )}
          {entity.marketingMethod === "rent" &&
            fieldsRenderer("rentalDeposit", (formValue, onFormValueChange) => (
              <NumberField
                nobox
                label={Numvals.numvalLabel("rentalDeposit")}
                value={formValue?.toString()}
                onChange={(value) => {
                  onFormValueChange(!value ? null : parseFloat(value));
                }}
                onBlur={(value: string) => {
                  validateRent(
                    "rentalDeposit",
                    !value ? null : parseFloat(value)
                  );
                }}
                prefix={entity.priceCurrency}
                step={1}
                min={0}
                message={
                  infoMesages.rentalDeposit.length
                    ? infoMesages.rentalDeposit
                    : {
                        type: "error",
                        text: (
                          <ErrorMessage name="rentalDeposit" errors={errors} />
                        ),
                      }
                }
              />
            ))}
          {entity.marketingMethod === "buy" &&
            chainFields(
              ["price", "pricePropertySegment"],
              (formValues, formValueChangeHandlers) => (
                <PropertyPriceField
                  price={formValues.price || undefined}
                  pricePropertySegment={
                    formValues.pricePropertySegment || undefined
                  }
                  currency={entity.priceCurrency}
                  onMultiFieldChange={(
                    fields: { name: string; value: string }[]
                  ) => {
                    fields.forEach((field) => {
                      if (field.name === "price") {
                        formValueChangeHandlers.price(
                          !field.value ? null : parseFloat(field.value)
                        );
                      }
                      if (field.name === "pricePropertySegment") {
                        const segment = validateSegments(
                          "price-property-segment",
                          field.value
                        );
                        formValueChangeHandlers.pricePropertySegment(segment);
                      }
                    });
                  }}
                  message={{
                    type: "error",
                    text: <ErrorMessage name="price" errors={errors} />,
                  }}
                />
              )
            )}
          {fieldsRenderer("floor", (formValue, onFormValueChange) => (
            <PropertyFloorField
              value={formValue || ""}
              onChange={(value: number) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage name="floor" errors={errors} />,
              }}
            />
          ))}
          {fieldsRenderer("numberOfRooms", (formValue, onFormValueChange) => (
            <PropertyNumberOfRoomsField
              value={formValue?.toString() || undefined}
              onChange={(value: string) => {
                onFormValueChange(!value ? null : parseFloat(value));
              }}
              message={{
                type: "error",
                text: <ErrorMessage name="numberOfRooms" errors={errors} />,
              }}
            />
          ))}

          {!!infoMesages.form.length && (
            <Messages icon messages={infoMesages.form} />
          )}

          <ModalFooter>
            <Button
              isPrimary
              type="submit"
              buttonValue={isCreateForm ? t("Save") : t("Duplicate")}
            />
          </ModalFooter>
        </form>
      )}
    />
  );
};

export default PropertyDuplicateForm;
