import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

/*
  Ausweis L EU/EFTA (Kurzaufenthaltsbewilligung)  EU_L
  Ausweis B EU/EFTA (Aufenthaltsbewilligung)  EU_B
  Ausweis C EU/EFTA (Niederlassungsbewilligung  EU_C
  Ausweis Ci EU/EFTA (Aufenthaltsbewilligung mit Erwerbstätigkeit)  EU_Ci
  Ausweis G EU/EFTA (Grenzgängerbewilligung)  EU_G
  Ausweis B (Aufenthaltsbewilligung)  B
  Ausweis C (Niederlassungsbewilligung)  C
  Ausweis Ci (Aufenthaltsbewilligung mit Erwerbstätigkeit)  Ci
  Ausweis G (Grenzgängerbewilligung)  G
  Ausweis L (Kurzaufenthaltsbewilligung)  L
  Ausweis F (Vorläufig aufgenommene Ausländerinnen, Ausländer)  F
  Ausweis N (Asylsuchende)  N
  Ausweis S (Schutzbedürftige)  S

  // UPDATE Contact SET permit = 'EU_B' WHERE permit = '0'
  // UPDATE Contact SET permit = 'EU_C' WHERE permit = '1'
  // UPDATE Contact SET permit = 'EU_Ci' WHERE permit = '2'
  // UPDATE Contact SET permit = 'EU_G' WHERE permit = '3'

*/

const untranslatedList = [
  {
    label: "Ausweis L EU/EFTA (Kurzaufenthaltsbewilligung)",
    value: "EU_L",
  },
  {
    label: "Ausweis B EU/EFTA (Aufenthaltsbewilligung)",
    value: "EU_B", // war 0
  },
  {
    label: "Ausweis C EU/EFTA (Niederlassungsbewilligung)",
    value: "EU_C", // war 1
  },
  {
    label: "Ausweis C1 EU/EFTA (Aufenthaltsbewilligung mit Erwerbstätigkeit)",
    value: "EU_Ci", // war 2
  },
  {
    label: "Ausweis G EU/EFTA",
    value: "EU_G", // war 3
  },
  {
    label: "Ausweis B (Aufenthaltsbewilligung)",
    value: "B",
  },
  {
    label: "Ausweis C (Niederlassungsbewilligung)",
    value: "C",
  },
  {
    label: "Ausweis Ci (Aufenthaltsbewilligung mit Erwerbstätigkeit)",
    value: "Ci",
  },
  {
    label: "Ausweis G (Grenzgängerbewilligung)",
    value: "G",
  },
  {
    label: "Ausweis L (Kurzaufenthaltsbewilligung)",
    value: "L",
  },
  {
    label: "Ausweis F (Vorläufig aufgenommene Ausländerinnen, Ausländer)",
    value: "F",
  },
  {
    label: "Ausweis N (Asylsuchende)",
    value: "N",
  },
  {
    label: "Ausweis S (Schutzbedürftige)",
    value: "S",
  },
];

export const getResidencePermits = createGetList(untranslatedList);
export const getResidencePermitsLabel = createGetLabel(untranslatedList);

export const useResidencePermits = createUtilityHook(untranslatedList);
