import { PropertyContentMediaShape } from "entities/propertyContentMedia/types";
import { PropertyAttachment } from "./propertyAttachment.model";

export const DOCUMENT_TYPES = [
  "document",
  "plan",
  "sales-brochure",
  "land-register-extract",
];

// TODO: Unit test this function
export const mapMediaToCustomShape = (
  media: PropertyContentMediaShape
): PropertyAttachment => ({
  id: media._embedded.media.id.toString(),
  title: media.title || media._embedded.media.originalFilename || "unknown",
  mediaResource: media._embedded.media,
});

export const filterContentMediaDocuments = (
  propertyContentMedia: PropertyContentMediaShape[]
): PropertyContentMediaShape[] =>
  propertyContentMedia.filter((media) => DOCUMENT_TYPES.includes(media.type));
