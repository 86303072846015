import { useState } from "react";
import type { PropertyAttachment } from "./propertyAttachment.model";
import { usePropertyDocuments } from "./usePropertyDocuments";
import { useStaticPropertyAttachments } from "./useStaticPropertyAttachments";

interface UsePropertyAttachmentsProps {
  selectedPropertyId?: string;
  initiallySelectedPropertyAttachments: PropertyAttachment[];
  onSelectionChange?: (selection: PropertyAttachment[]) => void;
}

export const usePropertyAttachments = ({
  selectedPropertyId,
  initiallySelectedPropertyAttachments: initiallySelected,
  onSelectionChange,
}: UsePropertyAttachmentsProps) => {
  const [lastSelectedAttachment, setLastSelectedAttachment] = useState<
    PropertyAttachment | undefined
  >(undefined);

  const { documents, isLoading } = usePropertyDocuments({
    propertyId: selectedPropertyId,
  });

  const [selectedAttachments, setSelectedAttachments] =
    useState<PropertyAttachment[]>(initiallySelected);

  const { staticAttachments } = useStaticPropertyAttachments({
    selectedAttachments,
  });

  const handleAttachmentSelectionToggle = (
    attachment: PropertyAttachment,
    isCurrentlyChecked: boolean
  ) => {
    if (!isCurrentlyChecked) {
      setSelectedAttachments((currentAttachments) => {
        const updatedAttachments = [...currentAttachments, attachment];
        onSelectionChange && onSelectionChange(updatedAttachments);
        return updatedAttachments;
      });
      setLastSelectedAttachment(attachment);
    } else {
      setSelectedAttachments((currentAttachments) => {
        const updatedAttachments = currentAttachments.filter(
          (item) => item.id !== attachment.id
        );
        onSelectionChange && onSelectionChange(updatedAttachments);
        return updatedAttachments;
      });
      setLastSelectedAttachment(undefined);
    }
  };

  const handleSelectedAttachmentUpdate = (
    updatedAttachment: PropertyAttachment
  ) => {
    setSelectedAttachments((currentAttachments) => {
      const updatedAttachments = currentAttachments.map((item) =>
        item.id === updatedAttachment.id ? updatedAttachment : item
      );
      onSelectionChange && onSelectionChange(updatedAttachments);
      return updatedAttachments;
    });
    setLastSelectedAttachment(updatedAttachment);
  };

  const handleAttachmentHover = (hoveredAttachment: PropertyAttachment) => {
    if (
      selectedAttachments.map((item) => item.id).includes(hoveredAttachment.id)
    ) {
      setLastSelectedAttachment(hoveredAttachment);
    }
  };

  return {
    isLoading,
    staticAttachments,
    uploadedPropertyAttachments: documents,
    selectedAttachments,
    handleAttachmentSelectionToggle,
    handleSelectedAttachmentUpdate,
    handleAttachmentHover,
    lastSelectedAttachment,
  };
};
