import { FilterItem, OrderByItem } from "utilities/queryBuilder";
import { cloneDeep } from "lodash";
import { FilterItemWithRememberMe } from "components/miscellaneous/TableListContainerProps";
import { TableListResourceOptions } from "./useTableListResourceOptions";

/** The object containing all the relevan information to build an actual list query (w/ pagination information, filters, orders, etc.) */
export interface TableListQuery {
  page: number;
  pageSize: number;
  filter: FilterItem[];
  orderBy: OrderByItem[];
  context?: string;
}

export type UserSortToQuerySortFn = (sortConf: {
  key: OrderByItem["field"];
  dir: OrderByItem["direction"];
}) => OrderByItem[];

export type UserFilterToQueryFilterFn = (
  inputFilter: FilterItemWithRememberMe[],
  filterName: string,
  filterValue: any
) => FilterItemWithRememberMe[];

interface UseTableListQueryArgs {
  /** The resource options to use for the query, returned by useTableListResourceOptions */
  resourceOptions: TableListResourceOptions;
  /** The default order */
  defaultOrder?: OrderByItem[];
  /** The default order to use while a search term is provided */
  searchOrder?: OrderByItem[];
  /** Forced filters will always get added to the generated filters. Can be used for specific features who filter by a type i.e. */
  forcedFilter?: FilterItem[];
  /** Pass a context to the backend if needed for different handling as defualt list */
  context?: string;
  /* The function convert the filters that the user selected into actual FilterItems. */
  userFilterToQueryFilter?: UserFilterToQueryFilterFn;
  /* The function convert the sort that the user selected into actual OrderByItems. */
  userSortToQuerySort?: UserSortToQuerySortFn;
}

/**
 * Creates a TableListQuery based on the TableListResourceOptions (provided by useTableListResourceOptions)
 * @param options The options to use for the query
 * @returns TableListQuery
 */
function useTableListQuery({
  resourceOptions: {
    searchField,
    searchTerm,
    sortDir,
    sortKey,
    page,
    pageSize,
    activeFilterPreset,
  },
  forcedFilter,
  context,
  defaultOrder = [
    {
      type: "field",
      field: "created",
      direction: "desc",
    },
  ],
  searchOrder = [
    {
      type: "field",
      field: "created",
      direction: "desc",
    },
  ],
  userFilterToQueryFilter = (inputFilter) => {
    return inputFilter;
  },
  userSortToQuerySort = (opts) => {
    return [
      {
        type: "customorderby",
        field: opts.key,
        alias: "",
        direction: opts.dir,
      },
    ];
  },
}: UseTableListQueryArgs) {
  // stop when the filter form hasn't been fetched yet (it should have a value or be null)
  let newFilter: FilterItem[] = [];

  // build up custom filter if there is an active FilterPreset
  if (activeFilterPreset?.preset) {
    // clonedeep so the userFilterToQueryFilter mutations won't modify the data stored in redux
    const activeFormCopy = cloneDeep(activeFilterPreset);

    Object.entries(activeFormCopy.preset).forEach((aPresetAr) => {
      const value = aPresetAr[1];
      newFilter = userFilterToQueryFilter(newFilter, aPresetAr[0], value);
    });
  }

  // add current search term to the filter
  if (searchTerm) {
    const newSearchFilter: FilterItem = {
      type: "search",
      value: searchTerm,
    };
    if (searchField && searchField !== "all") {
      newSearchFilter.field = searchField;
    }
    newFilter.push(newSearchFilter);
  }

  // add forced filters to the filter
  if (forcedFilter) {
    newFilter = [...newFilter, ...forcedFilter];
  }

  // build sort direction
  const sortOrder =
    sortKey && sortDir // TODO: snc needs doubleckeck
      ? userSortToQuerySort({
          key: sortKey,
          dir: sortDir,
        })
      : searchTerm
      ? searchOrder
      : defaultOrder;

  const query: TableListQuery = {
    page,
    pageSize,
    context,
    filter: newFilter,
    orderBy: sortOrder,
  };

  return [query] as const;
}

export default useTableListQuery;
