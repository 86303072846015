import { ReactNode } from "react";

interface TableListCardBodyProps {
  isEnabled: boolean;
  children: ReactNode;
}

function TableListCardBody({ children, isEnabled }: TableListCardBodyProps) {
  return <div className={`${isEnabled ? "tw-p-4" : ""}`}>{children}</div>;
}

export default TableListCardBody;
