import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseEventCreator, { EventResourceTypes } from "./baseEventCreator";

const name = "quickMessage" as const;
const pluralName = "quickMessages" as const;

export const { types, actions, rootReducer }: EventResourceTypes =
  createResource(
    baseEventCreator({
      name,
      pluralName,
      apiContext: "quickmessage-list",
      forcedFilter: [
        {
          type: "in",
          field: "type",
          values: ["message-inbound", "message-inbound-property-inquiry"],
        },
        {
          type: "in",
          field: "status",
          values: ["fresh", "seen"],
        },
        {
          type: "orx",
          conditions: [
            {
              type: "isnull",
              field: "doneReason",
            },
            {
              type: "neq",
              field: "doneReason",
              value: "completed",
            },
          ],
        },
      ],
      forcedOrderBy: [
        {
          type: "field",
          field: "start",
          direction: "desc",
        },
      ],
    })
  );

const quickMessageResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const quickMessageResource = quickMessageResourceCreator.resource;
export const useQuickMessage = quickMessageResourceCreator.createHook();
