import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";
import {
  BASE_DATA_PROJECT,
  PROJECT_PUBLICATION_PROJECT,
  SINGLE_PUBLICATION_PROJECT,
} from "utilities/propertyTypes";

const baseList = [
  {
    value: "general",
    label: "General", // Allgemein
  },
  {
    value: "property-requirements",
    label: "Property requirements", // Auflagen
  },
  {
    value: "equipment",
    label: "Equipment", // Ausstattung
  },
  {
    value: "building-specifications",
    label: "Building specifications", // Baubeschrieb
  },
  {
    value: "flooring",
    label: "Flooring", // Bodenbeläge
  },
  {
    value: "location",
    label: "Location description", // Lage
  },
  {
    value: "macro-locality",
    label: "macro-locality", // ?
  },
  {
    value: "property-description",
    label: "Property description", // Objektbeschreibung
  },
  {
    value: "room-layout",
    label: "Room layout", // Raumaufteilung
  },
  {
    value: "surroundings",
    label: "Surroundings", // Umgebung
  },
  {
    value: "terms-of-sale",
    label: "Terms of sale", // Verkaufsbedingung
  },
  // {
  //   value: 'locality',
  //   label: 'Locality',
  // },
  // {
  //   value: 'features',
  //   label: 'Features',
  // },
];

function getProjectListItem() {
  return [
    {
      value: "sustainability",
      label: "Sustainability", // Nachhaltig
    },
    {
      value: "offer",
      label: "Offer", // Angebot
    },
  ];
}

function listConstructor(propertyType?: string) {
  if (
    propertyType === BASE_DATA_PROJECT ||
    propertyType === SINGLE_PUBLICATION_PROJECT ||
    propertyType === PROJECT_PUBLICATION_PROJECT
  ) {
    const projectListItems = getProjectListItem();
    return baseList.concat(projectListItems);
  }
  return baseList;
}

export const getPropertyContentDescriptionTypes = createGetList(
  listConstructor,
  {
    sort: "a-z",
  }
);

export const getPropertyContentDescriptionTypesLabel =
  createGetLabel(listConstructor);

export const usePropertyContentDescriptionTypes = createUtilityHook(
  listConstructor,
  {
    sort: "a-z",
  }
);
