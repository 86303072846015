import { useTranslation } from "react-i18next";
import { FilterItem } from "utilities/queryBuilder";
import useTableListBulkSelect from "@casasoft/styleguide/hooks/useTableListBulkSelect";
import { FormModal } from "@casasoft/styleguide/components/form-modal";
import PropertyListContainer from "./features/property-list/PropertyListContainer";
import { BASE_DATA_UNIT } from "utilities/propertyTypes";

interface PropertyChooseModalProps {
  isOpen?: boolean;

  /** the second argument is ONLY supplied for "single" select!!! */
  onApply?: (selected: string[], item?: any[]) => void;
  onCancel?: () => void;

  select?: "single" | "multiple";
  filter?: FilterItem[];
  listContext?: string;
  propertyType?: string;
}

function PropertyChooseModal({
  isOpen = false,
  onApply,
  onCancel,
  select = "single",
  filter = [],
  listContext = "properties-modal",
  propertyType,
}: PropertyChooseModalProps) {
  const { t } = useTranslation();

  const bulkSelectControl = useTableListBulkSelect({
    bulkActionNodes: [
      {
        label: t("Save"),
        action: (items) => {
          onApply?.(items.map((item) => `${item.id}`));
        },
      },
    ],
  });

  const getHeader = () => {
    if (propertyType === BASE_DATA_UNIT) {
      if (select === "multiple") {
        return t("Choose units");
      }
      return t("Choose Unit");
    }
    if (select === "multiple") return t("Choose Properties");
    return t("Choose property");
  };

  const presetName = propertyType
    ? `properties-modal-${propertyType}`
    : "properties-modal-all-types";
  return (
    <FormModal
      size="lg"
      isOpen={isOpen}
      onCancel={() => {
        onCancel?.();
      }}
      header={getHeader()}
      form={() => (
        <form>
          <PropertyListContainer
            isModal
            forcedFilter={filter}
            propertyType={propertyType}
            presetName={presetName}
            apiContext={"properties-modal"}
            listContext={listContext}
            bulkSelectControl={
              select === "multiple" ? bulkSelectControl : undefined
            }
            mainActionNodes={[]}
            onItemClick={(item: any) => {
              if (select === "multiple") {
                bulkSelectControl.toggleItem(item);
              } else {
                onApply?.([item.id], [item]);
              }
            }}
          />
        </form>
      )}
    />
  );
}

export default PropertyChooseModal;
