import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import createBaseActions from "entities/createBaseActions";
import Config from "config";
import FaqState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: FaqState, actions?: any) => FaqState;
}

export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "faq",
  pluralName: "faqs",
  url: (param: any, context: any) => {
    if (context?.context?.id && context?.context?.lang) {
      return `${Config.api2Url}/company/${Config.customerKey}/zendesk/articles/:id?locale=${context.context.lang}`;
    }
    if (context?.context?.section) {
      return `${Config.api2Url}/company/${Config.customerKey}/zendesk/sections/${context.context.section}/articles`;
    }
    return `${Config.api2Url}/company/${Config.customerKey}/zendesk/articles/search`;
  },
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "WILL_OVERWRITE",
    }),
    fetch: {
      ...createBaseActions({
        embeddedName: "WILL_OVERWRITE",
      }).fetch,
      transformResponse: (res: any) => {
        if (res.body && res.body.items) {
          return { res, body: res.body.items };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        const cnxtOpts = Object.assign(
          {
            pageSize: 10,
            filter: [],
            orderBy: [],
            apiContext: "faq-list",
          },
          contextOpts
        );
        const opts = Object.assign(
          {
            pageSize: 10,
            orderBy: [
              {
                type: "field",
                field: "created",
                direction: "desc",
              },
            ],
            filter: [] as any[],
          },
          {
            pageSize: cnxtOpts.pageSize,
            orderBy: [...cnxtOpts.orderBy],
            filter: [...cnxtOpts.filter],
            apiContext: cnxtOpts.apiContext,
          }
        );
        const query: { [key: string]: any } = {};
        const params: { [keys: string]: any } = {
          per_page: opts.pageSize,
        };

        const langFilter = opts.filter.find((x) => x.field === "lang");
        if (langFilter) {
          params.lang = langFilter.value;
        }

        params.orderby = "date";
        params.order = "desc";

        if (opts.orderBy && opts.orderBy[0] && opts.orderBy[0].field) {
          params.orderby = opts.orderBy[0].field;
          params.order = opts.orderBy[0].direction;
        }

        if (contextOpts.page) {
          params.page = contextOpts.page;
        }
        // pagesize = page_size
        const pageSizeFilter = opts.pageSize;
        if (pageSizeFilter) {
          query["page[size]"] = pageSizeFilter;
        }
        // page number
        if (contextOpts.page) {
          query.page = contextOpts.page;
        }
        // company
        if (contextOpts.filterString) {
          const urlParams = new URLSearchParams(contextOpts.filterString);

          query.section = urlParams.get("section");
          query.locale = urlParams.get("lang");
          query.sort_by = urlParams.get("sort_by");
          query.sort_order = urlParams.get("sort_order");
        }

        return query;
      },
    },
  },
});

const faqResourceCreator = createResourceHelper({
  actions,
  name: "faq",
  pluralName: "faqs",
});

export const faqResource = faqResourceCreator.resource;
export const useFaq = faqResourceCreator.createHook();
