import { ModalFooter } from "@casasoft/styleguide/components/modal";

import Field from "@casasoft/styleguide/components/formElements/Field";
import Select from "@casasoft/styleguide/components/formElements/Select";
import DateField from "@casasoft/styleguide/components/formElements/DateField";
import { getLanguages } from "utilities/languages";
import csMoment from "utilities/csMoment";
import { Genders } from "utilities";
import { useTranslation } from "react-i18next";
import useForm, { RegisterFields } from "hooks/useForm";
import { SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Button from "@casasoft/styleguide/components/forms/Button";
import { useDynamicContact } from "entities/contact/dynamicStore";
import handleFormModalError, {
  FormModalOnFail,
} from "@casasoft/styleguide/utilities/api-error/handleFormModalError";
import { FetchProfileCasaoneUserShape } from "utilities/api";

interface FormShape {
  displayName: string | null;
  gender: string | number | null;
  lastName: string | null;
  firstName: string | null;
  dob: string | null;
  lang: string | null;
}

interface DashboardCompleteYourProfileEditFormProps {
  contact: FetchProfileCasaoneUserShape["contact"];
  onDone: () => void;
  onFail: FormModalOnFail;
}

function DashboardCompleteYourProfileEditForm({
  contact,
  onDone,
  onFail,
}: DashboardCompleteYourProfileEditFormProps) {
  const { t } = useTranslation();

  const { updateItem: updateDynamicContactsItem } = useDynamicContact({
    previousStateValidateContext: `complete-user-profile-edit-form-${contact.id}`,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isSubmitting },
    reset,
    control,
    getDirtyValues,
    watch,
  } = useForm<FormShape>({
    defaultValues: {
      displayName: contact.displayName || null,
      gender: contact.gender || null,
      lastName: contact.lastName || null,
      firstName: contact.firstName || null,
      dob: contact.dob?.date || null,
      lang: contact.lang || null,
    },
  });

  const onSubmit: SubmitHandler<FormShape> = async (data) => {
    const dirtyData = getDirtyValues();

    try {
      await updateDynamicContactsItem(contact.id, {
        ...dirtyData,
      });

      onDone();
      reset(data);
    } catch (error) {
      handleFormModalError(error, onFail);
    }
  };

  const languageOptions = getLanguages();
  const genderOptions = Genders.getGenders();

  const [firstNameWatch, lastNameWatch, displayNameWatch] = watch([
    "firstName",
    "lastName",
    "displayName",
  ]);

  return (
    <RegisterFields
      fields={{
        displayName: {},
        gender: {},
        lastName: {},
        firstName: {},
        dob: {},
        lang: {},
      }}
      control={control}
      render={(fieldsRenderer) => (
        <form
          className="spinner-fixture"
          onSubmit={handleSubmit(onSubmit)}
          style={
            isSubmitting ? { opacity: 0.5, pointerEvents: "none" } : undefined
          }
        >
          {fieldsRenderer("lang", (formValue, onFormValueChange) => (
            <Select
              nobox
              id="lang"
              label={t("Primary language")}
              placeholder={t("Select a Language")}
              value={formValue || undefined}
              options={languageOptions}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="lang" />,
              }}
            />
          ))}
          {fieldsRenderer("displayName", (formValue, onFormValueChange) => (
            <Field
              nobox
              id="displayName"
              label={t("Display name")}
              value={formValue || ""}
              placeholder={`${firstNameWatch || ""} ${lastNameWatch || ""}`}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="displayName" />,
              }}
            />
          ))}
          {fieldsRenderer("gender", (formValue, onFormValueChange) => (
            <Select
              nobox
              id="gender"
              label={t("Gender")}
              placeholder={t("Select a Gender")}
              value={formValue?.toString() || undefined}
              options={genderOptions}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="gender" />,
              }}
            />
          ))}
          {fieldsRenderer("lastName", (formValue, onFormValueChange) => (
            <Field
              nobox
              id="lastName"
              label={t("Last name")}
              value={formValue || ""}
              onChange={(value) => {
                if (!displayNameWatch) {
                  setValue("displayName", `${firstNameWatch || ""} ${value}`, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                } else if (
                  displayNameWatch ===
                  `${firstNameWatch || ""} ${lastNameWatch || ""}`
                ) {
                  setValue("displayName", `${firstNameWatch || ""} ${value}`, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="lastName" />,
              }}
            />
          ))}
          {fieldsRenderer("firstName", (formValue, onFormValueChange) => (
            <Field
              nobox
              id="firstName"
              label={t("First name")}
              value={formValue || ""}
              onChange={(value) => {
                if (!displayNameWatch || "") {
                  setValue("displayName", `${value} ${lastNameWatch || ""}`, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                } else if (
                  displayNameWatch ===
                  `${firstNameWatch || ""} ${lastNameWatch || ""}`
                ) {
                  setValue("displayName", `${value} ${lastNameWatch || ""}`, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }
                onFormValueChange(value || null);
              }}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="firstName" />,
              }}
            />
          ))}
          {fieldsRenderer("dob", (formValue, onFormValueChange) => (
            <DateField
              nobox
              id="dob"
              showTodayButton={false}
              label={t("Date of birth")}
              value={formValue || ""}
              onChange={(value) => {
                onFormValueChange(value || null);
              }}
              locale={csMoment().locale()}
              message={{
                type: "error",
                text: <ErrorMessage errors={errors} name="dob" />,
              }}
            />
          ))}
          {isDirty && (
            <ModalFooter>
              <Button isPrimary type="submit" buttonValue={t("Save")} />
            </ModalFooter>
          )}
        </form>
      )}
    />
  );
}

export default DashboardCompleteYourProfileEditForm;
