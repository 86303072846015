import { CasaoneResultDescription } from "api/types";
import { GetEventSingleResponse, GetEventSingleQueryParams } from "./types";

const providesTags: CasaoneResultDescription<
  GetEventSingleResponse,
  GetEventSingleQueryParams
> = (result) => {
  if (result) {
    return [{ type: "Event", id: result.id }];
  }

  return ["Event"];
};

export default providesTags;
