import {
  useLazyGetContactIdListQuery,
  useLazyGetContactListQuery,
} from "api/entities/contact";
import useLoadOptionsHelper from "hooks/useLoadOptionsHelper";
import { FilterItem } from "utilities/queryBuilder";

export const useRecipientSelectDataFetch = () => {
  const [getContactIdList] = useLazyGetContactIdListQuery();
  const [getContactListQuery, contactGetResult] = useLazyGetContactListQuery();

  const fetchContactList = async (contactIDs: string[]) => {
    const filter: FilterItem[] = [
      {
        type: "in",
        field: "id",
        values: contactIDs,
      },
    ];
    try {
      const result = await getContactListQuery({
        query: {
          filter: filter,
          orderBy: [],
          pageSize: -1,
          page: 1,
        },
      }).unwrap();
      return result._embedded?.contact || [];
    } catch (error) {
      return [];
    }
  };

  const [allContactItems, loadContactOptionsHelper] = useLoadOptionsHelper({
    notInField: "id",
    fetchIdListFn: async (query) => {
      const entityTypeExclude = ["subsidiary"];

      const entityTypeConditions: FilterItem[] = [
        {
          type: "isnull",
          field: "contactType",
        },
        {
          type: "notin",
          field: "contactType",
          values: entityTypeExclude,
        },
      ];

      const entityTypeFilterItem: FilterItem = {
        type: "orx",
        conditions: entityTypeConditions,
      };

      try {
        const result = await getContactIdList({
          query: {
            ...query,
            filter: [...query.filter, entityTypeFilterItem],
          },
        }).unwrap();
        return result.items.map((contact) => contact.id);
      } catch (error) {
        return [];
      }
    },
    fetchFullListFn: fetchContactList,
  });

  return {
    allContactItems,
    loadContactOptionsHelper,
    contactGetResult,
  };
};
