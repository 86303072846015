import * as Sentry from "@sentry/browser";

import { updateAxiosInstanceToken } from "utilities/axios";
import {
  CO_ACCESS_TOKEN,
  CO_ACCESS_TOKEN_EXPIRE,
  CO_REFRESH_TOKEN,
  CO_REFRESH_TOKEN_EXPIRE,
} from "./constants";

function saveAuthToken({
  accessToken,
  accessTokenExpire,
  refreshToken,
  refreshTokenExpire,
}: {
  accessToken: string;
  accessTokenExpire: number;
  refreshToken: string;
  refreshTokenExpire: number;
}) {
  const accessTokenExpireMs = accessTokenExpire * 1000; // convert to miliseconds

  const extraSlackTime = 20000; // give an extra slack of 20s

  const access_token_expire_int =
    Date.now() + accessTokenExpireMs - extraSlackTime;
  const access_token_expire = `${access_token_expire_int}`;

  if (Date.now() > access_token_expire_int) {
    const error = new Error(`new access_token_expire is in the past`);
    Sentry.captureException(error);
  }

  const refreshTokenExpireMs = refreshTokenExpire * 1000; // convert to miliseconds
  const refresh_token_expire = `${Date.now() + refreshTokenExpireMs}`;
  window.localStorage.setItem(CO_ACCESS_TOKEN_EXPIRE, access_token_expire);
  window.localStorage.setItem(CO_REFRESH_TOKEN_EXPIRE, refresh_token_expire);
  // IMPORTANT: for a "storage" listener in useAuth make sure CO_ACCESS_TOKEN is set AFTER CO_ACCESS_TOKEN_EXPIRE
  window.localStorage.setItem(CO_ACCESS_TOKEN, accessToken);
  window.localStorage.setItem(CO_REFRESH_TOKEN, refreshToken);

  updateAxiosInstanceToken();

  window.localStorage.setItem(
    "CO_REFRESH_TOKEN_EXPIRE_HUMANREADABLE",
    `${new Date(Date.now() + refreshTokenExpireMs).getHours()}:${new Date(
      Date.now() + refreshTokenExpireMs
    ).getMinutes()}:${new Date(Date.now() + refreshTokenExpireMs).getSeconds()}`
  );
  window.localStorage.setItem(
    "CO_ACCESS_TOKEN_EXPIRE_HUMANREADABLE",
    `${new Date(access_token_expire_int).getHours()}:${new Date(
      access_token_expire_int
    ).getMinutes()}:${new Date(access_token_expire_int).getSeconds()}`
  );
}

export default saveAuthToken;
