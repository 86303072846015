import Field from "../forms/Field";
import Select from "../formElements/Select";
import { useTranslation } from "react-i18next";
import { useGetDistinctMediaTagListQuery } from "api/entities/mediaTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/pro-light-svg-icons";

interface MediaChooserFilterSearchProps {
  searchString: string | undefined;
  searchTags: string | undefined;
  onSearchChange?: (value: string) => void;
  onDelaySearchChange?: (value: string) => void;
  onTagsChange: (value: string) => void;
}

export const MediaChooserFilterSearch = ({
  searchString,
  searchTags,
  onSearchChange,
  onDelaySearchChange,
  onTagsChange,
}: MediaChooserFilterSearchProps) => {
  const { t } = useTranslation();
  const getMediaTagListResult = useGetDistinctMediaTagListQuery(undefined);

  return (
    <div className="media-chooser__filter">
      <div className="media-chooser__filterItem">
        <Field
          id="filterSearch"
          value={searchString}
          placeholder={t("Search")}
          onChange={onSearchChange}
          onDelayChange={onDelaySearchChange}
        />
      </div>
      {getMediaTagListResult.data?.mediaTags &&
        getMediaTagListResult.data?.mediaTags?.length > 0 && (
          <div className="media-chooser__filterItem">
            <Select
              isMulti
              placeholder={t("Tags")}
              value={searchTags}
              onChange={onTagsChange}
              options={getMediaTagListResult.data?.mediaTags.map((x: any) => {
                return {
                  value: x.name,
                  label: x.name,
                  type: <FontAwesomeIcon icon={faTag} fixedWidth />,
                };
              })}
            />
          </div>
        )}
    </div>
  );
};
