import { useTranslation } from "react-i18next";
import type {
  RecipientChangeHandler,
  RecipientValues,
} from "../SelectRecipientsContainer";
import type { ContactSelectValueType } from "components/contact/features/contact-select/ContactSelectContainer";
import { getCollapsedSelectInput } from "./CollapsedSelectCustomInput";
import { RecipientSelectContainer } from "../RecipientSelect/RecipientSelectContainer";
import { ErrorMessage } from "@hookform/error-message";
import { type FieldErrors } from "react-hook-form";
import { CollapsedSelectCustomValueRemove } from "./CollapsedSelectCustomValueRemove";

export type CollapsedSelectData = {
  value: ContactSelectValueType[];
  changeHandler: RecipientChangeHandler;
  remainingCount: number;
};

export type CollapsedSelectProps = CollapsedSelectData & {
  className?: string;
  errors: FieldErrors<RecipientValues<"contact">>;
};

export const CollapsedSelect = ({
  value,
  changeHandler,
  errors,
  remainingCount = 0,
  className,
}: CollapsedSelectProps) => {
  const { t } = useTranslation();

  const remainingCountText =
    remainingCount > 0 ? t("+ {{count}} more", { count: remainingCount }) : "";

  return (
    <div className="tw-relative">
      <RecipientSelectContainer
        label={t("To:", { keySeparator: ">", nsSeparator: "|" })}
        onChange={changeHandler}
        value={value}
        className={className}
        selectAsyncOverride={{
          components: {
            Input: getCollapsedSelectInput(remainingCountText),
            MultiValueRemove: CollapsedSelectCustomValueRemove,
          },
        }}
        message={{
          type: "error",
          text: <ErrorMessage errors={errors} name="contact" />,
        }}
      />
    </div>
  );
};
