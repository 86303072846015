import Button from "../forms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BulkActionNodesProps } from "./types";
import { twMerge } from "tailwind-merge";
import { Tooltip } from "../tooltip";
import { Item } from "./TableListItem";

interface TableListBulkActionProps {
  index: number;
  bulkAction: BulkActionNodesProps;
  bulkSelected: Item[];
  isMobileBulk?: boolean;
}

export function TableListBulkAction({
  bulkAction,
  index,
  bulkSelected,
  isMobileBulk,
}: TableListBulkActionProps) {
  return (
    <Tooltip
      key={index}
      content={isMobileBulk ? bulkAction.label : null}
      placement="bottom"
      triggerAsChild
    >
      <Button
        data-testid={"bulk-action-button-" + index}
        key={index}
        isSecondary
        customStyle={twMerge(
          "tw-cs-btn tw-cs-btn-base tw-cs-btn-secondary tw-ml-1",
          !isMobileBulk && index >= 2 && "tw-hidden sm:tw-inline-block"
        )}
        onClick={async (e) => {
          e.preventDefault();
          await bulkAction.action(bulkSelected);
        }}
      >
        {bulkAction.icon && (
          <FontAwesomeIcon icon={bulkAction.icon} fixedWidth />
        )}
        {!isMobileBulk && bulkAction.label}
      </Button>
    </Tooltip>
  );
}
