import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "spouse",
    label: "Married couple",
  },
  {
    value: "roommate",
    label: "Roomates",
  },
  {
    value: "joint-heir",
    label: "Joint heir",
  },
  {
    value: "joint-owner",
    label: "Joint owner",
  },
  {
    value: "joint-investor",
    label: "Joint investor",
  },
  {
    value: "joint-prospect",
    label: "Joint prospect",
  },
  {
    value: "business-partner",
    label: "Business partner",
  },
  {
    value: "partner-in-life",
    label: "Partner in life",
  },
  {
    value: "family-relation",
    label: "Family relation",
  },
];

export const getTypes = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLabel = createGetLabel(untranslatedList);

export const useContactRelationTypes = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
