import {
  CO_ACCESS_TOKEN,
  CO_ACCESS_TOKEN_EXPIRE,
  CO_REFRESH_TOKEN,
  CO_REFRESH_TOKEN_EXPIRE,
} from "./constants";

function getAuthTokens() {
  const accessToken = window.localStorage.getItem(CO_ACCESS_TOKEN) || "";
  const refreshToken = window.localStorage.getItem(CO_REFRESH_TOKEN) || "";
  const accessTokenExpire =
    window.localStorage.getItem(CO_ACCESS_TOKEN_EXPIRE) || "-1";
  const refreshTokenExpire =
    window.localStorage.getItem(CO_REFRESH_TOKEN_EXPIRE) || "-1";

  const accessTokenExpiredDate = parseInt(accessTokenExpire, 10);
  const refreshTokenExpiredDate = parseInt(refreshTokenExpire, 10);

  if (refreshTokenExpiredDate <= Date.now()) {
    return {
      accessToken: "",
      refreshToken: "",
      accessTokenExpire: -1,
      refreshTokenExpire: -1,
    };
  }

  return {
    accessToken,
    refreshToken,
    accessTokenExpire: accessTokenExpiredDate,
    refreshTokenExpire: refreshTokenExpiredDate,
  };
}

export default getAuthTokens;
