import Countries from "i18n-iso-countries";
import { i18n } from "utilities";
import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

// register german language
Countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
// register english language
Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
// register french language
Countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
// register italian language
Countries.registerLocale(require("i18n-iso-countries/langs/it.json"));
// register spanish language
Countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

function getCountryNameList(lang: string = "en") {
  const countryObject = Countries.getNames(lang);
  return Object.keys(countryObject).map((key) => {
    const country = countryObject[key];
    return {
      value: key,
      label: country,
    };
  });
}

const baseList = getCountryNameList("de");

function customCountriesTranslator(
  item: { value: string; label: string }
  // originalTranslator: (arg: string) => string
) {
  const currLangList = getCountryNameList(i18n.language);
  const foundCurrLangItem = currLangList.find(
    (searchItem) => searchItem.value === item.value
  );

  return foundCurrLangItem?.label || item.label;
}

export const getCountries = createGetList(baseList, {
  translator: customCountriesTranslator,
});
export const getCountryLabel = createGetLabel(baseList, {
  translator: customCountriesTranslator,
});

export const useCountries = createUtilityHook(baseList, {
  translator: customCountriesTranslator,
});
