import { GetPhaseListQueryParams, GetPhaseListResponse } from "./types";
import { createProvidesTags } from "api/createEndpoint";

const providesTags = createProvidesTags<
  GetPhaseListResponse,
  GetPhaseListQueryParams
>((result) => {
  if (result) {
    // generate tags for each phase
    const phaseTags = result._embedded.phase.map((item) => ({
      type: "Phase" as const,
      id: item.id,
    }));

    // merge phase tags with static tags
    return [
      ...phaseTags,
      {
        type: "Phase",
        id: "PARTIAL-LIST",
      },
    ];
  }

  return ["Phase"];
});

export default providesTags;
