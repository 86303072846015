import CheckboxNew from "../forms/CheckboxNew";
import { Item } from "./TableListItem";

export interface TableListBulkCheckboxProps {
  item: Item;
  checked: boolean;
  onChange: (item: Item) => void;
  disabled?: boolean;
}

export function TableListBulkCheckbox({
  checked,
  item,
  onChange,
  disabled,
}: TableListBulkCheckboxProps) {
  return (
    <label className="tw-pl-0 tw-w-12 tw-flex tw-py-3 tw-justify-around tw-cursor-pointer">
      <CheckboxNew
        data-testid={`${item.id}`}
        name={`${item.id}`}
        disabled={disabled}
        checked={checked}
        onToggle={() => {
          onChange(item);
        }}
      />
    </label>
  );
}
