import { createResource } from "redux-rest-resource";
import Config from "config";
import PropertyPublisherChannelState from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: PropertyPublisherChannelState,
    actions?: any
  ) => PropertyPublisherChannelState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "propertyPublisherChannel",
  pluralName: "propertyPublisherChannels",
  url: `${Config.apiUrl}/${Config.customerKey}/property-publisher-channel/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
