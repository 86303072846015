export const TOPIC_Job = "8253275511709";
export const TOPIC_Unit = "7092367779101";
export const TOPIC_Logbook = "8253144451997";
export const TOPIC_Media = "7789329369245";
export const TOPIC_Property = "7575081847325";
export const TOPIC_Project = "7092322755741";
export const TOPIC_Publication = "7789338266653";
export const TOPIC_Invoice = "8253224239261";
export const TOPIC_Reporting = "8253145194013";
export const TOPIC_Dashboard = "8253216627229";
export const TOPIC_Message = "8253122646301";
export const TOPIC_Contact = "8253120734365";
export const TOPIC_Template = "8253226905629";
export const TOPIC_Userprofile = "8253218111261"; //category settings
export const TOPIC_Companyprofile = "8253218111261"; //category settings
export const TOPIC_Interface = "8253225738909";
export const TOPIC_User = "8253190687773";
export const TOPIC_Dokumentation = "7789328742685";
export const TOPIC_Uncatecorized = "8253223255325"; //unkategorisiert

/**
 * Get similar topics depending on path or selected topic
 * @param pathname current path
 * @param selectedTopic selected topic
 */
export function getSimilarTopics(
  pathname: string = TOPIC_Dashboard,
  selectedTopic?: string
) {
  const pathnameSplit = pathname.split("/");
  let context = TOPIC_Dashboard;

  if (selectedTopic) {
    context = selectedTopic;
  } else if (!selectedTopic && pathnameSplit?.[1]) {
    let pathRelatedTopic = pathnameSplit[1] || "dash";

    if (pathRelatedTopic === "admin") {
      const adminPath = pathnameSplit?.[2] || "profile";
      switch (adminPath) {
        case "user":
          pathRelatedTopic = "user";
          break;
        case "publisher":
          pathRelatedTopic = "publisher";
          break;
        case "template":
          pathRelatedTopic = "template";
          break;
        case "profile":
          pathRelatedTopic = "userprofile";
          break;
        case "company":
          pathRelatedTopic = "companyprofile";
          break;
        case "invoices":
          pathRelatedTopic = "invoices";
          break;
        default:
          pathRelatedTopic = "uncategorized";
          break;
      }
    }

    switch (pathRelatedTopic) {
      case "message":
        context = TOPIC_Message;
        break;
      case "job":
        context = TOPIC_Job;
        break;
      case "contact":
        context = TOPIC_Contact;
        break;
      case "unit":
        context = TOPIC_Unit;
        break;
      case "event":
        context = TOPIC_Logbook;
        break;
      case "media":
        context = TOPIC_Media;
        break;
      case "property":
        context = TOPIC_Property;
        break;
      case "project":
        context = TOPIC_Project;
        break;
      case "single-publication-property":
        context = TOPIC_Publication;
        break;
      case "project-publication-property":
        context = TOPIC_Publication;
        break;
      case "single-publication-unit":
        context = TOPIC_Publication;
        break;
      case "project-publication-unit":
        context = TOPIC_Publication;
        break;
      case "single-publication-project":
        context = TOPIC_Publication;
        break;
      case "project-publication-project":
        context = TOPIC_Publication;
        break;
      case "userprofile":
        context = TOPIC_Userprofile;
        break;
      case "companyprofile":
        context = TOPIC_Companyprofile;
        break;
      case "invoices":
        context = TOPIC_Invoice;
        break;
      case "reports":
        context = TOPIC_Reporting;
        break;
      case "user":
        context = TOPIC_User;
        break;
      case "publisher":
        context = TOPIC_Interface;
        break;
      case "template":
        context = TOPIC_Template;
        break;
      case "uncategorized":
        context = TOPIC_Uncatecorized;
        break;
      case "dash":
      default:
        context = TOPIC_Dashboard;
        break;
    }
  }

  switch (context) {
    case TOPIC_Property:
      return {
        activeTopic: context,
        related: [
          TOPIC_Unit,
          TOPIC_Project,
          TOPIC_Publication,
          TOPIC_Message,
          TOPIC_Contact,
          TOPIC_Dokumentation,
        ],
      };
    case TOPIC_Unit:
      return {
        activeTopic: context,
        related: [
          TOPIC_Project,
          TOPIC_Property,
          TOPIC_Publication,
          TOPIC_Message,
          TOPIC_Contact,
        ],
      };
    case TOPIC_Project:
      return {
        activeTopic: context,
        related: [
          TOPIC_Property,
          TOPIC_Unit,
          TOPIC_Publication,
          TOPIC_Message,
          TOPIC_Contact,
          TOPIC_Dokumentation,
        ],
      };
    case TOPIC_Publication:
      return {
        activeTopic: context,
        related: [
          TOPIC_Property,
          TOPIC_Unit,
          TOPIC_Project,
          TOPIC_Message,
          TOPIC_Contact,
        ],
      };
    case TOPIC_Contact:
      return {
        activeTopic: context,
        related: [TOPIC_Property, TOPIC_Message, TOPIC_Userprofile],
      };
    case TOPIC_Message:
      return {
        activeTopic: context,
        related: [TOPIC_Contact, TOPIC_Property, TOPIC_Template],
      };
    case TOPIC_Logbook:
      return {
        activeTopic: context,
        related: [
          TOPIC_Property,
          TOPIC_Message,
          TOPIC_Contact,
          TOPIC_Reporting,
        ],
      };
    case TOPIC_Reporting:
      return {
        activeTopic: context,
        related: [
          TOPIC_Property,
          TOPIC_Message,
          TOPIC_Contact,
          TOPIC_Unit,
          TOPIC_Project,
        ],
      };
    case TOPIC_Media:
      return {
        activeTopic: context,
        related: [TOPIC_Property],
      };
    case TOPIC_Job:
      return {
        activeTopic: context,
        related: [TOPIC_Contact],
      };
    case TOPIC_User:
      return {
        activeTopic: context,
        related: [TOPIC_Userprofile, TOPIC_Contact],
      };
    case TOPIC_Interface:
      return {
        activeTopic: context,
        related: [TOPIC_Userprofile],
      };
    case TOPIC_Template:
      return {
        activeTopic: context,
        related: [TOPIC_Message, TOPIC_Contact],
      };
    case TOPIC_Uncatecorized:
      return {
        activeTopic: context,
        related: [TOPIC_Userprofile, TOPIC_Property],
      };
    case TOPIC_Invoice:
      return {
        activeTopic: context,
        related: [],
      };
    case TOPIC_Userprofile:
      return {
        activeTopic: context,
        related: [
          TOPIC_Message,
          TOPIC_Contact,
          TOPIC_Property,
          TOPIC_Publication,
        ],
      };
    case TOPIC_Dashboard:
    default:
      return {
        activeTopic: context,
        related: [
          TOPIC_Message,
          TOPIC_Contact,
          TOPIC_Publication,
          TOPIC_Property,
          TOPIC_Unit,
        ],
      };
  }
}
