import { GetPhaseListResponse } from "api/entities/phase/endpoints/list/types";
import { getPhaseLabel } from "./PhaseExcerpt";
interface PhaseOptionsExcerptProps {
  value: string;
  label: string;
  color?: string;
  rank: number;
  previousPhase?: GetPhaseListResponse["_embedded"]["phase"][number];
  size?: "lg" | "sm";
}

const PhaseOptionsExcerpt = ({
  value,
  label,
  color,
  rank,
  previousPhase,
  size = "sm",
}: PhaseOptionsExcerptProps) => {
  const previousPhaseLabel = getPhaseLabel(previousPhase);
  return (
    <div>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "0.8rem",
          height: "0.8rem",
          border: `2px solid ${color || "#e3e3e3"}`,
          borderRadius: "100%",
          marginRight: size === "sm" ? "0.25rem" : "0.5rem",
          top: size === "sm" ? "-0.15rem" : undefined,
          fontSize: ".5rem",
          textAlign: "center",
          whiteSpace: "nowrap",
          lineHeight: 1.2,
        }}
      >
        <strong>{rank}</strong>
      </div>
      {previousPhase?.id !== value && previousPhaseLabel && (
        <>
          <del>{previousPhaseLabel}</del>
          <span> → </span>
        </>
      )}
      {label}
    </div>
  );
};

export default PhaseOptionsExcerpt;
