import { useCallback, useEffect, useState } from "react";

/** A simple hook which conditionally calls the method passed as an argument ONLY on request, through calling the callConditionalEffect returned by this hook. */
function useConditionalEffect<T1 extends () => any>(
  method?: T1,
  additionalConditon: boolean | (() => boolean) = true
) {
  const [shouldCallUseEffectBool, setShouldCallUseEffectBool] = useState(false);

  const additionalBool =
    typeof additionalConditon === "boolean"
      ? additionalConditon
      : additionalConditon();

  /** Triggers the conditional useEffect */
  const callConditionalEffect = useCallback(() => {
    setShouldCallUseEffectBool(true);
  }, []);

  /** Conditional useEffect */
  useEffect(() => {
    if (shouldCallUseEffectBool && additionalBool) {
      setShouldCallUseEffectBool(false);

      method?.();
    }
  }, [shouldCallUseEffectBool, method, additionalBool]);

  const typedReturn: [typeof callConditionalEffect] = [callConditionalEffect];
  return typedReturn;
}

export default useConditionalEffect;
