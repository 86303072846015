import { CleanSelectOption } from "@casasoft/styleguide/components/formElements/Select";
import { TemplateShape } from "entities/template/types";

export const mapMessageTemplates = (
  templates: TemplateShape[]
): CleanSelectOption[] =>
  templates
    .map((template) => ({
      label: template.name,
      value: template.id,
      key: template.id,
    }))
    .filter((template) => !!template.label && !!template.value);
