import { ReactNode, Children } from "react";

interface ModalFooterProps {
  children: ReactNode;
  className?: string;
}

function ModalFooter({ children, className }: ModalFooterProps) {
  return (
    <div
      className={`tw-cs-modal-footer tw-p-4 tw-clear-both tw-border-0 tw-border-t tw-border-solid tw-border-t-cs-shade-300 tw-flex tw-items-center ${
        className || Children.count(children) > 1
          ? "tw-justify-between"
          : "tw-justify-end"
      }`}
    >
      {children}
    </div>
  );
}

export default ModalFooter;
