import { ReactSelectMultiValueRemove } from "@casasoft/styleguide/components/formElements/helpers/ReactSelectComponents";
import { GroupBase, MultiValueRemoveProps } from "reactSelectNew";

export function CollapsedSelectCustomValueRemove<
  OptionType = unknown,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({ ...props }: MultiValueRemoveProps<OptionType, IsMulti, GroupType>) {
  // we have this custom component to prevent the click event from bubbling up to the parent
  const handleInnerClick = (e: any) => {
    props.innerProps.onClick?.(e);
    e.stopPropagation();
  };

  const updatedProps = {
    ...props,
    innerProps: { ...props.innerProps, onClick: handleInnerClick },
  };

  return <ReactSelectMultiValueRemove {...updatedProps} />;
}
