type MarketingMethod = "buy" | "rent";

function isMarketingMethod(value?: string | null): value is MarketingMethod {
  if (value) {
    return ["buy", "rent"].includes(value);
  }
  return false;
}

export default isMarketingMethod;
