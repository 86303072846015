import { MediaChooserFilterSearch } from "../MediaChooserFilterSearch";
import type { PropertyAttachment } from "./helpers/propertyAttachment.model";
import { PropertyListReducedShape } from "api/entities/property/endpoints/list-reduced/types";
import { MediaChooserLoadingSpinner } from "../MediaChooserLoadingSpinner/MediaChooserLoadingSpinner";
import { PropertyAttachmentSidebar } from "./PropertyAttachmentSidebar/PropertyAttachmentSidebar";
import { usePropertyAttachments } from "./helpers/usePropertyAttachments";
import { usePropertyAttachmentsFilter } from "./helpers/usePropertyAttachmentsFilter";
import { PropertyAttachmentList } from "./PropertyAttachmentList";
import { PropertyAttachmentSeparator } from "./PropertyAttachmentSeparator";
import { useTranslation } from "react-i18next";

export interface MediaChooserPropertyAttachmentsProps {
  initiallySelected: PropertyAttachment[] | undefined;
  selectedProperty: PropertyListReducedShape | undefined;
  onSelectionChange?: (selectedAttachments: PropertyAttachment[]) => void;
}

export const MediaChooserPropertyAttachments = ({
  selectedProperty,
  onSelectionChange,
  initiallySelected = [],
}: MediaChooserPropertyAttachmentsProps) => {
  const { t } = useTranslation();
  const {
    staticAttachments,
    uploadedPropertyAttachments,
    selectedAttachments,
    lastSelectedAttachment,
    handleAttachmentSelectionToggle,
    handleSelectedAttachmentUpdate,
    handleAttachmentHover,
    isLoading,
  } = usePropertyAttachments({
    selectedPropertyId: selectedProperty?.id,
    initiallySelectedPropertyAttachments: initiallySelected,
    onSelectionChange,
  });
  const { searchString, handleSearch, filteredPropertyAttachments } =
    usePropertyAttachmentsFilter({
      propertyAttachments: uploadedPropertyAttachments,
    });

  return (
    <div className="tw-flex tw-w-full">
      <div className="tw-grow">
        <MediaChooserFilterSearch
          searchString={searchString}
          onSearchChange={handleSearch}
          searchTags={""}
          onTagsChange={() => undefined}
        />

        <div className="tw-w-full">
          <PropertyAttachmentList
            list={staticAttachments}
            selectedAttachments={selectedAttachments}
            lastSelectedAttachment={lastSelectedAttachment}
            onAttachmentToggle={handleAttachmentSelectionToggle}
            onAattachmentHover={handleAttachmentHover}
          />
          <PropertyAttachmentSeparator
            label={t("Add from property")}
            show={filteredPropertyAttachments.length > 0}
          />
          <PropertyAttachmentList
            list={filteredPropertyAttachments}
            selectedAttachments={selectedAttachments}
            lastSelectedAttachment={lastSelectedAttachment}
            onAttachmentToggle={handleAttachmentSelectionToggle}
            onAattachmentHover={handleAttachmentHover}
          />
          {isLoading && <MediaChooserLoadingSpinner className="tw-m-3" />}
        </div>
      </div>
      <PropertyAttachmentSidebar
        lastSelectedAttachment={lastSelectedAttachment}
        onChange={handleSelectedAttachmentUpdate}
      />
    </div>
  );
};
