import * as React from "react";

interface FieldInputGroupProps {
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  prefixDropdown?: boolean;
  children?: React.ReactNode;
}

const FieldInputGroup = ({
  prefix = "",
  prefixDropdown = false,
  suffix = "",
  children = null,
}: FieldInputGroupProps) => {
  return (
    <div className="cs-input-group">
      {prefix ? (
        <span className={`cs-input-group-${prefixDropdown ? "btn" : "addon"}`}>
          {prefix}
        </span>
      ) : (
        ""
      )}
      {children}
      {suffix ? <span className="cs-input-group-addon">{suffix}</span> : ""}
    </div>
  );
};

export default FieldInputGroup;
