import { GetPropertyTagListQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<GetPropertyTagListQueryParams>(() => {
  return {
    url: "get-distinct-property-tags",
    method: "GET",
  };
});

export default query;
