import { CasaoneApiTagTypes, CasaoneResultDescription } from "api/types";
import { IaziCommonDataRequestBody, IaziCommonDataResponse } from "./types";

const providesTags: CasaoneResultDescription<
  IaziCommonDataResponse,
  IaziCommonDataRequestBody,
  CasaoneApiTagTypes
> = (result) => {
  if (result) {
    return [{ type: "IaziCommonData", id: "PARTIAL-LIST" }];
  }

  return ["IaziCommonData"];
};

export default providesTags;
