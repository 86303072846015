import { GetPhaseListResponse } from "api/entities/phase/endpoints/list/types";

const getPhaseByEvent = ({
  baseType,
  eventType,
  phaseItems,
}: {
  baseType?: string;
  eventType?: string | null;
  phaseItems: GetPhaseListResponse["_embedded"]["phase"];
}) => {
  const foundPhaseWithRule = phaseItems.find((item) => {
    const foundRule = item._embedded.phaseEventTypeRules.find((elem) => {
      // base type comparison
      if (elem.baseType === baseType && !eventType) {
        return true;
      }
      // custom type comparison
      if (
        !elem.baseType &&
        elem._embedded?.eventType.baseType === baseType &&
        elem._embedded?.eventType.id === eventType
      ) {
        return true;
      }
      return false;
    });
    if (foundRule) {
      return true;
    }
    return false;
  });
  return foundPhaseWithRule?.id;
};

export default getPhaseByEvent;
