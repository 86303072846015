import createBoardingInstance from "@casasoft/styleguide/utilities/tours/createBoardingInstance";
import { history } from "config/history";
import { CsHistoryChangeEventType } from "index";
import completeAndBackToOverview from "tours/completeAndBackToOverview";
import { waitForElm } from "tours/utilities";
import resourceGrabber from "utilities/resourceGrabber";

export const USER_CREATE_TOUR = "usr-create-tour";

export const USER_TOUR_USER_SETTINGS_ITEM = "usr-tr-stgs-itm";
export const USER_TOUR_USER_APP_CONTENT = "usr-app-cntnt";
export const USER_TOUR_USER_CREATE_BTN = "usr-tr-crt-btn";
export const USER_TOUR_USER_CREATE_FIELD_NAME = "usr-tr-crt-fld-nm";
export const USER_TOUR_USER_CREATE_FIELD_EMAIL = "usr-tr-crt-fld-eml";
export const USER_TOUR_USER_CREATE_FIELD_ROLE = "usr-tr-crt-fld-rl";
export const USER_TOUR_USER_CREATE_FIELD_LANG = "usr-tr-crt-fld-lng";
export const USER_TOUR_USER_CREATE_FIELD_INVITE = "usr-tr-crt-fld-invt";

export function subscribeUserCreateOnboarding(t: (term: string) => string) {
  const [boarding, unsubscribe] = createBoardingInstance(USER_CREATE_TOUR, t, {
    allowClose: false,
    padding: 5,
    strictClickHandling: "block-all", // we are doing the clicking for them
  });

  const historyChangeHandler = async (e: Event) => {
    if (!boarding.isActivated) {
      return;
    }

    const myEv = e as CsHistoryChangeEventType;

    // step 8 = enter user date step
    if (boarding.currentStep === 8) {
      if (myEv.detail.location.pathname.startsWith("/admin/user/list/item/")) {
        await waitForElm(".app__contentminilist__list");
        boarding.next();
      }
    }
  };

  document.addEventListener("CsHistoryChangeEvent", historyChangeHandler);
  const unsubscribeHistory = () => {
    document.removeEventListener("CsHistoryChangeEvent", historyChangeHandler);
  };

  boarding.defineSteps([
    {
      element: `[data-boarding~="${USER_TOUR_USER_SETTINGS_ITEM}"]`,
      popover: {
        title: t("usersCreate.intro.title"),
        description: t("usersCreate.intro.desc"),
        prefferedSide: "left",
        alignment: "start",
      },
      strictClickHandling: true,
      prepareElement: async () => {
        boarding.preventMove();
        history.push("/admin/user/list");
        await waitForElm(`[data-boarding~="${USER_TOUR_USER_SETTINGS_ITEM}"]`);
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto`,
      popover: {
        title: t("usersCreate.list.title"),
        description: t("usersCreate.list.desc"),
        prefferedSide: "bottom",
      },
      scrollIntoViewOptions: "no-scroll",
      padding: 2,
      prepareElement: async () => {
        boarding.preventMove();
        await waitForElm(
          `[data-boarding~="${USER_TOUR_USER_APP_CONTENT}"] .app__content__container .tw-overflow-x-auto`
        );
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_CREATE_BTN}"]`,
      popover: {
        title: t("usersCreate.createBtn.title"),
        description: t("usersCreate.createBtn.desc"),
        prefferedSide: "bottom",
      },
      scrollIntoViewOptions: "no-scroll",
      prepareElement: async () => {
        boarding.preventMove();
        history.push("/admin/user/list");
        await waitForElm(`[data-boarding~="${USER_TOUR_USER_CREATE_BTN}"]`);
        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_CREATE_FIELD_NAME}"]`,
      popover: {
        title: t("usersCreate.fullName.title"),
        description: t("usersCreate.fullName.desc"),
        prefferedSide: "left",
      },
      onPrevious: () => {
        // close modal
        const closeBtn = document.querySelector<HTMLElement>(
          `div[role="dialog"][data-state="open"] > div > button[type="button"]`
        );

        closeBtn?.click();
      },
      prepareElement: async (initiator) => {
        if (initiator !== "prev") {
          // open modal
          const createBtn = document.querySelector<HTMLElement>(
            `[data-boarding~="${USER_TOUR_USER_CREATE_BTN}"]`
          );
          createBtn?.click();
        }

        boarding.preventMove();
        const createFieldName = await waitForElm(
          `[data-boarding~="${USER_TOUR_USER_CREATE_FIELD_NAME}"]`
        );
        createFieldName.blur();

        boarding.continue();
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_CREATE_FIELD_EMAIL}"]`,
      popover: {
        title: t("usersCreate.eMail.title"),
        description: t("usersCreate.eMail.desc"),
        prefferedSide: "left",
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_CREATE_FIELD_ROLE}"] .Select__control`,
      popover: {
        title: t("usersCreate.role.title"),
        description: t("usersCreate.role.desc"),
        prefferedSide: "left",
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_CREATE_FIELD_LANG}"] .Select__control`,
      popover: {
        title: t("usersCreate.lang.title"),
        description: t("usersCreate.lang.desc"),
        prefferedSide: "left",
      },
    },
    {
      element: `[data-boarding~="${USER_TOUR_USER_CREATE_FIELD_INVITE}"]`,
      popover: {
        title: t("usersCreate.invitation.title"),
        description: t("usersCreate.invitation.desc"),
        prefferedSide: "left",
      },
    },
    {
      element: 'div[role="dialog"][data-state="open"]',
      popover: {
        title: t("usersCreate.createForm.title"),
        description: t("usersCreate.createForm.desc"),
        prefferedSide: "left",
        alignment: "center",
        disableButtons: ["next"],
        className: "cs-popover-awaiting-input",
      },
      strictClickHandling: true,
      onNext: () => {
        if (!history.location.pathname.startsWith("/admin/user/list/item/")) {
          // move next handeled by history change listener
          boarding.preventMove();
          setTimeout(() => {
            boarding.clearMovePrevented();
            history.push(
              `/admin/user/list/item/${resourceGrabber.grab(
                "username",
                "auth"
              )}`
            );
          }, 0);
        }
      },
    },
    {
      element: `.app__contentminilist__list`,
      popover: {
        title: t("usersCreate.finish.title"),
        description: t("usersCreate.finish.desc"),
        prevBtnText: t("onboarding.btn.backToOverview"),
      },
      onPrevious: () => {
        completeAndBackToOverview(boarding);
      },
    },
  ]);

  return () => {
    unsubscribe();
    unsubscribeHistory();
  };
}
