import { createReducer } from "@reduxjs/toolkit";
import { updateJobGateway } from "./actions";

interface JobGatewayShape {
  updateStatus: "fetching" | "error" | "success" | "fail" | null;
  updatePayload: null | { message?: string };
  updateReceivedAt: number | null;
}

const initialState: JobGatewayShape = {
  updateStatus: null,
  updatePayload: null,
  updateReceivedAt: null,
};

const jobGatewayReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateJobGateway.pending, (state) => {
      return {
        ...state,
        updateStatus: "fetching",
        updatePayload: null,
        updateReceivedAt: null,
      };
    })
    .addCase(updateJobGateway.rejected, (state, action) => {
      return {
        ...state,
        updateStatus: "error",
        updatePayload: action.payload?.response || action.error,
        updateReceivedAt: action.payload?.updateReceivedAt || null,
      };
    })
    .addCase(updateJobGateway.fulfilled, (state, action) => {
      const response = action.payload.response;
      return {
        ...state,
        updateStatus: response.status === 500 ? "fail" : "success",
        updatePayload: response,
        updateReceivedAt: action.payload.updateReceivedAt,
      };
    });
});

export { jobGatewayReducer };
