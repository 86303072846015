import { CasaiamRoles, KCRoles } from "utilities/type-helpers/userRoles";
import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const casaiamUntranslatedList = [
  {
    value: CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    label: "Admin & billing",
  },
  {
    value: CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
    label: "Admin",
  },
  {
    value: CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
    label: "Employee",
  },
  {
    value: CasaiamRoles.ROLE_CASAONE_PHOTOGRAPHER,
    label: "Photographer",
  },
  {
    value: CasaiamRoles.ROLE_CASAONE_REPORTER,
    label: "Report viewer",
  },
];

const kcUntranslatedList = [
  {
    value: KCRoles.ROLE_KC_CASASOFT_ADMIN,
    label: "Casasoft Admin",
  },
  {
    value: KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
    label: "Casasoft Super Admin",
  },
];

export const getKCRoles = createGetList(kcUntranslatedList);
export const getKCRoleLabel = createGetLabel(kcUntranslatedList);
export const getCasaiamRoles = createGetList(casaiamUntranslatedList);
export const getCasaiamRoleLabel = createGetLabel(casaiamUntranslatedList);

export const getRoles = createGetList([
  ...casaiamUntranslatedList,
  ...kcUntranslatedList,
]);
export const getRoleLabel = createGetLabel([
  ...casaiamUntranslatedList,
  ...kcUntranslatedList,
]);

export const useUserRoles = createUtilityHook(casaiamUntranslatedList);
