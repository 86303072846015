import CheckboxElem from "@casasoft/styleguide/components/formElements/helpers/CheckboxElem";
import { useTranslation } from "react-i18next";

export interface LanguageBreadkdownContentHeaderProps {
  isChecked: boolean;
  handleCheck: () => void;
  className?: string;
}

const Separator = () => (
  <div className="tw-border-solid tw-border-0 tw-border-b tw-text-tw-cs-accent-color"></div>
);

export const LanguageBreadkdownContentHeader = ({
  isChecked,
  handleCheck,
  className,
}: LanguageBreadkdownContentHeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={className}>
        <div>
          <CheckboxElem
            value={isChecked ? "checked" : "unchecked"}
            onClick={handleCheck}
          />
        </div>
        <div>{t("Contact language")}</div>
        <div className="tw-col-span-2">{t("Contacts")}</div>
      </div>
      <Separator />
    </>
  );
};
