import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Dialog from "@radix-ui/react-dialog";
import { ReactNode } from "react";

interface ModalProps extends Omit<Dialog.DialogProps, "open"> {
  children: ReactNode;
  /** will cause the modal to play a shaking animation. this is mainly used to visualize submit errors */
  isShaking?: boolean;
  /** controlls the width of the modal. supports three different sizes */
  size?: "md" | "sm" | "lg";
  /** controlls the open state */
  isOpen?: boolean;
  /** event handler that fires when the modal closes */
  onClose?: () => void;
  /** event fired when clicking outside of the modal */
  onOutsideClick?: (e: CustomEvent) => void;
}

function Modal({
  children,
  isShaking,
  isOpen,
  size = "md",
  onClose,
  onOutsideClick,
  ...dialogProps
}: ModalProps) {
  return (
    <Dialog.Root
      {...dialogProps}
      open={isOpen}
      onOpenChange={(newState) => {
        if (!newState) {
          onClose?.();
        }
        dialogProps.onOpenChange?.(newState);
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay
          data-testid="modal-overlay"
          className="tw-cs-modal-overlay tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-items-start tw-z-30 tw-overflow-y-auto tw-bg-black/50"
          onSubmit={(e) => {
            // required to prevent nested modals from firing the parents onSubmit events -> CASAONE-6122
            e.stopPropagation();
          }}
        >
          <Dialog.Content
            className={`tw-cs-modal-content tw-m-2 sm:tw-w-full sm:tw-mx-auto sm:tw-my-7 
              ${size === "sm" ? "sm:tw-max-w-[320px]" : ""}
              ${size === "md" ? "sm:tw-max-w-sm" : ""}
              ${size === "lg" ? "sm:tw-max-w-3xl" : ""}
            `}
            onPointerDownOutside={(e) => {
              const currentTarget = e.currentTarget as HTMLElement;

              if (onOutsideClick) {
                onOutsideClick(e);
              }

              // check if user clicked the scrollbar -> prevent outside click event (prevent closing dialog)
              // https://github.com/radix-ui/primitives/issues/1280
              if (e.detail.originalEvent.offsetX > currentTarget.clientWidth) {
                e.preventDefault();
              }
            }}
          >
            <div
              data-testid="modal-content"
              className={`tw-relative tw-bg-cs-shade-white tw-rounded-[0.3rem] tw-border tw-border-solid tw-border-cs-shade-black/20 ${
                isShaking ? "tw-animate-shake" : ""
              }`}
            >
              {children}
              <Dialog.Close
                data-testid="modal-close-button"
                className="tw-absolute tw-top-[1px] tw-right-[1px] tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center focus:tw-outline focus:tw-outline-2 tw-rounded-sm focus:tw-outline-cs-shade-300"
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="tw-text-cs-shade-400"
                />
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default Modal;
