import { useLazyGetMessagePreviewSingleQuery } from "api/entities/messagePreview";
import { UseFormGetValues } from "react-hook-form";
import { selectCasaoneUser } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import { MessageCreateFormShape } from "../../MessageCreateForm";
import {
  ContactForPreview,
  getAllContacts,
  getPreviewRequestBody,
} from "./messageBodyPreviewHelpers";
import { sendMessagePreviewEvent } from "utilities/analytics/messaging/sendMessagePreviewEvent";

export interface UseMessageBodyPreviewFetchResponse {
  contacts: ContactForPreview[];
  fetchPreview: (recipient: ContactForPreview | undefined) => Promise<void>;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  previewBody: string | undefined;
}

export const useMessageBodyPreview = ({
  getValues,
}: {
  getValues: UseFormGetValues<MessageCreateFormShape>;
}): UseMessageBodyPreviewFetchResponse => {
  const currentUser = useAppSelector(selectCasaoneUser);
  const sender = {
    id: currentUser?.contact?.id || "",
    email: currentUser?.contact?.email || "",
    contactIdentifier:
      currentUser?.contact?.id || currentUser?.contact?.email || "",
    lang: currentUser?.contact?.lang || "",
  };

  const [trigger, result] = useLazyGetMessagePreviewSingleQuery();
  const { data, isError, isLoading, isFetching } = result;

  const fetchPreview = async (recipient: ContactForPreview | undefined) => {
    const requestBody = getPreviewRequestBody(getValues, sender, recipient);

    if (requestBody) {
      trigger(requestBody);
      sendMessagePreviewEvent("message_preview", {
        propertyId: requestBody.property,
        lang: requestBody.lang,
        bodyLength: requestBody.html?.length,
      });
    }
  };

  return {
    contacts: getAllContacts(getValues),
    fetchPreview,
    isLoading,
    isFetching,
    previewBody: data?.html,
    isError,
  };
};
