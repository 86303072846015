export enum CasaiamRoles {
  ROLE_CASAONE_COMPANY_ADMIN_BILLING = "ROLE_CASAONE_COMPANY_ADMIN_BILLING",
  ROLE_CASAONE_COMPANY_ADMIN = "ROLE_CASAONE_COMPANY_ADMIN",
  ROLE_CASAONE_COMPANY_USER = "ROLE_CASAONE_COMPANY_USER",
  ROLE_CASAONE_PHOTOGRAPHER = "ROLE_CASAONE_PHOTOGRAPHER",
  ROLE_CASAONE_REPORTER = "ROLE_CASAONE_REPORTER",
}

export type CasaiamRole = keyof typeof CasaiamRoles;

export enum KCRoles {
  ROLE_KC_CASASOFT_ADMIN = "ROLE_KC_CASASOFT_ADMIN",
  ROLE_KC_CASASOFT_SUPER_ADMIN = "ROLE_KC_CASASOFT_SUPER_ADMIN",
  ROLE_KC_CASAIAM_ACCESS = "ROLE_KC_CASAIAM_ACCESS",
  ROLE_KC_CASAIAM_COMPANIES_MANAGEMENT = "ROLE_KC_CASAIAM_COMPANIES_MANAGEMENT",
  ROLE_KC_CASAIAM_CONTRACTS_MANAGEMENT = "ROLE_KC_CASAIAM_CONTRACTS_MANAGEMENT",
  ROLE_KC_CASAIAM_ENTRIES_MANAGEMENT = "ROLE_KC_CASAIAM_ENTRIES_MANAGEMENT",
  ROLE_KC_CASAIAM_PRODUCT_MANAGEMENT = "ROLE_KC_CASAIAM_PRODUCT_MANAGEMENT",
  ROLE_KC_CASAIAM_USERS_MANAGEMENT = "ROLE_KC_CASAIAM_USERS_MANAGEMENT",
  ROLE_KC_ZEFIX_COMPANIES_MANAGEMENT = "ROLE_KC_ZEFIX_COMPANIES_MANAGEMENT",
}

export type KCRole = keyof typeof KCRoles;

export const Roles = { ...CasaiamRoles, ...KCRoles };
export type Role = keyof typeof Roles;

export function isCasaiamRole(role: string | null): role is CasaiamRole {
  return Object.keys(CasaiamRoles).includes(role as CasaiamRole);
}

export function isKCRole(role: string | null): role is KCRole {
  return Object.keys(KCRoles).includes(role as KCRole);
}

export function isRole(role: string | null): role is Role {
  return Object.keys(Roles).includes(role as Role);
}

export function getCasaiamRolesButReporter(): CasaiamRole[] {
  return Object.values(CasaiamRoles).filter(
    (role) => role !== CasaiamRoles.ROLE_CASAONE_REPORTER
  );
}
