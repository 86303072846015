import { ReactNode } from "react";

interface NotificationcenterProps {
  collapsed?: boolean;
  peek?: boolean;
  onToggle?: () => void;
  children: ReactNode;
}

function Notificationcenter({
  collapsed = true,
  peek = false,
  children,
}: NotificationcenterProps) {
  const classes = [
    "tw-bg-cs-shade-100 tw-h-full tw-absolute tw-right-0 tw-bottom-0 tw-transition-all tw-w-full sm:tw-w-[400px] tw-z-[16] tw-shadow-[3px_0_20px_-2px_rgba(108,117,125,1)]",
  ];
  if (collapsed) {
    classes.push("tw-translate-x-[calc(100%+2rem)]");
  }
  if (peek) {
    classes.push("tw-translate-x-[calc(100%-2rem)]");
  }
  return (
    <div className={classes.join(" ")}>
      <div className="tw-h-full">{children}</div>
    </div>
  );
}

export default Notificationcenter;
