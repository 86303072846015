/**
 * Wait for an HTMLDomElement to be added to the document
 * @param selector documentQuerySelector string
 * @returns returns the HTMLElement once found
 */
export function waitForElm(selector: string) {
  return new Promise<HTMLElement>((resolve) => {
    const foundElm = document.querySelector<HTMLElement>(selector);
    if (foundElm) {
      return resolve(foundElm);
    }

    const observer = new MutationObserver(() => {
      const foundElm = document.querySelector<HTMLElement>(selector);
      if (foundElm) {
        resolve(foundElm);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

/**
 * Wait for an HTMLDomElement to be removed from the document
 * @param selector documentQuerySelector string
 */
export async function waitForElmRemoval(selector: string) {
  await new Promise<void>((resolve) => {
    const foundElm = document.querySelector<HTMLElement>(selector);
    if (!foundElm) {
      return resolve();
    }

    const observer = new MutationObserver(() => {
      const foundElm = document.querySelector<HTMLElement>(selector);
      if (!foundElm) {
        resolve();
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
