import i18n from "../i18n";

import {
  createGetLabel,
  createGetList,
  createUtilityHook,
  CustomUtilityTranslator,
} from "./createGenericUtility";

const baseList = [
  {
    label: i18n.t("Everything"),
    value: "0",
  },
  {
    label: `- 24 ${i18n.t("h")}`,
    value: "1",
  },
  {
    label: `- 48 ${i18n.t("h")} `,
    value: "2",
  },
  {
    label: `- 1 ${i18n.t("Week")}`,
    value: "7",
  },
  {
    label: `- 1 ${i18n.t("Calendar.month")}`,
    value: "30",
  },
];

const customTranslator: CustomUtilityTranslator<typeof baseList[number]> = (
  item,
  originalTranslator
) => {
  switch (item.value) {
    case "0":
      return originalTranslator("Everything");
    case "1":
      return `- 24 ${originalTranslator("h")}`;
    case "2":
      return `- 48 ${originalTranslator("h")}`;
    case "7":
      return `- 1 ${originalTranslator("Week")}`;
    case "30":
      return `- 1 ${originalTranslator("Calendar.month")}`;
    default:
      return originalTranslator(item.label);
  }
};

export const getTopicalityOptions = createGetList(baseList, {
  translator: customTranslator,
});
export const getTopicalityLabel = createGetLabel(baseList, {
  translator: customTranslator,
});

export const useReportTopicalities = createUtilityHook(baseList, {
  translator: customTranslator,
});
