/**
 * @deprecated please use sendEvent instead with strict definition of event types
 */
export function createGtagEvent(
  action: string,
  category: string,
  label: string = "",
  value: number = 0
) {
  if (typeof window !== "undefined") {
    gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
}
