import { casaoneApi } from "api";
import singleQuery from "./endpoints/single/query";
import singleProvidesTags from "./endpoints/single/providesTags";
import listQuery from "./endpoints/list/query";
import listProvidesTags from "./endpoints/list/providesTags";
import idListQuery from "./endpoints/list-ids/query";
import idListProvidesTags from "./endpoints/list-ids/providesTags";
import ListReducedQuery from "./endpoints/list-reduced/query";
import ListReducedProvidesTags from "./endpoints/list-reduced/providesTags";
import updateSingleQuery from "./endpoints/update-single/query";
import updateSingleInvalidatesTags from "./endpoints/update-single/invalidatesTags";
import transformResponse from "./endpoints/list-reduced/transformResponse";

const propertyApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getProperty: builder.query({
      query: singleQuery,
      providesTags: singleProvidesTags,
    }),
    getPropertyList: builder.query({
      query: listQuery,
      providesTags: listProvidesTags,
    }),
    getPropertyIdList: builder.query({
      query: idListQuery,
      providesTags: idListProvidesTags,
    }),
    getPropertyListReduced: builder.query({
      query: ListReducedQuery,
      providesTags: ListReducedProvidesTags,
      transformResponse: transformResponse,
    }),
    updateProperty: builder.mutation({
      query: updateSingleQuery,
      invalidatesTags: updateSingleInvalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPropertyQuery,
  useUpdatePropertyMutation,
  useGetPropertyListQuery,
  useLazyGetPropertyListQuery,
  useGetPropertyIdListQuery,
  useLazyGetPropertyIdListQuery,
  useGetPropertyListReducedQuery,
  useLazyGetPropertyListReducedQuery,
  util: propertyApiUtil,
} = propertyApi;
