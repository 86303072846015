import { useState, InputHTMLAttributes } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import "moment/locale/de-ch";
import "moment/locale/fr-ch";
import "moment/locale/it-ch";
import "moment/locale/en-gb";
import "moment/locale/es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faTimes } from "@fortawesome/pro-light-svg-icons";
import FieldInputGroup from "./FieldInputGroup";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import Button from "../../forms/Button";

export interface DateFieldElemProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "placeholder" | "prefix" | "onChange" | "onFocus" | "onBlur"
  > {
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
  placeholder?: string;
  value?: string;
  id?: string;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  rangeStart?: string;
  rangeEnd?: string;
  locale?: string;
  disabled?: boolean;
  required?: boolean;
  focused?: boolean;
  showTodayButton?: boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
}

const DateFieldElem = ({
  prefix = "",
  suffix = "",
  placeholder = "",
  onChange,
  onBlur,
  onFocus,
  value = "",
  id = "",
  rangeStart,
  rangeEnd,
  locale = "de-ch",
  disabled = false,
  required = false,
  focused: focusedProp = false,
  showTodayButton = true,
  isOutsideRange,
}: DateFieldElemProps) => {
  const { t } = useTranslation();

  const [date, setDate] = useState<Moment | null>(null);

  moment.locale(locale);
  let dateValue: Moment | null = null;
  if (value) {
    dateValue = moment(value);
  }
  if (dateValue && !dateValue.isValid()) {
    dateValue = null;
  }
  let rangeStartDate: Moment | null = moment(rangeStart);
  if (!rangeStartDate || !rangeStartDate.isValid()) {
    rangeStartDate = null;
  }
  let rangeEndDate: Moment | null = moment(rangeEnd);
  if (!rangeEndDate || !rangeEndDate.isValid()) {
    rangeEndDate = null;
  }
  const isOutsideRangeHandler = isOutsideRange
    ? isOutsideRange
    : (checkDate: moment.Moment) => {
        if (rangeStartDate && rangeEndDate) {
          return !checkDate.isBetween(rangeStartDate, rangeEndDate);
        }
        if (rangeStartDate) {
          return !checkDate.isSameOrAfter(rangeStartDate);
        }
        if (rangeEndDate) {
          return !checkDate.isSameOrBefore(rangeEndDate);
        }
        return false;
      };

  const now = moment().startOf("day");
  return (
    <FieldInputGroup prefix={prefix} suffix={suffix}>
      <SingleDatePicker
        daySize={50}
        isOutsideRange={isOutsideRangeHandler}
        initialVisibleMonth={() => {
          if (dateValue) {
            return dateValue;
          }
          if (rangeEndDate) {
            return moment(rangeEndDate).subtract(1, "months");
          }
          if (rangeStartDate) {
            return rangeStartDate;
          }
          return moment();
        }}
        onFocusChange={({ focused }) => {
          if (focused) {
            onFocus?.(date ? date.format("YYYY-MM-DD") : "");
          } else {
            // we need to use the setter callback since for some reason this event is fired before the state got updated from onDateChange...
            setDate((currDate) => {
              onBlur?.(currDate ? currDate.format("YYYY-MM-DD") : "");
              return currDate;
            });
          }
        }}
        focused={focusedProp}
        showDefaultInputIcon
        inputIconPosition="after"
        numberOfMonths={1}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
          let startYear = 1900;
          let endYear = 2150;
          if (rangeStartDate) {
            startYear = rangeStartDate.year();
          }
          if (rangeEndDate) {
            endYear = rangeEndDate.year();
          }
          const years = Array(endYear - startYear + 1)
            .fill(0)
            .map((v, i) => startYear + i);
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                zIndex: 1,
                marginTop: -10,
              }}
            >
              <div style={{ width: 130 }}>
                <Select
                  nobox
                  compact
                  isClearable={false}
                  value={month.month().toString()}
                  onChange={(newMonthValue) => {
                    onMonthSelect(month, newMonthValue);
                  }}
                  options={moment.months().map((label, monthValue) => {
                    return {
                      label,
                      value: monthValue.toString(),
                    };
                  })}
                />
              </div>
              <div style={{ width: 100 }}>
                <Select
                  nobox
                  compact
                  isClearable={false}
                  value={month.year().toString()}
                  onChange={(newYearValue) => {
                    onYearSelect(month, newYearValue);
                  }}
                  options={years.map((y) => {
                    return {
                      label: y,
                      value: y.toString(),
                    };
                  })}
                />
              </div>
            </div>
          );
        }}
        customInputIcon={<FontAwesomeIcon icon={faCalendar} />}
        customCloseIcon={<FontAwesomeIcon icon={faTimes} />}
        date={dateValue} // momentPropTypes.momentObj or null,
        id={id} // PropTypes.string.isRequired,
        showClearDate
        onDateChange={(newDate) => {
          if (newDate) {
            setDate(newDate);
            onChange?.(newDate.format("YYYY-MM-DD"));
          } else {
            setDate(null);
            onChange?.("");
          }
        }} // PropTypes.func.isRequired,
        placeholder={placeholder}
        phrases={{
          calendarLabel: "Kalender",
          closeDatePicker: "Schliessen",
          // clearDates: "Daten löschen",
        }}
        disabled={disabled}
        required={required}
        renderCalendarInfo={
          showTodayButton
            ? () => (
                <div className="tw-pl-4 tw-pb-4">
                  <Button
                    isSecondary
                    disabled={isOutsideRangeHandler(now)}
                    onClick={() => {
                      setDate(now);
                      onChange?.(now.format("YYYY-MM-DD"));

                      onBlur?.(now.format("YYYY-MM-DD"));
                    }}
                  >
                    {t("Calendar.today")}
                  </Button>
                </div>
              )
            : undefined
        }
      />
    </FieldInputGroup>
  );
};

export default DateFieldElem;
