import {
  useForm as useFormOriginal,
  Controller,
  FieldValues,
  UseFormProps,
  Control,
  RegisterOptions,
  FieldPath,
  FieldPathValue,
  ControllerRenderProps,
} from "react-hook-form";
import { PartialOrNull } from "utilities/type-helpers/object-oberators";

// Custom hook documented here: https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-forms-and-useform--page

// props to hydrate to first param
type newFormParamProps<TFieldValues extends FieldValues> = {
  // anAdditionalOptionAsAnExample?: string;
  defaultValues: TFieldValues;
};

// type magic to add property to first param object
type hydratedFormParamProps<
  TFieldValues extends FieldValues,
  T2 extends object = object
> = UseFormProps<TFieldValues, T2> & newFormParamProps<TFieldValues>;

// hydrated useForm
function useForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(origParams: hydratedFormParamProps<TFieldValues, TContext>) {
  const params = { ...origParams, mode: origParams?.mode || "all" };
  const useFormState = useFormOriginal<TFieldValues, TContext>(params);

  // deconstruct is important: https://react-hook-form.com/api/useformstate
  const {
    formState: { dirtyFields },
  } = useFormState;

  // to retrieve custom added options in the future we can do smth like this (linked to the newFormParamProps type above)
  // const anAdditionalOptionAsAnExample =
  //   origParams.anAdditionalOptionAsAnExample;

  return {
    ...useFormState,
    getDirtyValues: () => {
      const formData = useFormState.getValues();
      type DirtyData = PartialOrNull<typeof formData>;
      const dirtyData: DirtyData = {};

      Object.entries(dirtyFields).forEach(
        (dirtyField: [keyof DirtyData, DirtyData[keyof DirtyData]]) => {
          // we fall back to null, since dirtyData is sent to our api and our api needs null and not undefined to know a property should be removed
          dirtyData[dirtyField[0]] =
            typeof formData[dirtyField[0]] === "undefined"
              ? null
              : formData[dirtyField[0]];
        }
      );
      return dirtyData;
    },
  };
}

type FieldsProp<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  [P in TName]?: {
    rules?: Omit<
      RegisterOptions<TFieldValues, P>,
      "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >;
  };
};

// SHOULD BE:::: type FieldChangeHandler = ControllerRenderProps<TFieldValues, TName>["onChange"]
// But there is an issue, so we write a custom one
type FieldChangeHandler<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = (newValue: FieldPathValue<TFieldValues, TName>) => void;

type FullFieldParam<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  // an object, in case we want to extend it with more values (similar to <Controller> render arguments)
  field: ControllerRenderProps<TFieldValues, TName>;
};

type FieldRendererArgs<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = [
  name: TName,
  renderMethod: (
    value: FieldPathValue<TFieldValues, TName>,
    onChange: FieldChangeHandler<TFieldValues, TName>,
    full: FullFieldParam<TFieldValues, TName>
  ) => JSX.Element
];
type ChainFieldsArgs<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = [
  names: TName[],
  renderMethod: (
    values: {
      [P in TName]: FieldPathValue<TFieldValues, P>;
    },
    changeHandlers: {
      [P in TName]: FieldChangeHandler<TFieldValues, P>;
    },
    fulls: {
      [P in TName]: FullFieldParam<TFieldValues, P>;
    }
  ) => JSX.Element
];

export type RegisterFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  fields?: FieldsProp<TFieldValues, FieldPath<TFieldValues>>;
  render: (
    fieldRenderer: <TName extends FieldPath<TFieldValues>>(
      ...args: FieldRendererArgs<TFieldValues, TName>
    ) => JSX.Element,
    chainFields: <TName extends FieldPath<TFieldValues>>(
      ...args: ChainFieldsArgs<TFieldValues, TName>
    ) => JSX.Element
    // chainFields: ChainFields<TFieldValues, TName>
  ) => JSX.Element;
};

export const RegisterFields = <TFieldValues extends FieldValues>({
  fields,
  control,
  render,
}: RegisterFieldProps<TFieldValues>) => {
  const fieldRenderer = <TName extends FieldPath<TFieldValues>>(
    ...[name, renderMethod]: FieldRendererArgs<TFieldValues, TName>
  ) => {
    const registerData = fields?.[name];
    return (
      <>
        <Controller
          key={name}
          name={name}
          control={control}
          rules={registerData?.rules}
          render={({ field }) => {
            return renderMethod(field.value, field.onChange, { field });
          }}
        />
      </>
    );
  };

  const chainFields = <TName extends FieldPath<TFieldValues>>(
    ...[fieldsToChain, renderMethod]: ChainFieldsArgs<TFieldValues, TName>
  ) => {
    type NonPartialVals = {
      [P in TName]: FieldPathValue<TFieldValues, P>;
    };
    type NonPartialChangeHandlers = {
      [P in TName]: FieldChangeHandler<TFieldValues, P>;
    };
    type NonPartialFull = {
      [P in TName]: { field: ControllerRenderProps<TFieldValues, P> };
    };
    const partialVals: Partial<NonPartialVals> = {};
    const partialChangeHandlers: Partial<NonPartialChangeHandlers> = {};
    const partialFull: Partial<NonPartialFull> = {};

    const chainField = (currIndex = 0): JSX.Element => {
      const currChainKey = fieldsToChain[currIndex];
      // we reached the end
      if (!currChainKey) {
        return renderMethod(
          partialVals as NonPartialVals, // it's not a partial anymore
          partialChangeHandlers as NonPartialChangeHandlers, // it's not a partial anymore
          partialFull as NonPartialFull // it's not a partial anymore
        );
      }

      return (
        fieldRenderer(currChainKey, (myVal, changeHandler, full) => {
          partialVals[currChainKey] = myVal;
          partialChangeHandlers[currChainKey] = changeHandler;
          partialFull[currChainKey] = full;
          return chainField(currIndex + 1);
        }) || <></>
      );
    };
    return chainField();
  };

  return <>{render(fieldRenderer, chainFields)}</>;
};

export default useForm;
