import { useGetSubsidiaryListQuery } from "api/entities/subsidiary";
import { selectCompany } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import { SubsidiaryListShape } from "api/entities/subsidiary/endpoints/list/types";

function useSubsidiaryFilterFormOptions() {
  const company = useAppSelector(selectCompany);
  const getSubsidiaryListResult = useGetSubsidiaryListQuery(undefined);
  const { t } = useTranslation();
  if (!!getSubsidiaryListResult?.data?._embedded?.subsidiary) {
    let CompanyLabelForSubsidiarySelect: string | React.ReactNode =
      t("Headquarters");
    let companyLabelSortValue = CompanyLabelForSubsidiarySelect;
    if (company?.legalName) {
      CompanyLabelForSubsidiarySelect = company.legalName;
      companyLabelSortValue = CompanyLabelForSubsidiarySelect;
      if (company?.address?.locality) {
        CompanyLabelForSubsidiarySelect = (
          <>
            {CompanyLabelForSubsidiarySelect}
            <small>,&nbsp;{company.address.locality}</small>
          </>
        );
      }
    }
    return [
      {
        label: CompanyLabelForSubsidiarySelect,
        value: "isnull",
        sortValue: companyLabelSortValue,
      },
      ...getSubsidiaryListResult.data._embedded.subsidiary.map(
        (subsidiary: SubsidiaryListShape) => {
          return {
            label: (
              <>
                {subsidiary?._embedded?.contact?.displayName
                  ? subsidiary._embedded.contact.displayName
                  : subsidiary.id}
                {subsidiary?._embedded?.contact?._embedded?.address
                  ?.locality ? (
                  <small>
                    ,&nbsp;
                    {subsidiary._embedded.contact._embedded.address.locality}
                  </small>
                ) : (
                  ""
                )}
              </>
            ),
            value: subsidiary.id,
            sortValue: subsidiary?._embedded?.contact?.displayName
              ? subsidiary._embedded.contact.displayName
              : subsidiary.id,
          };
        }
      ),
    ];
  }
  return undefined;
}

export default useSubsidiaryFilterFormOptions;
