import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "new-mortgage",
    label: "New mortgage",
  },
  {
    value: "remortgage",
    label: "Remortgage",
  },
  {
    value: "extension",
    label: "Extension",
  },
];

export const getMortgageType = createGetList(untranslatedList);
export const getMortgageTypeLabel = createGetLabel(untranslatedList);

export const useMortgageTypes = createUtilityHook(untranslatedList);
