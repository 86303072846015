import { CasaoneResultDescription } from "api/types";
import { CreateEventSingleResponse, CreateEventSingleBody } from "./types";

const invalidatesTags: CasaoneResultDescription<
  CreateEventSingleResponse,
  CreateEventSingleBody
> = (result) => {
  return ["Event", "PropertyContact"];
};

export default invalidatesTags;
