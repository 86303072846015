import { createResource } from "redux-rest-resource";
import Config from "config";
import PropertyContent from "./types";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: PropertyContent, actions?: any) => PropertyContent;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "propertyContent",
  pluralName: "propertyContents",
  url: `${Config.apiUrl}/${Config.customerKey}/property-content/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
