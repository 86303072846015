import { createResource } from "redux-rest-resource";
import createResourceHelper from "utilities/createResourceHelper";
import baseEventCreator, { EventResourceTypes } from "./baseEventCreator";

const name = "quickAlert" as const;
const pluralName = "quickAlerts" as const;

export const { types, actions, rootReducer }: EventResourceTypes =
  createResource(
    baseEventCreator({
      name,
      pluralName,
      apiContext: "alert-list",
      forcedFilter: [
        {
          type: "in",
          field: "type",
          values: ["alert"],
        },
        {
          type: "in",
          field: "status",
          values: ["fresh"],
          where: "and",
        },
      ],
    })
  );

const quickAlertResourceCreator = createResourceHelper({
  actions,
  name,
  pluralName,
});

export const quickAlertResource = quickAlertResourceCreator.resource;
export const useQuickAlert = quickAlertResourceCreator.createHook();
