import { ReducedContactShape } from "components/contact/features/contact-excerpt/ContactExcerpt";

// this is a simple email regex, it doesn't cover all the edge cases
const SIMPLE_EMAIL_RE = /([A-Za-z0-9-]+)@([A-Za-z0-9-]+)\.([A-Za-z]+){2,}/;

export const looksLikeAnEmail = (text: string): boolean => {
  return new RegExp(SIMPLE_EMAIL_RE).test(text);
};

export const getContactDisplayName = (
  contact: ReducedContactShape | undefined,
  defaultText: string
) => contact?.displayName || contact?.email || defaultText;
