import Config, { Env } from "config";

function dynamicSvgColor(clr: TemplateStringsArray) {
  return `data:image/svg+xml,%3Csvg version='1' xmlns='http://www.w3.org/2000/svg' width='933.333' height='933.333' viewBox='0 0 700.000000 700.000000'%3E%3Cpath fill='${encodeURIComponent(
    clr[0]
  )}' d='M370.9 38.4c-.2.2-4.9.7-10.4 1-5.5.4-10.9.9-12 1.1-1.1.2-4.1.7-6.7 1-4.3.5-25 4.2-27.3 4.9-.5.1-2.8.6-5 1s-5.1 1.1-6.5 1.6c-1.4.4-4.5 1.3-7 2-19 5.3-42.4 14.5-59.9 23.7-8.8 4.6-28.9 16.6-32.6 19.5-.5.4-5.4 4.1-10.8 8.3-12.6 9.7-15 11.8-28.7 25.5-24.4 24.3-45.8 55.6-59.2 86.5-1.6 3.8-3.6 8.3-4.3 10-4.9 11.1-14.4 42.2-15.9 52-.4 2-.8 4.1-1.1 4.5-.3.4-.7 2.9-1 5.6-.4 2.6-.8 5.1-1 5.4-.2.4-.7 3.5-1.1 7.1-.3 3.5-.8 6.7-1 7-.2.3-.6 4.4-1 9-.3 4.6-.8 10-1 11.9-.8 7.4-.4 44.3.6 53.2.6 5.1 1.3 11.8 1.6 14.8.4 3 .8 6.4 1 7.5.2 1 .6 3.7 1 6 .3 2.2 1 6 1.6 8.5.5 2.5 1.1 5.4 1.4 6.5.2 1.1.6 3.3 1 5 .8 4.3 3.1 12.3 7.1 25C115.1 524.2 162 582.8 225 619.1c40.2 23.2 86.4 37.3 136.5 41.7 17.1 1.5 64.2.7 79.5-1.3 1.9-.2 5.3-.7 7.5-1 2.2-.3 6.5-.9 9.5-1.5 3-.5 7.1-1.2 9-1.5 1.9-.3 5.5-1.1 8-1.6 2.5-.6 5.2-1.2 6-1.4 7.7-1.7 20.8-5.4 29-8.2 8.1-2.8 26.8-10.1 28.5-11.1.5-.4 1.2-.7 1.5-.7.3-.1 5.9-2.8 12.5-6.2 18.4-9.5 42.8-26.4 51.3-35.6l2.4-2.5-11.4-15.4c-6.3-8.4-17.3-23.2-24.4-32.8-7.1-9.6-17.4-23.5-22.8-30.8-8.6-11.6-10-13.1-11.5-12-3.3 2.6-11.6 9.3-12.1 9.8-.7.8-13.9 9.4-18 11.8-21.8 12.7-52.2 23.3-74 25.8-2.5.3-5.1.7-5.8 1-2.3.7-20.2 1.8-30.2 1.8-32.5-.1-62.8-7-90.5-20.6-11.1-5.5-29.3-17.3-37-24-44-38.1-67-90.8-66.9-153.3.1-69.7 29.8-128.1 83.5-164 15.4-10.3 41.7-21.8 58.4-25.5 2.8-.7 5.9-1.4 7-1.6 2-.5 9.5-1.7 17-2.9 15.3-2.3 43.5-2.3 57 0 20.1 3.5 42.1 9.8 56 16 22.5 10.1 39.2 19.5 52.8 29.9l3.9 3 2.6-3.4c1.5-1.9 4.1-5.3 5.7-7.6 1.7-2.3 5.7-7.7 9-12.1 3.3-4.4 6.9-9.2 8-10.7 1.1-1.5 4.8-6.4 8.1-10.9 3.4-4.5 12.8-17.1 20.9-28.1l14.7-19.8-3.9-4c-6.3-6.5-27.6-22.3-36.8-27.4-23.2-12.6-33.2-17.2-54-24.2-15.5-5.3-31.8-9.6-49-12.7-2.5-.5-15.4-2.3-21.5-3-2.5-.3-6.3-.8-8.5-1.1-4.9-.6-61-1.6-61.6-1z'/%3E%3C/svg%3E`;
}

function updateFavicons() {
  if (Config.env !== Env.production) {
    const favicons = document.head.getElementsByTagName("link");

    const faviconUpdater = (newHref: string) => {
      Array.from(favicons).forEach((favicon) => {
        if (
          favicon.getAttribute("rel")?.trim() === "icon" ||
          favicon.getAttribute("rel")?.trim() === "shortcut icon"
        ) {
          favicon.href = newHref;
          favicon.setAttribute("href", newHref);
        }
      });
    };

    switch (Config.env) {
      case Env.local:
        faviconUpdater(dynamicSvgColor`#0d9488`);
        break;
      case Env.test:
        faviconUpdater(dynamicSvgColor`#6d28d9`);
        break;
      case Env.test1:
        faviconUpdater(dynamicSvgColor`#6d28d9`);
        break;
      case Env.test2:
        faviconUpdater(dynamicSvgColor`#6d28d9`);
        break;
      case Env.staging:
        faviconUpdater(dynamicSvgColor`#eab308`);
        break;
      case Env.preprod:
        faviconUpdater(dynamicSvgColor`#b91c1c`);
        break;
      default:
        throw new Error("An error occured");
    }
  }
}

export default updateFavicons;
