import { useEffect } from "react";
import { createGtagEvent } from "utilities/gtagHelper";
import useOnboardingTranslations from "@casasoft/styleguide/hooks/useOnboardingTranslations";
// user
import { subscribeUserDeleteOnboarding } from "./user-onboarding/userDeleteOnboarding";
import { subscribeUserCreateOnboarding } from "./user-onboarding/userCreateOnboarding";
// property
import { subscribePropertyCreateOnboarding } from "./property-onboarding/propertyCreateOnboarding";
import { subscribePropertyDetailsOnboarding } from "./property-onboarding/propertyDetailsOnboarding";
import { subscribePropertyEditDetailsOnboarding } from "./property-onboarding/propertyEditDetailsOnboarding";
import { BoardingStartEvent } from "@casasoft/styleguide/utilities/tours/events/boardingStart";
import { BoardingResetEvent } from "@casasoft/styleguide/utilities/tours/events/boardingReset";
import useCompleteTour from "./useCompleteTour";

/** Register all available casaone tours */
function useCasaoneTours() {
  const { t } = useOnboardingTranslations();
  const { completeTour } = useCompleteTour();

  useEffect(() => {
    // user
    const unsubUserDeleteOnboarding = subscribeUserDeleteOnboarding(t);
    const unsubUserCreateOnboarding = subscribeUserCreateOnboarding(t);
    // property
    const unsubPropertyCreateOnboarding = subscribePropertyCreateOnboarding(t);
    const unsubPropertyDetailsOnboarding =
      subscribePropertyDetailsOnboarding(t);
    const unsubPropertyEditDetailsOnboarding =
      subscribePropertyEditDetailsOnboarding(t);

    return () => {
      // user
      unsubUserDeleteOnboarding();
      unsubUserCreateOnboarding();
      // property
      unsubPropertyCreateOnboarding();
      unsubPropertyDetailsOnboarding();
      unsubPropertyEditDetailsOnboarding();
    };
  }, [t]);

  // Listen to boarding events to report them to GA
  useEffect(() => {
    function boardingStartEventHandler(e: Event) {
      const myEv = e as BoardingStartEvent;

      createGtagEvent(`Tour Started: ${myEv.detail.id}`, "OnboardingTours");
    }

    function boardingResetEventHandler(e: Event) {
      const myEv = e as BoardingResetEvent;

      if (myEv.detail.exitReason === "finish") {
        completeTour(myEv.detail.id).then();
        createGtagEvent(`Tour Completed: ${myEv.detail.id}`, "OnboardingTours");
      } else {
        createGtagEvent(`Tour Canceled: ${myEv.detail.id}`, "OnboardingTours");
      }
    }

    document.addEventListener("BoardingStartEvent", boardingStartEventHandler);
    document.addEventListener("BoardingResetEvent", boardingResetEventHandler);
    return () => {
      document.removeEventListener(
        "BoardingStartEvent",
        boardingStartEventHandler
      );
      document.removeEventListener(
        "BoardingResetEvent",
        boardingResetEventHandler
      );
    };
  }, [completeTour]);
}

export default useCasaoneTours;
