import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    label: "Commercial",
    value: "commercial",
  },
  {
    label: "Admin./HR/Consulting/CEO",
    value: "admin-hr-consulting-ceo",
  },
  {
    label: "CEO/Management",
    value: "ceo-management",
  },
  {
    label: "Secretary/Reception",
    value: "secretary-reception",
  },
  {
    label: "Management Assistance",
    value: "management-assistance",
  },
  {
    label: "Processing/Language Translation",
    value: "processing-language-translation",
  },
  {
    label: "HR Management/Development",
    value: "hr-management-development",
  },
  {
    label: "Personnel Placement/Consultancy",
    value: "personnel-placement-consultancy",
  },
  {
    label: "Consultancy/Company Development",
    value: "consultancy-company-development",
  },
  {
    label: "Quality Management",
    value: "quality-management",
  },
  {
    label: "Legal/Attorneys/Court",
    value: "legal-attorneys-court",
  },
  {
    label: "Regulatory Affairs",
    value: "regulatory-affairs",
  },
  {
    label: "Finance/Trusts/Real Estate",
    value: "finance-trusts-real-estate",
  },
  {
    label: "Auditing/Revision/Auditing",
    value: "auditing-revision-auditing",
  },
  {
    label: "Accounting",
    value: "accounting",
  },
  {
    label: "Finance",
    value: "finance",
  },
  {
    label: "Controlling",
    value: "controlling",
  },
  {
    label: "Real Estate Management/Trade",
    value: "real-estate-management-trade",
  },
  {
    label: "Collection Services",
    value: "collection-services",
  },
  {
    label: "Payroll Accounting",
    value: "payroll-accounting",
  },
  {
    label: "Tax",
    value: "tax",
  },
  {
    label: "Trusts",
    value: "trusts",
  },
  {
    label: "Banking/Insurance",
    value: "banking-insurance",
  },
  {
    label: "Investment Cons./Private Banking",
    value: "investment-cons-private-banking",
  },
  {
    label: "Asset/Portfolio Management",
    value: "asset-portfolio-management",
  },
  {
    label: "Back-Office/Operations",
    value: "back-office-operations",
  },
  {
    label: "Financial/Business Analysis",
    value: "financial-business-analysis",
  },
  {
    label: "Funds/Stocks/Trade",
    value: "funds-stocks-trade",
  },
  {
    label: "Loans/Mortgages",
    value: "loans-mortgages",
  },
  {
    label: "Customer Service/Relationship Mgmt",
    value: "customer-service-relationship-mgmt",
  },
  {
    label: "Risk Management/Compliance",
    value: "risk-management-compliance",
  },
  {
    label: "Treasury/Controlling/Auditing",
    value: "treasury-controlling-auditing",
  },
  {
    label: "Project Management",
    value: "project-management",
  },
  {
    label: "Insurance Agencies",
    value: "insurance-agencies",
  },
  {
    label: "Insurance: Customer Acquisition/Sales",
    value: "insurance-customer-acquisition-sales",
  },
  {
    label: "Actuary/Mathematics",
    value: "actuary-mathematics",
  },
  {
    label: "Insurance: Claims/Benefits",
    value: "insurance-claims-benefits",
  },
  {
    label: "Underwriting/Product Management",
    value: "underwriting-product-management",
  },
  {
    label: "Purchasing/Logistics/Trading",
    value: "purchasing-logistics-trading",
  },
  {
    label: "Purchasing/Procurement",
    value: "purchasing-procurement",
  },
  {
    label: "Logistics/Supply Chain",
    value: "logistics-supply-chain",
  },
  {
    label: "Import/Export/Trading",
    value: "import-export-trading",
  },
  {
    label: "Marketing/Communications/Editorial",
    value: "marketing-communications-editorial",
  },
  {
    label: "Product-/Brand Management",
    value: "product-brand-management",
  },
  {
    label: "Online Marketing/Social Media",
    value: "online-marketing-social-media",
  },
  {
    label: "Marketing/Direct Marketing",
    value: "marketing-direct-marketing",
  },
  {
    label: "Advertising Consultancy/Communications",
    value: "advertising-consultancy-communications",
  },
  {
    label: "PR/Corporate Communications",
    value: "pr-corporate-communications",
  },
  {
    label: "Media/Editorial/Publishing",
    value: "media-editorial-publishing",
  },
  {
    label: "Sales/Customer Service/Admin.",
    value: "sales-customer-service-admin-",
  },
  {
    label: "Telesale",
    value: "telesale",
  },
  {
    label: "Sales Retail Trade",
    value: "sales-retail-trade",
  },
  {
    label: "Sales Consulting/Management",
    value: "sales-consulting-management",
  },
  {
    label: "External Sales/Specialists",
    value: "external-sales-specialists",
  },
  {
    label: "Customer Advice/Key Account Mgmt",
    value: "customer-advice-key-account-mgmt",
  },
  {
    label: "Internal Sales Service/Back-office",
    value: "internal-sales-service-back-office",
  },
  {
    label: "Customer Service/Call Center",
    value: "customer-service-call-center",
  },
  {
    label: "Category Management",
    value: "category-management",
  },
  {
    label: "Technical",
    value: "technical",
  },
  {
    label: "Information Technology/Telecom.",
    value: "information-technology-telecom-",
  },
  {
    label: "Testing/Audit/Security",
    value: "testing-audit-security",
  },
  {
    label: "Consultancy/Business Informatics",
    value: "consultancy-business-informatics",
  },
  {
    label: "Database Specialists/Development",
    value: "database-specialists-development",
  },
  {
    label: "Network Specialists/Engineers",
    value: "network-specialists-engineers",
  },
  {
    label: "Project Management/Analysis",
    value: "project-management-analysis",
  },
  {
    label: "ERP/SAP/CRM",
    value: "erp-sap-crm",
  },
  {
    label: "Software Architecture/Engineering",
    value: "software-architecture-engineering",
  },
  {
    label: "Software Development",
    value: "software-development",
  },
  {
    label: "Web Programming/Mobile",
    value: "web-programming-mobile",
  },
  {
    label: "Web Publishing/Design",
    value: "web-publishing-design",
  },
  {
    label: "System Engineering",
    value: "system-engineering",
  },
  {
    label: "System Administration",
    value: "system-administration",
  },
  {
    label: "User Help Desk/Support/Training",
    value: "user-help-desk-support-training",
  },
  {
    label: "Chemical/Pharma/Biotechnology",
    value: "chemical-pharma-biotechnology",
  },
  {
    label: "Pharmacy/Drug Stores",
    value: "pharmacy-drug-stores",
  },
  {
    label: "Biology/Biotechnology",
    value: "biology-biotechnology",
  },
  {
    label: "Chemical R+D/Analysis/Production",
    value: "chemical-rd-analysis-production",
  },
  {
    label: "Pharmaceutical R+D/Analysis/Production",
    value: "pharmaceutical-rd-analysis-production",
  },
  {
    label: "Quality Assurance/Management",
    value: "chemical-pharma-biotechnology-quality-assurance-management",
  },
  {
    label: "Electronics/Engineering/Watches",
    value: "electronics-engineering-watches",
  },
  {
    label: "Electronics/Electrotechnics",
    value: "electronics-electrotechnics",
  },
  {
    label: "Mechanics/Precision Engin./Watchmakers",
    value: "mechanics-precision-engin-watchmakers",
  },
  {
    label: "Tool Engineering",
    value: "tool-engineering",
  },
  {
    label: "Medical Equipment Engineering",
    value: "medical-equipment-engineering",
  },
  {
    label: "Energy/Building Engineering",
    value: "energy-building-engineering",
  },
  {
    label: "Customer Service",
    value: "customer-service",
  },
  {
    label: "Technical Service",
    value: "technical-service",
  },
  {
    label: "Quality Assurance/Management",
    value: "electronics-engineering-watches-quality-assurance-management",
  },
  {
    label: "Machine/Plant Engin./Manufacturing",
    value: "machine-plant-engin-manufacturing",
  },
  {
    label: "Plant/Device Engineering",
    value: "plant-device-engineering",
  },
  {
    label: "Automation/Process Engineering",
    value: "automation-process-engineering",
  },
  {
    label: "Construction",
    value: "construction",
  },
  {
    label: "CNC Mechanics/Polymechanics",
    value: "cnc-mechanics-polymechanics",
  },
  {
    label: "Machine Building/Engineering",
    value: "machine-building-engineering",
  },
  {
    label: "Manufacturing/Production Industry",
    value: "manufacturing-production-industry",
  },
  {
    label: "Maintenance/Servicing",
    value: "maintenance-servicing",
  },
  {
    label: "Quality Assurance/Management",
    value: "machine-plant-engin-manufacturing-quality-assurance-management",
  },
  {
    label: "Construction/Architecture/Engineer",
    value: "construction-architecture-engineer",
  },
  {
    label: "Architecture/Technical Drawing/Planning",
    value: "architecture-technical-drawing-planning",
  },
  {
    label: "Interior Design/Decoration",
    value: "interior-design-decoration",
  },
  {
    label: "Gardening/Landscape Architecture",
    value: "gardening-landscape-architecture",
  },
  {
    label: "Civil Engineering/Supervision",
    value: "civil-engineering-supervision",
  },
  {
    label: "Construction Work/Scaffolding/Machinery",
    value: "construction-work-scaffolding-machinery",
  },
  {
    label: "Pluming/Roofing",
    value: "pluming-roofing",
  },
  {
    label: "Metal Construction",
    value: "metal-construction",
  },
  {
    label: "Wood Construction",
    value: "wood-construction",
  },
  {
    label: "Floor Laying/Paving",
    value: "floor-laying-paving",
  },
  {
    label: "Sanitary Engineering/Installation",
    value: "sanitary-engineering-installation",
  },
  {
    label: "Painting/Plastering",
    value: "painting-plastering",
  },
  {
    label: "Bricklaying",
    value: "bricklaying",
  },
  {
    label: "Electricity Engineering/Installation",
    value: "electricity-engineering-installation",
  },
  {
    label: "Heating/Ventilation/Climate",
    value: "heating-ventilation-climate",
  },
  {
    label: "Vehicles/Craft/Warehouse/Transport",
    value: "vehicles-craft-warehouse-transport",
  },
  {
    label: "Vehicle Mechanics/Diagnostics",
    value: "vehicle-mechanics-diagnostics",
  },
  {
    label: "Vehicle Body Engineering/Varnishing",
    value: "vehicle-body-engineering-varnishing",
  },
  {
    label: "Metal Crafts",
    value: "metal-crafts",
  },
  {
    label: "Wood Crafts",
    value: "wood-crafts",
  },
  {
    label: "Agriculture/Forestry",
    value: "agriculture-forestry",
  },
  {
    label: "Facility Management/Cleaning",
    value: "facility-management-cleaning",
  },
  {
    label: "Textile/Dressmaking/Tailoring",
    value: "textile-dressmaking-tailoring",
  },
  {
    label: "Storage",
    value: "storage",
  },
  {
    label: "Transport AIr/Sea/Rail/Road",
    value: "transport-air-sea-rail-road-",
  },
  {
    label: "Service",
    value: "service",
  },
  {
    label: "Medicine/Care/Therapy",
    value: "medicine-care-therapy",
  },
  {
    label: "Midwifery",
    value: "midwifery",
  },
  {
    label: "Laboratory/Analysis",
    value: "laboratory-analysis",
  },
  {
    label: "Doctors/Interns",
    value: "doctors-interns",
  },
  {
    label: "Med. Assistant/Admin",
    value: "med-assistant-admin",
  },
  {
    label: "Radiology",
    value: "radiology",
  },
  {
    label: "Nursing",
    value: "nursing",
  },
  {
    label: "Geriatrics",
    value: "geriatrics",
  },
  {
    label: "Psychology/Psychiatry",
    value: "psychology-psychiatry",
  },
  {
    label: "Therapy",
    value: "therapy",
  },
  {
    label: "Veterinary Medicine",
    value: "veterinary-medicine",
  },
  {
    label: "Public Admin./Education/Social",
    value: "public-admin-education-social",
  },
  {
    label: "Public Administration",
    value: "public-administration",
  },
  {
    label: "Science/Research",
    value: "science-research",
  },
  {
    label: "Teaching/Lecturing",
    value: "teaching-lecturing",
  },
  {
    label: "Social/Remedial Education",
    value: "social-remedial-education",
  },
  {
    label: "Social/Youth Work",
    value: "social-youth-work",
  },
  {
    label: "NGO/NPO/Charity",
    value: "ngo-npo-charity",
  },
  {
    label: "Catering/Food/Tourism",
    value: "catering-food-tourism",
  },
  {
    label: "Administration/Management",
    value: "administration-management",
  },
  {
    label: "Cooking/Cuisine",
    value: "cooking-cuisine",
  },
  {
    label: "Service/Bar",
    value: "service-bar",
  },
  {
    label: "Butcher/Baker/Pastry Chef/Cheese Maker",
    value: "butcher-baker-pastry-chef-cheese-maker",
  },
  {
    label: "Food Engineering/Technology",
    value: "food-engineering-technology",
  },
  {
    label: "Front Desk/Porter",
    value: "front-desk-porter",
  },
  {
    label: "Room Service/Cleaning/Housekeeping",
    value: "room-service-cleaning-housekeeping",
  },
  {
    label: "Travel Agencies/Tour Guiding",
    value: "travel-agencies-tour-guiding",
  },
  {
    label: "Graphic Art/Typography/Printing",
    value: "graphic-art-typography-printing",
  },
  {
    label: "Printing",
    value: "printing",
  },
  {
    label: "Graphic Art/Design",
    value: "graphic-art-design",
  },
  {
    label: "Typography/Polygraphy",
    value: "typography-polygraphy",
  },
  {
    label: "Surveillance/Police/Customs/Rescue",
    value: "surveillance-police-customs-rescue",
  },
  {
    label: "Surveillance/Control/Security",
    value: "surveillance-control-security",
  },
  {
    label: "Police/Military",
    value: "police-military",
  },
  {
    label: "Rescue/Fire Services",
    value: "rescue-fire-services",
  },
  {
    label: "Customs/Border Control",
    value: "customs-border-control",
  },
  {
    label: "Sport/Spas&Wellness/Culture",
    value: "sport-spaswellness-culture",
  },
  {
    label: "Hairdressing/Cosmetics",
    value: "hairdressing-cosmetics",
  },
  {
    label: "Massage/Sport/Spas&Wellness",
    value: "massage-sport-spaswellness",
  },
  {
    label: "Culture/Entertainment",
    value: "culture-entertainment",
  },
  {
    label: "Museum/Arts/Crafts",
    value: "museum-arts-crafts",
  },
  {
    label: "Fashion/Film/Theater/Radio",
    value: "fashion-film-theater-radio",
  },
];

export const getJobCategories = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLabel = createGetLabel(untranslatedList);

export const useJobCategories = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
