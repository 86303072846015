import * as Sentry from "@sentry/browser";
import { ReactNode, useEffect } from "react";

import { Route, useLocation } from "react-router";
import OutsideWrapper from "./OutsideWrapper";
import EditContactWithAccessKey from "./EditContactWithAccessKey";
import PageImprint from "./PageImprint";
import Config from "config";
import { applyTheme } from "@casasoft/styleguide/utilities/theming/updateThemeSettings";
import updateFavicons from "utilities/updateFavicons";
import CasaconnectAuthReturn from "./CasaconnectAuthReturn";
import Logout from "./Logout";
import ErrorPage from "./ErrorPage";
import OAuthReturn from "./OAuthReturn";
import checkPathIsPublicRoute from "utilities/checkPathIsPublicRoute";
import { addHotjar } from "utilities/addHotjar";

interface PublicRoutesProps {
  children: ReactNode;
}

async function applyThemeFromPublicSettings() {
  try {
    const res = await fetch(
      `${Config.apiUrl}/${Config.customerKey}/get-public-settings`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const json = await res.json();
    if (json.status === 500) {
      throw Error(`${json.status} ${json.detail}`);
    }

    applyTheme(json.theme);
  } catch (err) {
    const error = new Error(`Fetching public settings has failed`);
    Object.assign(error, {
      error: err,
    });
    Sentry.captureException(error);
  }
}

function PublicRoutes({ children }: PublicRoutesProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    applyThemeFromPublicSettings();
    updateFavicons();
    addHotjar();
  }, []);

  const publicPath = checkPathIsPublicRoute(pathname);

  return (
    <>
      {publicPath && (
        <>
          <Route
            path="/public/error"
            exact
            render={() => {
              return (
                <OutsideWrapper>
                  <ErrorPage />
                </OutsideWrapper>
              );
            }}
          />
          <Route
            path="/public/oauth-return"
            exact
            render={() => <OAuthReturn />}
          />
          <Route
            path="/public/logout/:lang?"
            exact
            render={() => {
              return (
                <OutsideWrapper>
                  <Logout />
                </OutsideWrapper>
              );
            }}
          />
          <Route
            path="/public/casaconnect-return"
            render={() => (
              <>
                <CasaconnectAuthReturn />
              </>
            )}
          />
          <Route
            path="/public/:lang/"
            render={() => {
              return (
                <>
                  <Route
                    path="/public/:lang/edit-contact/:accessKey"
                    exact
                    render={() => {
                      return <EditContactWithAccessKey />;
                    }}
                  />
                  <Route
                    path="/public/:lang/page/imprint"
                    exact
                    render={() => {
                      return (
                        <OutsideWrapper>
                          <PageImprint />
                        </OutsideWrapper>
                      );
                    }}
                  />
                </>
              );
            }}
          />
        </>
      )}
      {!publicPath && children}
    </>
  );
}

export default PublicRoutes;
