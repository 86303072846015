import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bubble, BubbleProps } from "./Bubble";

type BubbleSymbol = "check" | "xmark";

interface SymbolBubbleProps extends Omit<BubbleProps, "content"> {
  symbol: BubbleSymbol;
}

const renderSymbol = (symbol: BubbleSymbol) => {
  switch (symbol) {
    case "check":
      return <FontAwesomeIcon icon={faCheck} size="xs" />;
    case "xmark":
      return <FontAwesomeIcon icon={faTimes} size="xs" />;
  }
};

export const SymbolBubble = ({ symbol, ...rest }: SymbolBubbleProps) => (
  <Bubble {...rest} content={renderSymbol(symbol)} />
);
