import {
  CreateMediaTagSingleResponse,
  CreateMediaTagSingleParams,
} from "./types";
import { createInvalidatesTags } from "api/createEndpoint";

const invalidatesTags = createInvalidatesTags<
  CreateMediaTagSingleResponse,
  CreateMediaTagSingleParams
>((result, error, queryParams) => {
  if (Array.isArray(queryParams)) {
    return [{ type: "MediaTag", id: queryParams[0].media }];
  }
  return [{ type: "MediaTag", id: queryParams.media }];
});

export default invalidatesTags;
