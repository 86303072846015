import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetPhaseRelationshipQuery } from "api/entities/phase";

export const useContactLastPhase = ({
  recipientIds,
  propertyId,
}: {
  recipientIds: string[];
  propertyId?: string;
}) => {
  const params =
    recipientIds.length === 1 && propertyId
      ? {
          contact: recipientIds[0],
          property: propertyId,
        }
      : undefined;

  const getPhaseRelationshipResult = useGetPhaseRelationshipQuery(
    params || skipToken
  );

  return {
    error: getPhaseRelationshipResult.isError
      ? getPhaseRelationshipResult.error
      : undefined,
    latestPhaseId: getPhaseRelationshipResult.data?.latestPhase,
  };
};
