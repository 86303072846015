import { ReactNode } from "react";

interface CardBodyGraphColsItemProps {
  isList?: boolean;
  children: ReactNode;
}

function CardBodyGraphColsItem({
  children,
  isList,
}: CardBodyGraphColsItemProps) {
  return (
    <div
      className={`tw-min-w-0 ${
        isList
          ? "lg:tw-col-span-7"
          : "lg:tw-col-span-5 tw-p-4 tw-mx-auto tw-max-w-[15rem]"
      }`}
    >
      {children}
    </div>
  );
}

export default CardBodyGraphColsItem;
