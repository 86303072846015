import { EMPTY_BODY_HTML } from "../getMessageHistory";

export const mergeMessageBodyWithHistory = ({
  body = "",
  messageHistory,
  addMessageHistoryFlag,
}: {
  body?: string;
  messageHistory?: string;
  addMessageHistoryFlag: boolean;
}) => {
  if (addMessageHistoryFlag && messageHistory) {
    return [body, EMPTY_BODY_HTML, messageHistory].join("");
  }

  return body;
};
