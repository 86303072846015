export const getCasaoneMarketplaceIntegrationLink = (lang?: string) => {
  switch (lang) {
    case "fr":
      return "https://casasoft.ch/fr/integrationen/";
    case "it":
      return "https://casasoft.ch/it/integrazioni/";
    default:
      return "https://casasoft.ch/integrationen/";
  }
};
