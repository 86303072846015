import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export const NEW_EMAIL_PREFIX = "new:";

interface RecipientSelectNewEmailOptionProps {
  optionValue: string;
}

export const RecipientSelectNewEmailOption = ({
  optionValue,
}: RecipientSelectNewEmailOptionProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="tw-mb-1">
        <FontAwesomeIcon icon={faUser} className="tw-mr-1" />
        <span className="tw-font-semibold">
          {t("Create new contact with email")}
        </span>
      </div>
      <div>{optionValue.replace(NEW_EMAIL_PREFIX, "")}</div>
    </div>
  );
};
