import {
  EntityErrorMessage,
  toEntityErrorMessages,
} from "@casasoft/styleguide/utilities/api-error/entityError";
import {
  ApiErrorDefinition,
  isApiErrorDefinition,
} from "./apiErrorDefinitions";

export type FormModalOnFail = (
  errMessages?: EntityErrorMessage | EntityErrorMessage[] | ApiErrorDefinition,
  apiErrorContext?: string
) => void;

function handleFormModalError(
  error: unknown,
  onFail?: FormModalOnFail,
  apiErrorContext?: string
) {
  if (isApiErrorDefinition(error)) {
    onFail?.(error, apiErrorContext);
  } else {
    const errorMessages = toEntityErrorMessages(error);
    onFail?.(errorMessages, apiErrorContext);
  }
}

export default handleFormModalError;
