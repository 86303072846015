import { ReactNode } from "react";

interface CardBodyGraphColsProps {
  children: ReactNode;
}

function CardBodyGraphCols({ children }: CardBodyGraphColsProps) {
  return (
    <div className="tw-grid lg:tw-grid-cols-12 tw-gap-4 tw-text-sm">
      {children}
    </div>
  );
}

export default CardBodyGraphCols;
