import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CleanRootStateKeys } from "redux/store";
import { useDispatch } from "react-redux";
import resourceGrabber from "utilities/resourceGrabber";
import { changeResourcePage } from "redux/app";
import { Preset } from "./FilterForm";
import Select from "@casasoft/styleguide/components/formElements/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import usePreparedActiveFilterPreset from "hooks/filter-form/usePreparedActiveFilterPreset";
import usePreparedFilterPresets from "hooks/filter-form/usePreparedFilterPresets";

import { useUserFilterPresets } from "hooks/filter-form/useUserFilterPresets";

interface FilterFormSelectProps {
  presetName: CleanRootStateKeys | string;
  filterFormOptions?: Omit<Preset, "key">;
  onCreateFilter: () => void;
}

export const FilterFormSelect = ({
  presetName,
  filterFormOptions,
  onCreateFilter,
}: FilterFormSelectProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activateUserFilterPreset, updateUserFilterPreset } =
    useUserFilterPresets();

  const [filterPresetGeneratedKey, setFilterPresetGeneratedKey] =
    useState<string>((Math.random() + 1).toString(36).substring(7));

  const activeFilterPreset = usePreparedActiveFilterPreset(
    presetName,
    filterFormOptions
  );
  const filterPresets = usePreparedFilterPresets(presetName, filterFormOptions);

  const onFilterPresetSelect = (preset?: Preset) => {
    dispatch(changeResourcePage(presetName, 1));
    if (!preset) {
      activateUserFilterPreset({ presetName, presetKey: undefined });
    } else if (preset.key === "create-new-filter") {
      const previousUserFilters =
        resourceGrabber.grab("filterPresetList", "auth", presetName) || [];

      const nameValue = `${t("New Filter")} #${previousUserFilters.length + 1}`;
      updateUserFilterPreset({
        presetName,
        presetKey: filterPresetGeneratedKey,
        label: nameValue,
      });
      activateUserFilterPreset({
        presetName,
        presetKey: filterPresetGeneratedKey,
      });

      // generate new key for the next filter
      setFilterPresetGeneratedKey(
        (Math.random() + 1).toString(36).substring(7)
      );

      onCreateFilter();
    } else {
      activateUserFilterPreset({ presetName, presetKey: preset.key });
    }
  };

  return (
    <Select
      key={`_unique_select_key__${activeFilterPreset?.key}`}
      isClearable
      className="tw-m-0"
      placeholder={
        <span>
          <FontAwesomeIcon icon={faFilter} className="tw-mr-1" />
          {t("Filter")}
        </span>
      }
      onChange={(value: string) => {
        if (value) {
          const foundPresetObject = filterPresets.find((i) => i.key === value);
          if (foundPresetObject) {
            onFilterPresetSelect(foundPresetObject);
          }
        } else {
          onFilterPresetSelect();
        }
      }}
      value={activeFilterPreset?.key || ""}
      options={filterPresets.map((preset) => {
        return { value: preset.key, label: preset.label };
      })}
    />
  );
};

export default FilterFormSelect;
