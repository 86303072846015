import { casaoneApi } from "api";

import iaziCommonDataRequestQuery from "./endpoints/get-common-data/query";
import iaziCommonDataRequestProvidesTags from "./endpoints/get-common-data/providesTags";
import transformResponse from "./endpoints/get-common-data/transformResponse";

const iaziApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    iaziGetCommonData: builder.query({
      query: iaziCommonDataRequestQuery,
      providesTags: iaziCommonDataRequestProvidesTags,
      transformResponse: transformResponse,
    }),
  }),
  overrideExisting: false,
});

export const { useIaziGetCommonDataQuery, useLazyIaziGetCommonDataQuery } =
  iaziApi;
