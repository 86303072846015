import { casaoneApi, casaoneApi2 } from "api";
import singleQuery from "./endpoints/single/query";
import singleProvidesTags from "./endpoints/single/providesTags";
import listQuery from "./endpoints/list/query";
import listProvidesTags from "./endpoints/list/providesTags";
import userContactListQuery from "./endpoints/user-contact-list/query";
import userContactListProvidesTags from "./endpoints/user-contact-list/providesTags";
import updateSingleQuery from "./endpoints/update-single/query";
import updateSingleInvalidatesTags from "./endpoints/update-single/invalidatesTags";
import createSingleQuery from "./endpoints/create-single/query";
import createSingleInvalidatesTags from "./endpoints/create-single/invalidatesTags";
import deleteSingleQuery from "./endpoints/delete-single/query";

const userApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: singleQuery,
      providesTags: singleProvidesTags,
    }),
    getUserList: builder.query({
      query: listQuery,
      providesTags: listProvidesTags,
    }),
    getUserContactList: builder.query({
      query: userContactListQuery,
      providesTags: userContactListProvidesTags,
    }),
    updateUser: builder.mutation({
      query: updateSingleQuery,
      invalidatesTags: updateSingleInvalidatesTags,
    }),
    createUser: builder.mutation({
      query: createSingleQuery,
      invalidatesTags: createSingleInvalidatesTags,
    }),
  }),
  overrideExisting: false,
});

const userApi2 = casaoneApi2.injectEndpoints({
  endpoints: (builder) => ({
    deleteUser: builder.mutation({
      query: deleteSingleQuery,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useGetUserListQuery,
  useLazyGetUserListQuery,
  useCreateUserMutation,
  useGetUserContactListQuery,
  useLazyGetUserContactListQuery,
} = userApi;

export const { useDeleteUserMutation } = userApi2;
