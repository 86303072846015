import { useTranslation } from "react-i18next";

export interface CopyButtonsProps {
  showCcButton: boolean;
  showBccButton: boolean;
  onCCClick: () => void;
  onBCCClick: () => void;
}

export const CopyButtons = ({
  showCcButton,
  showBccButton,
  onCCClick,
  onBCCClick,
}: CopyButtonsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {showCcButton && (
        <div
          className="tw-absolute tw-top-6 tw-right-24 tw-cursor-pointer tw-text-cs-shade-500"
          onClick={onCCClick}
        >
          {t("CC")}
        </div>
      )}
      {showBccButton && (
        <div
          className="tw-absolute tw-top-6 tw-right-14 tw-cursor-pointer tw-text-cs-shade-500"
          onClick={onBCCClick}
        >
          {t("BCC")}
        </div>
      )}
    </>
  );
};
