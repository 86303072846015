import { useLazyGetPropertyListReducedQuery } from "api/entities/property";
import { FilterItem } from "utilities/queryBuilder";

export const ALL_PROPERTY_TYPES = ["property", "unit", "project"];
export const MAX_RESULTS = 5;

export const usePropertySelectDataFetch = () => {
  const [getPropertyList, propertyGetResult] =
    useLazyGetPropertyListReducedQuery();

  const fetchPropertiesBySearchTerm = async ({
    searchTerm,
  }: {
    searchTerm: string;
  }) => {
    const searchFilter: FilterItem[] = searchTerm
      ? [{ type: "search", value: searchTerm }]
      : [];

    try {
      const result = await getPropertyList({
        propertyType: ALL_PROPERTY_TYPES,
        query: {
          filter: [...searchFilter],
          orderBy: [{ type: "field", field: "status", direction: "asc" }],
          pageSize: MAX_RESULTS,
          page: 1,
        },
      }).unwrap();
      return result.items || [];
    } catch (error) {
      return [];
    }
  };

  const fetchPropertyById = async ({ id }: { id: string }) => {
    if (!id) {
      return undefined;
    }

    const searchFilter: FilterItem[] = [{ field: "id", type: "eq", value: id }];

    try {
      const result = await getPropertyList({
        propertyType: ALL_PROPERTY_TYPES,
        query: {
          filter: [...searchFilter],
          orderBy: [],
          pageSize: 1,
          page: 1,
        },
      }).unwrap();
      return result.items?.length ? result.items[0] : undefined;
    } catch (error) {
      return undefined;
    }
  };

  return {
    fetchPropertiesBySearchTerm,
    fetchPropertyById,
    isError: !!propertyGetResult.isError,
  };
};
