import cx from "classnames";

export interface HeightExpanderProps {
  isExpanded: boolean;
  transitionDuration?: number;
  children: React.ReactNode;
  className?: string;
}

export const HeightExpander = ({
  isExpanded,
  children,
  className,
  transitionDuration = 0.25,
}: HeightExpanderProps) => {
  const wrapperClassName = cx(
    "tw-grid",
    { "tw-grid-rows-[0fr]": !isExpanded },
    { "tw-grid-rows-[1fr]": isExpanded },
    className
  );
  const wrapperStyle = {
    transition: `grid-template-rows ${transitionDuration}s ease-in-out`,
  };
  const innerClassName = cx("tw-overflow-hidden");

  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      <div className={innerClassName}>{children}</div>
    </div>
  );
};
