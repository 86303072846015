import { CasaoneBaseQueryArgs, CasaoneResultDescription } from "api/types";

type TransfromReponseFn<TResponse> = (
  originalRepsonse: unknown
) => Promise<TResponse> | TResponse;

/**
 * Create a typed transform response function
 * @param transformFn A transform response function
 * @returns transformFn
 */
export function createTransformResponse<
  TRes = never,
  TResponse extends TRes = TRes // https://stackoverflow.com/a/57683742/9191773
>(transformFn: TransfromReponseFn<TResponse>) {
  return transformFn;
}

type QueryFn<TParams> = (params: TParams) => CasaoneBaseQueryArgs;

/**
 * Create a typed query function
 * @param queryFn  A query function
 * @returns queryFn
 */
export function createQuery<TParams = never>(queryFn: QueryFn<TParams>) {
  return queryFn;
}

/**
 * Create a typed providesTags function
 * @param providesTagsFn  A function that returns a list of tags that are provided by this endpoint
 * @returns  providesTagsFn
 */
export function createProvidesTags<TResponse = never, TParams = never>(
  providesTagsFn: CasaoneResultDescription<TResponse, TParams>
) {
  return providesTagsFn;
}

/**
 * Create a typed invalidatesTags function
 * @param invalidatesTagsFn A function that returns a list of tags that are invalidated by this endpoint
 * @returns invalidatesTagsFn
 */
export function createInvalidatesTags<TResponse = never, TParams = never>(
  invalidatesTagsFn: CasaoneResultDescription<TResponse, TParams>
) {
  return invalidatesTagsFn;
}
