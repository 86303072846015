import Config, { Env } from "config";
import store from "redux/store";
import { PROPERTY_CREATE_TOUR } from "tours/property-onboarding/propertyCreateOnboarding";
import { PROPERTY_DETAILS_TOUR } from "tours/property-onboarding/propertyDetailsOnboarding";
import { PROPERTY_EDETAILS_TOUR } from "tours/property-onboarding/propertyEditDetailsOnboarding";
import { USER_CREATE_TOUR } from "tours/user-onboarding/userCreateOnboarding";
import { USER_DELETE_TOUR } from "tours/user-onboarding/userDeleteOnboarding";
import {
  KCRoles,
  CasaiamRoles,
  Role,
  isRole,
  Roles,
} from "./type-helpers/userRoles";

// outline current architecture and refactor and migrate to hooks
// move the store logic to selectors (e.g. getCurrentRole, getCurrentPlan)
// split file by logic (e.g. constants, selectors, utility functions)
// add tests

// Local storage key for the simulated role
export const SIMULATED_ROLE_STORAGE_KEY = "simulatedRole";
export const SIMULATED_PLAN_STORAGE_KEY = "simulatedPlan";

export enum PlanTypes {
  Pro = "pro",
  Basic = "basic",
  Starter = "starter",
}

export const superDuperUsers = [
  "dominic.fath",
  "lukas.bossart",
  "yasin.tuna",
  "sandro.trottmann",
  "yavuz.cini",
  "francesco.patrizio",
  "jacqueline.co",
  "yves.studer",
  "felix.leeser",
  "stanislav.sarsanski",
  "federico.degan",
  "dominique.dijoux",
  "stefan.antic",
  "angelo.rivolta",
  "ornella.mancuso",
  "laetitia.privet",
  "christophe.braun",
  "sonia.barzaghi",
  "david.ziegler",
];

/** Check whether the current company is "casasoft" (crm) */
export function userIsCasasoft() {
  const state = store.getState()["auth"];
  const currUsername = state.profile.data.casaoneUser?.username || "";

  if (currUsername.includes("@casasoft.")) {
    return true;
  }

  if (currUsername) {
    const isSupoerDuperUserIndex = superDuperUsers.findIndex((userpart) => {
      const regex = new RegExp(userpart);
      return currUsername.search(regex) !== -1;
    });
    if (isSupoerDuperUserIndex !== -1) {
      return true;
    }
  }
  return false;
}

export function getCurrentRole() {
  const authStore = store.getState()["auth"];
  const isCasasoft = userIsCasasoft();
  const currentRoleSimulated =
    isCasasoft && localStorage.getItem(SIMULATED_ROLE_STORAGE_KEY);
  const currentRole =
    currentRoleSimulated || authStore.profile.data.casaoneUser?.roles?.[0];

  if (
    currentRole &&
    isRole(currentRole) &&
    Object.keys(Roles).includes(currentRole)
  ) {
    // Role Typeguard plus casting to Role type
    return currentRole;
  } else throw new Error("Invalid role");
}

export function getCurrentPlan() {
  const authStore = store.getState()["auth"];
  const isCasasoft = userIsCasasoft();
  const currentPlanSimulated =
    isCasasoft && localStorage.getItem(SIMULATED_PLAN_STORAGE_KEY);
  const currentPlan =
    currentPlanSimulated ||
    authStore.profile.data.company?.contracts?.[0]?.options?.plan;

  switch (currentPlan) {
    case "pro":
      return PlanTypes.Pro;
    case "basic":
      return PlanTypes.Basic;
    case "starter":
      return PlanTypes.Starter;
    default:
      throw new Error(`Unknown plan: ${currentPlan}`);
  }
}

// https://stackoverflow.com/questions/48230773/how-to-create-a-partial-like-that-requires-a-single-property-to-be-set
type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

interface FeatureFlagAllowedRules {
  rolesWithAccess: Role[];
  plansWithAccess: PlanTypes[];
  envWithAccess: Env[];
}

type FeatureFlagBase<T1> = FeatureFlagAllowedRules & {
  parentFlag: T1;
};
export type FeatureFlagItem = Partial<FeatureFlagBase<string>> & {
  description: string;
};

function createFeatureFlagMap<T1>(cfg: {
  [K in keyof T1]: AtLeastOne<FeatureFlagBase<keyof T1>> & {
    description: string;
  };
}) {
  return cfg;
}

export const featureFlagMap = createFeatureFlagMap({
  basicCasaoneFeatures: {
    description: "access to alerts/dob list/notifications panel/faq/news ",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  yourUserSettings: {
    description: "access to comepleteprofile, user settings",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  yourSignatureSettings: {
    description: "access to your profile signature settings",
    parentFlag: "yourUserSettings",
    plansWithAccess: [PlanTypes.Pro],
  },
  userSignatureSettings: {
    description: "access to other users signature settings",
    parentFlag: "yourSignatureSettings",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  userSettings: {
    description: "access to the user settings/list",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  companySettings: {
    description: "access to the company profile settings",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  publisherStats: {
    description: "access to the publisher statistics and graphs",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  publisherSettings: {
    description: "access to the publisher/interfaces settings/list",
    parentFlag: "publisherStats",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  templateSettings: {
    description: "access to the template settings",
    plansWithAccess: [PlanTypes.Pro],
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  tagSettings: {
    description: "access to the tag settings",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  serviceSettings: {
    description: "access to the (IAZI) service settings/list",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  placeSettings: {
    description: "access to the places/municipality settings/list",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  invoiceManagement: {
    description: "access to the invoices list and settings",
    rolesWithAccess: [],
  },
  basicContactFunctionallity: {
    description:
      "access to basic contact functionality which is necessary even for plans without the contact module enabled",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  contactManagement: {
    description: "access to the contact module and contact related features ",
    parentFlag: "basicContactFunctionallity",
    plansWithAccess: [PlanTypes.Pro, PlanTypes.Basic],
  },
  eventManagement: {
    description: "access to the event module and event related features ",
    plansWithAccess: [PlanTypes.Pro],
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  messageManagement: {
    description: "access to the message module and message related features ",
    plansWithAccess: [PlanTypes.Pro],
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  messageCompanyAdmin: {
    description: "access to the company-level mail configuration",
    parentFlag: "messageManagement",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  propertyManagement: {
    description: "access to the property module and property related features ",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  propertyStats: {
    description: "access to the property statistics and graphs ",
    parentFlag: "propertyManagement",
    plansWithAccess: [PlanTypes.Pro],
  },
  propertyMatchings: {
    description: "access to the property matchings ",
    parentFlag: "propertyManagement",
    plansWithAccess: [PlanTypes.Pro, PlanTypes.Basic],
  },
  propertyPDFGeneration: {
    description: "access to the property pdf generation ",
    parentFlag: "propertyManagement",
    plansWithAccess: [PlanTypes.Basic, PlanTypes.Pro],
  },
  projectPDFGeneration: {
    description: "access to the project pdf generation ",
    parentFlag: "propertyManagement",
    plansWithAccess: [PlanTypes.Basic, PlanTypes.Pro],
  },
  bookPromotions: {
    description:
      "access to top listing/promotions and possibility to book them",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  reportManagement: {
    description: "access to the report module and report related features ",
    plansWithAccess: [PlanTypes.Pro],
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_REPORTER,
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  reportManagementWrite: {
    description: "acces to the report module including write rights",
    parentFlag: "reportManagement",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  mediaManagement: {
    description: "access to the media module and media related features ",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_PHOTOGRAPHER,
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  mediaManagementWrite: {
    description: "acces to the media module including write rights",
    parentFlag: "mediaManagement",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  jobManagement: {
    description: "access to the job module and job related features ",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  iaziPropertyCreation: {
    description: "access to iazi services during property creation",
    plansWithAccess: [PlanTypes.Pro],
  },
  [USER_CREATE_TOUR]: {
    description: "access to the user creation tour",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  [USER_DELETE_TOUR]: {
    description: "access to the user deletion tour",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
    ],
  },
  [PROPERTY_CREATE_TOUR]: {
    description: "access to the property creation tour",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
    ],
  },
  [PROPERTY_DETAILS_TOUR]: {
    description: "access to the property details tour",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
    ],
  },
  [PROPERTY_EDETAILS_TOUR]: {
    description: "access to the edit property details tour",
    rolesWithAccess: [
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_ADMIN,
      KCRoles.ROLE_KC_CASASOFT_SUPER_ADMIN,
      CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN_BILLING,
      CasaiamRoles.ROLE_CASAONE_COMPANY_USER,
      KCRoles.ROLE_KC_CASAIAM_ACCESS,
    ],
  },
  // // EXAMPLES
  // phaseManagement: {
  //   rolesWithAccess: [CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN],
  // },
  // combinedFlagsExample: {
  //   plansWithAccess: [PlanTypes.Basic],
  //   rolesWithAccess: [CasaiamRoles.ROLE_CASAONE_COMPANY_ADMIN],
  // },
  // envFlagExample: {
  //   envWithAccess: [Env.local],
  // },
});

type FeatureFlagMapKeys = keyof typeof featureFlagMap;

/**
 * Compares a provided flag for a given flag type against the current user
 * @param featureArg The feature flag id
 * @param flagToCompare The flag type to compare against
 * @returns boolean
 */
function compareFlagWithCurrentUser(
  featureArg: FeatureFlagMapKeys,
  flagToCompare: keyof FeatureFlagAllowedRules
) {
  const currPlan = getCurrentPlan();
  const currRole = getCurrentRole();

  const featureToCompare = featureFlagMap[featureArg];

  /** If the FF is force enabled in localStorage, we skip all logic and just return true */
  if (
    localStorage
      .getItem("co_cs_enabled_force_feature_flags")
      ?.split(",")
      ?.includes(featureArg)
  ) {
    return true;
  }

  switch (flagToCompare) {
    case "envWithAccess":
      return (
        !featureToCompare.envWithAccess || // if not defined -> All Envs have access
        featureToCompare.envWithAccess.includes(Config.env)
      );
    case "plansWithAccess":
      return (
        !featureToCompare.plansWithAccess || // if not defined -> All Plans have access
        featureToCompare.plansWithAccess.includes(currPlan)
      );
    case "rolesWithAccess":
      return (
        !featureToCompare.rolesWithAccess || // if not defined -> All Roles have access
        featureToCompare.rolesWithAccess.includes(currRole)
      );

    default:
      throw new Error("Unknown flag provided to compare.");
  }
}

// HAS ACCESS HELPER (NOT verbose)
/** A method to check if a feature is available for the current logged in user  */
export function hasAccessTo(featureArg: FeatureFlagMapKeys) {
  const featureToCompare = featureFlagMap[featureArg];

  // check parent rules recursively and return false if it already fails there
  if (
    featureToCompare.parentFlag &&
    !hasAccessTo(featureToCompare.parentFlag)
  ) {
    return false;
  }

  return (
    compareFlagWithCurrentUser(featureArg, "envWithAccess") &&
    compareFlagWithCurrentUser(featureArg, "plansWithAccess") &&
    compareFlagWithCurrentUser(featureArg, "rolesWithAccess")
  );
}

// HAS NO ACCESS HELPER (verbose)
type HasNoAccesBecauseFullReturn = {
  envWithAccess: Env[];
  plansWithAccess: PlanTypes[];
  rolesWithAccess: Role[];
};

/** method will return all rules that cause the current user to have NO access */
function hasNoAccesBecauseFull(
  featureArg: FeatureFlagMapKeys
): HasNoAccesBecauseFullReturn {
  const featureToCompare = featureFlagMap[featureArg];

  // ENV
  const envNeededWithAccess = compareFlagWithCurrentUser(
    featureArg,
    "envWithAccess"
  )
    ? []
    : featureToCompare.envWithAccess || [];
  const parentEnvNeededWithAccess = featureToCompare.parentFlag
    ? hasNoAccesBecauseFull(featureToCompare.parentFlag).envWithAccess
    : [];
  // ROLES
  const rolesNeededWithAccess = compareFlagWithCurrentUser(
    featureArg,
    "rolesWithAccess"
  )
    ? []
    : featureToCompare.rolesWithAccess || [];
  const parentRolesNeededWithAccess = featureToCompare.parentFlag
    ? hasNoAccesBecauseFull(featureToCompare.parentFlag).rolesWithAccess
    : [];
  // PLANS
  const plansNeededWithAccess = compareFlagWithCurrentUser(
    featureArg,
    "plansWithAccess"
  )
    ? []
    : featureToCompare.plansWithAccess || [];
  const parentPlansNeededWithAccess = featureToCompare.parentFlag
    ? hasNoAccesBecauseFull(featureToCompare.parentFlag).plansWithAccess
    : [];

  // MERGE
  return {
    envWithAccess: [...envNeededWithAccess, ...parentEnvNeededWithAccess],
    plansWithAccess: [...plansNeededWithAccess, ...parentPlansNeededWithAccess],
    rolesWithAccess: [...rolesNeededWithAccess, ...parentRolesNeededWithAccess],
  };
}

/** method will return all rules that cause the current user to have NO access and omits those which are no problem */
function hasNoAccesBecause(featureArg: FeatureFlagMapKeys) {
  const reasons = hasNoAccesBecauseFull(featureArg);

  const cleanedUpReasons: Partial<HasNoAccesBecauseFullReturn> = { ...reasons };

  let hasRestrictingRules = false;
  for (const reason in reasons) {
    const properReasonKey = reason as keyof typeof reasons;
    const currentRules = reasons[properReasonKey];
    if (currentRules.length) {
      hasRestrictingRules = true;
    } else {
      delete cleanedUpReasons[properReasonKey]; // delete empty arrays from cleanedup object
    }
  }

  if (hasRestrictingRules) {
    return cleanedUpReasons;
  }
}

/** Utility that returns the minimum required PlanType for a asked feature flag. Returns "NOACCESS" if there are other rules conflicting. Returns undefiend when the user has access to that feature */
export function getMinimumRequiredPlan(featureArg: FeatureFlagMapKeys) {
  const noAccesBecause = hasNoAccesBecause(featureArg);
  // if it's failing because of other feature flags - return immediately
  if (noAccesBecause?.rolesWithAccess || noAccesBecause?.envWithAccess) {
    return "NOACCESS";
  }

  // hierarchy returns (skipping starter):
  if (noAccesBecause?.plansWithAccess?.includes(PlanTypes.Basic)) {
    return PlanTypes.Basic;
  }
  if (noAccesBecause?.plansWithAccess?.includes(PlanTypes.Pro)) {
    return PlanTypes.Pro;
  }
}
