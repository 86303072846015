import { useState } from "react";
import type { LanguageGroupData } from "../LanguageBreakdownContainer";

export const useLanguageGroupModal = (languageGroups: LanguageGroupData[]) => {
  const [selectedLanguageGroup, setSelectedLanguageGroup] = useState<
    LanguageGroupData | undefined
  >(undefined);

  const handleInfoClick = (lang: string) => {
    setSelectedLanguageGroup(
      languageGroups.find((group) => group.key === lang)
    );
  };

  const handleLanguageGroupModalClose = () => {
    setSelectedLanguageGroup(undefined);
  };

  return {
    selectedLanguageGroup,
    handleInfoClick,
    handleLanguageGroupModalClose,
  };
};
