import { createInvalidatesTags } from "api/createEndpoint";
import { MutateMediaTagQueryParams, MutateMediaTagResponse } from "./types";

const invalidatesTags = createInvalidatesTags<
  MutateMediaTagResponse,
  MutateMediaTagQueryParams
>((result, error, queryParams) => {
  if (Array.isArray(queryParams)) {
    return [{ type: "MediaTag", id: queryParams[0].media }];
  }
  return [{ type: "MediaTag", id: queryParams.media }];
});

export default invalidatesTags;
