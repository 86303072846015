import FieldContainer from "./helpers/FieldContainer";
import DateFieldElem from "./helpers/DateFieldElem";
import FieldContainerInterface from "./helpers/FieldContainerInterface";

interface DateFieldProps extends FieldContainerInterface {
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
  rangeStart?: string;
  rangeEnd?: string;
  locale?: string;
  showTodayButton?: boolean;
  isOutsideRange?: (day: moment.Moment) => boolean;
}

const DateField = ({
  prefix = "",
  suffix = "",
  rangeStart = "",
  rangeEnd = "",
  locale = "de-ch",
  showTodayButton,
  isOutsideRange,
  ...otherProps
}: DateFieldProps) => {
  return (
    <FieldContainer
      renderElem={(passedDownProps) => {
        const dateFieldElemProps: any = Object.assign(passedDownProps, {
          autoComplete: passedDownProps.autoComplete ? "on" : "off",
        });
        return (
          <DateFieldElem
            {...dateFieldElemProps}
            prefix={prefix}
            suffix={suffix}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            locale={locale}
            showTodayButton={showTodayButton}
            isOutsideRange={isOutsideRange}
          />
        );
      }}
      {...otherProps}
    />
  );
};

export default DateField;
