import { EventShape } from "entities/event/types";
import { MessageCreateForm, MessageCreateFormShape } from "./MessageCreateForm";
import {
  mapPropertyIdToPropertyValue,
  mapRecipientIdToContacts,
} from "./helpers/createFormContainerPropsHelper";
import { SendingMessageContainer } from "./SendingMessage/SendingMessageContainer";
import { useEffect } from "react";
import { RecipientListFn, useRecipientList } from "./helpers/useRecipientList";
import { LanguageBreakdownContainer } from "components/contact/features/language-breakdown/LanguageBreakdownContainer";
import { useLanguageBreakdown } from "./helpers/useLanguageBreakdown";
import { getMessageHistoryHtml } from "./helpers/getMessageHistory";
import {
  OnDoneArg,
  useMessageFormOnSubmit,
} from "./helpers/useMessageFormOnSubmit/useMessageFormOnSubmit";
import { useMessageAnalytics } from "./helpers/useMessagingAnalytics";

interface MessageCreateFormContainerProps {
  onDone?: (data: OnDoneArg) => void;
  onFail?: () => void;
  propertyId?: string;
  recipientId?: string;
  thread?: EventShape;
  type?: string;
  recipientList?: RecipientListFn;
  prependEvents?: EventShape[];
  lastInboundMessage?: EventShape;
  lang?: string;
  subject?: string;
}

export const MessageCreateFormContainer = ({
  onDone,
  onFail,
  propertyId,
  recipientId,
  recipientList,
  lang,
  type,
  thread,
  lastInboundMessage,
  prependEvents,
  subject,
}: MessageCreateFormContainerProps) => {
  const { sendStartMessageFlowEvent } = useMessageAnalytics();

  const defaultData: Partial<MessageCreateFormShape> = {
    contact: mapRecipientIdToContacts(recipientId),
    relation: "prospective-customer",
    eventBaseType: type,
    messageHistory: getMessageHistoryHtml(
      prependEvents
        ? prependEvents
        : lastInboundMessage
        ? [lastInboundMessage]
        : []
    ),
    lang,
    subject,
  };
  // difference between initialData and defaultData is that initialData is used to set the initial values of the form once,
  // defaultData is used to set the default values of the form every time the form is reset or value is set to undefined
  const initialData: Partial<MessageCreateFormShape> = {
    property: mapPropertyIdToPropertyValue(propertyId),
  };

  const { onSubmit, isSending, messageCount, sendCanceled, sentMessages } =
    useMessageFormOnSubmit({ thread, onDone, onFail });
  const {
    languageRecipients,
    loadRecipients,
    isLoadingLanguageRecipients,
    recipientsLimit,
  } = useRecipientList(recipientList);
  const {
    handleLanguageBreakdownModalClose,
    handleLanguageBreakdownOnDone,
    isLanguageBreakdownModalOpen,
    selectedRecipients,
    setIsLanguageBreakdownModalOpen,
  } = useLanguageBreakdown(recipientsLimit);

  useEffect(() => {
    loadRecipients();
    if (!!recipientList) {
      setIsLanguageBreakdownModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendStartMessageFlowEvent({
      messageType: type,
      propertyId,
      recipientId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isSending && (
        <SendingMessageContainer
          messageCount={messageCount}
          sendCanceled={sendCanceled}
          sentMessages={sentMessages}
          className="tw-mb-3"
        />
      )}
      <LanguageBreakdownContainer
        isOpen={isLanguageBreakdownModalOpen}
        onClose={handleLanguageBreakdownModalClose}
        onDone={handleLanguageBreakdownOnDone}
        languageGroups={languageRecipients || []}
        isLoading={isLoadingLanguageRecipients}
      />
      <MessageCreateForm
        defaultData={defaultData}
        initialData={initialData}
        thread={thread}
        onSubmit={onSubmit}
        isVisible={!isSending}
        updatedRecipientList={selectedRecipients}
      />
    </>
  );
};
