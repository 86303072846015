import type { UseFormWatch } from "react-hook-form";
import type { MessageCreateFormShape } from "../MessageCreateForm";

export const useRelationPhaseWatch = ({
  watch,
}: {
  watch: UseFormWatch<MessageCreateFormShape>;
}) => {
  const propertyId = watch("property")?.value;
  const recipientIds: string[] = watch(["contact", "contactCC", "contactBCC"])
    ?.flat()
    .map((contact) => contact?.value)
    .filter((contact) => !!contact) as string[];
  const eventType = watch("eventType");
  const eventBaseType = watch("eventBaseType");

  return {
    recipientIds,
    propertyId,
    eventType,
    eventBaseType,
  };
};
