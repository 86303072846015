import { useEffect } from "react";
import { PropertySelectValueType } from "./usePropertySelect";
import { usePropertySelectDataFetch } from "./usePropertySelectDataFetch";

interface UsePropertyDataPreloadProps {
  value?: PropertySelectValueType;
  onChange?: (newValue: PropertySelectValueType | null) => void;
}

export const usePropertyDataPreload = ({
  value,
  onChange,
}: UsePropertyDataPreloadProps) => {
  const { fetchPropertyById } = usePropertySelectDataFetch();

  useEffect(() => {
    // when we provide propertyId as value to the component without property object, then preload happens here (only once on load)
    if (value?.value && !value.property) {
      fetchPropertyById({ id: value.value }).then((property) => {
        if (property && onChange) {
          onChange({
            value: value.value,
            property,
          });
        }
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
