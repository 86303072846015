import { NavLink } from "react-router-dom";
import { Item, TableListItemProps } from "./TableListItem";
import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

interface TableListColumnWrapperProps extends Pick<TableListItemProps, "item"> {
  itemHref?: string;
  onItemClick?: (item: Item) => void;
  itemClassNames?: string;
  itemPathName?: string;
  children: ReactElement;
}

export const TableListColumnWrapper = ({
  children,
  onItemClick,
  itemClassNames,
  itemHref,
  itemPathName,
  item,
}: TableListColumnWrapperProps) => {
  if (onItemClick) {
    return (
      <div
        role="button"
        tabIndex={0}
        className={twMerge(itemClassNames, "tw-cursor-pointer")}
        onClick={(e) => {
          e.preventDefault();
          onItemClick(item);
        }}
      >
        {children}
      </div>
    );
  }

  if (itemHref) {
    return (
      <a
        href={itemHref}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={0}
        className={twMerge(itemClassNames, "tw-cursor-pointer")}
      >
        {children}
      </a>
    );
  }

  if (itemPathName) {
    return (
      <NavLink
        to={{
          pathname: itemPathName,
        }}
        role="button"
        tabIndex={0}
        className={itemClassNames}
        activeClassName={`active-navlink-tablelist-tw-helper tw-bg-cs-shade-50 tw-border-r-2 tw-border-r-tw-cs-accent-color`}
      >
        {children}
      </NavLink>
    );
  }

  return <div className={twMerge(itemClassNames, "tw-grow")}>{children}</div>;
};
