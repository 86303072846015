import Button from "@casasoft/styleguide/components/forms/Button";
import { ModalFooter } from "@casasoft/styleguide/components/modal";
import { useTranslation } from "react-i18next";

interface AddReminderFormFooterProps {
  isDisabled?: boolean;
  isUpdate?: boolean;
  onClear?: () => void;
}

export const AddReminderFormFooter = ({
  isDisabled = false,
  isUpdate = false,
  onClear,
}: AddReminderFormFooterProps) => {
  const { t } = useTranslation();

  return (
    <ModalFooter>
      <div className="tw-flex tw-gap-2">
        {isUpdate && (
          <Button isDanger isOutline={false} onClick={onClear}>
            {t("Remove")}
          </Button>
        )}
        <Button isPrimary isOutline={false} disabled={isDisabled} type="submit">
          {isUpdate ? t("Update") : t("Add")}
        </Button>
      </div>
    </ModalFooter>
  );
};
