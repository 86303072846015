import shouldRefetchAuthToken from "./shouldRefetchAuthToken";

async function waitForPreviousTokenRefresh(): Promise<void> {
  // we proactively check if we will have to refetch later and mark it as fetching here already.
  // if we were to do this in the fetch itself, two synchronous calls would both think they are the first when calling this method
  if (!window.__isFetchingAuthToken && shouldRefetchAuthToken()) {
    window.__isFetchingAuthToken = true;
    return;
  }

  if (!window.__isFetchingAuthToken) {
    return;
  }

  // polling of Nms to check again
  await new Promise<void>((res) => {
    setTimeout(() => {
      res();
    }, 50);
  });
  return waitForPreviousTokenRefresh();
}

export default waitForPreviousTokenRefresh;
