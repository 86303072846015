import queryBuilder, { OrderByItem, FilterItem, Params } from "./queryBuilder";
import store, { CleanRootStateKeys, RootState } from "redux/store";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import BaseState from "entities/baseState";
import { createGtagEvent } from "./gtagHelper";

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

interface FetchListArgs {
  previousStateValidateContext?: string;
  orderBy?: OrderByItem[];
  filter?: FilterItem[];
  urlParams?: Params;
  pageSize?: number;
  page?: number;
  apiContext?: string | null;
  force?: boolean;
}

interface GetItemArgs {
  previousStateValidateContext?: string;
  id: string | number;
  params?: Params;
  force?: boolean;
}

export interface CreateHookArgs {
  /** call fetchList automatically (boolean or arguments) */
  fetchList?: FetchListArgs | boolean;
  /** call fetchList automatically (id or arguments with id) */
  getItem?: GetItemArgs | string | number;
  /** a context string that validates the previous existing state. If the string does not match, the previous state will be cleared immediatly */
  previousStateValidateContext?: string;
}

/**
 * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
 */
const createResourceHelper = <TCleanRootStateKeys extends CleanRootStateKeys>({
  actions,
  name,
  pluralName,
}: {
  actions: any;
  name: string;
  pluralName: TCleanRootStateKeys;
}) => {
  const capitalizedName = capitalizeFirstLetter(name);
  const capitalizedPluralName = capitalizeFirstLetter(pluralName);

  // FETCH METHOD
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const fetchList = async ({
    orderBy = [],
    filter = [],
    urlParams = {},
    page = 1,
    pageSize = 20,
    apiContext = null,
    force,
    previousStateValidateContext,
  }: FetchListArgs = {}) => {
    const state = store.getState();

    const filterQuery = queryBuilder.buildToUrl(null, filter, urlParams);
    const orderByQuery = queryBuilder.buildToUrl(orderBy, null, urlParams);

    const pureParams = {
      filter,
      orderBy,
      pageSize: pageSize,
      apiContext,
      filterString: filterQuery,
      orderByString: orderByQuery,
      page: page > 1 ? page : undefined,
    };

    let fetchNew = true;

    // we type cast the type because of this: https://stackoverflow.com/questions/63670841/typescript-type-inference-generic-type-prop-in-condition-throws-an-error
    const lastState: RootState[CleanRootStateKeys] = state[pluralName];

    if ("lastQuery" in lastState) {
      if (
        // lastQuery exists
        lastState.lastQuery &&
        // pageSize is the same
        lastState.lastQuery.page === pureParams.page &&
        lastState.lastQuery.pageSize === pureParams.pageSize &&
        // filter and orderBy didnt change
        lastState.lastQuery.filterString === filterQuery &&
        lastState.lastQuery.orderByString === orderByQuery &&
        lastState.lastQuery.listValidateContext === previousStateValidateContext
      ) {
        fetchNew = false;
      }

      // if filters or orderBy or validateContext change, reset page to undefined aka 1
      if (
        (lastState.lastQuery?.filterString !== filterQuery ||
          lastState.lastQuery?.orderByString !== orderByQuery ||
          lastState.lastQuery.listValidateContext !==
            previousStateValidateContext) &&
        pureParams.page !== undefined &&
        pureParams.page > 1
      ) {
        pureParams.page = undefined;
      }

      // clear previous state if valdiateContext don't match
      if (process.env.NODE_ENV === "development") {
        if (!actions[`clearPreviousListState${capitalizedName}`]) {
          throw new Error(
            `Entity ${capitalizedName} has missing actions or is not set-up correctly`
          );
        }
      }
      if (
        previousStateValidateContext !==
        lastState.lastQuery?.listValidateContext
      ) {
        store.dispatch(actions[`clearPreviousListState${capitalizedName}`]());
      }
    }

    type ItemsType = typeof state[typeof pluralName] extends BaseState
      ? typeof state[typeof pluralName]["items"]
      : never;

    // always fetch if force is true
    if (fetchNew || force) {
      // check if actions exist for entity
      if (process.env.NODE_ENV === "development") {
        if (
          !actions[`fetch${capitalizedPluralName}`] ||
          !actions[`commitQueryFor${capitalizedName}`]
        ) {
          throw new Error(
            `Entity ${capitalizedName} has missing actions or is not set-up correctly`
          );
        }
      }
      // hydrate pureParams into lastQuery property
      store.dispatch(
        actions[`commitQueryFor${capitalizedName}`]({
          ...pureParams,
          listValidateContext: previousStateValidateContext,
          listUrlParams: urlParams,
        })
      );

      // fetch and return
      const response = await store.dispatch(
        actions[`fetch${capitalizedPluralName}`](urlParams, pureParams)
      );

      return (response.body || []) as ItemsType;
    }
    // TODO: proper type inference
    return ("items" in lastState ? lastState.items : []) as ItemsType;
  };

  // FETCH SINGLE METHOD
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const getItem = async ({
    id,
    params,
    force,
    previousStateValidateContext,
  }: GetItemArgs) => {
    const state = store.getState();
    const lastState: RootState[CleanRootStateKeys] = state[pluralName];

    const paramsQuery = queryBuilder.buildToUrl(null, null, params);

    let fetchNew = true;
    let anotherFetchIsHappening = false;

    if ("lastQuery" in lastState) {
      if (
        lastState.lastQuery &&
        id === lastState.lastQuery.getItemParams?.id &&
        paramsQuery === lastState.lastQuery.getItemParams?.paramsQuery &&
        lastState.lastQuery.itemValidateContext === previousStateValidateContext
      ) {
        fetchNew = false;
        if (lastState.isFetchingItem) {
          anotherFetchIsHappening = true;
        }
      }

      // clear previous state if valdiateContext don't match
      if (process.env.NODE_ENV === "development") {
        if (!actions[`clearPreviousItemState${capitalizedName}`]) {
          throw new Error(
            `Entity ${capitalizedName} has missing actions or is not set-up correctly`
          );
        }
      }
      if (
        previousStateValidateContext !==
        lastState.lastQuery?.itemValidateContext
      ) {
        store.dispatch(actions[`clearPreviousItemState${capitalizedName}`]());
      }
    }

    type ItemType = typeof state[typeof pluralName] extends BaseState
      ? typeof state[typeof pluralName]["item"]
      : never;

    if (anotherFetchIsHappening || fetchNew || force) {
      // check if actions exist for entity
      if (process.env.NODE_ENV === "development") {
        if (
          !actions[`get${capitalizedName}`] ||
          !actions[`commitQueryFor${capitalizedName}`]
        ) {
          throw new Error(
            `Entity ${capitalizedName} has missing actions or is not set-up correctly`
          );
        }
      }

      // hydrate params into lastQuery property
      store.dispatch(
        actions[`commitQueryFor${capitalizedName}`]({
          itemValidateContext: previousStateValidateContext,
          getItemParams: {
            id,
            paramsQuery,
            pureParams: params ? { ...params, id } : { id },
          },
        })
      );
      let fetchedItem: ItemType;
      if (anotherFetchIsHappening) {
        let interval: NodeJS.Timeout | undefined;

        fetchedItem = await new Promise<ItemType>((res) => {
          let intervalCount = 0;
          interval = setInterval(() => {
            const storeState = store.getState();
            const lastStoreState: RootState[CleanRootStateKeys] =
              storeState[pluralName];
            intervalCount++;
            if (
              "isFetchingItem" in lastStoreState &&
              !lastStoreState.isFetchingItem
            ) {
              if (lastStoreState.lastQuery?.getItemParams?.id !== id) {
                // could happen in a veery rare case and only if the getItem method is being misused

                res(null as ItemType);
              } else {
                res(lastStoreState.item as ItemType);
              }
              if (interval) {
                clearInterval(interval);
              }
            } else {
              // hard limit to 100 interval checks
              if (interval && intervalCount > 100) {
                clearInterval(interval);
                res(null as ItemType);
              }
            }
          }, 100);
        });
      } else {
        const response = await store.dispatch(
          actions[`get${capitalizedName}`](
            params ? { ...params, id: id } : { id: id }
          )
        );
        fetchedItem = response.body;
      }

      if (actions[`hydrate${capitalizedName}`]) {
        store.dispatch(actions[`hydrate${capitalizedName}`](fetchedItem));
      }
      return fetchedItem;
    }

    // TODO: proper type inference
    return ("item" in lastState ? lastState.item : []) as ItemType;
  };

  // RELOAD METHODS
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const reloadList = async () => {
    const state = store.getState();
    const lastState: RootState[CleanRootStateKeys] = state[pluralName];
    if ("lastQuery" in lastState) {
      await fetchList({
        orderBy: lastState.lastQuery?.orderBy,
        filter: lastState.lastQuery?.filter,
        urlParams: lastState.lastQuery?.listUrlParams,
        page: lastState.lastQuery?.page,
        pageSize: lastState.lastQuery?.pageSize,
        apiContext: lastState.lastQuery?.apiContext,
        previousStateValidateContext: lastState.lastQuery?.listValidateContext,
        force: true,
      });
    }
  };
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const reloadItem = async () => {
    const state = store.getState();
    const lastState: RootState[CleanRootStateKeys] = state[pluralName];
    if ("lastQuery" in lastState && lastState.lastQuery?.getItemParams?.id) {
      await getItem({
        id: lastState.lastQuery?.getItemParams?.id,
        params: lastState.lastQuery?.getItemParams?.pureParams,
        previousStateValidateContext: lastState.lastQuery?.itemValidateContext,
        force: true,
      });
    }
  };

  // UPDATE METHOD
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const updateItem = async (
    id: string | number,
    data: { [key: string]: any },
    refetch?: boolean | "item" | "list"
  ) => {
    createGtagEvent("Created item", pluralName);
    if (process.env.NODE_ENV === "development") {
      if (!actions[`update${capitalizedName}`]) {
        throw new Error(
          `Entity ${capitalizedName} has missing actions or is not set-up correctly`
        );
      }
    }

    const response = await store.dispatch(
      actions[`update${capitalizedName}`]({ ...data, id: id })
    );

    // if we should refetch both (true) or just "item"
    if (refetch === "item" || refetch === true) {
      const state = store.getState();
      const lastState: RootState[CleanRootStateKeys] = state[pluralName];
      if (
        "lastQuery" in lastState &&
        lastState.lastQuery?.getItemParams?.id &&
        lastState.lastQuery?.getItemParams?.id === id
      ) {
        reloadItem();
      } else {
        console.warn(
          "No need to reload an item which isn't the one in the store anyways"
        );
      }
    }
    // if we should refetch both (true) or just "list"
    if (refetch === "list" || refetch === true) {
      reloadList();
    }

    type ItemType = RootState[typeof pluralName] extends BaseState
      ? RootState[typeof pluralName]["item"]
      : never;
    const item: ItemType | undefined = response?.body;

    return item;
  };

  // CREATE METHOD
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const createItem = async (data: any, refetch: boolean = false) => {
    if (process.env.NODE_ENV === "development") {
      if (!actions[`create${capitalizedName}`]) {
        throw new Error(
          `Entity ${capitalizedName} has missing actions or is not set-up correctly`
        );
      }
    }

    createGtagEvent("Created item", pluralName);

    const response = await store.dispatch(
      actions[`create${capitalizedName}`](data)
    );
    // no clue why we fetch list if it is not 201, but that's how it was in the old resourceHelper
    if (response.code === 201) {
      if (refetch) {
        reloadList();
      }
      // return created item
      type StateType = ReturnType<typeof store.getState>;
      type ItemType = StateType[typeof pluralName] extends BaseState
        ? StateType[typeof pluralName]["item"]
        : never;
      return response.body as ItemType;
    } else {
      fetchList();
    }
  };
  // DELETE METHOD
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const deleteItem = async (
    data: { [key: string]: number | string }, // because it could be something else then ":id"
    refetch: boolean = false
  ) => {
    if (process.env.NODE_ENV === "development") {
      if (!actions[`delete${capitalizedName}`]) {
        throw new Error(
          `Entity ${capitalizedName} has missing actions or is not set-up correctly`
        );
      }
    }

    if (refetch) {
      const response = await store.dispatch(
        actions[`delete${capitalizedName}`](data)
      );
      if (response.code === 204) {
        reloadList();
      }
    } else {
      await store.dispatch(actions[`delete${capitalizedName}`](data));
    }
  };

  // HOOK CONSTRUCTOR
  /**
   * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
   */
  const createHook = () => {
    return ({
      fetchList: fetchListArgs,
      getItem: getItemArgs,
      previousStateValidateContext,
    }: CreateHookArgs = {}) => {
      const { foundStore } = useSelector((state: RootState) => ({
        foundStore: state[pluralName],
      }));

      const [argsFetchList, setArgsFetchList] = useState(fetchListArgs);
      const [argsGetItem, setArgsGetItem] = useState(getItemArgs);

      // deep compare arguments
      useEffect(() => {
        if (!isEqual(fetchListArgs, argsFetchList)) {
          setArgsFetchList(fetchListArgs);
        }
        if (!isEqual(getItemArgs, argsGetItem)) {
          setArgsGetItem(getItemArgs);
        }
      }, [fetchListArgs, getItemArgs, argsFetchList, argsGetItem]);

      // fetch list
      useEffect(() => {
        if (argsFetchList) {
          const argsFetchListWithPrevStateContext =
            argsFetchList === true
              ? { previousStateValidateContext }
              : {
                  ...argsFetchList,
                  previousStateValidateContext:
                    argsFetchList.previousStateValidateContext ||
                    previousStateValidateContext,
                };
          fetchList(argsFetchListWithPrevStateContext);
        }
      }, [argsFetchList, previousStateValidateContext]);

      // fetch item
      useEffect(() => {
        if (argsGetItem) {
          const argsGetItemWithPrevStateContext =
            typeof argsGetItem === "string" || typeof argsGetItem === "number"
              ? { previousStateValidateContext, id: argsGetItem }
              : {
                  ...argsGetItem,
                  previousStateValidateContext:
                    argsGetItem.previousStateValidateContext ||
                    previousStateValidateContext,
                };
          getItem(argsGetItemWithPrevStateContext);
        }
      }, [argsGetItem, previousStateValidateContext]);

      const fetchListWithPrevStateContext = useCallback(
        (args?: FetchListArgs) => {
          return fetchList(
            !args
              ? { previousStateValidateContext }
              : {
                  ...args,
                  previousStateValidateContext:
                    args.previousStateValidateContext ||
                    previousStateValidateContext,
                }
          );
        },
        [previousStateValidateContext]
      );

      const getItemWithPrevStateContext = useCallback(
        (args: GetItemArgs) => {
          return getItem({
            ...args,
            previousStateValidateContext:
              args.previousStateValidateContext || previousStateValidateContext,
          });
        },
        [previousStateValidateContext]
      );

      return {
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        store: foundStore,
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        fetchList: fetchListWithPrevStateContext,
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        getItem: getItemWithPrevStateContext,
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        updateItem,
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        reloadList,
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        reloadItem,
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        createItem,
        /**
         * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
         */
        deleteItem,
      };
    };
  };

  return {
    /**
     * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
     */
    createHook,
    /**
     * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
     */
    resource: {
      /**
       * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
       */
      fetchList,
      /**
       * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
       */
      getItem,
      /**
       * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
       */
      updateItem,
      /**
       * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
       */
      reloadList,
      /**
       * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
       */
      reloadItem,
      /**
       * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
       */
      createItem,
      /**
       * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
       */
      deleteItem,
    },
  };
};

/**
 * @deprecated createResourceHelper entities should be migrated to RTK entities https://styleguide.dev.casasoft.com/?path=/docs/documentation-architecture-api-introduction--page
 */
export default createResourceHelper;
