import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
  {
    value: "demo",
    label: "Demo",
  },
];

export const getContractStatus = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getContractStatusLabel = createGetLabel(untranslatedList);

export const useContractStatuses = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
