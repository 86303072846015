import { CasaoneBaseQueryArgs } from "api/types";
import { MutateContactListQueryParams } from "./types";

function query(body: MutateContactListQueryParams): CasaoneBaseQueryArgs {
  return {
    url: `contact`,
    method: "PATCH",
    data: body,
  };
}

export default query;
