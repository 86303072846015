import { ReactNode } from "react";

interface FilterFormColsItemProps {
  children: ReactNode;
  span?: "full" | "half" | "third";
}

function FilterFormColsItem({
  children,
  span = "full",
}: FilterFormColsItemProps) {
  return (
    <div
      className={`tw-min-w-0 
        ${span === "full" ? "md:tw-col-span-full" : ""} 
        ${span === "half" ? "md:tw-col-span-3" : ""} 
        ${span === "third" ? "md:tw-col-span-2" : ""}
      `}
    >
      {children}
    </div>
  );
}

export default FilterFormColsItem;
