import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "link",
    label: "Link",
  },
  {
    value: "direct-link",
    label: "Direct Link",
  },
  {
    value: "virtual-tour",
    label: "Virtual tour",
  },
  {
    value: "youtube",
    label: "Youtube",
  },
  {
    value: "archilogic",
    label: "Archilogic",
  },
  {
    value: "emonitor",
    label: "eMonitor",
  },
];

export const getLinkTypes = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getLinkTypesLabel = createGetLabel(untranslatedList);

export const useLinkTypes = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
