import { createReducer } from "@reduxjs/toolkit";
import { getEventTypeList, EventTypesShape } from "./actions";

const initialState: EventTypesShape = {
  eventTypesList: [],
  lastFetched: 0,
};

const eventTypesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getEventTypeList.fulfilled, (state, action) => {
    return {
      ...state,
      eventTypesList: action.payload.response,
      lastFetched: action.payload.updateReceivedAt,
    };
  });
});

export { eventTypesReducer };
