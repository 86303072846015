import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NotificationCloseButtonProps {
  onClick?: (e: React.MouseEvent) => void;
}

export const NotificationCloseButton = ({
  onClick,
}: NotificationCloseButtonProps) => {
  return (
    <div
      className={`${
        onClick ? "tw-cursor-pointer" : ""
      } tw-flex-shrink-0 tw-flex-grow-0 tw-px-2 tw-py-1 hover:tw-opacity-75`}
    >
      <FontAwesomeIcon
        fixedWidth
        icon={faTimes}
        onClick={onClick}
        role="button"
        aria-hidden="false"
        aria-label="Close notification"
      />
    </div>
  );
};
