import type { UserFilterPresetValue, UserFilterPresets } from "redux/auth";
import cloneDeep from "lodash/cloneDeep";

interface GetUpdatedUserFilterPresetsProps {
  allPresets: UserFilterPresets;
  presetName: string;
  presetKey: string;
  label: string;
  elementKey?: string;
  elementValue?: string | string[];
}

export const getUpdatedUserFilterPresets = ({
  allPresets,
  presetName,
  presetKey,
  label,
  elementKey,
  elementValue,
}: GetUpdatedUserFilterPresetsProps): UserFilterPresetValue[] => {
  const presets: UserFilterPresets[string] =
    cloneDeep(allPresets[presetName]) || [];

  const arrayedValue = elementValue ? arrayValue(elementValue) : undefined;

  const foundPresetIndex = presets.findIndex((x) => x.key === presetKey);
  const foundPreset =
    foundPresetIndex !== -1 ? presets[foundPresetIndex] : undefined;

  const needsUpdate = foundPreset && elementKey && arrayedValue;
  const needsDelete =
    elementKey && foundPreset?.preset[elementKey] && !arrayedValue;
  const needsLabelUpdate = foundPreset && !elementKey && !arrayedValue;
  const needsCreate = !foundPreset;

  // if the preset (find by key) already exists update it otherwise create a new one
  if (needsUpdate) {
    presets[foundPresetIndex] = updatePresetElement(
      foundPreset,
      elementKey,
      label,
      arrayedValue
    );
  }
  if (needsLabelUpdate) {
    presets[foundPresetIndex] = updateLabel(foundPreset, label);
  }
  if (needsDelete) {
    delete foundPreset.preset[elementKey];
  }
  if (needsCreate) {
    const newPreset = createPresetElement(
      presetKey,
      label,
      elementKey,
      arrayedValue
    );
    deactivatePresets(presets);
    presets.push(newPreset);
  }

  return presets;
};

const arrayValue = (value: string | string[]) =>
  Array.isArray(value) ? [...value] : [value];

const updatePresetElement = (
  preset: UserFilterPresetValue,
  elemKey: string,
  label: string,
  elemValue: string | string[]
): UserFilterPresetValue => ({
  ...preset,
  label,
  preset: {
    ...preset.preset,
    [elemKey]: elemValue || [],
  },
});

const createPresetElement = (
  presetKey: string,
  label: string,
  elemKey?: string,
  elemValue?: string[]
): UserFilterPresetValue => {
  const preset = elemKey && elemValue ? { [elemKey]: elemValue } : {};

  return {
    key: presetKey,
    label,
    active: true,
    preset,
  };
};

const updateLabel = (
  preset: UserFilterPresetValue,
  label: string
): UserFilterPresetValue => ({
  ...preset,
  label,
});

const deactivatePresets = (presets: UserFilterPresetValue[]): void =>
  presets.forEach((preset) => (preset.active = false));
