import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { authErrorIdsToMessage } from "redux/auth";
import { useTranslation } from "react-i18next";
import { history } from "config/history";
import { Alert } from "@casasoft/styleguide/components/helpers-ux";

function ErrorPage() {
  const { search } = useLocation();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const errorIdParam = params.get("errorId");
    setError(authErrorIdsToMessage(errorIdParam));
  }, [search]);

  return (
    <div className="outside__content__form">
      <div>
        <h1 className="display-4 tw-mb-4">{t("Something went wrong")}</h1>
        {error && <Alert color="danger">{error}</Alert>}
        <a
          href="/login"
          onClick={(e) => {
            e.preventDefault();
            history.push("/login");
          }}
        >
          {t("Link to login")}
        </a>
      </div>
    </div>
  );
}

export default ErrorPage;
