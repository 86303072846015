import queryBuilder from "utilities/queryBuilder";
import { createQuery } from "api/createEndpoint";
import { GetSubsidiaryListQueryParams } from "./types";

const query = createQuery<GetSubsidiaryListQueryParams>(() => {
  const builtQuery = queryBuilder.buildToUrl(
    [
      {
        type: "field",
        field: "created",
        direction: "asc",
      },
    ],
    [],
    {
      pagesize: -1,
      page: 1,
    }
  );

  return {
    url: `subsidiary?${builtQuery}`,
    method: "GET",
  };
});

export default query;
