import { MessageBodyPreviewControls } from "./MessageBodyPreview/MessageBodyPreviewControls";
import { MessageBodyEdit } from "./MessageBodyEdit/MessageBodyEdit";
import { MessageBodyPreview } from "./MessageBodyPreview/MessageBodyPreview";
import { type UseMessageBodyPreviewFetchResponse } from "../helpers/useMessageBodyPreview/useMessageBodyPreview";
import { hasMoustacheVariables } from "../helpers/useMessageBodyPreview/messageBodyPreviewHelpers";
import { useMessageBodyPreviewControls } from "./MessageBodyPreview/helpers/useMessageBodyPreviewControls";

interface MessageBodyContainerProps {
  value: string | undefined;
  onChange: (newValue: string | undefined) => void;
  onBlur?: () => void;
  error?: string;
  messageBodyPreview: UseMessageBodyPreviewFetchResponse;
}

export const MessageBodyContainer = ({
  messageBodyPreview: { contacts, previewBody, isLoading, fetchPreview },
  ...rest
}: MessageBodyContainerProps) => {
  const messageBodyPreviewControls = useMessageBodyPreviewControls({
    contacts: contacts,
    fetchPreview: fetchPreview,
  });

  const showControls =
    !!messageBodyPreviewControls.activeContact &&
    hasMoustacheVariables(rest.value);

  return (
    <div className="tw-relative tw-pb-[2px]">
      {showControls && (
        <MessageBodyPreviewControls
          {...messageBodyPreviewControls}
          contacts={contacts}
          previewLoading={isLoading}
          className="tw-mb-4 tw-mt-2"
        />
      )}

      {messageBodyPreviewControls.inPreviewMode ? (
        <MessageBodyPreview
          previewBody={previewBody || ""}
          previewLoading={isLoading}
        />
      ) : (
        <MessageBodyEdit {...rest} />
      )}
    </div>
  );
};
