import { type MessageAttachment } from "../../MessageMiscControls/MessageAttachFilesControl";

export const getCleanedUpMedia = (
  attachments: MessageAttachment[]
): MessageAttachment[] => {
  return attachments
    ? [...attachments]
        .filter((a) => a.media)
        .map((attachment) => {
          return {
            ...attachment,
            media: attachment.media.id as string,
          };
        })
    : [];
};
