import { TableListItemColProps } from "./TableListItemCols";
import { TableListItemProps } from "./TableListItem";
import { twMerge } from "tailwind-merge";

interface TableListColumnsDataProps
  extends Pick<
    TableListItemProps,
    "item" | "cols" | "renderValue" | "cardView" | "sortable"
  > {}

export const TableListColumnsData = ({
  cols = [],
  item,
  cardView,
  renderValue,
  sortable,
}: TableListColumnsDataProps) => {
  const colsLength = cols.length;
  return (
    <>
      {cols.map((col: TableListItemColProps, index) => {
        const mutadedItem = item;
        if (mutadedItem.isFiller && !mutadedItem[col.key]) {
          if (col.placeholder === "circle") {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-rounded-full tw-w-4 tw-leading-4 ">
                &nbsp;
              </span>
            );
          } else if (col.placeholder === "circle2x") {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-rounded-full tw-w-8 tw-leading-8">
                &nbsp;
              </span>
            );
          } else if (col.placeholder === "circle3x") {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-rounded-full tw-w-12 tw-leading-[3rem]">
                &nbsp;
              </span>
            );
          } else if (col.placeholder === "line4x") {
            mutadedItem[col.key] = (
              <span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
              </span>
            );
          } else if (col.placeholder === "line3x") {
            mutadedItem[col.key] = (
              <span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
              </span>
            );
          } else if (col.placeholder === "line2x") {
            mutadedItem[col.key] = (
              <span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
              </span>
            );
          } else {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-w-full">
                &nbsp;
              </span>
            );
          }
        }
        return (
          <div
            key={col.key}
            style={
              !cardView
                ? {
                    width: col.baseWidth || "150px",
                    minWidth:
                      col.minWidth || (col.maxWidth ? undefined : "150px"),
                    maxWidth: col.maxWidth,
                    overflow: "hidden",
                  }
                : undefined
            }
            className={twMerge(
              !cardView && "tw-grow tw-py-3 last:tw-pr-0 tw-text-sm",
              !cardView &&
                col.align &&
                col.align === "right" &&
                "tw-text-right",
              sortable && index === colsLength - 1 ? "tw-pr-8" : "tw-pr-6"
            )}
          >
            {cardView && renderValue?.(mutadedItem, col.key)?.length === 0
              ? "-"
              : renderValue?.(mutadedItem, col.key)}
          </div>
        );
      })}
    </>
  );
};
