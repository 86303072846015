import { DataAttributes } from "@casasoft/styleguide/utilities/extractDataAttributes";
import { AriaRole, ReactNode } from "react";

type ButtonGroupProps = {
  justifyContentEnd?: boolean;
  children: ReactNode;
  role?: AriaRole;
} & DataAttributes;

function ButtonGroup({
  children,
  justifyContentEnd = false,
  role = "group",
  ...dataAttrs
}: ButtonGroupProps) {
  return (
    <div
      {...dataAttrs}
      className={`
      tw-cs-btn-group
      tw-flex ${justifyContentEnd ? "tw-justify-end" : ""}
      `}
      role={role}
    >
      {children}
    </div>
  );
}

export default ButtonGroup;
