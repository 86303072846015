import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
];

export const getContactStatus = createGetList(untranslatedList, {
  sort: "a-z",
});
export const getContactStatusLabel = createGetLabel(untranslatedList);

export const useContactStatuses = createUtilityHook(untranslatedList, {
  sort: "a-z",
});
