import { ModalBody } from "@casasoft/styleguide/components/modal";
import { useTranslation } from "react-i18next";
import type { LanguageGroupData } from "../LanguageBreakdownContainer";
import { LanguageBreakdownContent } from "./LanguageBreakdownContent/LanguageBreadkdownContent";
import type { LanguageCheckboxStatus } from "../helpers/languageCheckboxHelper";

export interface LanguageBreakdownModalBodyProps {
  languageGroups: LanguageGroupData[];
  languageCheckboxStatus: LanguageCheckboxStatus;
  onInfoClick: (language: string) => void;
  isLoading?: boolean;
}

export const LanguageBreakdownModalBody = ({
  languageGroups,
  languageCheckboxStatus,
  onInfoClick,
  isLoading = false,
}: LanguageBreakdownModalBodyProps) => {
  const { t } = useTranslation();

  return (
    <ModalBody className="tw-p-0">
      <div className="tw-px-4 tw-mt-4 tw-mb-2 tw-font-light tw-text-lg">
        {t("Select the contact groups you would like to write to:", {
          keySeparator: ">",
          nsSeparator: "|",
        })}
      </div>
      <LanguageBreakdownContent
        languageGroups={languageGroups}
        languageCheckboxStatus={languageCheckboxStatus}
        onInfoClick={onInfoClick}
        isLoading={isLoading}
      />
    </ModalBody>
  );
};
