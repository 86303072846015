import { ColorVarNames, colorVarNamesMap } from "./colorVarNameMap";
import rgbToHex from "./rgbToHex";
import { ThemePresetSlugs, themePresetsMap } from "./themePresetsMap";

type ThemeSettings = {
  [key in ColorVarNames]: { rgb: [number, number, number]; hex: string };
};

export function themeSlugValidator(themeSlug: string) {
  if (
    themeSlug !== "default" &&
    themeSlug !== "custom" &&
    !themePresetsMap[themeSlug as ThemePresetSlugs]
  ) {
    throw new Error(`Unknown theme slug: ${themeSlug}`);
  }
  return themeSlug as ThemePresetSlugs | "default" | "custom";
}

function getThemeSettings() {
  const currentThemeSettings: Partial<ThemeSettings> = {};
  colorVarNamesMap.forEach((colorVarName) => {
    const colorVarValue = getComputedStyle(
      document.documentElement
    ).getPropertyValue(colorVarName);

    if (!colorVarValue) {
      throw new Error(`Missing color var value for: ${colorVarName}`);
    }

    const rgbArr = colorVarValue
      .trim()
      .split(" ")
      .map((stringifiedNumber) => parseInt(stringifiedNumber));

    if (rgbArr.length !== 3) {
      throw new Error(`Invalid color var value for: ${colorVarName}`);
    }

    const hex = rgbToHex(rgbArr[0], rgbArr[1], rgbArr[2]);

    currentThemeSettings[colorVarName] = {
      rgb: [rgbArr[0], rgbArr[1], rgbArr[2]],
      hex,
    };
  });

  const nonPartialCurrentThemeSetting = currentThemeSettings as ThemeSettings;
  return nonPartialCurrentThemeSetting;
}

export function getActiveTheme() {
  const activeThemeSlugVar =
    document.documentElement.style.getPropertyValue("--cs-theme-slug") ||
    "default";

  const activeThemeSlug = themeSlugValidator(activeThemeSlugVar);

  return activeThemeSlug;
}

export default getThemeSettings;
