import { type TFunction } from "i18next";
import { type CustomUtilityTranslator } from "../createGenericUtility";
import { type ServiceItem, untranslatedServiceList } from "./constants";

const infoTranslator = (info: string, t: TFunction) => {
  const match = info.match(/(\d+)-requests-(\d+)-variants/);
  if (match) {
    const numRequests = match[1];
    return `${numRequests} ${t(
      "Valuation requests with 4 variants per request are included"
    )}`;
  }

  const reportsMatch = info.match(/(\d+)-reports/);
  if (reportsMatch) {
    const numReports = reportsMatch[1];
    return `${numReports} ${t("Location reports are included")}`;
  }

  return t(info);
};

export const serviceCustomTranslator: CustomUtilityTranslator<
  typeof untranslatedServiceList[number]
> = (item: ServiceItem, originalTranslator: TFunction): ServiceItem => {
  return {
    ...item,
    info: item.info
      ? item.info.map((singleInfo) =>
          infoTranslator(singleInfo, originalTranslator)
        )
      : undefined,
    options: item.options.map((option) => ({
      ...option,
      label: originalTranslator(option.label),
      info: option.info
        ? option.info.map((singleInfo) =>
            infoTranslator(singleInfo, originalTranslator)
          )
        : undefined,
    })),
    label: originalTranslator(item.label),
  };
};
