import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";
import BaseField from "./BaseField";
import FormGroup from "../formElements/helpers/FormGroup";
import Field from "./../formElements/Field";
import Select from "./../formElements/Select";
import Countries from "./utilities/countries"; // DOESNT EVEN WORK; WE DONT LOAD THE FULL COUNTRIES INSIDE STYLEGUIDE

class AddressTypeAhead extends BaseField {
  constructor(props, context) {
    super(props, context);

    this.state = {
      curVal: "",
      address: {
        id: 2,
        country: "",
        locality: "",
        region: "",
        postal_code: "",
        post_office_box_number: "",
        street: "",
        street_number: "",
        street_addition: "",
        subunit: "",
        lat: "",
        lng: "",
      },
      autocomplete: null,
      predictionList: null,
      automaticEntry: false,
    };
  }

  componentDidMount() {
    const comp = this;
    if (comp.props.value) {
      comp.setState({ filled: true });
      comp.setState({ curVal: comp.props.value });
    } else {
      comp.setState({ filled: false });
    }

    Array.prototype.slice
      .call(document.querySelectorAll("[required]"))
      .forEach((input) => {
        input.addEventListener("invalid", () => {
          if (!comp.state.showSubFields) {
            comp.setState({
              showSubFields: true,
            });
          }
          if (document.getElementsByClassName("c-field-address-root").length) {
            document
              .getElementsByClassName("c-field-address-root")[0]
              .classList.remove("c-field-address-root");
          }
        });
      });
  }

  componentDidUpdate() {
    this._initialize();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        curVal: nextProps.value,
        filled: nextProps.value !== "",
      });
    }
    if (nextProps.subvalues && nextProps.subvalues !== this.state.subvalues) {
      this.setState({
        address: nextProps.subvalues,
      });
    }
    if (
      nextProps.manualInput &&
      nextProps.manualInput !== this.state.manualInput
    ) {
      this._handleManualInput();
    }
  }

  _handleSubvaluesChange(value, key) {
    const currAddress = Object.assign({}, this.state.address);
    currAddress[key] = value;
    if (this.props.onSubvaluesChange) {
      this.props.onSubvaluesChange(currAddress);
    }
    this.setState({
      address: currAddress,
    });
  }

  _handleSubvaluesBlur(value, key) {
    const currAddress = Object.assign({}, this.state.address);
    currAddress[key] = value;
    if (this.props.onSubvaluesChange && this.state.automaticEntry) {
      const searchAddress = {
        address: `${currAddress.street} ${currAddress.street_number} ${currAddress.postal_code} ${currAddress.locality} ${currAddress.country}`,
      };
      this._geoCodeSearch(searchAddress);
    }
  }

  _getPlaceDetails(e, id, prediction) {
    const comp = this;
    e.stopPropagation();
    const placeService = new comp.props.gmap.places.PlacesService(
      comp.autocompleteField
    );
    placeService.getDetails({ placeId: id }, (place) => {
      comp.fillInAddress(place, prediction);
    });
    comp._resetAutocompleteResults();
  }

  _resetAutocompleteResults() {
    const comp = this;

    comp.setState({
      predictionResults: false,
    });
  }

  _geoCodeSearch(query) {
    const comp = this;
    if (query.address) {
      const geocoder = new comp.props.gmap.Geocoder();
      geocoder.geocode({ address: query.address }, (results, status) => {
        if (status === "OK") {
          comp.fillInAddress(results[0]);
        } else {
          // geocode no result
        }
      });
    }
  }

  _initialize() {
    const comp = this;
    if (comp.props.gmap && comp.state.autocomplete === null) {
      const gmap = Object.assign({}, comp.props.gmap);

      // bounds of switzlerand to give some bias towards swiss results
      const defaultBounds = new gmap.LatLngBounds(
        new gmap.LatLng(45.7769477403, 6.02260949059),
        new gmap.LatLng(47.8308275417, 10.4427014502)
      );

      const displaySuggestions = (predictions, status) => {
        if (
          status !== gmap.places.PlacesServiceStatus.OK &&
          status !== gmap.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          return;
        }

        const results = [];
        let activeDescendant;
        if (predictions) {
          let count = 0;
          predictions.forEach((prediction) => {
            if (count === 0) {
              activeDescendant = prediction.id;
            }
            const predicitionButton = (
              <button
                id={prediction.id}
                role="option"
                aria-selected={false}
                className="cs-dropdown-item"
                key={prediction.id}
                tabIndex="0"
                onClick={(e) => {
                  comp._getPlaceDetails(e, prediction.place_id, prediction);
                  this.setState({ curVal: prediction.description });
                }}
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="tw-mr-1"
                  fixedWidth
                />
                {prediction.description}
              </button>
            );
            count += 1;
            results.push(predicitionButton);
          });
        }
        if (!activeDescendant) {
          activeDescendant = "manualItem";
        }
        results.push(<div key="divider" className="cs-dropdown-divider" />);
        const button = (
          <button
            href="#"
            tabIndex="-1"
            role="option"
            aria-selected={false}
            id="manualItem"
            key="manualItem"
            className="cs-dropdown-item"
            onClick={(e) => {
              comp._handleManualInput(e);
            }}
          >
            {this.props.translate("Enter address manual")}
          </button>
        );
        results.push(button);

        const resultsElement = (
          <div
            ref={(div) => {
              this.predictionList = div;
            }}
            onFocus={() => {
              comp.className = "focus";
            }}
            aria-activedescendant={activeDescendant}
            role="listbox"
            tabIndex="0"
            className="cs-dropdown-menu cs-dropdown-menu--full-width show"
          >
            {results}
          </div>
        );
        comp.setState({
          predictionResults: resultsElement,
        });
      };

      const autocomplete = new gmap.places.AutocompleteService();

      gmap.event.addDomListener(this.autocompleteField, "keyup", (event) => {
        // down arrow
        if (event.keyCode === 40) {
          const activeDescendant = this.predictionList.getAttribute(
            "aria-activedescendant"
          );
          if (activeDescendant) {
            const elem = document.getElementById(activeDescendant);
            if (elem) {
              elem.focus();
              elem.addEventListener("keyup", (subevent) => {
                const nextelem = elem.nextSibling;
                // down arrow
                if (subevent.key === "ArrowDown") {
                  nextelem.focus();
                  nextelem.addEventListener("keyup", (subsubevent) => {
                    const nextnextelem = nextelem.nextSibling;
                    // down arrow
                    if (subsubevent.key === "ArrowDown") {
                      nextnextelem.focus();
                    }
                  });
                }
              });
            }
          }
        } else if (event.key === "Enter") {
          // ENTER
          event.preventDefault();
        } else {
          const value =
            event.target.value !== "" ? event.target.value : event.key;
          if (value.length > 1) {
            autocomplete.getPlacePredictions(
              {
                input: value,
                types: ["geocode"],
                bounds: defaultBounds,
                componentRestrictions: {
                  country: this.props.countryGeoSearchLimit,
                },
              },
              displaySuggestions
            );
          }
        }
      });
      comp.setState({ autocomplete });
    }
  }

  handleClickOutside() {
    const comp = this;
    comp._resetAutocompleteResults();
  }

  fillInAddress(place, prediction) {
    let lat = null;
    let lng = null;
    let postal_code = false;
    const newState = {};
    if (place) {
      lat = place.geometry.location.lat();
      lng = place.geometry.location.lng();
      for (let i = 0; i < place.address_components.length; i += 1) {
        let addressType = place.address_components[i].types[0];
        let val = "";
        switch (addressType) {
          case "country":
            val = place.address_components[i].short_name;
            break;
          case "administrative_area_level_1":
            addressType = "region";
            val = place.address_components[i].short_name;
            break;
          case "route":
            addressType = "street";
            val = place.address_components[i].long_name;
            break;
          case "postal_code":
            postal_code = true;
            val = place.address_components[i].long_name;
            break;
          default:
            val = place.address_components[i].long_name;
        }
        newState[addressType] = val;
      }
      //if there is no postalcode in PlaceService.js, check terms for postalcode
      if (!postal_code) {
        prediction.terms.forEach((element) => {
          if (!isNaN(Number(element.value))) {
            newState["postal_code"] = element.value;
          }
        });
      }
    } else {
      // mismatch Google not finding own PlaceId, fallback
      // manualInput = true;
      if (prediction.terms && prediction.terms.length) {
        if (prediction.types && prediction.types.length) {
          // we could get the type
          // const type = prediction.type[0];
        }
        switch (prediction.terms.length) {
          case 1:
            // country
            // DOESNT EVEN WORK; WE DONT LOAD THE FULL COUNTRIES INSIDE STYLEGUIDE
            newState.country = Countries.getCountryValue(
              prediction.terms[0].value
            );
            break;
          case 2:
            // locality
            newState.locality = prediction.terms[0].value;
            newState.country = Countries.getCountryValue(
              prediction.terms[1].value
            );
            break;
          case 3:
            // route
            newState.street = prediction.terms[0].value;
            newState.locality = prediction.terms[1].value;
            newState.country = Countries.getCountryValue(
              prediction.terms[2].value
            );
            break;
          case 4:
            // street_address
            newState.street_number = prediction.terms[0].value;
            newState.street = prediction.terms[1].value;
            newState.locality = prediction.terms[2].value;
            newState.country = Countries.getCountryValue(
              prediction.terms[3].value
            );
            break;
          default:
            break;
        }
      }
    }
    newState.lat = lat;
    newState.lng = lng;
    const address = {
      country: newState.country ? newState.country : "",
      locality: newState.locality ? newState.locality : "",
      region: newState.region?.length < 5 ? newState.region : "",
      postal_code: newState.postal_code ? newState.postal_code : "",
      post_office_box_number: this.state.address.post_office_box_number
        ? this.state.address.post_office_box_number
        : "",
      street: newState.street ? newState.street : "",
      street_number: newState.street_number ? newState.street_number : "",
      street_addition: newState.street_addition ? newState.street_addition : "",
      subunit: newState.subunit ? newState.subunit : "",
      lat: newState.lat ? newState.lat : "",
      lng: newState.lng ? newState.lng : "",
    };
    this.setState({
      address: address,
    });
    this.props.onSubvaluesChange(address);
  }

  _isAllEmpty() {
    if (
      !this.state.address.street &&
      !this.state.address.street_number &&
      !this.state.address.postal_code &&
      !this.state.address.locality &&
      !this.state.address.country
    ) {
      return true;
    }
    return false;
  }

  _handleManualInput(e) {
    const comp = this;
    if (e) {
      e.stopPropagation();
    }
    comp.setState({
      manualInput: true,
    });
    comp._resetAutocompleteResults();
  }

  _setRef(ref) {
    this.autocompleteField = ref;
    this._initialize();
  }

  render() {
    const comp = this;

    const sharedProps = comp._getSharedProps();
    const messages = sharedProps.message ? sharedProps.message : false;
    delete sharedProps.message;

    let googleInput = null;
    if (comp.props.gmap) {
      const googleInputProps = Object.assign({}, sharedProps);

      // make input uncontrolled
      // delete googleInputProps.value;
      delete googleInputProps.filled;
      delete googleInputProps.focused;
      delete googleInputProps.theme;
      delete googleInputProps.setRef;
      delete googleInputProps.text;
      delete googleInputProps._handleKeyUp;
      delete googleInputProps.nobox;

      // delete sharedProps.onChange;
      googleInput = (
        <FormGroup {...sharedProps} message={messages.typeahead}>
          <div className="cs-input-group">
            <input
              {...googleInputProps}
              className="cs-form-control"
              ref={(input) => {
                this.autocompleteField = input;
              }}
              // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#the_autocomplete_attribute_and_login_fields
              // prevent suggestions covering our custom suggestions
              autocomplete="new-password"
            />
            {comp.state.predictionResults}
          </div>
        </FormGroup>
      );
    }
    let filled = false;
    if (
      !comp._isAllEmpty() ||
      comp.state.manualInput ||
      comp.state.showSubFields
    ) {
      filled = true;
    }
    const subfieldClasses = ["c-address-type-ahead__subfields"];
    if (filled) {
      subfieldClasses.push("c-address-type-ahead__subfields--filled");
    }
    const subfields = (
      <div id="autocomplete-subfields" className={subfieldClasses.join(" ")}>
        <div className="tw-grid lg:tw-grid-cols-2 tw-gap-4">
          <div className="tw-min-w-0">
            <Field
              className="c-address-type-ahead__street"
              name="street"
              onChange={(value, key) => this._handleSubvaluesChange(value, key)}
              label={
                this.props.streetLabel
                  ? this.props.streetLabel
                  : this.props.translate("Street")
              }
              placeholder={this.props.translate("Street")}
              value={this.state.address.street}
              keyPass="street"
              message={messages.street}
              onBlur={(value, key) => this._handleSubvaluesBlur(value, key)}
              id={this.props.id ? `${this.props.id}Street` : null}
              nobox={this.props.nobox}
            />
          </div>
          <div className="tw-min-w-0">
            <Field
              className="c-address-type-ahead__street-number"
              name="street_number"
              onChange={(value, key) => this._handleSubvaluesChange(value, key)}
              label={
                this.props.street_numberLabel
                  ? this.props.street_numberLabel
                  : this.props.translate("Street Number")
              }
              placeholder={this.props.translate("Street Number")}
              value={this.state.address.street_number}
              keyPass="street_number"
              message={messages.street_number}
              onBlur={(value, key) => this._handleSubvaluesBlur(value, key)}
              id={this.props.id ? `${this.props.id}StreetNumber` : null}
              nobox={this.props.nobox}
            />
          </div>
        </div>
        {this.props.postOfficeBoxField && (
          <div>
            <Field
              className="c-address-type-ahead__post-office-box-number"
              name="post_office_box_number"
              onChange={(value, key) => this._handleSubvaluesChange(value, key)}
              label={
                this.props.post_office_box_numberLabel
                  ? this.props.post_office_box_numberLabel
                  : this.props.translate("Post Office Box Number")
              }
              placeholder={this.props.translate("Post Office Box Number")}
              value={this.state.address.post_office_box_number}
              keyPass="post_office_box_number"
              message={messages.post_office_box_number}
              id={this.props.id ? `${this.props.id}PostOfficeBoxNumber` : null}
              nobox={this.props.nobox}
            />
          </div>
        )}
        <div className="tw-grid lg:tw-grid-cols-2 tw-gap-4">
          <div className="tw-min-w-0">
            <Field
              className="c-address-type-ahead__zip"
              name="postal_code"
              type="number"
              onChange={(value, key) => this._handleSubvaluesChange(value, key)}
              label={
                this.props.postal_codeLabel
                  ? this.props.postal_codeLabel
                  : this.props.translate("Postal Code")
              }
              placeholder={this.props.translate("Postal Code")}
              value={this.state.address.postal_code}
              keyPass="postal_code"
              message={messages.postal_code}
              onBlur={(value, key) => this._handleSubvaluesBlur(value, key)}
              id={this.props.id ? `${this.props.id}PostalCode` : null}
              nobox={this.props.nobox}
            />
          </div>
          <div className="tw-min-w-0">
            <Field
              className="c-address-type-ahead__locality"
              name="locality"
              onChange={(value, key) => this._handleSubvaluesChange(value, key)}
              label={
                this.props.localityLabel
                  ? this.props.localityLabel
                  : this.props.translate("Locality")
              }
              placeholder={this.props.translate("Locality")}
              value={this.state.address.locality}
              keyPass="locality"
              message={messages.locality}
              onBlur={(value, key) => this._handleSubvaluesBlur(value, key)}
              id={this.props.id ? `${this.props.id}Locality` : null}
              nobox={this.props.nobox}
            />
          </div>
        </div>
        <div>
          <Select
            className="c-address-type-ahead__country"
            required
            onChange={(value) => this._handleSubvaluesChange(value, "country")}
            value={this.state.address.country}
            placeholder={this.props.translate("Country")}
            label={this.props.translate("Country")}
            options={this.props.countryDropdownOptions}
            message={messages.country}
            onBlur={(value, key) => this._handleSubvaluesBlur(value, key)}
            id={this.props.id ? `${this.props.id}Country` : null}
            nobox={this.props.nobox}
          />
          <input type="hidden" name="country" value={this.state.country} />
        </div>
      </div>
    );

    return (
      <div>
        <div
          className={`cs-dropdown ${
            this.state.predictionResults ? "show" : ""
          }`}
        >
          {googleInput}
        </div>
        {subfields}
      </div>
    );
  }
}

AddressTypeAhead.propTypes = {
  translate: PropTypes.func,
  gmap: PropTypes.object,
  postOfficeBoxField: PropTypes.bool,
};

AddressTypeAhead.defaultProps = {
  translate: (key) => {
    return key;
  },
  gmap: null,
  postOfficeBoxField: false,
};

export default AddressTypeAhead;
