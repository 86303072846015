import {
  createGetLabel,
  createGetList,
  createUtilityHook,
} from "./createGenericUtility";

const untranslatedList = [
  {
    value: "0",
    label: "Undefined",
  },
  {
    value: "1",
    label: "Male",
  },
  {
    value: "2",
    label: "Female",
  },
];

export const getGenders = createGetList(untranslatedList);
export const getGenderLabel = createGetLabel(untranslatedList);

export const useGenders = createUtilityHook(untranslatedList);
