import { InputHTMLAttributes } from "react";
import * as React from "react";

interface CheckboxElemProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "onBlur" | "onClick"
  > {
  checked?: boolean;
  label?: string | JSX.Element;
  id?: string;
  value?: string;
  onChange?: (val: string) => void;
  onToggle?: (val: boolean) => void;
  onClick?: (val: boolean) => void;
}

const CheckboxElem: React.FC<CheckboxElemProps> = ({
  checked = false,
  label = "",
  id = "",
  value = "unchecked",
  onChange,
  onToggle,
  onClick,
  ...inputProps
}) => {
  const _handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event && event.target) {
      if (event.target.checked) {
        onChange?.("checked");
        onToggle?.(true);
      } else {
        onChange?.("unchecked");
        onToggle?.(false);
      }
    }
  };

  const handleCheckboxClick = () => {
    if (onClick) {
      onClick(!checked);
    }
  };

  return (
    <div className="cs-input-group" onClick={handleCheckboxClick}>
      <label
        htmlFor={id}
        className="cs-custom-control cs-custom-checkbox"
        style={inputProps.disabled ? undefined : { cursor: "pointer" }}
      >
        <input
          {...inputProps}
          className="cs-custom-control-input"
          type="checkbox"
          id={id}
          value=""
          checked={value === "checked" ? true : false}
          onChange={(event) => _handleCheckboxChange(event)}
        />
        <span className="cs-custom-control-indicator" />
        {typeof label === "string" ? (
          <span
            className="cs-custom-control-description"
            dangerouslySetInnerHTML={{ __html: label }}
          ></span>
        ) : (
          <span className="cs-custom-control-description">{label}</span>
        )}
      </label>
    </div>
  );
};

export default CheckboxElem;
