import { useTranslation } from "react-i18next";
import type { PropertyAttachment } from "./propertyAttachment.model";
import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { selectContactLang } from "redux/auth/selectors";

export const ATTACHMENT_DOCUMENTATION_KEY = "STATIC_DOC";
export const ATTACHMENT_FLYER_KEY = "STATIC_FLYER";
export const STATIC_PROPERTY_ATTACHMENT_KEYS = [
  ATTACHMENT_DOCUMENTATION_KEY,
  ATTACHMENT_FLYER_KEY,
];

interface UseStaticPropertyAttachmentsProps {
  selectedAttachments?: PropertyAttachment[];
}

export const useStaticPropertyAttachments = ({
  selectedAttachments,
}: UseStaticPropertyAttachmentsProps = {}) => {
  const { t } = useTranslation();
  const userLanguage = useAppSelector(selectContactLang);

  const staticAttachmentsDefaults: PropertyAttachment[] = useMemo(
    () => [
      {
        id: ATTACHMENT_DOCUMENTATION_KEY,
        title: t("Documentation"),
        mediaResource: { mimeType: "application/pdf", path: "" },
        addAddress: true,
        addAffordabilityCalculation: true,
        addPrice: true,
        showHeaderFooter: true,
        format: "portrait",
        tooltip: t(
          "Documentation and Flyer will be generated and added to your message based on chosen property."
        ),
        language: userLanguage,
      },
      {
        id: ATTACHMENT_FLYER_KEY,
        title: t("Flyer"),
        mediaResource: { mimeType: "application/pdf", path: "" },
        addAddress: true,
        addPrice: true,

        showHeaderFooter: true,
        format: "portrait",
        tooltip: t(
          "Documentation and Flyer will be generated and added to your message based on chosen property."
        ),
        language: userLanguage,
      },
    ],
    [t, userLanguage]
  );

  const staticAttachments = useMemo(() => {
    const updatedDocumentation = selectedAttachments?.find(
      (selected) => selected.id === ATTACHMENT_DOCUMENTATION_KEY
    );
    const updatedFlyer = selectedAttachments?.find(
      (selected) => selected.id === ATTACHMENT_FLYER_KEY
    );
    return [
      { ...staticAttachmentsDefaults[0], ...updatedDocumentation },
      { ...staticAttachmentsDefaults[1], ...updatedFlyer },
    ];
  }, [selectedAttachments, staticAttachmentsDefaults]);

  return {
    staticAttachments,
  };
};
