import { ReactNode } from "react";
import { NavLink as Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBuilding } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { UserIcon } from "components/admin/user/UserExcerpt";
import { useAppSelector } from "redux/hooks";
import { selectIsCrm } from "redux/auth/selectors";
import { GetContactSingleResponse } from "api/entities/contact/endpoints/single/types";

export type ReducedContactShape = {
  id: string;
  entityType?: GetContactSingleResponse["entityType"];
  contactType?: GetContactSingleResponse["contactType"];
  displayName?: GetContactSingleResponse["displayName"];
  firstName?: GetContactSingleResponse["firstName"];
  lastName?: GetContactSingleResponse["lastName"];
  email?: GetContactSingleResponse["email"];
  phone?: GetContactSingleResponse["phone"];
  sourceId?: GetContactSingleResponse["sourceId"];
  visualReferenceId?: GetContactSingleResponse["visualReferenceId"];
  lang?: GetContactSingleResponse["lang"];

  _embedded?: {
    avatar?: null | {
      mimeType?: string;
      path: string;
      orientation?: string | number | null;
    };
    employer?: null | {
      status?: GetContactSingleResponse["status"];
      displayName?: GetContactSingleResponse["displayName"];
      firstName?: GetContactSingleResponse["firstName"];
      lastName?: GetContactSingleResponse["lastName"];
    };
  };
};
export interface ContactExcerptProps {
  contact: ReducedContactShape;

  lines?: number;
  linkTo?: boolean;

  boldName?: boolean;
  prefix?: ReactNode;
}

function RenderContact({
  contact,

  lines,
  boldName,
  prefix,
}: Required<
  Pick<ContactExcerptProps, "contact" | "lines" | "prefix" | "boldName">
>) {
  const { t } = useTranslation();

  const linesArr: {
    key: string;
    render: ReactNode;
    className?: string[];
  }[] = [];

  let icon = <FontAwesomeIcon icon={faUser} className="tw-mr-1" />;
  if (contact.entityType === "legal") {
    icon = <FontAwesomeIcon icon={faBuilding} className="tw-mr-1" />;
  }

  if (contact.entityType === "individual" && contact.contactType === "user") {
    icon = <UserIcon avatar={contact._embedded?.avatar} />;
  }

  // line 1: name
  if (lines >= 1) {
    if (contact.displayName) {
      linesArr.push({
        key: "name",
        render: (
          <>
            {prefix}
            <span>
              {icon}
              {contact.displayName}
            </span>
          </>
        ),
        className: boldName ? ["tw-font-semibold"] : [],
      });
    } else if (
      (contact.firstName || contact.lastName) &&
      contact.entityType !== "legal" // legal doesn't support first/lastname
    ) {
      linesArr.push({
        key: "name",
        render: (
          <>
            {prefix}
            <span>
              {icon}
              {contact.firstName ? contact.firstName : ""}{" "}
              {contact.lastName ? contact.lastName : ""}
            </span>
          </>
        ),
        className: boldName ? ["tw-font-semibold"] : [],
      });
    } else if (contact.email) {
      linesArr.push({
        key: "name",
        render: (
          <>
            {prefix}
            <span>
              {icon}
              {contact.email}
            </span>
          </>
        ),
        className: boldName ? ["tw-font-semibold"] : [],
      });
    } else {
      linesArr.push({
        key: "name",
        render: (
          <>
            {prefix}
            <span>
              {icon}
              {t("Name is missing")}
            </span>
          </>
        ),
        className: boldName ? ["tw-font-semibold"] : [],
      });
    }
  }

  // line 2: primaryContactInfo
  if (lines >= 4) {
    if (contact.email) {
      linesArr.push({
        key: "email",
        render: contact.email,
      });
    } else if (contact.phone) {
      linesArr.push({
        key: "primaryContactInfo",
        render: contact.phone,
      });
    }
  }

  // line 3: visualReferenceId
  if (lines >= 3) {
    if (contact.visualReferenceId) {
      linesArr.push({
        key: "visualReferenceId",
        render: contact.visualReferenceId,
      });
    }
  }

  // line 4: employer name or ContactName
  if (lines >= 2) {
    if (contact._embedded?.employer?.status === "active") {
      if (contact._embedded.employer.displayName) {
        linesArr.push({
          key: "employer",
          render: contact._embedded.employer.displayName,
          className: ["tw-text-cs-shade-500"],
        });
      } else if (
        contact._embedded.employer.firstName ||
        contact._embedded.employer.lastName
      ) {
        linesArr.push({
          key: "employer",
          render: `${
            contact._embedded.employer.firstName
              ? contact._embedded.employer.firstName
              : ""
          } ${
            contact._embedded.employer.lastName
              ? contact._embedded.employer.lastName
              : ""
          }`,
          className: ["tw-text-cs-shade-500"],
        });
      }
    }
    if (
      contact.entityType === "legal" &&
      contact.displayName &&
      (contact.firstName || contact.lastName)
    ) {
      linesArr.push({
        key: "companyContact",
        render: `${contact.firstName ? contact.firstName : ""} ${
          contact.lastName ? contact.lastName : ""
        }`,
        className: ["tw-text-cs-shade-500"],
      });
    }
  }

  if (lines <= 1) {
    const line = linesArr[0];
    return <span className={line.className?.join(" ")}>{line.render}</span>;
  }

  return (
    <>
      {linesArr.map((line) => (
        <div key={line.key} className={line.className?.join(" ")}>
          {line.render}
        </div>
      ))}
    </>
  );
}

function ContactExcerpt({
  contact,

  lines = 3,
  linkTo = false,

  boldName = true,
  prefix = null,
}: ContactExcerptProps) {
  const isCrm = useAppSelector(selectIsCrm);

  if (linkTo && contact) {
    return (
      <Link
        key="link"
        title="Navigate to item"
        to={
          isCrm && contact.sourceId
            ? `/admin-super/company/list/item/${contact.sourceId}`
            : `/contact/list/item/${contact.id}`
        }
      >
        <RenderContact
          contact={contact}
          lines={lines}
          boldName={boldName}
          prefix={prefix}
        />
      </Link>
    );
  }
  return (
    <RenderContact
      contact={contact}
      lines={lines}
      boldName={boldName}
      prefix={prefix}
    />
  );
}

export default ContactExcerpt;
