import Badge from "./Badge";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import * as Sentry from "@sentry/browser";
import { Tooltip } from "../tooltip";
import { ApiErrorDefinition } from "@casasoft/styleguide/utilities/api-error/apiErrorDefinitions";
import { parseErrorMessage } from "../helpers/ParseErrorMessage";
import { useTranslation } from "react-i18next";

interface ApiErrorReportProps {
  context: string;
  error?: Omit<ApiErrorDefinition, "is_api_error_definition">; // is_api_error_definition can be omitted
  refetchFn?: () => void;
}

function ApiErrorReport({
  context,
  error = {
    code: "UNKNOWN",
    message: "Unknown error",
  },
  refetchFn,
}: ApiErrorReportProps) {
  const { t } = useTranslation();

  const [detailsVisible, setDetailsVisible] = useState(false);
  // string = Sentry event ID
  const [hasSentReport, setHasSentReport] = useState<string | boolean>(false);

  return (
    <div className="tw-relative tw-text-sm tw-p-4 tw-rounded tw-text-center tw-max-w-xs tw-min-w-[18rem] tw-mx-auto tw-shadow-lg tw-bg-cs-shade-white">
      <div className="tw-absolute tw-top-4 tw-right-4">
        <Badge color="danger" aria-label="error code badge">
          {error.code}
          {error.status ? ` (${error.status})` : ""}
        </Badge>
      </div>
      <FontAwesomeIcon
        icon={faTimesCircle}
        size="4x"
        className="tw-text-cs-danger"
      />

      <p className="tw-mt-3 tw-mb-3" aria-label="error title">
        {t("Failed to load {{subject}}", { subject: context })}
      </p>

      {detailsVisible && (
        <div
          aria-label="error details"
          className="tw-text-xs tw-mt-2 tw-text-left tw-bg-cs-danger-100 tw-p-2 tw-rounded tw-mb-3 tw-max-h-32 tw-overflow-auto"
        >
          <p className="tw-m-0" aria-label="error message">
            <strong>{error.message || "Unknown error"}</strong>
          </p>
          {error.details && (
            <div aria-label="error details message" className="tw-mt-1 tw-ml-0">
              {Object.entries(error.details).map(([field, messages]) => (
                <div key={field}>
                  <div>
                    <strong>{field}</strong>
                  </div>
                  {parseErrorMessage(messages).map((message, i) => (
                    <div key={i} className="tw-mb-2">
                      {message}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
          {"unknown_details" in error &&
            error.unknown_details &&
            error.unknown_details && (
              <pre
                aria-label="error unknown details"
                className="tw-text-xs tw-mt-1 tw-m-0"
              >
                {JSON.stringify(error.unknown_details, null, 2)}
              </pre>
            )}
        </div>
      )}

      {typeof hasSentReport === "string" && (
        <div className="tw-mb-3 tw-text-xs tw-text-cs-shade-400">
          Report ID: {hasSentReport}
        </div>
      )}

      <div className="tw-border-t tw-border-cs-shade-100 tw--mx-4 tw-mb-1 tw-border-0 tw-border-solid " />
      <div className="tw-flex tw-justify-between tw-pt-2 tw-whitespace-nowrap">
        <button
          aria-label="toggle details"
          type="button"
          className="tw-text-cs-danger tw-underline"
          onClick={() => {
            setDetailsVisible(!detailsVisible);
          }}
        >
          {detailsVisible ? t("Hide error details") : t("Show error details")}
        </button>
        <div className="tw-flex">
          <Tooltip
            triggerAsChild
            content={
              hasSentReport ? (
                t("Error report received, thank you")
              ) : (
                <div className="tw-w-32">
                  {t(
                    "Send a technical report which includes the error details"
                  )}
                </div>
              )
            }
          >
            <button
              className={
                hasSentReport
                  ? "tw-text-cs-shade-500"
                  : "tw-text-cs-danger tw-underline"
              }
              type="button"
              onClick={() => {
                if (!hasSentReport) {
                  const sEventId = Sentry.captureMessage(
                    `Report by User: ${context}`,
                    {
                      level: "error",
                      extra: {
                        name: error.name,
                        code: error.code,
                        message: error.message,
                        status: error.status,
                        details: error.details,
                        unknown_details: JSON.stringify(error.unknown_details),
                        stack: error.stack,
                      },
                    }
                  );

                  setHasSentReport(sEventId || true);
                }
              }}
            >
              {hasSentReport
                ? t("Error report received")
                : t("Send error report")}
            </button>
          </Tooltip>
          {refetchFn && (
            <button
              aria-label="try again"
              type="button"
              className="tw-ml-1 tw--my-1 tw-text-cs-danger tw-bg-cs-danger-100 tw-rounded tw-py-1 tw-px-2 hover:tw-bg-cs-danger hover:tw-text-cs-shade-white tw-transition-all"
              onClick={refetchFn}
            >
              {t("Error, try again")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApiErrorReport;
