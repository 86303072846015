import { useState } from "react";
import { FilterItem, OrderByItem } from "utilities/queryBuilder";

type LoadOptionsHelperArgs = {
  // query
  searchTerm?: string;
  activeSelection?: string[];
  pageSize?: number;
  orderBy?: OrderByItem[];
};

type UseLoadOptionsHelperArgs<
  TEntityShape extends any = unknown,
  TNotInField extends string = "id"
> = {
  notInField: TNotInField;
  notInAlias?: string;

  // data fetching
  fetchIdListFn: (query: {
    filter: FilterItem[];
    orderBy: OrderByItem[];
    pageSize: number;
    page: number;
  }) => Promise<string[]>;

  fetchFullListFn: (ids: string[]) => Promise<TEntityShape[]>;
};
function useLoadOptionsHelper<
  TEntityShape extends any = unknown,
  TNotInField extends string = "id"
>({
  notInField,
  notInAlias,
  fetchIdListFn,
  fetchFullListFn,
}: UseLoadOptionsHelperArgs<TEntityShape, TNotInField>) {
  const [initialFullItems, setInitialFullItems] = useState<TEntityShape[]>([]);
  const [fullItems, setFullItems] = useState<TEntityShape[]>([]);

  const allItems = [...initialFullItems, ...fullItems];

  async function loadOptionsHelper({
    searchTerm,
    activeSelection = [],
    pageSize = 20,
    orderBy = [],
  }: LoadOptionsHelperArgs) {
    const searchFilter: FilterItem[] = searchTerm
      ? [{ type: "search", value: searchTerm }]
      : [];

    const notInSelectedUsers: FilterItem[] = activeSelection.length
      ? [
          {
            type: "notin",
            field: notInField,
            values: activeSelection,
            alias: notInAlias,
          },
        ]
      : [];
    const query = {
      filter: [...searchFilter, ...notInSelectedUsers],
      orderBy: orderBy,
      pageSize: pageSize,
      page: 1,
    };

    const fetchedIds = await fetchIdListFn(query);

    const relevantIDs = [...fetchedIds, ...activeSelection];

    const fullItems = (await fetchFullListFn(relevantIDs)) || [];

    setFullItems(fullItems);

    if (!searchTerm) {
      setInitialFullItems(fullItems);
    }

    return fullItems;
  }

  return [allItems, loadOptionsHelper] as const;
}

export default useLoadOptionsHelper;
