import { createResource } from "redux-rest-resource";
import Config from "config";
import PropertyState from "./types";
import createResourceHelper from "utilities/createResourceHelper";
import createBaseActions from "entities/createBaseActions";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (state?: PropertyState, actions?: any) => PropertyState;
}
export const { types, actions, rootReducer }: ResourceTypes = createResource({
  name: "dynamicProperty",
  pluralName: "dynamicProperties",
  url: `${Config.apiUrl}/${Config.customerKey}/property/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    ...createBaseActions({
      embeddedName: "property",
      forcedFilter: [
        {
          type: "neq",
          field: "status",
          value: "ignore",
          where: "and",
        },
      ],
    }),
  },
});

const dynamicPropertyResourceCreator = createResourceHelper({
  actions,
  name: "dynamicProperty",
  pluralName: "dynamicProperties",
});

export const dynamicPropertyResource = dynamicPropertyResourceCreator.resource;
export const useDynamicProperty = dynamicPropertyResourceCreator.createHook();
