import * as React from "react";
import FieldContainer from "./helpers/FieldContainer";
import SwitchElem from "./helpers/SwitchElem";
import FieldContainerInterface from "./helpers/FieldContainerInterface";

interface SwitchProps extends FieldContainerInterface {
  checked?: boolean;
  value?: string; // either "checked" which evaluates to true or anythin else will evealuate to false
  label?: string;
  inputLabel?: string;
  onToggle?: (val: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  value = "unchecked",
  label = "",
  inputLabel,
  onToggle,
  ariaLabel,
  ...fieldContainerProps
}) => {
  if (checked === true) {
    value = "checked";
  } else if (checked === false) {
    value = "unchecked";
  }
  return (
    <FieldContainer
      {...fieldContainerProps}
      value={value}
      checked
      formType="switch"
      disableLabel={!inputLabel}
      renderElem={(passedDownProps) => {
        // TODO: workaround for FormContainerElem and HTMLInputElement interfaces incompatibility
        // deleting to avoid error on <input> element in SwitchElem: "Warning: Received `true` for a non-boolean attribute `nobox`."
        // TODO: avoid that FieldContainerInterface in future
        delete passedDownProps.focused;
        delete passedDownProps.filled;
        delete passedDownProps.nobox;
        delete passedDownProps.setRef;

        return (
          <SwitchElem
            {...passedDownProps}
            ariaLabel={ariaLabel}
            autoComplete={passedDownProps.autoComplete ? "on" : "off"}
            placeholder={
              passedDownProps.placeholder
                ? passedDownProps.placeholder.toString()
                : undefined
            }
            onToggle={(newCheckedState) => onToggle?.(newCheckedState)}
            label={inputLabel ? inputLabel : label}
          />
        );
      }}
    />
  );
};

export default Switch;
