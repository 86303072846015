import { useCookies } from "react-cookie";
import { COOKIES_AVAILABLE, CookieType } from "./models";

export const useNotificationCookies = (cookieId?: CookieType) => {
  const [cookies, setCookie] = useCookies([...COOKIES_AVAILABLE]);

  const isDisabledByCookie = cookieId ? cookies[cookieId] === "1" : false;

  const domain =
    window.location.hostname === "localhost"
      ? "localhost"
      : "." + window.location.hostname;

  const disableByCookie = () => {
    if (cookieId) {
      setCookie(cookieId, "1", {
        path: "/",
        maxAge: 86400,
        domain,
      });
    }
  };

  return { isDisabledByCookie, disableByCookie };
};
