import { colorVarNamesMap } from "./colorVarNameMap";
import { themeSlugValidator } from "./getThemeSettings";
import { ColorsMap, themePresetsMap } from "./themePresetsMap";

// DONT EXPORT, it does not trigger cs-theme-updated event, and is therefore only meant to be used internally
function clearTheme() {
  colorVarNamesMap.forEach((colorVarName) => {
    document.documentElement.style.removeProperty(colorVarName);
  });

  // clear theme slug var
  document.documentElement.style.removeProperty("--cs-theme-slug");
}

type ApplyThemeArgs = ColorsMap | string;
export function applyTheme(arg: ApplyThemeArgs) {
  const themeSlugOrCustom =
    typeof arg === "string" ? themeSlugValidator(arg) : arg;

  if (themeSlugOrCustom === "custom") {
    throw new Error("An error occured"); // would be an object instead
  }

  clearTheme(); // clear previously applied theme
  if (themeSlugOrCustom !== "default") {
    const colorsRecord =
      typeof themeSlugOrCustom === "string"
        ? themePresetsMap[themeSlugOrCustom]
        : themeSlugOrCustom;

    Object.entries(colorsRecord).forEach(([colorVarName, colorRgb]) => {
      document.documentElement.style.setProperty(
        colorVarName,
        `${colorRgb[0]} ${colorRgb[1]} ${colorRgb[2]}`
      );
    });
  }

  // update theme slug var
  const newActiveThemeSlug = typeof arg === "string" ? arg : "custom";
  document.documentElement.style.setProperty(
    "--cs-theme-slug",
    newActiveThemeSlug
  );

  // dispatch custom event for react hook to listen for changes
  const csThemeUpdatedEvent = new Event("cs-theme-updated");
  document.body.dispatchEvent(csThemeUpdatedEvent);
}
