import type { LanguageGroupData } from "../../LanguageBreakdownContainer";
import { LanguageBreadkdownContentHeader } from "./LanguageBreakdownContentHeader";
import { LanguageBreakdownContentBody } from "./LanguageBreakdownContentBody";
import type { LanguageCheckboxStatus } from "../../helpers/languageCheckboxHelper";

export interface LanguageBreakdownContentProps {
  languageGroups: LanguageGroupData[];
  languageCheckboxStatus: LanguageCheckboxStatus;
  onInfoClick: (language: string) => void;
  isLoading?: boolean;
}

export const LanguageBreakdownContent = ({
  languageGroups,
  languageCheckboxStatus,
  onInfoClick,
  isLoading = false,
}: LanguageBreakdownContentProps) => {
  const gridClassName =
    "tw-grid tw-gap-4 tw-auto-cols-max tw-grid-cols-[40px_1fr_100px_40px] tw-items-center tw-justify-center tw-px-4 tw-py-3";
  const { wholeSelectionChecked, handleWholeSelectionCheck } =
    languageCheckboxStatus;

  return (
    <div>
      <LanguageBreadkdownContentHeader
        isChecked={wholeSelectionChecked}
        handleCheck={handleWholeSelectionCheck}
        className={gridClassName}
      />
      <LanguageBreakdownContentBody
        languageGroups={languageGroups}
        languageCheckboxStatus={languageCheckboxStatus}
        className={gridClassName}
        onInfoClick={onInfoClick}
        isLoading={isLoading}
      />
    </div>
  );
};
