import { ReactNode } from "react";
import { DivAccessibleClick } from "../helpers";

interface CardBodyProps {
  children: ReactNode;
  /** Will render the content in a more compact way - smaller font size */
  isSmall?: boolean;
  /** Controls the min-height of the card body. Used for places where two cards are placed next to eachother and they should have the same vertical height */
  hasMinHeight?: boolean;
  /** Gives the card a slightly tint bg color based on the accent color */
  hasPrimaryBg?: boolean;
  /** Shows the card in a note style. Used for "internal notes" in most cases */
  isNote?: boolean;
  /** Click handler - used to call them main header action is most cases */
  onClick?: (key: any) => void;
}

const CardBody = ({
  children,
  onClick,
  isSmall = false,
  hasMinHeight = false,
  hasPrimaryBg = false,
  isNote = false,
}: CardBodyProps) => {
  return (
    <DivAccessibleClick
      onClick={onClick}
      className={`tw-cs-card-body-base 
        ${isSmall ? "tw-cs-card-body-is-small" : ""}
        ${hasMinHeight ? "tw-cs-card-body-has-min-height" : ""}
        ${isNote ? "tw-cs-card-body-note" : ""}
        ${hasPrimaryBg ? "tw-cs-card-body-has-primary-bg" : ""}
      `}
    >
      {children}
    </DivAccessibleClick>
  );
};

export default CardBody;
